const WOMAN_SEAT_CATEGORIES = ['woman_seat', 'woman_seat_tv'];
const GENERAL_SEAT_CATEGORIES = ['seat', 'seat_tv', 'seat_handicap', 'hallway_seat'];
const PET_FRIENDLY_SEAT_CATEGORIES = ['pet_seat', 'pet_seat_tv', 'pet_friendly'];
const SELECTABLE_CATEGORIES = [
  ...WOMAN_SEAT_CATEGORIES,
  ...GENERAL_SEAT_CATEGORIES,
  ...PET_FRIENDLY_SEAT_CATEGORIES,
];

export const isSeat = (category) => SELECTABLE_CATEGORIES.includes(category);
export const isWomanSeat = (category) => WOMAN_SEAT_CATEGORIES.includes(category);
export const isPetFriendlySeat = (category) => PET_FRIENDLY_SEAT_CATEGORIES.includes(category);
