import React from 'react';
import PropTypes from 'prop-types';

import { TicketBox, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

/**
 * TicketList Functional Component
 *
 * Renders a list of tickets for the provided passengers and trip.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.passengers - The list of passengers associated with the tickets.
 * @param {Function} props.onClickedTicket - The function to handle when a ticket is clicked.
 *                                            It typically receives a ticket identifier or details as an argument.
 *
 * @returns {JSX.Element} The rendered ticket list component.
 */
const TicketList = ({ passengers, onClickedTicket }) => {
  const { t } = useTranslation('exchange');
  const { t: tSeats } = useTranslation('seats');

  /**
   * handleRadioChange Function
   *
   * Handles the change event for a radio input.
   *
   * This function is called when a radio button's selected value changes, and it processes the event accordingly.
   *
   * @param {Object} e - The event object generated by the radio button change.
   * @param {HTMLInputElement} e.target - The radio input element that triggered the change event.
   * @returns {void}
   */
  const handleRadioChange = (e) => {
    onClickedTicket(e.target.value);
  };

  return passengers.map((passenger) => (
    <div id={passenger.transporterKey} key={passenger.transporterKey}>
      <TicketBox>
        <Spacing alignItems="center">
          <Spacing size="XS" vertical>
            <Text weight="semibold">
              {passenger.firstName} {passenger.lastName} {passenger.secondLastName}
            </Text>
            <Spacing>
              <Text size="S">{tSeats(`passengers:type.${passenger.category}`)}</Text>
              <Text size="S">
                {t('ticket_list.seat')}: {passenger.seatNumber}{' '}
              </Text>
            </Spacing>
          </Spacing>
          {passenger.state === 'canceled' ? (
            <span style={{ color: '#cf0101' }}>{t('ticket_list.state_cancelled')}</span>
          ) : (
            <input
              type="radio"
              name="ticket"
              id={passenger.transporterKey}
              value={passenger.transporterKey}
              onChange={handleRadioChange}
            />
          )}
        </Spacing>
      </TicketBox>
    </div>
  ));
};

TicketList.protoTypes = {
  passengers: PropTypes.array,
  onClickedTicket: PropTypes.func,
};

export default TicketList;
