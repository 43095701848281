import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@reservamos/elements';
import './BusIconIndicator.scss';

/**
 * It shows the selected seats
 * @param {*} param0 - Props
 * @param {boolean} param0.isActive - If is active
 * @returns - BusIconIndicator component.
 */
const BusIconIndicator = ({ isActive }) => {
  return (
    <div className={`bus-indicator-${isActive ? 'active' : 'inactive'}`}>
      <Icon type="emojiBusSide" />
    </div>
  );
};

BusIconIndicator.propTypes = {
  isActive: PropTypes.bool,
};

BusIconIndicator.defaultProps = {
  isActive: false,
};

export default BusIconIndicator;
