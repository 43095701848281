import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FilterTag from '../../../ui/atoms/FilterTag';

/**
 * Component for rendering a short access filter.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of the filter.
 * @param {string} props.value - The value of the filter.
 * @param {string} props.i18Key - The translation key for the label.
 * @param {Function} props.onSelect - The function to be called when the filter is selected.
 * @param {boolean} props.isActive - Indicates whether the filter is active or not.
 * @returns {JSX.Element} The rendered component.
 */
const ShortAccessFilter = ({ type, value, i18Key, onSelect, isActive }) => {
  const { t } = useTranslation();
  return (
    <FilterTag
      type={type}
      value={value}
      onSelect={onSelect}
      label={t(i18Key)}
      i18Key={i18Key}
      isActive={isActive}
      filterType={type}
    />
  );
};

ShortAccessFilter.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  i18Key: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

/**
 * Component for rendering a list of short access filters.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.options - The array of short access filter options to be rendered.
 * @param {Function} props.onSelect - The function to be called when a filter is selected.
 * @param {Array} props.scheduleFilters - The array of schedule filters.
 * @param {Array} props.categoriesFilter - The array of categories filters.
 * @param {string} props.sort - The selected sort option.
 * @param {Array} props.stopsOptionSelected - The array of selected stops options.
 * @param {boolean} props.isOpenTicketList - Indicates whether it is an open ticket list or not.
 * @returns {JSX.Element[]} The rendered list of short access filters.
 */
const ShortAccessFiltersList = ({ options, onSelect, isOpenTicketList }) => {
  return options.map((shortOption) => {
    if (isOpenTicketList) return null;
    return (
      <ShortAccessFilter
        key={`short-filter-${shortOption.value}`}
        type={shortOption.filterType}
        value={shortOption.value}
        i18Key={shortOption.i18Key}
        onSelect={onSelect}
        isActive={shortOption.isActive}
      />
    );
  });
};

ShortAccessFiltersList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      i18Key: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  sortOptions: PropTypes.array,
  scheduleOptions: PropTypes.array,
  categoriesOptions: PropTypes.array,
  stopsOptions: PropTypes.array,
  scheduleFilters: PropTypes.array,
  categoriesFilter: PropTypes.array,
  sort: PropTypes.string,
  stopsOptionSelected: PropTypes.array,
  isProviderList: PropTypes.bool,
};

export default ShortAccessFiltersList;
