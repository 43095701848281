import React from 'react';
import PropTypes from 'prop-types';
import { GrowthBookProvider as BaseGrowthBookProvider } from '@growthbook/growthbook-react';
import growthBook from '../../services/growthBook';

const propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Renders the GrowthBookProvider from the GrowthBook React SDK only if the GrowthBook instance is initialized.
 * Otherwise, it only renders the children.
 *
 */
const GrowthBookProvider = ({ children }) => {
  // GrowthBook is initialized before rendering the app, so this check should not change after the first render.
  if (growthBook?.isInitialized()) {
    const growthBookInstance = growthBook.getInstance();
    return (
      <BaseGrowthBookProvider growthbook={growthBookInstance}>{children}</BaseGrowthBookProvider>
    );
  }
  return children;
};

GrowthBookProvider.propTypes = propTypes;

export default GrowthBookProvider;
