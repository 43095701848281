/* eslint-disable import/prefer-default-export */
import smartBannerTracker from 'metrics/user-analytics/smartBannerTracker';
import 'vendor/smartbanner/main';
import 'vendor/smartbanner/main.css';
import 'vendor/smartbanner/custom';
import 'vendor/smartbanner/preventHideHeader';

function addProp(key, value) {
  const meta = document.createElement('meta');
  meta.name = `smartbanner:${key}`;
  meta.content = value;
  document.getElementsByTagName('head')[0].appendChild(meta);
}

// <!-- Start SmartBanner configuration -->
// <meta name="smartbanner:title" content="Smart Application">
// <meta name="smartbanner:author" content="SmartBanner Contributors">
// <meta name="smartbanner:price" content="FREE">
// <meta name="smartbanner:price-suffix-apple" content=" - On the App Store">
// <meta name="smartbanner:price-suffix-google" content=" - In Google Play">
// <meta name="smartbanner:icon-apple" content="https://url/to/apple-store-icon.png">
// <meta name="smartbanner:icon-google" content="https://url/to/google-play-icon.png">
// <meta name="smartbanner:button" content="VIEW">
// <meta name="smartbanner:button-url-apple" content="https://ios/application-url">
// <meta name="smartbanner:button-url-google" content="https://android/application-url">
// <meta name="smartbanner:enabled-platforms" content="android,ios">
// <meta name="smartbanner:close-label" content="Close">
// <!-- End SmartBanner configuration -->
export default function initSmartBanner(smartBannerConfig) {
  const { enabled, props = {} } = smartBannerConfig || {};
  if (!enabled) return;

  const propKeys = Object.keys(props);
  if (!propKeys.length) return;

  document.addEventListener('smartbanner.clickout', () => {
    smartBannerTracker();
    window.smartbanner.exit();
  });

  propKeys.forEach((key) => addProp(key, props[key]));

  window.addEventListener('smartbanner:ready', () => {
    window.smartbanner.publish();
  });
  const event = new Event('smartbanner:props-ready');
  window.dispatchEvent(event);
}
