import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import setupI18n from './setupI18n';

const CustomI18nextProvider = ({ children, i18n }) => (
  <I18nextProvider i18n={i18n || setupI18n()}>{children}</I18nextProvider>
);

CustomI18nextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }),
};

CustomI18nextProvider.defaultProps = {
  i18n: null,
};

export default CustomI18nextProvider;
