import { camelizeKeys } from 'humps';

const parsePassenger = (user, previousParams = {}) => {
  if (!user) return null;
  const camelizedUser = camelizeKeys(user);
  const firstName = camelizedUser.firstName || camelizedUser.name;
  let name = `${firstName} ${camelizedUser.lastName}`;
  if (camelizedUser.secondLastName) name = `${name} ${camelizedUser.secondLastName}`;

  return {
    ...previousParams,
    firstName,
    lastName: camelizedUser.lastName,
    secondLastName: camelizedUser.secondLastName,
    name,
    email: camelizedUser.email,
    category: 'adult',
    busCategory: 'general',
    documentType: 'CC',
  };
};

export const formatName = (passenger) => {
  const name = `${passenger.firstName} ${passenger.lastName || ''} ${
    passenger.secondLastName || ''
  }`.trim();
  return {
    ...passenger,
    name,
  };
};

export default parsePassenger;
