import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Text } from '@reservamos/elements';
import PricingRow from '../../../ui/atoms/PricingRow';

const TransferPaymentTicket = ({
  businessName,
  nit,
  description,
  createdAt,
  status,
  cus,
  reference,
  bankName,
  total,
  pdfUrl,
}) => {
  const { t } = useTranslation('purchase');

  return (
    <div className="purchases-section-side">
      <Text weight="bold">{t('label.purchase_receipt')}</Text>
      <div className="purchase-sidebar-pricing">
        <div className="purchase-pricing">
          <div className="section-content list-style">
            {Boolean(businessName) && (
              <PricingRow label={t('label.business_name')} price={businessName} isNumber />
            )}
            {Boolean(nit) && <PricingRow label="NIT" price={nit} isNumber />}
            {Boolean(description) && (
              <PricingRow label={t('label.description')} price={description} isNumber />
            )}
            {Boolean(createdAt) && (
              <PricingRow
                label={t('label.date')}
                price={moment(createdAt).format('DD-MM-YYYY')}
                isNumber
              />
            )}
            {Boolean(status) && <PricingRow label={t('label.status')} price={status} isNumber />}
            {Boolean(cus) && <PricingRow label="CUS" price={cus} isNumber />}
            {Boolean(reference) && (
              <PricingRow label={t('label.payment_reference')} price={reference} isNumber />
            )}
            {Boolean(bankName) && <PricingRow label={t('label.bank')} price={bankName} isNumber />}
            {Boolean(total) && <PricingRow label={t('label.total')} price={total} />}
            {Boolean(pdfUrl) && (
              <div className="total-details section-category">
                <p className="label" />
                <div className="tickets-succeed-label">
                  <a href={pdfUrl} target="_blank">
                    {t('label.download_receipt')}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TransferPaymentTicket.propTypes = {
  businessName: PropTypes.string,
  nit: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  status: PropTypes.string,
  cus: PropTypes.string,
  reference: PropTypes.string,
  bankName: PropTypes.string,
  total: PropTypes.number,
  pdfUrl: PropTypes.string,
};

TransferPaymentTicket.defaultProps = {
  businessName: null,
  nit: null,
  description: null,
  createdAt: null,
  status: null,
  cus: null,
  reference: null,
  bankName: null,
  total: null,
  pdfUrl: null,
};

export default TransferPaymentTicket;
