import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'styles/lib/helpers/javascript';

const propTypes = {
  className: PropTypes.string,
  closable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  className: '',
  closable: true,
};

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }

  clickHandler(e) {
    e.stopPropagation();

    this.tooltipDismissEl.classList.add('show');
  }

  mouseOverHandler(e) {
    e.stopPropagation();

    const tooltipContentEl = this.tooltipRootEl.getElementsByClassName('tooltip')[0];

    tooltipContentEl.classList.add('show');
    tooltipContentEl.classList.remove('hide');
  }

  mouseLeaveHandler(e) {
    e.stopPropagation();

    const tooltipContentEl = this.tooltipRootEl.getElementsByClassName('tooltip')[0];

    tooltipContentEl.classList.add('hide');
    tooltipContentEl.classList.remove('show');
    this.tooltipDismissEl.classList.add('hide');
    this.tooltipDismissEl.classList.remove('show');
  }

  render() {
    const { className, closable, children } = this.props;

    return (
      <div
        className={className}
        onClick={this.clickHandler}
        onMouseOver={this.mouseOverHandler}
        onFocus={this.mouseOverHandler}
        onMouseLeave={this.mouseLeaveHandler}
        onBlur={this.mouseLeaveHandler}
        ref={(el) => (this.tooltipRootEl = el)}
      >
        <div
          className="js-tooltip-dismiss"
          onClick={this.mouseLeaveHandler}
          ref={(el) => (this.tooltipDismissEl = el)}
        />

        {children}

        {closable && (
          <span className="tooltip-close">
            <i className="ic-tooltip-close" />
          </span>
        )}
      </div>
    );
  }
}

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
