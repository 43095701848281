/**
 * Definition of card brands
 * @enum {string}
 */
const CardBrands = {
  VISA: 'visa',
  ELECTRON: 'electron',
  MASTERCARD: 'mastercard',
  MAESTRO: 'maestro',
  AMEX: 'amex',
  DISCOVER: 'discover',
  DINERS: 'diners',
  JCB: 'JCB',
  UNION_PAY: 'union pay',
  NA: 'NA',
};

/**
 * Calculate card brand form card number
 * @param {String} cc - Card number
 * @returns {CardBrands} Card brand
 */
const creditCardType = (cc) => {
  const amex = /^3[47][0-9]{13}$/;
  const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const electron = /^(4026|417500|4405|4508|4844|4913|4917)\d+$/;
  const cup1 = /^(62|88)\d+$/;
  const mastercard = /^5[1-5][0-9]{14}$/;
  const disco1 = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
  const diners = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  const jcb = /^(?:2131|1800|35\d{3})\d{11}$/;

  const maestro = /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/;

  if (visa.test(cc)) {
    return CardBrands.VISA;
  }
  if (electron.test(cc)) {
    return CardBrands.ELECTRON;
  }
  if (amex.test(cc)) {
    return CardBrands.AMEX;
  }
  if (mastercard.test(cc)) {
    return CardBrands.MASTERCARD;
  }
  if (disco1.test(cc)) {
    return CardBrands.DISCOVER;
  }
  if (diners.test(cc)) {
    return CardBrands.DINERS;
  }
  if (jcb.test(cc)) {
    return CardBrands.JCB;
  }
  if (cup1.test(cc)) {
    return CardBrands.UNION_PAY;
  }
  if (maestro.test(cc)) {
    return CardBrands.MAESTRO;
  }
  return undefined;
};

/**
 * Get card brand from card number
 * @param {String} cardNumber - Card number
 * @returns {CardBrands} Card brand
 */
const getCardBrand = (cardNumber) => {
  const brand = creditCardType(cardNumber);

  return brand || 'NA';
};

export default getCardBrand;
