import { connect } from 'react-redux';
import moment from 'moment';
import { toggleModal } from '@/actions';
import TripSummaryCard from './TripSummaryCard';

const mapStateToProps = (state) => {
  const {
    purchase,
    whitelabelConfig: {
      env: { theme },
    },
  } = state;
  const departureTrip = purchase.get('departs');
  const returnTrip = purchase.get('returns');
  const departureTransportType = departureTrip.getIn(['fragments', 0, 'transportType']);
  const returnTransportType = returnTrip.getIn(['fragments', 0, 'transportType']);
  const departureDate = moment(departureTrip.get('departure'));
  const returnDate = moment(returnTrip.get('departure'));
  const departureOpenTicket = departureTrip.getIn(['fragments', 0, 'openTicket']) === true;
  const returnOpenTicket = returnTrip.getIn(['fragments', 0, 'openTicket']) === true;
  let departureLogoUrl;
  let returnLogoUrl;
  let departureOpenLogoUrl;
  let returnOpenLogoUrl;
  let departureLineName;
  let returnLineName;
  let departureServiceType;

  switch (departureTransportType) {
    case 'bus':
      departureLogoUrl = departureTrip.getIn(['fragments', 0, 'line', 'logoUrl']);
      departureOpenLogoUrl = departureTrip.getIn(['fragments', 0, 'line', 'openTicketLogosUrls']);
      departureOpenLogoUrl = departureOpenLogoUrl ? departureOpenLogoUrl.toJS() : undefined;
      departureLineName = departureTrip.getIn(['fragments', 0, 'lineId']);
      departureServiceType = departureTrip.getIn(['fragments', 0, 'service']);
      break;
    case 'flight':
      departureLogoUrl = departureTrip.getIn(['fragments', 0, 'carrier', 'logoUrl']);
      break;
    default:
      departureLogoUrl = null;
  }

  switch (returnTransportType) {
    case 'bus':
      returnLogoUrl = returnTrip.getIn(['fragments', 0, 'line', 'logoUrl']);
      returnOpenLogoUrl = returnTrip.getIn(['fragments', 0, 'line', 'openTicketLogosUrls']);
      returnOpenLogoUrl = returnOpenLogoUrl ? returnOpenLogoUrl.toJS() : undefined;
      returnLineName = returnTrip.getIn(['fragments', 0, 'lineId']);
      break;
    case 'flight':
      returnLogoUrl = returnTrip.getIn(['fragments', 0, 'carrier', 'logoUrl']);
      break;
    default:
      returnLogoUrl = null;
  }

  const [departTrip] = purchase.getIn(['departs', 'fragments']).toJS();
  const seenAdultPrice = purchase.get('seenAdultPrice') || departTrip.pricing.total;

  const departureLogosToShow =
    departureOpenTicket &&
    departureOpenLogoUrl &&
    Array.isArray(departureOpenLogoUrl) &&
    departureOpenLogoUrl.length > 0
      ? departureOpenLogoUrl
      : [{ name: departureLineName, url: departureLogoUrl }];

  const returnLogosToShow =
    returnOpenTicket &&
    returnOpenLogoUrl &&
    Array.isArray(returnOpenLogoUrl) &&
    returnOpenLogoUrl.length > 0
      ? returnOpenLogoUrl
      : [{ name: returnLineName, url: returnLogoUrl }];

  const serviceTypes = theme?.serviceTypes || {};
  const serviceTypeIcon =
    serviceTypes[departureServiceType]?.icon || serviceTypes.default?.icon || 'Bus';
  const lineName = departureTrip.getIn(['fragments', 0, 'line', 'name']);
  const fallbackServiceTypeLabel = serviceTypes.useLineNameAsServiceType
    ? lineName
    : departureServiceType;
  const serviceTypeLabel = serviceTypes[departureServiceType]?.label || fallbackServiceTypeLabel;

  return {
    departureDate,
    returnDate,
    departureOpenTicket,
    returnOpenTicket,
    departureTrip: departureTrip.toJS(),
    destinationCity: departureTrip.getIn(['destination', 'cityName']),
    originCity: departureTrip.getIn(['origin', 'cityName']),
    returnTrip: returnTrip.toJS(),
    roundTrip: purchase.get('roundTrip'),
    returnAdultPrice: purchase.get('returnAdultPrice'),
    seenAdultPrice,
    departureLogosToShow,
    returnLogosToShow,
    departureServiceType,
    features: state.whitelabelConfig.features,
    serviceTypeIcon,
    serviceTypeLabel,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (component, extraProps) => dispatch(toggleModal('visible', component, extraProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripSummaryCard);
