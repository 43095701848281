import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Text, Gap } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';

/**
 * FileSelectField component.
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of the input field.
 * @param {Object} props.input - The input props from redux-form.
 * @param {Object} props.meta - The meta props from redux-form.
 * @returns {JSX.Element} The rendered component.
 */
const FileSelectField = ({ type, input, meta }) => {
  delete input.value;

  const hiddenInputId = `${input.name}.file-select`;
  const [passengerDocument, setPassengerDocument] = useState();
  const [fileLabelProperties, setFileLabelProperties] = useState({});
  const { t } = useTranslation('passengers');
  /**
   * Delegate event to value(s).
   * @param {string} onChange - The function to be called when the input changes.
   */
  function delegateEventToFiles(onChange) {
    return (e) => {
      const firstSelectedDocument = e.target?.files?.[0] || undefined;
      const file = new FileReader();

      file.onload = () => {
        const fileSize = firstSelectedDocument?.size || '';
        const fileResultWithSize = `fileSize:${fileSize}::${file.result}`;

        onChange(fileResultWithSize);
        setPassengerDocument(fileResultWithSize);
        setFileLabelProperties({
          name: firstSelectedDocument?.name || '',
          size: fileSize,
          type: firstSelectedDocument?.type || '',
        });
      };

      file.readAsDataURL(firstSelectedDocument);
    };
  }

  /**
   * Render the file label content.
   *
   * If the file label properties are empty, return the upload file text.
   * Otherwise, return the selected file name.
   *
   * Highlight the label with a red border if there is an error.
   *
   * There is an error when the file size is greater than 3 MB.
   * @returns {string} The label content.
   */
  function renderFileLabelContent() {
    const hasError = Boolean(meta.touched && meta.error);
    const fileSize = fileLabelProperties?.size;
    const fileSizeInMB = fileSize / (1024 * 1024);
    const isSizeValid = !fileSize || (fileSize && fileSizeInMB <= 3);

    const buttonStyle = {
      cursor: 'pointer',
      border: `1px solid ${hasError || !isSizeValid ? '#bf0811' : '#c2c2c2'}`,
      borderRadius: '5px',
      padding: '5px 10px',
      backgroundColor: 'white',
      boxShadow: '0 2px 3px #ccc',
    };

    const textContentStyle = {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    return (
      <>
        <Gap columnGap="M">
          <div style={buttonStyle}>
            <Text elementType="span" size="S" color={hasError ? 'accent' : 'grayMedium'}>
              {t('choose_file')}
            </Text>
          </div>
          <When condition={Object.keys(fileLabelProperties).length}>
            <div style={textContentStyle}>
              <Text elementType="span" size="S" color={isSizeValid ? 'grayMedium' : 'accent'}>
                {`${fileLabelProperties.name} (${`${fileSizeInMB.toFixed(2)} MB`})`}
              </Text>
            </div>
          </When>
        </Gap>
      </>
    );
  }

  const acceptedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/svg+xml',
    'image/heic',
    'application/pdf',
    'image/tiff',
    'image/webp',
    'image/vnd.adobe.photoshop',
  ];

  return (
    <>
      <label className="custom-file-input" htmlFor={hiddenInputId}>
        {renderFileLabelContent()}
      </label>
      <input
        type={type}
        error={Boolean(meta.touched && meta.error)}
        accept={acceptedFileTypes.join(', ')}
        onChange={delegateEventToFiles(input.onChange)}
        id={hiddenInputId}
        className="hidden"
      />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field {...input} value={passengerDocument} type="hidden" component="input" />
    </>
  );
};

FileSelectField.propTypes = {
  type: PropTypes.string.isRequired,
  // Redux-form props
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default FileSelectField;
