import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AncillariesSwitch from '../../ui/atoms/AncillariesSwitch';
import useInsuranceConfig from '../../hooks/useInsuranceConfig';

/**
 * InsuranceAncillarySwitch component.
 *
 * @param {object} props - checked, isLoading, onChange, message, title, price, singlePrice, plusTaxes
 * @param {boolean} props.checked - true if the insurance is checked
 * @param {boolean} props.isLoading - true if the insurance is loading
 * @param {function} props.onChange - the function to call when the insurance is changed
 * @param {string} props.message - the message to show
 * @param {string} props.title - the title to show
 * @param {string} props.price - the price to show
 * @param {boolean} props.singlePrice - true if each abbr text can be shown
 * @param {boolean} props.plusTaxes - true if plus taxes text can be shown
 * @returns {React.Component} - The insurance ancillary switch component
 */
const InsuranceAncillarySwitch = ({
  checked,
  isLoading,
  message,
  title,
  price,
  singlePrice,
  onChange,
  plusTaxes,
}) => {
  const { icon, detailsUrl } = useInsuranceConfig();
  const { t } = useTranslation();

  return (
    <AncillariesSwitch
      icon={icon}
      href={detailsUrl}
      linkText={detailsUrl ? t('insurance:terms_conditions') : ''}
      checked={checked}
      isLoading={isLoading}
      onChange={onChange}
      message={message}
      title={title}
      price={price}
      singlePrice={singlePrice}
      plusTaxes={plusTaxes}
    />
  );
};

InsuranceAncillarySwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  singlePrice: PropTypes.bool,
  plusTaxes: PropTypes.bool,
};

export default InsuranceAncillarySwitch;
