import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/SpinLoader';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const Loader = ({ isLoading }) => {
  if (isLoading) return <div className="spin-loader" />;

  return null;
};

Loader.propTypes = propTypes;

export default Loader;
