export default function getPurchaseStepName(pathname) {
  if (pathname.match(/\/passengers/)) {
    return 'passengers';
  }
  if (pathname.match(/\/seats\/departure/)) {
    return 'dSeats';
  }
  if (pathname.match(/\/seats\/return/)) {
    return 'rSeats';
  }
  if (pathname.match(/\/checkout/)) {
    return 'checkout';
  }
  if (pathname.match(/\/complete/)) {
    return 'complete';
  }

  return '';
}
