import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

const SaveCardCheckbox = ({ onClick, onChange, checked, isLoading, user }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');

  if (!user || !features.ALLOW_TO_SAVE_CARDS) return null;

  return (
    <Checkbox
      name="save-card"
      id="save-card"
      label={t('label.save_card_future')}
      onChange={onChange}
      onClick={() => onClick(checked)}
      checked={checked}
      isLoading={isLoading}
    />
  );
};

SaveCardCheckbox.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
};

SaveCardCheckbox.defaultProps = {
  user: {},
  onChange: () => {},
  onClick: () => {},
  isLoading: false,
  checked: false,
};

export default SaveCardCheckbox;
