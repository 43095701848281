import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';
import Amenity from '../../ui/atoms/Amenity';

const propTypes = {
  logo: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  amenities: PropTypes.array.isRequired,
};

const DetailsFooter = ({ logo, service, amenities }) => {
  const { t } = useTranslation();
  return (
    <div className="detail-amenities">
      <Text>{t('amenities_and_services')}</Text>
      <div className="detail-amenities-row">
        <div className="amenities-supplier">
          <ul>
            <li>
              <img src={logo} />
            </li>

            <li>{service}</li>
          </ul>
        </div>
        <div className="amenities-details">
          <div className="amenities-details">
            <Tooltip>
              <span className="amenities-tooltip">{t('amenities')}</span>
              <div className="tooltip">
                <ul className="amenities-list">
                  {amenities.map((amenity) => (
                    <li key={amenity} className="amenities-list-item">
                      <Amenity type={amenity} />
                    </li>
                  ))}
                </ul>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsFooter.propTypes = propTypes;

export default DetailsFooter;
