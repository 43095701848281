import { Map, List, fromJS } from 'immutable';
import * as types from 'constants/ActionTypes';

function mergeProviders(left = List(), right) {
  const list = [];
  const screen = {};
  const addProviders = (provider) => {
    const name = provider.get('id');

    if (!screen[name]) {
      list.push(provider);
    }

    screen[name] = true;
  };

  left.forEach(addProviders);
  right.forEach(addProviders);

  return List(list);
}

const defaultProviders = Map({
  showing: 'all',
  sortBy: '',
  filterBy: ['none'],
});

export default function providers(providers = defaultProviders, action) {
  switch (action.type) {
    case types.SET_PROVIDERS:
      return providers.setIn(
        [action.searchId, 'list'],
        mergeProviders(providers.getIn([action.searchId, 'list']), fromJS(action.providers)),
      );

    case types.SET_TRANSPORT_STATE:
      return providers.setIn([action.searchId, 'state', action.transportType], action.state);

    case types.FILTER_PROVIDERS:
      return providers.set('showing', action.selected);

    case types.SET_PROVIDERS_SORT_ORDER:
      return providers.set('sortBy', action.sortBy);

    case types.SET_PROVIDERS_FILTER_BY:
      return providers.set('filterBy', action.filterBy);

    case types.RESET_PROVIDERS:
      return defaultProviders;

    case types.RESET_PROVIDERS_FILTERS:
      return providers.set('filterBy', ['none']).set('sortBy', '');

    default:
      return providers;
  }
}
