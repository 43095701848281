import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Input, Text, Box } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

/**
 * Displays an alert message box.
 * @param {Object} props - The component props.
 * @param {boolean} props.isActivated - Flag to determine if the message box is activated.
 * @param {object} props.errorMessage - Object containing error messages for email and phone.
 * @param {function} props.handleChange - Function to handle input changes.
 * @param {object} props.values - Object containing email and phone values.
 * @param {function} props.handleBlur - Function to handle input blur
 * @param {function} props.setFieldValue - Function to set field value
 */
function TripPriceAlertForm({
  isActivated,
  errorMessage,
  handleChange,
  values,
  handleBlur,
  setFieldValue,
}) {
  const { t } = useTranslation('search');
  return (
    <Box
      borderRadius="M"
      bubbleTailPosition="top"
      hasShadow
      paddingHorizontal="S"
      paddingVertical="S"
    >
      <Spacing size="S" vertical>
        {!isActivated && (
          <Text weight="semibold">{t('label.where_do_you_want_to_receive_your_offers')}</Text>
        )}

        {isActivated ? (
          <Box
            paddingVertical="S"
            paddingHorizontal="S"
            borderRadius="M"
            border="all"
            borderColor="grayBorder"
            alphaBg="M"
          >
            <Spacing vertical size="S">
              <Text weight="semibold" mobileSize="S">
                {values.email}
              </Text>

              <Text weight="semibold" mobileSize="S">
                {values.phone}
              </Text>
            </Spacing>
          </Box>
        ) : (
          <>
            <Input
              onBlur={handleBlur('email')}
              onChange={handleChange}
              autocomplete="on"
              borderRadius="M"
              errorMessage={t(errorMessage.email)}
              hasError={Boolean(errorMessage.email)}
              id="emial"
              name="email"
              label={t('passengers:email')}
              type="email"
              value={values.email}
              hasShadow={false}
              allBorders
              placeholderOnly
            />

            <Input
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue('phone', e.target.value.trim());
              }}
              autocomplete="on"
              borderRadius="M"
              value={values.phone}
              errorMessage={t(errorMessage.phone)}
              hasError={Boolean(errorMessage.phone)}
              id="phone"
              label={t('passengers:phone')}
              name="phone"
              type="tel"
              hasShadow={false}
              allBorders
              onChange={(e) => setFieldValue('phone', e.target.value.replace(/\s+/g, ''))}
              placeholderOnly
            />
          </>
        )}
      </Spacing>
    </Box>
  );
}

TripPriceAlertForm.propTypes = {
  isActivated: PropTypes.bool,
  errorMessage: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  values: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TripPriceAlertForm;
