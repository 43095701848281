import { GrowthBook } from '@growthbook/growthbook-react';
import store from '@/store';

const GrowthBookService = () => {
  let growthBookInstance;

  /**
   * This is a higher order function that validates if the GrowthBook instance is initialized,
   * throws an error if it is not. Otherwise, it calls the function passed as parameter.
   * Here's an example of how to use it:
   *
   * ```js
   *  const functionThatNeedsGrowthBook = withGrowthBookValidation((myParams) => {
   *    // Do something with myParams and growthBookInstance
   *  }))
   *```
   */
  const withGrowthBookValidation =
    (fn) =>
    (...args) => {
      if (!growthBookInstance) {
        throw new Error('The GrowthBook instance has not been initialized');
      }
      return fn(...args);
    };

  const init = (configuration) => {
    growthBookInstance = new GrowthBook(configuration);
  };

  const setAttributes = withGrowthBookValidation((attributes) => {
    growthBookInstance.setAttributes({ ...growthBookInstance.getAttributes(), ...attributes });
  });

  const loadFeatures = withGrowthBookValidation((featuresEndpoint) => {
    fetch(featuresEndpoint)
      .then((res) => res.json())
      .then((json) => {
        growthBookInstance.setFeatures(json.features);
        window.growthBook = growthBookInstance;
        const growthBookReady = new CustomEvent('GrowthBookReady');
        window.dispatchEvent(growthBookReady);
      });
  });

  /**
   * Returns the value of a feature.
   * @param {Array} feature - Feature name.
   * @returns {String} - Feature value
   */
  const getGrowthBookFeature = (feature) => {
    const { brand } = store.getState().whitelabelConfig.env;
    return growthBookInstance?.getFeatureValue(`${feature}_${brand}`);
  };

  const getInstance = withGrowthBookValidation(() => growthBookInstance);

  const isInitialized = () => Boolean(growthBookInstance);

  return {
    init,
    setAttributes,
    loadFeatures,
    getInstance,
    isInitialized,
    getGrowthBookFeature,
  };
};

const growthBook = GrowthBookService();

export default growthBook;
