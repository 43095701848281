import { List } from 'immutable';

export default function findTrip(trips, transportType, id) {
  if (!trips) return;

  let tripList;

  switch (transportType) {
    case 'bus':
      tripList = trips.getIn(['buses', 'trips']);
      break;
    case 'flight':
      tripList = trips.getIn(['flights', 'trips']);
      break;
    case 'mixed':
      tripList = trips.getIn(['mixed', 'trips']);
      break;
    default:
      tripList = List();
  }

  return tripList.find((trip) => trip.get('id') === id);
}
