import { useState, useCallback, useEffect, useRef } from 'react';
import base32 from 'hi-base32';
import { useDispatch, useSelector } from 'react-redux';
import usePurchase from 'hooks/store/usePurchase';
import { setError } from '../../actions';
import adyen from '../../../payments/core/engines/adyen';
import { cardPaymentAttempt } from '../../actions/payment';

function useAdyen(adyenElement, showAdyenForm) {
  const dispatch = useDispatch();
  const requestedPayment = useRef(false);

  const { env } = useSelector((state) => state.whitelabelConfig);
  const { currency } = env;

  const {
    token: purchaseToken,
    total: purchaseTotal,
    selectedPaymentOption,
    monthlySelectedPlan,
  } = usePurchase();

  const [firstDigits, setFirstDigits] = useState(null);
  const [lastDigits, setLastDigits] = useState(null);
  const [cardBrand, setCardBrand] = useState(null);

  function onBinValue({ binValue }) {
    setFirstDigits(binValue);
  }

  function onFieldValid({ fieldType, endDigits }) {
    if (fieldType !== 'encryptedCardNumber') return;
    setLastDigits(endDigits);
  }

  function onBrand({ brand }) {
    setCardBrand(brand);
  }

  /** Function that executes when an error card occures */
  function onError() {
    dispatch(setError(200, 'check_the_fields_in_red', 'warning', false, null, null, null, 'fixed'));
  }

  const onSubmit = useCallback(
    ({ isValid, data }) => {
      if (!isValid || requestedPayment.current) return;
      const { browserInfo, paymentMethod } = data;

      const adyenToken = base32.encode(
        [
          paymentMethod.encryptedCardNumber,
          paymentMethod.encryptedExpiryMonth,
          paymentMethod.encryptedExpiryYear,
          paymentMethod.encryptedSecurityCode,
        ].join(':'),
      );

      const mask = firstDigits.toString().length === 8 ? 'xxxx' : 'xxxxxx';

      const cardValues = {
        holderName: paymentMethod.holderName,
        cardNumber: `${firstDigits}${mask}${lastDigits}`,
        cardBrand,
        monthlySelectedPlan,
      };

      const paymentInfo = {
        adyenToken,
        amount: purchaseTotal,
        currency,
        browserInfo,
      };

      dispatch(cardPaymentAttempt(purchaseToken, cardValues, paymentInfo));
      requestedPayment.current = true;
    },
    [
      cardBrand,
      dispatch,
      firstDigits,
      lastDigits,
      monthlySelectedPlan,
      purchaseToken,
      currency,
      purchaseTotal,
    ],
  );

  useEffect(() => {
    const adyenInstance = adyen.getAdyen();

    if (!adyenElement.current) return;
    if (!adyenInstance) return;
    if (selectedPaymentOption !== 'credit_card') return;
    if (!showAdyenForm) return;

    adyenInstance
      .create('card', {
        hasHolderName: true,
        holderNameRequired: true,
        showPayButton: false,
        amount: {
          value: purchaseTotal * 100,
          currency: 'MXN',
        },
        styles: {
          error: {
            color: '#ff040d',
          },
          validated: {
            color: '#66ba5b',
          },
          placeholder: {
            color: '#c7c7c7',
          },
        },
        onSubmit,
        onFieldValid,
        onBinValue,
        onBrand,
        onError,
      })
      .mount(adyenElement.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adyenElement, onSubmit, purchaseTotal, selectedPaymentOption, showAdyenForm]);

  useEffect(() => {
    const adyenInstance = adyen.getAdyen();
    if (!adyenInstance) return;
    adyenInstance.update('card', {
      amount: {
        value: purchaseTotal * 100,
        currency: env.currency,
      },
    });
  }, [env.currency, purchaseTotal]);
}

export default useAdyen;
