import React from 'react';
import { Box, Spacing, Text } from '@reservamos/elements';
import PropTypes from 'prop-types';
import AddPointsButton from '../AddPointsButton';

const NotPointsContent = ({ notPointsMessage, showAddPointsButton, rechargeURL }) => {
  return (
    <Box paddingVertical="S" paddingHorizontal="S">
      <Spacing vertical>
        <Text>{notPointsMessage}</Text>
        {showAddPointsButton && (
          <div>
            <AddPointsButton rechargeURL={rechargeURL} tooltipPosition="left" />
          </div>
        )}
      </Spacing>
    </Box>
  );
};

NotPointsContent.propTypes = {
  notPointsMessage: PropTypes.string,
  showAddPointsButton: PropTypes.bool,
  rechargeURL: PropTypes.string,
};

export default NotPointsContent;
