import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { trackRecommendedTripsLoaded } from 'utils/userPreferences';
import { LoaderPulse, Spacing, SparkleBadge, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useRecommendedTrips from '../../../context/RecommendedTrips/useRecommendedTrips';
import TripList from '../TripList/TripList';
import '../../../styles/components/RecommendedTrips';

/**
 * Renders a list of recommended trips.
 *
 * @param {Object} props - The component props.
 * @param {string} props.way - The way of the trip.
 * @param {string} props.providerId - The provider ID.
 * @param {function} props.selectTrip - The function to select a trip.
 * @param {function} props.onOptionClick - The function to handle option click.
 * @param {Array} props.filtersApplied - The applied filters.
 * @param {Object} props.departureTrip - The departure trip selected
 *
 * @returns {JSX.Element|null} The rendered component.
 */
const RecommendedTripsList = ({
  way,
  providerId,
  selectTrip,
  onOptionClick,
  filtersApplied = [],
  departureTrip,
  couponCode,
  discountedTripsByCoupon,
}) => {
  const { t } = useTranslation('');
  const showRecommendedEventSent = useRef();
  const { trips, isLoading, requested } = useRecommendedTrips({
    providerId,
    way,
    departureTripArrival: !departureTrip?.openTicket && departureTrip?.arrival,
  });

  if (isLoading && !trips.length) {
    return <LoaderPulse color="accent" hasWrapper={false} />;
  }

  if (requested && !showRecommendedEventSent.current) {
    trackRecommendedTripsLoaded({
      way,
      recommendationsTypes: trips?.map((trip) => trip.recommendationType),
    });
    showRecommendedEventSent.current = true;
  }

  if (!trips.length) return null;

  const horizontalMode = trips.length > 1;

  return (
    <div className="recommended-trips-container">
      <Spacing vertical>
        <div className="recommended-trips-title">
          <Spacing size="S" alignItems="center">
            <Text size="M" weight="bold">
              {t('search:title.recommended_trips')}
            </Text>
            <SparkleBadge labelText={t('general:for_you')} />
          </Spacing>
        </div>
        <TripList
          trips={trips}
          way={way}
          selectTrip={selectTrip}
          onOptionClick={onOptionClick}
          filtersApplied={filtersApplied}
          isNotFiltered
          highLightAsMobile={horizontalMode}
          isHorizontal={horizontalMode}
          departureTrip={departureTrip}
          isRecommendedList
          couponCode={couponCode}
          discountedTripsByCoupon={discountedTripsByCoupon}
        />
      </Spacing>
    </div>
  );
};

RecommendedTripsList.propTypes = {
  way: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  selectTrip: PropTypes.func,
  onOptionClick: PropTypes.func,
  filtersApplied: PropTypes.array,
  departureTrip: PropTypes.object,
  couponCode: PropTypes.string,
  discountedTripsByCoupon: PropTypes.array,
};

export default RecommendedTripsList;
