import React from 'react';
import Proptypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Visibility, Text, Spacing, FlatButton, Gap } from '@reservamos/elements';
import PassengersList from './PassengersList';

const ExchangeSidebar = ({
  originDisplay,
  destinationDisplay,
  time,
  dueDate,
  openTicket,
  passengers,
  resetExchange,
}) => {
  const history = useHistory();
  const { t } = useTranslation('exchange');
  const handleChangeSchedule = () => {
    resetExchange();
    history.push('/exchange');
  };

  return (
    <Spacing vertical responsiveSize="XS">
      <Spacing vertical size="XS">
        <Visibility type="hideForMedium">
          <Text weight="bold" size="S">
            {t('subtitle.your_trip')}
          </Text>
        </Visibility>
        <Spacing vertical responsiveSize="XS">
          {originDisplay !== '' ? (
            <>
              <Text weight="semibold" size="L" mobileSize="S">
                {t('labels.trip_destinations_sumary', { originDisplay, destinationDisplay })}
              </Text>
              {time !== '' && openTicket === false && (
                <Spacing size="S">
                  <Spacing size="S">
                    <Text size="S" mobileSize="XS">
                      {dueDate}
                    </Text>
                    <Text size="S" mobileSize="XS">
                      {time}
                    </Text>
                  </Spacing>
                  <FlatButton size="S" mobileSize="XS" onClick={() => handleChangeSchedule()}>
                    {t('labels.change_schedule')}
                  </FlatButton>
                </Spacing>
              )}
              <Gap columnGap="M">{passengers && <PassengersList passengers={passengers} />}</Gap>
            </>
          ) : (
            <Text size="S" italic>
              {t('title.trip_sumary')}
            </Text>
          )}
        </Spacing>
      </Spacing>
    </Spacing>
  );
};

ExchangeSidebar.propTypes = {
  originDisplay: Proptypes.string.isRequired,
  destinationDisplay: Proptypes.string,
  time: Proptypes.string.isRequired,
  dueDate: Proptypes.string,
  openTicket: Proptypes.bool,
  resetExchange: Proptypes.func.isRequired,
  passengers: Proptypes.array,
};

export default ExchangeSidebar;
