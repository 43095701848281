import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text, Icon, Currency, Spacing } from '@reservamos/elements';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import openTicketETN from '../../../images/brands-unique/etn/openTicketETN.svg';
import 'styles/components/search/OpenTicketButton';

const NormalTicketButton = ({ onClick, way, isExchange }) => {
  const { t } = useTranslation();

  const search = useSelector((state) => state.search.toJS());
  const searchId = way === 'departure' ? search.departure.id : search.return.id;

  const trips = useSelector((state) => state.trips.toJS());
  const tripList = [
    ...trips[searchId].buses.trips,
    ...trips[searchId].flights.trips,
    ...trips[searchId].mixed.trips,
  ];

  const normalTripList = tripList.filter((trip) => trip.openTicket === false);

  let priceToShow;
  let providerDiscount;
  let total;

  if (normalTripList && normalTripList.length > 0) {
    priceToShow = normalTripList.reduce((prev, curr) => {
      if (way === 'departure') {
        return prev.pricing.total < curr.pricing.total ? prev : curr;
      }

      return prev.roundTripPricing.total < curr.roundTripPricing.total ? prev : curr;
    });

    const tripPrice = way === 'departure' ? priceToShow.pricing : priceToShow.roundTripPricing;

    total = tripPrice.total;
    providerDiscount = tripPrice.providerDiscount;
  }

  const hasDiscount = providerDiscount && providerDiscount.amount > 0;
  return (
    <div className="result-button" onClick={() => onClick()}>
      {hasDiscount && <i className="result-box-sale" />}
      <div className="result-button-wrapper">
        <Spacing size="XS" alignItems="center">
          <Icon type={openTicketETN} />
          <Text size="S" weight="semibold" mobileSize="M">
            {total && !isExchange
              ? t('general:return_to_regular_trips')
              : t('general:return_to_regular_trips_without')}
          </Text>
        </Spacing>
        {total && !isExchange && (
          <Currency
            price={total}
            decimals={0}
            color={hasDiscount ? 'discount' : 'primary'}
            currency={getCurrencySuffix()}
            sign={getCurrencyPrefix()}
            size="L"
            mobileSize="L"
            weight="bold"
          />
        )}
      </div>
    </div>
  );
};

NormalTicketButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  way: PropTypes.string.isRequired,
  isExchange: PropTypes.bool.isRequired,
};

export default NormalTicketButton;
