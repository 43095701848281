import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { Spacing, Input, Button, FlatButton, IconButton } from '@reservamos/elements';
import SectionTitle from 'ui/atoms/SectionTitle';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import ExchangeInfoModal from 'ui/molecules/ExchangeInfoModal';

const propTypes = {
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  addTicketsTitle: PropTypes.string,
  ctaText: PropTypes.string,
  handleRedirectExchangeAlternative: PropTypes.func,
  redirectText: PropTypes.string,
  showRedirectButton: PropTypes.bool,
};
/**
 * Form to exchange a ticket on IAMSA
 * @param {Object} props - Object component props
 * @param {Function} props.handleSubmit - Function to be executed when submitting the form
 * @param {Object} props.title - Title to show in the exchange section
 * @param {Object} props.addTicketsTitle - Text of the button to add a ticket to the exchange
 * @param {Object} props.ctaText - Cta text of the submit
 * @param {Function} props.handleRedirectExchangeAlternative - Function to execute to redirect to another type of exchange
 * @param {String} props.redirectText - Text to show in the redirect button
 * @param {Boolean} props.showRedirectButton - Flag to show or hide the redirect button
 * @returns Component
 */
const IAMSAForm = ({
  handleSubmit,
  title,
  addTicketsTitle,
  ctaText,
  handleRedirectExchangeAlternative,
  redirectText,
  showRedirectButton,
}) => {
  const { t } = useTranslation('exchange');
  let arrayHelper;
  const initialValues = {
    operationNumbers: [''],
    nit: '',
  };
  const validationSchema = yup.object().shape({
    operationNumbers: yup.array().of(
      yup
        .object()
        .shape({
          operationNumber: yup
            .string()
            .matches(/^[0-9]+$/, t('validation.only_numbers'))
            .min(8, t('validation.min_digits', { digits: 8 }))
            .max(12, t('validation.max_digits', { digits: 12 }))
            .required(t('validation.operation_number_required')),
        })
        .nullable(),
    ),
    nit: yup
      .string()
      .matches(/^[0-9]+$/, t('validation.only_numbers'))
      .min(1, t('validation.min_digits', { digits: 1 }))
      .max(4, t('validation.max_digits', { digits: 4 }))
      .required(t('validation.nit_number_required')),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          handleSubmit({ operationNumbers: values.operationNumbers, nit: values.nit })
        }
      >
        {({ values, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <Spacing vertical responsiveSize="XL">
              <Spacing vertical size="S">
                <SectionTitle
                  title={title}
                  description={t('subtitle.input_ticket_details', { context: 'etn' })}
                />
                <div className="exchange-content-inputs">
                  <div>
                    <FieldArray
                      name="operationNumbers"
                      render={(arrayHelpers) => {
                        arrayHelper = arrayHelpers;
                        return (
                          <>
                            {values.operationNumbers.map((op, index) => {
                              return (
                                <Field name={`operationNumbers.${index}`}>
                                  {({ field, meta }) => (
                                    <div className="exchange-content-wrapper">
                                      <Input
                                        id={`operationNumber[${index}]`}
                                        label={t('labels.input_operation_number')}
                                        maxLength={12}
                                        errorMessage={meta.error && meta.error.operationNumber}
                                        hasError={meta.error && meta.touched}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                      {index !== 0 && (
                                        <IconButton
                                          iconType="Close"
                                          onClick={() => arrayHelper.remove(index)}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Field>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      label={t('labels.input_nit_number')}
                      id="nit"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nit}
                      maxLength={4}
                      errorMessage={errors.nit}
                      hasError={errors.nit !== undefined && touched.nit}
                    />
                  </div>
                </div>
                <ExchangeInfoModal />
              </Spacing>
              <Spacing justifyContent="space-between" isResponsive>
                <FlatButton onClick={() => arrayHelper.push('')}>{addTicketsTitle}</FlatButton>
                <Spacing isResponsive responsiveColumnReverse>
                  {showRedirectButton && (
                    <FlatButton onClick={handleRedirectExchangeAlternative}>
                      {redirectText}
                    </FlatButton>
                  )}
                  <Button
                    buttonType="submit"
                    text={ctaText}
                    variant="accent"
                    isRounded
                    withHeight
                    fullWidthOnSmall
                  />
                </Spacing>
              </Spacing>
            </Spacing>
          </Form>
        )}
      </Formik>
    </>
  );
};

IAMSAForm.propTypes = propTypes;

export default IAMSAForm;
