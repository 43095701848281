import { combineReducers } from 'redux';
import * as types from 'constants/ActionTypes';
import { reducer as form } from 'redux-form';
import config from './config';
import agency from './agency';
import { cities, terminals, airports, lines, carriers } from './catalogs';
import overlayView from './overlayView';
import search from './search';
import providers from './providers';
import trips from './trips';
import siemprePlus from './siemprePlus';
import tripFilters from './tripFilters';
import purchase from './purchase';
import payment from './payment';
import tripsDetails from './tripsDetails';
import ads from './ads';
import exchange from './exchange';
import cards from './cards';
import costapass from './costapass';
import sso from './sso';
import tickets from './tickets';
import whitelabelConfig from './whitelabelConfig';
import userPreferences from './userPreferences';
import seatsBus from './seatsBus';

// eslint-disable-next-line prettier/prettier
function errors(
  state = {
    code: 0,
    message: 'Error message',
    style: 'error',
    customMsg: '',
    customRedirectPath: '/',
    subMessage: '',
    alertType: 'global',
  },
  action,
) {
  switch (action.type) {
    case types.SET_NOTIFICATION:
      return {
        ...state,
        code: action.code,
        message: action.message,
        style: action.style,
        redirect: action.redirect,
        customMsg: action.customMsg,
        subMessage: action.subMessage,
        customRedirectPath: action.customRedirectPath,
        alertType: action.alertType,
        messageData: action.messageData,
      };

    default:
      return state;
  }
}

function modal(state = { visibility: 'hidden' }, action) {
  switch (action.type) {
    case types.TOGGLE_MODAL:
      return {
        ...state,
        visibility: action.visibility,
        component: action.component,
        active: action.active,
        ...action.extraProps,
      };

    default:
      return state;
  }
}

function ux(state = { showMenu: false }, action) {
  switch (action.type) {
    case types.TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
      };

    case types.CHANGE_MENU:
      return {
        ...state,
        showMenu: action.visibility,
      };

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  ads,
  agency,
  config,
  errors,
  modal,
  overlayView,
  cities,
  terminals,
  airports,
  lines,
  carriers,
  search,
  providers,
  trips,
  siemprePlus,
  tripFilters,
  purchase,
  payment,
  form,
  tripsDetails,
  ux,
  exchange,
  cards,
  costapass,
  sso,
  tickets,
  whitelabelConfig,
  userPreferences,
  seatsBus,
});

export default rootReducer;
