import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/search/OpenTicketButton';

const NormalTicketWrapper = ({ children }) => {
  return <div className="result-button-container fade-in">{children}</div>;
};

NormalTicketWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NormalTicketWrapper;
