import * as types from 'constants/ActionTypes';

export function cities(state = {}, action) {
  switch (action.type) {
    case types.SET_CITIES:
      return { ...state, ...action.cities };

    default:
      return state;
  }
}

export function terminals(state = {}, action) {
  switch (action.type) {
    case types.SET_TERMINALS:
      return { ...state, ...action.terminals };

    default:
      return state;
  }
}

export function airports(state = {}, action) {
  switch (action.type) {
    case types.SET_AIRPORTS:
      return { ...state, ...action.airports };

    default:
      return state;
  }
}

export function lines(state = {}, action) {
  switch (action.type) {
    case types.SET_LINES:
      return { ...state, ...action.lines };

    default:
      return state;
  }
}

export function carriers(state = {}, action) {
  switch (action.type) {
    case types.SET_CARRIERS:
      return { ...state, ...action.carriers };

    default:
      return state;
  }
}
