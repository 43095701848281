// @ts-check

import kushki from '../../../engines/kushki';

/**
 * @typedef {import('./StoreStrategy').StoreStrategy} StoreStrategy
 */

/**
 * @function KushkiStoreStrategy
 * @returns {StoreStrategy}
 */
function KushkiStoreStrategy() {
  async function getPayload(payload) {
    const token = await kushki.createCashToken(payload);
    return { kushki_token: token };
  }

  return {
    getPayload,
  };
}

const kushkiStoreStrategy = KushkiStoreStrategy();

export default kushkiStoreStrategy;
