import moment from 'moment';
import * as types from 'constants/ActionTypes';
import { hourDescription, hasMultipleCarriers } from 'utils/Reserbus';
import dataFinder from '@/store/dataFinder';

function getBusProviderProperties(trip) {
  const {
    lineId,
    providerId,
    duration,
    departure,
    service,
    pricing: { total, providerDiscount },
    roundTripPricing: { total: roundTotal, providerDiscount: roundProviderDiscount },
  } = trip;
  const line = dataFinder('lines', lineId);
  const departureHourDescription = hourDescription(moment(departure).hour());

  return {
    providerDiscount,
    roundProviderDiscount,
    service: service.toUpperCase(),
    transportType: 'bus',
    id: providerId,
    name: line.name,
    transporterName: line.transporterName,
    ally: line.ally,
    lowestPrice: total,
    roundLowestPrice: roundTotal,
    duration,
    averageRating: line.averageRatings,
    logoUrl: line.logoUrl,
    copyrightProtected: line.copyrightProtected,
    lineUrl: line.ally ? null : line.redirectionInfo.mobile.shortUrl,
    hasDiscounts: !!providerDiscount,
    roundHasDiscounts: !!roundProviderDiscount,
    tripCount: 1,
    departures: [departureHourDescription],
    amenities: line.services,
    importance: line.importance,
  };
}

function getFlightProviderProperties(flight) {
  const {
    legs,
    duration,
    departure,
    services = [],
    pricing: { total, providerDiscount },
  } = flight;
  const carrier = dataFinder('carriers', legs[0].carrierId);
  const multicarrier = hasMultipleCarriers(legs);
  const departureHourDescription = hourDescription(moment(departure).hour());
  let multicarrierProperties = {};

  if (multicarrier) {
    multicarrierProperties = {
      id: 'multicarrier',
      name: 'Múltiples Aerolíneas',
      transporterName: 'Múltiples Aerolíneas',
      ally: true,
      copyrightProtected: false,
      averageRating: 0,
      logoUrl: null,
    };
  }

  return {
    transportType: 'flight',
    id: carrier.carrierId,
    name: carrier.name,
    transporterName: carrier.name,
    ally: carrier.ally,
    lowestPrice: parseInt(total.toFixed(), 10),
    duration,
    averageRating: carrier.averageRating,
    logoUrl: carrier.logoUrl,
    copyrightProtected: !!carrier.copyrightProtected,
    hasDiscounts: !!providerDiscount,
    tripCount: 1,
    departures: [departureHourDescription],
    amenities: services,
    // overwrite properties for multicarrier flights
    ...multicarrierProperties,
  };
}

function getMixedProviderProperties(itinerary) {
  const {
    duration,
    departure,
    pricing: { total },
  } = itinerary;
  const departureHourDescription = hourDescription(moment(departure).hour());
  let ally = true;
  let copyrightProtected = false;
  const mixedProviders = [];

  itinerary.fragments.forEach((fragment) => {
    let providerProperties;

    if (fragment.transportType === 'bus') {
      providerProperties = getBusProviderProperties(fragment);
    } else {
      providerProperties = getFlightProviderProperties(fragment);
    }

    ally = ally && providerProperties.ally;
    copyrightProtected = copyrightProtected || providerProperties.copyrightProtected;
    mixedProviders.push(providerProperties);
  });

  return {
    transportType: 'mixed',
    id: itinerary.providerId,
    name: 'Multicarrier',
    transporterName: 'Multicarrier',
    ally,
    lowestPrice: total,
    duration,
    averageRatings: 0,
    logoUrl: null, // fixme
    copyrightProtected,
    hasDiscounts: false,
    tripCount: 1,
    departures: [departureHourDescription], // fixme
    mixedProviders,
  };
}

function getProviderProperties(trip) {
  switch (trip.transportType) {
    case 'bus':
      return getBusProviderProperties(trip);
    case 'flight':
      return getFlightProviderProperties(trip);
    case 'mixed':
      return getMixedProviderProperties(trip);
    default:
      return {};
  }
}

export function setProviders(searchId, trips) {
  const providers = {};
  trips.forEach((trip) => {
    if (trip.openTicket) return;

    const providerProperties = getProviderProperties(trip);
    const providerId = providerProperties.id;
    const provider = providers[providerId];

    if (provider) {
      // update provider properties if its already added
      const {
        lowestPrice,
        duration,
        departures: [departureHourDescription],
      } = providerProperties;

      if (lowestPrice < provider.lowestPrice) {
        Object.assign(provider, { lowestPrice, duration });
      }

      if (!provider.departures.includes(departureHourDescription)) {
        provider.departures.push(departureHourDescription);
      }

      Object.assign(provider, { tripCount: provider.tripCount + 1 });
    } else {
      providers[providerId] = providerProperties;
    }
  });

  return {
    type: types.SET_PROVIDERS,
    searchId,
    providers: Object.keys(providers).map((providerId) => providers[providerId]),
  };
}

export function setTransportState(transportType, searchId, pollState, tripCount) {
  if (pollState === 'finished' && !tripCount) {
    pollState = 'disabled';
  }

  return {
    type: types.SET_TRANSPORT_STATE,
    searchId,
    transportType,
    state: pollState,
  };
}

export function setDynamicPriceAttributes(search) {
  return {
    type: types.SET_DYNAMIC_ATTRIBUTES,
    dynamicPricing: search.type_of_transport.bus.dynamic_pricing,
    promotions: search.type_of_transport.bus.promotions,
  };
}

export function filterProviders(selected) {
  return {
    type: types.FILTER_PROVIDERS,
    selected,
  };
}

export function setProviderSortOrder(sortBy) {
  return {
    type: types.SET_PROVIDERS_SORT_ORDER,
    sortBy,
  };
}

export function setProviderFilterBy(filterBy) {
  return {
    type: types.SET_PROVIDERS_FILTER_BY,
    filterBy,
  };
}

/**
 * Reset the providersFilters
 * @returns action
 */
export function resetProviderFilters() {
  return {
    type: types.RESET_PROVIDERS_FILTERS,
  };
}

export function resetProviders() {
  return { type: types.RESET_PROVIDERS };
}
