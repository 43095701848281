import React from 'react';
import PropTypes from 'prop-types';
import { Gap, Text } from '@reservamos/elements';

/**
 * Displays the available seats for a given trip inside a search result
 * @param {Object} props - Component props
 * @param {Function} props.t - i18n translation function
 * @param {Number} props.studentSeat - Number of student seats available
 * @param {Number} props.teacherSeat - Number of teacher seats available
 * @param {Number} props.minorSeat - Number of minor seats available
 * @param {Number} props.olderSeat - Number of older seats available
 * @param {Boolean} props.useSmallText - Whether to use small text
 * @returns {React.Component}
 * @example
 * <SeatAvailability
 *  t={t}
 *  studentSeat={studentSeat}
 *  teacherSeat={teacherSeat}
 *  minorSeat={minorSeat}
 *  olderSeat={olderSeat}
 * />
 * */
const SeatAvailability = ({ t, studentSeat, teacherSeat, minorSeat, olderSeat, useSmallText }) => {
  const seats = {
    student: studentSeat,
    teacher: teacherSeat,
    minor: minorSeat,
    older: olderSeat,
  };

  const seatArray = Object.entries(seats).map(([key, value]) => ({
    name: key,
    value,
  }));

  seatArray.sort((a, b) => b.value - a.value);

  const seatText = seatArray.map((seat) => {
    const empty = seat.value === 0;
    if (empty) return null;

    return (
      <Text
        size={useSmallText ? 'XS' : 'S'}
        color={useSmallText ? 'grayLight' : 'grayStrong'}
        key={seat.name}
        elementType="span"
      >
        {t(`search:available_${seat.name}_seats`, { count: seat.value })}
      </Text>
    );
  });

  return (
    <Gap columnGap="S" rowGap="">
      {seatText}
    </Gap>
  );
};

SeatAvailability.propTypes = {
  t: PropTypes.func.isRequired,
  studentSeat: PropTypes.number,
  teacherSeat: PropTypes.number,
  minorSeat: PropTypes.number,
  olderSeat: PropTypes.number,
  useSmallText: PropTypes.bool,
};

SeatAvailability.defaultProps = {
  studentSeat: null,
  teacherSeat: null,
  minorSeat: null,
  olderSeat: null,
};

export default SeatAvailability;
