import { connect } from 'react-redux';
import { filterProviders } from '@/actions/providers';
import TransportFilter from './TransportFilter';

const busSelector = ['buses', 'trips'];
const flightSelector = ['flights', 'trips'];
const mixedSelector = ['mixed', 'trips'];

const mapStateToProps = (state) => {
  const { search, trips, providers } = state;
  const departureId = search.getIn(['departure', 'id']);
  const departureTrips = trips.get(departureId);

  return {
    transportTypes: {
      bus: !!(departureTrips && departureTrips.getIn(busSelector).size),
      flight: !!(departureTrips && departureTrips.getIn(flightSelector).size),
      mixed: !!(departureTrips && departureTrips.getIn(mixedSelector).size),
    },
    showing: providers.get('showing'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFilterChange: (selected) => dispatch(filterProviders(selected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportFilter);
