import { connect } from 'react-redux';
import { fetchTripsDetails, selectSeats, setPassengers, updateSeat } from '@/actions/purchase';
import { clearError, setError } from '@/actions/index';
import { getSeatSelectorPropsFromPurchase, getSeatSelectorPropsFromTrip } from 'utils/seats';
import ReturnSeats from './ReturnSeats';
import {
  dispatchFinishSeatSelection,
  dispatchResetPassengers,
} from '../../../features/purchase/redux/dispatchers';

const mapStateToProps = (state, ownProps) => {
  const purchase = state.purchase?.toJS() || {};
  const { isPurchaseView = true, trip, fromTrip, resultsLayout } = ownProps;
  const way = 'return';

  let propsNeeded = {};
  if (isPurchaseView) {
    propsNeeded = getSeatSelectorPropsFromPurchase({ way, purchase });
  } else {
    propsNeeded = getSeatSelectorPropsFromTrip({ way, trip, fromTrip });
  }

  return { ...propsNeeded, resultsLayout };
};

const mapDispatchToProps = (dispatch, { isPurchaseView = true, ...ownProps }) => ({
  fetchTripsDetails: (fragments) => {
    dispatch(fetchTripsDetails({ ...fragments, saveIn: isPurchaseView ? 'purchase' : 'trips' }));
  },
  selectSeats: (seats) =>
    dispatch(
      selectSeats('returns', seats, isPurchaseView ? 'purchase' : 'trips', ownProps.trip?.id),
    ),

  updateSeat: (seats) =>
    dispatch(
      updateSeat('returns', seats, isPurchaseView ? 'purchase' : 'trips', ownProps.trip?.id),
    ),

  sendNotification: (message, messageData) =>
    dispatch(setError(null, message, 'warning', false, null, null, null, 'fixed', messageData)),

  resetPassengers: () => dispatch(dispatchResetPassengers()),

  next: () => {
    if (isPurchaseView) {
      dispatch(dispatchFinishSeatSelection(ownProps.history));
    } else {
      ownProps.finishSeatsSelection();
    }
  },

  goToDepartureSeats: () => {
    dispatch(setPassengers([]));
    ownProps.history.go(-1);
  },

  /**
   * Clear the alert error
   */
  clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnSeats);
