import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, ToggleSwitch, Box, Visibility, Icon } from '@reservamos/elements';
import adjacentSeatPerson from 'images/seats/adjacentSeatPerson.svg';
import adjacentSeat from 'images/seats/adjacentSeat.svg';
import './AdjacentSeat.scss';

const AdjacentSeat = ({
  isVisible,
  checked,
  isLoading,
  onChange,
  id,
  title,
  text,
  hasToggleSwitch,
  isMobile,
}) => {
  if (!isVisible) return null;
  const ImageComponent = <img className="adjacent-image" src={adjacentSeatPerson} />;
  const visibility = isMobile ? 'hideForLargeOnly' : 'hideForMedium';

  const VisibilityComponent = <Visibility type="hideForMobileOnly">{ImageComponent}</Visibility>;

  return (
    <Visibility type={visibility}>
      <Box
        border="all"
        borderColor="grayBorder"
        borderRadius="M"
        paddingHorizontal="M"
        paddingVertical="M"
        paddingHorizontalMobile="S"
        paddingVerticalMobile="S"
      >
        <Spacing justifyContent="space-between" alignItems="center">
          <Spacing size="XS" vertical={!isMobile} alignItems={isMobile ? 'center' : 'flex-start'}>
            {isMobile && <Icon type={adjacentSeat} size="S" />}
            <Text weight="semibold" size="L" mobileSize="S">
              {title}
            </Text>
            {!isMobile && (
              <Text size="M" mobileSize="S">
                {text}
              </Text>
            )}
          </Spacing>

          {hasToggleSwitch ? (
            <Spacing alignItems="center">
              {VisibilityComponent}
              <ToggleSwitch
                checked={checked}
                isLoading={isLoading}
                id={id}
                onChange={onChange}
                fillBackground
                size="S"
              />
            </Spacing>
          ) : (
            <>{ImageComponent}</>
          )}
        </Spacing>
      </Box>
    </Visibility>
  );
};

AdjacentSeat.propTypes = {
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hasToggleSwitch: PropTypes.bool,
  isMobile: PropTypes.bool,
  isVisible: PropTypes.bool,
};

AdjacentSeat.defaultProps = {
  hasToggleSwitch: false,
  isMobile: false,
  isVisible: false,
};

export default AdjacentSeat;
