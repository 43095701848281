/* eslint-disable no-case-declarations */
import { Map } from 'immutable';
import * as types from 'constants/ActionTypes';

const WAY_TEMPLATE = Map({ id: null, didInvalidate: false });

const defaultSearch = Map({
  passengers: Map({ adult: 1, child: 0, infant: 0 }),
  departure: WAY_TEMPLATE,
  return: WAY_TEMPLATE,
  couponCode: '',
  couponCodeDeleted: false,
  couponLoading: false,
  minPrices: Map({ departure: null, return: null }),
  nearTerminals: Map({ origins: null, destinations: null }),
  redirectTo: Map({ redirect: false, roundTrip: false, brand: '', departure: {}, return: {} }),
  resultPriceToShow: Map({ priceType: 'normal', walletType: '' }),
});

export default function search(search = defaultSearch, action) {
  switch (action.type) {
    case types.SET_REDIRECT_PARAMS:
      return search
        .setIn(['redirectTo', action.way], action.params)
        .setIn(['redirectTo', 'redirect'], action.redirect)
        .setIn(['redirectTo', 'brand'], action.brand)
        .setIn(['redirectTo', 'roundTrip'], action.roundTrip)
        .setIn(['redirectTo', 'waitForPurchase'], action.waitForPurchase);

    case types.SET_SEARCH_PARAMS: {
      return search.merge({
        originId: action.origin,
        destinationId: action.destination,
        passengers: Map(action.passengers),
        departureDate: action.departureDate,
        returnDate: action.returnDate,
      });
    }

    case types.RECEIVE_MIN_PRICES:
      return search.setIn(['minPrices', action.way], action.prices);

    case types.RECEIVE_NEAR_TERMINALS:
      if (!action.terminals) return search;

      const currentKey = action.way === 'departure' ? 'origins' : 'destinations';
      const currentTerminals = action.terminals[currentKey] ? action.terminals[currentKey] : [];
      return search.setIn(['nearTerminals', currentKey], currentTerminals);

    case types.SET_DYNAMIC_ATTRIBUTES:
      return search.merge({ dynamicPricing: action.dynamicPricing, promotions: action.promotions });

    case types.REQUEST_SEARCH:
      return search.set('couponCode', '').setIn([action.way, 'isFetching'], true);

    case types.RECEIVE_SEARCH: {
      const lineNames = Object.keys(action.lines);
      const { isOpen } = action;

      return search
        .merge({
          hasMultipleProviders: lineNames.length > 1,
          providerId: lineNames[0],
        })
        .mergeIn(
          [action.way],
          Map({
            ...action.search,
            isFetching: false,
            didInvalidate: false,
            lastUpdated: action.receivedAt,
            isOpen: isOpen || false,
          }),
        );
    }
    case types.INVALIDATE_SEARCH:
      return search.setIn([action.way, 'didInvalidate'], true);

    case types.FETCHING_COUPON:
      return search.merge({
        couponLoading: true,
      });

    case types.RESET_SEARCH:
      return search.merge({
        departure: WAY_TEMPLATE,
        return: WAY_TEMPLATE,
      });

    case types.SAVE_COUPON_CODE:
      return search.merge({
        couponCode: action.couponCode,
        couponLoading: false,
      });

    case types.DELETE_COUPON_CODE:
      return search.merge({
        couponCode: '',
        couponLoading: false,
        couponCodeDeleted: true,
      });

    case types.RESET_COUPON_FORM_STATE:
      return search.merge({
        couponCode: '',
        couponCodeDeleted: false,
      });

    case types.SET_DISCOUNTED_TRIPS:
      return search
        .setIn(['discountedTripsByCoupon', action.couponCode], action.trips)
        .set('walletTypeDiscount', action.walletType);

    case types.DELETE_WALLET_DISCOUNT:
      return search.removeIn(['discountedTripsByCoupon', action.walletType]);

    case types.FETCHING_WALLET_DISCOUNT:
      return search.set('isWalletDiscountFetching', action.isFetching);

    case types.SET_RESULT_PRICE_TO_SHOW:
      return search.set('resultPriceToShow', {
        priceType: action.priceType,
        walletType: action.walletType,
      });

    default:
      return search;
  }
}
