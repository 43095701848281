import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { selectRenderer, FilterableSelectRenderer } from 'utils/formRenderers';

const NationalityField = ({ name }) => {
  const {
    env: { nationalities },
    features,
  } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('passengers');

  const options = [
    ...nationalities.map((nationality) => ({
      value: nationality.value,
      label: nationality.label,
    })),
  ];

  const componentToUse = features.NATIONALITY_FIELD_WITH_FILTER
    ? FilterableSelectRenderer
    : selectRenderer;

  return (
    <Field
      id={name}
      name={name}
      label={t('nationality')}
      placeholder={t('nationality')}
      options={options}
      noOptionsText={t('general:no_options_found')}
      component={componentToUse}
    />
  );
};

NationalityField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default NationalityField;
