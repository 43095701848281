import React from 'react';
import useGrowthBookFeaturesValue from './useGrowthBookFeaturesValues';

/**
 * Higher-order component that enhances a component with GrowthBook features.
 *
 * @param {React.Component} Component - The component to be enhanced.
 * @returns {React.Component} - The enhanced component.
 */
const withGrowthBookFeatures = (Component, ...args) => {
  /**
   * Wrapped component that receives the GrowthBook features as props.
   *
   * @param {Object} props - The component props.
   * @returns {React.Element} - The rendered component.
   */
  const WrappedComponent = (props) => {
    const growthBookFeatures = useGrowthBookFeaturesValue(...args);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} growthBookFeatures={growthBookFeatures} />;
  };

  return WrappedComponent;
};

export default withGrowthBookFeatures;
