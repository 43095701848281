import { Map, fromJS } from 'immutable';
import { SET_AD_DATA, SET_AD_IS_LOADING, SET_AD_HAS_ERROR } from '../constants/ActionTypes';

const defaultState = Map({
  isLoading: false,
  hasError: false,
  adData: null,
});

export default function ads(state = defaultState, action) {
  switch (action.type) {
    case SET_AD_IS_LOADING:
      return state.set('isLoading', action.isLoading);
    case SET_AD_HAS_ERROR:
      return state.set('hasError', action.hasError);
    case SET_AD_DATA:
      return state.set('adData', fromJS(action.data));
    default:
      return state;
  }
}
