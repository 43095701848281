import React from 'react';
import { useSelector } from 'react-redux';
import DefaultTripSummaryDetails from './TripSummaryDetails';
import TripSummaryDetailsFlat from './flat/TripSummaryDetails';

const TripSummaryDetails = (props) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const IS_FLAT = features.FUNNEL_STYLE === 'FLAT';
  const TripSummaryDetailsToRender = IS_FLAT ? TripSummaryDetailsFlat : DefaultTripSummaryDetails;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TripSummaryDetailsToRender {...props} />;
};

export default TripSummaryDetails;
