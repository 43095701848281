import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { buildSearchUrl } from 'utils/urls';
import NerbyTerminals from '../../../ui/atoms/NerbyTerminals';

const mapStateToProps = ({ search }) => {
  const origins = search.getIn(['nearTerminals', 'origins']);

  return {
    terminals: origins,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: (terminal) => {
      const { match } = ownProps;
      const { params } = match;
      const { departureDate, destinationSlug, returnDate, passengers = 'A1' } = params;

      const url = buildSearchUrl(terminal, destinationSlug, departureDate, returnDate, passengers);

      window.location.assign(`${url}?nearby=true`);
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NerbyTerminals));
