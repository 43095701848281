import React, { useState } from 'react';
import Amenities from 'components/modal/Amenities';
import OverlayView from 'components/OverlayView';
import TripSummaryDetails from 'components/purchase/TripSummaryDetails';
import PropTypes from 'prop-types';

const propTypes = {
  departureTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

const TicketItinerary = ({ departureTrip, returnTrip, roundTrip, isOpenTicket }) => {
  const [amenities, setAmenities] = useState(null);
  const showAmenities = (component, amenities) => setAmenities(amenities.amenities);
  const closeAmenities = () => setAmenities(null);

  return (
    <>
      <TripSummaryDetails
        departureTrip={departureTrip}
        returnTrip={returnTrip}
        roundTrip={roundTrip}
        showModal={showAmenities}
        showSeats={false}
        isOpenTicket={isOpenTicket}
      />
      <OverlayView title="amenities" visible={Boolean(amenities)} hideOverlay={closeAmenities}>
        {Boolean(amenities) && <Amenities amenities={amenities} hideModal={closeAmenities} />}
      </OverlayView>
    </>
  );
};

TicketItinerary.propTypes = propTypes;

export default TicketItinerary;
