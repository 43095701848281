import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text, Box, Button } from '@reservamos/elements';
import 'styles/components/PrivacyBanner';
import useSessionStorage from '../../hooks/useSessionStorage';

const PrivacyBanner = () => {
  const { t, i18n } = useTranslation('notifications');
  const { copies } = useSelector((state) => state.whitelabelConfig);
  const { noticeOfPrivacy } = copies.policies[i18n.language];
  const { provider } = copies;
  const [isClosed, setIsClosed] = useSessionStorage('privacy-banner-is-closed');
  const closeBanner = () => setIsClosed('true');

  if (isClosed === 'true') return null;

  return (
    <div className="privacy-banner">
      <Box bgColor="info" maxWidth>
        <div className="privacy-banner-content">
          <Text color="white" size="S" lineHeight="1.6em">
            {t('message.agree_to_the_use_of_cookies', { brand: provider.name })}{' '}
            <a href={noticeOfPrivacy.url} target="_blank">
              {noticeOfPrivacy.label}
            </a>
            .
          </Text>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <Button
            onClick={closeBanner}
            text={t('message.agree_to_the_use_of_cookies_button_label')}
            variant="accent"
          />
        </div>
      </Box>
    </div>
  );
};

export default PrivacyBanner;
