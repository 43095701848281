import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/PaymentStatusIcon';
import useWhitelabelTheme from '../../../hooks/whitelabel/useWhitelabelTheme';

/**
 * PaymentStatusIcon Component
 *
 * Component for displaying payment status icons based on the provided title status. It dynamically determines the appropriate icon to show, considering different payment status scenarios.
 *
 * @component
 * @param {string} titleStatus - The payment status title to determine the icon.
 *
 * @returns {JSX.Element} - The rendered PaymentStatusIcon component.
 */

function getIconName(titleStatus) {
  switch (titleStatus) {
    case 'pending':
    case 'process':
      return 'process';
    case 'pendingCompleted':
    case 'completed':
      return 'success';
    default:
      return titleStatus;
  }
}

const PaymentStatusIcon = ({ titleStatus }) => {
  const { paymentStatusIcons } = useWhitelabelTheme();
  const paymentIconName = getIconName(titleStatus);
  const customPaymentStatusIconSrc = paymentStatusIcons[paymentIconName];
  const isGif =
    customPaymentStatusIconSrc && customPaymentStatusIconSrc.toLowerCase().endsWith('.gif');
  const animatedIconClass = isGif ? 'payment-status-icon-animated' : 'payment-status-icon';
  return customPaymentStatusIconSrc ? (
    <img src={customPaymentStatusIconSrc} alt={paymentIconName} className={animatedIconClass} />
  ) : (
    <i className={`payment-status-icon-${paymentIconName}`} />
  );
};

PaymentStatusIcon.propTypes = {
  titleStatus: PropTypes.string.isRequired,
};

export default PaymentStatusIcon;
