import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text, Icon } from '@reservamos/elements';
import './MessageBox.scss';

// TODO move this component to elements

const MessageBox = ({ children, title, inlineIcon, icon }) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const brandSizes = {
    gfa: 'M',
    default: 'L',
  };

  const currentSize = brandSizes[env.brand] ?? brandSizes.default;

  return (
    <div className="message-box">
      {!inlineIcon && <Icon type={icon} size={currentSize} />}
      <div className="message-box-content">
        <div className="message-box-title">
          {inlineIcon && <Icon type={icon} />}
          <Text weight="semibold">{title}</Text>
        </div>
        {children}
      </div>
    </div>
  );
};

MessageBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  inlineIcon: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};

MessageBox.defaultProps = {
  inlineIcon: false,
};

export default MessageBox;
