import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import RouteStatus from 'components/RouteStatus';
import Header from 'components/Header';
import BrandLogo from './BrandLogo';
import 'styles/components/NotFound';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const NotFound = ({ history }) => {
  const { t } = useTranslation('');
  const goToHome = () => () => {
    history.push('/');
  };

  return (
    <RouteStatus code={404}>
      <div className="l-app l-error-page">
        <Header isFixed>
          <BrandLogo />
        </Header>
        <div className="not-found-container">
          <Spacing justifyContent="center" alignItems="center" vertical>
            <Text size="XL" weight="bold">
              {t('notifications:error.error_page_not_found')}
            </Text>
            <i className="no-results-image" />
          </Spacing>
          <button className="button-primary" onClick={goToHome()}>
            {t('general:go_back_to_home')}
          </button>
        </div>
      </div>
    </RouteStatus>
  );
};

NotFound.propTypes = propTypes;

export default NotFound;
