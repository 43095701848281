import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProviderSortSelector from './ProviderSortSelector';
import ProviderFilterSelector from './ProviderFilterSelector';
import 'styles/components/SearchSidebar';
import NavbarFilters from './search/NavbarFilters/NavbarFiltersComponent';
import { FiltersProvider } from '../context/FiltersContext';

/**
 * Component for the search sidebar.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onChangeSort - The function to be called when the sort option changes.
 * @param {Function} props.onChangeFilter - The function to be called when the filter option changes.
 * @param {Function} props.onResetFilters - The function to be called when the filters are reset.
 * @param {string} props.sort - The selected sort option.
 * @param {string} props.filter - The selected filter option.
 * @param {string} props.way - The direction of the trip.
 * @returns {JSX.Element} The rendered component.
 */
const SearchSidebar = ({ onChangeSort, onChangeFilter, sort, filter, way, onResetFilters }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const isNewFilters = features.NEW_FILTERS_VERSION;

  const { t } = useTranslation('search');
  return (
    <>
      <div className="sidebar-title">
        <p className="sidebar-title-copy">{t('label.sort_by')}</p>
      </div>
      <div className="search-sidebar">
        {isNewFilters ? (
          <FiltersProvider>
            <NavbarFilters
              onApplyFilters={onChangeFilter}
              scheduleFilters={filter}
              onChangeSort={onChangeSort}
              sort={sort}
              way={way}
              isProviderList
              onResetFilters={onResetFilters}
            />
          </FiltersProvider>
        ) : (
          <>
            <ProviderSortSelector onChange={onChangeSort} value={sort} way={way} />
            <p className="sidebar-title-copy">{t('label.filter_by_schedules')}</p>
            <ProviderFilterSelector onChange={onChangeFilter} value={filter} way={way} />
          </>
        )}
      </div>
    </>
  );
};

SearchSidebar.propTypes = {
  onChangeSort: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  sort: PropTypes.string,
  filter: PropTypes.string,
  way: PropTypes.string.isRequired,
  onResetFilters: PropTypes.func.isRequired,
};

SearchSidebar.defaultProps = {
  sort: null,
};

export default SearchSidebar;
