import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addTripDetails } from '@/actions/trip';
import parseTripDetails from 'utils/trips/parseTripDetails';
import store from '@/store';
import fetchTripPath from '../api/fetchTripPath';

/**
 * Hook to get the  indicated trip data.
 * @param {string} tripId - Indicates current trip id.
 * @returns {Object} currentTrip - contains all needed trip data for various purposes.
 * @returns {function} fetchTripDetails - function to call fetchTripDetails.
 * @returns {boolean} loading - state indicating the endpoint's call status.
 */
const useTripDetails = (tripId) => {
  const dispatch = useDispatch();

  // Get trip details from state
  const { tripsDetails } = store.getState();
  const { trips } = tripsDetails.toJS();
  const tripData = trips[tripId] || null;

  const [loading, setLoading] = useState(false);

  const fetchTripDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchTripPath(tripId);
      const tripDetail = parseTripDetails(response);
      dispatch(addTripDetails(tripId, tripDetail));
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }, [dispatch, tripId]);

  // validates if state has tripDetails data, if not fetchs it from api
  useEffect(() => {
    if (!tripData) {
      fetchTripDetails();
    }
  }, [tripData, fetchTripDetails, dispatch, tripId]);

  return { tripData, fetchTripDetails, loading };
};

export default useTripDetails;
