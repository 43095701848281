// @ts-check

import kushki from '../../../engines/kushki';

/**
 * @typedef {import('./PseStrategy').PseStrategy} PseStrategy
 */

/**
 * @function KushkiPseStrategy
 * @returns {PseStrategy}
 */
const KushkiPseStrategy = () => {
  const needsRedirect = true;

  const fetchBanksList = async () => {
    const banks = await kushki.fetchPseBanksList();
    return banks.map((bank) => ({
      id: bank.code,
      name: bank.name,
    }));
  };

  const createToken = async (config) => {
    const {
      bankId,
      personType,
      documentType,
      documentNumber,
      paymentDescription,
      email,
      total,
      currency,
      callbackUrl,
    } = config;
    const payload = {
      bankId,
      callbackUrl,
      userType: personType,
      documentType,
      documentNumber,
      paymentDesc: paymentDescription,
      email,
      currency,
      amount: {
        subtotalIva0: total,
        subtotalIva: 0,
        iva: 0,
      },
    };
    const token = await kushki.createTransferToken(payload);
    return token;
  };

  return {
    fetchBanksList,
    createToken,
    needsRedirect,
  };
};

const kushkiPseStrategy = KushkiPseStrategy();

export default kushkiPseStrategy;
