import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '@reservamos/elements';
import ProviderAmenities from './ProviderAmenities';
import 'styles/components/TripItinerary/ProviderDetails';

const propTypes = {
  amenities: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

const ProviderDetails = ({ amenities, showModal, isOpenTicket }) => {
  const { t } = useTranslation('general');
  if (!amenities.some(({ services }) => services && services.length)) return null;

  return (
    <div className="detail-amenities">
      <Text>{t('amenities_and_services')}</Text>

      {amenities.map(({ provider, services }, index) => {
        const amenityKey = `${provider.id}-${index}`;

        return (
          <ProviderAmenities
            key={amenityKey}
            provider={provider}
            amenities={services}
            showModal={showModal}
            isOpenTicket={isOpenTicket}
          />
        );
      })}
    </div>
  );
};

ProviderDetails.propTypes = propTypes;

export default ProviderDetails;
