import { useSelector } from 'react-redux';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import filtersTypes from '../constants/filtersTypes';

const { TRIP_OPTIONS } = filtersTypes;

const isObject = (obj) => {
  return typeof obj === 'object' && obj !== null;
};

/**
 * Hook to get the available trip options
 * @param {String} way - Way of the trips to filter
 * @returns Array
 */
const useAvailableTripOptions = (way) => {
  const state = useSelector((state) => state);
  const features = useWhitelabelFeatures();
  const { search, trips } = state;
  const searchId = search.getIn([way, 'id']);
  const tripsList = trips.getIn([searchId, 'buses', 'trips']);
  const tripListFiltered = trips
    .getIn([searchId, 'buses', 'trips'])
    .filter((t) => !t.get('openTicket'))
    .toJS();

  if (!tripsList || !tripListFiltered.length) return [];

  const tripOptions = features.TRIP_OPTIONS_FILTER.map((option) => {
    const hasAvailability = Boolean(
      tripListFiltered.some((trip) => {
        /* Get the trip property from option.value key. I.e: value: {property:value} */
        const [property] = Object.keys(option.value);
        const value = option.value[property];
        /* If one is true then we have availability */
        if (
          property &&
          (trip[property] === value || (isObject(trip[property]) && trip[property].includes(value)))
        ) {
          return true;
        }
        return false;
      }),
    );

    return {
      ...option,
      hasAvailability,
      filterType: TRIP_OPTIONS,
    };
  });

  return tripOptions;
};

export default useAvailableTripOptions;
