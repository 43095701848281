import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class DepartureFilter extends Component {
  componentDidMount() {
    document.querySelector('.modal').classList.add('modal-view-options');
  }

  componentWillUnmount() {
    document.querySelector('.modal').classList.remove('modal-view-options');
  }

  select(selected) {
    const { hideModal, onFilterSelect } = this.props;
    onFilterSelect('departureTime', selected);
    hideModal();
    window.scrollTo(0, 0);
  }

  render() {
    const { activeDeparture, floatingOptions, t } = this.props;
    const isChecked = (active) => active === activeDeparture;

    return (
      <div className="modal-arrow-container departure" onClick={(e) => e.stopPropagation()}>
        <form className="modal-content floating-sort-menu">
          <div className="modal-row row-morning" onClick={() => this.select('morning')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="radio" defaultChecked={isChecked('morning')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_morning')}</h4>
              <p>7:00 am - 11:59 am</p>
            </div>
          </div>

          <div className="modal-row row-afternoon" onClick={() => this.select('afternoon')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="radio" defaultChecked={isChecked('afternoon')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_afternoon')}</h4>
              <p>12:00 pm - 6:59 pm</p>
            </div>
          </div>

          <div className="modal-row row-night" onClick={() => this.select('night')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="radio" defaultChecked={isChecked('night')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_night')}</h4>
              <p>7:00 pm - 11:59 pm</p>
            </div>
          </div>

          <div className="modal-row row-earlymorning" onClick={() => this.select('earlymorning')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="radio" defaultChecked={isChecked('earlymorning')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_earlymorning')}</h4>
              <p>12:00 am - 6:59 am</p>
            </div>
          </div>

          <div className="modal-row" onClick={() => this.select('none')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="radio" defaultChecked={isChecked('none')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_without_filters')}</h4>
              <p>{t('label.description.selector_without_filters')}</p>
            </div>
          </div>
        </form>

        <div className={`arrow-down options-${floatingOptions}`} />
      </div>
    );
  }
}

DepartureFilter.propTypes = {
  activeDeparture: PropTypes.string.isRequired,
  floatingOptions: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(DepartureFilter);
