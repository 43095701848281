function validate(validator, response) {
  if (validator instanceof Array) {
    return validator.includes(response.status);
  }
  return validator(response);
}

/**
 * @param { PollInfo } pollInfo
 */
export default function pollingPromises(pollInfo) {
  const {
    data,
    create,
    name,
    finishedStatus = ['finished'],
    failedStatus = ({ status }) => status !== 'pending' && status !== 'finished',
  } = pollInfo;

  const createPolling = (payload) =>
    new Promise((resolve, reject) => {
      const polling = {
        [name]: (response) => {
          if (validate(failedStatus, response)) {
            reject(response);
          } else if (validate(finishedStatus, response)) {
            resolve(response);
          }
        },
      };

      const promise = create(polling, payload);
      if (promise instanceof Promise) {
        promise.catch(reject);
      }
    });

  return data instanceof Array ? data.map(createPolling) : createPolling(data);
}
