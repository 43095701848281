/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { OutlineButton, Text, Visibility, Currency } from '@reservamos/elements';
import PropTypes from 'prop-types';
import '../../ui/layouts/FaresResults';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
/* import { useTranslation } from 'react-i18next'; */
import FaresModal from './FaresModal';

const FareItem = ({ id, code, availability, total, selectFare }) => {
  /*   const { t } = useTranslation(); */
  const isEco = code === 'ECO';
  const className = isEco ? 'fares-item discount' : 'fares-item';
  const onClick = () => selectFare({ key: id, code, availability, total });

  return (
    <div className={className}>
      <Visibility type="showForMobileOnly">
        <FaresModal title={code} />
      </Visibility>
      <Visibility type="hideForMedium">
        <Text size="S" color="accent" italic>
          6 disponibles{/* {t('search:Fare Availability', { count: availability })} */}
        </Text>
      </Visibility>
      <div className="price">
        <Currency
          size="XL"
          price={total}
          responsiveSize="XXL"
          color={isEco ? 'discount' : 'primary'}
          weight="bold"
          decimals={0}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
        />
        <Text color={isEco ? 'discount' : 'primary'} size="S">
          MXN
        </Text>
      </div>
      <OutlineButton
        text="Elegir"
        isRounded
        variant={isEco ? 'discount' : 'primary'}
        onClick={onClick}
      />
    </div>
  );
};

FareItem.propTypes = {
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  selectFare: PropTypes.func.isRequired,
};

export default FareItem;
