import React from 'react';
import PropTypes from 'prop-types';
import { Spacing } from '@reservamos/elements';
import SeatsAlert from 'components/seats/SeatsAlert';

const CheckoutNavigation = ({ leftContent, children }) => {
  return (
    <Spacing vertical>
      <SeatsAlert scrollToAlert />
      <Spacing justifyContent="space-between" isResponsive>
        {leftContent || <div />}
        {children}
      </Spacing>
    </Spacing>
  );
};

CheckoutNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  leftContent: PropTypes.node,
};

CheckoutNavigation.defaultProps = {
  leftContent: null,
};

export default CheckoutNavigation;
