import React from 'react';
import PropTypes from 'prop-types';
import { When } from 'react-if';
import { Spacing } from '@reservamos/elements';
import { useSelector } from 'react-redux';
import prepareTicketsPricing from 'utils/purchase/prepareTicketsPricing';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import PricingRow from '../../ui/atoms/PricingRow';
import wayIsOpenTicket from '../../utils/wayIsOpenTicket';
import discountGFA from '../../images/brands-unique/gfa/early-discount.png';
import discountETN from '../../images/brands-unique/etn/discountETN.png';

const earlyDiscountIcons = {
  etn: discountETN,
  gfa: discountGFA,
  default: 'emojiSparkles',
};

const propTypes = {
  reservation: PropTypes.shape({
    fragments: PropTypes.array.isRequired,
    pricing: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      providerDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      providerDiscountPercent: PropTypes.number,
      providerDiscountReason: PropTypes.string,
      taxes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      serviceFees: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      breakdown: PropTypes.shape(),
    }).isRequired,
    transportType: PropTypes.string.isRequired,
  }).isRequired,
  passengers: PropTypes.array.isRequired,
  passengerCount: PropTypes.number.isRequired,
  totalLabel: PropTypes.string.isRequired,
  displayPricingBeforeCheckout: PropTypes.bool,
  passengersCountBeforeCheckout: PropTypes.number,
  exchangedTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pricingBeforeCheckout: PropTypes.shape({
    amount: PropTypes.number,
    discount: PropTypes.number,
    total: PropTypes.number,
    luggage: PropTypes.number,
    taxes: PropTypes.number,
    networks: PropTypes.number,
    insurance: PropTypes.number,
  }),
};

/**
 * RowComponent component.
 * Render a pricing row item.
 *
 * @param {object} props - label, discountLabel, price, hasDiscount, isMain, icon, primary, tooltipContent.
 * @param {string} props.label - The label for the pricing row.
 * @param {string} [props.discountLabel] - The discount label for the pricing row.
 * @param {number|string} props.price - The price for the pricing row.
 * @param {boolean} [props.hasDiscount] - Indicates if the pricing row has a discount.
 * @param {boolean} [props.isMain] - Indicates if the pricing row is the main row.
 * @param {string} [props.icon] - The icon for the pricing row.
 * @param {boolean} [props.primary] - Indicates if the pricing row is primary.
 * @param {string} [props.tooltipContent] - The tooltip content for the pricing row.
 */
const RowComponent = ({
  label,
  discountLabel,
  price,
  hasDiscount,
  isMain,
  icon,
  primary,
  tooltipContent,
}) => {
  return (
    <PricingRow
      label={label}
      discountLabel={discountLabel}
      isMain={isMain}
      hasDiscount={hasDiscount}
      price={price}
      sign={getCurrencyPrefix()}
      currency={getCurrencySuffix()}
      primary={primary}
      icon={icon}
      tooltipContent={tooltipContent}
    />
  );
};

RowComponent.propTypes = {
  label: PropTypes.string.isRequired,
  discountLabel: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasDiscount: PropTypes.bool,
  isMain: PropTypes.bool,
  icon: PropTypes.string,
  primary: PropTypes.bool,
  tooltipContent: PropTypes.string,
};

/**
 * PurchasePricingTrip component.
 * Render the pricing details for a trip purchase.
 *
 * @param {object} props - The props object.
 * @param {object} props.reservation - The reservation details.
 * @param {array} props.passengers - The list of passengers.
 * @param {number} props.passengerCount - The number of passengers.
 * @param {string} props.totalLabel - The label for the total amount.
 * @param {boolean} props.displayPricingBeforeCheckout - Indicates if pricing should be displayed before checkout.
 * @param {number} props.passengersCountBeforeCheckout - The number of passengers before checkout.
 * @param {object} props.pricingBeforeCheckout - The pricing data before checkout.
 * @param {number} props.exchangedTotal - The total amount after exchange.
 */
const PurchasePricingTrip = ({
  reservation,
  passengers,
  passengerCount,
  totalLabel,
  displayPricingBeforeCheckout,
  passengersCountBeforeCheckout,
  pricingBeforeCheckout,
  exchangedTotal,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { brand } = useWhitelabelEnvs();
  const breakdownValuesThatCanBeDisplayedAtCero =
    env.theme.pricing?.breakdownValuesThatCanBeDisplayedAtCero || [];

  const { transportType, fragments, pricing } = reservation;
  const {
    providerDiscountReason,
    providerDiscountPercent,
    providerDiscount,
    taxes,
    serviceFees,
    amount,
    total,
    breakdown,
  } = pricing;
  const {
    equipaje: luggage,
    impuestos: taxesFromBreakdown,
    redes: networks,
    seguros: insurance,
    toll,
    boardingTax,
    managementCosts,
  } = breakdown || {
    equipaje: 0,
    impuestos: 0,
    redes: 0,
    seguros: 0,
    toll: 0,
    boardingTax: 0,
    managementCosts: 0,
  };
  const taxesToRender = taxes > 0 ? taxes : taxesFromBreakdown;

  const pricingToRender = displayPricingBeforeCheckout
    ? {
        ...pricingBeforeCheckout,
        passengerCount: passengersCountBeforeCheckout,
      }
    : {
        discount: providerDiscount,
        amount,
        total,
        passengerCount,
        luggage,
        taxes: taxesToRender,
        networks,
        insurance,
        serviceFees,
        toll,
        boardingTax,
        managementCosts,
      };

  const ticketsPassengersCount = pricingToRender.passengerCount;
  const ticketsAmount = pricingToRender.amount;
  const tickets = prepareTicketsPricing(
    transportType,
    fragments,
    passengers,
    ticketsPassengersCount,
    ticketsAmount,
    displayPricingBeforeCheckout,
  );

  const hasEarlyDiscount = providerDiscountReason === 'compra anticipada';
  const isOpenTicket = wayIsOpenTicket(reservation);
  const showPriceOutTaxes = features.SHOW_TICKET_PRICE_WITHOUT_TAX;
  const groupIncludedFees = features.GROUP_INCLUDED_FEES;

  return (
    <div className="section-content">
      <Spacing vertical size="S">
        {tickets.map((item) => {
          let categoryText = `${item.count} ${t(`passengers:type.${item.category}`, {
            context: brand,
          })}`;

          const toll = (item.toll ?? pricingToRender.toll) * item.count;
          const boardingTax = (item.boardingTax ?? pricingToRender.boardingTax) * item.count;
          const managementCosts =
            (item.managementCosts ?? pricingToRender.managementCosts) * item.count;
          const priceOutTaxes = item.total - toll - boardingTax - managementCosts;
          const price = showPriceOutTaxes ? priceOutTaxes : item.total;

          if (isOpenTicket) categoryText = `${categoryText} - ${t('general:open_ticket')}`;
          return <RowComponent key={item.category} label={categoryText} price={price} />;
        })}

        <When condition={providerDiscount > 0 && hasEarlyDiscount}>
          <RowComponent
            label={t('trips:early_discount')}
            price={pricingToRender.discount}
            hasDiscount
            primary
            icon={earlyDiscountIcons[env.brand] ?? earlyDiscountIcons.default}
          />
        </When>
        <When condition={providerDiscount > 0 && !hasEarlyDiscount}>
          {features.PURCHASE_PRICING_DETAILED_DISCOUNT ? (
            <RowComponent
              label={t('purchase:label.your_discount_savings', {
                reason: providerDiscountReason,
              })}
              discountLabel={t('purchase:label.maximum_discount_percentage', {
                percent: providerDiscountPercent,
              })}
              price={pricingToRender.discount}
              hasDiscount
            />
          ) : (
            <RowComponent
              label={t('label.discount', { context: env.brand })}
              price={pricingToRender.discount}
              hasDiscount
            />
          )}
        </When>

        <When
          condition={
            pricingToRender.luggage > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('luggage')
          }
        >
          <RowComponent label={t('luggage')} price={pricingToRender.luggage} />
        </When>

        <When
          condition={
            pricingToRender.toll > 0 || breakdownValuesThatCanBeDisplayedAtCero.includes('toll')
          }
        >
          <RowComponent label={t('toll')} price={pricingToRender.toll} />
        </When>

        <When
          condition={
            pricingToRender.boardingTax > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('boardingTax')
          }
        >
          <RowComponent label={t('boardingTax')} price={pricingToRender.boardingTax} />
        </When>

        <When
          condition={
            pricingToRender.networks > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('networks')
          }
        >
          <RowComponent label={t('networks')} price={pricingToRender.networks} />
        </When>

        <When
          condition={
            pricingToRender.insurance > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('insurance')
          }
        >
          <RowComponent label={t('insurance')} price={pricingToRender.insurance} />
        </When>

        <When
          condition={
            pricingToRender.taxes > 0 || breakdownValuesThatCanBeDisplayedAtCero.includes('taxes')
          }
        >
          <RowComponent
            label={t('extra_price', { context: env.brand })}
            price={pricingToRender.taxes}
          />
        </When>

        {groupIncludedFees ? (
          <RowComponent
            label={t('label.included_fees')}
            price={
              parseFloat(pricingToRender.serviceFees) + parseFloat(pricingToRender.managementCosts)
            }
            tooltipContent={t('tooltip.included_fees')}
          />
        ) : (
          <>
            <When
              condition={
                pricingToRender.serviceFees > 0 ||
                breakdownValuesThatCanBeDisplayedAtCero.includes('serviceFees')
              }
            >
              <RowComponent label={t('label.service_charge')} price={pricingToRender.serviceFees} />
            </When>

            <When
              condition={
                pricingToRender.managementCosts > 0 ||
                breakdownValuesThatCanBeDisplayedAtCero.includes('managementCosts')
              }
            >
              <RowComponent label={t('managementCosts')} price={pricingToRender.managementCosts} />
            </When>
          </>
        )}

        <RowComponent label={t(`label.${totalLabel}`)} price={pricingToRender.total} isMain />

        <When condition={exchangedTotal > 0}>
          <RowComponent label={t('previousTicketsTotal')} price={exchangedTotal} isMain />
        </When>
      </Spacing>
    </div>
  );
};

PurchasePricingTrip.propTypes = propTypes;

export default PurchasePricingTrip;
