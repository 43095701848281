import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/Helpers';
import { getCurrencySuffix } from 'utils/currency';
import PropTypes from 'prop-types';
import { LoaderPulse, Text } from '@reservamos/elements';
import './CompleteHeader.scss';
import { When } from 'react-if';

/* eslint-disable valid-jsdoc */
const CompleteHeader = ({ title, status, total = 0 }) => {
  const { t } = useTranslation('payment');
  const totalFormatted = `${formatCurrency(total, 2)} ${getCurrencySuffix()}`;
  const isPendingCompleted = status === 'pendingCompleted';
  const isNotFailed = status !== 'failed';
  const isInProcess = status === 'process';

  return (
    <div className={`complete-header complete-header-${status}`}>
      <div className="complete-header-overlay">
        <div className="l-container">
          <Text size="XXL" mobileSize="XL" weight="bold" color="white">
            {title}
          </Text>
          <When condition={isPendingCompleted}>
            <LoaderPulse color="white" hasWrapper={false} />
          </When>
          <When condition={isNotFailed && !isPendingCompleted && !isInProcess}>
            <Text size="XL" mobileSize="L" color="white">
              {t('payment_subtitle', { context: status, total: totalFormatted })}
            </Text>
          </When>
        </div>
      </div>
    </div>
  );
};

CompleteHeader.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['success', 'error', 'pending']).isRequired,
};

export default CompleteHeader;
