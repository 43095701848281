import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Toggle, Visibility } from '@reservamos/elements';
import SeatSelector from 'components/purchase/SeatSelector';
import usePurchase from 'hooks/store/usePurchase';
import { getSeatSelectorPropsFromPurchase } from 'utils/seats';

/**
 * Returns a string with the trip route label
 * this label is composed by the origin and destination names
 * and it looks like this: "Origin - Destination"
 *
 * @param {*} trip
 * @returns the trip route label
 *
 * @example
 *
 * ```js
 *   const firstTrip = trips[0];
 *   const firstTripRouteLabel = getTripRouteLabel(firstTrip);
 *   console.log(firstTripRouteLabel); // "Belem - Florianopolis"
 * ```
 */
function getTripRouteLabel(trip) {
  const tripOriginName = trip.terminals[trip.trip.originId].name;
  const tripDestinationName = trip.terminals[trip.trip.destinationId].name;
  const tripRouteLabel = `${tripOriginName} - ${tripDestinationName}`;
  return tripRouteLabel;
}

const propTypes = {
  way: PropTypes.oneOf(['departure', 'return']).isRequired,
  selectSeats: PropTypes.func.isRequired,
  finishSeatSelection: PropTypes.func.isRequired,
};

/**
 * Renders seat selector for trips with connections.
 * It supports only one connection (two legs trip).
 *
 * @todo
 * - Add support for more than one connection (when needed).
 */
const MultiTripSeatSelector = ({ way, selectSeats, finishSeatSelection }) => {
  const purchase = usePurchase();
  const { t } = useTranslation('seats');
  const {
    departs: {
      layouts: departureLayouts = {},
      seats: departureSeats = {},
      trips: departureTripsState = [],
      tickets: departureTickets = [],
    },
    returns: {
      layouts: returnLayouts = {},
      seats: returnSeats = {},
      trips: returnTripsState = [],
      tickets: returnTickets = [],
    },
  } = purchase;
  const seats = way === 'departure' ? departureSeats : returnSeats;
  const layouts = way === 'departure' ? departureLayouts : returnLayouts;
  const lockedSeats = way === 'departure' ? departureTickets : returnTickets;
  const tripsState = way === 'departure' ? departureTripsState : returnTripsState;
  const trips = tripsState.map((trip) => ({ ...trip, id: trip.trip.id }));
  const [currentTrip, setCurrentTrip] = useState(trips[0]);
  const {
    sendNotification,
    updateSeat,
    isExchange,
    busCategories,
    isLoading,
    allowsSeatSelection,
    passengers,
    diagramType,
    isLockingTickets = false,
    fetchingDetails = true,
    selectedSeats = [],
    isOpenTicket,
    canGoBack,
    tripPricing,
    hasSeats,
    resultsLayout,
    onClose,
    clearError,
    fetchingDetailsError,
    minPassengers,
  } = getSeatSelectorPropsFromPurchase({ way, purchase });
  const loading = !allowsSeatSelection || isLockingTickets || !hasSeats || isLoading;

  const onSelectTripSeats = (tripSlug) => (tripSeats) => {
    const newSelectedSeats = selectedSeats.filter((seat) => seat.tripSlug !== tripSlug);
    newSelectedSeats.push(...tripSeats);
    selectSeats(newSelectedSeats);
  };

  const firstTrip = trips[0];
  const secondTrip = trips[1];
  const firstTripSelectedSeats = selectedSeats.filter((seat) => seat.tripSlug === firstTrip.id);
  const secondTripSelectedSeats = selectedSeats.filter((seat) => seat.tripSlug === secondTrip.id);
  const firstTripHasSelectedSeats = firstTripSelectedSeats.length > 0;
  const secondTripHasSelectedSeats = secondTripSelectedSeats.length > 0;
  const firstTripRouteLabel = getTripRouteLabel(firstTrip);
  const secondTripRouteLabel = getTripRouteLabel(secondTrip);

  /**
   *
   * Handles finish seat selection for the current trip leg.
   * It takes in mind only two legs trips.
   *
   * - For the firt trip:
   *  - Makes sure user has selected seats.
   *  - Removes the extra selected seats from the second trip, if any.
   *     (Second trip cannot have more selected seats than the first trip).
   *  - Sets the second trip as the current trip.
   * - For the second trip:
   *  - Makes sure user has selected seats.
   *  - Finishes the seat selection process.
   *
   * @param {H} isFirstTrip
   *
   */
  const onFinishSeatSelection =
    (isFirstTrip = false) =>
    () => {
      if (isFirstTrip && firstTripHasSelectedSeats) {
        const invalidSecondTripSeatsLength =
          secondTripSelectedSeats.length > firstTripSelectedSeats.length;
        if (invalidSecondTripSeatsLength) {
          onSelectTripSeats(trips[1].id)(
            secondTripSelectedSeats.slice(0, firstTripSelectedSeats.length),
          );
        }
        setCurrentTrip(trips[1]);
        return;
      }
      if (firstTripHasSelectedSeats && secondTripHasSelectedSeats) {
        finishSeatSelection();
      }
    };

  /**
   * Handles toggle trip leg selection for the toggle component.
   *
   * Supports only two legs trips. Handles toggle differently depending on what leg is active.
   *
   * - If the first trip is active, it delegates
   *   to onFinishSeatSelection in order to apply its verification logic and set the second trip as the current trip.
   * - If the second trip is active, it sets the first trip as the current trip.
   */
  const onToggleCurrentTrip = () => {
    const firstTripIsActive = currentTrip.id === trips[0].id;
    if (firstTripIsActive) {
      onFinishSeatSelection(true)();
      return;
    }
    setCurrentTrip(trips[0]);
  };

  const firstTripIsActive = currentTrip.id === trips[0].id;
  const secondTripIsActive = currentTrip.id === trips[1].id;

  return (
    <Spacing vertical size="M">
      <Toggle
        isChecked={secondTripIsActive}
        onClick={onToggleCurrentTrip}
        fullWidth
        alphaBg="S"
        isGradient={false}
        firstContent={
          <Spacing size="S" isResponsive>
            <Text size="S" weight="bold" color={firstTripIsActive ? 'grayStrong' : 'grayLight'}>
              {t('first_connection')}
            </Text>
            <Visibility type="hideForMedium">
              <Text color={firstTripIsActive ? 'grayStrong' : 'grayLight'} size="S">
                {firstTripRouteLabel}
              </Text>
            </Visibility>
          </Spacing>
        }
        secondContent={
          <Spacing size="S" isResponsive>
            <Text size="S" weight="bold" color={secondTripIsActive ? 'grayStrong' : 'grayLight'}>
              {t('second_connection')}
            </Text>
            <Visibility type="hideForMedium">
              <Text color={secondTripIsActive ? 'grayStrong' : 'grayLight'} size="S">
                {secondTripRouteLabel}
              </Text>
            </Visibility>
          </Spacing>
        }
      />
      {trips.map((trip, index) => {
        const selectedTripSeats = selectedSeats.filter((seat) => seat.tripSlug === trip.id);
        const tripLockedSeats = lockedSeats.filter((seat) => seat.tripSlug === trip.id);
        const tripIsActive = currentTrip.id === trip.id;
        const isFirstTrip = index === 0;
        const originName = trip.terminals[trip.trip.originId].name;
        const destinationName = trip.terminals[trip.trip.destinationId].name;
        const routeLabel = `${originName} - ${destinationName}`;

        /**
         * minPassengers is the minimum number of seats that the user must select for the trip.
         * getSeatSelectorPropsFromPurchase returns this value based on the way (departure or return),
         * but it only considers trips without connections. (one leg trips only)
         *
         * For trips with connections, the value of minPassengers works for:
         *  - The departure first leg. (first trip)
         *  - The return trip (for both legs)
         *
         * So, for the departure second leg, we need to calculate the minPassengers value based on the first leg selected seats.
         * this value is stored in departureTripSecondLegMinPassengers.
         */
        let tripMinPassengers = minPassengers;
        const isDepartureTripSecondLeg = way === 'departure' && !isFirstTrip;
        if (isDepartureTripSecondLeg) {
          const departureTripSecondLegMinPassengers = firstTripSelectedSeats.length;
          tripMinPassengers = departureTripSecondLegMinPassengers;
        }

        return tripIsActive ? (
          <SeatSelector
            isMultiTrip
            firstTripIsActive={isFirstTrip && tripIsActive}
            secondTripIsActive={!isFirstTrip && tripIsActive}
            routeLabel={routeLabel}
            headerLegend={
              isFirstTrip ? t('your_seats_first_connection') : t('your_seats_second_connection')
            }
            key={trip.id}
            way={way}
            tripId={trip.id}
            layout={layouts[trip.id]}
            seats={seats[trip.id]}
            trip={trip}
            allowsSeatSelection={allowsSeatSelection}
            diagramType={diagramType}
            fetchingDetails={fetchingDetails}
            isPetFriendly={false}
            lockedSeats={tripLockedSeats}
            selectedSeats={selectedTripSeats}
            selectSeats={onSelectTripSeats(trip.id)}
            onFinishedSelection={onFinishSeatSelection(isFirstTrip)}
            sendNotification={sendNotification}
            isOpenTicket={isOpenTicket}
            isExchange={isExchange}
            passengers={isExchange && passengers}
            busCategories={busCategories}
            minPassengers={tripMinPassengers}
            updateSeat={updateSeat}
            isLoading={loading}
            canGoBack={canGoBack}
            tripPricing={tripPricing}
            resultsLayout={resultsLayout}
            onClose={onClose}
            hasSeats={hasSeats}
            clearError={clearError}
            fetchingDetailsError={fetchingDetailsError}
          />
        ) : null;
      })}
    </Spacing>
  );
};

MultiTripSeatSelector.propTypes = propTypes;

export default MultiTripSeatSelector;
