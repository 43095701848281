const busItinerary = (
  {
    transportType,
    origin,
    destination,
    departure,
    arrival,
    innerCityConnection,
    line,
    ticketPdfUrl,
    destinationAddress,
    originAddress,
  },
  showTickets,
) => {
  const { name, copyrightProtected, logoUrl, redirectionInfo } = line;
  const siteUrl = redirectionInfo && redirectionInfo.mobile.shortUrl;
  return [
    {
      transportType,
      departure,
      arrival,
      provider: {
        name,
        copyrightProtected,
        logoUrl,
        siteUrl,
      },
      origin: {
        ...origin,
        address: originAddress,
      },
      destination: {
        ...destination,
        address: destinationAddress,
      },
      innerCityConnection,
      ticketUrl: ticketPdfUrl,
      showTickets,
    },
  ];
};

const flightItinerary = ({ legs, innerCityConnection }, showTickets) =>
  legs.map((leg, index) => {
    const {
      transportType,
      departure,
      arrival,
      flightCode,
      origin,
      destination,
      carrier: { name, ally, logoUrl },
    } = leg;
    const lastLeg = index === legs.length - 1;

    return {
      transportType,
      departure,
      arrival,
      flightCode,
      showTickets,
      provider: {
        name,
        ally,
        logoUrl,
      },
      origin: {
        cityName: origin.cityName,
        name: origin.name,
      },
      destination: {
        cityName: destination.cityName,
        name: destination.name,
      },
      innerCityConnection: lastLeg ? innerCityConnection : null,
    };
  });

const mixedItinerary = (trip, showTickets) => {
  const itinerary = [];

  trip.fragments.forEach((fragment) => {
    if (fragment.transportType === 'bus') {
      itinerary.push(...busItinerary(fragment, showTickets));
    } else {
      itinerary.push(...flightItinerary(fragment, showTickets));
    }
  });

  return itinerary;
};

export default (trip, showTickets = false) => {
  const { transportType } = trip;
  let fragments;

  switch (transportType) {
    case 'bus':
      fragments = busItinerary(trip, showTickets);
      break;
    case 'flight':
      fragments = flightItinerary(trip, showTickets);
      break;
    case 'mixed':
      fragments = mixedItinerary(trip, showTickets);
      break;
    default:
      fragments = [];
  }

  return {
    transportType,
    fragments,
  };
};
