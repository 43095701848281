import React from 'react';
import PropTypes from 'prop-types';
import PurchaseFare from './PurchaseFare';

const PurchaseFares = ({ departsFares, returnsFares }) => {
  const isRoundTrip = Boolean(returnsFares);

  return (
    <>
      {departsFares.map(({ fare }) => (
        <PurchaseFare key={fare.code} code={fare.code} isRoundTrip={isRoundTrip} />
      ))}
      {isRoundTrip &&
        Boolean(returnsFares.length) &&
        returnsFares.map(({ fare }) => (
          <PurchaseFare key={fare.code} code={fare.code} isReturn isRoundTrip={isRoundTrip} />
        ))}
    </>
  );
};

PurchaseFares.propTypes = {
  departsFares: PropTypes.any,
  returnsFares: PropTypes.any,
};

export default PurchaseFares;
