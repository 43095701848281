export default (purchase, { tripSlug, way }) => {
  if (way === 'departs') {
    const purchaseDeparture = purchase.getIn(['departs', 'tripSlug']);
    return tripSlug === purchaseDeparture;
  }

  if (way === 'returns') {
    const purchaseReturns = purchase.getIn(['returns', 'tripSlug']);
    return tripSlug === purchaseReturns;
  }
};
