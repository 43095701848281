import { Map, fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';

const defaultState = Map({
  isLoading: false,
  agency: null,
});

export default function agency(state = defaultState, action) {
  switch (action.type) {
    case types.AGENCY_SET_SESSION:
      return state.set('agency', fromJS(action.agency)).set('user', fromJS(action.user));
    case types.AGENCY_SET_LOADING:
      return state.set('isLoading', action.isLoading);
    default:
      return state;
  }
}
