// @ts-check

/**
 * @typedef {import('./PixStrategy').PixStrategy} PixStrategy
 */

/**
 * Cielo Pix strategy.
 * @function CieloPixStrategy
 * @returns {PixStrategy}
 */
const CieloPixStrategy = () => {
  const needsRedirect = false;

  /**
   * Creates a token for Pix payment.
   * @returns {Promise<string>} The created token.
   */
  const createToken = async () => {
    // Cielo does not need to create a token in order to process a Pix payment
    return '';
  };

  return {
    needsRedirect,
    createToken,
  };
};

const cieloPixStrategy = CieloPixStrategy();

export default cieloPixStrategy;
