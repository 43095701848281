import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Gap, Icon, Text, Spacing, Box } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import filtersTypes from '../../../constants/filtersTypes';

const { SORT_BY } = filtersTypes;

/**
 * Component for rendering a sort option.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the sort option.
 * @param {string} props.title - The title of the sort option.
 * @param {string} props.icon - The icon of the sort option.
 * @param {string} props.value - The value of the sort option.
 * @param {boolean} props.isActive - Indicates whether the sort option is active or not.
 * @param {Function} props.onSelect - The function to be called when the sort option is selected.
 * @returns {JSX.Element} The rendered component.
 */
const SortOption = ({ id, title, icon, value, isActive, onSelect }) => {
  /**
   * Handles the selection of the sort option.
   */
  const handleOnSelect = () => {
    onSelect({ filterType: SORT_BY, value });
  };

  return (
    <RadioButton id={id} isRounded isActive={isActive} onChange={handleOnSelect}>
      <Gap alignItems="center" columnGap="XXS">
        <Icon type={icon} size="S" />
        <Text weight="bold" color={isActive ? 'white' : 'grayStrong'}>
          {title}
        </Text>
      </Gap>
    </RadioButton>
  );
};

SortOption.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
};

/**
 * Component for rendering a list of sort options.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.options - The array of sort options to be rendered.
 * @param {string} props.sortBy - The selected sort option.
 * @param {Function} props.onSelectOption - The function to be called when a sort option is selected.
 * @returns {JSX.Element} The rendered component.
 */
const SortOptionList = ({ options, sortBy, onSelectOption }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('search');

  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  return (
    <Spacing vertical>
      <Spacing
        justifyContent={isFlat ? 'space-between' : 'flex-start'}
        alignItems="center"
        responsiveSize="S"
      >
        {!isFlat && (
          <Box bgColor="primary" borderRadius="L" paddingVertical="S" paddingHorizontal="XS">
            <Spacing alignItems="center">
              <Icon type="OrderBy" color="white" size="S" />
            </Spacing>
          </Box>
        )}
        <Gap>
          <Text weight="bold" size="XL">
            {t('label.sort_by')}
          </Text>
        </Gap>
        {isFlat && (
          <Box bgColor="primary" borderRadius="L" paddingVertical="S" paddingHorizontal="S">
            <Spacing alignItems="center">
              <Icon type="OrderBy" color="white" size="S" />
            </Spacing>
          </Box>
        )}
      </Spacing>
      <Gap columnGap="M" rowGap="S">
        {options.map((option) => (
          <SortOption
            key={option.value}
            id={option.value}
            title={option.title}
            icon={option.icon}
            value={option.value}
            isActive={sortBy === option.value}
            onSelect={onSelectOption}
          />
        ))}
      </Gap>
    </Spacing>
  );
};

SortOptionList.propTypes = {
  options: PropTypes.array,
  sortBy: PropTypes.string,
  onSelectOption: PropTypes.func,
};

export default SortOptionList;
