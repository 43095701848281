import React from 'react';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const EfectyForm = () => {
  const { t } = useTranslation('payment');

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <PaymentImage type="efecty" />

        <Text size="M" weight="bold">
          {t('efecty_instructions.first')}
        </Text>

        <Text size="S">{t('efecty_instructions.second')}</Text>

        <Text size="S" italic>
          {t('efecty_instructions.third')}
        </Text>
      </Spacing>
    </div>
  );
};

export default EfectyForm;
