import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text } from '@reservamos/elements';
import { formatCurrency } from 'utils/Helpers';
import InstallmentsRadio from 'components/purchase/InstallmentsRadio';
import InstallmentsSelectedPlan from 'components/purchase/InstallmentsSelectedPlan';
import 'styles/components/purchase/CardFormPaymentOptions';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import amex from 'images/payment/americanexpress.svg';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix } from 'utils/currency';
import PaymentImage from '../../ui/atoms/PaymentImage';

const propTypes = {
  qualifiesForMonthlyInstallments: PropTypes.bool.isRequired,
  monthlySelectedPlan: PropTypes.number.isRequired,
  selectedInstallmentsPlan: PropTypes.shape({
    card: PropTypes.string.isRequired,
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
  total: PropTypes.number.isRequired,
  maxInstallmentsPlan: PropTypes.shape({
    months: PropTypes.number,
    monthlyPayment: PropTypes.number,
  }).isRequired,
  onChangeClick: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
};

function CardFormPaymentOptions({
  qualifiesForMonthlyInstallments,
  monthlySelectedPlan,
  selectedInstallmentsPlan,
  total,
  maxInstallmentsPlan,
  onChangeClick,
  selectedPlan,
  setSelectedPlan,
}) {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { months, monthlyPayment } = maxInstallmentsPlan;
  const isCol = env.brand === 'ochoa' || env.brand === 'brasilia';
  const showDebitOptions =
    !isCol && Boolean(env.openPay?.enabled) && features.SHOW_CREDIT_DEBIT_LOGOS;
  const showCreditOptions = !isCol && features.SHOW_CREDIT_DEBIT_LOGOS;
  const showLogosContainer = isCol || showCreditOptions || showDebitOptions;
  const creditOptions = env.lang.default.startsWith('pt') ? 'credit-options-pt' : 'credit-options';
  const numberOfDecimals = features.ROUND_CURRENCY_AMOUNTS ? 0 : 2;

  if (!features.INSTALLMENTS_ENABLED || !qualifiesForMonthlyInstallments) {
    return showLogosContainer ? (
      <div className="checkout-section">
        {features.SHOW_CREDIT_DEBIT_LOGOS && <Text size="S">{t('purchase:accepted_cards')}</Text>}
        {isCol && <PaymentImage type="credit-openpay-col" />}
        {showCreditOptions && <PaymentImage type={creditOptions} />}
        {showDebitOptions && <PaymentImage type="debit-options" />}
      </div>
    ) : null;
  }

  if (monthlySelectedPlan > 1) {
    return (
      <div className="checkout-section">
        {features.SHOW_CREDIT_DEBIT_LOGOS && <Text size="S">{t('purchase:accepted_cards')}</Text>}
        <InstallmentsSelectedPlan
          selectedInstallmentsPlan={selectedInstallmentsPlan}
          onChangeClick={onChangeClick}
        />
      </div>
    );
  }

  return (
    <div className="checkout-section">
      <Text size="S">{t('accepted_cards')}</Text>

      {showCreditOptions && <PaymentImage type={creditOptions} />}

      {!features.SHOW_ADYEN_CARD_LOGOS && (
        <div className="cards-logos">
          <img src={visa} />
          <img src={mastercard} />
          <img src={amex} />
        </div>
      )}

      <div className="installments-wrapper">
        <InstallmentsRadio
          name="singlePayment"
          variant="text"
          labelText={`${t('payment:single_payment')} ${formatCurrency(total, numberOfDecimals)} 
          ${getCurrencySuffix()}`}
          checked={selectedPlan === 'singlePayment'}
          onClick={setSelectedPlan}
        />

        <InstallmentsRadio
          name="installmentsPayment"
          variant="text"
          labelText={t('payment:or_installment', {
            count: months,
            amount: formatCurrency(monthlyPayment, numberOfDecimals),
            currency: getCurrencySuffix(),
          })}
          checked={selectedPlan === 'installmentsPayment'}
          onClick={setSelectedPlan}
        />
      </div>

      {features.SHOW_INSTALLMENTS_BANKS && (
        <div className="installments-payment-note">
          <h6 className="checkout-text">* {t('label.participating_banks')}:</h6>
          <p className="checkout-disclaimer-text">
            BBVA Bancomer, Banorte, HSBC, Santander, Scotiabank, Inbursa, Invex, Monex, Afirme,
            BanRegio.
          </p>
        </div>
      )}
    </div>
  );
}

CardFormPaymentOptions.propTypes = propTypes;

export default CardFormPaymentOptions;
