import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Icon, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import checkboxRenderer from './CheckboxRenderer';
import InsuranceModal from './InsuranceModal';
import { formatCurrency } from '../utils/Helpers';
import insuranceETN from '../images/brands-unique/etn/insuranceETN.png';
import 'styles/components/CardCheckbox';

const PassengerInsuranceCheckbox = ({
  passenger,
  insuranceOutgoingUnitAmount,
  insuranceIncomingUnitAmount,
  isExchange,
  wantsOutgoingInsurance,
  wantsIncomingInsurance,
}) => {
  const { t } = useTranslation('purchase');
  const preWantsOutgoingInsurance = useRef(null);
  const preWantsIncomingInsurance = useRef(null);

  useEffect(() => {
    preWantsOutgoingInsurance.current = wantsOutgoingInsurance;
    preWantsIncomingInsurance.current = wantsIncomingInsurance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card-checkbox">
      <Icon type={insuranceETN} hasMargin />
      <div className="card-checkbox-wrapper">
        <Spacing size="S" vertical>
          <InsuranceModal />
          {insuranceOutgoingUnitAmount > 0 && (
            <Field
              id={`${passenger}.wantsOutgoingInsurance`}
              name={`${passenger}.wantsOutgoingInsurance`}
              type="checkbox"
              label={t('label.add_departing_trip_insurance', {
                cost: formatCurrency(insuranceOutgoingUnitAmount, 2),
              })}
              component={checkboxRenderer}
              disabled={isExchange && wantsOutgoingInsurance && preWantsOutgoingInsurance.current}
            />
          )}
          {insuranceIncomingUnitAmount > 0 && (
            <Field
              id={`${passenger}.wantsIncomingInsurance`}
              name={`${passenger}.wantsIncomingInsurance`}
              type="checkbox"
              label={t('label.add_return_trip_insurance', {
                cost: formatCurrency(insuranceIncomingUnitAmount, 2),
              })}
              component={checkboxRenderer}
              disabled={isExchange && wantsIncomingInsurance && preWantsIncomingInsurance.current}
            />
          )}
        </Spacing>
      </div>
    </div>
  );
};

PassengerInsuranceCheckbox.propTypes = {
  passenger: PropTypes.string.isRequired,
  insuranceOutgoingUnitAmount: PropTypes.number.isRequired,
  insuranceIncomingUnitAmount: PropTypes.number,
  isExchange: PropTypes.bool,
  wantsOutgoingInsurance: PropTypes.bool,
  wantsIncomingInsurance: PropTypes.bool,
};

export default PassengerInsuranceCheckbox;
