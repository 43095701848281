import { camelizeKeys } from 'humps';
import parseQueryString from './queryString/parseQueryString';

const getUtmCampaign = () => {
  const { utmCampaign } = camelizeKeys(parseQueryString(window.location.search));

  return utmCampaign || '';
};

export default getUtmCampaign;
