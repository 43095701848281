import store from '@/store';

/**
 * Retrieves the regular expression pattern for a given document type.
 *
 * @param {string} target - The target category of the document (e.g., 'passengers', 'purchaser').
 * @param {string} [documentTypeValue =''] - The specific type of document to retrieve the regex pattern for.
 * @returns {string} - The regular expression pattern associated with the specified document type.
 */
const getDocTypeRegex = (target, documentTypeValue = '') => {
  const {
    env: { documentTypes },
  } = store.getState().whitelabelConfig;

  const document = Object.values(documentTypes[target] || {}).find(
    (doc) => doc.brand === documentTypeValue,
  );
  return document?.regex || '';
};

export default getDocTypeRegex;
