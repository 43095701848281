import cookie from 'js-cookie';

const getUserHasAcceptedPrivacyPolicy = () => {
  const acceptedPrivacyPolicyCookieKey = 'acceptedPrivacyPolicy';
  const rawCookieValue = cookie.get(acceptedPrivacyPolicyCookieKey);
  const hasAcceptedPrivacyPolicy = rawCookieValue ? Boolean(JSON.parse(rawCookieValue)) : true;
  return hasAcceptedPrivacyPolicy;
};

export default getUserHasAcceptedPrivacyPolicy;
