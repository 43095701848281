import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import InstallmentsRadio from 'components/purchase/InstallmentsRadio';
import 'styles/components/purchase/InstallmentsPlanSelectorOv';
import CSSVariablesProvider from 'components/CSSVariablesProvider/CSSVariablesProvider';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  paymentPlans: PropTypes.shape({
    visaMc: PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number.isRequired,
        monthlyPayment: PropTypes.number.isRequired,
      }),
    ),
    amex: PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number.isRequired,
        monthlyPayment: PropTypes.number.isRequired,
      }),
    ),
  }),
  selectedCard: PropTypes.string.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  onSelectCard: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const defaultProps = {
  paymentPlans: {},
};

function InstallmentsPlanSelectorOv({
  selectedCard,
  onSelectCard,
  onSelectPlan,
  visible,
  paymentPlans,
  onCloseModal,
}) {
  const { t } = useTranslation('general');
  const { env, features } = useSelector((state) => state.whitelabelConfig);

  if (!visible) return null;

  const selectPlanHandler = (selectedMonths) => {
    onSelectPlan(selectedCard, Number(selectedMonths), paymentPlans);
  };

  if (!paymentPlans.visaMc) return null;

  const selectedCardPlans = paymentPlans[selectedCard];
  const installmentsPlans = Object.keys(selectedCardPlans).map((option) => ({
    months: Number(option),
    monthlyPayment: selectedCardPlans[option].monthlyPayment,
    interest: selectedCardPlans[option].interest,
    interestByMonth: selectedCardPlans[option].interestByMonth,
  }));

  return (
    <Modal
      title={t('deferred_payment')}
      responsiveSize="L"
      headerColor="primary"
      visible={visible}
      headerButton={false}
      onCloseModal={onCloseModal}
    >
      <CSSVariablesProvider
        theme={env.theme}
        funnelStyle={features.FUNNEL_STYLE}
        funnelStyleBrandVariation={env.brand.toUpperCase()}
      >
        <div className="installments-radios">
          <p className="installments-radios-title">{t('payment:select_card_type')}</p>

          <div className="installments-wrapper">
            <InstallmentsRadio
              variant="visa"
              name="visaMc"
              onClick={onSelectCard}
              checked={selectedCard === 'visaMc'}
            />
            <InstallmentsRadio
              variant="amex"
              name="amex"
              onClick={onSelectCard}
              checked={selectedCard === 'amex'}
            />
          </div>
        </div>
        <div className="installments-radios">
          <p className="installments-radios-title">{t('payment:select_intallments_plan')}</p>

          <div className="installments-options">
            {installmentsPlans.map((plan) => (
              <InstallmentsRadio
                key={`${selectedCard}-${plan.months}`}
                name={`${selectedCard}-plan`}
                variant="installments"
                installmentsPlan={plan}
                value={plan.months}
                onClick={() => selectPlanHandler(plan.months)}
              />
            ))}
          </div>
        </div>
      </CSSVariablesProvider>
    </Modal>
  );
}

InstallmentsPlanSelectorOv.propTypes = propTypes;
InstallmentsPlanSelectorOv.defaultProps = defaultProps;

export default InstallmentsPlanSelectorOv;
