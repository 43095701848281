import React from 'react';
import PropTypes from 'prop-types';
import { Link, Box, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  brand: PropTypes.string,
  destinationDetails: PropTypes.object,
};

const EndItem = ({ title, description, time, brand, destinationDetails }) => {
  const { t } = useTranslation('trips');
  const hasCoordinates =
    destinationDetails.coordinates?.lat || destinationDetails.coordinates?.long;
  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;
  return (
    <div className="route-detail-row departure">
      <div className="rd-left">
        <Text weight="bold" size="S" color="grayMedium">
          {time}
        </Text>
      </div>

      <div className="rd-center">
        <i className="ic-dot" />
      </div>

      <div className={`rd-right ic-dot ${brand}`}>
        <Box
          paddingVertical="S"
          paddingHorizontal="M"
          borderRadius="S"
          border="all"
          borderColor="grayBorder"
          alphaBg="M"
          bgColor="grayBorder"
        >
          <Spacing size="XS" vertical>
            <Text weight="bold" size="L">
              {title}
            </Text>
            {showTerminal && <Text size="S">{description}</Text>}
            {destinationDetails.address && <Text size="S">{destinationDetails.address}</Text>}
          </Spacing>
          {hasCoordinates ? (
            <Link
              newTab
              type="primary"
              size="XS"
              href={`https://www.google.com/maps/search/?api=1&query=${destinationDetails.coordinates.lat},${destinationDetails.coordinates.long}`}
              text={t('see_location')}
            />
          ) : null}
        </Box>
      </div>
    </div>
  );
};

EndItem.propTypes = propTypes;

export default EndItem;
