import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Spacing } from '@reservamos/elements';
import { trackEvent } from 'user-analytics';
import { TERMINAL_GEOLOCATION_CLICKED } from 'constants/TrackEvents';
import LocationTerminalLink from '../atoms/LocationTerminalLink';

/**
 * Displays the route terminals.
 *
 * @param {Object} props - The component props.
 * @param {string} props.terminalOrigin - The origin terminal.
 * @param {string} props.cityOrigin - The origin city.
 * @param {string} props.terminalDestination - The destination terminal.
 * @param {string} props.cityDestination - The destination city.
 * @param {string} props.originUrl - The origin URL.
 * @param {string} props.destinationUrl - The destination URL.
 * @returns {JSX.Element} The rendered component.
 */
const RouteTerminalsDisplay = ({
  terminalOrigin,
  cityOrigin,
  terminalDestination,
  cityDestination,
  originUrl,
  destinationUrl,
}) => {
  const { t } = useTranslation('trips');

  /**
   * Handle on click for the terminal geolocation link.
   */
  const handleOnClick = (terminalType) => () => {
    trackEvent(TERMINAL_GEOLOCATION_CLICKED, { terminalType });
  };

  return (
    <Box
      alphaBg="XL"
      bgColor="grayBorder"
      blurBg="XS"
      borderRadius="M"
      paddingHorizontal="M"
      paddingVertical="M"
    >
      <Spacing
        justifyContent="space-between"
        alignItems="center"
        mobileAlign="flex-start"
        isResponsive
      >
        <LocationTerminalLink
          label={t('origin')}
          iconType="Pin"
          terminal={terminalOrigin}
          city={cityOrigin}
          url={originUrl}
          onClick={handleOnClick('origin')}
        />

        <LocationTerminalLink
          label={t('destination')}
          iconType="PinOutlined"
          terminal={terminalDestination}
          city={cityDestination}
          url={destinationUrl}
          onClick={handleOnClick('destination')}
        />
      </Spacing>
    </Box>
  );
};

RouteTerminalsDisplay.propTypes = {
  terminalOrigin: PropTypes.string.isRequired,
  cityOrigin: PropTypes.string.isRequired,
  terminalDestination: PropTypes.string.isRequired,
  cityDestination: PropTypes.string.isRequired,
  originUrl: PropTypes.string.isRequired,
  destinationUrl: PropTypes.string.isRequired,
};

export default RouteTerminalsDisplay;
