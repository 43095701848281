import React from 'react';
import PropTypes from 'prop-types';
import OptionsSelector from './OptionsSelector';
import useSortOptions from '../hooks/useSortOptions';

/**
 * Component for selecting a sort option.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - The function to be called when the sort option changes.
 * @param {string} props.value - The selected value of the sort option.
 * @returns {JSX.Element} The rendered component.
 */
const SortSelector = ({ onChange, value }) => {
  return <OptionsSelector onChange={onChange} options={useSortOptions()} value={value} />;
};

SortSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SortSelector.defaultProps = {
  value: null,
};

export default SortSelector;
