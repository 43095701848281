import { connect } from 'react-redux';
import CardPaymentInfo from './CardPaymentInfo';
import wayIsOpenTicket, { isHybridTrip } from '../../../utils/wayIsOpenTicket';

const mapStateToProps = ({ purchase, payment }) => ({
  estimatedCompletedAt: purchase.get('estimatedCompletedAt'),
  purchaseStatus: purchase.get('state') || payment.get('purchaseState'),
  token: purchase.get('token'),
  transportType: purchase.getIn(['departs', 'transportType']),
  isOpenTicket: purchase.get('openTicket'),
  email: purchase.get('email'),
  isHybridTrip: Boolean(
    isHybridTrip(purchase.get('departs').toJS(), purchase.get('returns').toJS()),
  ),
  isDepartureOpen: wayIsOpenTicket(purchase.get('departs').toJS()),
  isReturnOpen: wayIsOpenTicket(purchase.get('returns').toJS()),
});

export default connect(mapStateToProps)(CardPaymentInfo);
