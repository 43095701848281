import React from 'react';
import PropTypes from 'prop-types';
import { Text, Gap, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import FilterChip from '../../../ui/atoms/FilterChip';
import filtersTypes from '../../../constants/filtersTypes';

/**
 * Component for rendering an individual schedule option.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the schedule option.
 * @param {string} props.description - The description of the schedule option.
 * @param {string} props.icon - The icon of the schedule option.
 * @param {string} props.iconColor - The color of the icon.
 * @param {string} props.value - The value of the schedule option.
 * @param {boolean} props.isActive - Indicates whether the schedule option is active or not.
 * @param {boolean} props.isAvailable - Indicates whether the schedule option is available or not.
 * @param {Function} props.onSelect - The function to be called when the option is selected.
 * @param {string} props.i18Key - The translation key for the label.
 * @returns {JSX.Element} The rendered component.
 */
const ScheduleOption = ({
  title,
  description,
  icon,
  iconColor,
  value,
  isActive,
  onSelect,
  isAvailable,
  i18Key,
}) => {
  return (
    <FilterChip
      i18Key={i18Key}
      text={title}
      secondText={description}
      icon={icon}
      iconColor={iconColor}
      filterType={filtersTypes.DEPARTURE_TIME}
      value={value}
      onSelect={onSelect}
      active={isActive}
      notAvailable={!isAvailable}
    />
  );
};

ScheduleOption.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  value: PropTypes.string,
  isActive: PropTypes.bool,
  isAvailable: PropTypes.bool,
  onSelect: PropTypes.func,
  i18Key: PropTypes.string,
};

/**
 * Component for rendering a list of schedule options.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.options - The array of schedule options to be rendered.
 * @param {Array} props.selectedScheduleOptions - The array of selected schedule options.
 * @param {Function} props.onSelectOption - The function to be called when an option is selected.
 * @returns {JSX.Element} The rendered component.
 */
const ScheduleOptionList = ({ options, selectedScheduleOptions, onSelectOption }) => {
  const { t } = useTranslation('common');
  if (!options || !options.length) return null;
  return (
    <Spacing size="S" vertical>
      <Text size="L" weight="bold" color="grayMedium">
        {t('buttons.schedules')}
      </Text>

      <Gap columnGap="M" rowGap="S">
        {options.map((option) => (
          <ScheduleOption
            key={option.value}
            id={option.value}
            i18Key={option.i18Key}
            title={option.title}
            description={option.description}
            icon={option.icon}
            iconColor={option.iconColor}
            value={option.value}
            isActive={selectedScheduleOptions.includes(option.value)}
            onSelect={onSelectOption}
            isAvailable={option.isAvailable}
          />
        ))}
      </Gap>
    </Spacing>
  );
};

ScheduleOptionList.propTypes = {
  options: PropTypes.array,
  selectedScheduleOptions: PropTypes.array,
  onSelectOption: PropTypes.func,
};

export default ScheduleOptionList;
