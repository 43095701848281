import 'styles/components/AgencyHeader';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useQuery from '../../hooks/useQuery';

const AgencyHeader = ({ email, fetchAgency, isLoading, isLoggedIn }) => {
  const { t } = useTranslation('general');
  const { agencyToken } = useQuery('agencyToken');
  useEffect(() => {
    if (agencyToken) {
      fetchAgency(agencyToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) return null;
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  if (isLoading) return <label>{t('loading')}...</label>;

  return <p className="agency-header">{email}</p>;
};

AgencyHeader.propTypes = {
  email: PropTypes.string.isRequired,
  fetchAgency: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AgencyHeader;
