import React from 'react';
import PropTypes from 'prop-types';
import TripViewOptions from 'components/search/TripViewOptions';
import Modal from 'components/Modal';
import 'styles/components/TripListOptions';

const propTypes = {
  sortOn: PropTypes.bool.isRequired,
  departureFilterOn: PropTypes.bool.isRequired,
  locationFilterOn: PropTypes.bool.isRequired,
  stopsFilterOn: PropTypes.bool.isRequired,
  showLocationFilter: PropTypes.bool.isRequired,
  stopsFilter: PropTypes.string.isRequired,
  locations: PropTypes.shape({
    departure: PropTypes.array.isRequired,
    arrival: PropTypes.array.isRequired,
  }).isRequired,
  departureLocation: PropTypes.string.isRequired,
  arrivalLocation: PropTypes.string.isRequired,
  floatingOptions: PropTypes.number.isRequired,
  sortProperty: PropTypes.string.isRequired,
  providerSortProperty: PropTypes.string,
  activeDeparture: PropTypes.string.isRequired,
  onSortSelect: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  trips: PropTypes.array.isRequired,
  isStopsFilterAvailable: PropTypes.bool.isRequired,
};

const defaultProps = {
  providerSortProperty: '',
};

const TripListOptions = ({
  sortOn,
  departureFilterOn,
  locationFilterOn,
  stopsFilterOn,
  showLocationFilter,
  stopsFilter,
  locations,
  floatingOptions,
  sortProperty,
  providerSortProperty,
  activeDeparture,
  departureLocation,
  arrivalLocation,
  onSortSelect,
  onFilterSelect,
  onOptionClick,
  trips,
  isStopsFilterAvailable,
}) => {
  return (
    <>
      <TripViewOptions
        sortOn={!!sortOn}
        sortProperty={sortProperty}
        departureFilterOn={departureFilterOn}
        locationFilterOn={locationFilterOn}
        stopsFilterOn={stopsFilterOn}
        showLocationFilter={showLocationFilter}
        onOptionClick={onOptionClick}
        isStopsFilterAvailable={isStopsFilterAvailable}
      />

      <Modal
        locations={locations}
        floatingOptions={floatingOptions}
        sortProperty={sortProperty}
        providerSortProperty={providerSortProperty}
        activeDeparture={activeDeparture}
        departureLocation={departureLocation}
        arrivalLocation={arrivalLocation}
        onSortSelect={onSortSelect}
        onFilterSelect={onFilterSelect}
        stopsFilter={stopsFilter}
        trips={trips}
      />
    </>
  );
};

TripListOptions.propTypes = propTypes;
TripListOptions.defaultProps = defaultProps;

export default TripListOptions;
