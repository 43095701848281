import { camelizeKeys } from 'humps';
import { SET_CONFIG, SET_CYBERSOURCE_KEYS } from 'constants/ActionTypes';

export default function setConfig(config) {
  return {
    type: SET_CONFIG,
    config: camelizeKeys(config),
  };
}

export function setCybersourceKeys(keys) {
  return {
    type: SET_CYBERSOURCE_KEYS,
    keys,
  };
}
