import React from 'react';
import { Button, Icon, Modal, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import adjacentBadge from 'images/badges/adjacent-seat.svg';
import adjacentPerson from 'images/seats/adjacentSeatPerson.svg';
import InfoText from './InfoText';

/**
 * It shows a modal to explain what is the adjacent seat about.
 * @param {*} props
 * @param {boolean} props.show - Should show or hide the dialog
 * @param {function} props.onClose - Callback when the dialog is closed
 * @returns - AdjacentSeatDialog component.
 */
const AdjacentSeatDialog = ({ show, onClose }) => {
  const { t } = useTranslation('seats');

  /**
   * Handle close dialog
   */
  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  if (!show) return null;

  return (
    <Modal
      responsiveSize="S"
      iconType={adjacentBadge}
      title={t('adjacent_dialog.title')}
      defaultOpened
      onCloseModal={handleClose}
      footer={
        <Spacing vertical textAlign="center">
          <Button text={t('adjacent_dialog.cta')} variant="accent" onClick={handleClose} />
        </Spacing>
      }
    >
      <Spacing vertical size="L" responsiveSize="M">
        <Text mobileSize="S">{t('adjacent_dialog.message')}</Text>

        <Spacing flexGrow>
          <img src={adjacentPerson} alt={t('pet_dialog.seats_for_pet_and_me')} height="130px" />
        </Spacing>

        <Spacing vertical size="XS">
          <InfoText>
            <Text elementType="span" mobileSize="S">
              {`${t('adjacent_dialog.disclaimer_one')} `}
            </Text>
            <Icon type={adjacentBadge} size="S" hasMargin marginSide="right" margin="1" />
            <Text elementType="span" mobileSize="S">
              {` ${t('adjacent_dialog.disclaimer_two')}`}
            </Text>
          </InfoText>
        </Spacing>
      </Spacing>
    </Modal>
  );
};

AdjacentSeatDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdjacentSeatDialog;
