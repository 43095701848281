import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { fieldRenderer, countryRenderer } from 'utils/formRenderers';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import es from 'react-phone-number-input/locale/es.json';
import { Spacing } from '@reservamos/elements';
import normalizePhone from 'utils/normalizePhone';

const COMMON_COUNTRIES = ['US', 'MX', 'CO', 'BR'];

/**
 * PhoneNumberField component
 * @param {Object} props - Component props
 * @param {string} props.phoneCountryFieldName - Name of the phone country field
 * @param {string} props.phoneCodeFieldName - Name of the phone code field
 * @param {string} props.phoneFieldName - Name of the phone field
 * @param {string} props.formName - Name of the redux form
 * @param {string} props.placeholder - Placeholder text for the phone number input
 * @param {boolean} [props.isDisabled] - Flag to disable the field
 * @param {boolean} [props.hideCodeNumber] - Flag to hide the country code dropdown
 * @param {string} props.initCountry - Initial country code for the phone number input
 */
const PhoneNumberField = ({ ...props }) => {
  const {
    // phoneCountryFieldName, phoneCodeFieldName and phoneFieldName are needed
    // to work correctly with redux-form
    phoneCountryFieldName,
    phoneCodeFieldName,
    phoneFieldName,
    // formName is needed to change the phoneCode field
    // when the country is changed using redux-form dispatch
    formName,
    placeholder,
    isDisabled,
    hideCodeNumber,
    // initCountry is used to set the initial country of the phone number field
    // and just to use with the normalizePhone function
    initCountry,
  } = props;
  const [country, setCountry] = useState(initCountry);
  const { t } = useTranslation('general');
  const dispatch = useDispatch();

  /**
   * Handles the change of the country selection.
   * Updates the country state and dispatches actions to update the phone code in the form.
   *
   * @param {Object} _ - Unused parameter.
   * @param {string} value - The selected country code.
   */
  const handleCountryChange = (_, value) => {
    const phoneCode = getCountryCallingCode(value);
    setCountry(value);
    dispatch(change(formName, phoneCodeFieldName, phoneCode));
  };

  /**
   * Normalizes the phone number based on the selected country.
   *
   * @param {string} value - The phone number to normalize.
   * @returns {string} - The normalized phone number.
   */
  const handleNormalize = (value) => {
    return normalizePhone(value, country);
  };

  const mostUsedOptions = {
    groupTitle: t('most_used'),
    subOptions: COMMON_COUNTRIES.map((country) => ({
      label: `${es[country]} +${getCountryCallingCode(country)}`,
      value: country,
    })),
  };

  const otherOptions = {
    groupTitle: t('others'),
    subOptions: getCountries()
      .filter((country) => {
        return !COMMON_COUNTRIES.includes(country);
      })
      .sort((a, b) => {
        return es[a].localeCompare(es[b]);
      })
      .map((country) => ({
        label: `${es[country]} +${getCountryCallingCode(country)}`,
        value: country,
      })),
  };
  const optionsObject = [mostUsedOptions, otherOptions];

  return (
    <Spacing flexGrow size="S">
      {!hideCodeNumber && (
        <Field
          name={phoneCountryFieldName}
          id={phoneCountryFieldName}
          component={countryRenderer}
          onChange={handleCountryChange}
          options={optionsObject}
          isDisabled={isDisabled}
        />
      )}
      <Field
        type="tel"
        name={phoneFieldName}
        id={phoneFieldName}
        placeholder={placeholder}
        component={fieldRenderer}
        normalize={handleNormalize}
        isDisabled={isDisabled}
      />
      <Field type="hidden" name={phoneCodeFieldName} id={phoneCodeFieldName} component="input" />
    </Spacing>
  );
};

PhoneNumberField.propTypes = {
  phoneCountryFieldName: PropTypes.string.isRequired,
  phoneCodeFieldName: PropTypes.string.isRequired,
  phoneFieldName: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  hideCodeNumber: PropTypes.bool,
  initCountry: PropTypes.string.isRequired,
};
export default PhoneNumberField;
