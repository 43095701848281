import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Option from './TypeaheadOption';

const TypeaheadOptionsContainer = ({ showItems, setShowItems, url }) => {
  const [options, setOptions] = useState([]);

  const fetchPlaces = useCallback(() => {
    if (url !== '') {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setShowItems(true);
          setOptions(data);
        });
    }
  }, [setShowItems, url]);

  useEffect(() => {
    fetchPlaces();
  }, [fetchPlaces]);

  return (
    <ul
      className="typeahead"
      style={{
        display: showItems ? 'block' : 'none',
      }}
    >
      {options.length > 0 &&
        showItems &&
        options.map((option) => {
          return <Option key={option.slug} place={option} />;
        })}
    </ul>
  );
};

const propTypes = {
  url: PropTypes.string.isRequired,
  showItems: PropTypes.bool.isRequired,
  setShowItems: PropTypes.func.isRequired,
};

TypeaheadOptionsContainer.propTypes = propTypes;
export default TypeaheadOptionsContainer;
