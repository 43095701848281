import React from 'react';
import PropTypes from 'prop-types';
import './MenuButton.scss';
import { Icon } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

const MenuButton = ({ onClick }) => {
  const { t } = useTranslation('common');

  return (
    <button className="rvui-menu-button" onClick={onClick} title={t('buttons.menu')}>
      <Icon type="Menu" color="white" />
    </button>
  );
};

const propTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
};

MenuButton.defaultProps = defaultProps;
MenuButton.propTypes = propTypes;

export default MenuButton;
