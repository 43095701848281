import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import './StepStatus.scss';

const StepStatus = ({ status, title, email }) => (
  <div className="steps-status">
    <i className={`icon-${status}`} />
    <div className="steps-status-content">
      <Spacing vertical size="XS">
        <Text weight="semibold" size="S">
          {title}
        </Text>
        {Boolean(email) && (
          <Text size="L" color="primary" weight="bold">
            {email}
          </Text>
        )}
      </Spacing>
    </div>
  </div>
);

StepStatus.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  email: PropTypes.string,
};

StepStatus.defaultProps = {
  email: '',
};

export default StepStatus;
