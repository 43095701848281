import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CoppelPayForm from './CoppelPayForm';

const thirdPartyForms = {
  coppel_pay: CoppelPayForm,
};

const ThirdPartyForm = () => {
  const { t } = useTranslation('payment');
  const labels = [t('store_form_submit'), t('store_form_instructions')];
  // this is the new way to get the payment engine for each payment method
  const selectedPaymentMethod = useSelector((state) => state.purchase.get('selectedPaymentMethod'));
  // defaults to kushki for backwards compatibility
  const paymentEngine = selectedPaymentMethod?.engine ?? 'coppel_pay';
  const Component = thirdPartyForms[paymentEngine];

  return (
    <div className="checkout-section">
      <Component labels={labels} />
    </div>
  );
};

export default ThirdPartyForm;
