import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@reservamos/elements';
import discountETN from '../images/brands-unique/etn/discountETN.png';
import 'styles/components/SelectableOption';

const SelectableOption = ({ description, icon, onSelect, selected, title }) => (
  <div onClick={onSelect} className="selectable-option">
    <div className="selectable-option-wrapper">
      <div className={selected ? 'selectable-option-radio active' : 'selectable-option-radio'} />
      <div className="selectable-option-inner">
        <p className="selectable-option-title">{title}</p>
        <p className="selectable-option-description">{description}</p>
      </div>
    </div>
    {icon === 'discount' ? (
      <Icon type={discountETN} size="S" />
    ) : (
      <i className={`selectable-option-${icon}`} />
    )}
  </div>
);

SelectableOption.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.oneOf([
    null,
    'afternoon',
    'arrival',
    'departure',
    'discount',
    'duration',
    'earlymorning',
    'morning',
    'night',
    'price',
    'stops',
  ]),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

SelectableOption.defaultProps = {
  icon: null,
};

export default SelectableOption;
