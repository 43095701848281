import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getExpirationTime = (expiresAt, currentTime, isExpired) => {
  if (isExpired || !expiresAt) return;

  const purchaseCurrentTime = currentTime;
  const currentTimeMoment = moment(purchaseCurrentTime, 'x');
  const expireTime = moment(expiresAt, 'x');
  const remainingTime = expireTime.diff(currentTimeMoment, 'seconds', true);

  return remainingTime;
};

/**
 * Creates the format for the timer
 * @param {Number} time - time in seconds to format
 * @param {String} format - format to use
 */
export function createTimerFormat(time, format) {
  return moment.duration(time, 'seconds').format(format, { trim: false });
}
