import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getExpirationTime = (purchase, milisecondsAdded) => {
  if (purchase.isExpired) return;

  const expiration = purchase.expiresAt;

  if (!expiration) return;

  let purchaseCurrentTime = purchase.currentTime;
  if (milisecondsAdded) purchaseCurrentTime += milisecondsAdded;
  const currentTime = moment(purchaseCurrentTime, 'x');
  const expireTime = moment(expiration, 'x');
  const remainingTime = expireTime.diff(currentTime, 'seconds', true);

  return remainingTime;
};
