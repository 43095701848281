import * as types from 'constants/ActionTypes';

export function networkError(code, message) {
  return {
    type: types.SET_NOTIFICATION,
    code,
    message,
  };
}

/**
 * Action to show an error alert in the funnel
 * @param {*} code - Status error code
 * @param {*} msg - Main message to show that match with a translation
 * @param {*} style - Style of the alertm, it defines the color of the alert ('success'|'info'|'warning' | 'error')
 * @param {*} redirect - Flag to indicate if the funnel should redirect to some path
 * @param {*} customMsg - Custom message to show instead of looking for the one that matches a translation key
 * @param {*} customRedirectPath - Custom redirect path. Default: '/'
 * @param {*} subMessage - Submessage to show in the alert
 * @returns Object
 */
export function setError(
  code,
  msg,
  style = 'error',
  redirect = true,
  customMsg,
  customRedirectPath,
  subMessage,
  alertType = 'global',
  messageData,
) {
  return {
    type: types.SET_NOTIFICATION,
    code,
    message: msg,
    style,
    redirect,
    customMsg,
    subMessage,
    customRedirectPath,
    alertType,
    messageData,
  };
}

/**
 * Action to show an alert in the funnel
 * @param {*} msg - Main message to show that match with a translation
 * @param {*} style - Style of the alert, it defines the color of the alert ('success'|'info'|'warning')
 * @param {*} customMsg - Custom message to show instead of looking for the one that matches a translation key
 * @param {*} subMessage - Submessage to show in the alert
 * @returns Object
 */
export function setAlert(
  msg,
  style = 'info',
  customMsg,
  subMessage,
  alertType = 'global',
  messageData,
) {
  return {
    type: types.SET_NOTIFICATION,
    code: 200,
    message: msg,
    style,
    redirect: false,
    customMsg,
    subMessage,
    alertType,
    messageData,
  };
}

export function clearError() {
  return {
    type: types.SET_NOTIFICATION,
    code: 0,
    message: '',
    style: '',
    redirect: true,
    customMsg: false,
    customRedirectPath: '/',
  };
}

export function toggleModal(visibility, component, extraProps = {}) {
  return { type: types.TOGGLE_MODAL, visibility, component, extraProps };
}

export function toggleMenu() {
  return { type: types.TOGGLE_MENU };
}

export function changeMenu(visibility) {
  return { type: types.CHANGE_MENU, visibility };
}

export function closeMenu() {
  return (dispatch) => dispatch(changeMenu(false));
}
