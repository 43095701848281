import { Map } from 'immutable';

export default function getTransportState(dState, rState, roundTrip, way) {
  const departureState =
    dState ||
    Map({
      bus: 'pending',
      flight: 'pending',
      mixed: 'pending',
    });

  const returnState =
    rState ||
    Map({
      bus: 'pending',
      flight: 'pending',
      mixed: 'pending',
    });

  if (way === 'return') {
    return returnState;
  }

  if (roundTrip) {
    const returnBusState = returnState.get('bus');
    const returnFlightState = returnState.get('flight');
    const returnMixedState = returnState.get('mixed');
    let busState = departureState.get('bus');
    let flightState = departureState.get('flight');
    let mixedState = departureState.get('mixed');

    if (busState === 'pending' || returnBusState === 'pending') {
      busState = 'pending';
    } else if (busState === 'disabled' && returnBusState === 'disabled') {
      busState = 'disabled';
    } else {
      busState = 'finished';
    }

    if (flightState === 'pending' || returnFlightState === 'pending') {
      flightState = 'pending';
    } else if (flightState === 'disabled' && returnFlightState === 'disabled') {
      flightState = 'disabled';
    } else {
      flightState = 'finished';
    }

    if (mixedState === 'pending' || returnMixedState === 'pending') {
      mixedState = 'pending';
    } else if (mixedState === 'disabled' && returnMixedState === 'disabled') {
      mixedState = 'disabled';
    } else {
      mixedState = 'finished';
    }

    return Map({
      bus: busState,
      flight: flightState,
      mixed: mixedState,
    });
  }

  return departureState;
}
