import React from 'react';
import PropTypes from 'prop-types';
import './BusDiagramNotFoundError.scss';
import { useTranslation } from 'react-i18next';
import { Text, Spacing, Visibility } from '@reservamos/elements';
import conoImageFirst from 'images/seats/cono.png';
import conoImageSecond from 'images/seats/cono_error.png';
import girlError from 'images/seats/girl_error.png';
import stop from 'images/seats/stop.png';

/**
 * Shows an error screen for the bus diagram.
 * @param {Object} props - Props.
 *  @param {boolean} props.isFlat - Is flat.
 * @returns
 */
const BusDiagramNotFoundError = ({ isFlat }) => {
  const { t } = useTranslation('errors');
  return (
    <div className="bus-diagram-error-container">
      <Spacing alignItems="center" justifyContent="center" isResponsive>
        {isFlat ? (
          <img src={girlError} width="150px" height="150px" />
        ) : (
          <img src={conoImageFirst} width="150px" height="150px" />
        )}
        <Spacing vertical size="S" alignItems="center" textAlign="center">
          <p className="decorative-error-message">¡Ups!</p>
          <Text size="XL" color="grayMedium" weight="bold">
            {t('unavailable')}
          </Text>
          <Text size="S" color="grayLight">
            {t('select_another_trip')}
          </Text>
        </Spacing>
        <Visibility type="hideForMobileOnly">
          {isFlat ? (
            <img src={stop} width="150px" height="150px" />
          ) : (
            <img src={conoImageSecond} width="150px" height="150px" />
          )}
        </Visibility>
      </Spacing>
    </div>
  );
};

BusDiagramNotFoundError.propTypes = { isFlat: PropTypes.bool };

BusDiagramNotFoundError.defaultProps = { isFlat: false };

export default BusDiagramNotFoundError;
