import * as types from 'constants/ActionTypes';
import store from '@/store';
import { omit, intersectionBy, unionBy } from 'lodash';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import { change as changeFormField, reset as resetForm } from 'redux-form';
import { getDistinctId, resetUser } from 'user-analytics';
import { getSessionId, triggerSignOutWidget } from 'utils/session';
import { trip, purchase as purchaseApi } from 'js-api-client';
import { decodePassengers, getProductType } from 'utils/Reserbus';
import { getExpirationTime } from 'utils/purchase/getExpirationTime';
import camelizeConverter from 'utils/camelizeConverter';
import checkIfRetriesExceeded from 'utils/polls/checkIfRetriesExceeded';
import pollingPromises from 'utils/pollingPromises';
import parsePurchase from 'models/parsePurchase';
import isSetSeats from 'utils/purchase/validateSetSeat';
import Cookies from 'js-cookie';
import getSeats from 'utils/purchase/getSeats';
import normalizeRegex from 'utils/normalizeRegex';
import {
  getUserByWalletType,
  getWalletTypeForPurchase,
  setUserTokenForAPI,
} from '../utils/loyalty';
import { setError } from '.';
import { setTerminals, setAirports, setLines, setCarriers } from './catalogs';
import { errorTripExchange } from './exchange';
import customServerError from '../utils/purchase/customServerError';
import { getBrand2ProcessorMap } from '../utils/documentMaps';
import wayIsOpenTicket from '../utils/wayIsOpenTicket';
// eslint-disable-next-line import/no-cycle
import { setCostaPassError } from './costapass';
import { getInitialPassengers } from '../utils/passengers';
import userFingerprint from '../services/userFingerprint';
import { setSeatsBusError } from './seatsBus';

const EXCHANGE_HOME_PATH = '/exchange';

export function selectInstallmentsPlan(installmentsPlan) {
  return { type: types.SELECT_MONTHLY_PLAN, installmentsPlan };
}

export function selectInstalmentsPlan(selectedPlan) {
  return { type: types.SET_SELECTED_PLAN, selectedPlan };
}

export function resetInstallmentsPlan() {
  return {
    type: types.SELECT_MONTHLY_PLAN,
    installmentsPlan: { card: '', months: 1, total: 0, monthlyPayment: 0 },
  };
}

const getAvailableBusCategories = (departs, returns = {}) => {
  let dPassengerTypes = [];
  let rPassengerTypes = [];
  let departureHasBus = false;
  let returnHasBus = false;
  let isDepartureOpenTicket = false;
  let isReturnOpenTicket = false;

  departs.oneWayPurchases.forEach(({ trip }) => {
    const { transportType, openTicket, passengerTypes = [] } = trip;
    const availableTypes = passengerTypes.filter(({ availability }) => availability > 0);

    if (transportType === 'bus' && dPassengerTypes.length === 0) {
      departureHasBus = true;
      dPassengerTypes = availableTypes;
      isDepartureOpenTicket = !!openTicket;
    } else if (transportType === 'bus') {
      dPassengerTypes = intersectionBy(dPassengerTypes, availableTypes, 'type');
    }
  });

  if (returns.oneWayPurchases) {
    returns.oneWayPurchases.forEach(({ trip }) => {
      const { transportType, openTicket, passengerTypes = [] } = trip;
      const availableTypes = passengerTypes.filter(({ availability }) => availability > 0);

      if (transportType === 'bus' && rPassengerTypes.length === 0) {
        returnHasBus = true;
        rPassengerTypes = availableTypes;
        isReturnOpenTicket = !!openTicket;
      } else if (transportType === 'bus') {
        rPassengerTypes = intersectionBy(rPassengerTypes, availableTypes, 'type');
      }
    });
  }

  if (departureHasBus && returnHasBus) {
    const { JUST_ADULT_OPEN_TICKET } = store.getState().whitelabelConfig.features;

    if (JUST_ADULT_OPEN_TICKET) {
      if (isDepartureOpenTicket && !isReturnOpenTicket) {
        return rPassengerTypes;
      }

      if (isReturnOpenTicket && !isDepartureOpenTicket) {
        return dPassengerTypes;
      }
    }

    return intersectionBy(dPassengerTypes, rPassengerTypes, 'type');
  }

  return unionBy(dPassengerTypes, rPassengerTypes, 'type');
};

const isPlanAvailable = (qualifiesForInstallments, selectedInstallmentsPlan, paymentPlans) => {
  if (qualifiesForInstallments) {
    const { card, months } = selectedInstallmentsPlan;
    return Boolean(card && paymentPlans[card][months]);
  }

  return false;
};

export function requestPayment() {
  return { type: types.REQUEST_PAYMENT };
}

export function resetPaymentCardError() {
  return { type: types.RESET_PAYMENT_CARD_ERROR };
}

export function resetPayment() {
  return { type: types.RESET_PAYMENT };
}

export function requestPurchase() {
  return { type: types.REQUEST_PURCHASE };
}

export function receivePayment(payment = {}) {
  return (dispatch, getState) => {
    const loggedInUser = getState().siemprePlus.toJS().user;
    const isSuccessPayment = payment.id;
    if (!loggedInUser && isSuccessPayment) {
      resetUser();
    }
    dispatch({
      type: types.RECEIVE_PAYMENT,
      receivedAt: Date.now(),
      payload: camelizeKeys(payment),
    });
  };
}

export function receivePurchase(purchaseResponse) {
  return (dispatch, getState) => {
    const { features } = getState().whitelabelConfig;
    const tenantSupportsConnections = features.SUPPORT_TRIPS_WITH_CONNECTIONS;
    dispatch({
      type: types.RECEIVE_PURCHASE,
      purchase: parsePurchase(purchaseResponse, tenantSupportsConnections),
      tenantSupportsConnections,
      receivedAt: Date.now(),
    });
  };
}

const setFailedStatePurchaseAction = {
  type: types.SET_FAILED_STATE_PURCHASE,
};

export function resetPurchase() {
  return { type: types.RESET_PURCHASE };
}

export function updatePurchase(updating) {
  return { type: types.UPDATE_PURCHASE, updating };
}

export function setBusCategories(busCategories, tripSlug = null) {
  return {
    type: types.SET_BUS_CATEGORIES,
    busCategories,
    tripSlug,
  };
}

/**
 * Sets the seat map for a given trip.
 * @param {string} way - The direction of the trip (e.g., 'departure', 'return').
 * @param {Object} trip - The trip object containing bus and diagramType.
 * @param {string} tripSlug - The unique identifier for the trip.
 * @param {string} [saveIn='purchase'] - The context in which the seat map is being saved.
 * @param {Array} busCategories - Categories of buses available for the trip.
 * @returns {Object} The action object with type and payload for setting the seat map.
 */
export function setSeatMap(
  way,
  trip,
  tripSlug,
  saveIn = 'purchase',
  busCategories,
  layouts,
  seats,
  trips,
) {
  const { bus: tripBus, diagramType } = trip;
  const layoutsValues = layouts ? Object.values(layouts) : [];
  /**
   * When a trip has connections, the fetchTripDetails response has a null value for the bus key (tripBus),
   * So if the trips has connections, we take the bus from the first layout at layoutsValues.
   * This first value is the bus layout for the first connection.
   * When a trip has connections the layout attribute is not useful, but the code dies if this value is not set.
   */
  const bus = layoutsValues.length ? layoutsValues[0] : tripBus;
  return {
    way,
    layout: bus,
    diagramType,
    type: saveIn === 'purchase' ? types.SET_SEAT_MAP : types.SET_SEAT_MAP_TRIPS,
    tripSlug,
    busCategories,
    layouts,
    seats,
    trips,
  };
}

export function requestTripsDetails(way) {
  return { type: types.REQUEST_TRIPS_DETAILS, way };
}

export function receiveTripsDetails(way, tripSlug) {
  return { type: types.RECEIVE_TRIPS_DETAILS, way, tripSlug };
}

export function postPassengers() {
  return { type: types.PURCHASE_POST_PASSENGERS };
}

/**
 * Reorders passengers by moving minor to the end of the list.
 * @param {Array} passengers - List of passengers to reorder.
 * @returns {Array} Reordered list of passengers.
 */
function reorderPassengers(passengers) {
  if (passengers.length <= 1) {
    return passengers;
  }

  if (passengers[0].bus_category === 'minor') {
    const minorPassenger = passengers.shift();
    passengers.push(minorPassenger);
  }

  return passengers;
}

/**
 * Sets the passengers for the purchase
 * @param {Array} passengers - List of passengers
 * @param {string} token - Purchase token
 * @returns {Object} Action to set passengers
 */
export function setPassengers(passengers, token) {
  const { purchase } = store.getState();
  const { isExchange } = purchase.toJS();

  if (isExchange) {
    passengers = reorderPassengers(passengers);
  }

  return {
    type: types.PURCHASE_SET_PASSENGERS,
    passengers: camelizeKeys(passengers),
    token,
  };
}

export function setPaymentPlans(paymentPlans) {
  return {
    type: types.PURCHASE_SET_PAYMENT_PLANS,
    paymentPlans,
  };
}

export function receiveWalletType(walletType, flatFareAvailable) {
  return {
    type: types.RECEIVE_WALLET_TYPE,
    walletType,
    flatFareAvailable,
  };
}

/**
 * @typedef {'credit_card' | 'paypal' | 'coppel_pay' | 'oxxo' | 'efecty' | 'transfer' | 'nequi'} PaymentOption
 */

/**
 * Handles the payment option selection, this value represent the selected payment option
 * @param {PaymentOption} option - Payment option
 * @returns {Object} Event dispatch
 */
export function selectPaymentOption(option) {
  return { type: types.SELECT_PAYMENT_OPTION, option };
}

/**
 * @typedef {Object} PaymentMethod
 * @property {string} type - Payment method type
 * @property {string} engine - Payment method engine
 * @property {string} provider - Payment method provider
 */

/**
 * Function that handles the selection of a payment method
 * This payment method is a new alternative with more information about the payment method
 * @param {PaymentMethod} method - Payment method
 * @returns {Object} Event dispatch
 */
export function selectPaymentMethod(method) {
  return { type: types.SELECT_PAYMENT_METHOD, method };
}

/**
 * Function that handles the creation of a new purchase
 * @param {String} dSlug - Departure slug
 * @param {String} rSlug - Return slug
 * @param {String} [passengers] - Passengers string
 * @param {String | Number} [seenPrice] - Seen price
 * @param {String} [adAttribution] - Ad attribution
 * @param {Boolean} [isExchange] - Is exchange
 * @param {Object} [exchangeData] - Exchange data
 * @param {Boolean} [redirect] - Redirect
 * @param {String} [redirectedFrom] - Redirected from brand
 * @param {Object} [tripInfo] - Trip info
 * @returns Event dispatch
 */
export function newPurchase(
  dSlug,
  rSlug,
  passengers = 'A1',
  seenPrice,
  adAttribution,
  isExchange = false,
  exchangeData = {},
  redirect = false,
  redirectedFrom = '',
  tripInfo,
  selectedSeats,
) {
  return (dispatch) => {
    const {
      search,
      whitelabelConfig: { env },
    } = store.getState();
    const decodedPassengers = decodePassengers(passengers);
    const externalCoupon = search.get('couponCode', null);
    const { operationNumbers, nit, document, email } = exchangeData;
    const userFingerprintValue = userFingerprint.getFingerprint();

    const googleAnalyticsID = Cookies.get('_ga'); // Google Analytics ID to track offline purchases
    const payload = {
      departs: dSlug,
      returns: rSlug,
      passenger_selection: decodedPassengers,
      seen_price: seenPrice && Number(seenPrice),
      ad_attribution: adAttribution === 'true',
      device_fingerprint: userFingerprintValue,
      tracker_id: getDistinctId(),
      ga_client_id: googleAnalyticsID,
      ...(env.riskified && env.riskified.enabled && { riskified_beacon_id: getSessionId() }),
      locale:
        typeof window !== 'undefined' && window.localStorage
          ? window.localStorage.getItem('i18nextLng') || 'es-MX'
          : 'es-MX',
      external_coupon_code: externalCoupon,
      ...(isExchange && { operation_number: operationNumbers, nit, document, email }),
      redirected_from: redirectedFrom,
      redirect,
      ...(tripInfo && decamelizeKeys(tripInfo)),
      ...(selectedSeats && decamelizeKeys({ ...selectedSeats })),
    };

    // The user token is set in the requests
    setUserTokenForAPI();

    dispatch(resetPurchase());
    dispatch(requestPurchase());

    if (isExchange) {
      dispatch({ type: types.SET_EXCHANGE_OPERATION });
    }

    return purchaseApi
      .create(payload)
      .then((purchaseResponse) => {
        const {
          terminals,
          airports,
          lines,
          carriers,
          purchase: { departs, returns },
        } = camelizeKeys(purchaseResponse, camelizeConverter);
        const busCategories = getAvailableBusCategories(departs, returns);

        dispatch(setTerminals(terminals));
        dispatch(setAirports(airports));
        dispatch(setLines(lines));
        dispatch(setCarriers(carriers));
        dispatch(setBusCategories(busCategories));
        dispatch(receivePurchase(purchaseResponse));
      })
      .catch((reason) => {
        const { code, message } = reason;
        const codeType = 200;
        if (!redirect) {
          // eslint-disable-next-line prettier/prettier
          dispatch(
            setError(
              codeType,
              'trip_not_available',
              'warning',
              true,
              customServerError(code, message),
              isExchange && EXCHANGE_HOME_PATH,
            ),
          );
        }
        dispatch(setFailedStatePurchaseAction);
        if (isExchange) dispatch(errorTripExchange(true));

      });
  };
}

export function getPurchase(token, update = false) {
  const {
    whitelabelConfig: {
      env: { defaultPaymentMethod, defaultPaymentOption },
    },
  } = store.getState();

  return (dispatch) => {
    if (update) {
      dispatch(updatePurchase(true));
    } else {
      dispatch(requestPurchase());
    }

    return purchaseApi
      .get(token)
      .then((purchaseResponse) => {
        const {
          terminals,
          airports,
          lines,
          carriers,
          purchase: { departs, lastPaymentId, returns, paymentMethods },
        } = camelizeKeys(purchaseResponse, camelizeConverter);
        const busCategories = getAvailableBusCategories(departs, returns);
        const paymentMethod =
          paymentMethods.find((method) => {
            return (
              method.type === defaultPaymentMethod.type &&
              method.engine === defaultPaymentMethod.engine &&
              method.provider === defaultPaymentMethod.provider
            );
          }) || {};

        dispatch(setTerminals(terminals));
        dispatch(setAirports(airports));
        dispatch(setLines(lines));
        dispatch(setCarriers(carriers));
        dispatch(setBusCategories(busCategories));
        dispatch(selectPaymentMethod(paymentMethod));
        dispatch(selectPaymentOption(defaultPaymentOption));
        dispatch(receivePurchase(purchaseResponse));

        if (lastPaymentId) {
          dispatch(requestPayment());
          purchaseApi
            .getPayment(token, lastPaymentId)
            .then((paymentResponse) => {
              dispatch(receivePayment(paymentResponse));
            })
            .catch((reason) => {
              dispatch(setError(304, 'error_when_looking_for_payment', 'error', true));
              dispatch(receivePayment());
              throw new Error(`Error 304: Failed to get payment. ${reason.message}`);
            });
        }
      })
      .catch((reason) => {
        dispatch(setError(201, 'error_when_generating_purchase'));

      });
  };
}

export function unlockSeats({ purchaseToken, forceUnlock, shouldUpdateState, dispatch }) {
  const { pathname } = window.location;
  if (!forceUnlock && (pathname.includes('purchase') || pathname.includes('payment'))) return;

  if (shouldUpdateState) dispatch(updatePurchase(true));
  return purchaseApi
    .unlockSeats(purchaseToken)
    .then((purchaseResponse) => {
      if (shouldUpdateState && dispatch && purchaseResponse) {
        dispatch(receivePurchase(purchaseResponse));
      }
    })
    .catch((reason) => {

    });
}

export function updatingWalletType(updating) {
  return {
    type: types.UPDATING_WALLET_TYPE,
    updating,
  };
}

export function updateWalletType({
  purchaseToken,
  walletType,
  needsUnlockSeats,
  needsDisableFlatFare,
}) {
  return (dispatch) => {
    const payload = {
      wallet_type: walletType,
      ...(needsDisableFlatFare && { wants_flat_fare: false }),
    };
    dispatch(updatingWalletType(true));
    purchaseApi
      .update(purchaseToken, payload)
      .then((response) => {
        const {
          purchase: { walletType, flatFareAvailable },
        } = camelizeKeys(response);
        dispatch(receiveWalletType(walletType, flatFareAvailable));
        if (needsUnlockSeats) {
          unlockSeats({ purchaseToken, forceUnlock: true, shouldUpdateState: true, dispatch });
        }
      })
      .catch((reason) => {
        dispatch(setError(202, reason.message, 'error', false));

      })
      .finally(() => {
        dispatch(updatingWalletType(false));
      });
  };
}

/**
 * Returns and object with the bus layouts by trip slug.
 * The resultant object would have the tripSlug for each seat.
 *
 * @param {*} trips
 * @returns {Object} - Object with the bus layouts by trip slug.
 *
 * @example
 * const layouts = getConnectionTripsBusLayouts(trips);
 * console.log(layouts);
 * // {
 * // "trip-slug1": [[ [ {category: "seat", tripSlug: "trip-slug1"}], [{categoty: "hallway"}] ]],
 * // "trip-slug2": [[ [], [] ]],
 * // }
 */
const getConnectionTripsBusLayouts = (trips) => {
  return trips.reduce((acc, trip) => {
    const {
      bus,
      trip: { id: slug },
    } = trip;
    /**
     * Adding the tripSlug to the seats object in the bus layout.
     * It doesn't modifies the bus object structure, it just adds the tripSlug to the seats.
     */
    bus
      .flat(2)
      .filter((item) => item.number)
      .forEach((item) => {
        item.tripSlug = slug;
      });
    acc[slug] = bus;
    return acc;
  }, {});
};

/**
 * Returns an object with the seats by trip slug.
 * The resultant object would have the tripSlug for each seat.
 *
 * @param {*} trips
 * @returns - Object with the bus seats by trip slug.
 *
 * @example
 * const seats = getConnectionTripsSeats(trips);
 * console.log(seats);
 * // {
 * // "trip-slug1": [{ number: "15", tripSlug: "trip-slug1" }, { number: "16", tripSlug: "trip-slug1" }],
 * // "trip-slug2": [{ number: "1", tripSlug: "trip-slug2" }, { number: "2", tripSlug: "trip-slug2" }],
 * // }
 */
const getConnectionTripsSeats = (trips) => {
  return trips.reduce((acc, trip) => {
    const {
      bus,
      trip: { id: slug },
    } = trip;
    acc[slug] = getSeats(bus, slug);
    return acc;
  }, {});
};

/**
 * Action creator to indicate if the bus data is being refreshed.
 * @param {boolean} isRefreshing - Whether the bus data is currently being refreshed.
 * @returns {object} Action object with type REFRESHING_BUS and isRefreshing flag.
 */
export function refreshingBus(isRefreshing) {
  return {
    type: types.REFRESHING_BUS,
    isRefreshing,
  };
}

/**
 * Fetches detailed information about trips based on departure and return fragments.
 * @param {Object} params - The parameters for fetching trip details.
 * @param {Array} params.departureFragments - Array of departure trip fragments.
 * @param {Array} [params.returnFragments=[]] - Array of return trip fragments (optional).
 */
export function fetchTripsDetails({
  departureFragments,
  returnFragments = [],
  saveIn = 'purchase',
}) {
  return (dispatch) => {
    const seatsBus = store.getState().seatsBus.toJS();
    const { features } = store.getState().whitelabelConfig;

    /**
     * @todo Remove this feature flag
     * We can determine if the trip has connections using the trip fragments
     * so we don't really need this feature flag.
     */
    const tenantSupportsTripsWithConnections = features.SUPPORT_TRIPS_WITH_CONNECTIONS;
    const saveInPurchase = saveIn === 'purchase';

    let tripIds = [...departureFragments, ...returnFragments].map((owp) => owp.id);

    if (isSetSeats(seatsBus, tripIds)) {
      return;
    }

    /**
     * When the trip has connections, the purchase response has more than one fragment (trip connections count + 1)
     * The +1 is the "general" trip, which has the primary origin and final destination.
     * The id from this trip is the one that we need to use in order to fetch the bus layouts of the connection trips.
     * This "general" trip always comes in the first position of the fragments array, so we're getting its ID with this code.
     * In that way, we only fetch details of the "general" trip.
     */
    if (tenantSupportsTripsWithConnections) {
      tripIds = [departureFragments[0].id];
      if (returnFragments.length) tripIds.push(returnFragments[0].id);
    }

    const polls = pollingPromises({
      data: tripIds,
      name: 'onReceiveTrip',
      create: (polling, tripId) => trip.getWithBus(tripId, { include: ['bus'] }, polling),
    });
    if (saveInPurchase) {
      dispatch(requestTripsDetails('departs'));
      dispatch(requestTripsDetails('returns'));
    }

    return Promise.all(polls)
      .then((rawTrips) => {
        const trips = camelizeKeys(rawTrips, camelizeConverter).map((rawTrip) => rawTrip.payload);
        const formattedTrips = {
          oneWayPurchases: trips.map(({ trip }) => ({ trip })),
        };
        const busCategories = getAvailableBusCategories(formattedTrips);
        const totalFragmentsCount = trips.length;
        const departureFragmentsCount = tenantSupportsTripsWithConnections
          ? 1
          : departureFragments.length;
        const tripsByWay = {
          departs: trips.slice(0, departureFragmentsCount),
          returns: trips.slice(departureFragmentsCount, totalFragmentsCount),
        };

        ['departs', 'returns'].forEach((way) => {
          const wayTrips = tripsByWay[way];
          const firstTrip = wayTrips[0];
          const tripSlug = firstTrip && firstTrip.trip.id;
          const hasConnections = firstTrip ? firstTrip.hasConnections : false;
          const bus = hasConnections ? {} : firstTrip?.bus;
          if (wayTrips.length === 1 && bus) {
            const trip = firstTrip;
            if (hasConnections) {
              const layouts = getConnectionTripsBusLayouts(trip.trips);
              const seats = getConnectionTripsSeats(trip.trips);
              dispatch(
                setSeatMap(
                  way,
                  firstTrip,
                  tripSlug,
                  saveIn,
                  busCategories,
                  layouts,
                  seats,
                  trip.trips,
                ),
              );
            } else {
              dispatch(setSeatMap(way, firstTrip, tripSlug, saveIn, busCategories));
            }
          }
          if (saveInPurchase) {
            dispatch(setBusCategories(busCategories, tripSlug));
            dispatch(receiveTripsDetails(way, tripSlug));
          }
        });
      })
      .catch((reason) => {
        if (!saveInPurchase) dispatch(setSeatsBusError({ tripId: tripIds[0] }));
        dispatch(setError(203, 'error_when_consulting_a_trip', 'error', saveInPurchase));

      })
      .finally(() => {
        dispatch(refreshingBus(false));
      });
  };
}

export function deletePassenger(token, passengerId) {
  return (dispatch) => {
    dispatch(updatePurchase(true));

    return purchaseApi
      .deletePassenger(token, passengerId)
      .then(({ passengers }) => {
        dispatch(setPassengers(decamelizeKeys(passengers), token));
      })
      .catch((reason) => {
        dispatch(setError(204, reason.message, 'error', false));

      })
      .finally(() => {
        dispatch(updatePurchase(false));
      });
  };
}
/**
 * Selects seats for a specified trip way and saves them based on the context.
 * @param {string} way - The direction of the trip (e.g., 'departure', 'return').
 * @param {Array} seats - An array of seat identifiers to be selected.
 * @param {string} saveIn - The context in which the seat selection is being saved ('purchase' or other).
 * @returns {Object} The action object with type and payload for seat selection.
 */
export function selectSeats(way, seats, saveIn = 'purchase', tripSlug) {
  return {
    type: saveIn !== 'purchase' ? types.SELECT_SEATS_TRIPS : types.SELECT_SEATS,
    way,
    seats,
    tripSlug,
  };
}

export function updateSeat(way, seat, saveIn = 'purchase', tripSlug) {
  return {
    type: saveIn !== 'purchase' ? types.UPDATE_SEAT_TRIPS : types.UPDATE_SEAT,
    way,
    seat,
    tripSlug,
  };
}

export function finishSeatSelection() {
  return (dispatch, getState) => {
    const { purchase, whitelabelConfig } = getState();
    const { features } = whitelabelConfig;
    const { allowsSeatSelection, departs, returns, passengerSelection } = purchase.toJS();
    const walletType = getWalletTypeForPurchase();
    const mainUser = getUserByWalletType(walletType);

    const passengersWithSeats = getInitialPassengers({
      allowsSeatSelection,
      departureSelectedSeats: departs.selectedSeats,
      returnSelectedSeats: returns?.selectedSeats,
      passengerSelection,
      departureTrips: departs.trips,
      returnTrips: returns?.trips || [],
    });

    dispatch({ type: types.FINISHED_SEAT_SELECTION, features, mainUser, passengersWithSeats });
  };
}

export function clearSeats(way) {
  return (dispatch) => {
    dispatch(selectSeats(way, []));
  };
}

export function requestTickets(way) {
  return { type: types.REQUEST_TICKETS, way };
}

export function receiveTickets(way, tickets, error = null) {
  return { type: types.RECEIVE_TICKETS, way, tickets, error };
}

export function refreshBus() {
  return (dispatch) => {
    const { departs, returns = {} } = store.getState().purchase.toJS();
    dispatch(
      fetchTripsDetails({
        departureFragments: departs.fragments,
        returnFragments: returns.fragments,
      }),
    );
  };
}

/**
 * For purchase with connections, each passenger can have multiple tickets for each trip (departure and return).
 * This method asignates seats to each passenger and creates a ticket for each selected seat.
 * Each ticket has the following structure:
 *
 * ```js
 *  {
 *   passenger_id: 1,
 *   category: 'general',
 *   seat: '1A',
 *   trip_slug: 'trip-slug'
 *  }
 * ```
 *
 * @returns {Array} - Returns an array of tickets for each selected seat.
 *
 */
const getTicketsForTripWithConnections = ({
  seats,
  trips,
  passengers,
  isOpenTicket,
  justAdultOpenTicket,
}) => {
  const tickets = [];
  const cleanedWaySeats = seats.filter((seat) => !seat.isPickedAsAdjacent);
  // generate tickets for each passenger, each passenger can have multiple tickets for each trip
  // so we need to iterate over the passengers and the trips
  passengers.forEach((passenger, passengerIndex) => {
    trips.forEach((trip) => {
      const tripSeats = cleanedWaySeats.filter((seat) => seat.tripSlug === trip.trip.id);
      const passengerTripSeat = tripSeats[passengerIndex];
      const category = justAdultOpenTicket && isOpenTicket ? 'general' : passenger.busCategory;
      const ticket = {
        passenger_id: passenger.id,
        category,
        trip_slug: trip.trip.id,
        seat: passengerTripSeat.number,
      };
      tickets.push(ticket);
    });
  });
  return tickets;
};

/**
 * Function that serializes the lock seats payload
 * @param {Array} trips - An array of trip objects. Each object should contain details about a specific trip.
 * @param {Array} passengers - An array of passenger objects. Each object should contain details about a specific passenger.
 * @param {Array} seats - An array of seat objects. Each object should contain details about a specific seat.
 * @param {String} way - A string indicating the direction of the trip. It can only be 'departure' or 'return'.
 * @returns {Object} - Returns an object that represents the serialized payload for locking seats.
 */
function serializeLockSeats(trips, passengers, seats = [], way) {
  const { purchase, whitelabelConfig } = store.getState();
  const { returns, departs } = purchase.toJS();
  const { JUST_ADULT_OPEN_TICKET } = whitelabelConfig.features;

  const busPassengers = passengers.filter((p) => p.category !== 'infant');
  const flightPassengers = passengers;
  const isOpenTicket = way === 'departure' ? wayIsOpenTicket(departs) : wayIsOpenTicket(returns);

  let busTickets = [];
  const wayObject = way === 'departure' ? departs : returns;
  if (wayObject.hasConnections) {
    /**
     * @todo implement adjacent seats for purchase with connections, find a way to unify the logic
     */
    busTickets = getTicketsForTripWithConnections({
      seats,
      trips,
      passengers: busPassengers,
      isOpenTicket,
      justAdultOpenTicket: JUST_ADULT_OPEN_TICKET,
    });
  } else {
    // TODO: Implementar una estrategia con la cual dependiendo de si se tiene asientos contiguos o no, se utilice map o reduce dependiendo del caso
    busTickets = busPassengers.reduce((acc, passenger, index) => {
      const category = JUST_ADULT_OPEN_TICKET && isOpenTicket ? 'general' : passenger.busCategory;
      const seatsWithoutAdjacent = seats.filter((seat) => !seat.isPickedAsAdjacent);
      const seat = seatsWithoutAdjacent[index];
      const newTicket = {
        passenger_id: passenger.id,
        category,
        ...(seat && { seat: seat.number }),
        ...(seat?.seatLevel && { seat_level: seat.seatLevel }),
        ...(seat?.seatFloor && { seat_floor: seat.seatFloor }),
      };
      if (seat && seat.isAdjacentPicked) {
        const adjacentSeatNumber = seat.adjacentSeats.numbers[0];
        newTicket.adjacent_seat = adjacentSeatNumber;
        const adjacentTicket = {
          passenger_id: passenger.id,
          seat: adjacentSeatNumber,
          category: 'general',
        };
        acc.push(newTicket, adjacentTicket);
      } else {
        acc.push(newTicket);
      }
      return acc;
    }, []);
  }

  const flightTickets = flightPassengers.map((passenger) => ({
    passenger_id: passenger.id,
    category: passenger.category,
  }));

  const [trip] = trips;
  if (trip.type === 'bus' || wayObject.hasConnections) {
    return {
      trip_slug: wayObject.hasConnections ? wayObject.tripSlug : trip.id,
      tickets: busTickets,
    };
  }
  return {
    trip_slug: trip.id,
    tickets: flightTickets,
  };
}

export function lockTicketsLegacy(way, token, trips, passengers, seats = []) {
  const busPassengers = passengers.filter((p) => p.category !== 'infant');
  const flightPassengers = passengers;

  const busTickets = busPassengers.map((passenger, index) => ({
    passenger_id: passenger.id,
    category: passenger.busCategory,
    seat: seats[index],
  }));

  const flightTickets = flightPassengers.map((passenger) => ({
    passenger_id: passenger.id,
    category: passenger.category,
  }));

  const payloads = trips.map((trip) => {
    if (trip.type === 'bus') {
      return { trip_slug: trip.id, tickets: busTickets };
    }

    return { trip_slug: trip.id, tickets: flightTickets };
  });

  return (dispatch) => {
    dispatch(requestTickets(way));

    const polls = pollingPromises({
      data: payloads,
      name: 'onReceiveTickets',
      create: (polling, payload) => purchaseApi.createTickets(token, payload, polling),
    });

    return Promise.all(polls)
      .then((polls) => {
        polls.forEach(({ status }) => {
          checkIfRetriesExceeded(status);
        });

        const tickets = polls.map(({ payload }) => payload.tickets);

        dispatch(receiveTickets(way, tickets));
        dispatch(getPurchase(token, true));
      })
      .catch((reason) => {
        const knownErrors = ['occupied'];
        if (knownErrors.includes(reason.type)) {
          dispatch(setError(204, reason.message, 'warning', false));
          dispatch(receiveTickets(way, []));
        } else {
          dispatch(setError(204, 'Ocurrió un error al reservar tus asientos'));
        }

      });
  };
}

/**
 * Function that handles the error when polling tickets
 * @param {Object} reason - Error object
 * @returns dispatch
 */
function lockTicketsPollingError(reason) {
  return (dispatch) => {
    const { purchase } = store.getState();
    const { isExchange } = purchase.toJS();
    const { payload } = reason;

    if (!payload) {
      dispatch(setError(204, 'trip_no_longer_available', 'warning', true));
      return;
    }

    const { errors } = payload;
    const { state } = errors;
    const [error] = state;

    if (/occupied/g.test(error)) {
      const message = error.split(':')[1] || 'try_to_change_your_seats';
      dispatch(setError(204, message, 'error', false));
    } else if (/unavailable_wallet/g.test(error)) {
      const message = error.split(':')[1] || 'try_to_change_your_seats';
      dispatch(setError(204, message, 'error', true, null, isExchange && EXCHANGE_HOME_PATH));
    } else if (/unavailable/g.test(error)) {
      dispatch(setError(204, 'discounts_not_available', 'warning', false));
    } else if (/exchange_limit_reached/g.test(error)) {
      const message = error.split(':')[1];
      dispatch(
        setError(
          204,
          'try_to_change_your_seats',
          'error',
          true,
          message,
          isExchange && EXCHANGE_HOME_PATH,
        ),
      );
    } else if (/invalid_exchange/g.test(error)) {
      const message = error.split(':')[1];
      dispatch(setError(204, 'exchange_cannot_be_used', 'warning', true, message));
    } else if (/exchange_time_exceed/g.test(error)) {
      const message = error.split(':')[1];
      dispatch(
        setError(
          204,
          'exchange_cannot_be_used',
          'error',
          true,
          message,
          isExchange && EXCHANGE_HOME_PATH,
        ),
      );
    } else {
      dispatch(setError(204, 'try_to_change_your_seats', 'error', false));
    }
    dispatch(receiveTickets('departs', [], error));
    dispatch(receiveTickets('returns', [], error));
    if (!/unavailable/g.test(error)) dispatch(clearSeats('departs'));
    if (!/unavailable/g.test(error)) dispatch(clearSeats('returns'));
    dispatch(refreshingBus(true));
    dispatch(refreshBus());

  };
}

export function lockTickets(token, { departs, returns }, passengers) {
  return (dispatch, getState) => {
    const purchase = getState().purchase.toJS();
    const payloads = {};
    const departureSeats = departs[0].selectedSeats;

    if (purchase.departs.hasConnections) {
      payloads.departs = serializeLockSeats(
        purchase.departs.trips || [],
        passengers,
        departureSeats,
        'departure',
      );
    } else {
      payloads.departs = serializeLockSeats(departs, passengers, departureSeats, 'departure');
    }

    if (returns) {
      const returnSeats = returns[0].selectedSeats;
      if (purchase.returns?.hasConnections) {
        payloads.returns = serializeLockSeats(
          purchase.returns.trips || [],
          passengers,
          returnSeats,
          'returns',
        );
      } else {
        payloads.returns = serializeLockSeats(returns, passengers, returnSeats, 'returns');
      }
    }

    dispatch(requestTickets('departs'));
    if (returns) dispatch(requestTickets('returns'));

    const polls = pollingPromises({
      data: [payloads],
      name: 'onReceiveTickets',
      create: (polling, payload) => purchaseApi.createTickets(token, payload, polling),
    });

    return Promise.all(polls)
      .then((polls) => {
        polls.forEach(({ status }) => {
          checkIfRetriesExceeded(status);
        });

        const departsTickets = polls.map(({ payload }) => payload.departs.tickets);
        dispatch(receiveTickets('departs', departsTickets));

        if (returns) {
          const returnsTickets = polls.map(({ payload }) => payload.returns.tickets);
          dispatch(receiveTickets('returns', returnsTickets));
        }
        dispatch(getPurchase(token, true));
      })
      .catch((reason) => {
        dispatch(lockTicketsPollingError(reason));
      });
  };
}

export function clearTicketsErrorType() {
  return {
    type: types.CLEAR_TICKETS_ERROR_TYPE,
  };
}

/**
 * Function that removes the file size segment from the document
 *
 * @param {string} document - The document with the file size segment
 * @returns {string} The document without the file size segment
 */
function documentWithoutSize(document) {
  const originalDataSegment = document.split('::')[1];
  return originalDataSegment;
}

export function savePassengersWithTickets(token, fields, trips) {
  return (dispatch) => {
    const { env, features } = store.getState().whitelabelConfig;
    const purchasePayloadTypes = {
      gfa: decamelizeKeys({
        purchaserNationalityId: 1,
        ...omit(fields, 'passengers'),
        email: fields.email,
      }),
      default: decamelizeKeys({
        purchaserNationalityId: 1,
        ...omit(fields, 'passengers'),
        email: features.NEEDS_PASSENGER_EMAIL_ON_PURCHASER_PAYLOAD
          ? fields.passengers[0].email
          : fields.email,
      }),
    };
    const { availableWallets = [] } = store.getState().purchase.toJS();
    const purchasePayload = purchasePayloadTypes[env.brand] ?? purchasePayloadTypes.default;

    const brand2ProcessorMap = getBrand2ProcessorMap();
    const passengersPayload = decamelizeKeys(
      fields.passengers.map((passenger) => ({
        ...passenger,
        ...(features.DOCUMENT_PER_PASSENGER && {
          document: documentWithoutSize(passenger?.document),
        }),
        nationalityId: purchasePayload.purchaser_nationality_id,
        document_type: brand2ProcessorMap[passenger.documentType],
        isoCountryCode: passenger.nationality,
        phone: normalizeRegex('[^0-9]', passenger.phone),
      })),
    );

    dispatch(postPassengers());

    // The user token is set in the requests
    setUserTokenForAPI();
    // Getting the wallet type to update the purchase
    const walletType = getWalletTypeForPurchase();
    const isValidWalletType = walletType && availableWallets?.includes(walletType);
    const purchasePayloadWithLoyalty = {
      ...purchasePayload,
      wallet_type: isValidWalletType ? walletType : null,
    };

    return purchaseApi
      .update(token, purchasePayloadWithLoyalty)
      .then((purchaseResponse) => {
        if (purchaseResponse.purchase.passengers.length) {
          return purchaseApi.updatePassengers(token, passengersPayload);
        }

        passengersPayload.forEach((passenger) => {
          Reflect.deleteProperty(passenger, 'id');
        });
        return purchaseApi.createPassengers(token, passengersPayload);
      })
      .then((payload) => {
        const { departs, returns } = trips;
        const { passengers } = camelizeKeys(payload);
        const { features } = store.getState().whitelabelConfig;
        const departureSeats =
          departs[0].selectedSeats && departs[0].selectedSeats.map(({ number }) => number);

        if (features.USE_LEGACY_LOCK_TICKETS) {
          dispatch(lockTicketsLegacy('departs', token, departs, passengers, departureSeats));

          if (returns) {
            const returnSeats =
              returns[0].selectedSeats && returns[0].selectedSeats.map(({ number }) => number);
            dispatch(lockTicketsLegacy('returns', token, returns, passengers, returnSeats));
          }
        } else {
          dispatch(lockTickets(token, { departs, returns }, passengers));
        }
        dispatch(setPassengers(passengers, token));
      })
      .catch((reason) => {
        dispatch(setPassengers([], token));
        dispatch(setError(202, reason.message, 'error', false));

      });
  };
}

export function fetchingTaxpayerId(updating) {
  return {
    type: types.FETCHING_TAXPAYER_ID,
    updating,
  };
}

/**
 * Validates the Taxpayer ID for Cruz del Sur
 *
 * @param {string} documentId - The taxpayer ID to validate
 * @returns {Function} A thunk action creator
 */
export function validateTaxpayerId(documentId) {
  return (dispatch, getState) => {
    if (documentId.length < 11) {
      dispatch(changeFormField('purchaser', 'taxpayerLegalName', ''));
      dispatch(changeFormField('purchaser', 'taxpayerAddress', ''));
      return;
    }
    const state = getState();
    const { purchaseUrl, desktopKey, mobileKey } = state.whitelabelConfig.env.api;
    const purchaseToken = state.purchase.toJS().token;
    const isDesktop = getProductType() === 'desktop';

    dispatch(fetchingTaxpayerId(true));
    fetch(
      `
      ${purchaseUrl}/v2/tax_payer/validate?taxpayer_id=${documentId}&purchase_token=${purchaseToken}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${isDesktop ? desktopKey : mobileKey}`,
        },
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const { taxpayer_legal_name: taxpayerLegalName, taxpayer_address: payerAddress } =
          data.body;

        if (!taxpayerLegalName || taxpayerLegalName === 'unknown or empty RUC parameter') {
          dispatch(changeFormField('purchaser', 'taxpayerLegalName', 'RUC Invalido'));
          dispatch(changeFormField('purchaser', 'taxpayerAddress', ''));
          throw new Error('Invalid RUC');
        }

        dispatch(changeFormField('purchaser', 'taxpayerLegalName', taxpayerLegalName));
        dispatch(changeFormField('purchaser', 'taxpayerAddress', payerAddress || ''));
      })
      .catch((error) => {
        dispatch(changeFormField('purchaser', 'taxpayerLegalName', 'RUC Invalido'));
        throw new Error('Invalid RUC', error);
      })
      .finally(() => {
        dispatch(fetchingTaxpayerId(false));
      });
  };
}

export function toggleInsurance(updating) {
  return { type: types.TOGGLE_INSURANCE, updating };
}

export function toggleWantsInsurance(token, wants) {
  return (dispatch) => {
    dispatch(toggleInsurance(true));

    return purchaseApi
      .update(token, { wants_insurance: wants })
      .then((purchase) => {
        dispatch(receivePurchase(purchase));
      })
      .catch((reason) => {
        dispatch(setError(205, 'error_when_updating_purchase', 'error', false));

      })
      .finally(() => {
        dispatch(toggleInsurance(false));
      });
  };
}

export function toggleUsingWallet(updating) {
  return { type: types.UPDATE_USING_WALLET, updating };
}

export function requestDiscount() {
  return { type: types.REQUEST_DISCOUNT };
}

export function receiveDiscount() {
  return { type: types.RECEIVE_DISCOUNT };
}

export function setUsingWallet(token, useWallet, amount = null, updating) {
  return (dispatch) => {
    const { walletType } = store.getState().purchase.toJS();
    if (amount && amount <= 0) {
      dispatch(setError(205, 'error_when_using_points', 'error', false));
      return;
    }

    dispatch(toggleUsingWallet(true));

    // return a promise to wait for
    return purchaseApi
      .updateWallet(token, updating ? false : useWallet, { amount })
      .then((payload) => {
        if (updating) return dispatch(setUsingWallet(token, true, amount));

        const message = useWallet
          ? `buy_using_your_points_${walletType}`
          : `buy_without_using_your_points_${walletType}`;
        const messageType = useWallet ? 'success' : 'warning';

        dispatch(resetInstallmentsPlan());
        dispatch(changeFormField('card', 'paymentPlan', 'single'));
        dispatch(resetForm('installmentsSelector'));
        dispatch(receivePurchase(payload));
        dispatch(setError(null, message, messageType, false));
      })
      .catch((reason) => {
        if (reason.code === 'INVALID_USER') {
          dispatch(setError(205, 'user_is_not_a_passenger', 'warning', false));
        } else {
          dispatch(setError(205, 'error_when_using_points', 'error', false));
        }

      })
      .finally(() => dispatch(toggleUsingWallet(false)));
  };
}

export function applyDiscount(token, code, selectedInstallmentsPlan) {
  return (dispatch) => {
    dispatch(requestDiscount());

    return purchaseApi
      .applyDiscountCode(token, code, selectedInstallmentsPlan)
      .then((payload) => {
        const { qualifiesForMonthlyInstallments, paymentPlans } = camelizeKeys(payload.purchase);
        const i18nKey = 'code_applied_to_purchase';
        const currentPlanAvailable = isPlanAvailable(
          qualifiesForMonthlyInstallments,
          selectedInstallmentsPlan,
          paymentPlans,
        );

        if (currentPlanAvailable) {
          const { card, months } = selectedInstallmentsPlan;
          payload.purchase.monthly_selected_plan = months;
          payload.purchase.selectedInstallmentsPlan = {
            ...selectedInstallmentsPlan,
            ...paymentPlans[card][months],
          };
        } else {
          dispatch(resetInstallmentsPlan());
          dispatch(changeFormField('card', 'paymentPlan', 'single'));
          dispatch(resetForm('installmentsSelector'));
        }

        dispatch(setPaymentPlans(paymentPlans));
        dispatch(receivePurchase(payload));
        dispatch(setError(null, i18nKey, 'success', false));
      })
      .catch((reason) => {
        dispatch(resetForm('discountCode'));
        dispatch(
          setError(
            206,
            reason.code === 500 ? 'invalid_discount' : 'error_when_applying_discount_code',
            'error',
            false,
          ),
        );
      })
      .finally(() => {
        dispatch(receiveDiscount());
      });
  };
}

export function formErrors(funnelStep, fields) {
  return {
    type: types.UA_FORM_ERRORS,
    funnelStep,
    fields,
  };
}

export function expirePurchase() {
  return {
    type: types.EXPIRE_PURCHASE,
  };
}

export function setPurchaseExpiration(expiration) {
  return {
    type: types.PURCHASE_SET_EXPIRATION,
    expiration,
  };
}

export function timeoutTick(milisecondsAdded) {
  return (dispatch, getState) => {
    const { purchase } = getState();

    const remainingTime = getExpirationTime(purchase.toJS(), milisecondsAdded);

    if (remainingTime <= 0) {
      dispatch(expirePurchase());
    }
  };
}

export function setEmailStatus(status) {
  return {
    type: types.SET_EMAIL_STATUS,
    status,
  };
}

export function sendEmail(token, email) {
  return (dispatch) => {
    dispatch(setEmailStatus('sending'));
    purchaseApi
      .sendEmail(token, email)
      .then(() => dispatch(setEmailStatus('sent')))
      .catch(() => dispatch(setEmailStatus('error')));
  };
}

export function updatePurchaseFields(purchaseToken, fields) {
  return (dispatch) => {
    const {
      purchase,
      whitelabelConfig: {
        features: {
          NEEDS_PASSENGER_EMAIL_ON_PURCHASER_PAYLOAD,
          IDENTIFICATION_DOCUMENT_FOR_PURCHASER,
        },
      },
    } = store.getState();
    const { passengers } = purchase.toJS();

    const phoneCode = fields.phoneCountry && getCountryCallingCode(fields.phoneCountry);
    const phone =
      phoneCode && fields.phone && parsePhoneNumber(`+${phoneCode}${fields.phone}`).nationalNumber;

    const payload = decamelizeKeys({
      ...fields,
      purchaserNationalityId: 1,
      documentType: IDENTIFICATION_DOCUMENT_FOR_PURCHASER ? fields.documentType : 'CC',
      phone,
      email: NEEDS_PASSENGER_EMAIL_ON_PURCHASER_PAYLOAD ? passengers[0].email : fields.email,
      ...(fields.phoneCountry && { phone_code: phoneCode }),
      ...(fields.taxpayerId && {
        taxpayer_id: fields.taxpayerId,
        taxpayer_legal_name: fields.taxpayerLegalName,
        taxpayer_address: fields.taxpayerAddress || '',
      }),
    });
    dispatch(updatePurchase(true));

    return purchaseApi
      .update(purchaseToken, payload)
      .then((response) => {
        dispatch(receivePurchase(response));
      })
      .catch((reason) => {
        dispatch(updatePurchase(false));
        dispatch(setError(204, reason.message, 'error', false));
        return Promise.reject(reason);
      });
  };
}

export function insurancesChecked() {
  return {
    type: types.PURCHASE_INSURANCES_CHECKED,
  };
}

export function updatePurchaseUser(purchaseToken) {
  return (dispatch) => {
    dispatch(updatePurchase(true));

    return purchaseApi
      .update(purchaseToken, {})
      .then((response) => {
        dispatch(receivePurchase(response));
      })
      .catch((reason) => {
        dispatch(updatePurchase(false));
        dispatch(setError(204, reason.message, 'error', false));
        return Promise.reject(reason);
      });
  };
}

export function purchaseStatusResult(purchaseToken, minutes = 1, interval = 2) {
  return new Promise((resolve, reject) => {
    const pollOptions = {
      interval: interval * 1000,
      maxRetries: (minutes * 60) / interval,
    };

    const onReceivePurchase = (poll) => {
      if (!['pending', 'completed'].includes(poll.status)) {
        return reject(poll);
      }

      const { state } = poll.payload.purchase;
      switch (state) {
        case 'completed':
          resolve(poll.payload);
          break;
        case 'pending':
          break;
        default:
          reject(poll.payload);
          break;
      }
    };

    purchaseApi.get(purchaseToken, null, {
      onReceivePurchase,
      options: pollOptions,
    });
  });
}

export function pollPurchaseComplete(purchasetoken) {
  return (dispatch) => {
    purchaseStatusResult(purchasetoken)
      .then((purchaseResponse) => {
        dispatch(receivePurchase(purchaseResponse));
      })
      .catch((e) => {

      });
  };
}

/**
 * Carbon ancillary actions
 */
export function toggleCarbonOffset(updating) {
  return { type: types.TOGGLE_CARBON_OFFSET, updating };
}

export function toggleWantsCarbonOffset(token, wants) {
  return (dispatch) => {
    dispatch(toggleCarbonOffset(true));

    return purchaseApi
      .update(token, { wants_carbon_offset: wants })
      .then((purchase) => {
        dispatch(receivePurchase(purchase));
      })
      .catch((reason) => {
        dispatch(setError(205, 'error_when_updating_purchase', 'error', false));

      })
      .finally(() => {
        dispatch(toggleCarbonOffset(false));
      });
  };
}

/**
 * Set purchase expiration after extending
 * @param {*} extendedAt - Extended date
 * @param {*} expiresAt - New expiration date
 * @param {*} currentTime - Current time of the server
 * @returns dispatch
 */
export function setPurchaseExtendedExpiration(extendedAt, expiresAt, currentTime) {
  return {
    type: types.PURCHASE_EXTENDED_EXPIRATION,
    expiresAt,
    extendedAt,
    currentTime,
  };
}

/**
 * Request to extend purchase expiration
 * @param {String} token - Purchase token
 * @param {Function} errorFunction - function to execute if an error occures
 * @returns dispatch
 */
export function requestExtendPurchaseExpiration(token, errorFunction) {
  return (dispatch) => {
    dispatch(updatePurchase(true));
    purchaseApi
      .increaseExpiration(token)
      .then((purchase) => {
        if (!Object.keys(purchase).length) throw new Error();

        const {
          extendedAt,
          expiresAt,
          currentTime,
          incomingTicketRequestId,
          outgoingTicketRequestId,
          type,
        } = camelizeKeys(purchase);
        if (type !== 'ticket_request') {
          dispatch(setPurchaseExtendedExpiration(extendedAt, expiresAt, currentTime));
          dispatch(updatePurchase(false));
        } else {
          const dataToPoll = [{ id: outgoingTicketRequestId }];
          if (incomingTicketRequestId) dataToPoll.push({ id: incomingTicketRequestId });
          const polls = pollingPromises({
            data: dataToPoll,
            name: 'onReceiveTickets',
            create: (polling, payload) => purchaseApi.startTicketsPoll(token, payload, polling),
          });
          Promise.all(polls)
            .then((polls) => {
              polls.forEach(({ status }) => {
                checkIfRetriesExceeded(status);
              });
              dispatch(getPurchase(token));
            })
            .catch((reason) => {
              dispatch(lockTicketsPollingError(reason));
              dispatch(updatePurchase(false));
            });
        }
      })
      .catch(() => {
        dispatch(setError(205, 'error_when_extending_purchase', 'error', false));
        dispatch(updatePurchase(false));
        if (errorFunction) errorFunction();
      });
  };
}

/** Error handle of membership discount request */
function membershipDiscountError(errorCode, walletName, dispatch) {
  if (errorCode === 401 && (walletName === 'travelpass' || walletName === 'costapass')) {
    triggerSignOutWidget(walletName);
    dispatch(setCostaPassError(errorCode));
  } else {
    dispatch(setError(205, `error_when_using_wallet_${walletName}`, 'error', false));
  }
}

/** Method to apply a loyalty membership discount */
export function membershipDiscountPayment(token, discount, walletName) {
  return (dispatch) => {
    // The user token is set in the requests
    setUserTokenForAPI();
    dispatch(toggleUsingWallet(true));

    purchaseApi
      .membershipDiscountPayment(token, discount)
      .then(() => {
        dispatch(getPurchase(token));
        dispatch(setError(200, `buy_using_your_points_${walletName}`, 'success', false));
      })
      .catch((error) => {
        const { code } = error;
        membershipDiscountError(code, walletName, dispatch);
      })
      .finally(() => {
        dispatch(toggleUsingWallet(false));
      });
  };
}

/** Method to delete the membership discount applied */
export function membershipDiscountPaymentDelete(token, walletName) {
  return (dispatch) => {
    // The user token is set in the requests
    setUserTokenForAPI();
    dispatch(toggleUsingWallet(true));

    purchaseApi
      .membershipDiscountPaymentDelete(token)
      .then(() => {
        dispatch(setError(205, `buy_without_using_wallet_${walletName}`, 'warning', false));
        dispatch(getPurchase(token));
      })
      .catch((error) => {
        const { code } = error;
        membershipDiscountError(code, walletName, dispatch);
      })
      .finally(() => {
        dispatch(toggleUsingWallet(false));
      });
  };
}

/** Method to apply a loyalty membership discount
 * @param {Boolean} updating - boolean to indicate if the purchase is updating
 */
export function toggleFlatFare({ updating }) {
  return { type: types.TOGGLE_FLAT_FARE, updating };
}

/** Method to apply a loyalty membership discount
 * @param {String} token - purchase token
 * @param {Boolean} wantsFlatFare - boolean to indicate if the user wants flat fare
 */
export function toggleWantsFlatFare({ token, wantsFlatFare }) {
  return (dispatch) => {
    dispatch(toggleFlatFare({ updating: true }));

    return purchaseApi
      .update(token, { wants_flat_fare: wantsFlatFare })
      .then((purchase) => {
        dispatch(receivePurchase(purchase));
      })
      .catch((reason) => {
        dispatch(setError(205, 'error_when_updating_purchase', 'error', false));

      })
      .finally(() => {
        dispatch(toggleFlatFare({ updating: false }));
      });
  };
}
