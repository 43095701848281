import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Text, Link } from '@reservamos/elements';
import ModalPopUp from '../../../ui/atoms/ModalPopUp';
import 'styles/components/payment/Insurance';
import useInsuranceConfig from '../../../hooks/useInsuranceConfig';

const ModalContentTripInsurance = ({ message, onAccept, onReject }) => {
  const { icon, detailsUrl } = useInsuranceConfig();
  const { t } = useTranslation();

  return (
    <ModalPopUp
      modalTitle={t('passengers:label.add_coverage')}
      modalIconType={icon}
      buttonPrimaryText={t('passengers:button.yes_thanks_coverage')}
      buttonSecondaryText={t('passengers:button.no_thanks')}
      onClickPrimary={onAccept}
      onClickSecondary={onReject}
    >
      <Text fontFamily="secondary" size="S">
        {message}
      </Text>
      {detailsUrl && (
        <Text fontFamily="secondary" size="S">
          <Link
            text={t('insurance:terms_conditions')}
            href={detailsUrl}
            type="primary"
            fontFamily="secondary"
            newTab
          />
          .
        </Text>
      )}
    </ModalPopUp>
  );
};

ModalContentTripInsurance.propTypes = {
  message: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ModalContentTripInsurance;
