import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Currency, Spacing, Text } from '@reservamos/elements';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import 'styles/components/PriceTooltip';

const PriceTooltip = ({
  color,
  earlyDiscount,
  total,
  tripId,
  amenities,
  availabilityCategories,
  providerDiscounts,
  shouldDisplayFromPriceLabel,
}) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();

  return (
    <ProviderInfo
      amenities={amenities}
      providerDiscounts={providerDiscounts}
      availabilityCategories={availabilityCategories}
      variant="icon"
      initialTab={1}
      tripId={tripId}
    >
      <Spacing size="XS" alignItems="baseline" justifyContent="flex-end">
        {Boolean(earlyDiscount) && (
          <div className="result-mobile-click">
            <button
              className="discount-etn"
              type="button"
              aria-label="mute"
              title={t('purchase:early_discount')}
            />
          </div>
        )}
        <Spacing vertical size="XS">
          {shouldDisplayFromPriceLabel && (
            <Text size="XS" color="grayLight">
              {t('trips:label.from_price')}
            </Text>
          )}
          <Currency
            sign={getCurrencyPrefix()}
            price={total}
            decimals={env.tripPriceDecimals || 0}
            color={color}
            currency={getCurrencySuffix()}
            size="XL"
            mobileSize="L"
            weight="bold"
          />
        </Spacing>
      </Spacing>
    </ProviderInfo>
  );
};

PriceTooltip.propTypes = {
  total: PropTypes.number.isRequired,
  color: PropTypes.string,
  tripId: PropTypes.string.isRequired,
  earlyDiscount: PropTypes.bool,
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  availabilityCategories: PropTypes.array,
  providerDiscounts: PropTypes.array,
  shouldDisplayFromPriceLabel: PropTypes.bool,
};

PriceTooltip.defaultProps = {
  color: '',
  earlyDiscount: false,
  shouldDisplayFromPriceLabel: false,
};

export default PriceTooltip;
