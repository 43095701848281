import React from 'react';
import PropTypes from 'prop-types';
import { SeatNumber, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import 'styles/components/SelectedSeats';
import { useSelector } from 'react-redux';
import { isPetFriendlySeat } from '../utils/purchase/seats';

const SelectedSeats = ({ seats, seatProps, showPetFriendly }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('passengers');
  const hasName = seats.some((seat) => seat.firstName !== undefined);

  return (
    <Spacing size={hasName ? 'M' : 'S'} vertical={hasName}>
      <>
        {seats.map(({ number: seatNumber, category, firstName, lastName, isPickedAsAdjacent }) => {
          const { className: classFromSeatProps } = seatProps(seatNumber);
          // TODO: Patch to remove pet-seat if showPetFriendly is false. This is a temporary solution. Issue: https://reservamossaas.atlassian.net/browse/ENT-809
          const classFromPetFriendly =
            showPetFriendly && isPetFriendlySeat(category)
              ? 'pets-seat-selected'
              : 'selected-seats-boxes';
          const className = features.WOMAN_SEATS_ENABLED
            ? `selected-seats-boxes ${classFromSeatProps || ''}`
            : classFromPetFriendly;
          const seatType = isPickedAsAdjacent ? 'adjacent' : 'selected';
          return (
            <Spacing alignItems="center" justifyContent="space-between" key={seatNumber}>
              {hasName && (
                <div>
                  <Text weight="bold">{`${firstName} ${lastName}`}</Text>
                  <Text size="S">{t(`type.${category}`)}</Text>
                </div>
              )}
              <SeatNumber type={seatType} number={seatNumber} className={className} />
            </Spacing>
          );
        })}
      </>
    </Spacing>
  );
};

SelectedSeats.propTypes = {
  seats: PropTypes.array,
  seatProps: PropTypes.func,
  showPetFriendly: PropTypes.bool,
};

SelectedSeats.defaultProps = {
  seats: [],
  seatProps: () => ({}),
  showPetFriendly: false,
};

export default SelectedSeats;
