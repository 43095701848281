import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LoadingScreen from 'components/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'user-analytics';

const LoadingRedirect = ({
  brand,
  redirect,
  departure,
  returns,
  roundTrip,
  isUpdating,
  redirectUrl,
  waitForPurchase,
  purchaseState,
}) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('search');

  const redirectToTrip = useCallback(() => {
    const brandUrl = env.redirectPartners[brand];
    const params = new URLSearchParams({
      ...departure,
      ...returns,
      ...{ roundtrip: roundTrip, from: env.brand },
    }).toString();

    const url = `${brandUrl.funnelUrl || brandUrl}purchase?${params}`;
    window.location.replace(url);
  }, [brand, departure, returns, roundTrip, env]);

  const redirectToTripAfterPurchase = useCallback(() => {
    if (isUpdating) return;

    // Track the source of the redirect. API (New) or Funnel (Old)
    const redirectSource = redirectUrl ? 'api' : 'funnel';
    trackEvent('Redirected From', { redirectedFrom: redirectSource });

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else if (purchaseState !== 'attempt') {
      redirectToTrip();
    }
  }, [isUpdating, redirectUrl, redirectToTrip, purchaseState]);

  useEffect(() => {
    if (redirect && !waitForPurchase) {
      const timer = setTimeout(() => {
        redirectToTrip();
      }, 2000);

      return () => clearInterval(timer);
    }

    if (redirect && waitForPurchase) {
      redirectToTripAfterPurchase();
    }
  }, [redirect, redirectToTrip, waitForPurchase, redirectToTripAfterPurchase]);

  if (!redirect) return null;

  return <LoadingScreen loadingText={t('redirecting_line', { line: brand })} />;
};

LoadingRedirect.propTypes = {
  brand: PropTypes.string,
  redirect: PropTypes.bool,
  departure: PropTypes.object,
  returns: PropTypes.object,
  roundTrip: PropTypes.bool,
  isUpdating: PropTypes.bool,
  redirectUrl: PropTypes.string,
  waitForPurchase: PropTypes.bool,
  purchaseState: PropTypes.string,
};

LoadingRedirect.defaultProps = {
  brand: '',
  redirect: false,
  departure: {},
  returns: {},
  roundTrip: false,
  isUpdating: false,
  redirectUrl: '',
  waitForPurchase: false,
  purchaseState: '',
};

export default LoadingRedirect;
