import React from 'react';
import { Text, ToggleSwitch, Spacing, Icon } from '@reservamos/elements';
import PropTypes from 'prop-types';
import adjacentIcon from 'images/badges/adjacent-seat.svg';
import womanIcon from 'images/brands-unique/gfa/badge-woman.svg';

const icons = {
  adjacent: adjacentIcon,
  woman: womanIcon,
};

/**
 * Renders a toggle label component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label text.
 * @param {string} props.iconType - The type of icon.
 * @param {boolean} props.value - The value of the toggle switch.
 * @param {boolean} props.fillBackground - The flag to fill the background.
 * @param {function} props.onChange - The function to handle the change event.
 * @param {boolean} props.error - The flag to indicate an error.
 * @returns {JSX.Element} The rendered component.
 */
const ToggleLabel = ({ label, iconType, value, fillBackground, onChange, error }) => {
  return (
    <Spacing alignItems="center" justifyContent="space-between">
      <Spacing size="XS" alignItems="center">
        {iconType && <Icon type={icons[iconType]} size="S" />}
        <Text size="XS" color={error ? 'error' : 'grayStrong'}>
          {label}
        </Text>
      </Spacing>
      <ToggleSwitch size="S" checked={value} fillBackground={fillBackground} onClick={onChange} />
    </Spacing>
  );
};

ToggleLabel.propTypes = {
  iconType: PropTypes.oneOf(['', 'adjacent', 'woman']),
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  fillBackground: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

ToggleLabel.defaultProps = {
  iconType: '',
};

export default ToggleLabel;
