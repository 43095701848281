/**
 * OpenPay engine implementation.
 */
function OpenPay() {
  /**
   * Generates a token using OpenPay.
   * @async
   * @function generateToken
   * @param {Object} cardData - The card details.
   * @param {string} cardData.card_number - The card number.
   * @param {string} cardData.holder_name - The card holder's name.
   * @param {string} cardData.expiration_year - The card's expiration year.
   * @param {string} cardData.expiration_month - The card's expiration month.
   * @param {string} cardData.cvv2 - The card's CVV.
   * @returns {Promise<string>} The OpenPay token.
   * @throws {Error} If OpenPay is not initialized or token creation fails.
   */
  const generateToken = async (cardData) => {
    if (!window.OpenPay) {
      console.error('OpenPay is not initialized');
      throw new Error('OpenPay is not properly initialized. Please try again later.');
    }

    return new Promise((resolve, reject) => {
      window.OpenPay.token.create(
        cardData,
        // Handle success
        (res) => {
          resolve(res.data.id);
        },
        // Handle error
        (error) => {
          reject(new Error('Failed to create token. Please try again.', error));
        },
      );
    });
  };

  return { generateToken };
}

const openPay = OpenPay();

export default openPay;
