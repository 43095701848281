import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Spacing, Text } from '@reservamos/elements';
import { When } from 'react-if';

/**
 * PassengersStatements component.
 * Render both the passengers disclaimer and the passengers
 * instructions to upload documents.
 *
 * Only renders if features flags are enabled.
 * @returns {JSX.Element} The PassengersStatements component.
 */
const PassengersStatements = () => {
  const {
    features,
    env: { brand },
  } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('passengers');

  return (
    <When
      condition={
        Boolean(features.PASSENGERS_SHOW_ID) ||
        Boolean(features.SHOW_INFANTS_DISCLAIMER) ||
        Boolean(features.SHOW_FOREIGN_DISCLAIMER)
      }
    >
      <Spacing vertical size="M">
        <When condition={Boolean(features.PASSENGERS_SHOW_ID)}>
          <Spacing size="S">
            <i className="id-icon" />
            <Text size="S">
              {t('remember_your_official_identification_1')}{' '}
              <b>{t('remember_your_official_identification_2', { context: brand })}</b>
            </Text>
          </Spacing>
        </When>

        <When condition={Boolean(features.SHOW_INFANTS_DISCLAIMER)}>
          <Spacing size="S">
            <i className="minor-icon" />
            <Text size="S">{t('infant_disclaimer', { context: brand })}</Text>
          </Spacing>
          {features.SHOW_SECOND_INFANT_DISCLAIMER && (
            <Spacing size="S">
              <i className="minor-icon" />
              <Text size="S">{t('infant_disclaimer_2', { context: brand })}</Text>
            </Spacing>
          )}
        </When>

        <When condition={Boolean(features.SHOW_FOREIGN_DISCLAIMER)}>
          <Spacing size="S">
            <Icon type="CircleInfo" size="S" />
            <Spacing size="S" vertical>
              <Text size="S">{t('foreign_disclaimer_1', { context: brand })}</Text>
              <Text size="S">{t('foreign_disclaimer_2', { context: brand })}</Text>
              <Text size="S">{t('foreign_disclaimer_3', { context: brand })}</Text>
            </Spacing>
          </Spacing>
        </When>

        <When condition={Boolean(features.DOCUMENT_PER_PASSENGER)}>
          <Spacing vertical size="S">
            <Spacing size="S">
              <Icon type="CircleInfo" size="S" />
              <Spacing size="XS" vertical>
                <Text size="S">{t('instructions.file_upload')}</Text>
              </Spacing>
            </Spacing>
          </Spacing>
        </When>
      </Spacing>
    </When>
  );
};

export default PassengersStatements;
