import defaultEnvs from 'config/defaultEnvs';
import defaultFeatures from 'config/defaultFeatures';

const whitelabelContextEndpoint = process.env.RESERVAMOS_PUBLIC_WL_CONTEXT_ENDPOINT;

const getRemoteConfig = async () => {
  const response = await fetch(whitelabelContextEndpoint);
  const whitelabelContext = await response.json();
  const {
    environment_variables: environment,
    feature_flags: features,
    copies,
  } = whitelabelContext.config;
  return {
    environment,
    features,
    copies,
  };
};

const localConfigsTenantName = process.env.RESERVAMOS_PUBLIC_LOCAL_CONFIG_TENANT_NAME;
const emptyConfig = { environment: {}, features: {}, copies: {} };

const getLocalConfig = async () => {
  if (!localConfigsTenantName) return emptyConfig;
  try {
    const { default: environment } = await import(
      `config/${localConfigsTenantName}/environment.js`
    );
    const { default: features } = await import(`config/${localConfigsTenantName}/features.js`);
    const { default: copies } = await import(`config/${localConfigsTenantName}/copies.js`);
    const localConfigs = {
      environment,
      features,
      copies,
    };

    return localConfigs;
  } catch (e) {
    throw new Error(
      `Could not load local config for ${localConfigsTenantName}. Please check if the tenant name is correct or if the config files exist in the config folder.`,
    );
  }
};

const useOnlyLocalConfig = process.env.RESERVAMOS_PUBLIC_USE_ONLY_LOCAL_CONFIG === 'true';

async function fetchWhiteLabelConfig() {
  let remoteConfig = await getRemoteConfig();
  if (useOnlyLocalConfig) {

    remoteConfig = emptyConfig;
  }
  const localConfig = await getLocalConfig();

  return {
    env: {
      ...defaultEnvs,
      ...remoteConfig.environment,
      ...localConfig.environment,
    },
    features: {
      ...defaultFeatures,
      ...remoteConfig.features,
      ...localConfig.features,
    },
    copies: {
      ...remoteConfig.copies,
      ...localConfig.copies,
    },
  };
}

export default fetchWhiteLabelConfig;
