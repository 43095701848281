import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  description: PropTypes.string.isRequired,
};

const TransferItem = ({ description }) => (
  <div className="route-detail-row transfer">
    <div className="rd-left" />

    <div className="rd-center">
      <i className="ic-transfer" />
    </div>

    <div className="rd-right bus">
      <p>
        <span>{description}</span>
      </p>
    </div>
  </div>
);

TransferItem.propTypes = propTypes;

export default TransferItem;
