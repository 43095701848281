import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, Spacing, Text, Icon, Gap } from '@reservamos/elements';
import whatsappIcon from 'images/icons/whatsapp-icon.svg';

/**
 * Component to show the contact information for help
 *
 * @param {Object} props - The component props
 * @param {string} props.email - The email to contact
 * @param {Array} props.phones - The phones to contact
 * @param {string} props.invoiceUrl - The url to bill the trip
 * @param {Array} props.whatsapp - The whatsapp numbers to contact
 * @returns {JSX.Element} The rendered component
 */
const HelpContact = ({ email, phones, invoiceUrl, whatsapp }) => {
  const { t } = useTranslation('general');
  if (!email && phones.length === 0) return null;

  const phoneItems = phones
    .map((phone) => (
      <Link
        key={phone.value}
        href={`tel:${phone.value}`}
        text={phone.display}
        size="M"
        type="accent"
      />
    ))
    .reduce((links, link, index) => {
      return index === 0 ? [link] : [...links, ', ', link];
    }, []);

  const whatsappItems = whatsapp
    .map((item) => (
      <Link
        key={item.value}
        href={`https://wa.me/52${item.value}`}
        text={item.display}
        size="M"
        type="accent"
        newTab
      />
    ))
    .reduce((links, link, index) => {
      return index === 0 ? [link] : [...links, ', ', link];
    }, []);

  return (
    <div className="tickets-message">
      <Spacing size="L" vertical>
        {email && (
          <Spacing size="XS" vertical>
            <Text size="S">{`${t('text.if_you_have_doubts_or_questions')} ${t(
              'text.write_us_to_the_mail',
            )} `}</Text>
            <Text>
              <Link href={`mailto:${email}`} text={email} size="M" type="accent" />
            </Text>
          </Spacing>
        )}

        {Boolean(phoneItems.length) && (
          <Spacing size="XS" vertical>
            <Text size="S">
              {!email && t('text.if_you_have_doubts_or_questions')}
              {phoneItems.length > 1 ? (
                <>{t('text.you_can_contact_us_on_the_phones')}</>
              ) : (
                <>{t('text.you_can_also_contact_us_on_the_phone')}</>
              )}
            </Text>
            <Text size="S">{phoneItems}</Text>
          </Spacing>
        )}

        {Boolean(whatsapp.length) && (
          <Spacing size="XS" vertical>
            <Text size="S">{t('text.you_can_contact_us_on_whatsapp')}</Text>
            <Gap display="inline-flex" alignItems="center">
              <Icon size="S" type={whatsappIcon} />
              <Text size="S">{whatsappItems}</Text>
            </Gap>
          </Spacing>
        )}

        {invoiceUrl && (
          <Spacing size="XS" vertical>
            <Text size="S">{`${t('text.bill_your_trip')} `}</Text>
            <Text>
              <Link
                href={invoiceUrl}
                text={t('text.bill_your_trip_link')}
                size="M"
                type="accent"
                newTab
              />
            </Text>
          </Spacing>
        )}
      </Spacing>
    </div>
  );
};

HelpContact.propTypes = {
  email: PropTypes.string,
  phones: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  invoiceUrl: PropTypes.string,
  whatsapp: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

HelpContact.defaultProps = {
  email: '',
  invoiceUrl: '',
};

export default HelpContact;
