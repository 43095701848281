import { connect } from 'react-redux';
import { selectInstallmentsPlan } from '@/actions/purchase';
import * as types from 'constants/ActionTypes';
import InstallmentsPlanSelectorOv from './InstallmentsPlanSelectorOv';

const mapStateToProps = ({ purchase }) => ({
  initialValues: {
    card: 'visaMc',
  },
  selectedCard: purchase.get('selectedInstallmentsCard'),
});

const mapDispatchToProps = (dispatch) => ({
  onSelectCard: (card) => dispatch({ type: types.SET_INSTALLMENTS_CARD, card }),
  onSelectPlan: (selectedCard, selectedMonths, paymentPlans) => {
    dispatch(
      selectInstallmentsPlan({
        ...paymentPlans[selectedCard][selectedMonths],
        card: selectedCard,
        months: selectedMonths,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentsPlanSelectorOv);

// export default compose(
//   withTranslation('general'),
//   connect(mapStateToProps),
// )(InstallmentsPlanSelectorForm);
