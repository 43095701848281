import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.string.isRequired,
    transportType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    copyrightProtected: PropTypes.bool.isRequired,
    lineUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    mixedProviders: PropTypes.array,
  }).isRequired,
};

const ProviderItemBrand = ({ provider }) => {
  const { transportType, id, name, copyrightProtected, lineUrl, logoUrl, mixedProviders } =
    provider;

  if (transportType === 'mixed') {
    return (
      <ul className="provider-logos">
        {mixedProviders.map((mixedProvider, index) => {
          const { id, copyrightProtected, lineUrl, logoUrl } = mixedProvider;
          const brandId = `${id}-${index}`;

          if (copyrightProtected) {
            return (
              <li key={brandId}>
                <span className="provider-name">{lineUrl}</span>
              </li>
            );
          }

          return (
            <li key={brandId}>
              <img className="results-provider-logo" src={logoUrl} />
            </li>
          );
        })}
      </ul>
    );
  }
  if (id === 'multicarrier') {
    return <span className="provider-name">{name}</span>;
  }
  if (copyrightProtected) {
    return <span className="provider-name">{lineUrl}</span>;
  }

  return <img className="results-provider-logo" src={logoUrl} alt={name} />;
};

ProviderItemBrand.propTypes = propTypes;

export default ProviderItemBrand;
