import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import 'styles/components/search/TransportFilter';

const transportTypeNames = {
  all: 'Ver todos',
  bus: 'Autobús',
  flight: 'Avión',
  mixed: 'Multitransporte',
};

const propTypes = {
  transportTypes: PropTypes.shape({
    bus: PropTypes.bool.isRequired,
    flight: PropTypes.bool.isRequired,
    mixed: PropTypes.bool.isRequired,
  }).isRequired,
  hasProviders: PropTypes.bool.isRequired,
  showing: PropTypes.string,
  isLoading: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  showing: 'all',
  isLoading: true,
};

class TransportFilter extends Component {
  filterClickHandler(transportType) {
    const { showing, onFilterChange } = this.props;

    if (showing === transportType) return;

    onFilterChange(transportType);
  }

  renderFilter(filter, count = 0) {
    const { showing, transportTypes } = this.props;
    const cls = showing === filter ? 'active' : '';

    if (count > 1 && (filter === 'all' || transportTypes[filter])) {
      return (
        <a className={`animated fade-in ${cls}`} onClick={() => this.filterClickHandler(filter)}>
          <i className={`transport-${filter}`} />
          {transportTypeNames[filter]}
        </a>
      );
    }
  }

  render() {
    const {
      isLoading,
      hasProviders,
      transportTypes: { bus, flight, mixed },
    } = this.props;
    const filterCount = bus + flight + mixed;
    let emptyCls = '';

    if (!hasProviders) {
      return null;
    }

    if (!isLoading && filterCount < 2) {
      emptyCls = 'empty';
    }

    return (
      <div className="filters-container">
        <div className={`filters ${emptyCls}`}>
          <Loader isLoading={isLoading} />

          {this.renderFilter('all', filterCount)}
          {this.renderFilter('bus', filterCount)}
          {this.renderFilter('flight', filterCount)}
          {this.renderFilter('mixed', filterCount)}
        </div>
      </div>
    );
  }
}

TransportFilter.propTypes = propTypes;
TransportFilter.defaultProps = defaultProps;

export default TransportFilter;
