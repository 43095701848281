import React from 'react';
import PropTypes from 'prop-types';
import SelectableOption from './SelectableOption';

const OptionsSelector = ({ onChange, options, value }) => (
  <div className="flat-box">
    {options.map(({ description, icon, title, value: optionValue }) => (
      <SelectableOption
        key={optionValue}
        description={description}
        icon={icon}
        onSelect={() => onChange(optionValue)}
        selected={value === optionValue}
        title={title}
      />
    ))}
  </div>
);

OptionsSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      icon: PropTypes.string,
      title: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  value: PropTypes.any,
};

export default OptionsSelector;
