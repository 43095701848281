import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reservamos/elements';
import 'styles/components/search/ResultBox';

/**
 * A component used by ResultDefault to display the terminal name with a link to the location
 * when the USE_TERMINAL_NAME_WITH_LOCATION is enabled
 */
const TerminalNameWithLocationLink = ({ terminalName, mapsLink }) => (
  <div className="result-mobile-click">
    <Link href={mapsLink} text={terminalName} size="S" type="grayLight" newTab />
  </div>
);

TerminalNameWithLocationLink.propTypes = {
  terminalName: PropTypes.string.isRequired,
  mapsLink: PropTypes.string.isRequired,
};

export default TerminalNameWithLocationLink;
