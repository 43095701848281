/**
 *  Cache Trips
 */

import LocalStorageManager from 'utils/localStorageManager';

/**
 * Retrieves the cached trip data from local storage.
 * @returns {Object} The cached trip data or null if no data is found.
 */
function getCachedRecentSeenTrips() {
  const cache = LocalStorageManager.get('seenTrip');
  return cache ?? {};
}

/**
 * Removes the cached trip data from local storage.
 * @param{string} slug - The id trip
 */
export function removeCachedRecentSeenTrip(slug) {
  const cache = getCachedRecentSeenTrips();
  delete cache[slug];
  LocalStorageManager.set('seenTrip', cache);
}

/**
 * Checks if the cached trip data is older than one day.
 * @param {Object} cache - The cache object containing the expiresAt.
 * @returns {boolean} Returns true if the cache is older than one day, otherwise false.
 */
function isCachedRecentSeenTripExpired(cache) {
  const { expiresAt, slug } = cache;
  const currentTime = Date.now();
  const isExpired = expiresAt < currentTime;

  if (isExpired) {
    removeCachedRecentSeenTrip(slug);
    return true;
  }
  return false;
}

/**
 * Saves a trip to the local storage cache.
 * @param {string} tripId - The trip id to be cached.
 */
export function saveCacheRecentSeenTrip(tripId) {
  // expiresAt trip limit time
  const expiresAt = Date.now() + 86400000;
  const cache = getCachedRecentSeenTrips();
  const payloadTime = { expiresAt, slug: tripId };
  if (!cache.prevSeen) {
    cache.prevSeen = payloadTime;
  } else if (cache.prevSeen && cache.prevSeen.slug !== tripId) {
    const prevItem = cache.prevSeen;
    cache[prevItem.slug] = prevItem;
    cache.prevSeen = payloadTime;
  } else {
    return;
  }

  LocalStorageManager.set('seenTrip', cache);
}

/**
 * Checks if the trip is recently seen in the cache.
 * @param {string} tripId - The trip id string to check.
 * @returns {boolean} Returns true if the trip is found in the cache and not old, otherwise false.
 */
export function checkRecentTripSeen(tripId) {
  const cache = getCachedRecentSeenTrips();
  const cachedTrip = cache[tripId];
  if (cachedTrip) return !isCachedRecentSeenTripExpired(cachedTrip);
  return false;
}

/**
 * Deletes expired trips from the cache based on the current time.
 */
export function deleteExpiredRecentSeenTrips() {
  const cache = getCachedRecentSeenTrips();
  const now = Date.now();

  Object.keys(cache).forEach((key) => {
    const { expiresAt } = cache[key];
    if (expiresAt < now) {
      delete cache[key];
    }
  });
  LocalStorageManager.set('seenTrip', cache);
}
/**
 * Cleans up the previously seen trip from the cache.
 */
export function cleanPrevRecentSeenTrip() {
  const cache = getCachedRecentSeenTrips();
  if (cache.prevSeen) {
    cache[cache.prevSeen.slug] = cache.prevSeen;
    delete cache.prevSeen;
    LocalStorageManager.set('seenTrip', cache);
  }
}
