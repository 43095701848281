// @ts-check

import mercadoPago from '../../../engines/mercadoPago';

/**
 * @typedef {import('./PseStrategy').PseStrategy} PseStrategy
 */

/**
 * @function MercadoPagoPseStrategy
 * @returns {PseStrategy}
 */
const MercadoPagoPseStrategy = () => {
  const needsRedirect = true;

  const fetchBanksList = async () => {
    const banks = await mercadoPago.fetchPseBanksList();
    return banks.map(({ id, description }) => ({
      id,
      name: description,
    }));
  };

  const createToken = async () => {
    // Mercado Pago does not need to create a token in order to process a PSE payment
    return '';
  };

  return {
    needsRedirect,
    fetchBanksList,
    createToken,
  };
};

const mercadoPagoPseStrategy = MercadoPagoPseStrategy();

export default mercadoPagoPseStrategy;
