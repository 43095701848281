import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { When } from 'react-if';
import PaymentStatus from 'components/purchase/PaymentStatus';
import EstimatedAt from 'components/purchase/EstimatedAt';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import parsePurchaseStatus from '../../../utils/parsePurchaseStatus';
import DownloadTicketsContainer from '../DownloadTicketsContainer';

const propTypes = {
  estimatedCompletedAt: PropTypes.string,
  purchaseStatus: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  transportType: PropTypes.string.isRequired,
  title: PropTypes.string,
  isOpenTicket: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  departsTicketPdfUrl: PropTypes.string,
  returnTicketPdfUrl: PropTypes.string,
  isReturnOpen: PropTypes.bool,
  isDepartureOpen: PropTypes.bool,
  // isHybridTrip: PropTypes.bool, TODO: Add ExchangeCTA component
};

const CardPaymentInfo = ({
  estimatedCompletedAt,
  purchaseStatus,
  token,
  transportType,
  title,
  isOpenTicket,
  // isHybridTrip, TODO: Add ExchangeCTA component
  email,
  departsTicketPdfUrl,
  returnTicketPdfUrl,
  isReturnOpen,
  isDepartureOpen,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const isCompleted = purchaseStatus === 'completed';
  const isAttempt = purchaseStatus === 'attempt';
  const hasEstimation = Boolean(estimatedCompletedAt);
  const showCompletedAt = !isCompleted && hasEstimation;
  const showSendingTickets = !isCompleted && !hasEstimation && !isAttempt;
  const displayPurchaseToken = features.TICKETS_REFERENCE === 'purchase-token';

  const IS_FLAT = features.FUNNEL_STYLE === 'FLAT';

  return (
    <div className="purchases-section">
      {!IS_FLAT && (
        <div className="purchases-section-inner">
          <PaymentStatus
            title={`${t(`label.${title}`)}:`}
            status={parsePurchaseStatus(purchaseStatus)}
            transporterType={transportType}
            isOpenTicket={isOpenTicket}
            email={email}
          />
        </div>
      )}
      <div className="purchases-section-inner">
        {isCompleted && (
          <DownloadTicketsContainer
            hrefDeparture={departsTicketPdfUrl}
            hrefReturn={returnTicketPdfUrl}
            instructions={t('ticket_instructions_digital', { context: env.brand })}
            isReturnOpen={isReturnOpen}
            isDepartureOpen={isDepartureOpen}
          />
        )}
        {showCompletedAt && <EstimatedAt at={estimatedCompletedAt} reference={token} />}
        {showSendingTickets && (
          <Spacing vertical size="XS">
            <Text>{t('you_tickets_will_be_ready')}</Text>
            <When condition={displayPurchaseToken}>
              <Text size="S">
                {`${t('purchase_reference')} `}
                <Text size="S" elementType="span" weight="bold">
                  {token}
                </Text>
              </Text>
            </When>
          </Spacing>
        )}
      </div>
    </div>
  );
};

CardPaymentInfo.propTypes = propTypes;

CardPaymentInfo.defaultProps = {
  departsTicketPdfUrl: '',
  returnTicketPdfUrl: '',
};

export default CardPaymentInfo;
