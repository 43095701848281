import React from 'react';
import LoyaltyWidget from 'components/LoyaltyWidget';
import { useTranslation } from 'react-i18next';

/**
 * Renders the DotersResultInvitation component.
 * @returns {JSX.Element} The rendered DotersResultInvitation component.
 */
const DotersResultInvitation = () => {
  const { t } = useTranslation('loyalty');
  return (
    <LoyaltyWidget
      id="doters-results-invitation"
      variant="join-button-doters"
      buttonText={t('join_free')}
      visible
    />
  );
};

export default DotersResultInvitation;
