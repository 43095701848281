import { connect } from 'react-redux';
import OpenTicketList from './OpenTicketList';

const mapStateToProps = ({ search, trips }, ownProps) => {
  if (trips.size === 0) return [];
  const { way, isExchange } = ownProps;

  const searchId = search.getIn([way, 'id']);
  const busTrips = trips.getIn([searchId, 'buses', 'trips']);
  if (!busTrips || busTrips.size === 0) return [];

  const openTicketTrips = busTrips
    .filter(
      (t) => t.get('openTicket') && ((isExchange && t.get('redirectTo') === null) || !isExchange),
    )
    .toJS();

  return {
    openTicketTrips,
  };
};

export default connect(mapStateToProps)(OpenTicketList);
