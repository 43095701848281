import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';

const propTypes = {
  at: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};

const EstimatedAt = ({ at, reference }) => {
  const { t } = useTranslation('purchase');
  return (
    <Spacing vertical>
      <Spacing vertical size="S">
        <Text size="S">{t('you_tickets_will_be_ready', { context: 'at' })}</Text>
        <Text size="L" weight="bold">
          {moment(at).format('LLLL')}
        </Text>
      </Spacing>
      <Spacing vertical size="S">
        <Text size="S">{t('purchase_reference')}</Text>
        <Text size="L" weight="bold">
          {reference}
        </Text>
      </Spacing>
    </Spacing>
  );
};

EstimatedAt.propTypes = propTypes;

export default EstimatedAt;
