import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CompleteHeader from '../../../ui/atoms/CompleteHeader';

const PaymentStatusTitle = ({ titleStatus, isExchange, total }) => {
  const { t } = useTranslation('payment');

  const currentStatus =
    isExchange && titleStatus === 'completed' ? `${titleStatus}_exchange` : titleStatus;

  return (
    <CompleteHeader
      title={t('payment_status', { context: currentStatus })}
      status={titleStatus}
      total={total}
    />
  );
};

PaymentStatusTitle.propTypes = {
  titleStatus: PropTypes.string.isRequired,
  isExchange: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default PaymentStatusTitle;
