import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@reservamos/elements';
import './ArrowRight.scss';

/**
 * ArrowRight component renders a right-facing arrow icon composed of three chevrons.
 *
 * @param {Object} props - Component props.
 * @param {string} props.color - Color of the chevron icons (default is white).
 * @returns {React.Component} A component that renders three right-facing chevron icons.
 */
const ArrowRight = ({ color }) => (
  <div className="arrow-right">
    <div className="arrow-right-container">
      <span>
        <Icon type="ChevronRight" size="S" color={color} />
      </span>
      <span>
        <Icon type="ChevronRight" size="S" color={color} />
      </span>
      <span>
        <Icon type="ChevronRight" size="S" color={color} />
      </span>
    </div>
  </div>
);

ArrowRight.propTypes = {
  color: PropTypes.string,
};

ArrowRight.defaultProps = {
  color: 'white',
};

export default ArrowRight;
