import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/Helpers';
import Checkbox from '../../../ui/atoms/Checkbox';

const CheckoutWalletCheckbox = (props) => {
  const {
    checkboxContext,
    setUsingWallet,
    loadingWallet,
    usingWallet,
    usableBalance,
    walletBalanceUsed,
    purchaseToken,
  } = props;

  const { t } = useTranslation('loyalty');

  return (
    <Checkbox
      name="wallet"
      isLoading={loadingWallet}
      checked={usingWallet}
      labelText={t('wallet_checkbox_label', {
        context: checkboxContext(loadingWallet, usingWallet),
        amount: formatCurrency(usableBalance, 2),
        using: formatCurrency(walletBalanceUsed, 2),
        currencyCode: 'MXN',
      })}
      id="wallet-checkbox"
      onChange={() => setUsingWallet(purchaseToken, !usingWallet, 25)}
    />
  );
};

CheckoutWalletCheckbox.propTypes = {
  checkboxContext: PropTypes.func.isRequired,
  setUsingWallet: PropTypes.func.isRequired,
  loadingWallet: PropTypes.bool.isRequired,
  usingWallet: PropTypes.bool.isRequired,
  usableBalance: PropTypes.number.isRequired,
  walletBalanceUsed: PropTypes.number.isRequired,
  purchaseToken: PropTypes.string.isRequired,
};

export default CheckoutWalletCheckbox;
