import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import 'styles/components/IconNotice';

const propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  iconLabel: PropTypes.string,
};

const defaultProps = {
  iconLabel: '',
};

const IconNotice = ({ iconLabel, variant, children }) => (
  <Tooltip className={`icon-notice ${variant}`}>
    <i className={`ic-${variant}`} />

    <span className="icon-notice-link">{iconLabel}</span>

    <div className="tooltip">{children}</div>
  </Tooltip>
);

IconNotice.propTypes = propTypes;
IconNotice.defaultProps = defaultProps;

export default IconNotice;
