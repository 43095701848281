import React from 'react';
import PropTypes from 'prop-types';
import PseStrategyContext from './PseStrategyContext';

const propTypes = {
  children: PropTypes.node.isRequired,
  strategy: PropTypes.object.isRequired,
};

const PseStrategyProvider = ({ children, strategy }) => {
  return <PseStrategyContext.Provider value={strategy}>{children}</PseStrategyContext.Provider>;
};

PseStrategyProvider.propTypes = propTypes;

export default PseStrategyProvider;
