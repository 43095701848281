import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatDuration, formatPercent } from 'utils/Helpers';
import { Text, Button, Currency, BadgeRounded } from '@reservamos/elements';
import '../../../ui/layouts/MatrixResult';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import ProvidersGrid from '../../../ui/layouts/ProvidersGrid';

const propTypes = {
  trip: PropTypes.array.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  way: PropTypes.string.isRequired,
  onSelectClick: PropTypes.func.isRequired,
};

const OpenTripItem = ({ trip, roundTrip, way, onSelectClick }) => {
  const { t } = useTranslation();
  const features = useWhitelabelFeatures();
  const {
    ticket: { nit },
    operationNumbers,
  } = useSelector((state) => state.exchange);

  let pricingKey = 'pricing';
  let lowestPriceKey = 'pricing';

  if (roundTrip && way === 'departure') {
    pricingKey = 'departureRoundTripPricing';
  } else if (way === 'return') {
    pricingKey = 'roundTripPricing';
    lowestPriceKey = 'roundTripPricing';
  }

  const { providerDiscount } = trip[pricingKey];
  const { total: lowestPrice } = trip[lowestPriceKey];
  const { duration, id } = trip;
  const { logoUrl, openTicketLogosUrls = '', abbr } = trip.line;

  const logosToShow =
    Array.isArray(openTicketLogosUrls) && openTicketLogosUrls.length > 0
      ? openTicketLogosUrls
      : [{ name: abbr, url: logoUrl }];

  const discountPercent = providerDiscount
    ? formatPercent(lowestPrice + providerDiscount.amount, lowestPrice)
    : null;

  const isExchange = operationNumbers?.length && nit;

  return (
    <div data-testid={id} className="result-box-container animated fade-in">
      <div className="js-mobile-click" onClick={() => onSelectClick(trip, 0)} />
      <div className="matrix matrix-line matrix-line-openticket">
        {providerDiscount && providerDiscount.amount > 0 && features.DISCOUNT_BADGE_RESULT_ENABLED && (
          <div className="matrix-right-corner">
            <BadgeRounded
              text={`-${discountPercent}`}
              firstColor="discountLight"
              secondColor="discountStrong"
            />
          </div>
        )}

        <div className="matrix-provider">
          <ProvidersGrid logosToShow={logosToShow} column />
        </div>

        <div className="matrix-duration">
          <Text size="S" color="grayLight" weight="bold">
            {formatDuration(duration)}
          </Text>
        </div>

        <div className="matrix-duration-label">
          <Text size="S" color="grayLight">
            {t('general:period')}
          </Text>
        </div>

        <div className="matrix-service">
          <Text size="S" color="grayLight" weight="bold">
            {t('purchase:services', { context: trip.service })}
          </Text>
        </div>
        <div className="matrix-service-label">
          <Text size="S" color="grayLight">
            {t('search:service')}
          </Text>
        </div>

        {!isExchange && (
          <div className="matrix-price">
            <Currency
              price={lowestPrice}
              decimals={1}
              color={providerDiscount && providerDiscount.amount > 0 ? 'discount' : 'primary'}
              currency={getCurrencySuffix()}
              sign={getCurrencyPrefix()}
              size="XL"
              mobileSize="L"
              weight="bold"
            />
          </div>
        )}

        {!isExchange && (
          <div className="matrix-info">
            <Text size="XXS" color="grayLight">
              <em>{t('purchase:label.open_ticket_adult_price')}</em>
            </Text>
          </div>
        )}

        <div className="matrix-action">
          <Button
            text={t('general:select')}
            onClick={() => onSelectClick(trip, 0)}
            variant="accent"
            isRounded
          />
        </div>
      </div>
    </div>
  );
};

OpenTripItem.propTypes = propTypes;

export default OpenTripItem;
