import React from 'react';
import PropTypes from 'prop-types';
import { Box, Gap, Icon, Spacing, Text } from '@reservamos/elements';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelTheme from 'hooks/whitelabel/useWhitelabelTheme';

const SearchRoutes = ({ routeOrigin, routeDestination }) => {
  const { brand } = useWhitelabelEnvs();
  const { headerTextColor } = useWhitelabelTheme();
  const isGFA = brand === 'gfa';

  return (
    <Box
      paddingVertical="XS"
      paddingHorizontal="XS"
      borderRadius="L"
      bgColor={headerTextColor}
      alphaBg={headerTextColor === 'primary' ? 'XS' : 'M'}
      hasHoverBg
    >
      <Spacing size="XS" justifyContent="space-between">
        <Gap />
        <Spacing size={isGFA ? 'XXS' : 'XS'} alignItems="center">
          <Text
            size="S"
            color={headerTextColor}
            weight="semibold"
            maxWidthEllipsis={150}
            mobileMaxWidthEllipsis={isGFA ? 88 : 110}
            xsMobileMaxWidthEllipsis={85}
            textTransform="capitalize"
          >
            {routeOrigin}
          </Text>
          <Icon type="ArrowForward" size="S" color={headerTextColor} />
          <Text
            size="S"
            color={headerTextColor}
            weight="semibold"
            maxWidthEllipsis={150}
            mobileMaxWidthEllipsis={isGFA ? 88 : 110}
            xsMobileMaxWidthEllipsis={85}
            textTransform="capitalize"
          >
            {routeDestination}
          </Text>
        </Spacing>

        <Box bgColor="white" borderRadius="L" paddingHorizontal="S" paddingVertical="S">
          <Spacing alignItems="center" justifyContent="center">
            <Icon type="Search" size="S" color="primary" />
          </Spacing>
        </Box>
      </Spacing>
    </Box>
  );
};

SearchRoutes.propTypes = {
  routeOrigin: PropTypes.string.isRequired,
  routeDestination: PropTypes.string.isRequired,
};

export default SearchRoutes;
