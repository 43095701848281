import store from '@/store';
import currencySuffix from '../constants/currencySuffix';
import currencyPrefix from '../constants/currencyPrefix';

/**
 * Returns the currency suffix
 * @returns String
 */
const getCurrencySuffix = () => {
  const {
    env: { currency },
  } = store.getState().whitelabelConfig;
  let styledCurrency = currencySuffix[currency];
  const isEmptyString = typeof styledCurrency === 'string';
  if (!isEmptyString && !styledCurrency) {
    styledCurrency = currency || 'MXN';
  }
  return styledCurrency;
};

/**
 * Returns the currency prefix
 * @returns String
 */
const getCurrencyPrefix = () => {
  const {
    env: { currency },
  } = store.getState().whitelabelConfig;
  return currencyPrefix[currency] || '$';
};

/**
 * Returns the number of decimals for the currency
 * @returns Number
 */
export const getCurrencyDecimals = () => {
  const {
    env: { tripPriceDecimals },
  } = store.getState().whitelabelConfig;
  return tripPriceDecimals || 0;
};

/**
 * Get a number and return a readable quantity
 * @param {Number} quantity - Quantity to format
 * @returns Number
 */
const getReadableQuantity = (quantity) => {
  const replacesRegexComas = /\B(?=(\d{3})+(?!\d))/g;
  return quantity.toFixed(2).replace(replacesRegexComas, ',');
};

const negativeAsZero = (number) => (number < 0 ? 0 : number);

export { getCurrencyPrefix, getCurrencySuffix, getReadableQuantity, negativeAsZero };
