import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Text } from '@reservamos/elements';
import './FilterTag.scss';

/**
 * Component for displaying a filter tag.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.label - The label text of the filter tag.
 * @param {function} props.onSelect - The function to handle the filter tag selection.
 * @param {string} props.type - The type of the filter tag.
 * @param {string} props.value - The value of the filter tag.
 * @param {boolean} props.isActive - Indicates if the filter tag is active.
 * @param {string} props.i18Key - The translation key for the label.
 * @returns {JSX.Element} The rendered component.
 */
const FilterTag = ({ label, onSelect, type, value, isActive, i18Key, filterType }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const [isChecked, setIsChecked] = useState(false);

  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  /** Handle the click event */
  const handleClick = () => {
    setIsChecked((prevChecked) => !prevChecked);
    onSelect({ type, value, label, isActive: !isChecked, i18Key, filterType });
  };

  useEffect(() => {
    setIsChecked(isActive);
  }, [isActive]);

  return (
    <div className="tag">
      {isChecked && !isFlat && (
        <div className="tag-success">
          <Icon type="Success" size="S" color="success" />
        </div>
      )}
      <button
        className={`${isFlat ? 'tag-flat' : 'tag-classic'} ${isChecked ? 'actived' : ''}`}
        onClick={handleClick}
      >
        <Text size="S" weight="bold" color={isChecked ? 'success' : 'grayStrong'}>
          {label}
        </Text>
      </button>
    </div>
  );
};

FilterTag.propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  i18Key: PropTypes.string,
};

export default FilterTag;
