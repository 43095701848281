import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Input,
  FlatButton,
  Modal,
  OutlineButton,
  Spacing,
  Text,
} from '@reservamos/elements';
import LabelLoading from '../../../ui/molecules/LabelLoading';
import useSearchCouponSelection from './SearchCoupon.hooks';
import 'styles/components/SearchCoupon';

export const SearchCoupon = () => {
  const {
    couponCode,
    handleCouponSubmit,
    handleCouponAppliedSuccesfuly,
    couponCodeDeleted,
    handleDeleteCoupon,
    couponLoading,
    handleCouponInvalidOnLoad,
  } = useSearchCouponSelection();
  const [code, setCode] = useState(couponCode);
  const [codeApplied, setCouponApplied] = useState(false);
  const { t } = useTranslation('search');

  useEffect(() => {
    if (Boolean(couponCode) && !codeApplied) {
      handleCouponAppliedSuccesfuly(couponCode);
      setCouponApplied(true);
    }
  }, [couponCode, handleCouponAppliedSuccesfuly, setCouponApplied, codeApplied]);

  useEffect(() => {
    if (couponCodeDeleted) {
      handleCouponInvalidOnLoad();
      setCode('');
    }
  }, [couponCodeDeleted, handleCouponInvalidOnLoad, setCode]);

  const onChange = ({ target }) => {
    setCode(target.value);
  };

  return (
    <>
      {!couponCode || couponCode === '' ? (
        <div className="code-form-block">
          <Modal
            responsiveSize="S"
            portalSelector=".portalModal"
            title={t('title.promo_code')}
            modalTrigger={
              <div className="code-form-trigger">
                <OutlineButton
                  text={t('button.promo_code')}
                  fontSize="S"
                  mobileSize="XS"
                  onClick={() => {}}
                  variant="accent"
                  padding="M"
                  hasShadow
                  isRounded
                />
              </div>
            }
            zIndex={2999999999}
          >
            {() => (
              <Spacing vertical size="XS">
                <Text size="S">{t('text.promo_code_subtitle')}</Text>
                <div className="code-form-wrapper">
                  <Input
                    label={t('label.promo_code_enter_code')}
                    id="search-coupon"
                    type="text"
                    value={code}
                    onChange={onChange}
                    readOnly={couponLoading}
                  />
                  <Button
                    onClick={() => {
                      setCouponApplied(false);
                      handleCouponSubmit(code);
                    }}
                    variant="primary"
                    withHeight
                    text={t('button.promo_code_apply')}
                    isDisabled={!code || couponLoading}
                  />
                </div>
                {couponLoading && <LabelLoading text={t('label.promo_code_searching')} />}
              </Spacing>
            )}
          </Modal>
        </div>
      ) : (
        <div className="code-form-trigger">
          <Spacing size="XS" vertical alignItems="flex-start">
            <Spacing size="S" alignItems="baseline">
              <Text size="S" weight="semibold">
                {t('label.promo_code_applied')}
              </Text>
              <Badge smallPadding type="info">
                <Text elementType="span" size="S" color="white" lineHeight="1em">
                  {couponCode}
                </Text>
              </Badge>
            </Spacing>

            {couponLoading ? (
              <LabelLoading text={t('label.promo_code_loading')} />
            ) : (
              <FlatButton
                onClick={() => {
                  handleDeleteCoupon();
                  setCode('');
                }}
                size="S"
              >
                {t('label.promo_code_remove')}
              </FlatButton>
            )}
          </Spacing>
        </div>
      )}
    </>
  );
};

export default SearchCoupon;
