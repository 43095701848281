import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Spacing, Text, Tooltip } from '@reservamos/elements';
import dotersCheck from 'images/doters/check.svg';
import environment from 'config/environment';

/** Component that displays a tooltip with information about Doters Rewards benefits for a specific level.
  @param {Object} props - Component props
  @param {string} props.level - The level for which benefits will be displayed ("silver" or "gold").
  @param {string} props.levelIcon - The level icon image.
  @param {ReactNode} props.children - The component's child elements that will trigger the tooltip when hovered over.
  @returns {JSX.Element} - DotersRewardTooltip component
*/
const DotersRewardTooltip = ({ level, levelIcon, children }) => {
  const { t } = useTranslation('loyalty');
  const { brand } = environment;

  const dotersText = {
    silver: {
      title: 'tooltip_benefit_doters.silver_title',
      items: [
        'tooltip_benefit_doters.silver_text_first',
        'tooltip_benefit_doters.silver_text_second',
        'tooltip_benefit_doters.silver_text_third',
        'tooltip_benefit_doters.silver_text_fourth',
        'tooltip_benefit_doters.silver_text_fifth',
      ],
    },
    gold: {
      title: 'tooltip_benefit_doters.gold_title',
      items: [
        'tooltip_benefit_doters.gold_text_first',
        'tooltip_benefit_doters.gold_text_second',
        'tooltip_benefit_doters.gold_text_third',
        'tooltip_benefit_doters.gold_text_fourth',
        'tooltip_benefit_doters.gold_text_fifth',
      ],
    },
  };

  const levelCopies = dotersText[level];

  const benefits = {
    gfa: [levelCopies.items[0], levelCopies.items[1]],
    default: levelCopies.items.map((item) => item),
  };

  return (
    <Tooltip
      type="top"
      customContent={
        <Spacing vertical size="S">
          <Spacing size="S">
            <Icon type={levelIcon} />
            <Text color="white" size="S">
              {t(levelCopies.title)}
            </Text>
          </Spacing>
          <Spacing vertical size="XS">
            {(benefits[brand] || benefits.default).map((item) => (
              <Spacing key={item} size="S">
                <Icon type={dotersCheck} size="S" />
                <Text color="white" size="S">
                  {t(item)}
                </Text>
              </Spacing>
            ))}
          </Spacing>
        </Spacing>
      }
    >
      {children}
    </Tooltip>
  );
};

DotersRewardTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.string.isRequired,
  levelIcon: PropTypes.string.isRequired,
};

export default DotersRewardTooltip;
