import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import { formatDotersPoints } from 'utils/doters';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PricingRow from '../../ui/atoms/PricingRow';
import usePurchase from '../../hooks/store/usePurchase';

/**
 * Purchase Pricing Discount section component
 * Displays the discount code and wallet discounts, also the total
 * @returns {JSX.Element}
 */
const PurchasePricingDiscount = () => {
  const { t } = useTranslation('purchase');
  const {
    walletType,
    walletBalanceUsed = 0,
    walletPointsUsed = 0,
    discountAmount = 0,
  } = usePurchase();
  const discountCodeAmount = Number(discountAmount);
  const totalDiscount = discountCodeAmount + walletBalanceUsed;
  const { travelpassLoyaltyConfig } = useWhitelabelEnvs();
  const prefix = travelpassLoyaltyConfig?.prefix || 'costapass';
  const validatedPrefix = prefix || 'costapass';

  if (totalDiscount <= 0) return null;
  const walletLabel = t('label.wallet_discount', {
    context: walletType === 'costapass' ? validatedPrefix : walletType,
    points: formatDotersPoints(Number(walletPointsUsed)),
  });
  return (
    <div className="section-content">
      {discountCodeAmount > 0 && (
        <PricingRow
          label={t('label.discount_code')}
          price={discountCodeAmount}
          sign={getCurrencyPrefix()}
          currency={getCurrencySuffix()}
          isMain
          hasDiscount
        />
      )}

      {walletBalanceUsed > 0 && (
        <PricingRow
          label={walletLabel}
          price={walletBalanceUsed}
          sign={getCurrencyPrefix()}
          currency={getCurrencySuffix()}
          hasDiscount
        />
      )}

      <PricingRow
        label={t('label.discount')}
        price={totalDiscount}
        sign={getCurrencyPrefix()}
        currency={getCurrencySuffix()}
        isMain
        hasDiscount
      />
    </div>
  );
};

export default PurchasePricingDiscount;
