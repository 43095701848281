import React from 'react';
import PropTypes from 'prop-types';
import TripSummaryDetails from 'components/purchase/TripSummaryDetails';
import 'styles/components/modals/TripSummaryModal';
import { useTranslation } from 'react-i18next';
import ModalWithTheme from 'components/ModalWithTheme';

const propTypes = {
  departureTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const TripSummaryModal = ({
  visible,
  departureTrip,
  returnTrip,
  roundTrip,
  showModal,
  hideModal,
}) => {
  const { t } = useTranslation('general');
  if (!visible) return null;

  return (
    <div className="modal-content trip-summary" onClick={(e) => e.stopPropagation()}>
      <ModalWithTheme
        title={t('trip_details')}
        responsiveSize="XL"
        onCloseModal={hideModal}
        expandedModal={roundTrip}
      >
        <TripSummaryDetails
          departureTrip={departureTrip}
          returnTrip={returnTrip}
          roundTrip={roundTrip}
          showModal={showModal}
        />
      </ModalWithTheme>
    </div>
  );
};

TripSummaryModal.propTypes = propTypes;

export default TripSummaryModal;
