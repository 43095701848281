import React from 'react';
import { Icon, Text } from '@reservamos/elements';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import evaluateIcon from '../../images/brands-unique/etn/v-evaluate.svg';
import './EvaluateButton.scss';

const EvaluateButton = () => {
  const {
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('common');
  if (!provider.surveyUrl) return null;

  return (
    <a
      className="rvui-evaluate-container"
      title="Evalua tu viaje"
      href={provider.surveyUrl}
      target="_blank"
      rel="noreferrer"
    >
      <Icon type={evaluateIcon} size="M" />
      <span className="rvui-evaluate">
        <Text elementType="span" color="white" weight="bold">
          {t('buttons.evaluate_trip')}
        </Text>
      </span>
    </a>
  );
};

export default EvaluateButton;
