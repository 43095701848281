/**
 * Helper function to decide whether to convert an object's key to camelCase. For the data models
 * used in this app we don't camelize keys that only contain lower case letters, numbers, dashes
 * and plus signs since they're used as slugs for collections (i.e. lines, terminals, cities, etc)
 *
 * @param  {String}   key     The key to test
 * @param  {Function} convert A function provided by humps to convert a string to camelCase
 * @return {String}           The converted string
 */
function camelizeConverter(key, convert) {
  return /^[a-z0-9\-+]+$/.test(key) ? key : convert(key);
}

export default camelizeConverter;
