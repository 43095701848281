import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import LoyaltyWidget from '../../components/LoyaltyWidget/LoyaltyWidget';

const DotersBanner = ({ show, onSidebar, onPayment }) => {
  const features = useWhitelabelFeatures();
  const envs = useWhitelabelEnvs();
  const { t } = useTranslation('loyalty');

  if (!show || !features.DOTERS_ENABLED) return null;

  const message = t('earn_doters_points', { context: envs.brand });
  const paymentMessage = t('invitation_message_doters_payment', { context: envs.brand });
  const checkoutMessage = t('invitation_message_doters', { context: envs.brand });

  const dotersMessage = onPayment ? paymentMessage : message;

  return (
    <LoyaltyWidget
      invitationText={onSidebar ? checkoutMessage : dotersMessage}
      id="doters"
      variant="banner"
      buttonText={t('join_free')}
      visible
      onSidebar={onSidebar}
    />
  );
};

DotersBanner.propTypes = {
  show: PropTypes.bool,
  onSidebar: PropTypes.bool,
  onPayment: PropTypes.bool,
};

DotersBanner.defaultProps = {
  show: false,
  onSidebar: false,
  onPayment: false,
};

export default DotersBanner;
