import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Spacing, Input, Button, Text, FlatButton } from '@reservamos/elements';
import SectionTitle from 'ui/atoms/SectionTitle';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import ExchangeInfoModal from 'ui/molecules/ExchangeInfoModal';
import { EXCHANGE_TYPEAHEAD_CLEAR } from 'constants/ActionTypes';
import ExchangeTypeahead from '../../ExchangeTypeahead';

const propTypes = {
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  ctaText: PropTypes.string,
  handleRedirectExchangeAlternative: PropTypes.func,
  redirectText: PropTypes.string,
  showRedirectButton: PropTypes.bool,
};
/**
 * Form to exchange a ticket on GFA
 * @param {Object} props - Object component props
 * @param {Function} props.handleSubmit - Function to be executed when submitting the form
 * @param {Object} props.title - Title to show in the exchange section
 * @param {Object} props.ctaText - Cta text of the submit
 * @param {Function} props.handleRedirectExchangeAlternative - Function to execute to redirect to another type of exchange
 * @param {String} props.redirectText - Text to show in the redirect button
 * @param {Boolean} props.showRedirectButton - Flag to show or hide the redirect button
 * @returns Component
 */
const GfaForm = ({
  handleSubmit,
  title,
  ctaText,
  handleRedirectExchangeAlternative,
  redirectText,
  showRedirectButton,
}) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const [originInput, setOriginInput] = useState('');
  const [destinationInput, setDestinationInput] = useState('');
  const [originError, setOriginError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [originUrl, setOriginUrl] = useState('');
  const [destinationUrl, setDestinationUrl] = useState('');
  const placesBaseUrl = `${env.api.searchUrl}/v2/places`;

  const { originSlug, originDisplay, destinationDisplay, destinationSlug } = useSelector(
    (state) => state.exchange.trip,
  );
  const dispatch = useDispatch();

  const { t } = useTranslation('exchange');

  const initialValues = {
    operationNumber: '',
  };
  const validationSchema = yup.object().shape({
    operationNumber: yup
      .string()
      .min(6, t('validation.min_digits', { digits: 6 }))
      .max(20, t('validation.max_digits', { digits: 20 }))
      // eslint-disable-next-line no-useless-escape
      .matches(/([A-Z]+)\-(\d+)/, t('validation.operation_number_invalid'))
      .required(t('validation.operation_number_required')),
  });

  useEffect(() => {
    if (originInput !== '' && !originSlug) {
      setOriginUrl(`${placesBaseUrl}?q=${originInput}`);
    }
  }, [originInput, placesBaseUrl, originDisplay, originSlug]);

  useEffect(() => {
    if (destinationInput !== '' && destinationDisplay === '') {
      setDestinationUrl(`${placesBaseUrl}?q=${destinationInput}&from=${originSlug}`);
    }
  }, [destinationDisplay, destinationInput, originSlug, placesBaseUrl]);

  const isDestinationDisabled = !originSlug;

  useEffect(() => {
    if (originDisplay !== '') {
      setOriginInput(originDisplay);
      setOriginError(false);
    }
  }, [originDisplay]);

  useEffect(() => {
    if (destinationDisplay !== '') {
      setDestinationInput(destinationDisplay);
      setDestinationError(false);
    }
  }, [destinationDisplay]);

  const clearTypeaheadData = (origin = false, destination = false) => {
    dispatch({
      type: EXCHANGE_TYPEAHEAD_CLEAR,
      origin,
      destination,
    });
  };

  const handleKeyUp = (control, event) => {
    event.persist();

    if (event.keyCode === 8 || event.key === 'Backspace') {
      if (control === 'origin' && originSlug !== '') {
        clearTypeaheadData(true, true);
        setDestinationInput('');
      }

      if (control === 'destination' && destinationSlug !== '') {
        clearTypeaheadData(false, true);
      }
    }
  };

  const originValidation = () => {
    if (!originSlug) {
      setOriginError(true);
      setOriginUrl(`${placesBaseUrl}?q=${originInput}`);
      return false;
    }
    return true;
  };

  const destinationValidation = () => {
    if (!destinationSlug) {
      setDestinationError(true);
      setDestinationUrl(`${placesBaseUrl}?q=${destinationInput}&from=${originSlug}`);
      return false;
    }
    return true;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (originValidation() && destinationValidation()) {
            handleSubmit({
              ...values,
              operationNumbers: [values.operationNumber],
              originSlug,
              destinationSlug,
            });
          }
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <Spacing vertical size="L">
              <SectionTitle title={title} description={t('subtitle.input_ticket_details')} />
              <Spacing flexGrow isResponsive responsiveSize="S">
                <ExchangeTypeahead
                  label={t('labels.origin')}
                  name="origin"
                  onChange={setOriginInput}
                  url={originUrl}
                  value={originInput}
                  onKeyUp={handleKeyUp}
                  errorMessage={t('validation.origin_slug_required')}
                  hasError={originError}
                  handleBlur={originValidation}
                />
                <ExchangeTypeahead
                  label={t('labels.destination')}
                  name="destination"
                  onChange={setDestinationInput}
                  url={destinationUrl}
                  value={destinationInput}
                  isDisabled={isDestinationDisabled}
                  onKeyUp={handleKeyUp}
                  errorMessage={t('validation.destination_slug_required')}
                  hasError={destinationError}
                  handleBlur={destinationValidation}
                />
                <Input
                  label={t('labels.input_operation_number', { context: 'gfa' })}
                  id="operationNumber"
                  errorMessage={errors.operationNumber}
                  hasError={errors.operationNumber !== undefined && touched.operationNumber}
                  maxLength={20}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.operationNumber}
                />
              </Spacing>
              <ExchangeInfoModal />
              <Spacing justifyContent="space-between" isResponsive>
                <Spacing size="XS" vertical>
                  <Text weight="bold"> {t('disclaimer.first')} </Text>
                  <Text weight="bold"> {t('disclaimer.second')} </Text>
                  <Text weight="bold"> {t('disclaimer.third')} </Text>
                </Spacing>
                <Spacing isResponsive responsiveColumnReverse>
                  {showRedirectButton && (
                    <FlatButton onClick={handleRedirectExchangeAlternative}>
                      {redirectText}
                    </FlatButton>
                  )}
                  <Button
                    buttonType="submit"
                    text={ctaText}
                    variant="accent"
                    withHeight
                    fullWidthOnSmall
                  />
                </Spacing>
              </Spacing>
            </Spacing>
          </Form>
        )}
      </Formik>
    </>
  );
};

GfaForm.propTypes = propTypes;

export default GfaForm;
