import { connect } from 'react-redux';
import stringifyQueryString from 'utils/queryString/stringifyQueryString';
import { isRecommendedTripsEnabled } from 'utils/userPreferences';
import { createSearch, setParams } from '../../../actions/search';
import { newPurchase } from '../../../actions/purchase';
import { setError } from '../../../actions';
import parseQueryString from '../../../utils/queryString/parseQueryString';
import getSearchAnalyticsData from '../../../utils/search/getSearchAnalyticsData';
import { decodePassengers } from '../../../utils/Reserbus';
import OpenTicketSearch from './OpenTicketSearch';

const isSearchPolling = (trips) =>
  !trips || trips.valueSeq().some((transportType) => transportType.get('isPolling'));

const mapStateToProps = (state, ownProps) => {
  const { search, trips, purchase, cities, terminals } = state;
  const { match, location } = ownProps;
  const { returnIsOpenTicket = false } = parseQueryString(location.search);
  const {
    originSlug,
    destinationSlug,
    departureDate: departureDateParam,
    returnDate: returnDateParam,
  } = match.params;
  const searchId = search.getIn(['departure', 'id']);
  const searchIsPolling = isSearchPolling(trips.get(searchId));
  const tripList = trips.getIn([searchId, 'buses', 'trips']);

  const { lodgingProvider = 'none' } = location.search;
  const departureId = search.getIn(['departure', 'id']);
  const places = { ...cities, ...terminals };
  const departures = trips.get(departureId);

  const analyticsData = !searchIsPolling
    ? getSearchAnalyticsData({
        search,
        places,
        departures,
        returns: null,
        lodgingProvider,
        openTicket: true,
      })
    : null;

  return {
    originSlug,
    destinationSlug,
    searchIsPolling,
    tripList: tripList && tripList.toJS(),
    purchase: purchase.toJS(),
    analyticsData,
    departureDate: departureDateParam,
    returnDate: returnDateParam,
    returnIsOpenTicket,
    includeRecommendedTrips: isRecommendedTripsEnabled(),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  createSearch: (
    way,
    origin,
    destination,
    date,
    round = false,
    isOpen,
    includeRecommendedTrips,
  ) => {
    const passengers = 'A1';
    dispatch(setParams(origin, destination, decodePassengers(passengers), date, null));
    dispatch(
      createSearch(
        way,
        origin,
        destination,
        date,
        passengers,
        round,
        isOpen,
        includeRecommendedTrips,
      ),
    );
  },

  createPurchase: (tripId) => {
    const { location } = ownProps;
    const { seenPrice, adAttribution = false } = parseQueryString(location.search);

    dispatch(newPurchase(tripId, null, 'A1', seenPrice, adAttribution));
  },
  replaceUrl: (route) => ownProps.history.replace(route),
  setError: (code, message) => dispatch(setError(code, message)),
  /**
   * Transitions to a new route
   * @param {*} route - Route to transition to
   * @param {*} searchQuery - Query string to append to the route
   */
  transitionTo: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);
    ownProps.history.push(`${route}${queryString}`);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenTicketSearch);
