import { trackEvent } from 'user-analytics';

/**
 * Tracks when the adjacent dialog is opened.
 */
export function trackOpenAdjacentDialog() {
  trackEvent('Adjacent Dialog Opened');
}

/**
 * Tracks when the adjacent dialog is closed.
 */
export function trackCloseAdjacentDialog() {
  trackEvent('Adjacent Dialog Closed');
}
