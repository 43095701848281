import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import LoadingPulse from './LoadingPulse';

const Button = ({ isLoading, text, type, variant, onClick }) => (
  // eslint doesn't allow dynamic button type
  // eslint-disable-next-line react/button-has-type
  <button
    type={type}
    className={`rvui-button ${variant} ${isLoading ? 'loading' : ''}`}
    onClick={(e) => !isLoading && onClick(e)}
  >
    {isLoading ? <LoadingPulse /> : text}
  </button>
);
Button.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['', 'secondary', 'nofill']),
};

Button.defaultProps = {
  isLoading: false,
  onClick: () => {},
  text: '',
  type: 'button',
  variant: '',
};
export default Button;
