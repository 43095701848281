import { hasSeatSelection } from 'utils/Reserbus';
import wayIsOpenTicket from '../wayIsOpenTicket';
import tripSupportsSeatSelection from './tripSupportsSeatSelection';

export default (purchase) => {
  const { departs, returns, roundTrip } = purchase;
  const departsAllowsSeatSelection =
    hasSeatSelection(departs) && !wayIsOpenTicket(departs) && tripSupportsSeatSelection(departs);
  if (!roundTrip) {
    return departsAllowsSeatSelection;
  }

  const returnsAllowsSeatSelection =
    hasSeatSelection(returns) && !wayIsOpenTicket(returns) && tripSupportsSeatSelection(returns);
  return departsAllowsSeatSelection || returnsAllowsSeatSelection;
};
