import { connect } from 'react-redux';
import { setSaveCard } from '@/actions/cards';
import SaveCardCheckbox from './SaveCardCheckbox';

const mapStateToProps = ({ siemprePlus, cards }) => {
  return {
    checked: cards.get('saveCard'),
    user: siemprePlus.get('user'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (checked) => {
      dispatch(setSaveCard(!checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveCardCheckbox);
