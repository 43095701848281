/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, SeatNumber, Spacing, Text } from '@reservamos/elements';
import Seat from '../../../ui/atoms/Seat';

const propTypes = {
  from: PropTypes.string.isRequired,
  seat: PropTypes.string,
  to: PropTypes.string.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  adjacentSeat: PropTypes.shape({
    seat: PropTypes.string.isRequired,
    parentAdjacentSeat: PropTypes.string.isRequired,
  }),
  seatFloor: PropTypes.number,
};
const TicketPassengerSeat = ({ from, seat, to, isOpenTicket, adjacentSeat, seatFloor }) => {
  const { t } = useTranslation('general');
  const shouldRenderAdjacentSeat = adjacentSeat && seat === adjacentSeat.parentAdjacentSeat;
  return (
    <Spacing vertical size="XS" alignItems="flex-end">
      <Text size="XS" italic>{`${from} - ${to}`}</Text>
      {seat && !isNaN(seat) && !isOpenTicket ? (
        <Spacing size="XS" alignItems="center">
          {seatFloor && (
            <Badge roundCorners type="gray">
              <Text elementType="span" size="S" weight="semibold">
                {t('deck')}
                <Text elementType="span" size="S" weight="bold">
                  {' '}
                  {seatFloor}
                </Text>
              </Text>
            </Badge>
          )}
          {seatFloor && <Text>{t('seat')}</Text>}
          <SeatNumber isSmall number={seat} type="selected" />
          {shouldRenderAdjacentSeat && (
            <Seat isSmall seat={adjacentSeat.seat} type="selected" icon="adjacent" />
          )}
        </Spacing>
      ) : (
        <Text>{t('text.no_assigned_seat')}</Text>
      )}
    </Spacing>
  );
};

TicketPassengerSeat.propTypes = propTypes;

export default TicketPassengerSeat;
