import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCybersourceKeys } from '@/actions/config';
import cybersource from 'payments/core/engines/cybersource';
import { getSessionId } from 'utils/session';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import usePurchase from 'hooks/store/usePurchase';

/**
 * Hook to initialize the cybersource keys dynamically based on purchase and whitelabel configurations.
 *
 * @returns {void}
 */
const useCybersourceKeys = () => {
  const cyberKeysSet = useRef(false);
  const { DYNAMIC_CYBERSOURCE_CREDENTIALS } = useWhitelabelFeatures();
  const { cybersource: cybersourceConfig } = useWhitelabelEnvs();
  const { token, paymentCredentials } = usePurchase();
  const { cybersourceLocalKeys } = useSelector((state) => state.config);
  const { cybersource: cybersourceKeys } = paymentCredentials?.creditCard || {};
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * Method to init the cybersource credentials dynamically
     * It compares the cybersource credentials from the backend with the ones from the local state,
     * if they are different, it reloads the page to get the new credentials and initialize cybersource,
     * it is need it to reload because cybersource is loaded in the index.html with an script tag.
     */
    const initCybersourceKeys = () => {
      if (
        !DYNAMIC_CYBERSOURCE_CREDENTIALS ||
        !token ||
        cyberKeysSet.current ||
        !cybersourceConfig.enabled
      )
        return;

      const sessionId = getSessionId();
      let cybersourceKeysToUse = { ...cybersourceConfig, sessionId };

      const { merchantId, orgId } = cybersourceKeys;
      const { merchantId: merchantIdLocal, orgId: orgIdLocal } = cybersourceLocalKeys;

      /**
       * If the cybersource keys saved in the store are different to the one sent by the backend,
       * The page is reload to init the cybersource beacon with the new credentials.
       */
      if (
        (merchantIdLocal && merchantIdLocal !== merchantId) ||
        (orgIdLocal && orgIdLocal !== orgId)
      ) {
        window.location.reload();
        return;
      }

      /**
       * If the cybersource keys are the same as the ones saved in the store,
       * it is not necessary to init the cybersource beacon again.
       */
      if (merchantIdLocal && merchantIdLocal === merchantId && orgIdLocal && orgIdLocal === orgId)
        return;

      if (merchantId && orgId)
        cybersourceKeysToUse = {
          merchantId,
          orgId,
          enabled: cybersourceConfig.enabled,
          sessionId,
        };

      cyberKeysSet.current = true;
      cybersource.loadAntifraudBeacon(cybersourceKeysToUse)();
      dispatch(setCybersourceKeys(cybersourceKeysToUse));
    };

    initCybersourceKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { cyberKeysSet, cybersourceKeys };
};

export default useCybersourceKeys;
