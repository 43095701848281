import { request } from 'graphql-request';
import store from '@/store';

const defaultVariables = {
  campaignSlug: '',
  origin: '',
  destination: '',
  line: '',
};

const AD_QUERY = `
  query Ad(
    $campaignSlug: String!
    $space: String!
    $origin: String!
    $destination: String!
    $line: String!
  ) {
    ad(
      campaignSlug: $campaignSlug
      space: $space
      origin: $origin
      destination: $destination
      line: $line
    ) {
      body
      imageUrl
      bg
      link
    }
  }
`;

export default function fetchCampaingAd(params, space) {
  const { env } = store.getState().whitelabelConfig;
  const variables = {
    ...defaultVariables,
    ...params,
    space,
  };

  return request(env.api.adsUrl, AD_QUERY, variables);
}
