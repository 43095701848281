/**
 * Moves a specific object to the beginning of the array based on a given key and value.
 * Modifies the array in place.
 *
 * @param {Array} array - The array to be modified.
 * @param {string} key - The key of the object being searched for.
 * @param {*} value - The value of the key that must match to move the object.
 * @returns {Array} - The modified array.
 */
const moveObjToFirst = (array, key, value) => {
  const arrayCopy = [...array];
  const index = arrayCopy.findIndex((el) => el[key] === value);
  const object = arrayCopy[index];
  arrayCopy.splice(index, 1);
  arrayCopy.unshift(object);
  return arrayCopy;
};

export default moveObjToFirst;
