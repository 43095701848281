import store from '@/store';

export default function riskifiedPageview(page) {
  const {
    env: { riskified },
  } = store.getState().whitelabelConfig;
  if (!riskified || !riskified.enabled) return;
  if (window && window.RISKX && window.RISKX.go) {
    window.RISKX.go(page.pathname);
  }


}
