import { connect } from 'react-redux';
import getSearchAnalyticsData from 'utils/search/getSearchAnalyticsData';
import { decodePassengers } from 'utils/Reserbus';
import { createSearch, setParams } from '../../../actions/search';
import { newPurchase } from '../../../actions/purchase';
import { setError } from '../../../actions';
import TripSelector from './TripSelector';

const mapStateToProps = ({ purchase, search, trips, cities, terminals }) => {
  const departureId = search.getIn(['departure', 'id']);
  const departureDidInvalidate = search.getIn(['departure', 'didInvalidate']);
  const departures = trips.get(departureId);
  const returnId = search.getIn(['return', 'id']);
  const returnDidInvalidate = search.getIn(['return', 'didInvalidate']);
  const returns = trips.get(returnId);
  const token = purchase.get('token');
  const isFetching = purchase.get('isFetchingRedirect');
  const places = { ...cities, ...terminals };
  return {
    purchase: purchase.toJS(),
    departureId,
    departureDidInvalidate,
    returnId,
    returnDidInvalidate,
    departures,
    returns,
    token,
    isFetching,
    analyticsData: getSearchAnalyticsData({
      search,
      places,
      departures,
      returns,
      lodgingProvider: 'none',
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSearch: (way, origin, destination, date, roundTrip) =>
      dispatch(createSearch(way, origin, destination, date, 'A1', roundTrip)),
    newPurchase: (dslug, rslug, from, passengers, selectedSeats) =>
      dispatch(
        newPurchase(
          dslug,
          rslug,
          passengers,
          null,
          false,
          false,
          undefined,
          true,
          from,
          null,
          selectedSeats,
        ),
      ),
    setError: () => dispatch(setError(100, 'trips_search_error', 'error', false)),
    setParams: (origin, destination, departure, returns) =>
      dispatch(setParams(origin, destination, decodePassengers('A1'), departure, returns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TripSelector);
