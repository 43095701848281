import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PricingRow from '../../ui/atoms/PricingRow';

const BankDetailsRow = ({ type, value }) => {
  const { t } = useTranslation('payment');

  // NOTE: Format here the value as date/amount by "type"
  function formatValue() {
    switch (type) {
      default:
        return value;
    }
  }

  return <PricingRow label={t('bank_field', { context: type })} price={formatValue()} isNumber />;
};

BankDetailsRow.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default BankDetailsRow;
