import React from 'react';
import PropTypes from 'prop-types';
import './NewSeatsLayout.scss';
import { Placeholder } from '@reservamos/elements';
import LoadingText from 'components/LoadingText';

/**
 * Renders the layout for new seats.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.busDiagram - The bus diagram component.
 * @param {ReactNode} props.toggle - The toggle diagram component.
 * @param {ReactNode} props.pricing - The pricing component.
 * @param {ReactNode} props.seatsLegend - The seats legend component.
 * @param {ReactNode} props.mobileHeader - The mobile header component.
 * @param {boolean} props.isFlat - The isFlat diagram component.
 * @param {boolean} props.isLoading - Indicates if the layout is in loading state.
 * @returns {ReactNode} The rendered component.
 */
const NewSeatsLayout = ({
  busDiagram,
  pricing,
  seatsLegend,
  toggle,
  mobileHeader,
  isLoading,
  isFlat,
}) => {
  const loadingPlaceholder = <Placeholder height="280px" smallHeight="480px" />;
  const loadingText = <LoadingText loadingText="searching_available_seats" />;
  const legendLoading = isFlat ? (
    <Placeholder height="40px" smallHeight="480px" />
  ) : (
    loadingPlaceholder
  );

  let loadingClass = '';

  if (isLoading) {
    loadingClass = isFlat ? 'new-seats-layout-flat-loading' : 'new-seats-layout-loading';
  }

  return (
    <div className={`new-seats-layout ${isFlat ? 'new-seats-layout-flat' : ''}  ${loadingClass}`}>
      <div className={`new-seats-layout-header ${isLoading ? '' : 'show-for-mobile-only'}`}>
        {isLoading ? loadingText : mobileHeader}
      </div>

      <div className="new-seats-layout-diagram">{isLoading ? loadingPlaceholder : busDiagram}</div>

      {isFlat && !isLoading && (
        <div className="new-seats-layout-toggle">{isLoading ? legendLoading : toggle}</div>
      )}

      <div className="new-seats-layout-legend">{isLoading ? legendLoading : seatsLegend}</div>

      <div className="new-seats-layout-pricing">{isLoading ? loadingPlaceholder : pricing}</div>

      {isFlat && <div className="new-seats-layout-empty-space" />}
    </div>
  );
};

NewSeatsLayout.propTypes = {
  busDiagram: PropTypes.node,
  pricing: PropTypes.node,
  seatsLegend: PropTypes.node,
  mobileHeader: PropTypes.node,
  toggle: PropTypes.node,
  isLoading: PropTypes.bool,
  isFlat: PropTypes.bool,
};

NewSeatsLayout.defaultProps = {
  isLoading: false,
  isFlat: false,
};

export default NewSeatsLayout;
