import React, { Component } from 'react';
import PropTypes from 'prop-types';
import prepareItineraryDetails from 'utils/trips/prepareItineraryDetails';
import prepareProviderDetails from 'utils/trips/prepareProviderDetails';
import TripItinerary from 'components/TripItinerary';

const propTypes = {
  departureTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  showSeats: PropTypes.bool,
  showTickets: PropTypes.bool,
  showDownloadTicketsStep: PropTypes.bool,
};

const defaultProps = {
  showSeats: true,
  showTickets: false,
};

class TripSummaryDetails extends Component {
  renderTripDetails(trip, roundTrip = false, isReturn = false) {
    const { showModal, showSeats, showTickets, showDownloadTicketsStep } = this.props;
    const { transportType, fragments } = trip;
    const [firstFragment] = fragments;
    const { openTicket } = firstFragment;
    let title = roundTrip ? 'your_one_way_trip' : 'your_trip';
    let itinerary;
    let providerAmenities;
    let selectedSeats;

    if (isReturn) title = 'your_return_trip';

    if (transportType === 'mixed') {
      itinerary = prepareItineraryDetails(trip, showTickets);
      providerAmenities = prepareProviderDetails(trip);
    } else {
      itinerary = prepareItineraryDetails(firstFragment, showTickets);
      providerAmenities = prepareProviderDetails(firstFragment);
    }

    if (!showSeats) {
      selectedSeats = null;
    } else if (transportType === 'bus') {
      selectedSeats = firstFragment.tickets
        .map((ticket) => ({ number: ticket.seat, category: ticket.category }))
        .filter(({ number }) => !!number);
    }

    return (
      <TripItinerary
        headerDescription="total"
        itinerary={itinerary}
        providerAmenities={providerAmenities}
        selectedSeats={selectedSeats}
        showModal={showModal}
        stopoverPlace={trip.stopoverPlace}
        title={title}
        total={trip.pricing.total}
        trip={trip}
        isOpenTicket={openTicket}
        showDownloadTicketsStep={showDownloadTicketsStep}
      />
    );
  }

  render() {
    const { departureTrip, returnTrip, roundTrip } = this.props;

    return (
      <div className="trip-summary-itinerary">
        <div className="trip-summary-itinerary-left">
          {this.renderTripDetails(departureTrip, roundTrip)}
        </div>

        {roundTrip && (
          <div className="trip-summary-itinerary-right">
            {this.renderTripDetails(returnTrip, roundTrip, true)}
          </div>
        )}
      </div>
    );
  }
}

TripSummaryDetails.propTypes = propTypes;
TripSummaryDetails.defaultProps = defaultProps;

export default TripSummaryDetails;
