import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { getSearchDateParamFromMoment } from 'utils/search/searchDateParam';
import DayButton from 'ui/atoms/DayButton';
import 'ui/molecules/DayControls';

const DayControls = ({
  way,
  fixed,
  prevDayDate,
  nextDayDate,
  prevDayPrice,
  nextDayPrice,
  disabledButtons,
  originId,
  destinationId,
  departureDate,
  returnDate,
  isRoundTrip,
  isProviderList,
  providerId,
  encodedPassengersSelection = 'A1',
}) => {
  const { departureId } = useParams();
  const history = useHistory();
  const prevDayDateDisplay = prevDayDate.format('DD MMM');
  const nextDayDateDisplay = nextDayDate.format('DD MMM');
  const isOpen = window.location.href.split('/').pop().includes('open');

  const formatActionDate = (date) => {
    const momentDate = moment.isMoment(date) ? date : moment(date, 'DD-MM-YYYY');
    return getSearchDateParamFromMoment(momentDate);
  };

  const createRoundTrip = (url, returns, button) => {
    const formatedReturns = formatActionDate(returns);
    let newUrl = url;
    newUrl += `${formatedReturns}/`;

    const finalPath = providerId ? `/${providerId}?action=${button}` : `?action=${button}`;
    newUrl += `p/${encodedPassengersSelection}/`;
    const returnsFinalPath = `${departureId}/returns`;

    if (isProviderList) {
      if (way === 'return') {
        newUrl += `${departureId}/return/`;
      }
      newUrl += 'providers';
      newUrl += isOpen ? '/open' : finalPath;

      return newUrl;
    }

    newUrl += way === 'return' ? returnsFinalPath : 'departures';
    newUrl += isOpen ? '/open' : finalPath;

    return newUrl;
  };

  const createDepartureTrip = (url, button) => {
    let newUrl = url;

    const finalPath = providerId ? `/${providerId}?action=${button}` : `?action=${button}`;
    newUrl += isProviderList
      ? `p/${encodedPassengersSelection}/providers`
      : `p/${encodedPassengersSelection}/departures`;
    newUrl += isOpen ? '/open' : finalPath;

    return newUrl;
  };

  const createUrl = (date, button) => {
    const departure = way === 'departure' ? date : departureDate;
    const formatedDeparture = formatActionDate(departure);
    const returns = way === 'return' ? date : returnDate;

    let url = `/search/${originId}/${destinationId}/${formatedDeparture}/`;
    url = isRoundTrip ? createRoundTrip(url, returns, button) : createDepartureTrip(url, button);
    return url;
  };

  const handleOptionSelect = (date, button) => () => {
    const url = createUrl(date, button);
    history.push(url);
  };

  return (
    <div className={`day-controls ${fixed ? 'day-controls-fixed' : ''}`}>
      <DayButton
        onClick={handleOptionSelect(prevDayDate, 'prev')}
        date={prevDayDateDisplay}
        price={prevDayPrice}
        leftIcon
        disabled={disabledButtons.prevDay}
      />
      <DayButton
        onClick={handleOptionSelect(nextDayDate, 'next')}
        date={nextDayDateDisplay}
        price={nextDayPrice}
        rightIcon
        disabled={disabledButtons.nextDay}
      />
    </div>
  );
};

DayControls.propTypes = {
  way: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
  prevDayDate: PropTypes.object.isRequired,
  nextDayDate: PropTypes.object.isRequired,
  prevDayPrice: PropTypes.string.isRequired,
  nextDayPrice: PropTypes.string.isRequired,
  disabledButtons: PropTypes.object.isRequired,
  originId: PropTypes.string.isRequired,
  destinationId: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  isProviderList: PropTypes.bool,
  providerId: PropTypes.string,
  encodedPassengersSelection: PropTypes.string.isRequired,
};

DayControls.defaultProps = {
  fixed: false,
};

export default DayControls;
