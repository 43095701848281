import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TripSort from 'components/modal/TripSort';
import DepartureFilter from 'components/modal/DepartureFilter';
import StopsFilter from 'components/modal/StopsFilter';
import LocationFilter from 'components/modal/LocationFilter';
import 'styles/components/search/ViewOptions';
import { withTranslation } from 'react-i18next';

const propTypes = {
  showingFilters: PropTypes.bool.isRequired,
  showLocationFilter: PropTypes.bool.isRequired,
  sortOn: PropTypes.bool.isRequired,
  departureFilterOn: PropTypes.bool.isRequired,
  locationFilterOn: PropTypes.bool.isRequired,
  stopsFilterOn: PropTypes.bool.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isStopsFilterAvailable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  sortProperty: PropTypes.string,
  features: PropTypes.object.isRequired,
};

const translateSort = {
  price: 'Precio',
  duration: 'Duración',
  departure: 'Salida',
  arrival: 'Llegada',
  stops: 'Parada',
};

const defaultProps = {
  sortProperty: '',
};

class TripViewOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleModal: undefined,
    };
  }

  select(option, modalComponent) {
    const { showingFilters, onOptionClick, hideModal } = this.props;
    const { visibleModal } = this.state;
    if (showingFilters && visibleModal === option) {
      this.setState({ visibleModal: undefined });
      hideModal();
    } else {
      this.setState({ visibleModal: option });
      onOptionClick(option, modalComponent);
    }
  }

  render() {
    const {
      sortOn,
      showLocationFilter,
      departureFilterOn,
      stopsFilterOn,
      locationFilterOn,
      isStopsFilterAvailable,
      t,
      sortProperty,
      features,
    } = this.props;

    let locationFilter;
    const hasSortProperty = sortProperty !== '';

    if (showLocationFilter) {
      locationFilter = (
        <a
          className={`location${locationFilterOn ? ' filter-on' : ''}`}
          onClick={() => this.select('Location Filter', LocationFilter)}
        >
          terminal
        </a>
      );
    }

    let title;

    if (features.SHOW_SORT_PROPERTY) {
      title = hasSortProperty ? `ordenado${` por ${translateSort[sortProperty]}`}` : 'ordenar';
    } else {
      title = t('label.filter_sort');
    }

    return (
      <div className="view-options">
        <a
          className={`sort ${sortOn ? ' filter-on' : ''}`}
          onClick={() => this.select('Sort', TripSort)}
        >
          {title}
        </a>
        <a
          className={`schedule${departureFilterOn ? ' filter-on' : ''}`}
          onClick={() => this.select('Departure Filter', DepartureFilter)}
        >
          {t('label.filter_schedule')}
        </a>
        {isStopsFilterAvailable && (
          <a
            className={`stops${stopsFilterOn ? ' filter-on' : ''}`}
            onClick={() => this.select('Stops Filter', StopsFilter)}
          >
            {t('label.filter_stops')}
          </a>
        )}
        {locationFilter}
      </div>
    );
  }
}

TripViewOptions.propTypes = propTypes;
TripViewOptions.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(withTranslation('search')(TripViewOptions));
