import {
  BadgeRounded,
  Button,
  Currency,
  FlatButton,
  IconText,
  ProviderLogo,
  Spacing,
  Text,
} from '@reservamos/elements';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'styles/components/search/MatrixResultDefault';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { formatDuration, formatPercent } from 'utils/Helpers';
import '../../ui/layouts/MatrixResult';
import { generateGoogleMapsSearchUrl } from 'utils/urls';
import TerminalNameWithLocationLink from 'ui/atoms/TerminalNameWithLocationLink';
import RouteDetailsModal from '../../ui/molecules/RouteDetailsModal';
import LowAvailability from './LowAvailability';
import PriceTooltip from './PriceTooltip';
import TripFiltersBadges from './TripFiltersBadges';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';

// hide the duration if is minor than 15min
// because this is probably wrong estimation
const MIN_DURATION = 15;

const ResultDefault = ({
  arrival,
  availability,
  departure,
  destination,
  duration,
  id,
  isRoundTrip,
  onSelectClick,
  origin,
  providerDiscount,
  providerDiscounts,
  total,
  totalBeforeDiscount,
  trip,
  way,
  filtersApplied = {},
  showNewFiltersList,
  hasDiscountType,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { theme, brand } = env;
  const { getServiceType } = useWhitelabelTheme();
  const { t } = useTranslation();
  const { line, passengerTypes, pricing, service } = trip;

  const wayContext = isRoundTrip ? way : 'one way';
  const showDuration = duration > MIN_DURATION;
  const hasProviderDiscount = providerDiscount && Boolean(providerDiscount.amount);
  const discountPercent = hasProviderDiscount ? formatPercent(totalBeforeDiscount, total) : null;
  const { useLineNameAsServiceType = false } = theme.serviceTypes || {};
  const serviceTypeLabel = useLineNameAsServiceType ? line.name : getServiceType(service).label;
  const transportTypeImageSrc = theme.transportTypes
    ? theme.transportTypes[trip.diagramType]?.image
    : null;
  const providerLogoSrc = transportTypeImageSrc || line.logoUrl;

  const SHOW_ON_FLAT = features.FUNNEL_STYLE === 'FLAT';
  const shouldShowServiceTypeBadge = features.SHOW_SERVICE_TYPE_BADGE && serviceTypeLabel;
  const shouldShowTripDetails = features.SHOW_TRIP_DETAILS_AT_RESULTS;
  const shouldShowDiscountsModal = features.SHOW_DISCOUNTS_MODAL_AT_RESULTS;
  const discountAvailability =
    providerDiscount &&
    (hasDiscountType ? pricing.discountAvailability : providerDiscount.availability);
  const discountSeat = providerDiscount && discountAvailability;

  const showLocationLinkAtResults = features.USE_TERMINAL_NAME_WITH_LOCATION;
  const originHasCoordinates = origin && origin.coordinates;
  const destinationHasCoordinates = destination && destination.coordinates;
  const showOriginNameWithLocationLink = showLocationLinkAtResults && originHasCoordinates;
  const showDestinationNameWithLocationLink =
    showLocationLinkAtResults && destinationHasCoordinates;
  const originMapsLink = generateGoogleMapsSearchUrl({
    lat: origin?.coordinates?.lat || '',
    long: origin?.coordinates?.long || '',
  });
  const destinationMapsLink = generateGoogleMapsSearchUrl({
    lat: destination?.coordinates?.lat || '',
    long: destination?.coordinates?.long || '',
  });

  const { discountType } = pricing;
  const earlyDiscount = discountType === 'early_trip_discount';

  /**
   * RollBits uses dynamic pricing.
   * This dynamic prices are controlled by the brand using Brain admin.
   * This dynamic prices can be of type
   * - early discount
   * - online purchase discount
   * - round trip discount
   * Currently, we have no way to know which type of discount is being applied.
   * Purchase API only sends discountType as dynamic_pricing.
   * In the specific case of roll-bits, they only use early discount.
   * So, we are assuming that if the brand is roll-bits, then it is early discount.
   *
   * We don´t use earlyDiscount variable declared before because it triggers
   * IAMSA specific cases
   */
  const isRollBits = env.brand === 'roll-bits';
  const isDynamicPricingDiscount =
    discountType === 'dynamic_pricing' ||
    discountType === 'dynamic_pricing_single_on_round_trip_discount';
  const isRollBitsEarlyDiscount = isRollBits && isDynamicPricingDiscount;
  const showOfficePrice = features.SHOW_OFFICE_PRICE;
  const displayRollBitsEarlyDiscountAvailability = isRollBitsEarlyDiscount && !showOfficePrice;

  const detailsComponent = (
    <>
      {shouldShowTripDetails ? (
        <RouteDetailsModal tripId={id} trip={trip} provider={line}>
          <div className="matrix-mobile-click">
            <FlatButton weight="bold" size="S" type="info">
              {t('general:show_details', { context: brand })}
            </FlatButton>
          </div>
        </RouteDetailsModal>
      ) : null}
    </>
  );

  const busDetails = (
    <>
      {shouldShowTripDetails ? (
        <RouteDetailsModal tripId={id} trip={trip} provider={line}>
          <div className="matrix-mobile-click">
            <IconText iconType="Bus" label={serviceTypeLabel} iconSize="S" fontSize="XS" />
          </div>
        </RouteDetailsModal>
      ) : (
        <div className="matrix-mobile-click">
          <IconText iconType="Bus" label={serviceTypeLabel} iconSize="S" fontSize="XS" />
        </div>
      )}
    </>
  );

  const ctaComponent = () => {
    return (
      <Button
        text={t('general:select')}
        onClick={onSelectClick}
        variant={hasProviderDiscount ? 'discount' : 'accent'}
        isRounded={SHOW_ON_FLAT}
      />
    );
  };

  return (
    <div className={`matrix matrix-default ${shouldShowServiceTypeBadge ? 'matrix-bus-type' : ''}`}>
      {hasProviderDiscount && features.DISCOUNT_BADGE_RESULT_ENABLED && (
        <div className="matrix-right-corner">
          <BadgeRounded
            text={`-${discountPercent}`}
            firstColor="discountLight"
            secondColor="discountStrong"
          />
        </div>
      )}
      <div className="matrix-provider">
        <ProviderLogo name={env.brand} imgSrc={providerLogoSrc} />
      </div>

      <div className="matrix-details">{detailsComponent}</div>

      {shouldShowServiceTypeBadge && <div className="matrix-bustype">{busDetails}</div>}

      {showNewFiltersList ? (
        <TripFiltersBadges
          filtersApplied={filtersApplied}
          trip={trip}
          alternativeComponent={null}
        />
      ) : null}

      <div className="matrix-route">
        <div className="matrix-departure">
          <Text mobileSize="S" weight="bold">
            {moment(departure).format('LT')}
          </Text>
        </div>

        <div className="matrix-origin">
          {showOriginNameWithLocationLink ? (
            <TerminalNameWithLocationLink terminalName={origin.name} mapsLink={originMapsLink} />
          ) : (
            <Text size="S" color="grayLight">
              {origin.name}
            </Text>
          )}
        </div>

        <div className="matrix-arrival">
          <Text mobileSize="S" color="grayLight" weight="bold">
            {moment(arrival).format('LT')}
          </Text>
        </div>

        <div className="matrix-destination">
          {showDestinationNameWithLocationLink ? (
            <TerminalNameWithLocationLink
              terminalName={destination.name}
              mapsLink={destinationMapsLink}
            />
          ) : (
            <Text size="S" color="grayLight">
              {destination.name}
            </Text>
          )}
        </div>

        {showDuration && (
          <>
            <div className="matrix-duration">
              <Text size="XS" color="grayLight">
                {formatDuration(duration)}
              </Text>
            </div>

            <div className="matrix-duration-label">
              <Text size="XS" color="grayLight">
                {t('general:period')}
              </Text>
            </div>
          </>
        )}
      </div>

      <div className="matrix-price">
        {shouldShowDiscountsModal ? (
          <PriceTooltip
            amenities={line.services}
            providerDiscounts={providerDiscounts}
            availabilityCategories={passengerTypes}
            earlyDiscount={earlyDiscount}
            total={total}
            color={hasProviderDiscount ? 'discount' : 'primary'}
            tripId={id}
            providerDiscount={providerDiscount}
          />
        ) : (
          <Spacing size="XS" alignItems="baseline" justifyContent="flex-end">
            {Boolean(earlyDiscount) && (
              <div className="result-mobile-click">
                <button
                  className="discount-etn"
                  type="button"
                  aria-label="mute"
                  title={t('purchase:early_discount')}
                />
              </div>
            )}

            <Currency
              sign={getCurrencyPrefix()}
              price={total}
              decimals={env.tripPriceDecimals || 0}
              color={hasProviderDiscount ? 'discount' : 'primary'}
              currency={getCurrencySuffix()}
              size="XL"
              mobileSize="L"
              weight="bold"
            />
          </Spacing>
        )}
      </div>

      <div className="matrix-info">
        {hasProviderDiscount && showOfficePrice && (
          <>
            <Text color="grayLight" size="XS">
              <em>{t('search:ticket_office')}</em>
              <Currency
                sign={getCurrencyPrefix()}
                price={totalBeforeDiscount}
                decimals={0}
                color="grayLight"
                currency={getCurrencySuffix()}
                weight="semibold"
                mobileSize="S"
              />
            </Text>
          </>
        )}

        {displayRollBitsEarlyDiscountAvailability && (
          <Text size="S" color="discount" weight="semibold" italic textAlign="right">
            {t('search:available_early_discount_seats', { count: discountSeat })}
          </Text>
        )}

        {!providerDiscount && (
          <p className="flat-text-small-italic">
            {t([`search:trip_pricing_${env.brand}`, 'search:trip_pricing'], {
              context: wayContext,
            })}
          </p>
        )}
      </div>

      <div className="matrix-aditional">
        {!displayRollBitsEarlyDiscountAvailability && (
          <LowAvailability availability={availability} pricing={pricing} type="desktop" />
        )}
      </div>

      <div className="matrix-action">{ctaComponent()}</div>

      <div className="matrix-bottom">
        <div>
          {!displayRollBitsEarlyDiscountAvailability && (
            <LowAvailability availability={availability} pricing={pricing} type="mobile" />
          )}
          {detailsComponent}
        </div>
        {ctaComponent()}
      </div>
    </div>
  );
};

const placeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  coordinates: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
  }),
});

ResultDefault.propTypes = {
  arrival: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  departure: PropTypes.string.isRequired,
  destination: placeType.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  origin: placeType.isRequired,
  providerDiscount: PropTypes.any,
  providerDiscounts: PropTypes.array,
  total: PropTypes.number.isRequired,
  totalBeforeDiscount: PropTypes.number,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
      serviceType: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    service: PropTypes.string.isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
    diagramType: PropTypes.string,
  }).isRequired,
  way: PropTypes.oneOf(['departure', 'return']).isRequired,
  filtersApplied: PropTypes.object,
  showNewFiltersList: PropTypes.bool,
  hasDiscountType: PropTypes.bool,
};

ResultDefault.defaultProps = {
  providerDiscount: null,
  providerDiscounts: [],
};

export default ResultDefault;
