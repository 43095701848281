/* eslint-disable prettier/prettier */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Text, Spacing, DialogMessage } from '@reservamos/elements';
import { formatDotersPoints } from 'utils/doters';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import greendoters from '../../../images/doters/greendoters.svg';

/**
 * Displays a modal for using Doters points.
 * @param {String} label - The label for the modal.
 * @returns {JSX.Element} A React component representing the Doters modal.
 */
const DotersModalUsage = ({
  visible,
  walletPointsUsed,
  walletBalanceUsed,
  handleClick,
  currency,
}) => {
  const { t } = useTranslation(['payment', 'loyalty']);
  const { DOTERS_NO_CANCELLATION_NOTICE} = useWhitelabelFeatures();

  if (!visible) return null;

  return (
    <DialogMessage
      iconType={greendoters}
      title={t('payment:label.apply_your_doters_points')}
      primaryButton={t('loyalty:apply')}
      onClickPrimary={() => handleClick(true)}
      onClickSecondary={() => handleClick(false)}
      secondaryButton={t('loyalty:dont_apply')}
      showFade
      dialogType="loyaltyLight"
    >
      <Spacing vertical>
        <Text>
          <Trans>
            {t('payment:doters_instructions.accept_doters_usage', {
              pointsUsed: formatDotersPoints(walletPointsUsed),
              balanceUsed: walletBalanceUsed,
              currency,
            })}
            {" "}
            {DOTERS_NO_CANCELLATION_NOTICE && t('payment:doters_instructions.doters_no_cancelable')}
          </Trans>
        </Text>
      </Spacing>
    </DialogMessage>
  );
};

DotersModalUsage.propTypes = {
  visible: PropTypes.bool,
  walletPointsUsed: PropTypes.number,
  walletBalanceUsed: PropTypes.number,
  handleClick: PropTypes.func,
  currency: PropTypes.string,
};

export default DotersModalUsage;
