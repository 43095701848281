// @ts-check

/**
 * @typedef {import('./strategies/CardStrategy').CardStrategy} CardStrategy
 */

/**
 * Card payment method. It uses the strategy design pattern.
 * @function Card
 */
const Card = () => {
  /**
   * @type {CardStrategy | undefined}
   */
  let strategy;

  /**
   * Set the card strategy.
   * @param {CardStrategy} newStrategy - The new card strategy.
   */
  const setStrategy = (newStrategy) => {
    strategy = newStrategy;
  };

  /**
   * Create the payload for the card payment.
   * @param {Object} cardInfo - The card information.
   * @param {Object} enginePayload - The engine payload.
   * @returns {Promise<Object>} The created payload.
   */
  const createPayload = async (cardInfo, enginePayload) => {
    if (!strategy) {
      throw new Error('Card strategy not set');
    }

    return strategy.createPayload(cardInfo, enginePayload);
  };

  return {
    setStrategy,
    createPayload,
  };
};

const card = Card();

export default card;
