import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, ToggleSwitch } from '@reservamos/elements';

const LabelSwitch = ({ id, label, checked, onChange, justifyContent }) => (
  <Spacing size="S" alignItems="Center" justifyContent={justifyContent}>
    <label htmlFor={id}>
      <Text elementType="span" size="S">
        {label}
      </Text>
    </label>
    <ToggleSwitch id={id} size="M" checked={checked} onChange={onChange} fillBackground />
  </Spacing>
);

LabelSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  justifyContent: PropTypes.string,
};

LabelSwitch.defaultProps = {
  checked: false,
  justifyContent: 'initial',
};
export default LabelSwitch;
