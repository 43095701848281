import React from 'react';
import { useDispatch } from 'react-redux';
import useWhitelabelFeatures from '../../../hooks/whitelabel/useWhitelabelFeatures';
import usePurchase from '../../../hooks/store/usePurchase';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import { toggleWantsFlatFare } from '../../../actions/purchase';
import DotersRewardsSwitch from '../../../ui/atoms/DotersRewardsSwitch';

/**
 * Component that contains Flatfare functions through DotersRewardsSwitch.
 * @returns Exchange Form component
 */
const FlatFareSwitch = () => {
  const dispatch = useDispatch();
  const features = useWhitelabelFeatures();
  const {
    doters: { userIsLoggedIn },
  } = useLoyaltyPrograms();
  const { flatFareAvailable, wantsFlatFare, walletType, token, isUpdatingFlatFare } = usePurchase();

  if (
    !features.FLAT_FARE_ENABLED ||
    !flatFareAvailable ||
    !userIsLoggedIn ||
    walletType !== 'doters'
  )
    return null;

  /**
   * Updates wants_flat_fare tag using a switch
   * @returns Dispatch function
   */
  const handleOnToggle = () => {
    dispatch(toggleWantsFlatFare({ token, wantsFlatFare: !wantsFlatFare }));
  };

  return (
    <DotersRewardsSwitch
      onChange={handleOnToggle}
      checked={wantsFlatFare}
      isLoading={isUpdatingFlatFare}
      disabled={isUpdatingFlatFare}
    />
  );
};

export default FlatFareSwitch;
