import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FlatButton, OutlineButton, Visibility } from '@reservamos/elements';

const ChangeProvider = ({ text, hasOtherProviders, onChangeProviderClick }) => {
  const { t } = useTranslation('search');
  if (!hasOtherProviders) return null;

  return (
    <div className="change-provider">
      <Visibility type="showForMobileOnly">
        <FlatButton size="S" onClick={onChangeProviderClick} type="info">
          {t(text)}
        </FlatButton>
      </Visibility>
      <Visibility type="hideForMobileOnly">
        <OutlineButton
          text={t(text)}
          isRounded
          fontSize="S"
          variant="infoLight"
          padding="M"
          onClick={onChangeProviderClick}
        />
      </Visibility>
    </div>
  );
};

ChangeProvider.propTypes = {
  text: PropTypes.string,
  hasOtherProviders: PropTypes.bool,
  onChangeProviderClick: PropTypes.func.isRequired,
};

ChangeProvider.defaultProps = {
  text: 'change_line',
  hasOtherProviders: true,
};

export default ChangeProvider;
