import { Spacing, Text } from '@reservamos/elements';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/components/AgencyForm';
import CheckList from '../../ui/atoms/CheckList';

const AgencyForm = () => {
  const { t } = useTranslation('purchase');

  const labels = [t('text.pay_with_credit'), t('text.an_email_will_be_sent')];

  return (
    <div className="agencypay">
      <Spacing vertical>
        <div className="agencypay-cont">
          <i className="agencypay-cont-icon" />
          <Text color="primaryStrong" size="L" weight="semibold">
            {t('label.agency_credit')}
          </Text>
        </div>

        <CheckList title={t('text.steps_to_pay')} labels={labels} />
      </Spacing>
    </div>
  );
};

export default AgencyForm;
