import { Map, List, fromJS } from 'immutable';
import * as types from 'constants/ActionTypes';
import findTrip from 'utils/trips/findTrip';

const tripTemplate = (active) => Map({ isPolling: active, trips: List() });

export default function trips(trips = Map({}), action) {
  let requestInfo;

  switch (action.type) {
    case types.SETUP_TRIPS:
      return trips.mergeIn(
        [action.searchId],
        Map({
          buses: tripTemplate(action.activeBuses),
          flights: tripTemplate(action.activeFlights),
          mixed: tripTemplate(action.activeMixed),
        }),
      );

    case types.RECEIVE_BUSES: {
      requestInfo = Map(action.apiResponse).delete('trips');
      const mapedTrips = action.apiResponse.trips.map((trip) => {
        const existentTrip = findTrip(trips.get(requestInfo.get('id')), 'bus', trip.id);
        return {
          ...trip,
          isLoading: existentTrip ? existentTrip.get('isLoading') === true : false,
        };
      });

      return trips.mergeIn(
        [requestInfo.get('id')],
        Map({
          buses: requestInfo.merge({
            isPolling: action.isPolling,
            trips: fromJS(mapedTrips),
          }),
        }),
      );
    }

    case types.RECEIVE_FLIGHTS:
      requestInfo = Map(action.apiResponse).delete('flights');

      return trips.mergeIn(
        [requestInfo.get('id')],
        Map({
          flights: requestInfo.merge({
            isPolling: action.isPolling,
            trips: fromJS(action.apiResponse.flights),
          }),
        }),
      );

    case types.RECEIVE_MIXED:
      requestInfo = Map(action.apiResponse).delete('itineraries');

      return trips.mergeIn(
        [requestInfo.get('id')],
        Map({
          mixed: requestInfo.merge({
            isPolling: action.isPolling,
            trips: fromJS(action.apiResponse.itineraries),
          }),
        }),
      );
    default:
      return trips;
  }
}
