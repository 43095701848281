import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Fallback component to handle errors.
 * @param {function} resetError - When called it'll remove the Fallback component
 * and render the Sentry ErrorBoundary's children in their initial state
 * @returns {JSX.Element} React element to display in case of error.
 */
function ErrorFallback({ resetError }) {
  const history = useHistory();
  const { t } = useTranslation(['errors']);

  /**
   * Function to navigate back and reset the error.
   * Uses the history object to go back one page in the navigation history
   * and calls the resetError function to reset the error state.
   */
  const goBack = () => {
    history.go(-1);
    resetError();
  };

  return (
    <div className="h-screen flex flex-col gap-5 items-center justify-center">
      <div className="w-16 h-16">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          fill="red"
          viewBox="0 0 16 16"
        >
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
        </svg>
      </div>
      <p className="text-xl my-3 font-semibold text-gray-600">{t('something_went_wrong')}</p>
      <button
        onClick={goBack}
        className="px-4 py-2 text-gray-600 font-semibold rounded-md border-2 shadow-lg shadow-gray-500/40"
      >
        {t('general:go_back')}
      </button>
    </div>
  );
}

export default ErrorFallback;

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};
