import { camelizeKeys } from 'humps';
import { Map } from 'immutable';
import { LOADING_TICKETS, SET_TICKETS } from '../constants/ActionTypes';

const defaultState = Map({
  tickets: [],
  userData: {},
  isLoading: false,
  error: false,
});

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOADING_TICKETS:
      return state.set('isLoading', action.isLoading);
    case SET_TICKETS:
      return state
        .set('tickets', camelizeKeys(action.tickets))
        .set('userData', camelizeKeys(action.userData))
        .set('isLoading', false);
    default:
      return state;
  }
}
