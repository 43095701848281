import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';
import { getCountryCallingCode } from 'react-phone-number-input';
import { createValidator } from '../../../utils/formValidations';
import purchaserValidations from '../../../utils/purchaserValidations';
import { getStoredPurchaser, setStoredPurchaser } from '../../../utils/storedPurchaser';
import PurchaserForm from './PurchaserForm';
import { getDoc2BrandMap } from '../../../utils/documentMaps';
import { setError } from '../../../actions';
import { formErrors } from '../../../actions/purchase';
import { getUserByWalletType, walletTypeNeedsUserInPurchaser } from '../../../utils/loyalty';
import parsePurchaser from '../../../utils/purchase/parsePurchaser';

const FORM = 'purchaser';

const mapStateToProps = (state) => {
  const { purchase, whitelabelConfig } = state;
  const { env } = whitelabelConfig;
  const purchaseJs = purchase.toJS();
  const { walletType } = purchaseJs;

  const userNeededForPurchaser =
    walletTypeNeedsUserInPurchaser(walletType) && getUserByWalletType(walletType);

  const {
    documentId,
    documentType,
    purchaserFirstName,
    purchaserLastName,
    purchaserSecondLastName,
    email,
    phone,
    phoneCountry,
    isExchange,
    phoneCode,
  } = purchaseJs;

  /**
   * The initial values for the purchaser form are created, they are created with the purchase data if exits,
   * or with the local storage data.
   */
  const storedPurchaser = getStoredPurchaser();

  const doc2BrandMapPurchaser = getDoc2BrandMap('purchaser');
  const purchaserDocumentsOptions = Object.values(doc2BrandMapPurchaser);
  const phoneCountryValue =
    phoneCountry || storedPurchaser?.phoneCountry || env.lang?.default?.substring(3) || 'MX';
  const initialValues = {
    documentId: documentId || (!isExchange ? storedPurchaser?.documentId : undefined) || '',
    documentType:
      documentType ||
      (!isExchange ? storedPurchaser?.documentType : undefined) ||
      purchaserDocumentsOptions[0] ||
      'CC',
    purchaserFirstName:
      purchaserFirstName || (!isExchange ? storedPurchaser?.purchaserFirstName : undefined) || '',
    purchaserLastName:
      purchaserLastName || (!isExchange ? storedPurchaser?.purchaserLastName : undefined) || '',
    purchaserSecondLastName:
      purchaserSecondLastName ||
      (!isExchange ? storedPurchaser?.purchaserSecondLastName : undefined) ||
      '',
    email: email || (!isExchange ? storedPurchaser?.email : undefined) || '',
    phone: phone || (!isExchange ? storedPurchaser?.phone : undefined) || '',
    phoneCountry: phoneCountryValue,
    phoneCode: phoneCode || getCountryCallingCode(phoneCountryValue) || '',
    ...(userNeededForPurchaser && { ...parsePurchaser(userNeededForPurchaser) }),
  };

  return { initialValues };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmitFail: (fields) => {
    // eslint-disable-next-line prettier/prettier
    dispatch(setError(200, 'check_the_fields_in_red', 'warning', false, null, null, null, 'fixed'));
    dispatch(formErrors(1, fields));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM,
    destroyOnUnmount: false,
    validate: createValidator(purchaserValidations),
    onSubmitSuccess: (result, dispatch, props) => {
      setStoredPurchaser(props.values);
    },
    onChange: (values, dispatch) => {
      const { documentId, documentType } = values;
      if ((documentType === 'CPF' || documentType === 'CNPJ') && documentId) {
        const valueWithOnlyDigits = documentId.replace(/\D/g, '');
        dispatch(change('purchaser', 'documentId', valueWithOnlyDigits));
      }
    },
  }),
)(PurchaserForm);
