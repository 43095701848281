import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { trackSearchChanged } from 'metrics/user-analytics/search';
import CSSVariablesProvider from 'components/CSSVariablesProvider/CSSVariablesProvider';
import SearchWidget from '../../ui/atoms/SearchWidget';
import '../../styles/components/search/SearchForm';

/**
 * Search bar component
 * @param {Object} props - Component props
 * @param {boolean} props.isModalVersion - If the search bar is being used inside a modal
 * @returns {JSX} - React component
 */
const SearchBar = ({ isModalVersion }) => {
  const { env, features } = useSelector((state) => state.whitelabelConfig);
  const { origin, destination, departureDate, returnDate, hybridTrip } = useSelector(
    ({ search }) => {
      const searchWidgetDateFormat = env.searchWidget.dateFormat;
      return {
        origin: search.get('originId'),
        destination: search.get('destinationId'),
        departureDate: moment(search.get('departureDate'), 'DD-MM-YYYY').format(
          searchWidgetDateFormat?.toUpperCase() || 'DD-MMM-YY',
        ),
        ...(search.get('returnDate') && {
          returnDate: moment(search.get('returnDate'), 'DD-MM-YYYY').format(
            searchWidgetDateFormat?.toUpperCase() || 'DD-MMM-YY',
          ),
        }),
        hybridTrip: search.get('hybridTrip'),
      };
    },
  );

  /**
   * Tracks the search changed event
   */
  const trackOnChangeSearch = () => {
    trackSearchChanged({ searchType: isModalVersion ? 'modal' : 'header' });
  };

  const searchWidget = (
    <SearchWidget
      origin={origin}
      destination={destination}
      departureDate={departureDate}
      returnDate={returnDate}
      hybridTrip={hybridTrip}
      searchCallback={trackOnChangeSearch}
    />
  );
  return isModalVersion ? (
    <CSSVariablesProvider
      theme={env.theme}
      funnelStyle={features.FUNNEL_STYLE}
      funnelStyleBrandVariation={env.brand.toUpperCase()}
    >
      {searchWidget}
    </CSSVariablesProvider>
  ) : (
    <div className="search-form-wrapper">
      <div className="search-form-wrapper-container">{searchWidget}</div>
    </div>
  );
};

SearchBar.propTypes = {
  isModalVersion: PropTypes.bool,
};
export default SearchBar;
