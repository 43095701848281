import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { change, Field } from 'redux-form';
import { selectRenderer } from 'utils/formRenderers';
import { useDispatch } from 'react-redux';

function MinorTravelModeField({ age, name }) {
  const dispatch = useDispatch();
  const [isOverTwelve, setIsOverTwelve] = useState(false);
  const { t } = useTranslation('passengers');

  useEffect(() => {
    setIsOverTwelve(age >= 12);

    if (age >= 18) {
      dispatch(change('passengers', name, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [age, name]);

  if (age >= 18) return null;

  const baseOptions = [{ label: t('label.accompaniedTravel'), value: 'accompaniedTravel' }];
  const notarialPermissionOption = {
    label: t('label.notarialPermissionTravel'),
    value: 'notarialPermissionTravel',
  };
  const options = isOverTwelve ? [...baseOptions, notarialPermissionOption] : baseOptions;

  return (
    <Field
      name={name}
      id={name}
      component={selectRenderer}
      label={t('label.minor_travel_mode')}
      placeholder={t('placeholder.minor_travel_mode')}
      options={options}
    />
  );
}

MinorTravelModeField.propTypes = {
  age: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default MinorTravelModeField;
