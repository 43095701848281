import { connect } from 'react-redux';
import { toggleModal } from '@/actions';
import TicketItinerary from './TicketItinerary';

const mapStateToProps = ({ purchase }) => {
  const departureTrip = purchase.get('departs');
  const returnTrip = purchase.get('returns');

  return {
    departureTrip: departureTrip.toJS(),
    returnTrip: returnTrip.toJS(),
    roundTrip: purchase.get('roundTrip'),
    isOpenTicket: purchase.get('openTicket'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (component, extraProps) => dispatch(toggleModal('visible', component, extraProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketItinerary);
