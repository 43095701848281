import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import 'styles/components/TripListOptions';
import { useTranslation } from 'react-i18next';
import { Text, Gap, Visibility } from '@reservamos/elements';
import AlertSubscriptionModal from './AlertSubscriptionModal';
import TripListOptions from './search/TripListOptions';
import ChangeProvider from '../ui/atoms/ChangeProvider';
import ResultsTitle from '../ui/atoms/ResultsTitle';
import NavbarFilters from './search/NavbarFilters/NavbarFiltersComponent';
import { FiltersProvider } from '../context/FiltersContext';

const propTypes = {
  way: PropTypes.string.isRequired,
  provider: PropTypes.object,
  hasOtherProviders: PropTypes.bool.isRequired,
  sortOn: PropTypes.bool.isRequired,
  departureFilterOn: PropTypes.bool.isRequired,
  locationFilterOn: PropTypes.bool.isRequired,
  stopsFilterOn: PropTypes.bool.isRequired,
  showLocationFilter: PropTypes.bool.isRequired,
  locations: PropTypes.shape({
    departure: PropTypes.array.isRequired,
    arrival: PropTypes.array.isRequired,
  }).isRequired,
  departureLocation: PropTypes.string.isRequired,
  arrivalLocation: PropTypes.string.isRequired,
  floatingOptions: PropTypes.number.isRequired,
  sortProperty: PropTypes.string.isRequired,
  providerSortProperty: PropTypes.string,
  activeDeparture: PropTypes.string.isRequired,
  onSortSelect: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onChangeProviderClick: PropTypes.func.isRequired,
  trips: PropTypes.array,
  isStopsFilterAvailable: PropTypes.bool,
  stopsFilter: PropTypes.string,
  date: PropTypes.string,
  roundTrip: PropTypes.bool,
  isOpenTicketList: PropTypes.bool,
  onResetFilters: PropTypes.func,
};

const defaultProps = {
  providerSortProperty: '',
  stopsFilter: '',
  date: '',
  isStopsFilterAvailable: false,
  trips: null,
  roundTrip: false,
};

/**
 * Component for displaying the header of the trip list.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.way - The direction of the trip.
 * @param {Object} props.provider - The selected provider.
 * @param {boolean} props.hasOtherProviders - Flag indicating if there are other providers available.
 * @param {boolean} props.sortOn - Flag indicating if sorting is enabled.
 * @param {boolean} props.departureFilterOn - Flag indicating if departure filter is enabled.
 * @param {boolean} props.locationFilterOn - Flag indicating if location filter is enabled.
 * @param {string} props.stopsFilter - The selected stops filter.
 * @param {boolean} props.stopsFilterOn - Flag indicating if stops filter is enabled.
 * @param {boolean} props.showLocationFilter - Flag indicating if location filter should be shown.
 * @param {Object} props.locations - The available locations for filtering.
 * @param {number} props.floatingOptions - The number of floating filter options.
 * @param {string} props.sortProperty - The selected sort property.
 * @param {string} props.providerSortProperty - The selected provider sort property.
 * @param {string} props.activeDeparture - The selected active departure filter.
 * @param {string} props.departureLocation - The selected departure location.
 * @param {string} props.arrivalLocation - The selected arrival location.
 * @param {Function} props.onSortSelect - The function to be called when a sort option is selected.
 * @param {Function} props.onFilterSelect - The function to be called when a filter option is selected.
 * @param {Function} props.onOptionClick - The function to be called when a filter option is clicked.
 * @param {Function} props.onChangeProviderClick - The function to be called when changing the provider.
 * @param {Array} props.trips - The list of trips.
 * @param {boolean} props.isStopsFilterAvailable - Flag indicating if stops filter is available.
 * @param {string} props.date - The selected date.
 * @param {boolean} props.roundTrip - Flag indicating if it's a round trip.
 * @param {boolean} props.isOpenTicketList - Flag indicating if it's an open ticket list.
 * @returns {JSX.Element} The rendered component.
 */
const TripListHeader = ({
  way,
  provider,
  hasOtherProviders,
  sortOn,
  departureFilterOn,
  locationFilterOn,
  stopsFilter,
  stopsFilterOn,
  showLocationFilter,
  locations,
  floatingOptions,
  sortProperty,
  providerSortProperty,
  activeDeparture,
  departureLocation,
  arrivalLocation,
  onSortSelect,
  onFilterSelect,
  onOptionClick,
  onChangeProviderClick,
  trips,
  isStopsFilterAvailable,
  date,
  roundTrip,
  isOpenTicketList,
  onResetFilters,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const departureText = roundTrip && t('trips:one_way_trip');
  const titleText = way === 'return' ? t('trips:return_trip') : departureText;
  const sectionTitle = way === 'return' ? 'schedulesReturn' : 'schedules';

  if (!provider) return null;

  const isNewFilters = features.NEW_FILTERS_VERSION;

  const rightContent = (
    <Gap columnGap="M">
      <Visibility type="hideForMobileOnly">
        <AlertSubscriptionModal />
      </Visibility>

      {features.PROVIDERS_SELECTION_ENABLED && (
        <ChangeProvider
          hasOtherProviders={hasOtherProviders}
          onChangeProviderClick={onChangeProviderClick}
        />
      )}

      {isNewFilters ? (
        <FiltersProvider>
          <NavbarFilters
            onApplyFilters={onFilterSelect}
            onChangeSort={onSortSelect}
            way={way}
            onResetFilters={onResetFilters}
            isOpenTicketList={isOpenTicketList}
            onSidebar={false}
          />
        </FiltersProvider>
      ) : (
        <TripListOptions
          hasOtherProviders={hasOtherProviders}
          sortOn={sortOn}
          departureFilterOn={departureFilterOn}
          locationFilterOn={locationFilterOn}
          showLocationFilter={showLocationFilter}
          locations={locations}
          floatingOptions={floatingOptions}
          sortProperty={sortProperty}
          providerSortProperty={providerSortProperty}
          activeDeparture={activeDeparture}
          departureLocation={departureLocation}
          arrivalLocation={arrivalLocation}
          onSortSelect={onSortSelect}
          onFilterSelect={onFilterSelect}
          onOptionClick={onOptionClick}
          onChangeProviderClick={onChangeProviderClick}
          stopsFilterOn={stopsFilterOn}
          stopsFilter={stopsFilter}
          trips={trips}
          isStopsFilterAvailable={isStopsFilterAvailable}
        />
      )}
    </Gap>
  );

  return (
    <ResultsTitle
      sectionTitle={sectionTitle}
      date={date}
      subtitle={titleText}
      disclaimer={
        features.SHOW_SCHEDULE_CHANGES_TEXT &&
        `*${t('trips:schedule_changes_without_prior_notice')}.`
      }
      rightContent={
        isOpenTicketList ? <Text>{t('search:label.not_filters_open_ticket')}</Text> : rightContent
      }
    />
  );
};

TripListHeader.propTypes = propTypes;
TripListHeader.defaultProps = defaultProps;

export default TripListHeader;
