import store from '@/store';
import { SET_AD_DATA, SET_AD_IS_LOADING, SET_AD_HAS_ERROR } from '../constants/ActionTypes';
import fetchCampaingAd from '../utils/fetchCampaingAd';

export const setAdIsLoading = (isLoading) => ({
  isLoading,
  type: SET_AD_IS_LOADING,
});

export const setAdHasError = (hasError) => ({
  hasError,
  type: SET_AD_HAS_ERROR,
});

export const setAdData = (data) => ({
  data,
  type: SET_AD_DATA,
});

export const fetchAd = (queryInput, space) => (dispatch) => {
  const { whitelabelConfig } = store.getState();
  if (!whitelabelConfig.env.api.adsUrl) return;

  dispatch(setAdIsLoading(true));
  dispatch(setAdHasError(false));

  return fetchCampaingAd(queryInput, space)
    .then((data) => {
      dispatch(setAdData(data ? data.ad : null));
    })
    .catch(() => {
      dispatch(setAdHasError(true));
      dispatch(setAdData(null));
    })
    .finally(() => dispatch(setAdIsLoading(false)));
};
