import { compose } from 'redux';
import { connect } from 'react-redux';
import { expirePurchase } from '@/actions/purchase';
import withPurchaseTimer from './withPurchaseTimer';

const mapStateToProps = (state) => {
  const { purchase } = state;
  const {
    expiresAt,
    currentTime,
    token: purchaseToken,
    isExpired,
    expirationExtension,
  } = purchase.toJS();
  return {
    expiresAt,
    currentTime,
    purchaseToken,
    isExpired,
    expirationExtension,
  };
};

const mapDispatchToProps = (dispatch) => ({
  /** Function to expires the purchase */
  expirePurchase: () => dispatch(expirePurchase()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withPurchaseTimer);
