export default function useReactNativeMessage() {
  const post = (message) => {
    try {
      const JSONMessage = JSON.stringify(message);
      if (window && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSONMessage);
      }
    } catch (error) {


    }
  };

  const listen = (listener) => {
    try {
      if (window) {
        if (window.addEventListener) {
          window.addEventListener('message', listener, false);
        } else {
          window.attachEvent('onmessage', listener);
        }
      }
    } catch (error) {


    }
  };

  return { post, listen };
}
