import React from 'react';
import './LoadingDots.scss';

const LoadingPulse = () => (
  <div className="loader-pulse">
    <div />
    <div />
    <div />
  </div>
);

export default LoadingPulse;
