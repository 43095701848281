/**
 * Factory function to create a Nequi payment method instance.
 * @returns {object} Nequi payment method instance with strategy methods.
 */
const Nequi = () => {
  /** @type {object|null} Strategy to handle Nequi payments. */
  let strategy = null;

  /**
   * Sets the strategy to handle Nequi payments.
   * @param {object} newStrategy - The strategy to be used for Nequi payments.
   */
  const setStrategy = (newStrategy) => {
    strategy = newStrategy;
  };

  /**
   * Creates a token using the current strategy.
   * @param {object} data - The data needed to create the token.
   * @throws Will throw an error if a strategy has not been set.
   * @returns {string} A token representing the payment data.
   */
  const createToken = (data) => {
    if (!strategy) {
      throw new Error('Nequi strategy not set');
    }
    return strategy.createToken(data);
  };

  /**
   * Determines if a redirect is needed based on the current strategy.
   * @throws Will throw an error if a strategy has not been set.
   * @returns {boolean} True if a redirect is needed, false otherwise.
   */
  const needsRedirect = () => {
    if (!strategy) {
      throw new Error('Nequi strategy not set');
    }
    return strategy.needsRedirect;
  };

  return {
    setStrategy,
    createToken,
    needsRedirect,
  };
};

const nequi = Nequi();

export default nequi;
