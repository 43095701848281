import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import notAllowed from '../../../images/badges/not-allowed.svg';

const NotAvailableCategory = ({ type, isFirst, isLast }) => {
  const { t } = useTranslation('seats');
  const { brand } = useWhitelabelEnvs();
  return (
    <div className="fade-in">
      <Text mobileSize="S" cursorDefault>
        {isFirst && (
          <>
            &nbsp; <Icon size="S" type={notAllowed} /> &nbsp;
          </>
        )}
        {`${t(`passengers:type.${type}`, { context: brand })}, `}
        {isLast && `${t('passengers:type.out_of_stock')}`}
      </Text>
    </div>
  );
};

NotAvailableCategory.propTypes = {
  type: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

const NotAvailableCategoriesList = ({ categories }) => {
  const notAvailableCategories = categories.map(({ type }, index) => (
    <NotAvailableCategory
      key={type}
      type={type}
      isFirst={index === 0}
      isLast={index === categories.length - 1}
    />
  ));

  return notAvailableCategories;
};

NotAvailableCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default NotAvailableCategoriesList;
