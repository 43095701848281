import { connect } from 'react-redux';
import { toggleMenu } from '@/actions';
import Header from './Header';

const mapStateToProps = ({ ux }) => ({
  showMenu: ux.showMenu,
});

const mapDispatchToProps = (dispatch) => ({
  switchMenu: () => dispatch(toggleMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
