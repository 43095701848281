import React from 'react';
import PropTypes from 'prop-types';
import PlaceItem from './PlaceItem';
import EndItem from './EndItem';
import TransferItem from './TransferItem';
import StopItem from './StopItem';

const propTypes = {
  type: PropTypes.string.isRequired,
};

/**
 * Component used as body for trip timeline.
 * @param {Object} props - Object props
 * @param {string} props.type - Indicates stage of the trip.
 * @returns {JSX.Element} component corresponding to the stage of the trip.
 */
const TimeLineItem = ({ type, ...props }) => {
  switch (type) {
    case 'departure':
    case 'place':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PlaceItem {...props} type={type} />;
    case 'stop':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <StopItem {...props} />;
    case 'transfer':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <TransferItem {...props} />;
    case 'end':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <EndItem {...props} />;
    default:
      return null;
  }
};

TimeLineItem.propTypes = propTypes;

export default TimeLineItem;
