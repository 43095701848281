import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Gap, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import 'styles/components/AgencyForm';
import CheckList from '../../ui/atoms/CheckList';

const FreePayForm = ({ isExchange }) => {
  const { t } = useTranslation('payment');
  const exchange = isExchange ? 'exchange' : '';

  const labels = [
    t('free_pay_instructions.step_1', { context: exchange }),
    t('free_pay_instructions.step_2', { context: exchange }),
  ];
  return (
    <div className="checkout-section">
      <Spacing vertical>
        <Gap>
          <i className="agencypay-cont-icon" />
          <Text size="L" weight="semibold" color="primary">
            {t('free_pay_instructions.title', { context: exchange })}
          </Text>
        </Gap>

        <CheckList
          title={t('free_pay_instructions.subtitle', { context: exchange })}
          labels={labels}
        />
      </Spacing>
    </div>
  );
};

FreePayForm.propTypes = {
  isExchange: PropTypes.bool,
};

export default FreePayForm;
