import { connect } from 'react-redux';
import ModalOpenTicket from './ModalOpenTicket';
import getSearchAnalyticsData from '../../../../utils/search/getSearchAnalyticsData';

const mapStateToProps = (state) => {
  const { search, trips, cities, terminals } = state;
  const searchId = search.getIn(['departure', 'id']);
  const tripList = searchId ? trips.getIn([searchId, 'buses', 'trips']).toJS() : undefined;
  const openTicketTrip = tripList ? tripList.find((trip) => trip.openTicket) : undefined;
  const isRoundTrip = Boolean(search.get('returnDate'));
  const isDynamicPricing = Boolean(search.get('dynamicPricing'));

  const departureId = search.getIn(['departure', 'id']);
  const places = { ...cities, ...terminals };
  const departures = trips.get(departureId);

  const { search: analyticSearchData } = getSearchAnalyticsData({
    search,
    places,
    departures,
    returns: null,
    openTicket: true,
  });

  return {
    openTicketTrip,
    isRoundTrip,
    isDynamicPricing,
    analyticSearchData,
  };
};

export default connect(mapStateToProps)(ModalOpenTicket);
