import { trackEvent } from 'user-analytics';

export const loyaltyTracker = ({ wallet, purchaseToken, amount, action }) => {
  trackEvent('Wallet State Changed', {
    'Wallet Type': wallet,
    'Amount': amount,
    'Purchase Token': purchaseToken,
    'Action': action,
  });
};

export const loyaltyApplyTracker = ({ wallet, purchaseToken, amount }) => {
  loyaltyTracker({ wallet, purchaseToken, amount, action: 'Apply' });
};

export const loyaltyRemoveTracker = ({ wallet, purchaseToken }) => {
  loyaltyTracker({ wallet, purchaseToken, amount: 0, action: 'Remove' });
};

export const loyaltyModifyTracker = ({ wallet, purchaseToken, amount }) => {
  loyaltyTracker({ wallet, purchaseToken, amount, action: 'Modify' });
};
