/**
 * Builds a search string
 * @param {string} origin - Departure ID
 * @param {string} destination - Destination ID
 * @param {Date} departure - Departure date
 * @param {Date} returns - Return date if round trip
 * @returns {string}
 */
export const buildSearchUrl = (origin, destination, departure, returns, passengers) => {
  const returnDate = returns ? `${returns}/` : '';

  const url = `/search/${origin}/${destination}/${departure}/${returnDate}p/${passengers}/departures`;
  return url;
};

/**
 * Remove all the params from a url
 * @param {String} sourceURL url string who will be cleaned
 * @returns new url
 */
export function removeAllURLParams(sourceURL) {
  const rtn = sourceURL.split('?')[0];
  return rtn;
}

/**
 * remove a param from the url that is passed as an argument
 * @param {String} key param to be removed
 * @param {String} sourceURL url where the param will be removed from
 * @returns new url
 */
export function removeURLParam(key, sourceURL) {
  let rtn = sourceURL.split('?')[0];
  let param;
  let paramsArr = [];
  const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArr = queryString.split('&');
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      // eslint-disable-next-line prefer-destructuring
      param = paramsArr[i].split('=')[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    if (paramsArr.length) rtn += `?${paramsArr.join('&')}`;
  }
  return rtn;
}

/**
 * Function to remove a query param from the current URL
 * @param {*} param - Query param to be removed
 */
export function replaceURLParam(param) {
  if (window) {
    window.history.replaceState(null, null, removeURLParam(param, window.location.href));
  }
}

/**
 * Delete all analytics params from the current URL
 */
export function cleanAnalyticsParams() {
  const queryParams = new URLSearchParams(window.location.search);
  const prefix = 'analytics_';
  let newUrl;
  Array.from(queryParams.keys()).forEach((param) => {
    if (param.startsWith(prefix)) newUrl = removeURLParam(param, newUrl || window?.location.href);
  });
  if (newUrl) window.history.replaceState(null, null, newUrl);
}

/**
 * Generates a Google Maps search URL based on provided latitude and longitude coordinates.
 * @param {number} long - The longitude coordinate.
 * @param {number} lat - The latitude coordinate.
 */
export const generateGoogleMapsSearchUrl = ({ long, lat }) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
};

export const generateDepartureUrl = ({
  originSlug,
  destinationSlug,
  departureDate,
  returnDate,
  passengers,
  useProviderSelection,
}) => {
  const endRoute = useProviderSelection ? 'providers' : 'departures';
  return `/search/${originSlug}/${destinationSlug}/${departureDate}/${returnDate}/p/${passengers}/${endRoute}`;
};
