/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const propTypes = {
  code: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const RouteStatus = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = code;
      return children;
    }}
  />
);

RouteStatus.propTypes = propTypes;

export default RouteStatus;
