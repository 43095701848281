import moment from 'moment';
import { List, Map } from 'immutable';
import { afternoonEval, earlyMorningEval, morningEval, nightEval } from './trips/parseTripDetails';

/**
 * Filters the trips based on the provider ID.
 * @param {Immutable.Map} trip - The trip to filter.
 * @param {string} providerId - The ID of the provider.
 * @returns {boolean} Whether the trip matches the provider ID or not.
 */
const providerFilter = (trip, providerId) => !providerId || trip.get('providerId') === providerId;

// // flat fare discounts names
// const SINGLE_FLAT_FARE_DISCOUNT = 'single_trip_flat_fare_discount';
// const DEPARTURE_FLAT_FARE_DISCOUNT = 'departure_on_round_trip_flat_fare_discount';
// const SINGLE_ON_ROUND_FLAT_FARE_DISCOUNT = 'single_on_round_trip_flat_fare_discount';

/**
 * Determines if the trip's departure time is after the provided time.
 * @param {Immutable.Map} trip - The trip to compare.
 * @param {string} departureAfterTime - The time to compare against.
 * @returns {boolean} Whether the trip's departure time is after the provided time or not.
 */
export const departureAfter = (trip, departureAfterTime) => {
  if (trip.get('openTicket')) return true;

  const departureTime = moment(departureAfterTime);
  const tripTime = moment(trip.get('departure'));

  return tripTime.isAfter(departureTime);
};

/**
 * Determines if the trip's departure time satisfies the given schedule condition.
 * @param {number} tripHours - The trip's departure time in hours.
 * @param {string} departureTime - The schedule condition.
 * @returns {boolean} Whether the trip's departure time satisfies the schedule condition or not.
 */
export const getScheduleCondition = (tripHours, departureTime) => {
  switch (departureTime) {
    case 'earlymorning':
      return earlyMorningEval(tripHours);
    case 'morning':
      return morningEval(tripHours);
    case 'afternoon':
      return afternoonEval(tripHours);
    case 'night':
      return nightEval(tripHours);
    default:
      return true;
  }
};

/**
 * Filters the trips based on the departure time.
 * @param {Immutable.Map} trip - The trip to filter.
 * @param {Array|string} departureTimes - The departure times to filter by.
 * @returns {boolean} Whether the trip matches the departure times or not.
 */
export const departureTimeFilter = (trip, departureTimes) => {
  if (trip.get('openTicket')) return true;
  const tripHours = moment(trip.get('departure')).hours();
  let condition;
  if (Array.isArray(departureTimes)) {
    departureTimes.forEach((dpt) => {
      const evaluatedCondition = getScheduleCondition(tripHours, dpt);
      condition = condition ? condition || evaluatedCondition : evaluatedCondition;
    });
    return condition;
  }
  return getScheduleCondition(tripHours, departureTimes);
};

/**
 * Determines if the trip's number of stops satisfies the given stop condition.
 * @param {number} stops - The trip's number of stops.
 * @param {string} type - The stop condition.
 * @returns {boolean} Whether the trip's number of stops satisfies the stop condition or not.
 */
const getStopCondition = (stops, type) => {
  switch (type) {
    case 'multiple':
      return stops >= 1;
    case 'direct':
      return stops === 0;
    default:
      return true;
  }
};

/**
 * Filters the trips based on the number of stops.
 * @param {Immutable.Map} trip - The trip to filter.
 * @param {Array|string} types - The stop types to filter by.
 * @returns {boolean} Whether the trip matches the stop types or not.
 */
export const stopsFilter = (trip, types) => {
  if (trip.get('openTicket')) return true;

  const stops = trip.get('stops');
  let condition;
  if (Array.isArray(types)) {
    types.forEach((type) => {
      const evaluatedCondition = getStopCondition(stops, type);
      condition = condition ? condition || evaluatedCondition : evaluatedCondition;
    });
    return condition;
  }
  return getStopCondition(stops, types);
};

/**
 * Filters the trips based on the passenger categories.
 * @param {Immutable.Map} trip - The trip to filter.
 * @param {Array} categories - The passenger categories to filter by.
 * @returns {boolean} Whether the trip matches the passenger categories or not.
 */
export const categoriesFilter = (trip, categories, searchPassengers) => {
  if (trip.get('openTicket') || categories.length === 0) {
    return true;
  }

  const tripCategories = trip.get('passengerTypes');
  const tripCategoriesMap = tripCategories.reduce((accum, category) => {
    const type = category.get('type');
    const availability = category.get('availability');
    accum[`${type}`] = availability;
    return accum;
  }, {});

  return categories.reduce((accum, category) => {
    let categoriesHasAvailability =
      tripCategoriesMap[`${category}`] && tripCategoriesMap[`${category}`] > 0;

    if (searchPassengers && searchPassengers[category]) {
      categoriesHasAvailability =
        categoriesHasAvailability &&
        searchPassengers[category] &&
        tripCategoriesMap[category] >= searchPassengers[category];

      return accum !== null ? accum && categoriesHasAvailability : categoriesHasAvailability;
    }
    if (categoriesHasAvailability || (category === 'supportWoman' && trip.get('supportWoman'))) {
      return accum !== null ? accum || true : true;
    }
    return accum || false;
  }, null);
};

/**
 * Filters the trips based on the trip options.
 * @param {Immutable.Map} trip - The trip to filter.
 * @param {Array} tripOptions - The trip options to filter by.
 * @returns {boolean} Whether the trip matches the trip options or not.
 */
export const tripOptionsFilter = (trip, tripOptions) => {
  if (trip.get('openTicket') || tripOptions.length === 0) {
    return true;
  }

  return tripOptions.reduce((accum, tripOption) => {
    /* Get key as property from object. I.E: {wallet: costapass} returns wallet */
    const property = Object.keys(tripOption)[0];
    const value = tripOption[property];
    const tripAttributeValue = trip.get(property);
    const isObject = typeof tripAttributeValue === 'object';
    if (tripAttributeValue === value || (isObject && tripAttributeValue.includes(value))) {
      return accum !== null ? accum || true : true;
    }
    return accum || false;
  }, false);
};

/**
 * Filters a single trip by the provided filters.
 * @param {Immutable.Map} trip - Trip to filter.
 * @param {Immutable.Map} filters - The filters to apply.
 * @returns {boolean} Whether the trip passes the filters or not.
 */
const filterTripsBy = (trip, filters, searchPassengers) => {
  const providerId = filters.get('providerId');
  const departureTime = filters.get('departureTime');
  const stops = filters.get('stops');
  const sameOrigin = filters.get('sameOrigin');

  const categories = filters.get('categories');
  const departureAfterTime = filters.get('departureAfterTime');
  const hasDepartureTime = departureTime && !departureTime?.includes('none');
  const hasCategories = categories && Boolean(categories?.length);
  const hasStops = stops && !stops?.includes('none');
  const tripOptions = filters.get('tripOptions');
  const hasTripOptions = Boolean(tripOptions?.length);
  const hasRedirectSameOrigin = Boolean(sameOrigin);

  if (
    !hasDepartureTime &&
    !hasCategories &&
    !hasStops &&
    !providerId &&
    !departureAfterTime &&
    !hasTripOptions
  ) {
    return true;
  }

  let providerTrip = true;
  let afterDepartureTrip = true;
  let filtersBaseCondition;

  /**
   * As the first step, the trips are filter from departure time and provider ID.
   * departureAfterTime is a date where the trips are filtered from, this is applied for round trips,
   * because the return date should be after the departure date.
   * providerId is the ID of the provider to filter by. This is done for brands that have multiple lines/providers,
   * in this way it is just showed the trips of the selected provider.
   */
  if (departureAfterTime) {
    afterDepartureTrip = departureAfter(trip, departureAfterTime);
  }
  if (providerId) {
    providerTrip = providerFilter(trip, providerId);
  }
  const baseCondition = providerTrip && afterDepartureTrip;

  if (
    (providerId || afterDepartureTrip) &&
    !hasDepartureTime &&
    !hasCategories &&
    !hasStops &&
    !hasTripOptions &&
    !hasRedirectSameOrigin
  )
    return baseCondition;

  /**
   * Now all the filters are applied.
   */
  filtersBaseCondition = hasDepartureTime && departureTimeFilter(trip, departureTime);

  if (hasRedirectSameOrigin) {
    filtersBaseCondition =
      (sameOrigin === true && !trip.get('redirectTo')) ||
      trip.getIn(['redirectTo', 'brand']) === sameOrigin;
  }

  if (hasCategories) {
    const categoriesFiltered = categoriesFilter(trip, categories, searchPassengers);
    filtersBaseCondition = hasDepartureTime
      ? filtersBaseCondition && categoriesFiltered
      : categoriesFiltered;
  }

  if (hasStops) {
    const tripsStopsFiltered = stopsFilter(trip, stops);
    const hasFilters = hasCategories || hasDepartureTime;

    filtersBaseCondition = !hasFilters
      ? tripsStopsFiltered
      : filtersBaseCondition && stopsFilter(trip, stops);
  }

  if (hasTripOptions) {
    const tripOptionsFiltered = tripOptionsFilter(trip, tripOptions);
    const hasFilters = hasCategories || hasDepartureTime || hasStops;

    filtersBaseCondition = !hasFilters
      ? tripOptionsFiltered
      : filtersBaseCondition && tripOptionsFilter(trip, tripOptions);
  }

  return baseCondition && filtersBaseCondition;
};

/**
/**
 * Retrieves the list of departure and arrival places for the given trips and provider ID.
 * @param {Immutable.List} trips - The list of trips.
 * @param {string} providerId - The ID of the provider.
 * @returns {Object} The departure and arrival places.
 */
export function tripsLocationByProvider(trips, providerId) {
  if (!trips) {
    return {
      departure: [],
      arrival: [],
    };
  }

  const departurePlaces = [
    {
      id: 'none',
      name: 'Cualquier Ubicación',
      type: 'departureLocation',
    },
  ];
  const arrivalPlaces = [
    {
      id: 'none',
      name: 'Cualquier Ubicación',
      type: 'arrivalLocation',
    },
  ];
  const seenInDeparture = {};
  const seenInArrival = {};

  trips.forEach((trip) => {
    if (!providerFilter(trip, providerId)) {
      return;
    }

    const originId = trip.get('originId');
    const destinationId = trip.get('destinationId');

    if (!seenInDeparture[originId]) {
      departurePlaces.push({
        id: originId,
        name: trip.getIn(['origin', 'name']),
        type: 'departureLocation',
      });
      seenInDeparture[originId] = true;
    }

    if (!seenInArrival[destinationId]) {
      arrivalPlaces.push({
        id: destinationId,
        name: trip.getIn(['destination', 'name']),
        type: 'arrivalLocation',
      });
      seenInArrival[destinationId] = true;
    }
  });

  return {
    departure: departurePlaces,
    arrival: arrivalPlaces,
  };
}

/**
 * Sorts the trips based on the provided property.
 * @param {Immutable.Map} a - The first trip.
 * @param {Immutable.Map} b - The second trip.
 * @param {string} property - The property to sort by.
 * @param {Array} newTerminals - The list of new terminals.
 * @returns {number} The comparison result for sorting.
 */
const sortTripsBy = (a, b, property, newTerminals) => {
  if (['departure', 'arrival'].includes(property)) {
    return moment(a.get(property)).unix() - moment(b.get(property)).unix();
  }

  if (property === 'price') {
    const selector = ['pricing', 'total'];
    const subs = a.getIn(selector) - b.getIn(selector);
    if (subs === 0) return moment(a.get('departure')).unix() - moment(b.get('departure')).unix();
    return subs;
  }
  if (property === 'openTicket') {
    if (a.get(property) === b.get(property)) return 0;
    return a.get(property) ? -1 : 1;
  }
  if (property === 'discount') {
    const aProviderDiscount = a.getIn(['pricing', 'providerDiscount']);
    const bProviderDiscount = b.getIn(['pricing', 'providerDiscount']);
    const aDiscount = aProviderDiscount ? aProviderDiscount.get('total') : 0;
    const bDiscount = bProviderDiscount ? bProviderDiscount.get('total') : 0;

    return bDiscount - aDiscount;
  }

  if (property === 'near_terminals') {
    if (!newTerminals || !newTerminals.length) {
      return 0;
    }
    const aTerminal = newTerminals.find((terminal) => terminal.id === a.get('originId'));
    const bTerminal = newTerminals.find((terminal) => terminal.id === b.get('originId'));

    if (aTerminal && !bTerminal) return 1;
    if (!aTerminal && bTerminal) return -1;
    if (!aTerminal && !bTerminal) return 0;
  }

  return a.get(property) - b.get(property);
};

/**
 * Filters the trips based on the provided filters.
 * @param {Immutable.List} trips - The list of trips to filter.
 * @param {string} providerId - The ID of the provider to filter by.
 * @param {string} sortBy - The property to sort the trips by.
 * @param {Immutable.Map} filters - The filters to apply.
 * @param {Array} newTerminals - The list of new terminals.
 * @returns {Immutable.List} The filtered and sorted list of trips.
 */
export function filterTrips(trips, providerId, sortBy, filters, newTerminals, searchPassengers) {
  if (!trips) return List();
  // eslint-disable-next-line prettier/prettier
  const filteredTrips = filters
    ? trips.filter(
        (trip) => filterTripsBy(trip, filters.merge(Map({ providerId })), searchPassengers),
        // eslint-disable-next-line function-paren-newline
      )
    : trips;

  const sortedTrips = filteredTrips
    ?.sort((a, b) => sortTripsBy(a, b, sortBy || 'departure', newTerminals))
    ?.sort((a, b) => sortTripsBy(a, b, 'openTicket'));
  return sortedTrips;
}
