import React from 'react';
import PropTypes from 'prop-types';
import { DialogMessage } from '@reservamos/elements';

const ModalPopUp = ({
  children,
  buttonPrimaryText,
  buttonSecondaryText,
  modalTitle,
  modalIconType,
  onClickPrimary,
  onClickSecondary,
}) => {
  return (
    <DialogMessage
      title={modalTitle}
      iconType={modalIconType}
      primaryButton={buttonPrimaryText}
      onClickPrimary={onClickPrimary}
      secondaryButton={buttonSecondaryText}
      onClickSecondary={onClickSecondary}
      dialogType="primary"
      spacedButtons
      hideBorder
    >
      {children}
    </DialogMessage>
  );
};

ModalPopUp.propTypes = {
  modalTitle: PropTypes.string,
  modalIconType: PropTypes.string,
  children: PropTypes.node,
  buttonPrimaryText: PropTypes.string.isRequired,
  buttonSecondaryText: PropTypes.string,
  onClickPrimary: PropTypes.func.isRequired,
  onClickSecondary: PropTypes.func.isRequired,
};

ModalPopUp.defaultProps = {
  modalTitle: '',
  modalIconType: '',
  children: null,
  buttonSecondaryText: '',
};

export default ModalPopUp;
