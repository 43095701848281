// @ts-check

import conektaStoreStrategy from '../../methods/store/strategies/conektaStoreStrategy';
import kushkiStoreStrategy from '../../methods/store/strategies/kushkiStoreStrategy';
import paycashStoreStrategy from '../../methods/store/strategies/paycashStoreStrategy';

/**
 * @typedef {import('../../methods/store/strategies/StoreStrategy').StoreStrategy} StoreStrategy
 */

const engineStrategies = {
  kushki: kushkiStoreStrategy,
  conekta: conektaStoreStrategy,
  paycash: paycashStoreStrategy,
};

function StoreFactory() {
  /**
   * Creates an instance of a Store payment method with the correct strategy.
   * @function create
   * @param {Object} params
   * @param {string} params.engine - Payment engine.
   * @returns {StoreStrategy}
   */
  function create({ engine }) {
    const strategy = engineStrategies[engine];

    if (!strategy) throw new Error(`${engine} store strategy not found`);

    return strategy;
  }

  return { create };
}

const storeFactory = StoreFactory();

export default storeFactory;
