import store from '@/store';
import { autofill } from 'redux-form';
import { UPDATE_SIEMPRE_PLUS_DATA, SET_UPDATED_SIEMPRE_PLUS } from 'constants/ActionTypes';
import { camelizeKeys } from 'humps';

export function updateSiemprePlusData(user, loyaltyName) {
  return {
    type: UPDATE_SIEMPRE_PLUS_DATA,
    user: camelizeKeys(user),
    loyaltyName,
  };
}

export function setUpdated(isUpdated) {
  return { type: SET_UPDATED_SIEMPRE_PLUS, isUpdated };
}

export function updateFirstPassenger(passenger) {
  if (passenger && passenger.email) {
    store.dispatch(autofill('passengers', 'passengers[0].category', passenger.category));
    store.dispatch(autofill('passengers', 'passengers[0].busCategory', passenger.busCategory));
    store.dispatch(autofill('passengers', 'passengers[0].documentType', passenger.documentType));
    store.dispatch(autofill('passengers', 'passengers[0].firstName', passenger.firstName));
    store.dispatch(autofill('passengers', 'passengers[0].lastName', passenger.lastName));
    store.dispatch(
      autofill('passengers', 'passengers[0].secondLastName', passenger.secondLastName),
    );
    store.dispatch(autofill('passengers', 'passengers[0].documentId', passenger.documentId));
    store.dispatch(autofill('passengers', 'passengers[0].email', passenger.email));
  }
}

export function updatePurchaserData(purchaser) {
  const { purchaserFirstName, purchaserLastName, purchaserSecondLastName, email, phone } =
    purchaser;
  if (purchaser) {
    store.dispatch(autofill('purchaser', 'purchaserFirstName', purchaserFirstName));
    store.dispatch(autofill('purchaser', 'purchaserLastName', purchaserLastName));
    store.dispatch(autofill('purchaser', 'purchaserSecondLastName', purchaserSecondLastName));
    if (email) store.dispatch(autofill('purchaser', 'email', email));
    if (phone) store.dispatch(autofill('purchaser', 'phone', phone));
  }
}

export default updateSiemprePlusData;
