import { useState, useEffect } from 'react';

const defaultOptions = {
  shouldLoadScript: true,
};

const useScript = (scriptSrc, { shouldLoadScript } = defaultOptions) => {
  const [scriptStatus, setScriptStatus] = useState({
    isLoaded: false,
    hasError: false,
  });

  const handleError = () => {
    setScriptStatus({
      isLoaded: false,
      hasError: true,
    });
  };

  const handleLoad = () => {
    setScriptStatus({
      isLoaded: true,
      hasError: false,
    });
  };

  useEffect(() => {
    if (shouldLoadScript) {
      const script = document.createElement('script');

      script.src = scriptSrc;
      script.async = true;
      script.onerror = handleError;
      script.onload = handleLoad;

      document.body.appendChild(script);
    }
  }, [shouldLoadScript, scriptSrc]);

  return scriptStatus;
};

export default useScript;
