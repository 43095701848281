import React from 'react';
import { useSelector } from 'react-redux';

const BrandLogo = () => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  return (
    <a href={env.homeUrl}>
      <i className="topbar-logo" role="img" aria-label="Brand Logo" />
    </a>
  );
};

export default BrandLogo;
