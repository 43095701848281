import { useEffect, useState } from 'react';
import usePseStrategyContext from '../context/strategy/usePseStrategyContext';

const useBanksList = () => {
  const pse = usePseStrategyContext();
  const [banksList, setBanksList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  const fetchBanksList = async () => {
    try {
      setIsLoading(true);
      const banksList = await pse.fetchBanksList();
      setBanksList(banksList);
    } catch (error) {
      setHasError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBanksList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pse]);

  return { banksList, isLoading, hasError };
};

// eslint-disable-next-line import/prefer-default-export
export { useBanksList };
