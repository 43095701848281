import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class ProviderSort extends Component {
  componentDidMount() {
    document.querySelector('.modal').classList.add('modal-view-options');
  }

  componentWillUnmount() {
    document.querySelector('.modal').classList.remove('modal-view-options');
  }

  select(selected) {
    const { hideModal, onSortSelect } = this.props;
    onSortSelect(selected, 'providers');
    hideModal();
    window.scrollTo(0, 0);
  }

  render() {
    const { providerSortProperty, t } = this.props;

    const isChecked = (property) => property === providerSortProperty;

    return (
      <div className="modal-arrow-container" onClick={(e) => e.stopPropagation()}>
        <form className="modal-content floating-sort-menu">
          <div className="modal-row row-price" onClick={() => this.select('lowestPrice')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('lowestPrice')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_price')}</h4>
              <p>{t('label.description.selector_price')}</p>
            </div>
          </div>

          <div className="modal-row row-duration" onClick={() => this.select('duration')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('duration')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_duration')}</h4>
              <p>{t('label.description.selector_duration')}</p>
            </div>
          </div>

          <div className="modal-row row-trips" onClick={() => this.select('tripCount')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('tripCount')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_departures')}</h4>
              <p>{t('label.description.selector_departures')}</p>
            </div>
          </div>
        </form>

        <div className="arrow-down" />
      </div>
    );
  }
}

ProviderSort.propTypes = {
  providerSortProperty: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSortSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(ProviderSort);
