/* eslint-disable import/prefer-default-export */
import store from '@/store';
import { setConfig as setApiConfig } from 'js-api-client';
import environment from 'config/environment';
import { formatName } from './purchase/parsePassenger';
/**
 * Checks if the data passed as param is the same as the account data
 * @param {Object} data - Object with the data to compare
 * @param {*} account - Object with the account data
 * @returns Boolean
 */
export function isAccountData(data, account) {
  if (!data || !account) return false;
  const { firstName, lastName, secondLastName, email } = data;
  const {
    firstName: accountFirstName,
    lastName: accountLastName,
    secondLastName: accountSecondLastName,
    email: accountEmail,
  } = account;
  const { name: accountName } = formatName({
    firstName: accountFirstName,
    lastName: accountLastName,
    secondLastName: accountSecondLastName,
  });
  const { name: dataName } = formatName({ firstName, lastName, secondLastName });
  let isSame = accountName === dataName;
  isSame = email ? isSame && email === accountEmail : isSame;
  return isSame;
}

/**
 * Fetch the profile of the user.
 * @param {*} wallet - Wallet/loyalty type of the user
 * @param {*} token - User token.
 * @returns Object
 */
export const fetchProfile = (wallet, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${environment.api.purchaseUrl}/v2/${wallet}/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((profile) => {
        resolve(profile);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getUserByWalletType(walletType) {
  const { siemprePlus, costapass } = store.getState();
  const { user: siemprePlusOrDotersUser } = siemprePlus.toJS();
  const { profile: costapassUser } = costapass.toJS();

  const userExists = [siemprePlusOrDotersUser, costapassUser].some((user) => user && user.email);
  if (!userExists) return null;

  const userByWalletType = {
    siempreplus: siemprePlusOrDotersUser,
    costapass: costapassUser,
    travelpass: costapassUser,
    doters: siemprePlusOrDotersUser,
    default: siemprePlusOrDotersUser,
  };

  return userByWalletType[walletType] || userByWalletType.default;
}

/**
 * Indicates weather the user needs to select a loyalty program or not
 * when entering the Passengers step.
 *
 * @returns Boolean
 *
 */
export function needsLoyaltySelection() {
  const { purchase, siemprePlus, costapass } = store.getState();
  const { walletType, availableWallets } = purchase.toJS();
  const { user: siemprePlusOrDotersUser } = siemprePlus.toJS();
  const { profile: costapassUser } = costapass.toJS();
  const doterIsEnabledForPurchase = availableWallets.includes('doters');
  const costaPassEnabledForPurchase = availableWallets.includes('costapass');

  /**
   * A loyalty program can be selected if the user is logged in and
   * the loyalty program is enabled for the current purchase
   */
  const dotersCanBeUsed = siemprePlusOrDotersUser && doterIsEnabledForPurchase;
  const costapassCanBeUsed = costapassUser && costaPassEnabledForPurchase;
  return costapassCanBeUsed && dotersCanBeUsed && !walletType;
}

/**
 * Returns the walletType that should be used for the purchase
 *
 * @returns String
 */
export function getWalletTypeForPurchase() {
  const { purchase, siemprePlus, costapass, config, whitelabelConfig } = store.getState();
  const { user: siemprePlusOrDotersUser } = siemprePlus.toJS();
  const { profile: costapassUser } = costapass.toJS();
  const {
    walletType: purchaseWalletType,
    walletOriginalType,
    isExchange,
    token,
    availableWallets = [],
  } = purchase.toJS();

  const {
    env: { travelpassLoyaltyConfig },
  } = whitelabelConfig;
  const { loyaltyProgramSelected } = config;

  // If the purchase is an exchange, the walletType used should be the same as the original purchase
  const isPurchaseFlow = window && window.location.pathname.includes('/purchase');
  const walletType = isExchange ? walletOriginalType : purchaseWalletType;

  /**
   * @todo refactor this conditions to reduce complexity
   */

  /**
   * If the purchase has a walletType already set, the same walletType will be returned
   * just if the user that matches with this loyalty program is logged in
   */
  if (isPurchaseFlow && walletType && token) {
    if (getUserByWalletType(walletType)) return walletType;
    if (!isExchange && siemprePlusOrDotersUser) return siemprePlusOrDotersUser.loyaltyName;
    if (!isExchange && costapassUser) return travelpassLoyaltyConfig?.prefix;
  } else {
    const loyaltyUsers = {
      doters: siemprePlusOrDotersUser,
      costapass: costapassUser,
      travelpass: costapassUser,
    };
    const doterIsEnabledForPurchase = availableWallets.includes('doters');
    const costaPassEnabledForPurchase = availableWallets.includes('costapass');
    const travelPassEnabledForPurchase = availableWallets.includes('travelpass');
    const dotersCanBeUsed = siemprePlusOrDotersUser && doterIsEnabledForPurchase;
    const costapassCanBeUsed = costapassUser && costaPassEnabledForPurchase;
    const travelpassCanBeUsed = costapassUser && travelPassEnabledForPurchase;
    const selectedLoyaltyIsValidForPurchase = availableWallets.includes(loyaltyProgramSelected);
    const userIsLoggedInWithSelectedLoyalty = loyaltyUsers[loyaltyProgramSelected];

    /**
     * If the purchase doesn't have a walletType set up, a new one is returned based on the following rules:
     */
    const selectedLoyaltyIsValid =
      selectedLoyaltyIsValidForPurchase && userIsLoggedInWithSelectedLoyalty;
    if (selectedLoyaltyIsValid) return loyaltyProgramSelected;

    // Return null to allow the user to select a loyalty program using the LoyaltySelection component
    if (dotersCanBeUsed && costapassCanBeUsed && travelpassCanBeUsed && !loyaltyProgramSelected)
      return null;

    // If the user is logged in with a loyalty program, the same loyalty program will be used
    if (dotersCanBeUsed) return siemprePlusOrDotersUser.loyaltyName;
    if (costapassCanBeUsed || travelpassCanBeUsed) return travelpassLoyaltyConfig?.prefix;
  }
  /** If not a walletType can be used, it is returned null */
  return null;
}

export function setUserTokenForAPI() {
  const walletType = getWalletTypeForPurchase();
  if (walletType) {
    const { token: userAuthToken } = getUserByWalletType(walletType);
    setApiConfig({ userAuthToken });
  }
}

export function isServiceAllowed({ departs, returns, allowedServices }) {
  if (!departs?.fragments?.length) return false;
  if (returns && returns.fragments && !returns.fragments.length) return false;
  const serviceDeparture = departs.fragments['0'].service;
  const serviceReturn = returns.fragments ? returns.fragments['0'].service : null;

  if (!allowedServices?.length) return true;

  // eslint-disable-next-line prettier/prettier
  const departureServiceAllowed = allowedServices.find((service) => service === serviceDeparture);
  const returnsServiceAllowed =
    serviceReturn && allowedServices.find((service) => service === serviceReturn);

  if (departureServiceAllowed || returnsServiceAllowed) {
    return true;
  }
}

export function walletTypeNeedsUserInPassenger(walletType) {
  const { features } = store.getState().whitelabelConfig;
  const loyaltyNeedsAccount = features.LOYALTY_NEEDS_ACCOUNT_IN_PASSENGERS;
  if (!loyaltyNeedsAccount) return false;
  return loyaltyNeedsAccount.includes(walletType);
}

export function walletTypeNeedsUserInPurchaser(walletType) {
  const { features } = store.getState().whitelabelConfig;
  const loyaltyNeedsAccount = features.LOYALTY_NEEDS_ACCOUNT_IN_PURCHASER;
  if (!loyaltyNeedsAccount) return false;
  return features.LOYALTY_NEEDS_ACCOUNT_IN_PURCHASER.includes(walletType);
}
