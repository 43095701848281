import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { selectRenderer } from 'utils/formRenderers';

/**
 * GenderField component renders a dropdown field for selecting gender.
 *
 * @param {Object} props - Component props.
 * @param {string} props.name - The name of the field.
 * @returns {JSX.Element} The rendered component.
 */
const GenderField = ({ name }) => {
  const { t } = useTranslation('general');
  const options = [
    { label: t('male'), value: 'male' },
    { label: t('female'), value: 'female' },
  ];

  return (
    <Field
      id={name}
      name={name}
      label={t('gender')}
      placeholder={t('gender')}
      options={options}
      component={selectRenderer}
    />
  );
};

GenderField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default GenderField;
