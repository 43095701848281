import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DownloadTicketStep = ({ ticketUrl }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');

  const isAvailable = Boolean(ticketUrl);
  return (
    <div className="route-detail-row">
      <div className="rd-ticket">
        <div className={`tickets-${isAvailable ? 'succeed' : 'process'}-label`}>
          {ticketUrl && (
            <a href={ticketUrl} target="_blank">
              <p>Descargar boleto</p>
            </a>
          )}
          {!ticketUrl && <p>Boletos pendientes</p>}
        </div>
        <p className="rd-ticket-comment">
          <i>
            {isAvailable
              ? t('ticket_instructions', {
                  context: features.PRINT_TICKETS_REQUIRED ? 'print' : 'digital',
                })
              : t('ticket_instructions', { context: 'pending' })}
          </i>
        </p>
      </div>
    </div>
  );
};

DownloadTicketStep.propTypes = {
  ticketUrl: PropTypes.string.isRequired,
};

export default DownloadTicketStep;
