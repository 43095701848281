/* global */
import React from 'react';
import PropTypes from 'prop-types';
import PoweredBy from 'components/PoweredBy';
import 'styles/components/Loading';
import { useSelector } from 'react-redux';
import LoadingText from './LoadingText';

const Loading = ({ backgroundColor, loadingText }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const DOTS_LOADING = features.LOADING_TYPE === 'DOTS';

  return (
    <div className="loading-container">
      <div className="loader-wrap">
        {DOTS_LOADING ? (
          <div className="spinner">
            <div className="bounce-one" />
            <div className="bounce-two" />
            <div className="" />
          </div>
        ) : (
          <div className="loader-icons">
            <div className={`loader-circle ${backgroundColor}`} />
            <div className="loader-search">
              <i className="loader-image" />
              <i className="loader-image-second" />
            </div>
          </div>
        )}

        <LoadingText loadingText={loadingText} />
      </div>
      {features.SHOW_POWERED_BY && <PoweredBy />}
    </div>
  );
};

Loading.propTypes = {
  backgroundColor: PropTypes.string,
  loadingText: PropTypes.string,
};

Loading.defaultProps = {
  backgroundColor: '',
  loadingText: '',
};

export default Loading;
