import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import './BackButton.scss';
import i18n from 'i18next';

const BackButton = ({ onClick }) => (
  <div
    className="rvui-back-container"
    title={i18n.t('common:tooltip.back_to_schedules')}
    onClick={onClick}
  >
    <Text color="grayMedium" size="S">
      {i18n.t('common:labels.back_to')}
    </Text>
    <div className="rvui-back-button">
      <Text size="S" weight="bold" elementType="span">
        {i18n.t('common:buttons.schedules')}
      </Text>
    </div>
  </div>
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
