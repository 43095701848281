export default function parsePurchaseStatus(status, paymentType) {
  switch (status) {
    case 'pending':
      return {
        payment: paymentType === 'store' ? 'pending' : 'success',
        tickets: paymentType === 'store' ? 'pending' : 'process',
        sent: 'pending',
      };

    case 'completed':
      return {
        payment: 'success',
        tickets: 'success',
        sent: 'success',
      };

    default:
      return {
        payment: 'process',
        tickets: 'pending',
        sent: 'pending',
      };
  }
}
