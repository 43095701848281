import sentry from './Sentry';

/**
 * @typedef {object} ErrorMonitor - Error Monitoring interface
 * @property {(config: Object.<string, any>) => void} init - Initialize Error Monitoring
 * @property {(params: { error: Error, info: Object.<string, any> }) => void} notify - Send custom exception
 * @property {()=> any} getReduxMiddlewares - Get Erorr Monitoring Middleware
 */

/**
 * @typedef {object} MonitorImplementation - Error Monitor Implementation
 * @property {(config: Object.<string, any>)=> void} init - Monitor Implementation Initializer
 * @property {(params: { error: Error, info: Object.<string, any> }) => void} notify - Send custom exception
 * @property {()=> any} getReduxMiddleware - Get Erorr Monitoring Middleware
 */

/**
 * Error Monitoring Factory
 * @param {MonitorImplementation} strategy - Error Monitoring Implementation
 * @returns {Readonly<ErrorMonitor>} - Error Monitoring
 */
export const ErrorMonitoringFactory = (strategy) => {
  /**
   * Initialize Error Monitoring
   * @param {Object.<string, any>} options - Sentry options
   * @returns {void}
   */
  const init = (options) => {
    strategy.init(options);
  };

  /**
   * Send custom exception
   * @param {object} params - configurable parameters
   * @param {Error} params.error - error object
   * @param {Object.<string, any>} params.info - additional information to the error
   */
  const notify = ({ error, info }) => {
    strategy.notify({ error, info });
  };

  /**
   * Get Erorr Monitoring Middleware
   * @returns {*} - Error monitoring middleware
   */
  const getReduxMiddlewares = () => {
    return strategy.getReduxMiddleware();
  };

  return Object.freeze({
    init,
    notify,
    getReduxMiddlewares,
  });
};

const errorMonitoring = ErrorMonitoringFactory(sentry);

export default errorMonitoring;
