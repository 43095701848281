import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FlatButton } from '@reservamos/elements';
import { useSelector } from 'react-redux';

const LowAvailability = ({ availability, pricing, type }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const earlyDiscount = pricing.discountType === 'early_trip_discount';
  const lowAvailability = availability <= features.LOW_AVAILABILITY_THRESHOLD;
  const divClass =
    type === 'desktop' ? 'hide-for-mobile-only' : 'result-mobile-click show-for-mobile-only';

  if (earlyDiscount) {
    return (
      <em>
        <div className={divClass}>
          <FlatButton type="discount" size="XS" mobileSize="XS">
            {t('search:available_discounts', { count: pricing.discountAvailability })}
          </FlatButton>
        </div>
      </em>
    );
  }

  if (!earlyDiscount && lowAvailability) {
    return (
      <div className={divClass}>
        <em>
          <FlatButton type="discount" size="XS" mobileSize="XS">
            {t('search:available_seats', { count: availability })}
          </FlatButton>
        </em>
      </div>
    );
  }

  return (
    <p className={`flat-text-small-italic ${divClass}`}>
      {t('search:available_seats', { count: availability })}
    </p>
  );
};

LowAvailability.propTypes = {
  availability: PropTypes.number.isRequired,
  pricing: PropTypes.object,
  type: PropTypes.oneOf(['desktop', 'mobile']),
};

export default LowAvailability;
