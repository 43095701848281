import { useGrowthBook } from '@growthbook/growthbook-react';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';

/**
 * Custom Hook to get a feature value
 * @param {string} feature - The feature name without the brand.
 * @returns {boolean} - Whether the feature is on or not.
 */
const useGrowthBookFeatureValue = (feature) => {
  const { brand } = useWhitelabelEnvs();
  const growthBook = useGrowthBook();

  if (!growthBook?.ready) return null;

  const availableFeatures = growthBook.getFeatures();
  let featureName = `${feature}_${brand}`;
  if (!availableFeatures[featureName]) featureName = feature;
  const value = growthBook.getFeatureValue(featureName);

  return value;
};

export default useGrowthBookFeatureValue;
