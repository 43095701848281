import { useContext } from 'react';
import { FiltersContext } from '../context/FiltersContext';

const useFiltersContext = () => {
  const contextValue = useContext(FiltersContext);

  if (!contextValue) throw new Error('useFiltersContext must be used within a FiltersProvider');

  return contextValue;
};

export default useFiltersContext;
