import React from 'react';
import PropTypes from 'prop-types';
import SelectedSeats from 'components/SelectedSeats';
import { Box } from '@reservamos/elements';
import usePurchase from 'hooks/store/usePurchase';

const propTypes = {
  selectedSeats: PropTypes.array,
  removePadding: PropTypes.bool,
};

/**
 * Component used in itinerary detail to display seat and passanger information.
 * @param {Object} props - Object props
 * @param {array} props.selectedSeats - Indicates seats information.
 * @param {boolean} props.removePadding - Indicates validation for padding applying.
 * @returns {JSX.Element} A React component that renders selected seat's details.
 */
const SeatDetails = ({ selectedSeats, removePadding = false }) => {
  const { allowSeatSelection } = usePurchase();

  if (!selectedSeats || selectedSeats.length < 1 || !allowSeatSelection) return null;

  return (
    <Box paddingVertical={removePadding ? '' : 'S'} paddingHorizontal={removePadding ? '' : 'M'}>
      <SelectedSeats seats={selectedSeats} />
    </Box>
  );
};

SeatDetails.propTypes = propTypes;

export default SeatDetails;
