import { Map } from 'immutable';
import { decodePassengers, isOldSearchPassengersFormat } from 'utils/Reserbus';
import { searchedPassengersCanBeFiltered } from 'utils/passengers';
import { getShortAccessFilters } from '../utils/shortAccessFilters';
import filtersTypes from '../constants/filtersTypes';

/**
 * Retrieves the default filters value.
 * @returns {Map} The default filters value.
 */
export default function getDefaultFiltersValue() {
  const params = new URLSearchParams(window.location.search);
  const passengersCategories = window.location.pathname.split('/p/')[1]?.split('/')[0] ?? null;
  const decodedValidPassengersCategories =
    passengersCategories &&
    searchedPassengersCanBeFiltered({ searchPassengers: decodePassengers(passengersCategories) });
  const filterByCategoriesAvailability =
    decodedValidPassengersCategories && !isOldSearchPassengersFormat(passengersCategories);

  const filtersParam = (params.get('filters') || filterByCategoriesAvailability) ?? null;

  const savedFilters = getShortAccessFilters();
  const savedDepartureTimeFilters = [];
  let savedCategoriesFilters = [];
  const savedTripOptionsFilters = [];
  const savedStopsFilters = [];

  const isExchange = window.location.pathname.includes('exchange');

  if (filterByCategoriesAvailability && !isExchange) {
    const decodePassengersCategories = Object.keys(decodePassengers(passengersCategories));
    savedCategoriesFilters = decodePassengersCategories;
  }

  if (!filterByCategoriesAvailability && !filtersParam && savedFilters && !isExchange) {
    savedFilters.forEach((filter) => {
      if (filter.isActive && filter.filterType === filtersTypes.DEPARTURE_TIME) {
        savedDepartureTimeFilters.push(filter.value);
      }
      if (filter.isActive && filter.filterType === filtersTypes.CATEGORIES) {
        savedCategoriesFilters.push(filter.value);
      }
      if (filter.isActive && filter.filterType === filtersTypes.STOPS) {
        savedStopsFilters.push(filter.value);
      }
      if (filter.isActive && filter.filterType === filtersTypes.TRIP_OPTIONS) {
        savedTripOptionsFilters.push(filter.value);
      }
    });
  }

  return Map({
    departureTime: savedDepartureTimeFilters.length ? savedDepartureTimeFilters : ['none'],
    departureLocation: 'none',
    arrivalLocation: 'none',
    stopovers: 'none',
    stops: savedStopsFilters.length ? savedStopsFilters : ['none'],
    categories: savedCategoriesFilters.length ? savedCategoriesFilters : [],
    tripOptions: savedTripOptionsFilters.length ? savedTripOptionsFilters : [],
  });
}
