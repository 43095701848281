import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faresCollection } from 'utils/providers/getFares';
import 'styles/components/PurchaseFare';

const PurchaseFare = ({ code, isRoundTrip, isReturn }) => {
  const { t } = useTranslation('purchase');

  let titleContext = null;
  if (isRoundTrip) {
    titleContext = 'depart';
    if (isReturn) titleContext = 'return';
  }

  const { name, features } = faresCollection.get(code);

  return (
    <div className="purchase-fare">
      <p className="purchase-fare-title">{`${t('Fare Title', {
        context: titleContext,
      })} ${name}`}</p>
      <ul className="purchase-fare-list">
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

PurchaseFare.propTypes = {
  code: PropTypes.oneOf(['ECO', 'XTRA', 'FLEX']).isRequired,
  isRoundTrip: PropTypes.bool,
  isReturn: PropTypes.bool,
};

export default PurchaseFare;
