import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Currency,
  Gap,
  Icon,
  Link,
  Spacing,
  Text,
  TextCollapse,
  ToggleSwitch,
} from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import './AncillariesSwitch.scss';

/**
 * Component to render multiple links based on object URL
 * @param {*} href Array of url object to show as links
 * @returns { array } Link component
 */
const MultiLink = ({ href }) => {
  return href.map(({ text, url }) => (
    <Link text={text} href={url} type="primary" newTab size="S" />
  ));
};

const hasDecimals = (price) => {
  return price % 1 === 0 ? 0 : 2;
};

const AncillariesSwitch = ({
  checked,
  isLoading,
  onChange,
  id,
  title,
  icon,
  message,
  linkText,
  href,
  price,
  singlePrice,
  plusTaxes,
  isDoters,
  disabled,
  headerTitle,
  headerIcon,
  children,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';
  const { t } = useTranslation();

  return (
    <div
      className={`ancillaries-grid fadeinup ${isFlat && 'ancillaries-grid-flat'}  ${
        isDoters && 'ancillaries-grid-container-doters'
      }`}
    >
      {headerTitle && (
        <div className="ancillaries-grid-header">
          {headerIcon && <Icon type={headerIcon} size="S" />}
          <Text weight="semibold" size="M" color="white">
            {headerTitle}
          </Text>
        </div>
      )}
      <div className="ancillaries-grid-wrapper">
        <Spacing justifyContent="space-between">
          <Spacing justifyContent="space-between">
            <Icon type={icon} size="L" />
            <Spacing size="XS" vertical alignItems="flex-start">
              <Text weight="semibold" size="M">
                {title}
              </Text>
              {children ? (
                <>{children}</>
              ) : (
                <TextCollapse
                  triggerTextCollapsed={t('see_details')}
                  triggerTextExpanded={t('close_details')}
                  triggerOptions={{ size: 'S', type: 'discount' }}
                  margin="XS"
                  triggerPosition="top"
                  hasChevron
                >
                  <Spacing vertical size="XS" alignItems="flex-start">
                    <Text size="S">{message}</Text>
                    {typeof href === 'string' ? (
                      <Link text={linkText} href={href} type="primary" newTab size="S" />
                    ) : (
                      <MultiLink href={href} />
                    )}
                  </Spacing>
                </TextCollapse>
              )}
            </Spacing>
          </Spacing>
          <Spacing vertical size="XS" alignItems="flex-end">
            <Gap size="S" alignItems="baseline">
              {Boolean(price) && (
                <Currency
                  color="primary"
                  weight="semibold"
                  price={price}
                  sign={getCurrencyPrefix()}
                  currency={getCurrencySuffix()}
                  size="L"
                  decimals={hasDecimals(price)}
                />
              )}
              {plusTaxes && (
                <Text size="XS" color="grayMedium">
                  + {t('purchase:extra_price')}
                </Text>
              )}
              {singlePrice && (
                <Text size="XS" color="grayMedium">
                  {t('each_abbr')}
                </Text>
              )}
            </Gap>
            <ToggleSwitch
              color={isDoters ? 'loyalty' : 'accent'}
              checked={checked}
              isLoading={isLoading}
              id={id}
              onChange={!isLoading && onChange}
              fillBackground
              size="S"
              disabled={disabled}
            />
          </Spacing>
        </Spacing>
      </div>
    </div>
  );
};

AncillariesSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired || PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  singlePrice: PropTypes.bool,
  plusTaxes: PropTypes.bool,
  disabled: PropTypes.bool,
  isDoters: PropTypes.bool,
  headerTitle: PropTypes.string,
  headerIcon: PropTypes.string,
  children: PropTypes.node,
};

AncillariesSwitch.defaultProps = {
  singlePrice: false,
  plusTaxes: false,
  disabled: false,
};

export default AncillariesSwitch;
