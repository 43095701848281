/**
 * Provides methods to interact with localStorage.
 */
const LocalStorageManager = {
  /**
   * Sets data in localStorage.
   * @param {string} key - The key under which the data is stored.
   * @param {*} value - The value to store.
   */
  set(key, value) {
    try {
      const data = typeof value === 'object' ? JSON.stringify(value) : value;
      localStorage.setItem(key, data);
    } catch (e) {
      return e;
    }
  },
  /**
   * Retrieves data from localStorage.
   * @param {string} key - The key for the data to retrieve.
   * @returns {*} Returns the parsed data if obj is true, otherwise returns the raw data.
   */
  get(key) {
    const data = localStorage.getItem(key);
    try {
      const parsedData = JSON.parse(data);
      return parsedData;
    } catch (e) {
      return data;
    }
  },
  /**
   * Deletes data from localStorage.
   * @param {string} key - The key for the data to delete.
   */
  delete(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      return e;
    }
  },
};

export default LocalStorageManager;
