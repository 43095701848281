import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './ExchangeDatePicker.css';

/**
 * Date field component
 * @param {Object} props - Components props.
 * @param {String} props.value - click function.
 * @returns {JSX} - React component
 */
const ExchangeDatePicker = forwardRef(({ value, onClick }, ref) => {
  const { t } = useTranslation('exchange');
  const placeholder = t('labels.input_date');
  const formattedDate = moment(value, 'DD-MM-YYYY').format('MMM DD, dddd');

  const textDate = value ? formattedDate : placeholder;

  return (
    <button ref={ref} className="exchange__picker" onClick={onClick}>
      {textDate}
    </button>
  );
});

ExchangeDatePicker.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default ExchangeDatePicker;
