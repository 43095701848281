import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Currency,
  Gap,
  Icon,
  SmallButton,
  Spacing,
  Text,
  Visibility,
} from '@reservamos/elements';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import TicketInfo from './TicketInfo';
import './TicketInfoBar.scss';

/**
  TicketInfoBar is a component that displays information about a trip,
  including origin and destination cities, departure and return dates, passenger count,
  total price, and a "Details" button.
  * @param props - Component props
  * @param {string} props.originCity - The name of the origin city.
  * @param {string} props.destinationCity - The name of the destination city.
  * @param {Function} props.onClick - A callback function that is called when the "Details" button is clicked.
  * @param {moment} props.departureDate - A Moment object representing the departure date and time.
  * @param {moment} props.returnDate - A Moment object representing the return date and time.
  * @param {number} props.passengerCount - The number of passengers.
  * @param {boolean} props.isRoundTrip - A boolean indicating whether the ticket is for a round trip.
  * @param {number} props.total - The total price of the ticket.
  * @param {boolean} props.isDepartureOpen - A boolean indicating whether the departure information is open.
  * @param {boolean} props.isReturnOpen - A boolean indicating whether the return information is open.
  * @returns {JSX.Element} JSX.Element
*/
const TicketInfoBar = ({
  originCity,
  destinationCity,
  onClick,
  departureDate,
  returnDate,
  passengerCount,
  isRoundTrip,
  total,
  isDepartureOpen,
  isReturnOpen,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTripComponent, setShowTripComponent] = useState(false);
  const { t } = useTranslation();
  const { BOOKING_REVIEW_ENABLED } = useWhitelabelFeatures();

  const departureDateFormat = departureDate.format('LT - DD MMM');

  const returnDateFormat = returnDate?.format('LT - DD MMM');

  const text = isRoundTrip ? t('general:round_trip') : t('general:one_way_trip');

  const departureText = isDepartureOpen ? t('general:open_ticket') : departureDateFormat;
  const returnText = isReturnOpen ? t('general:open_ticket') : returnDateFormat;

  const tripTypes = [
    <TicketInfo
      title={t('purchase:label.your_one_way_trip')}
      text={departureText}
      exitAnimation={showTripComponent}
    />,
    <TicketInfo
      title={t('purchase:label.your_return_trip')}
      text={returnText}
      exitAnimation={showTripComponent}
    />,
    <TicketInfo passengers={passengerCount} text={text} exitAnimation={showTripComponent} />,
  ];

  if (!isRoundTrip) tripTypes.splice(1, 1);
  if (!passengerCount) tripTypes.pop();

  useEffect(() => {
    const totalIndex = currentIndex < tripTypes.length - 1;

    const intoAnimationTimeout = setTimeout(() => {
      setShowTripComponent(true);

      if (totalIndex) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }

      setShowTripComponent(false);
    }, 5800);

    const outAnimationTimeout = setTimeout(() => {
      setShowTripComponent(true);
    }, 5000);

    return () => {
      clearTimeout(intoAnimationTimeout);
      clearTimeout(outAnimationTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, passengerCount]);

  return (
    <Box
      hasShadow
      paddingHorizontalMobile=""
      paddingVerticalMobile="S"
      paddingHorizontal="M"
      paddingVertical="S"
    >
      <div className="container-ticket-bar">
        <Spacing responsiveSize="XS" alignItems="center" justifyContent="space-between" fullWidth>
          <Visibility type="hideForMobileOnly">
            <Gap alignItems="center">
              <Icon type="PinOutlined" size="S" />
              <Text size="S">
                {destinationCity} - {originCity}
              </Text>
            </Gap>
          </Visibility>

          {tripTypes[currentIndex]}

          <Gap alignItems="center" columnGap="S" responsiveColumnGap="XS" responsiveRowGap="XS">
            {total >= 0 && window.location.pathname.includes('checkout') && (
              <>
                <Visibility type="hideForMobileOnly">
                  <Text size="S" color="grayMedium">
                    {t('purchase:total')}
                  </Text>
                </Visibility>
                <Currency
                  currencyLowercase
                  mobileSize="S"
                  price={total}
                  weight="bold"
                  sign={getCurrencyPrefix()}
                  currency={getCurrencySuffix()}
                />
              </>
            )}
            {!BOOKING_REVIEW_ENABLED && (
              <SmallButton
                hasBorder
                onClick={onClick}
                text={t('general:details')}
                variant="accentWhite"
              />
            )}
          </Gap>
        </Spacing>
      </div>
    </Box>
  );
};

TicketInfoBar.propTypes = {
  originCity: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  departureDate: PropTypes.string,
  returnDate: PropTypes.string,
  passengerCount: PropTypes.number,
  isRoundTrip: PropTypes.bool,
  total: PropTypes.number,
  isDepartureOpen: PropTypes.bool,
  isReturnOpen: PropTypes.bool,
};

TicketInfoBar.defaultProps = {
  onClick: null,
  departureDate: null,
  returnDate: null,
};

export default TicketInfoBar;
