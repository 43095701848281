// @ts-check
import cieloEngine from '../../../engines/cielo';

/**
 * @typedef {import('./CardStrategy').CardStrategy} CardStrategy
 * @typedef {import('./CardStrategy').CardInfo} CardInfo
 */

/**
 * Cielo card strategy.
 * @function CieloCardStrategy
 * @returns {CardStrategy}
 */
const CieloStrategy = () => {
  /**
   * Create payload for Cielo.
   * @param {CardInfo} cardInfo - The card information.
   */
  const createPayload = async (cardInfo) => {
    const { cardNumber, holderName, expirationMonth, expirationYear, cardBrand, cvv2 } = cardInfo;
    const token = await cieloEngine.tokenizeCard({
      holderName,
      cardNumber,
      expirationMonth,
      expirationYear,
      cardBrand,
      cvv: cvv2,
    });
    const firstDigits = cardNumber.slice(0, 6);
    const lastDigits = cardNumber.slice(-4);
    return {
      card_number: `${firstDigits}${'xxxxxx'}${lastDigits}`,
      card_holder_name: holderName,
      card_brand: cardBrand,
      credit_card_tokens: {
        cielo_token: token,
      },
    };
  };

  return { createPayload };
};

const cieloStrategy = CieloStrategy();

export default cieloStrategy;
