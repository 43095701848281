import React from 'react';
import PropTypes from 'prop-types';
import adjacentSeat from 'images/seats/adjacentseat.gif';
import './AdjacentIcon.scss';

// eslint-disable-next-line jsdoc/require-jsdoc
const AdjacentIcon = ({ isVertical }) => (
  <div className={`adjacent-container ${isVertical ? 'adjacent-vertical' : ''}`}>
    <img className="adjacent-icon" src={adjacentSeat} alt="GIF-adjacentseat" />
  </div>
);

AdjacentIcon.propTypes = {
  isVertical: PropTypes.bool,
};

AdjacentIcon.defaultProps = {
  isVertical: false,
};

export default AdjacentIcon;
