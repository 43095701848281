import React from 'react';
import { Button, Input, Spacing, Box, Gap, Text } from '@reservamos/elements';
import { Form, Formik } from 'formik';
import SectionTitle from 'ui/atoms/SectionTitle';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import usePqrs from './usePqrs';

/**
 * `PqrsTracking` is a React functional component that provides a form for users to enter a tracking code
 * and submit it to retrieve tracking information for a PQRS (Petitions, Complaints, Requests, and Suggestions) system.
 * It utilizes Formik for form handling and Yup for validation schema.
 * The component also uses a custom hook `usePqrs` to interact with the tracking API
 * and manage loading states and responses. It displays the tracking information
 * if available, including details such as the opening date, name, email, and status of the PQRS entry.
 * @returns {JSX.Element} The `PqrsTracking` component that renders the tracking form and tracking information.
 */
const PqrsTracking = () => {
  const { t } = useTranslation('pqrs');
  const { isLoading, response, getTracking } = usePqrs();

  const trackingValidationSchema = Yup.object().shape({
    trackingCode: Yup.string().required(t('errors.tracking_code_required')),
  });

  return (
    <Gap flexDirection="column" rowGap="L">
      <Formik
        initialValues={{ trackingCode: '' }}
        validationSchema={trackingValidationSchema}
        onSubmit={(values) => {
          getTracking(values.trackingCode);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Gap flexDirection="column">
              <Spacing vertical>
                <Input
                  id="trackingCode"
                  label={t('tracking_code')}
                  value={values.trackingCode}
                  hasError={errors.trackingCode !== undefined && touched.trackingCode}
                  errorMessage={errors.trackingCode}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Button
                  buttonType="submit"
                  text={t('track')}
                  variant="accent"
                  isRounded
                  withHeight
                  fullWidthOnSmall
                  isLoading={isLoading}
                />
              </Spacing>
            </Gap>
            {response && (
              <Box
                paddingVertical="M"
                paddingHorizontal="L"
                paddingVerticalMobile="M"
                paddingHorizontalMobile="M"
                blurBg=""
                hasShadow
                maxWidth
              >
                <Gap flexDirection="column" rowGap="S">
                  <SectionTitle
                    title={`${t('tracking.title')} ${values.trackingCode} `}
                    description={`${t('tracking.opening_date')}: ${response.date}`}
                  />
                  <Text>{response.name}</Text>
                  <Text>{response.mail}</Text>
                  <Text>{response.detail}</Text>
                  <Text>{`${t('tracking.status')}: ${response.status}`}</Text>
                </Gap>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Gap>
  );
};

export default PqrsTracking;
