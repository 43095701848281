import React from 'react';
import PropTypes from 'prop-types';
import { Gap } from '@reservamos/elements';
import FilterResultBadge from '../atoms/FilterResultBadge';

/**
 * Component for rendering a filter result badge that indicates the filter applied in the result.
 * @component
 * @param {Object} props - The component props.
 * @param {Number} props.total - The quantity to be displayed in the badge.
 * @param {boolean} props.minimalDesign - Whether to render the badge in a minimal design.
 * @returns {JSX.Element} The rendered component.
 */
const FilterPlusBadge = ({ total, minimalDesign }) => {
  if (total <= 0) return null;
  return (
    <Gap>
      <FilterResultBadge elementType="span" minimalDesign={minimalDesign}>
        + {total}
      </FilterResultBadge>
    </Gap>
  );
};

FilterPlusBadge.propTypes = {
  total: PropTypes.number.isRequired,
  minimalDesign: PropTypes.bool,
};

export default FilterPlusBadge;
