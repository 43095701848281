import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/components/AgencyForm';

const ReservamosPayForm = () => {
  const { t } = useTranslation('exchange');

  return (
    <div className="agencypay">
      <div className="agencypay-cont">
        <i className="agencypay-cont-icon" />
        <h3>{t('instructions.title')}</h3>
      </div>

      <p className="agencypay-cont-text">{t('instructions.subtitle')}:</p>

      <ul className="agencypay-cont-list">
        <li>{t('instructions.step_1')}</li>
        <li>{t('instructions.step_2')}</li>
      </ul>
    </div>
  );
};

export default ReservamosPayForm;
