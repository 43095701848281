import { connect } from 'react-redux';
import CashPaymentInfo from './CashPaymentInfo';

const mapStateToProps = ({ purchase, payment }) => ({
  amount: purchase.get('total'),
  paydayLimit: payment.get('expirationDate'),
  pdfUrl: payment.get('storeFormatUrl'),
});

export default connect(mapStateToProps)(CashPaymentInfo);
