import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/components/modals/Amenities';
import Amenity from '../../ui/atoms/Amenity';

const Amenities = ({ hideModal, amenities }) => {
  const { t } = useTranslation('general');
  return (
    <div className="modal-content amenities" onClick={(e) => e.stopPropagation()}>
      <div className="modal-title">
        <h4>{t('amenities')}</h4>

        <div className="modal-close" onClick={hideModal} />
      </div>

      <ul className="amenities-list">
        {amenities.map((amenity) => (
          <li key={amenity} className="amenities-list-item">
            <Amenity type={amenity} />
          </li>
        ))}
      </ul>
    </div>
  );
};
Amenities.propTypes = {
  hideModal: PropTypes.func.isRequired,
  amenities: PropTypes.array.isRequired,
};

export default Amenities;
