// @ts-check
import kushki from '../../../engines/kushki';

/**
 * @typedef {import('./CardStrategy').CardStrategy} CardStrategy
 */

/**
 * Kushki card strategy.
 * @function KushkiCardStrategy
 * @returns {CardStrategy}
 */
function KushkiStrategy() {
  /**
   * Create payload for Kushki card strategy.
   */
  const createPayload = async (cardParams, { amount, currency }) => {
    const token = await kushki.createCardToken({
      amount,
      currency,
      card: {
        name: cardParams.holderName,
        number: cardParams.cardNumber,
        cvc: cardParams.cvv2,
        expiryMonth: cardParams.expirationMonth,
        expiryYear: cardParams.expirationYear.slice(2, 4),
      },
    });

    return {
      credit_card_tokens: {
        kushki_token: token,
      },
    };
  };

  return { createPayload };
}

const kushkiStrategy = KushkiStrategy();

export default kushkiStrategy;
