import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { LinkButton, Spacing, Text } from '@reservamos/elements';
import { formatCurrency } from 'utils/Helpers';
import PaymentImage from '../../../ui/atoms/PaymentImage';
import 'styles/components/purchase/TicketsPanel';
import KushkiCashLogos from '../../../ui/templates/KushkiCashLogos';

const CashPaymentInfo = ({ amount, pdfUrl, paydayLimit }) => {
  const { t } = useTranslation('payment');
  const paydayLimitDisplay = moment(paydayLimit).format('LLLL');
  const amountDisplay = formatCurrency(amount, 2);

  return (
    <div>
      <div className="tickets-panel">
        <div className="tickets-panel-content">
          <Spacing vertical size="S">
            <PaymentImage type="kushki-logo" />
            <Text size="S">{t('cash_payment_title')}</Text>
            <Text size="S">
              <Trans
                t={t}
                i18nKey="cash_payment_instructions"
                as="p"
                className="tickets-panel-text"
              >
                <b />
              </Trans>
            </Text>
            <Text size="S">{t('cash_print_tickets')}</Text>
          </Spacing>
        </div>
        <div className="tickets-panel-content right-content">
          <div className="right-content-info">
            <Spacing vertical size="L">
              <Spacing vertical size="S">
                <Text size="S" textAlign="center" color="grayMedium">
                  {t('purchase:label.payment_reference')}:
                </Text>
                <Text textAlign="center">
                  <LinkButton
                    borderRadius
                    href={pdfUrl}
                    iconColor="white"
                    iconType="Download"
                    newTab
                    padding="M"
                    variant="accent"
                    text={t('button.print_payment_reference')}
                  />
                </Text>
              </Spacing>
              <Spacing vertical size="S">
                <Text size="S" textAlign="center" color="grayMedium">
                  {t('label.payday_limit')}:
                </Text>
                <Text size="XL" weight="bold" textAlign="center">
                  {paydayLimitDisplay}
                </Text>
              </Spacing>
            </Spacing>
          </div>
          <div className="right-content-price">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.waiting_for_your_payment')}:
              </Text>
              <div className="right-content-price-amount">
                <Text size="XL" weight="bold" textAlign="center">
                  {amountDisplay}
                </Text>
              </div>
            </Spacing>
          </div>
        </div>
      </div>
      <KushkiCashLogos />
    </div>
  );
};

CashPaymentInfo.propTypes = {
  amount: PropTypes.number.isRequired,
  paydayLimit: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
};

export default CashPaymentInfo;
