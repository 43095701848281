import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OptionsSelector from './OptionsSelector';
import { stopsFilter } from '../utils/tripFilters';

const ScheduleFilterSelector = ({ onChange, value, way }) => {
  const { t } = useTranslation('search');
  const { hasNonStops, hasMultipleStops } = useSelector(({ search, trips }) => {
    const searchId = search.getIn([way, 'id']);
    const evaluator = stopsFilter;
    const wayTrips = trips.getIn([searchId, 'buses', 'trips']);

    return {
      hasNonStops: wayTrips.some((t) => evaluator(t, 'direct')),
      hasMultipleStops: wayTrips.some((t) => evaluator(t, 'multiple')),
    };
  });

  const options = [
    hasNonStops && {
      title: t('label.selector_direct_trip'),
      description: '',
      icon: 'direct',
      value: 'direct',
    },
    hasMultipleStops && {
      title: t('label.selector_stopover_trip'),
      description: '',
      icon: 'stops',
      value: 'multiple',
    },
    {
      title: t('label.selector_all_trips'),
      description: '',
      value: 'none',
    },
  ].filter(Boolean);

  return <OptionsSelector onChange={onChange} options={options} value={value} />;
};

ScheduleFilterSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  way: PropTypes.string.isRequired,
};

ScheduleFilterSelector.defaultProps = {
  value: null,
};

export default ScheduleFilterSelector;
