import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { LoaderDots, Button } from '@reservamos/elements';
import { fieldRenderer } from 'utils/formRenderers';
import getPurchaseStepName from '../../../utils/purchase/getPurchaseStepName';
import 'styles/components/purchase/DiscountCode';
import 'styles/components/LoadingDots';
import './DiscountCode.scss';

const propTypes = {
  token: PropTypes.string.isRequired,
  selectedInstallmentsPlan: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  applyingDiscount: PropTypes.bool.isRequired,
  onApplyDiscount: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
};

class DiscountCode extends Component {
  constructor(props) {
    super(props);

    this.applyDiscount = this.applyDiscount.bind(this);
  }

  applyDiscount({ discountCode }) {
    const { token, selectedInstallmentsPlan, onApplyDiscount } = this.props;

    onApplyDiscount(token, discountCode, selectedInstallmentsPlan);
  }

  render() {
    const { handleSubmit, applyingDiscount, t, features } = this.props;

    const purchaseStep = getPurchaseStepName(window.location.pathname);
    if (purchaseStep !== 'checkout') return null;

    if (!features.DISCOUNT_CODE_ENABLED) return null;

    return (
      <div className="checkout-section discount-code">
        <p className="checkout-text">{t('text.discount_code')}</p>

        <form className="form-item-wrap inline-items" onSubmit={handleSubmit(this.applyDiscount)}>
          <div className="form-item-wrap-l">
            <Field
              type="text"
              name="discountCode"
              id="discountCodeSidebar"
              placeholder={t('payment:placeholder.discount_coupon')}
              placeholderOnly
              cls="form-item textfield"
              component={fieldRenderer}
            />
          </div>

          <div className="form-item-wrap-button">
            {applyingDiscount ? (
              <LoaderDots isAccent />
            ) : (
              <Button withHeight text={t('button.apply')} buttonType="submit" />
            )}
          </div>
        </form>
      </div>
    );
  }
}

DiscountCode.propTypes = propTypes;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(withTranslation('general')(DiscountCode));
