import { connect } from 'react-redux';
import { EXCHANGE_RESET } from 'constants/ActionTypes';
import ExchangeSidebar from './ExchangeSidebar';

const mapStateToProps = ({ exchange }) => {
  const { passengers } = exchange;
  const { dueDate, openTicket } = exchange.ticket;
  const { originDisplay, destinationDisplay, time } = exchange.trip;

  return {
    originDisplay,
    destinationDisplay,
    time,
    dueDate,
    openTicket,
    passengers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetExchange: () => dispatch({ type: EXCHANGE_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeSidebar);
