export default (bmid) => {
  const script = document.createElement('script');
  script.src = 'https://widget.botlers.io/sdk/main.js';
  script.defer = true;
  script.async = false;
  script.id = 'botlers-messaging-sdk-v2';
  script.setAttribute('bmid', bmid);
  script.setAttribute('button', 'true');
  script.setAttribute('skip-start', 'true');
  document.getElementsByTagName('head')[0].appendChild(script);
};
