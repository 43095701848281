// @ts-check
import 'babel-polyfill';

/**
 * @typedef {import('./strategies/PixStrategy').PixStrategy} PixStrategy
 */

/**
 * @function Pix
 */
const Pix = () => {
  /**
   * @type {PixStrategy | undefined}
   */
  let strategy;

  const setStrategy = (newStrategy) => {
    strategy = newStrategy;
  };

  const createToken = (data) => {
    if (!strategy) {
      throw new Error('Pix strategy not set');
    }
    return strategy.createToken(data);
  };

  const needsRedirect = () => {
    if (!strategy) {
      throw new Error('Pix strategy not set');
    }
    return strategy.needsRedirect;
  };

  return {
    setStrategy,
    createToken,
    needsRedirect,
  };
};

const pix = Pix();

export default pix;
