import React from 'react';
import PropTypes from 'prop-types';
import TripList from '../TripList/TripList';
import NoResults from '../../../ui/molecules/NoResults';

const OpenTicketList = ({ selectTrip, way, roundTrip, openTicketTrips, providerId }) => {
  return (
    <>
      {openTicketTrips && openTicketTrips.length > 0 ? (
        <TripList
          trips={openTicketTrips}
          way={way}
          selectTrip={selectTrip}
          roundTrip={roundTrip}
          isOpenTicketList
        />
      ) : (
        <NoResults way={way} providerId={providerId} />
      )}
    </>
  );
};

OpenTicketList.propTypes = {
  selectTrip: PropTypes.func.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  openTicketTrips: PropTypes.array.isRequired,
  way: PropTypes.string,
  providerId: PropTypes.bool,
};

OpenTicketList.defaultProps = {
  way: 'departure',
};

export default OpenTicketList;
