import parseQueryString from './parseQueryString';

const mergeQueryObjects = (queryObj, currentQuery) => {
  let newParams = {};
  let currentParams = {};

  if (typeof queryObj === 'object' && queryObj !== null) {
    newParams = queryObj;
  }

  if (typeof currentQuery === 'object' && currentQuery !== null) {
    currentParams = currentQuery;
  }

  return { ...currentParams, ...newParams };
};

export default (queryObj, queryString = '?') => {
  const keyValues = Object.entries(mergeQueryObjects(queryObj, parseQueryString(queryString)));

  if (keyValues.length < 1) return '';

  return keyValues.reduce((partialString, [key, value]) => {
    if (partialString.length === 1) return `${partialString}${key}=${value}`;

    return `${partialString}&${key}=${value}`;
  }, '?');
};
