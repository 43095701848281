import { camelizeKeys, decamelizeKeys } from 'humps';
import store from '@/store';
import moment from 'moment';
import { search, setConfig as setApiConfig } from 'js-api-client';
import * as types from 'constants/ActionTypes';
import camelizeConverter from 'utils/camelizeConverter';
import { departureDateCheck, returnDateCheck } from 'utils/searchDatesCheck';
import parseTrip from 'models/parseTrip';
import { isRecommendedTripsEnabled } from 'utils/userPreferences';
import { setError } from '.';
import setConfig from './config';
import { setProviders, setTransportState, setDynamicPriceAttributes } from './providers';
import { setCities, setTerminals, setAirports, setLines, setCarriers } from './catalogs';
import { setRecommendedTripsLoading, setUpRecommendedTrips } from './userPreferences';

export function setParams(origin, destination, passengers, departs, returns) {
  return {
    type: types.SET_SEARCH_PARAMS,
    origin,
    destination,
    passengers,
    departureDate: departureDateCheck(departs),
    returnDate: returnDateCheck(returns),
  };
}

export function requestSearch(way) {
  return { type: types.REQUEST_SEARCH, way };
}

/**
 * Receives the search results
 * @param {Way} way
 * @param {Object} search
 * @param {Object} lines - The different provider lines
 * @param {boolean} isOpen - If the search is open or not
 * @returns - Redux action
 */
export function receiveSearch(way, search, lines, isOpen) {
  return {
    type: types.RECEIVE_SEARCH,
    way,
    receivedAt: Date.now(),
    search: camelizeKeys(search),
    lines,
    isOpen,
  };
}

export function setupTrips(search) {
  const {
    id,
    type_of_transport: { bus, flights, mix },
  } = search;

  return {
    type: types.SETUP_TRIPS,
    searchId: id,
    activeBuses: bus.active,
    activeFlights: flights.active,
    activeMixed: mix.active,
  };
}

export function loadCoupon(couponCode) {
  return {
    type: types.FETCHING_COUPON,
    couponCode,
  };
}

export const deleteCoupon = () => {
  return {
    type: types.DELETE_COUPON_CODE,
  };
};

export function saveCoupon(couponCode) {
  return {
    type: types.SAVE_COUPON_CODE,
    couponCode,
  };
}

export function receiveTrips(type, response) {
  return {
    type,
    isPolling: response.state !== 'finished',
    apiResponse: camelizeKeys(response),
  };
}

export function receiveMinPrices(way, prices) {
  return {
    type: types.RECEIVE_MIN_PRICES,
    way,
    prices,
  };
}

export function receiveNearTerminals(way, terminals) {
  return {
    type: types.RECEIVE_NEAR_TERMINALS,
    way,
    terminals,
  };
}

export function invalidateSearch(way) {
  return { type: types.INVALIDATE_SEARCH, way };
}

export function resetSearch() {
  return { type: types.RESET_SEARCH };
}

let prevPollingStatus;
/**
 * Set up the recommended trips with polling status.
 * @param {Object} options - The options for setting up recommendations.
 * @param {string} options.searchId - The search ID.
 * @param {string} options.way - The way of the search.
 * @param {Array} options.searchTrips - The search trips.
 * @param {string} options.pollingStatus - The polling status.
 * @param {function} options.dispatch - The dispatch function.
 */
const setUpRecommendationsWithPolling = ({
  searchId,
  way,
  searchTrips,
  pollingStatus,
  dispatch,
}) => {
  /**
   * The recommended trips are set up in the following cases to keep the most actual info in the faster way possible:
   * If the polling status is pending the first time and there are trips, the recommended trips are set up
   * If the polling status is finished and the previous polling status was pending, the recommended trips are set up
   * If the polling status is pending and the previous polling status was not set, the recommended trips are set up
   */

  if (!isRecommendedTripsEnabled()) return;

  const isFinishedPollingValid =
    pollingStatus === 'finished' && (prevPollingStatus === 'pending' || !prevPollingStatus);

  const isPendingPollingValid = pollingStatus === 'pending' && !prevPollingStatus;

  const isPollingValidForRecommendedTrips = isFinishedPollingValid || isPendingPollingValid;

  if (searchTrips.length && isPollingValidForRecommendedTrips)
    dispatch(
      setUpRecommendedTrips({
        searchId,
        way,
        searchTrips,
      }),
    );

  if (pollingStatus === 'finished') {
    prevPollingStatus = null;
  } else {
    prevPollingStatus = pollingStatus;
  }
};

/**
 * Process the buses response
 * @param {Object} poll - The poll object
 * @param {Boolean} roundTrip - Indicates if it is a round trip
 * @param {Function} dispatch - The dispatch function
 * @param {String} way - The id way of the search
 * @param {Boolean} includeRecommendedTrips - Indicates if the recommended trips are included
 */
const processBuses = (poll, roundTrip, dispatch, way, includeRecommendedTrips) => {
  const { status, payload } = poll;

  if (payload && status !== 'aborted') {
    const search = camelizeKeys(payload, camelizeConverter);
    const { terminals, lines, minPrice, nearTerminals } = search;
    const { cities } = store.getState();
    const tripCount = search.trips.length;

    search.trips = search.trips.filter((trip) => !roundTrip || trip.isBuyable);
    search.trips.forEach((trip) => parseTrip({ trip, cities, terminals, lines }));

    dispatch(setTerminals(terminals));
    dispatch(setLines(lines));
    dispatch(setProviders(search.id, search.trips, roundTrip));

    if (status === 'retries_exceeded') {
      // overwrite search state since polling remained pending after max attempts
      search.state = 'finished';
      dispatch(receiveTrips(types.RECEIVE_BUSES, search));
      throw new Error('Polling retries exceeded');
    } else {
      const { features } = store.getState().whitelabelConfig;
      if (features.SHOW_MIN_PRICES) dispatch(receiveMinPrices(way, minPrice));
      if (features.USE_NERBY_TERMINALS) dispatch(receiveNearTerminals(way, nearTerminals));
      dispatch(receiveTrips(types.RECEIVE_BUSES, search));
    }

    if (includeRecommendedTrips)
      setUpRecommendationsWithPolling({
        searchId: search.id,
        way,
        searchTrips: search.trips,
        pollingStatus: status,
        dispatch,
      });

    dispatch(setTransportState('bus', search.id, search.state, tripCount));
  }
};

const processFlights = (poll, roundTrip, dispatch) => {
  const { status, payload } = poll;

  if (payload && status !== 'aborted') {
    const search = camelizeKeys(payload, camelizeConverter);
    const { id, airports, carriers, fareServices } = search;
    const { cities } = store.getState();
    const tripCount = search.flights.length;

    search.flights = search.flights.filter((flight) => !roundTrip || flight.isBuyable);

    search.flights.forEach((flight) => {
      parseTrip({ trip: flight, cities, airports, carriers, fareServices });
    });

    dispatch(setAirports(airports));
    dispatch(setCarriers(carriers));
    Reflect.deleteProperty(search, 'airports');
    Reflect.deleteProperty(search, 'carriers');
    dispatch(setProviders(id, search.flights));

    if (status === 'retries_exceeded') {
      // overwrite search state since polling remained pending after max attempts
      search.state = 'finished';
      dispatch(receiveTrips(types.RECEIVE_FLIGHTS, search));
      throw new Error('Polling retries exceeded');
    } else {
      dispatch(receiveTrips(types.RECEIVE_FLIGHTS, search));
    }

    dispatch(setTransportState('flight', search.id, search.state, tripCount));
  }
};

const processMixed = (poll, dispatch) => {
  const { status, payload } = poll;

  if (payload && status !== 'aborted') {
    const search = camelizeKeys(payload, camelizeConverter);
    const { id, fareServices, carriers, airports, terminals, lines, innerCityConnections } = search;
    const { cities } = store.getState();
    const tripCount = search.itineraries.length;

    search.itineraries.forEach((itinerary) => {
      parseTrip({
        trip: itinerary,
        cities,
        terminals,
        airports,
        lines,
        carriers,
        innerCityConnections,
        fareServices,
      });
    });

    dispatch(setAirports(airports));
    dispatch(setCarriers(carriers));
    dispatch(setLines(lines));
    dispatch(setTerminals(terminals));
    Reflect.deleteProperty(search, 'airports');
    Reflect.deleteProperty(search, 'carriers');
    Reflect.deleteProperty(search, 'terminals');
    Reflect.deleteProperty(search, 'fare_services');
    Reflect.deleteProperty(search, 'lines');
    dispatch(setProviders(id, search.itineraries));

    if (status === 'retries_exceeded') {
      // overwrite search state since polling remained pending after max attempts
      search.state = 'finished';
      dispatch(receiveTrips(types.RECEIVE_MIXED, search));
      throw new Error('Polling retries exceeded');
    } else {
      dispatch(receiveTrips(types.RECEIVE_MIXED, search));
    }

    dispatch(setTransportState('mixed', search.id, search.state, tripCount));
  }
};

/**
 * Dispatches the creation of a new request of search
 * @param {Way} way - Departure or return
 * @param {string} origin - Departure id
 * @param {string} destination - Destination id
 * @param {Date} date - Date of departure or return
 * @param {string} passengers - Passengers A2-C1-I1 => { adult: 2, child: 1, infant: 1 }
 * @param {boolean} roundTrip - Flag that indicates if it is a round trip
 * @param {boolean} isOpen - Flag that indicates if it is an open ticket
 * @returns - Redux action
 */
export function createSearch(
  way,
  origin,
  destination,
  date,
  passengers,
  roundTrip,
  isOpen,
  includeRecommendedTrips,
) {
  return (dispatch, getState) => {
    const correctDate = way === 'departure' ? departureDateCheck(date) : returnDateCheck(date);
    const payload = {
      origin,
      destination,
      date: correctDate,
      passengers: [],
      way,
      round: roundTrip,
    };
    const createSearchParams = {
      onReceiveBuses: (response) =>
        processBuses(response, roundTrip, dispatch, way, includeRecommendedTrips),
      onReceiveFlights: (response) => processFlights(response, roundTrip, dispatch),
      onReceiveMixed: (response) => processMixed(response, dispatch),
      initRides: false,
    };
    payload.passengers.push('adult');

    // Resets recommended trips state to loading
    if (includeRecommendedTrips) dispatch(setRecommendedTripsLoading(true));

    // the app state is updated to inform that the API call is starting
    dispatch(requestSearch(way));

    // Cancel any previous search with the same "way"
    const previousSearchId = getState().search.getIn([way, 'id']);
    if (previousSearchId) {
      search.stopSearch(previousSearchId);
    }

    // return a promise to wait for.
    return (
      search
        .create(payload, createSearchParams)
        // update the app state with the results of the API call
        .then((response) => {
          const { config, cities, terminals, lines, search } = response;
          const { bus, flights, mix } = search.type_of_transport;

          dispatch(setDynamicPriceAttributes(search));
          dispatch(setConfig(config));
          dispatch(setCities(cities));
          dispatch(setTerminals(terminals));
          dispatch(setLines(lines));
          dispatch(receiveSearch(way, search, lines, isOpen));
          dispatch(setupTrips(search));
          dispatch(setTransportState('bus', search.id, bus.active ? 'pending' : 'disabled'));
          dispatch(setTransportState('flight', search.id, flights.active ? 'pending' : 'disabled'));
          dispatch(setTransportState('mixed', search.id, mix.active ? 'pending' : 'disabled'));
          setTimeout(() => dispatch(invalidateSearch(way)), 10 * 60000);
        })
        .catch((reason) => {
          dispatch(setRecommendedTripsLoading(false));
          dispatch(setError(100, 'trips_search_error'));

        })
    );
  };
}

function getParamsFromUrl(url) {
  const u = new URL(url);
  const data = u.pathname.split('/');
  const isRound = data.length === 9;

  if (isRound) {
    const [, , origin, destination, departsDate, returnsDate, , passengers, wayType] = data;
    const isDepart = wayType === 'departures';
    const way = isDepart ? 'departure' : 'return';
    const date = isDepart ? departsDate : returnsDate;

    return {
      way,
      origin,
      destination,
      date: moment(date, 'DD-MMM-YY').format('DD-MM-YYYY'),
      passengers,
      roundTrip: true,
    };
  }

  const [, , origin, destination, date, , passengers] = data;

  return {
    way: 'departure',
    origin,
    destination,
    date: moment(date, 'DD-MMM-YY').format('DD-MM-YYYY'),
    passengers,
    roundTrip: false,
  };
}

export function createSearchFromParams() {
  return (dispatch) => {
    const { way, origin, destination, date, passengers, roundTrip } = getParamsFromUrl(
      window.location,
    );

    dispatch(createSearch(way, origin, destination, date, passengers, roundTrip));
  };
}

function findBy(list, key, value) {
  return list.find((item) => item[key] === value);
}

function parseTripDiscount(trip) {
  const { id, categories_attributes: categories } = trip;

  const category = categories.find(({ category }) => category === 'general');
  const { discount_prices_attributes: discounts } = category;

  const pricing = findBy(discounts, 'name', 'single_trip_discount');
  const departureRoundTripPricing = findBy(discounts, 'name', 'departure_on_round_trip_discount');
  const roundTripPricing = findBy(discounts, 'name', 'single_on_round_trip_discount');

  return {
    id,
    pricing,
    departureRoundTripPricing,
    roundTripPricing,
  };
}

export function setDiscountedTrips(couponCode, response) {
  const { trips } = response;

  const allTrips = Object.keys(trips)
    .map((searchId) => trips[searchId].map(parseTripDiscount))
    .flat();

  return {
    type: types.SET_DISCOUNTED_TRIPS,
    couponCode,
    trips: allTrips,
  };
}

export const applyCoupon = (couponCode, showSuccess, dispatch) => {
  const { search: searchState } = store.getState();
  const departSearchId = searchState.getIn(['departure', 'id']);
  const returnSearchId = searchState.getIn(['return', 'id']);
  const searchIds = returnSearchId ? [departSearchId, returnSearchId] : [departSearchId];

  const params = decamelizeKeys({ searchIds });

  dispatch(loadCoupon(couponCode));

  search
    .validateExternalCoupon(couponCode, params)
    .then((response) => {
      if (response.valid) {
        dispatch(saveCoupon(couponCode));
        dispatch(setDiscountedTrips(couponCode, response));
        if (showSuccess) {
          dispatch(setError(null, 'promo_code', 'success', false));
        }
      } else {
        dispatch(setError(null, 'promo_code', 'warning', false));
        dispatch(deleteCoupon());
      }
    })
    .catch((reason) => {
      dispatch(setError(null, 'promo_code', 'warning', false));
      dispatch(deleteCoupon());

    });
};

export const resetCouponForm = () => {
  return {
    type: types.RESET_COUPON_FORM_STATE,
  };
};

export const setRedirectParams = (way, params, brand, redirect, roundTrip, waitForPurchase) => {
  return {
    type: types.SET_REDIRECT_PARAMS,
    way,
    params,
    redirect,
    brand,
    roundTrip,
    waitForPurchase,
  };
};

// Wallet actions
/**
 * Removes a wallet discount from the search
 * @param walletType - Wallet name
 */
export const deleteWalletDiscount = (walletType) => {
  return {
    type: types.DELETE_WALLET_DISCOUNT,
    walletType,
  };
};

/** Sets if a wallet discount is being fetched */
const fetchingWalletDiscount = (isFetching) => {
  return {
    type: types.FETCHING_WALLET_DISCOUNT,
    isFetching,
  };
};

/** Configurate the api token to use the one passed as walletType
 * If the session doesn't exists return false;
 */
const configWalletTypeDiscount = (walletType) => {
  const { costapass } = store.getState();
  const { profile } = costapass.toJS();
  if (walletType === 'ewallet') {
    if (profile) {
      setApiConfig({ userAuthToken: profile.token });
    } else {
      return false;
    }
  }
  return true;
};

/**
 * Gets the wallet discounts for a search
 */
export const walletSearchDiscount = (walletType) => {
  return (dispatch) => {
    const { search: searchState } = store.getState();

    // If the function return false, the walletSearchDiscount is not executed
    if (!configWalletTypeDiscount(walletType)) return;

    if (!walletType || searchState.get('isWalletDiscountFetching')) return;
    const departSearchId = searchState.getIn(['departure', 'id']);
    const returnSearchId = searchState.getIn(['return', 'id']);
    const searchIds = returnSearchId ? [departSearchId, returnSearchId] : [departSearchId];
    const params = decamelizeKeys({ searchIds });

    dispatch(fetchingWalletDiscount(true));
    search
      .walletSearchDiscount(walletType, params)
      .then((response) => {
        dispatch(setDiscountedTrips(null, response, walletType));
      })
      .catch((reason) => {

        dispatch(deleteWalletDiscount(walletType));
      })
      .finally(() => {
        dispatch(fetchingWalletDiscount(false));
      });
  };
};

/** Set the price type to be showed in the search results */
export const setResultPriceToShow = ({ priceType, walletType }) => {
  return {
    type: types.SET_RESULT_PRICE_TO_SHOW,
    priceType,
    walletType,
  };
};
