import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'user-analytics';
import { LoaderDots, Text } from '@reservamos/elements';
import './MainButton.scss';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';
import ArrowRight from './ArrowRight';

/**
 * MainButton component renders a customizable button with an optional loading state and right arrow icon.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onClick - Callback function triggered when the button is clicked.
 * @param {string} props.label - Text label displayed on the button.
 * @param {boolean} [props.isDisabled=false] - Flag to disable the button.
 * @param {boolean} [props.isLoading=false] - Flag to show a loading indicator instead of the label and arrow.
 * @param {boolean} [props.isFlat=false] - Flag to apply a flat rounded style to the button.
 * @returns {React.Component} A button component with dynamic styles and behaviors.
 */
const MainButton = ({ onClick, label, isDisabled, isLoading, isFlat }) => {
  const { colors } = useWhitelabelTheme();
  const mainButtonColor = colors.mainButtonColor || 'white';

  /**
   * handleOnClick function handles the button click event.
   * It tracks the 'Main Button Click' event and then triggers the provided onClick callback.
   *
   * @returns {void}
   */
  const handleOnClick = () => {
    trackEvent('Main Button Click');
    onClick();
  };

  return (
    <>
      <div className="main-button-fixed-space" />
      <button
        className={`main-button ${isFlat ? 'main-button-rounded' : ''} ${
          isLoading ? 'main-button-loading' : ''
        }`}
        onClick={handleOnClick}
        disabled={isDisabled}
      >
        {isLoading ? (
          <LoaderDots />
        ) : (
          <>
            <Text
              elementType="span"
              size="L"
              weight="semibold"
              color={mainButtonColor}
              textAlign="center"
            >
              {label}
            </Text>
            {!isDisabled && <ArrowRight color={mainButtonColor} />}
          </>
        )}
      </button>
    </>
  );
};

MainButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFlat: PropTypes.bool,
};

MainButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isFlat: false,
};

export default MainButton;
