import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Spacing, Currency } from '@reservamos/elements';
import usePurchase from 'hooks/store/usePurchase';
import PointsSlider from 'ui/molecules/PointsSlider';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import useCostaPassSlider from './useCostaPassSlider';

const CostaPassSlider = () => {
  const purchase = usePurchase();
  const {
    selectedLoyaltyProgram,
    costaPass: {
      wallet,
      onApplyPointsDiscount,
      onRemovePointsDiscount,
      onRefreshWalletBalance,
      config: { rechargeURL },
    },
  } = useLoyaltyPrograms();
  const {
    sliderPoints,
    canApplyPoints,
    error,
    disabled,
    handleOnChange,
    handleOnEdit,
    handleOnCancel,
    handleOnDelete,
    handleOnSubmitPoints,
    replacesRegexComas,
    formattedMinUsableBalance,
    formattedUsableBalance,
    formattedBalance,
    totalBalance,
  } = useCostaPassSlider({
    wallet,
    onApplyPointsDiscount,
    onRemovePointsDiscount,
    purchaseTotal: purchase.total,
    purchaseTotalBeforeLock: purchase.totalBeforeLock,
  });
  const { t: tPayment } = useTranslation('payment');
  const { travelpassLoyaltyConfig } = useWhitelabelEnvs();
  const prefix = travelpassLoyaltyConfig?.prefix || 'costapass';

  const headerTitle = !wallet.balance
    ? tPayment('label.add_money_costapass_title', { context: prefix })
    : tPayment('label.select_costapass_money_to_use', { context: prefix });

  if (selectedLoyaltyProgram !== 'travelpass' && selectedLoyaltyProgram !== 'costapass')
    return null;

  return (
    <PointsSlider
      showInput
      inputValue={String(sliderPoints)}
      minPoints={wallet.minUsableBalance}
      maxPoints={wallet.maxUsableBalance}
      handleOnSubmitPoints={handleOnSubmitPoints}
      handleOnChange={handleOnChange}
      handleOnDelete={handleOnDelete}
      handleOnEdit={handleOnEdit}
      handleOnCancel={handleOnCancel}
      sliderPoints={Number(sliderPoints).toFixed(2).toString()}
      inputPointsValue={sliderPoints}
      balance={totalBalance}
      disabled={disabled}
      pointsApplied={wallet.usedBalance}
      isLoading={wallet.isUpdating || purchase.isUpdating}
      headerTitle={headerTitle}
      notPoints={!wallet.balance}
      notPointsMessage={tPayment('label.add_money_costapass_instructions', { context: prefix })}
      sliderHeader={
        <Spacing justifyContent="center" alignItems="center" vertical size="S">
          <Text color="grayMedium" size="M" mobileSize="S">
            {tPayment('label.you_have_costapass', {
              points: formattedBalance,
            })}
          </Text>
          {canApplyPoints ? (
            <Currency
              size="L"
              mobileSize="M"
              weight="semibold"
              color="primary"
              currency="MXN"
              price={sliderPoints}
              currencyLowercase
              sign=""
            />
          ) : (
            <Text color="grayMedium" size="M" mobileSize="S">
              {tPayment('label.you_can_exchange_costapass_money', {
                points: formattedUsableBalance,
              })}
            </Text>
          )}
        </Spacing>
      }
      inputLabelText={tPayment('label.costapass_to_use')}
      minPointsText={`${formattedMinUsableBalance} MXN`}
      maxPointsText={`${formattedUsableBalance} MXN`}
      pointsAppliedText={tPayment('label.points_applied_costapass', {
        pointsUsed: wallet.usedBalance.toFixed(2).toString().replace(replacesRegexComas, ','),
        context: prefix,
      })}
      errorMessage={error}
      editButtonText={tPayment('button.modify_costapass_money')}
      modifierClass={prefix}
      rechargeURL={rechargeURL}
      steps="0.01"
      showAddPointsButton
      canApplyPoints={canApplyPoints}
      loyalty={prefix}
      deleteButtonText={tPayment('button.delete_costapass_money')}
      handleUpdateWallet={onRefreshWalletBalance}
    />
  );
};

export default CostaPassSlider;
