/* eslint-disable import/prefer-default-export */
import * as types from 'constants/ActionTypes';
import store from '@/store';
import Cookies from 'js-cookie';

/** action that receives the costapass profile and save it in the store */
export function receiveProfile(profile, loyaltyName, error) {
  const { siemprePlus } = store.getState();
  const { user } = siemprePlus.toJS();
  return {
    type: types.COSTAPASS_RECEIVE_PROFILE,
    data: {
      profile,
      loyaltyName,
      error,
      anotherLoyaltyActivaded: Boolean(user) || Cookies.get('token'),
    },
  };
}

/** set an error code in the costapass state */
export function setCostaPassError(errorCode) {
  return {
    type: types.COSTAPASS_ERROR,
    errorCode,
  };
}
