import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { analyticsReady, gaTracker } from 'user-analytics';
import siftSciencePageView from 'metrics/siftSciencePageView';
import Notification from 'components/Notification';
import Home from 'components/home/Home';
import SearchProcessor from 'components/search/SearchProcessor';
import NewPurchase from 'components/purchase/NewPurchase';
import Purchase from 'components/purchase/Purchase';
import PaymentProcessor from 'components/payment/PaymentProcessor';
import FailedPayment from 'components/payment/FailedPayment';
import MobilePayment from 'components/purchase/MobilePayment';
import NotFound from 'components/NotFound';
import PurchaseComplete from 'components/purchase/PurchaseComplete';
import TripSelector from 'components/purchase/TripSelector';
import LogoutPage from 'components/sso/LogoutPage';
import LoginPage from 'components/sso/LoginPage';
import { Helmet } from 'react-helmet';
import defaultFavicon from 'images/brand/favicon/favicon.png';
import { setLang, supportedLangs } from 'utils/lang';
import Exchange from 'components/exchange/ExchangeContainer';
import TagManager from 'react-gtm-module';
import { initTest } from 'utils/abtest';
import useUnlockSeats from 'hooks/useUnlockSeats';
import store from '@/store';
import { useTranslation } from 'react-i18next';
import PrivacyBanner from './PrivacyBanner';
import PendingTransferPayment from './purchase/PendingTransferPayment';
import OpenTicketSearch from './search/OpenTicketSearch';
import riskifiedPageview from '../metrics/riskifiedPageView';
import DownloadTickets from './DownloadTickets';
import { loadSearchWidgetStyle } from '../utils/searchWidget';
import { removeURLParam } from '../utils/urls';
import PurchasesHistoryForm from './PurchasesHistory/PurchasesHistoryForm';
import PurchasesHistoryView from './PurchasesHistory/PurchasesHistoryView';
import '@adyen/adyen-web/dist/adyen.css';
import Pqrs from './ochoa/Pqrs';
import DynamicSearch from './search/DynamicSearch';
import CanceledPayment from './payment/CanceledPayment';

const App = () => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t, i18n } = useTranslation('common');
  const location = useLocation();
  useUnlockSeats();

  useEffect(() => {
    if (features.ALLOW_TO_CHANGE_LANGUAGE) {
      const paramString = 'lang';
      const paramLang = new URLSearchParams(location.search).get(paramString) ?? null;
      if (paramLang && supportedLangs[paramLang]) {
        // Removes the lang param in the url if exists, this to reset the language
        window?.history.replaceState({}, null, removeURLParam(paramString, window?.location.href));
        setLang(i18n, paramLang);
      }
    }

    // Initialize Google Tag Manager after mounting
    if (env.gtmContainerId) {
      TagManager.initialize({
        gtmId: env.gtmContainerId,
      });
    }

    loadSearchWidgetStyle(env.searchWidget.version);
    analyticsReady(() => gaTracker.setUserType('Anonymous'));

    // Initialize AB TESTS variable if feature exists
    if (features.AB_TESTS) {
      initTest();
    }

    // Expose the store on the window object for debugging purposes
    // expose store when run in Cypress
    if (__DEV__ && window) {
      window.store = store;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    siftSciencePageView();
    riskifiedPageview(location);
  }, [location]);

  return (
    <div className="app">
      <Helmet>
        <title>{t('page_title')}</title>
        <link rel="icon" href={env.theme.faviconSrc || defaultFavicon} type="image/x-icon" />
        <meta name="google" content="notranslate" />
        <meta name="theme-color" content={env.theme.colors.primaryStrong} />
      </Helmet>

      <Notification />

      <div className="portalModal" />

      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/search/:originSlug/:destinationSlug" exact component={DynamicSearch} />
        <Route
          path="/search/:originSlug/:destinationSlug/:departureDate/:returnDate?/p/:passengers"
          component={SearchProcessor}
        />

        <Route path="/purchase/:departureId/:returnId?/new/:passengers" component={NewPurchase} />

        <Route path="/purchase/:token/complete" component={PurchaseComplete} />

        <Route path="/purchase/:token/transfer/:paymentId" component={PendingTransferPayment} />

        <Route path="/purchase/:token" component={Purchase} />

        <Route exact path="/payment/:token/:paymentId/failure" component={FailedPayment} />

        <Route exact path="/payment/:token/canceled" component={CanceledPayment} />

        <Route path="/payment/:token/:paymentId" component={PaymentProcessor} />

        <Route path="/mobile/purchase/:token/checkout" component={MobilePayment} />

        <Route
          path="/search/open-ticket/:originSlug/:destinationSlug/:departureDate/:returnDate/"
          component={OpenTicketSearch}
          exact
        />

        <Route
          path="/search/open-ticket/:originSlug/:destinationSlug"
          component={OpenTicketSearch}
          exact
        />

        {features.DOWNLOAD_TICKETS_PAGE_ENABLED && (
          <Route
            exact
            path="/download-tickets"
            component={
              features.DOWNLOAD_TICKETS_PAGE_TYPE === 'HISTORY'
                ? PurchasesHistoryForm
                : DownloadTickets
            }
          />
        )}

        {features.DOWNLOAD_TICKETS_PAGE_ENABLED &&
          features.DOWNLOAD_TICKETS_PAGE_TYPE === 'HISTORY' && (
            <Route exact path="/download-tickets/tickets" component={PurchasesHistoryView} />
          )}

        {features.ALLOW_TO_EXCHANGE_TICKETS && (
          <Route path="/exchange" exact component={Exchange} />
        )}
        {features.ALLOW_TO_EXCHANGE_TICKETS && (
          <Route path="/exchange/:type" exact component={Exchange} />
        )}
        {features.ALLOW_TO_EXCHANGE_TICKETS && (
          <Route
            path="/exchange/ticket-list/:operationNumbers/:email/:document"
            exact
            component={Exchange}
          />
        )}
        {features.ALLOW_TO_EXCHANGE_TICKETS && (
          <Route
            path="/exchange/schedule/:operationNumbers/:origin/:destination"
            exact
            component={Exchange}
          />
        )}
        {features.ALLOW_TO_EXCHANGE_TICKETS && (
          <Route path="/exchange/schedule/:operationNumbers/:NIT" exact component={Exchange} />
        )}

        {features.TRIP_REDIRECTION_RECEIVE && <Route path="/purchase" component={TripSelector} />}

        {features.USE_SSO && <Route path="/sso/logout" component={LogoutPage} />}
        {features.USE_SSO && <Route path="/sso/login" component={LoginPage} />}

        {features.PQRS_FORM && <Route exact path="/radicar" component={Pqrs} />}

        <Route component={NotFound} />
      </Switch>
      {features.PRIVACY_BANNER_ENABLED && <PrivacyBanner />}
    </div>
  );
};

export default App;
