import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConektaStoreForm from '../payment/store/ConektaStoreForm';
import KushkiStoreForm from '../payment/store/KushkiStoreForm';
import PaycashStoreForm from '../payment/store/PaycashStoreForm';

const storeForms = {
  conekta: ConektaStoreForm,
  kushki: KushkiStoreForm,
  paycash: PaycashStoreForm,
};

const StoreForm = () => {
  const { t } = useTranslation('payment');
  const labels = [t('store_form_submit'), t('store_form_instructions')];
  // this is the new way to get the payment engine for each payment method
  const selectedPaymentMethod = useSelector((state) => state.purchase.get('selectedPaymentMethod'));
  // defaults to kushki for backwards compatibility
  const paymentEngine = selectedPaymentMethod?.engine ?? 'kushki';
  const Component = storeForms[paymentEngine];

  return (
    <div className="checkout-section">
      <Component labels={labels} />
    </div>
  );
};

export default StoreForm;
