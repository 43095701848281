import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/components/purchase/PurchaseFooter';
import { useSelector } from 'react-redux';

const propTypes = {
  availablePayments: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

const PurchaseFooter = ({ availablePayments }) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');

  let footerLogos = [];

  if (availablePayments.includes('oxxo')) footerLogos.push('oxxo');
  if (availablePayments.includes('paypal')) footerLogos.push('paypal');
  if (env.kushki.enabled) footerLogos.push('kushki');
  if (env.openPay?.enabled && env.openPay.showInFooter) footerLogos.push('openpay');
  if (availablePayments.includes('transfer')) footerLogos.push('pse');
  if (availablePayments.includes('efecty')) footerLogos.push('efecty');

  footerLogos.push(env.lang.default.startsWith('pt') ? 'safe-payment-pt' : 'safe-payment');
  footerLogos = env.footer.logos.concat(footerLogos);

  return (
    <>
      <div className="purchase-footer-block" />
      <div className="purchase-footer">
        <div className="purchase-footer-content">
          <p>{t('text.remember_payment_methods')}</p>

          <div className="purchase-footer-logos">
            {footerLogos.map((logo) => (
              <i key={logo} className={`pf-${logo}`} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

PurchaseFooter.propTypes = propTypes;

export default PurchaseFooter;
