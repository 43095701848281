import { connect } from 'react-redux';
import { getPayment } from '@/actions/payment';
import PaymentProcessor from './PaymentProcessor';
import { setError } from '../../../actions';

const mapStateToProps = (state, ownProps) => {
  const { token, paymentId } = ownProps.match.params;
  const { payment, purchase } = state;
  const { preSelectedSeats } = purchase.toJS();
  let pathToMobile = '';

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    if (origin === 'mobile-app') {
      pathToMobile = '/mobile';
    }
  }

  return {
    token,
    paymentId: Number(paymentId),
    type: payment.get('paymentType'),
    using3dSecure: payment.get('using3dSecure'),
    paymentRedirectUrl: payment.get('redirectTo'),
    isFetching: payment.get('isFetching') || false,
    paymentStatus: payment.get('status'),
    loaded: payment.get('loaded'),
    paymentEngine: payment.get('paymentEngine'),
    pathToMobile,
    hasPreSelectedSeats: preSelectedSeats && Object.keys(preSelectedSeats).length > 0,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPayment: () => {
    const { paymentId, token } = ownProps.match.params;
    dispatch(getPayment(paymentId, token));
  },
  setError: (code, msg, redirect) => dispatch(setError(code, msg, redirect)),
  transitionTo: (route) => ownProps.history.push(route),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessor);
