import React from 'react';
import { Text, ProviderLogo, Visibility } from '@reservamos/elements';
import PropTypes from 'prop-types';
import './LineBanner.scss';
import { useTranslation } from 'react-i18next';

/**
 * Renders the LineBanner component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.brand - The brand name.
 * @param {string} props.logoUrl - The logo url.
 * @returns {JSX.Element} The rendered component.
 */
const LineBanner = ({ brand, logoUrl }) => {
  const { t } = useTranslation('common');
  return (
    <div className="banner-line">
      <div className="banner-line-provider">
        <Visibility type="hideForMedium">
          <Text color="grayStrong">{t('common:labels.provider')}</Text>
        </Visibility>
        <ProviderLogo name={brand} imgSrc={logoUrl} isSmall />
      </div>
    </div>
  );
};

LineBanner.propTypes = {
  brand: PropTypes.string,
  logoUrl: PropTypes.string,
};

export default LineBanner;
