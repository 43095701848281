import store from '@/store';
import { CurrentLang } from 'utils/lang';
import availablePaymentEngines from '../constants/PaymentEngine';

const Adyen = typeof window === 'undefined' ? null : require('@adyen/adyen-web');

let adyen = null;

export function getAdyen() {
  return adyen;
}

export function getAdyenInstance() {
  if (adyen) {
    const instances = adyen.components;
    const lastInstance = instances[instances.length - 1];
    const validInstances = instances.filter((instance) => instance.isValid);

    if (validInstances.length > 0) {
      const lastValidInstance = validInstances[validInstances.length - 1];

      lastInstance.setState(lastValidInstance.state);
      return lastInstance;
    }

    return lastInstance;
  }
}

export function removeAdyenInstance() {
  if (adyen) {
    if (adyen.components.length > 0) {
      adyen.components[adyen.components.length - 1].remove();
    }
  }
}

export function setupAdyen(whitelabelEnvs) {
  const { enabled, environment, publicKey: clientKey } = whitelabelEnvs.adyen || {};

  if (!enabled) return;
  if (!Adyen) return;

  adyen = new Adyen({
    locale: CurrentLang() || whitelabelEnvs.lang.default,
    clientKey,
    environment,
  });
}

export function shouldUseAdyen(paymentEngine) {
  const adyenIsEnabled = store.getState().whitelabelConfig.env.adyen.enabled;
  return adyenIsEnabled && paymentEngine === availablePaymentEngines.adyen;
}
