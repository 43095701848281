import React from 'react';
import Tooltip from 'components/Tooltip';
import 'styles/components/DisclaimerTooltip';

const DisclaimerTooltip = () => (
  <Tooltip className="disclaimer-tooltip">
    <i className="disclaimer-icon" />

    <p className="disclaimer-tooltip-copy">No aliado</p>

    <div className="tooltip">
      <p className="tooltip-paragraph">
        Reservamos únicamente actúa como comisionista del usuario del sitio, de acuerdo a los
        Términos y Condiciones que el usuario acepta. Reservamos realiza reservaciones legítimas y
        adquiere boletos a nombre y por cuenta de los usuarios del sitio con el proveedor del
        servicio. Los logotipos y nombres de productos, servicios o empresas prestadoras de
        servicios aquí mencionados pueden ser marcas registradas de terceros, legítimos propietarios
        de sus marcas, y se mencionan en este sitio únicamente para fines informativos y
        comparativos para el público consumidor. Reservamos no comercializa productos, ni presta
        servicios relacionados con marcas de terceros.
      </p>
    </div>
  </Tooltip>
);

export default DisclaimerTooltip;
