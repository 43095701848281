import * as types from 'constants/ActionTypes';

/**
 * Sets the sort order for the trips.
 * @param {string} sortBy - The sort by property.
 * @returns {Object} The action object.
 */
export function setTripSortOrder(sortBy) {
  return {
    type: types.SET_TRIP_SORT_ORDER,
    sortBy,
  };
}

/**
 * Sets a specific filter for the trips.
 * @param {string} filterBy - The filter criteria.
 * @param {boolean} active - Indicates whether the filter is active or not.
 * @param {boolean} keepFiltersSelected - Indicates whether the filters should be kept selected.
 * @returns {Object} The action object.
 */
export function setTripFilter(filterBy, active, keepFiltersSelected) {
  return {
    type: types.SET_TRIP_FILTER,
    keepFiltersSelected,
    filterBy,
    active,
  };
}

/**
 * Sets multiple filters for the trips.
 * @param {Array} filters - The array of filter criteria.
 * @returns {Object} The action object.
 */
export function setTripFilters(filters) {
  return {
    type: types.SET_TRIP_FILTER,
    filters,
  };
}

/**
 * Resets the trip filters.
 * @returns {Object} The action object.
 */
export function resetTripFilters() {
  return { type: types.RESET_TRIP_FILTERS };
}
