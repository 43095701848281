export default function redirectHttps() {
  const redirectHttpsHosts = ['viaje.peninsulaejecutivo.com.mx', 'viaje.primeraplus.com.mx'];

  const currentHost = window.location.host;
  const redirectHttpsEnabled = redirectHttpsHosts.indexOf(currentHost) > -1;
  // eslint-disable-next-line
  if (redirectHttpsEnabled && window.location.protocol != 'https:') {
    window.location.protocol = 'https';
  }
}
