import store from '@/store';

const KEY = 'form.purchaser';

export function getStoredPurchaser() {
  const { features } = store.getState().whitelabelConfig;
  if (!features.STORE_PURCHASER_FORM) return null;
  return JSON.parse(
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem(KEY) || 'null'
      : 'null',
  );
}

export function setStoredPurchaser(passengers) {
  const jsonPassengers = JSON.stringify(passengers);
  if (typeof window !== 'undefined' && window.localStorage)
    window.localStorage.setItem(KEY, jsonPassengers);
}
