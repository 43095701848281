const merge = require('lodash').merge;
const commonConfig = require('./commonConfig');

const ENV = merge(commonConfig, {
  brand: 'gfa',
  homeUrl: 'http://wl.reserviaje.com/',

  api: {
    searchUrl: 'https://wl.reserviaje.com/api',
    purchaseUrl: 'https://wl.reserviaje.com/api',
    mobileKey: 'f197103000a8636dcad21eb7896553dc',
    desktopKey: 'dec63cbf40bb87d4805a65bdc03264e6',
    get searchHost() {
      return this.searchUrl.slice(0, -4);
    },
    get purchaseHost() {
      return this.purchaseUrl.slice(0, -4);
    },
  },

  siemprePlus: {
    colors: {
      primaryColor: '#dc291e',
      strongColor: '#af2118',
    },
    iconType: 'siempreplus',
    cardType: 'siempreplus',
    prefix: 'GFA',
    recoverPassword: true,
    registerAccount: true,
    logoutApi: 'false',
    thirdParty: true,
    facebookLogin: false,
    googleLogin: false,
    outlookLogin: false,
    hasModalDescription: false,
    hrefConfig: {
      noticeOfPrivacyUrl:
        'https://ventas.primeraplus.com.mx/web/primerapluspublico/solicitud-de-derechos-arco',
      termsAndConditionsUrl:
        'https://ventas.primeraplus.com.mx/web/primerapluspublico/terminos-y-condiciones',
    },
  },

  frontline: {
    host: 'wl.reserviaje.com',
  },

  sift: {
    enabled: false,
    apiKey: 'd65bc9f265',
  },

  adyen: {
    enabled: true,
    clientKey: 'test_YXRKAP7VKNE3TNXMFEKLGOMAIESWJM2E',
    env: 'test',
    locale:
      typeof window !== 'undefined' && window.localStorage
        ? window.localStorage.getItem('i18nextLng') || 'es-MX'
        : 'es-MX',
  },

  openPay: {
    enabled: true,
    id: 'mkpfh2m4ttpjwpps0jxq',
    apiKey: 'pk_940330d1563046e384645da6f2c8b8c8',
    isSandbox: true,
  },

  footer: {
    logos: ['visa', 'mastercard', 'amex'],
  },

  conekta: {
    enabled: false,
    pubKey: '',
  },

  segment: {
    token: '62f3d8100c8a58750483c2809b8f8292',
    options: {
      debug: true,
      tracker: 'mixpanel',
    },
  },

  sentry: {
    enabled: false,
  },

  // prettier-ignore
  paymentMethods: [
    'mastercard',
    'visa',
    'americanexpress',
    'paypal',
  ],

  insurance: {
    url: 'https://wl.primeraplus.com.mx/seguro_coberturadicional?_ga=2.174447718.599267047.1621264886-1417943745.1597703858',
  },

  theme: {
    colors: {
      primary: '#dc291e',
      primaryStrong: '#af2118',
      accent: '#dc291e',
      accentStrong: '#af2118',
      busColor: '#dc291e',
    },
    elementColors: {
      busFill: 'accent',
    },
    fonts: {
      primary: '"Korolev", "Open Sans", sans-serif',
      secondary: '"Korolev", "Open Sans", sans-serif',
    },
  },
  riskified: {
    enabled: true,
    storeDomain: 'reservamos-primera.mx',
  },
  smartBanner: {
    enabled: true,
    props: {
      'title': 'Primera Plus',
      'author': 'Grupo Flecha Amarilla',
      'price': 'Gratis',
      'price-suffix-apple': ' - En App Store',
      'price-suffix-google': ' - En Google Play',
      'icon-apple':
        'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/d5/4d/ff/d54dffd9-e168-c424-6671-f10d6b1b8f66/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp',
      'icon-google':
        'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/d5/4d/ff/d54dffd9-e168-c424-6671-f10d6b1b8f66/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp',
      'button': 'VER',
      'button-url-apple': 'https://apps.apple.com/mx/app/primera-plus/id1081519379?l=es_MX',
      'button-url-google':
        'https://play.google.com/store/apps/details?id=com.gfa.PrimeraPlus&hl=es_MX',
      'enabled-platforms': 'android,ios',
      'close-label': 'Cerrar',
      'hide-ttl': 6048e5, // 7 days
    },
  },
});

if (__DEV__) {
  merge(ENV, {
    homeUrl: 'https://wl.reserviaje.com/',

    api: {
      searchUrl: 'https://wl.reserviaje.com/api',
      purchaseUrl: 'https://wl.reserviaje.com/api',
      mobileKey: 'f197103000a8636dcad21eb7896553dc',
      desktopKey: 'dec63cbf40bb87d4805a65bdc03264e6',

      get searchHost() {
        return this.searchUrl.slice(0, -4);
      },
      get purchaseHost() {
        return this.purchaseUrl.slice(0, -4);
      },
    },
    gtmContainerId: 'GTM-M953GV5',
  });
}

// Set production environment configuration
if (__PROD__) {
  merge(ENV, {
    homeUrl: 'https://wl.primeraplus.com.mx/',

    api: {
      searchUrl: 'https://wl.primeraplus.com.mx/api',
      purchaseUrl: 'https://wl.primeraplus.com.mx/api',
      adsUrl: 'https://pp-ads.reservamos.mx/graphql',
      mobileKey: 'e664a2d43c9dadfeffbb0f17b5df9156',
      desktopKey: '8bdcd30b56dcd62673f55847375e158d',
      get searchHost() {
        return this.searchUrl.slice(0, -4);
      },
      get purchaseHost() {
        return this.purchaseUrl.slice(0, -4);
      },
    },

    frontline: {
      host: 'wl.primeraplus.com.mx',
    },

    sift: {
      enabled: true,
      apiKey: '559311d255',
    },

    taplytics: {
      enabled: false,
      token: 'ba2a600026cc49ac9e3d188988409d10',
    },

    adyen: {
      enabled: true,
      clientKey: 'live_CFXTJVBMVZBYNGCFG637RK7PMYGXJHPD',
      env: 'live',
      locale: 'es-MX',
    },

    segment: {
      token: 'db24167fff38ec276bda6bda124ec667',
      options: {
        debug: false,
      },
    },

    sentry: {
      enabled: true,
    },

    openPay: {
      id: 'mo9znmfjlzorsvhfypet',
      apiKey: 'pk_b398c510ac8846338d1792919318f69c',
      isSandbox: false,
    },

    conekta: {
      pubKey: '',
    },

    smartBanner: {
      enabled: true,
      props: {
        'title': 'Primera Plus',
        'author': 'Grupo Flecha Amarilla',
        'price': 'Gratis',
        'price-suffix-apple': ' - En App Store',
        'price-suffix-google': ' - En Google Play',
        'icon-apple':
          'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/d5/4d/ff/d54dffd9-e168-c424-6671-f10d6b1b8f66/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp',
        'icon-google':
          'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/d5/4d/ff/d54dffd9-e168-c424-6671-f10d6b1b8f66/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp',
        'button': 'VER',
        'button-url-apple': 'https://apps.apple.com/mx/app/primera-plus/id1081519379?l=es_MX',
        'button-url-google':
          'https://play.google.com/store/apps/details?id=com.gfa.PrimeraPlus&hl=es_MX',
        'enabled-platforms': 'android,ios',
        'close-label': 'Cerrar',
        'hide-ttl': 6048e5, // 7 days
      },
    },
  });
}

module.exports = ENV;
