import { connect } from 'react-redux';
import moment from 'moment';
import { getCurrentPrice, getDisabledButtons } from 'utils/search/minPrices';
import isSearchPolling from 'utils/search/isSearchPolling';
import { encodePassengers } from 'utils/Reserbus';
import PriceIndicator from './DayControls';

const mapStateToProps = ({ search, trips }, ownProps) => {
  const { way, isProviderList, providerId } = ownProps;
  const minPrices = search.get('minPrices');
  const departureDate = search.get('departureDate');
  const returnDate = search.get('returnDate');
  const originId = search.get('originId');
  const destinationId = search.get('destinationId');

  const isRoundTrip = !!(departureDate && returnDate);
  const baseDate = way === 'departure' ? departureDate : returnDate;

  const prevDayDate = moment(baseDate, 'DD-MM-YYYY').subtract(1, 'days');
  const nextDayDate = moment(baseDate, 'DD-MM-YYYY').add(1, 'days');

  const currentPrices = minPrices.get(way) ?? { previousDay: null, nextDay: null };
  const { previousDay = null, nextDay = null } = currentPrices;
  const departureId = search.getIn(['departure', 'id']);
  const returnId = search.getIn(['return', 'id']);

  const departures = trips.get(departureId);
  const returns = isRoundTrip ? trips.get(returnId) : undefined;

  const isPolling = isSearchPolling(null, departures, returns, isRoundTrip);

  const previousDayPrices = previousDay?.lines?.[providerId] ?? previousDay;
  const nextDayPrices = nextDay?.lines?.[providerId] ?? nextDay;

  const prevDayPrice = isPolling ? 0 : getCurrentPrice(previousDayPrices, way, isRoundTrip);
  const nextDayPrice = isPolling ? 0 : getCurrentPrice(nextDayPrices, way, isRoundTrip);

  const passengersSelection = search.get('passengers');
  const encodedPassengersSelection =
    (passengersSelection && encodePassengers(passengersSelection.toJS())) || 'A1';

  const disabledButtons = getDisabledButtons(
    way,
    isRoundTrip,
    prevDayDate,
    nextDayDate,
    prevDayPrice,
    nextDayPrice,
    departureDate,
    returnDate,
  );

  return {
    prevDayDate,
    nextDayDate,
    prevDayPrice,
    nextDayPrice,
    disabledButtons,
    originId,
    destinationId,
    departureDate,
    returnDate,
    isRoundTrip,
    isProviderList,
    encodedPassengersSelection,
  };
};

export default connect(mapStateToProps)(PriceIndicator);
