import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';
import PurchaseLayout from '../../PurchaseLayout';

const PendingTransferPayment = ({
  fetchPayment,
  paymentLoaded,
  paymentStatus,
  processorState,
  purchaseToken,
  redirect,
  purchaseState,
  paymentProvider,
}) => {
  const { t } = useTranslation('payment');
  const isPixPayment = purchaseState === 'attempt' && paymentProvider === 'pix';
  const isNequiPayment = purchaseState === 'pending' && paymentProvider === 'nequi';

  useEffect(() => {
    // No need to fetch payment if it's a pix or nequi payment or the purchase is completed
    if (isPixPayment || isNequiPayment || purchaseState === 'completed') {
      return;
    }
    fetchPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!paymentLoaded) return;
    /**
     * When using MP, this condition can be true, but the purchaseState is still 'attempt',
     * so we need to add this condition first to correctly redirect the user back to the checkout
     */
    if (processorState === 'FAILED' || paymentStatus === 'failed') {
      redirect(`/purchase/${purchaseToken}/checkout`);
    }

    // No need to fetch payment if it's a pix or nequi payment, just redirect to complete
    if (isPixPayment || isNequiPayment) {
      redirect(`/purchase/${purchaseToken}/complete`);
      return;
    }
    if (isPixPayment || isNequiPayment) return;

    if (processorState === 'PENDING' && paymentStatus !== 'charged') return;

    if (processorState === 'OK' || paymentStatus === 'charged') {
      redirect(`/purchase/${purchaseToken}/complete?trackPurchase=true`);
    } else {
      redirect(`/purchase/${purchaseToken}/checkout`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLoaded, processorState, purchaseState]);

  return (
    <PurchaseLayout>
      <Loading loadingText={t('loading_payment')} />
    </PurchaseLayout>
  );
};

PendingTransferPayment.propTypes = {
  fetchPayment: PropTypes.func.isRequired,
  paymentLoaded: PropTypes.bool.isRequired,
  paymentStatus: PropTypes.string,
  processorState: PropTypes.string,
  purchaseToken: PropTypes.string.isRequired,
  purchaseState: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
  paymentProvider: PropTypes.string.isRequired,
};

PendingTransferPayment.defaultProps = {
  processorState: '',
};

export default PendingTransferPayment;
