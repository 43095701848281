import { connect } from 'react-redux';
import AgencyHeader from './AgencyHeader';
import { fetchAgency } from '../../actions/agency';

const mapStateToProps = ({ agency }) => ({
  email: agency.getIn(['user', 'email']) || '',
  isLoading: agency.get('isLoading'),
  isLoggedIn: Boolean(agency.get('agency')),
});

const mapDispatchToProps = {
  fetchAgency,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyHeader);
