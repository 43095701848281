/* global $ */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import 'styles/components/LoadingText';

const LoadingText = ({ loadingText }) => {
  const { t } = useTranslation('general');
  const messages = [
    t('loading_messages.first'),
    t('loading_messages.second'),
    t('loading_messages.third'),
    t('loading_messages.fourth'),
  ];
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const selector = $('#content');

      selector.fadeOut('slow', () => {
        selector.fadeIn('slow');

        if (currentMessage < messages.length - 1) {
          setCurrentMessage(currentMessage + 1);
        } else {
          setCurrentMessage(0);
        }
      });
    }, 5000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessage]);

  return (
    <p className="loading-text">
      <em id="content">
        {loadingText ? i18n.t(`general:${loadingText}`) : messages[i18n.t(currentMessage)]}

        <span>.</span>
        <span>.</span>
        <span>.</span>
      </em>
    </p>
  );
};

LoadingText.propTypes = {
  loadingText: PropTypes.string,
};

LoadingText.defaultProps = {
  loadingText: '',
};

export default LoadingText;
