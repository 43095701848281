import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery(...keys) {
  const { search } = useLocation();
  function loadParams() {
    const params = new URLSearchParams(search);

    return keys.reduce(
      (result, key) => ({
        ...result,
        [key]: params.get(key),
      }),
      {},
    );
  }

  const [query, setQuery] = useState(loadParams());

  useEffect(() => {
    setQuery(loadParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return query;
}
