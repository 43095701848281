import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacing, Text } from '@reservamos/elements';
import './FilterChip.scss';

/**
 * Component for displaying a filter chip.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.text - The main text of the filter chip.
 * @param {string} props.secondText - The secondary text of the filter chip.
 * @param {boolean} props.notAvailable - Indicates if the filter chip is not available.
 * @param {string} props.icon - The type of icon to display in the filter chip.
 * @param {string} props.iconColor - The color of the icon.
 * @param {function} props.onSelect - The function to handle the filter chip selection.
 * @param {string} props.type - The type of the filter chip.
 * @param {string} props.value - The value of the filter chip.
 * @param {boolean} props.active - Indicates if the filter chip is active.
 * @param {string} props.i18Key - The translation key for the label.
 * @returns {JSX.Element} The rendered component.
 */
const FilterChip = ({
  text,
  secondText,
  notAvailable,
  icon,
  iconColor,
  onSelect,
  filterType,
  value,
  active,
  i18Key,
}) => {
  const [isActive, setIsActive] = useState(active);

  const notAvailableColor = notAvailable ? 'grayLight' : 'grayStrong';
  const notAvailableClass = notAvailable ? 'not-available' : '';
  const notAvailableColorIcon = notAvailable ? 'error' : '';

  /** Handle the click event */
  const handleClick = () => {
    onSelect({ filterType, value, label: text, isActive: !isActive, i18Key });
    setIsActive((prevIsActive) => !prevIsActive);
  };

  return (
    <button
      className={`${secondText ? 'chip-time' : 'chip-to-filter'} ${
        isActive ? 'actived' : notAvailableClass
      }`}
      onClick={handleClick}
      disabled={!!notAvailable}
    >
      <Icon
        type={notAvailable ? 'BlockNotAvailable' : icon}
        size="S"
        color={notAvailableColorIcon || iconColor}
      />
      <Spacing elementType="span" vertical size="">
        <Text size="S" weight="bold" color={isActive ? 'white' : notAvailableColor}>
          {text}
        </Text>
        {Boolean(secondText) && (
          <Text size="XXS" color={isActive ? 'white' : notAvailableColor}>
            {secondText}
          </Text>
        )}
      </Spacing>
    </button>
  );
};

FilterChip.propTypes = {
  text: PropTypes.string.isRequired,
  secondText: PropTypes.string,
  notAvailable: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  i18Key: PropTypes.string,
};

FilterChip.defaultProps = {
  secondText: false,
  notAvailable: false,
  icon: '',
  iconColor: false,
};

export default FilterChip;
