import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import LoadingScreen from '../../LoadingScreen';
import { getMomentFromSearchDateParam } from '../../../utils/search/searchDateParam';

const OpenTicketSearch = ({
  createSearch,
  originSlug,
  destinationSlug,
  searchIsPolling,
  tripList,
  createPurchase,
  purchase,
  replaceUrl,
  setError,
  departureDate,
  returnDate,
  transitionTo,
  returnIsOpenTicket,
  includeRecommendedTrips,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const dDate = departureDate ? getMomentFromSearchDateParam(departureDate) : moment();
  const rDate = returnDate ? getMomentFromSearchDateParam(returnDate) : moment();

  /**
   * Redirects to search return trip
   * @param {*} trip - The selected trip
   */
  const redirectRoundTrip = useCallback(
    (trip) => {
      const formattedDepartureDate = dDate.format('DD-MMM-YY');
      const formattedReturnDate = rDate.format('DD-MMM-YY');
      let route =
        `/search/${originSlug}/${destinationSlug}/${formattedDepartureDate}/${formattedReturnDate}` +
        `/p/A1/${trip.id}/return/providers`;
      if (!features.PROVIDERS_SELECTION_ENABLED) {
        route =
          `/search/${originSlug}/${destinationSlug}/${formattedDepartureDate}/${formattedReturnDate}` +
          `/p/A1/${trip.id}/returns/`;
      }

      transitionTo(route, {
        seenPrice: trip.pricing.total,
        ...(returnIsOpenTicket && { returnIsOpenTicket }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [departureDate, destinationSlug, originSlug, returnDate, returnIsOpenTicket],
  );

  useEffect(() => {
    createSearch(
      'departure',
      originSlug,
      destinationSlug,
      dDate.format('DD-MM-YYYY'),
      !!returnDate,
      true,
      includeRecommendedTrips,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originSlug, destinationSlug]);

  useEffect(() => {
    if (searchIsPolling) return;

    const openTicketTrip = tripList.find((trip) => trip.openTicket);
    if (!openTicketTrip) {
      setError(100, 'No se encontraron viajes para la ruta seleccionada');
      return;
    }

    // If there is a return date given by param, redirect to search return trip
    if (returnDate || returnIsOpenTicket) {
      redirectRoundTrip(openTicketTrip);
    } else {
      createPurchase(openTicketTrip.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIsPolling, tripList.length]);

  useEffect(() => {
    if (!purchase.isFetching && purchase.token) {
      replaceUrl(`/purchase/${purchase.token}/seats/departure`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase.isFetching]);

  return <LoadingScreen backgroundColor="white" />;
};

OpenTicketSearch.propTypes = {
  createSearch: PropTypes.func.isRequired,
  createPurchase: PropTypes.func.isRequired,
  originSlug: PropTypes.string.isRequired,
  destinationSlug: PropTypes.string.isRequired,
  searchIsPolling: PropTypes.bool.isRequired,
  tripList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      openTicket: PropTypes.bool.isRequired,
    }).isRequired,
  ),
  purchase: PropTypes.shape({
    token: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }).isRequired,
  replaceUrl: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  departureDate: PropTypes.string,
  returnDate: PropTypes.string,
  transitionTo: PropTypes.func.isRequired,
  returnIsOpenTicket: PropTypes.bool.isRequired,
  includeRecommendedTrips: PropTypes.bool.isRequired,
};

OpenTicketSearch.defaultProps = {
  departureDate: null,
  returnDate: null,
  tripList: [],
};

export default OpenTicketSearch;
