import { connect } from 'react-redux';
import { fetchTripsDetails, selectSeats, updateSeat } from '@/actions/purchase';
import { clearError, setError } from '@/actions/index';
import { getSeatSelectorPropsFromPurchase, getSeatSelectorPropsFromTrip } from 'utils/seats';
import DepartureSeats from './DepartureSeats';
import {
  dispatchFinishSeatSelection,
  dispatchResetPassengers,
} from '../../../features/purchase/redux/dispatchers';

const mapStateToProps = (state, ownProps) => {
  const purchase = state.purchase?.toJS() || {};
  const { isPurchaseView = true, trip, resultsLayout } = ownProps;
  const way = 'departure';

  let propsNeeded = {};
  if (isPurchaseView) {
    propsNeeded = getSeatSelectorPropsFromPurchase({ way, purchase });
  } else {
    propsNeeded = getSeatSelectorPropsFromTrip({ way, trip });
  }

  return { ...propsNeeded, resultsLayout };
};

const mapDispatchToProps = (dispatch, { isPurchaseView = true, ...ownProps }) => ({
  fetchTripsDetails: (fragments) =>
    dispatch(fetchTripsDetails({ ...fragments, saveIn: isPurchaseView ? 'purchase' : 'trips' })),

  selectSeats: (seats) =>
    dispatch(
      selectSeats('departs', seats, isPurchaseView ? 'purchase' : 'trips', ownProps.trip?.id),
    ),
  updateSeat: (seat) =>
    dispatch(updateSeat('departs', seat, isPurchaseView ? 'purchase' : 'trips', ownProps.trip?.id)),
  finishSeatSelection: () => {
    if (isPurchaseView) {
      dispatch(dispatchFinishSeatSelection(ownProps.history));
    } else {
      ownProps.finishSeatsSelection();
    }
  },
  resetPassengers: () => dispatch(dispatchResetPassengers()),
  sendNotification: (message, messageData) =>
    dispatch(setError(null, message, 'warning', false, null, null, null, 'fixed', messageData)),
  /**
   * Clear the alert error
   */
  clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartureSeats);
