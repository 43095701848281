import React, { useState, useEffect, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'styles/components/purchase/Timer/';
import { useTranslation } from 'react-i18next';
import { createTimerFormat } from '../../../utils/purchase/timer';

const propTypes = {
  variant: PropTypes.oneOf(['purchase', 'payment', 'exchange']),
  size: PropTypes.oneOf(['fixed', 'medium', 'default']),
  remainingTime: PropTypes.number,
};

const defaultProps = {
  variant: 'purchase',
  size: 'default',
};

const sizes = {
  fixed: 'timer-fixed',
  medium: 'timer-medium',
  default: '',
};
/**
 * A timer component that displays the remaining time until a purchase is finalized.
 * @param {Object} props - The component props.
 * @param {string} props.size - The size of the timer ('fixed' or 'inline').
 * @param {string} props.variant - The visual style of the timer ('light' or 'dark').
 * @param {number} [props.remainingTime=0] - The remaining time in seconds.
 * @returns {JSX.Element|null} - The rendered timer component or null if the timer is not enabled.
 */
const Timer = ({ size, variant, remainingTime }) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const timerContainerRef = useRef(null);
  const [timeDisplay, setTimeDisplay] = useState('');

  const smartbannerCookie = Cookies.get('smartbanner_exited');
  let initialPosition = 60;
  if (env.smartBanner?.enabled && !smartbannerCookie) initialPosition = 130;

  const [topPosition, setTopPosition] = useState(initialPosition);
  const isMobile = window.innerWidth <= 1080;

  const handleScroll = useCallback(() => {
    if (isMobile) {
      const scrolledPixels = window.scrollY;
      const calculatedPosition = Math.max(0, 60 - scrolledPixels);
      const newPosition = timerContainerRef.current.className.includes('smartbanner-hack')
        ? calculatedPosition + 70
        : calculatedPosition;
      setTopPosition(newPosition);
    }
  }, [isMobile]);

  useEffect(() => {
    if (env.smartBanner?.enabled && smartbannerCookie) {
      handleScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartbannerCookie]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const scrollStyle = isMobile ? { top: `${topPosition}px` } : null;

  const showBlock = size === 'fixed';

  useEffect(() => {
    const remainingTimeFormat = remainingTime ? createTimerFormat(remainingTime, 'mm:ss') : '';
    setTimeDisplay(remainingTimeFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  if (!features.PURCHASE_TIMER_ENABLED) return null;

  return (
    <div>
      {showBlock && <div className="timer-block" />}
      <div
        ref={timerContainerRef}
        className={`timer ${sizes[size]} ${variant}-variant`}
        style={scrollStyle}
      >
        <p className="timer-copy">
          {t('finalize_at', { context: variant })}:<span>{timeDisplay}</span>
        </p>
      </div>
    </div>
  );
};

Timer.propTypes = propTypes;
Timer.defaultProps = defaultProps;

export default Timer;
