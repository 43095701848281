/* eslint-disable import/prefer-default-export */
const departureParams = {
  departs_origin: '',
  departs_destination: '',
  departs_date: '',
  departs_hour: '',
  departs_minutes: '',
  departs_line: '',
  roundtrip: false,
  passengers: 'A1',
  selected_seats: {
    preSelectedSeats: {
      departure: [],
      return: [],
    },
  },
};

const departureSearch = {
  origin: 'departs_origin',
  destination: 'departs_destination',
  date: 'departs_date',
  roundTrip: 'roundtrip',
};

const returnSearch = {
  origin: 'return_origin',
  destination: 'return_destination',
  date: 'return_date',
  roundTrip: 'roundtrip',
};

const returnParams = {
  return_origin: '',
  return_destination: '',
  return_date: '',
  return_hour: '',
  return_minutes: '',
  return_line: '',
  roundtrip: false,
};

export const paramsToObject = (params, way) => {
  const wayParams = {
    departure: departureParams,
    return: returnParams,
  };

  const result = Object.keys(wayParams[way]).reduce((acc, key) => {
    const value = params.get(key) ? params.get(key) : '';

    acc[key] = value;

    return acc;
  }, {});

  return result;
};

export const isRoundTrip = (params) => {
  return params.get('roundtrip') === 'true';
};

export const fromBrand = (params) => {
  return params.get('from');
};

export const ObjectToSearchParams = (payload, way) => {
  const wayParams = {
    departure: departureSearch,
    return: returnSearch,
  };

  const result = Object.keys(wayParams[way]).reduce((acc, key) => {
    const payloadKey = wayParams[way][key];
    const value = payload[payloadKey];

    acc[key] = value;

    return acc;
  }, {});

  return result;
};
