// @ts-check

/**
 * @typedef {import('./strategies/PseStrategy').PseStrategy} PseStrategy
 */

/**
 * @function Pse
 */
const Pse = () => {
  /**
   * @type {PseStrategy | undefined}
   */
  let strategy;

  const setStrategy = (newStrategy) => {
    strategy = newStrategy;
  };

  const fetchBanksList = async () => {
    if (!strategy) {
      throw new Error('Pse strategy not set');
    }
    return strategy.fetchBanksList();
  };

  const createToken = (data) => {
    if (!strategy) {
      throw new Error('Pse strategy not set');
    }
    return strategy.createToken(data);
  };

  const needsRedirect = () => {
    if (!strategy) {
      throw new Error('Pse strategy not set');
    }
    return strategy.needsRedirect;
  };

  return {
    setStrategy,
    fetchBanksList,
    createToken,
    needsRedirect,
  };
};

const pse = Pse();

export default pse;
