import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Spacing } from '@reservamos/elements';
import DiscountRowItem from './DiscountRowItem';

const EarlyDiscounts = (props) => {
  const { discounts } = props;
  const { t } = useTranslation();

  return (
    <Spacing vertical>
      <Spacing vertical size="S">
        <Text weight="bold">{t('search:trip_discount_early')}</Text>
        <div className="provider-info-discounts">
          {discounts.map((discount) => (
            <DiscountRowItem
              key={discount.id}
              label={t('search:trip_discount_description', { context: discount.name })}
              availability={discount.availability}
              total={discount.total}
            />
          ))}
        </div>
      </Spacing>
      <Spacing vertical size="S">
        <Text size="XS">{t('search:trip_discount_politic')}</Text>
      </Spacing>
    </Spacing>
  );
};

EarlyDiscounts.propTypes = {
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      availability: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ),
};

export default EarlyDiscounts;
