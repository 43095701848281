import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export function createSchedulePath(operationNumbers, NIT, origin, destination, email, document) {
  const { whitelabelConfig } = store.getState();

  let url = `/exchange/${
    whitelabelConfig.features.USE_EXCHANGE_TICKET_LIST_ROUTE ? 'ticket-list' : 'schedule'
  }`;
  if (operationNumbers) url += `/${operationNumbers}`;
  if (NIT) url += `/${NIT}`;
  if (origin) url += `/${origin}`;
  if (destination) url += `/${destination}`;
  if (email) url += `/${email}`;
  if (document) url += `/${document}`;
  return url;
}
