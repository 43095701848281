import { List } from 'immutable';

function findTrip(trips, transportType, id) {
  let tripList;

  switch (transportType) {
    case 'bus':
      tripList = trips.getIn(['buses', 'trips']);
      break;
    case 'flight':
      tripList = trips.getIn(['flights', 'trips']);
      break;
    case 'mixed':
      tripList = trips.getIn(['mixed', 'trips']);
      break;
    default:
      tripList = List();
  }

  const index = tripList.findIndex((trip) => trip.get('id') === id);
  const trip = index >= 0 ? tripList.get(index) : null;
  return [index, trip];
}

export default function findTripFromAllSearches(trips, tripId) {
  return trips
    .keySeq()
    .toArray()
    .reduce((found, searchId) => {
      if (found) return found;
      const searchedTrips = trips.get(searchId);

      let [index, trip] = findTrip(searchedTrips, 'bus', tripId);
      if (trip) return { trip, path: [searchId, 'buses', 'trips', index] };

      [index, trip] = findTrip(searchedTrips, 'flights', tripId);
      if (trip) return { trip, path: [searchId, 'flights', 'trips', index] };

      [index, trip] = findTrip(searchedTrips, 'mixed', tripId);
      if (trip) return { trip, path: [searchId, 'mixed', 'trips', index] };

      return null;
    }, null);
}
