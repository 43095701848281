import React from 'react';
import { useSelector } from 'react-redux';
import { Text, MessageBox, Spacing } from '@reservamos/elements';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { When } from 'react-if';
import iamsaBusCalendar from '../../images/brands-unique/etn/calendar-bus.png';
import gfaBusCalendar from '../../images/brands-unique/gfa/calendar-bus.png';

const nowAllowedImageType = {
  etn: iamsaBusCalendar,
  gho: iamsaBusCalendar,
  costaline: iamsaBusCalendar,
  gfa: gfaBusCalendar,
};

const NotAllowedSeats = ({ t, way, isOpenTicket }) => {
  const { env, features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  const labels = isOpenTicket ? 'choose_seats_open' : 'choose_seats_at_office';
  const imgSrc = nowAllowedImageType[env.brand];
  return (
    <Spacing vertical size="L">
      {isOpenTicket && <Text weight="bold">{t('general:open_ticket')}</Text>}
      <Spacing vertical alignItems="center">
        <When condition={imgSrc}>
          <img className="calendar-bus" src={imgSrc} height="200" width="250" />
        </When>
        <MessageBox borderColor="info" padding="S" hasShadow={!isFlat} hasBorder={isFlat}>
          <Text>
            <Trans t={t} i18nKey={labels} tOptions={{ context: way }} parent="p">
              text<b>text</b>text
            </Trans>
          </Text>
        </MessageBox>
      </Spacing>
    </Spacing>
  );
};

NotAllowedSeats.propTypes = {
  t: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool,
  way: PropTypes.string.isRequired,
};

NotAllowedSeats.defaultProps = {
  isOpenTicket: false,
};

export default NotAllowedSeats;
