// @ts-check

// Type definitions for Kushki.js
/**
 *
 * @typedef {Object} KushkiConfig
 *
 * @property {String} merchantId
 * @property {Boolean} inTestEnvironment
 */

/**
 * @typedef {Object} KushkiBankItem
 * @property {string} name - The bank name.
 * @property {string} code - The bank's code.
 */

/**
 * @typedef {Object} KushkiAmount
 * @property {number} subtotalIva0 - The amount without taxes.
 * @property {number} subtotalIva - The amount with taxes.
 * @property {number} iva - The amount of taxes.
 */

/**
 * @typedef {Object} KushkiCard
 * @property {string} name - The card's holder name.
 * @property {string} number - The card's number.
 * @property {string} cvc - The card's cvc.
 * @property {string} expiryMonth - The card's expiration month MM format.
 * @property {string} expiryYear - The card's expiration year in YY format.
 */

/**
 * @typedef {Object} KushkiCardTokenPayload
 * @property {string} amount - The amount.
 * @property {string} currency - The currency.
 * @property {KushkiCard} card - The card.
 */

/**
 * @typedef {Object} KushkiTransferTokenPayload
 * @property {string} bankId - The bank's code.
 * @property {string} callbackUrl - The url to redirect the user after the payment.
 * @property {string} userType - The user type.
 * @property {string} documentType - The user's document type.
 * @property {string} documentNumber - The user's document number.
 * @property {string} paymentDesc - The payment description.
 * @property {string} email - The user's email.
 * @property {string} currency - The currency.
 * @property {KushkiAmount} amount - The amount.
 */

/**
 * @typedef {Object} KushkiCashTokenPayload
 * @property {string} name - The user's name.
 * @property {string} lastName - The user's last name.
 * @property {string} identification - The user's identification.
 * @property {string} documentType - The user's document type.
 * @property {string} email - The user's email.
 * @property {number} totalAmount - The amount.
 * @property {string} currency - The currency.
 * @property {string} description - The payment description.
 */

const Kushki = () => {
  let kushkiInstance;

  /**
   * Loads Kushki SDK script and creates an instance with the given config
   * @function initialize
   * @param {KushkiConfig} config - the merchantId and inTestEnvironment values
   * @returns {void}
   *
   * @example
   * ```js
   * kushki.initialize({
   *  merchantId: '100000000000123456789',
   *  inTestEnvironment: true,
   * });
   * ```
   */
  const initialize = (config) => {
    const script = document.createElement('script');
    script.src = 'https://cdn.kushkipagos.com/kushki.min.js';
    script.defer = true;
    script.async = false;
    script.id = 'kushki';

    script.onload = () => {
      // @ts-ignore - Kushki is a global variable loaded by the script
      kushkiInstance = new window.Kushki({
        ...config,
      });
    };

    document.head.appendChild(script);
  };

  /**
   * Fetches the list of banks available for PSE payments
   * @async
   * @function fetchPseBanksList
   * @returns {Promise<KushkiBankItem[]>}
   * @throws {Error} if the request fails
   * @example
   * ```js
   * const banks = await kushki.fetchBankList();
   * ```
   */
  const fetchPseBanksList = async () =>
    new Promise((resolve, reject) => {
      kushkiInstance.requestPseBankList((response) => {
        const isResponseWithError = Boolean(response.code);
        if (isResponseWithError) {
          reject(response.message);
        }
        const validBanks = response.filter((bank) => Number(bank.code) !== 0);
        resolve(validBanks);
      });
    });

  /**
   * Creates a token for transfer payments
   * @async
   * @function createTransferToken
   * @param {KushkiTransferTokenPayload} payload - The payload to create the token
   * @returns {Promise<string>}
   * @throws {Error} if the request fails
   * @example
   * ```js
   * const token = await kushki.createTransferToken({
   *  bankId: '100000000000123456789',
   *  callbackUrl: 'https://example.com',
   *  userType: '0',
   *  documentType: 'CC',
   *  documentNumber: '123456789',
   *  paymentDesc: 'Payment description',
   *  email: ''
   *  currency: 'COP',
   *  amount: {
   *    subtotalIva0: 0,
   *    subtotalIva: 10000,
   *    iva: 0,
   *  },
   * });
   * ```
   *
   * @see {@link https://api-docs.kushkipagos.com/docs/libraries/kushkijs}
   */
  const createTransferToken = async (payload) =>
    new Promise((resolve, reject) => {
      kushkiInstance.requestTransferToken(payload, (response) => {
        const isResponseWithError = Boolean(response.code);
        if (isResponseWithError) {
          reject(response.message);
        }
        resolve(response.token);
      });
    });

  /**
   * Creates a token for credit card payments
   * @async
   * @function createCardToken
   * @param {KushkiCardTokenPayload} payload - The payload to create the token
   *
   * @returns {Promise<{token: string}>}
   * @throws {Error} if the request fails
   *
   * @example
   * ```js
   * const token = await kushki.createCardToken({
   *   amount: '10000',
   *   currency: 'COP',
   *   card: {
   *     name: 'John Doe',
   *     number: '4242424242424242',
   *     cvc: '123',
   *     expiryMonth: '12',
   *     expiryYear: '25',
   *   },
   * });
   * ```
   *
   * @see {@link https://api-docs.kushkipagos.com/docs/libraries/kushkijs}
   */
  const createCardToken = async (payload) =>
    new Promise((resolve, reject) => {
      kushkiInstance.requestToken(payload, (response) => {
        const isResponseWithError = Boolean(response.code);
        if (isResponseWithError) {
          reject(response.message);
        }
        resolve(response.token);
      });
    });

  /**
   * Creates a token for cash payments
   * @async
   * @function createCashToken
   * @param {KushkiCashTokenPayload} payload - The config to create the token
   *
   * @returns {Promise<string>}
   * @throws {Error} if the request fails
   *
   * @example
   * ```js
   * const token = await kushki.createCashToken({
   *   name: 'John Doe',
   *   lastName: 'Doe',
   *   identification: '123456789',
   *   documentType: 'CC',
   *   email: '',
   *   totalAmount: 10000,
   *   currency: 'COP',
   *   paymentDescription: 'Payment description',
   * });
   * ```
   *
   * @see {@link https://api-docs.kushkipagos.com/docs/libraries/kushkijs}
   */
  const createCashToken = async (payload) =>
    new Promise((resolve, reject) => {
      kushkiInstance.requestCashToken(payload, (response) => {
        const isResponseWithError = Boolean(response.code);
        if (isResponseWithError) {
          reject(response.message);
        }
        resolve(response.token);
      });
    });

  return { initialize, fetchPseBanksList, createTransferToken, createCardToken, createCashToken };
};

const kushki = Kushki();

export default kushki;
