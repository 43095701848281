/* eslint-disable no-case-declarations */
import { Map } from 'immutable';
import {
  SET_USER_SELECTED_CARD,
  SET_USER_CARDS_LOADING,
  SET_USER_CARDS,
  SET_CARDS_UPDATED,
  SET_CARDS_LOADING,
  SET_CARDS_AVAILABLE,
  SET_CARDS_ANOTHER_CARD,
  SET_SAVE_CARD,
} from 'constants/ActionTypes';

const defaultState = Map({
  cards: [],
  selectedCard: '',
  isLoading: false,
  isUpdated: false,
  payWithAnotherCard: false,
  saveCard: false,
});

export default function cards(state = defaultState, action) {
  switch (action.type) {
    case SET_USER_SELECTED_CARD:
      return state.set('selectedCard', action.card);

    case SET_USER_CARDS_LOADING:
      return state.set('isLoading', action.isLoading);

    case SET_USER_CARDS:
      const { details = [] } = action.cards;
      return state.set('cards', details);

    case SET_CARDS_UPDATED:
      return state.set('isUpdated', action.isUpdated);

    case SET_CARDS_LOADING:
      return state.set('isLoading', action.isLoading);

    case SET_CARDS_AVAILABLE:
      return state.set('isAvailable', action.isAvailable);

    case SET_CARDS_ANOTHER_CARD:
      return state.set('payWithAnotherCard', action.payWithAnotherCard);

    case SET_SAVE_CARD:
      return state.set('saveCard', action.saveCard);

    default:
      return state;
  }
}
