import React from 'react';
import PropTypes from 'prop-types';
import { Currency, Spacing, Text, Tooltip } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { getCurrencyDecimals, getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { isDesktopSize } from 'utils/Reserbus';

/**
 * Extras component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.total - The total prop.
 * @param {string} props.text - The text prop.
 * @returns {JSX.Element} The rendered component.
 */
const Extras = ({ total, text }) => {
  return (
    <Spacing justifyContent="space-between">
      <Text size="XS" color="white">
        {text}
      </Text>

      <Currency
        price={total}
        sign={getCurrencyPrefix()}
        currency={getCurrencySuffix()}
        decimals={getCurrencyDecimals()}
        size="XS"
        weight="bold"
        color="white"
      />
    </Spacing>
  );
};

Extras.propTypes = {
  total: PropTypes.number,
  text: PropTypes.string,
};

/**
 * PricingTooltipDetails component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.luggage - The luggage prop.
 * @param {number} props.networks - The networks prop.
 * @param {number} props.taxes - The taxes prop.
 * @param {number} props.insurance - The insurance prop.
 *
 * @returns {JSX.Element} The rendered component.
 */
const PricingTooltipDetails = ({ luggage, networks, taxes, insurance }) => {
  const { t } = useTranslation('');
  const isMobile = !isDesktopSize();
  return (
    <Tooltip
      type="top"
      horizontal={isMobile ? 'left' : 'right'}
      widthBox={200}
      customContent={
        <Spacing vertical size="XS">
          {luggage >= 0 && <Extras total={luggage} text={t('purchase:luggage')} />}
          {networks >= 0 && <Extras total={networks} text={t('purchase:networks')} />}
          {taxes >= 0 && <Extras total={taxes} text={t('purchase:label.taxes')} />}
          {insurance >= 0 && <Extras total={taxes} text={t('purchase:insurance')} />}
        </Spacing>
      }
    >
      <Text color="primary" underline>
        {t('general:show_details_new')}
      </Text>
    </Tooltip>
  );
};

PricingTooltipDetails.propTypes = {
  luggage: PropTypes.number,
  networks: PropTypes.number,
  taxes: PropTypes.number,
  insurance: PropTypes.number,
};

export default PricingTooltipDetails;
