import React from 'react';
import { Spacing, Text, Icon } from '@reservamos/elements';
import PropTypes from 'prop-types';

/**
 * Displays an icon and city name.
 * @param {object} props - The props for the RouteTripsAlertPrice component.
 * @param {string} props.iconType - Type of icon.
 * @param {string} props.city - Name of the city.
 */
function RouteTripAlertPrice({ iconType, city }) {
  return (
    <Spacing size="XXS" alignItems="center">
      <Icon size="XS" type={iconType} color="grayMedium" />
      <Text color="grayMedium" mobileSize="S">
        {city}
      </Text>
    </Spacing>
  );
}
RouteTripAlertPrice.propTypes = {
  iconType: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

export default RouteTripAlertPrice;
