import React from 'react';
import PropTypes from 'prop-types';
import './MobileBottomContainer.scss';

/**
 * It shows the selected seats
 * @param {*} param0 - Props
 * @param {node} param0.children - Children
 * @returns - MobileBottomContainer component.
 */
const MobileBottomContainer = ({ children }) => {
  return <div className="mobile-bottom-container animated fade-in">{children}</div>;
};

MobileBottomContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MobileBottomContainer;
