import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import OptionsSelector from './OptionsSelector';

const optionToIcon = {
  lowestPrice: 'price',
  duration: 'duration',
  tripCount: 'direct',
};

const SortSelector = ({ onChange, value }) => {
  const { t } = useTranslation('search');

  const options = ['lowestPrice', 'duration', 'tripCount'].map((option) => ({
    title: t('label.selector', { context: option }),
    description: t('label.description.selector', { context: option }),
    icon: optionToIcon[option],
    value: option,
  }));

  return <OptionsSelector onChange={onChange} options={options} value={value} />;
};

SortSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SortSelector.defaultProps = {
  value: null,
};

export default SortSelector;
