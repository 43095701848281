import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Spacing, Text } from '@reservamos/elements';
import PetFriendly from '../../images/badges/pet.svg';
import './BadgePetFriendly.scss';

/**
 * BadgePetFriendly component. It shows the badge for pet friendly on the results page.
 * @returns {JSX.Element} - BadgePetFriendly component.
 */
const BadgePetFriendly = () => {
  const { t } = useTranslation('seats');

  return (
    <div className="badge-petfriendly">
      <Spacing alignItems="center" size="XS">
        <div className="badge-petfriendly-icon">
          <Icon size="S" type={PetFriendly} />
        </div>
        <Text color="primary" elementType="span" size="S" weight="bold" whiteSpace="nowrap">
          {t('pet_counter.badge_results')}
        </Text>
      </Spacing>
    </div>
  );
};

export default BadgePetFriendly;
