import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MercadoPagoInstanceContext from './MercadoPagoInstanceContext';
import useScript from '../../../hooks/useScript';
import mercadoPago from '../../../../payments/core/engines/mercadoPago';

const propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

const mercadoPagoSDKSrc = 'https://sdk.mercadopago.com/js/v2';
const mercadoPagoAntiFraudScriptSrc = 'https://www.mercadopago.com/v2/security.js';

const MercadoPagoInstanceProvider = ({ children, config }) => {
  const [mercadoPagoInstanceIsCreated, setMercadoPagoInstanceIsCreated] = useState(false);
  const { isLoaded: mercadoPagoSDKIsLoaded } = useScript(mercadoPagoSDKSrc, {
    shouldLoadScript: config.enabled,
  });
  useScript(mercadoPagoAntiFraudScriptSrc, {
    shouldLoadScript: config.enabled && mercadoPagoSDKIsLoaded,
  });

  useEffect(() => {
    const { enabled, publicKey } = config;
    if (enabled && mercadoPagoSDKIsLoaded) {
      mercadoPago.initialize(publicKey);
      setMercadoPagoInstanceIsCreated(true);
    }
  }, [config, mercadoPagoSDKIsLoaded]);

  const contextValue = {
    mercadoPagoInstanceIsCreated,
  };

  return (
    <MercadoPagoInstanceContext.Provider value={contextValue}>
      {children}
    </MercadoPagoInstanceContext.Provider>
  );
};

MercadoPagoInstanceProvider.propTypes = propTypes;

export default MercadoPagoInstanceProvider;
