import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Text } from '@reservamos/elements';
import 'ui/layouts/FaresModal';
import 'ui/layouts/FaresResults';
import PropTypes from 'prop-types';
import FareDescription from './FareDescription';

const FaresModal = ({ title }) => {
  const {
    copies: { fares },
  } = useSelector((state) => state.whitelabelConfig);

  return (
    <Modal
      title="Detalles de las tarifas"
      expandedModal
      responsiveSize="L"
      modalTrigger={
        <div className="fares-heading">
          <Text weight="semibold" color="primaryStrong" alignText="center">
            {title}
          </Text>
          <i className="fares-question" />
        </div>
      }
    >
      <div className="fares-description">
        {fares.faresTypes.map((type) => (
          <FareDescription
            key={type.code}
            name={type.name}
            bagsCount={type.bagsCount}
            features={type.features}
          />
        ))}
      </div>

      {Boolean(fares.disclaimer) && (
        <div className="modal-label">
          <Text size="XS" color="grayMedium">
            {fares.disclaimer}
          </Text>
        </div>
      )}
    </Modal>
  );
};

FaresModal.propTypes = {
  title: PropTypes.string,
};

export default FaresModal;
