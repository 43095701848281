import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Box, Icon, Gap, Button } from '@reservamos/elements';
import moment from 'moment';
import PropTypes from 'prop-types';
import { togglePurchaseReviewOverlay } from '@/actions/overlayView';
import './BookingReview.scss';
import { trackEvent } from 'user-analytics';
import { CHECKOUT_DETAILS_CLICKED } from '../../constants/TrackEvents';
import useWhitelabelFeatures from '../../hooks/whitelabel/useWhitelabelFeatures';

const BookingReview = ({
  departureDate,
  returnDate,
  passengers,
  isDepartureOpen,
  isReturnOpen,
}) => {
  const dispatch = useDispatch();
  const features = useWhitelabelFeatures();
  const isFlat = features.FUNNEL_STYLE === 'FLAT';
  const { t } = useTranslation('general');
  const departureText = isDepartureOpen ? t('open_ticket') : moment(departureDate).format('DD MMM');
  const returnText = isReturnOpen ? t('open_ticket') : moment(returnDate).format('DD MMM');
  const hasFewPassengers = passengers.length < 2;

  if (!features.BOOKING_REVIEW_ENABLED) return null;

  const handleOnClick = () => {
    trackEvent(CHECKOUT_DETAILS_CLICKED);
    dispatch(togglePurchaseReviewOverlay(true));
  };

  return (
    <div>
      <Text weight="bold" size="L">
        {t('trip_summary')}
      </Text>

      <div className="booking-review-wrapper">
        <div className="booking-review-hole-left">
          <div className="booking-review-hole-left-fill" />
        </div>
        <div className="booking-review-hole-right">
          <div className="booking-review-hole-right-fill" />
        </div>
        <div className="booking-review-container">
          <Spacing
            isResponsive
            vertical={!hasFewPassengers}
            justifyContent="space-between"
            size="S"
          >
            <Spacing alignItems="center" responsiveSize="S" justifyContent="space-between">
              <Text weight="bold" mobileSize="S">
                {t(returnDate ? 'round_trip' : 'one_way_trip')}
              </Text>
              <Spacing alignItems="center" justifyContent="center" size="S">
                <Gap columnGap="XS" alignItems="center">
                  <Icon size="S" type="CalendarOutlined" />
                  <Text weight="bold" size="S">
                    {t('departure')}
                  </Text>
                  <Text size="S" color="grayMedium">
                    {departureText}
                  </Text>
                </Gap>
                {returnDate && (
                  <Gap columnGap="XS" alignItems="center">
                    <Icon size="S" type="CalendarOutlined" />
                    <Text weight="bold" size="S">
                      {t('return')}
                    </Text>
                    <Text size="S" color="grayMedium">
                      {returnText}
                    </Text>
                  </Gap>
                )}
              </Spacing>
            </Spacing>

            <Spacing alignItems="center" justifyContent="space-between" flexGrow>
              <Gap columnGap="XS" alignItems="center">
                {passengers.map((passenger) => (
                  <Box
                    elementType="div"
                    paddingVertical="XS"
                    paddingHorizontal="S"
                    borderRadius="L"
                    bgColor="accent"
                    alphaBg="S"
                    key={passenger}
                  >
                    <Text weight="bold" size="S" textAlign="center">
                      {`${passenger.firstName} ${passenger.lastName}`}
                    </Text>
                  </Box>
                ))}
              </Gap>
            </Spacing>
          </Spacing>
          <div className="booking-review-bottom">
            <Button
              color="accent"
              iconType="Info"
              iconColor="accent"
              text={t('trip_details')}
              variant="noFill"
              onClick={handleOnClick}
              isRounded={isFlat}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BookingReview.propTypes = {
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  passengers: PropTypes.array.isRequired,
  isDepartureOpen: PropTypes.bool,
  isReturnOpen: PropTypes.bool,
};

BookingReview.defaultProps = {
  isDepartureOpen: null,
  isReturnOpen: null,
};

export default BookingReview;
