import React, { useState, useEffect } from 'react';
import { Box, Link, Spacing, Text } from '@reservamos/elements';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { trackEvent } from 'user-analytics';
import { DOWNLOAD_APP_BANNER_PRESSED } from 'constants/TrackEvents';
import { useTranslation } from 'react-i18next';

/**
 * AppBanner component displays a banner prompting users to download the mobile app,
 * with platform-specific links to the iOS App Store or Google Play Store.
 * @component
 * @returns {JSX.Element} A box container with the app banner, including an icon, text, and a download link.
 */
const AppBanner = () => {
  const { smartBanner } = useWhitelabelEnvs();
  const { t } = useTranslation('purchase');
  const [platform, setPlatform] = useState('android'); // Default to Android
  const storeLinks = {
    ios: smartBanner.props[`button-url-apple`],
    android: smartBanner.props[`button-url-google`],
  };

  /**
   * Handles the click event on the download link, tracking the event for analytics.
   *
   * @function
   * @returns {void}
   */
  const handleOnClick = () => {
    trackEvent(DOWNLOAD_APP_BANNER_PRESSED);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPlatform('ios');
    }
    // If not iOS, it will remain 'android'
  }, []);

  return (
    <Box border="all" borderRadius="M" paddingHorizontal="S" paddingVertical="S">
      <Spacing alignItems="center" size="S">
        <img
          className="rounded-lg shadow-md"
          src={smartBanner.props[`icon-apple`]}
          alt="appicon"
          height="35"
          width="35"
        />
        <Text weight="semibold" size="S">
          {t('app_banner.message')}
        </Text>
        <Link
          text={t('app_banner.cta')}
          href={platform === 'ios' ? storeLinks.ios : storeLinks.android}
          type="primary"
          newTab
          onClick={handleOnClick}
        />
      </Spacing>
    </Box>
  );
};

export default AppBanner;
