import { Map } from 'immutable';
import { camelizeKeys } from 'humps';
import { setConfig as setApiConfig } from 'js-api-client';
import Cookies from 'js-cookie';
import * as types from '../constants/ActionTypes';

const initialState = Map({
  profile: null,
  isFetching: false,
  requested: false,
  logged: false,
});

/**
 * Reducer create to control the costapass state
 * @param {object} state costapass state (profile, error, isFetching)
 * @param {object} action value of the action passed
 * @returns state
 */
export default function costapass(state = initialState, action) {
  switch (action.type) {
    case types.COSTAPASS_RECEIVE_PROFILE: {
      const { profile, anotherLoyaltyActivaded } = action.data;
      const cookieToken = Cookies.get('costapassToken');

      if (profile) {
        // logged in

        // set userAuthToken in js-api-client if user is at purchase page
        if (window && window.location.href.includes('purchase'))
          setApiConfig({ userAuthToken: profile.token });

        const camelizedProfile = camelizeKeys(profile);
        // sets costapass profile in the store
        return state
          .set('profile', {
            ...camelizedProfile,
            firstName: camelizedProfile.name,
            balance: profile.balance,
            loyaltyName: action.data.loyaltyName,
          })
          .set('isFetching', false)
          .set('requested', true)
          .set('logged', true);
      }
      // logged out

      // remove userAuthToken in js-api-client only is there is not another loyalty activated
      if (!anotherLoyaltyActivaded) setApiConfig({ userAuthToken: undefined });

      // sets costapass profile in the store
      return state
        .set('profile', null)
        .set('isFetching', false)
        .set('requested', !profile ? !cookieToken : true)
        .set('logged', false);
    }
    case types.COSTAPASS_UPDATE_BALANCE:
      return state.setIn(['profile'], { balance: action.balance });
    case types.COSTAPASS_ERROR:
      return state.set('error', action.errorCode);
    default:
      return state;
  }
}
