import { useEffect } from 'react';
import Cookies from 'js-cookie';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { REDIRECT_LOGIN_KEY } from 'constants/sso';

/**
 * Redirects the user to the previous url after login with SSO
 * */
const LoginPage = () => {
  const { homeUrl, siemprePlus } = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();

  useEffect(() => {
    let redirectPath = localStorage.getItem(REDIRECT_LOGIN_KEY);
    localStorage.removeItem(REDIRECT_LOGIN_KEY);

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    if (!redirectPath) {
      let redirectURL = `/?token=${token}`;
      if (features.SSO_LOGIN_REDIRECT_LANDING) {
        const landingParseURL = new URL(homeUrl);
        Cookies.set('token', token, {
          domain: landingParseURL.hostname,
          expires: siemprePlus.cookieExpiration,
        });
        redirectURL = `${homeUrl}?token=${token}`;
      }
      window.location.href = redirectURL;
      return;
    }
    const redirectURLSearch = new URL(redirectPath).search;

    if (token) redirectPath += redirectURLSearch ? `&token=${token}` : `?token=${token}`;

    window.location.href = redirectPath;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LoginPage;
