import { List, Map } from 'immutable';
import availablePaymentEngines from 'constants/PaymentEngine';

const DefaultPurchase = () => {
  let defaultPurchaseInstance;

  const createInstance = ({ selectedPaymentOption, selectedPaymentMethod }) => {
    if (defaultPurchaseInstance) return;
    defaultPurchaseInstance = Map({
      isExpired: false,
      isFetching: true,
      isFetchingRedirect: false,
      isUpdating: false,
      isFetchingDetails: false,
      isLockingTickets: false,
      hasDetails: false,
      loaded: false,
      hasSelectedSeats: false,
      hasTickets: false,
      ticketsErrorType: null,
      lastUpdated: null,
      savingPassengers: false,
      updatingInsurance: false,
      applyingDiscount: false,
      updatingWallet: false,
      insurancesChecked: false,
      token: '',
      state: 'attempt',
      availablePayments: List([]),
      purchaserPaymentCards: Map({}),
      roundTrip: false,
      departs: Map({}),
      returns: Map({}),
      selectedPaymentOption,
      selectedInstallmentsCard: 'visaMc',
      selectedPlan: 'singlePayment',
      monthlySelectedPlan: 1,
      selectedInstallmentsPlan: Map({
        card: '',
        months: 1,
        total: 0,
        monthlyPayment: 0,
      }),
      sentEmailStatus: '',
      isExchange: false,
      paymentEngine: availablePaymentEngines.openPay,
      selectedPaymentMethod,
      isUpdatingFlatFare: false,
    });
  };

  const getInstance = () => {
    if (!defaultPurchaseInstance) {
      throw new Error('DefaultPurchase instance is not initialized');
    }
    return defaultPurchaseInstance;
  };

  return {
    createInstance,
    getInstance,
  };
};

const defaultPurchase = DefaultPurchase();

export default defaultPurchase;
