export default function isSearchPolling(search, departures, returns, roundTrip) {
  if (roundTrip && (!departures || !returns)) {
    return true;
  }
  if (!roundTrip && !departures) {
    return true;
  }

  let isPolling = departures.getIn(['buses', 'isPolling']);
  isPolling = isPolling || departures.getIn(['flights', 'isPolling']);

  if (roundTrip) {
    isPolling = isPolling || returns.getIn(['buses', 'isPolling']);
    isPolling = isPolling || returns.getIn(['flights', 'isPolling']);
  }

  return Boolean(isPolling);
}
