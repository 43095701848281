import { useState } from 'react';

/**
 * Custom hook for handling PQRS (Petitions, Queries, Requests, and Suggestions) form submissions and tracking.
 * @returns {object} An object containing:
 * - `isLoading`: A boolean indicating if a request is currently being processed.
 * - `response`: The response data from the last request made. This could be a success message or tracking information.
 * - `submitRequest`: A function to submit a new PQRS request. It takes a data object as a parameter.
 * - `getTracking`: A function to get the tracking information for a PQRS request. It takes a trackingCode string as a parameter.
 */
const usePqrs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState('');

  /**
   * Submits a new PQRS request.
   * @param {object} data - The data object for the PQRS request.
   */
  const submitRequest = (data) => {
    setIsLoading(true);
    fetch('/api/pqrs-ochoa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setResponse(data.message);
      })
      .catch((error) => {

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Retrieves tracking information for a PQRS request.
   * @param {string} trackingCode - The tracking code for the request.
   */
  const getTracking = (trackingCode) => {
    setIsLoading(true);
    fetch(`/api/pqrs-ochoa?trackingCode=${trackingCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setResponse(data);
      })
      .catch((error) => {

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    response,
    submitRequest,
    getTracking,
  };
};

export default usePqrs;
