import { flatten } from 'lodash';
import { CurrentLang } from 'utils/lang';

function formatPlace({ id, name, cityId, cityName, countryName }) {
  return {
    id,
    name,
    city: {
      id: cityId,
      name: cityName,
      state: {
        country: countryName,
      },
    },
  };
}

function getBusProperties({ line, stops }) {
  const { name, allowsSeatSelection, ally, copyrightProtected, transporterName } = line;

  return {
    carrier: {
      name,
      allowsSeatSelection,
      ally,
      copyrightProtected,
      buslineGroup: {
        name: transporterName,
      },
    },
    stops,
  };
}

function airlineProperties(carrier) {
  const { name, allowsSeatSelection = false, ally, copyrightProtected = false } = carrier;

  return { name, allowsSeatSelection, ally, copyrightProtected };
}

function getFlightProperties({ legs, carrier }) {
  return {
    carrier: airlineProperties(carrier),
    stops: legs.length - 1,
    legs: legs.map(({ carrier, origin, destination, departure, arrival }) => ({
      carrier: airlineProperties(carrier),
      origin: formatPlace(origin),
      destination: formatPlace(destination),
      departure,
      arrival,
    })),
  };
}

function serializeFragment(fragment) {
  const { transportType, origin, destination, departure, arrival, pricing } = fragment;
  let transportProperties;

  if (transportType === 'bus') {
    transportProperties = getBusProperties(fragment);
  } else {
    transportProperties = getFlightProperties(fragment);
  }

  return {
    transportType,
    // carrier (transportProperties)
    origin: formatPlace(origin),
    destination: formatPlace(destination),
    departure,
    arrival,
    pricing,
    // stops (transportProperties)
    // legs (transportProperties)
    ...transportProperties,
  };
}

export default function serializeReservation(reservation, way) {
  if (!reservation.fragments) return null;

  const { tripSlug, fragments, departure, arrival, tickets, pricing } = reservation;
  const origin = formatPlace(fragments[0].origin);
  const destination = formatPlace(fragments.slice(-1)[0].destination);
  const serializedFragments = fragments.map((fragment) => serializeFragment(fragment));

  return {
    id: tripSlug,
    fragments: serializedFragments,
    origin,
    destination,
    departure,
    arrival,
    pricing,
    tickets: flatten(tickets),
    openTicket: reservation.fragments.some((fragment) => fragment.openTicket),
    way,
    lang: CurrentLang({ shouldReadDefaultLanguageFromStore: false }),
  };
}
