import moment from 'moment';
import i18n from 'i18next';
import { getCurrencyPrefix } from 'utils/currency';
import store from '@/store';

/**
 * Returns true if the passed value is null or undefined.
 * ```javascript
 * isNone();              // true
 * isNone(null);          // true
 * isNone(undefined);     // true
 * isNone('');            // false
 * isNone([]);            // false
 * isNone(function() {}); // false
 * ```
 * @method isNone
 * @param {Object} obj Value to test
 * @return {Boolean}
 */
export function isNone(obj) {
  return obj === null || obj === undefined || obj === '';
}

/**
 * Returns a formated duration, in short or long format, from minutes
 * If the trip lasts less than an hour (i.e. 10 minutes):
 *  - 10 minutos (long)
 *  - 10 min (short)
 * If the trip lasts hours without minutes (i.e. 10 hours):
 *  - 10 horas (long)
 *  - 10 hrs (short)
 * If the trip lasts hours with minutes (i.e. 10 hours 20 minutes):
 *  - 10 hrs 20 min (long)
 *  - 10 h 20 m (short)
 *
 * @method duration
 * @param {Number} duration Trip duration in minutes
 * @param {String} format Duration format (short | long)
 * @return {String}
 */

export function formatDuration(duration, format = 'long') {
  const d = moment.duration(duration, 'm');
  const hours = d.asHours();
  const minutes = d.minutes();
  let formats = {
    aloneMinutes: i18n.t('common:time.minutes'),
    aloneHour: i18n.t('common:time.hour'),
    minutes: 'min',
    hour: 'hr',
  };
  let formatString;

  if (format === 'short') {
    formats = { aloneMinutes: 'min', aloneHour: 'hr', minutes: 'm', hour: 'h' };
  }

  if (hours < 1) {
    formatString = `mm [${formats.aloneMinutes}]`;
  } else if (minutes < 1) {
    formatString = `h [${formats.aloneHour}${hours !== 1 ? 's' : ''}]`;
  } else if (hours < 2) {
    formatString = `h [${formats.hour}] m [${formats.minutes}]`;
  } else {
    formatString = `h [${formats.hour}s] m [${formats.minutes}]`;
  }

  // FIXME this is patch for a moment translation bug
  return d.format(formatString).replace('minsutos', 'minutos');
}

/**
 * Returns the number passed to the function formated as a currency
 *
 * @method formatCurrency
 * @param {Number} num Number to format
 * @return {String}
 */
export function formatCurrency(num, decimals) {
  const formatted = Number(num)
    .toFixed(decimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${getCurrencyPrefix()}${formatted}`;
}

/**
 * Returns whether the app is running on an iOS device
 *
 * @method isIos
 * @return {Boolean}
 */
export function isIos() {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator && window.navigator.userAgent;

    return !!(userAgent && userAgent.match(/iPhone|iPad|iPod/i));
  }
  return false;
}

export function dasherize(string) {
  return string.toLowerCase().replace(/\s/g, '-');
}

/**
 * Removes noise from the lodging provider string.
 * Returns `none` if there is no match in the string or if argument is undefined or null.
 * Regex is case insensitive. Will return match with lowercase letters.
 * @method sanitizeLodgingProvider
 * @param {String} string String to sanitize
 * @return {String}
 */
export function sanitizeLodgingProvider(string) {
  let lodgingProvider = 'none';

  if (string) {
    const match = string.match(/airbnb|none/gi);
    if (match) {
      lodgingProvider = match[0].toLowerCase();
    }
  }

  return lodgingProvider;
}

/**
 * Returns the method passed to the function to format as a percent
 * @param {('FLOOR'|'CEIL'|'ROUND')} method - method to round the percent
 * @returns - round method
 */
const roundMethod = (method) => {
  if (method === 'FLOOR') {
    return Math.floor;
  }
  if (method === 'CEIL') {
    return Math.ceil;
  }
  return Math.round;
};

/**
 * Formats a percent.
 * @method formatPercent
 * @param {Number} den denominator of percent
 * @param {Number} num numerator of percent
 * @param {('FLOOR'|'CEIL'|'ROUND')} method - method to round the percent
 * @return {String} formatted percent
 *  * @example
 * ```javascript
 * // returns '0%'
 * formatPercent(100, 100);
 * // returns '100%'
 * formatPercent(100, 0);
 * // returns '10%'
 * formatPercent(435, 392);
 * // returns '9%'
 * formatPercent(435, 392, 'FLOOR');
 * ```
 */
export function formatPercent(den, num, method = 'ROUND') {
  if (!num) {
    return '0%';
  }

  const formatted = roundMethod(method)((1 - num / den) * 100);

  return `${formatted}%`;
}

export const getTripDiscountPercent = ({ tripTotal, discountAmount, way }) => {
  const { features } = store.getState().whitelabelConfig;
  const discountRoundMethod =
    way === 'return' ? features.DISCOUNT_LABEL_ROUND_METHOD_ON_RETURN : undefined;
  const windowTicketPrice = tripTotal + discountAmount;

  const discountPercent = discountAmount
    ? formatPercent(windowTicketPrice, tripTotal, discountRoundMethod)
    : null;

  return discountPercent;
};
