import React from 'react';
import { useSelector } from 'react-redux';
import DefaultTripItinerary from './TripItinerary';
import TripItineraryFlat from './flat/TripItinerary';

const TripItinerary = (props) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const IS_FLAT = features.FUNNEL_STYLE === 'FLAT';
  const TripItineraryToRender = IS_FLAT ? TripItineraryFlat : DefaultTripItinerary;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TripItineraryToRender {...props} />;
};

export default TripItinerary;
