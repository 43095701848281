import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from 'components/Header';
import BrandLogo from '../BrandLogo';
import 'styles/components/home/Home';
import Footer from '../../ui/atoms/Footer';
import FaresResults from '../search/FaresResults';
import FaresHeader from '../search/FaresHeader';
import SearchWidget from '../../ui/atoms/SearchWidget';

const Home = () => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('general');
  // Fares functionality is not implemented yet.
  // In order to not show this section in home, we set this to false
  // A real implementation should be something like this:
  // const showDFares = Object.keys(fares).length
  // fares are read from whitelabelConfig.copies.fares
  const showFares = false;

  return (
    <div className="l-app-home">
      <Header isFixed>
        <BrandLogo />
      </Header>

      <div className="home-container">
        <h1 className="home-title" data-testid="home-title">
          {t('title')}
        </h1>

        <SearchWidget />
        {showFares && (
          <>
            {/* Provisional place for development, pending functionality */}
            <FaresHeader />
            <FaresResults variableDepartureTime />
          </>
        )}
      </div>
      {features.SHOW_SEARCH_FOOTER && <Footer />}
    </div>
  );
};

export default Home;
