import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Icon, Spacing, Text } from '@reservamos/elements';

const InsuranceModal = () => {
  const { t } = useTranslation('insurance');
  return (
    <Modal
      title={t('protected_traveler_insurance')}
      modalTrigger={
        <Spacing size="XS" alignItems="center">
          <Text weight="semibold">{t('protected_traveler')}</Text>
          <Icon type="badgeQuestion" size="S" />
        </Spacing>
      }
    >
      <div className="insurance-table">
        <Text weight="semibold">{t('insurances')}</Text>
        <Text weight="semibold">{t('amounts')}</Text>
        <Text size="S">{t('accidental_death')}</Text>
        <Text size="S">$25,000.00</Text>
        <Text size="S">{t('disability')}</Text>
        <Text size="S">$15,000.00</Text>
        <Text size="S">{t('lost_baggage')} </Text>
        <Text size="S">$2,000.00</Text>
        <Text size="S">{t('protected_bag')}</Text>
        <Text size="S">$5,000.00</Text>
        <Text size="S">{t('funerary_asistance')}</Text>
        <Text size="S">{t('up_to_amount', { amount: '20,000.00' })}</Text>
        <Text size="S">{t('medical_asistante')}</Text>
        <Text size="S">{t('up_to_amount', { amount: '20,000.00' })}</Text>
      </div>
    </Modal>
  );
};

export default InsuranceModal;
