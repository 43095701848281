import { useContext } from 'react';
import LoyaltyProgramsContext from './LoyaltyProgramsContext';

const useLoyaltyPrograms = () => {
  const contextValue = useContext(LoyaltyProgramsContext);
  if (!contextValue) {
    throw new Error('useLoyaltyPrograms must be used within a LoyaltyProgramsProvider');
  }
  return contextValue;
};

export default useLoyaltyPrograms;
