import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDuration, formatPercent } from 'utils/Helpers';
import { ProviderLogo, Spacing, Text, Button, Currency, BadgeRounded } from '@reservamos/elements';
import '../../ui/layouts/MatrixResult';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

const ResultLine = ({ onSelectClick, provider, departuresDescription, isOpenTicket }) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const features = useWhitelabelFeatures();
  const { t } = useTranslation();

  const { logoUrl, duration, tripCount, lowestPrice, providerDiscount, service } = provider;
  const discountPercent = providerDiscount
    ? formatPercent(lowestPrice + providerDiscount.amount, lowestPrice)
    : null;

  return (
    <div className="result-box-container animated fade-in">
      <div className="js-mobile-click" onClick={onSelectClick} />
      <div className={`matrix matrix-line ${isOpenTicket && 'matrix-line-openticket'}`}>
        {providerDiscount && features.DISCOUNT_BADGE_RESULT_ENABLED && (
          <div className="matrix-right-corner">
            <BadgeRounded
              text={`-${discountPercent}`}
              firstColor="discountLight"
              secondColor="discountStrong"
            />
          </div>
        )}

        <div className="matrix-provider">
          {isOpenTicket ? (
            <Spacing size="M">
              <ProviderLogo isSmall name={env.brand} imgSrc={logoUrl} />
              <ProviderLogo isSmall name={env.brand} imgSrc={logoUrl} />
              <ProviderLogo isSmall name={env.brand} imgSrc={logoUrl} />
            </Spacing>
          ) : (
            <ProviderLogo name={env.brand} imgSrc={logoUrl} />
          )}
        </div>

        <div className="matrix-duration">
          <Text size="S" color="grayLight" weight="bold">
            {formatDuration(duration)}
          </Text>
        </div>

        {!isOpenTicket && (
          <>
            <div className="matrix-service">
              <Text size="S" color="grayLight" weight="bold">
                {service}
              </Text>
            </div>
            <div className="matrix-service-label">
              <Text size="S" color="grayLight">
                {t('search:service')}
              </Text>
            </div>
            <div className="matrix-departures-label">
              <Text size="S" color="grayLight">
                {t('search:label.selector_departures', { count: tripCount })}
              </Text>
            </div>
            <div className="matrix-departures">
              <Text size="S" color="grayLight" weight="bold">
                {departuresDescription}
              </Text>
            </div>
          </>
        )}

        <div className="matrix-duration-label">
          <Text size="S" color="grayLight">
            {t('general:period')}
          </Text>
        </div>

        <div className="matrix-price">
          <Currency
            price={lowestPrice}
            decimals={env.tripPriceDecimals || 0}
            color={providerDiscount ? 'discount' : 'primary'}
            currency={getCurrencySuffix()}
            sign={getCurrencyPrefix()}
            size="XL"
            mobileSize="L"
            weight="bold"
          />
        </div>

        <div className="matrix-info">
          <Text size="XXS" color="grayLight">
            <em>{t('purchase:label.open_ticket_adult_price')}</em>
          </Text>
        </div>

        <div className="matrix-action">
          <Button text={t('general:select')} onClick={onSelectClick} variant="accent" isRounded />
        </div>
      </div>
    </div>
  );
};

ResultLine.propTypes = {
  onSelectClick: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  departuresDescription: PropTypes.string.isRequired,
  isOpenTicket: PropTypes.bool,
};

ResultLine.defaultProps = {
  isOpenTicket: false,
};

export default ResultLine;
