import store from '@/store';
import findTripFromAllSearches from 'utils/trips/findTripFromAllSearches';
import { showTripDetailsTracker } from 'metrics/user-analytics/trip';
import { connect } from 'react-redux';
import { getTripDetails } from '@/actions/trip';
import DetailsButton from './DetailsButton';

const mapStateToProps = (state, ownProps) => {
  const { tripsDetails } = state;
  const { tripId } = ownProps;
  const loadingTripId = tripsDetails.get('loadingTripId');

  return {
    isLoading: tripId === loadingTripId,
    isDisabled: Boolean(loadingTripId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  showDetails(tripId, position) {
    const { trips } = store.getState();
    const { trip } = findTripFromAllSearches(trips, tripId);
    showTripDetailsTracker(trip.toJS(), position);
    dispatch(getTripDetails(tripId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsButton);
