import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, Tabs, Tab, Spacing, Alert } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import EarlyDiscounts from '../EarlyDiscounts';
import AvailabilityDiscounts from '../AvailabilityDiscounts';
import 'styles/components/search/ProviderInfo';
import ResultItinerary from '../ResultItinerary';
import Amenity from '../../../ui/atoms/Amenity';

/**
 * Renders the provider information.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children.
 * @param {string[]} props.amenities - The list of amenities.
 * @param {number} props.initialTab - The initial active tab.
 * @param {Object[]} props.availabilityCategories - The availability categories.
 * @param {Object[]} props.providerDiscounts - The provider discounts.
 * @param {string} props.tripId - The trip ID.
 * @param {boolean} props.isOnTheWay - Indicates if the trip is on the way.
 * @returns {JSX.Element} The rendered component.
 */
const ProviderInfo = ({
  amenities,
  availabilityCategories,
  initialTab,
  providerDiscounts,
  children,
  tripId,
  isOnTheWay,
}) => {
  const { t } = useTranslation('search');
  const { SHOW_DISCOUNTS_ON_RESULTS_DETAILS } = useWhitelabelFeatures();
  const { brand } = useWhitelabelEnvs();
  const sortedAvailabilityCategories = availabilityCategories.sort((a, b) => a.total - b.total);
  const sortedEarlyDiscounts = providerDiscounts.sort((a, b) => a.total - b.total);

  const modifiedCategories = sortedAvailabilityCategories.map((c) => {
    if (c.type === 'general' && sortedEarlyDiscounts.length > 0) {
      const maxDiscount = sortedEarlyDiscounts[sortedEarlyDiscounts.length - 1];
      c.total = maxDiscount.total;
    }

    return c;
  });

  return (
    <Modal
      responsiveSize="L"
      portalSelector=".portalModal"
      fixedHieght
      removeContentPadding
      title={t('title.trip_details', { context: brand })}
      modalTrigger={children}
      zIndex={2999999999}
    >
      <Tabs headerGrow active={initialTab}>
        <Tab label={t('tab.itinerary')} iconType="route">
          <Spacing vertical>
            {isOnTheWay && (
              <Alert
                alertType="info"
                title={t('trip_service_on_the_way', {
                  context: 'details',
                })}
                padding="S"
                titleMobileSize="M"
                iconSize="S"
                weightTitle="semibold"
              />
            )}
            <ResultItinerary tripId={tripId} />
            <Text weight="bold">{t('label.amenities_and_services')}</Text>
            <Spacing vertical alignItems="flex-start" size="XS">
              {amenities.map((amenity) => (
                <Amenity key={amenity} type={amenity} />
              ))}
            </Spacing>
          </Spacing>
        </Tab>
        {SHOW_DISCOUNTS_ON_RESULTS_DETAILS && (
          <Tab label={t('tab.discounts')} iconType="discountTag">
            <Spacing isResponsive size="L" vertical>
              <AvailabilityDiscounts availabilityCategories={modifiedCategories} />
              {Boolean(sortedEarlyDiscounts.length) && (
                <EarlyDiscounts discounts={sortedEarlyDiscounts} />
              )}
            </Spacing>
          </Tab>
        )}
      </Tabs>
    </Modal>
  );
};

ProviderInfo.propTypes = {
  children: PropTypes.node.isRequired,
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialTab: PropTypes.number.isRequired,
  availabilityCategories: PropTypes.array,
  providerDiscounts: PropTypes.array,
  tripId: PropTypes.string.isRequired,
  isOnTheWay: PropTypes.bool,
};

export default ProviderInfo;
