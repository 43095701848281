import { Text } from '@reservamos/elements';
import React from 'react';
import 'styles/components/search/ResultsHorizontalLegend';

const ResultsHorizontalLegend = () => (
  <div className="results-legend-table">
    <div className="results-legend-table-wrapper">
      <Text size="XS" italic>
        Salida
      </Text>
      <Text size="XS" italic>
        Duración
      </Text>
      <Text size="XS" italic>
        Llegada
      </Text>
      <Text size="XS" italic>
        Línea
      </Text>
      <Text size="XS" italic>
        Servicio
      </Text>
      <Text size="XS" italic>
        Escalas
      </Text>
      <Text size="XS" italic>
        Autobús
      </Text>
    </div>
  </div>
);

export default ResultsHorizontalLegend;
