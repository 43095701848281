import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, Currency } from '@reservamos/elements';
import PropTypes from 'prop-types';
import '../../ui/layouts/FaresResults';
import IconNotice from 'components/IconNotice';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
/* import { useTranslation } from 'react-i18next'; */
import amenityClassName from 'components/modal/Amenities';
import DetailsButton from 'components/search/DetailsButton';
import FareItem from './FareItem';

const FaresResults = ({
  duration,
  arrival,
  departure,
  origin,
  destination,
  total,
  /* selectFare, */
  amenities,
  /*   tripId,
  position,  */
  variableDepartureTime,
}) => {
  const {
    copies: { fares },
  } = useSelector((state) => state.whitelabelConfig);
  const [faresVisibles, setFaresVisibles] = useState(true);
  const faresDetailsClassNames = faresVisibles
    ? 'fares-arrow arrow-down'
    : 'fares-arrow arrow-down fares-arrow__hiden';

  return (
    <div className="fares-grid">
      <div className="fares-details">
        <div className="fares-details-top">
          <div>
            <Text elementType="span" weight="semibold" mobileSize="S">
              {departure}
            </Text>
            <Text elementType="span" size="S" color="grayLight">{` — ${arrival}`}</Text>
            {variableDepartureTime && (
              <IconNotice iconLabel="Corrida de paso" variant="departure">
                <p className="tooltip-paragraph">La hora de salida puede variar</p>
              </IconNotice>
            )}
            <Text color="grayLight" size="S" mobileSize="XS">
              {duration}
            </Text>
          </div>
          <div>
            <Text color="grayStrong" weight="semibold" size="S" mobileSize="XS">
              Servicio Primera Clase
            </Text>
            <DetailsButton /* tripId={tripId} position={position}  */ />
          </div>
        </div>

        <div className="fares-details-top">
          <div className="result-box-summary1">
            <div className="result-box-terminals">
              <Text size="XS" color="grayLight" mobileSize="XS">
                {origin}
              </Text>
              <Text size="XS" color="grayLight" mobileSize="XS">
                {destination}
              </Text>
            </div>
          </div>

          <div className="fares-details-amenities">
            {amenities.map((amenity) => (
              <i key={amenity} className={amenityClassName(amenity)} />
            ))}
          </div>
        </div>
      </div>

      <div className="fares-item mobile discount">
        <Text size="S" color="grayMedium">
          Precios desde
        </Text>
        <div className="price">
          <Currency
            size="XL"
            price={total}
            responsiveSize="XXL"
            color="discount"
            weight="bold"
            decimals={0}
            currency={getCurrencySuffix()}
            sign={getCurrencyPrefix()}
          />
          <Text color="discount" size="S">
            MXN
          </Text>
        </div>
        <button className="button" onClick={() => setFaresVisibles(!faresVisibles)}>
          <i className={faresDetailsClassNames} />
        </button>
      </div>
      {faresVisibles && (
        <>
          {fares?.faresTypes?.map((type) => (
            <FareItem
              key={type.code}
              id={type.code}
              code={type.code}
              availability="1 disponible" /* {type.availability}  */
              total={total}
              selectFare="123" /* {selectFare} */
            />
          ))}
        </>
      )}
    </div>
  );
};

FaresResults.propTypes = {
  duration: PropTypes.string,
  arrival: PropTypes.string,
  departure: PropTypes.string,
  origin: PropTypes.string,
  destination: PropTypes.string,
  total: PropTypes.number,
  // selectFare: PropTypes.func.isRequired,
  amenities: PropTypes.arrayOf(PropTypes.string),
  // tripId: PropTypes.string.isRequired,
  // position: PropTypes.number.isRequired,
  variableDepartureTime: PropTypes.bool.isRequired,
};

FaresResults.defaultProps = {
  amenities: [],
  duration: '3 horas',
  arrival: '09:30 AM',
  departure: '01:30 PM',
  origin: 'México Auditorio Nacional',
  destination: 'León Centro Max, Hotsson Smart',
  total: 400,
};

export default FaresResults;
