import { connect } from 'react-redux';
import PaymentStatusTitle from './PaymentStatusTitle';

function parseTitle(status, type, paymentStatus, paymentProvider, paymentEngine) {
  switch (status) {
    case 'attempt':
      if (paymentProvider === 'pix') return 'pending';
      if (type === 'paypal') return 'pending';
      if (type === 'third_party') return 'pending';
      if (paymentEngine === 'evertec' && paymentStatus !== 'failed') return 'process';
      return status;
    case 'pending':
      if (paymentStatus === 'charged') return 'pendingCompleted';
      if (type === 'credit_card' || type === 'paypal' || type === 'transfer') return 'completed';
      return status;

    default:
      return status;
  }
}

const mapStateToProps = ({ purchase, payment }) => {
  const purchaseStatus = purchase.get('state') || payment.get('purchaseState');
  const purchaseEmail = purchase.get('email');
  const paymentStatus = payment.get('status');
  const paymentProvider = payment.get('paymentProvider');
  const paymentType = payment.get('paymentType') || 'credit_card';
  const isExchange = purchase.get('isExchange');
  const total = purchase.get('total');
  const paymentEngine = payment.get('paymentEngine');

  return {
    titleStatus: parseTitle(
      purchaseStatus,
      paymentType,
      paymentStatus,
      paymentProvider,
      paymentEngine,
    ),
    isExchange,
    purchaseEmail,
    total,
  };
};

export default connect(mapStateToProps)(PaymentStatusTitle);
