import React from 'react';
import PropTypes from 'prop-types';
import './WhiteBar.scss';

const WhiteBar = ({ children, isFixed }) => (
  <>
    {isFixed && <div className="whitebar-block" />}
    <div className={`whitebar ${isFixed ? 'whitebar-fixed' : ''}`}>
      <div className="l-container">{children}</div>
    </div>
  </>
);

WhiteBar.propTypes = {
  children: PropTypes.node.isRequired,
  isFixed: PropTypes.bool,
};

WhiteBar.defaultProps = {
  isFixed: false,
};

export default WhiteBar;
