import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/StarRating';

const propTypes = {
  rating: PropTypes.number,
  size: PropTypes.string,
  grayScale: PropTypes.bool,
};

const defaultProps = {
  rating: 0,
  size: 'medium',
  grayScale: false,
};

const StarRating = ({ rating, size, grayScale }) => {
  if (rating === 0) return null;

  const decimalIndex = rating.toString().indexOf('.');
  const stars = [];
  let key = 1;

  for (let i = Math.floor(rating) - 1; i >= 0; i -= 1, key += 1) {
    stars.push(<i key={key} className={`stars ${size} ${grayScale ? 'gray' : ''}`} />);
  }

  if (decimalIndex > 0) {
    const decimalValue = rating.toString().substr(decimalIndex + 1);

    if (decimalValue > 7) {
      stars.push(<i key={key} className={`stars ${size} ${grayScale ? 'gray' : ''}`} />);
    } else if (decimalValue > 2) {
      stars.push(<i key={key} className={`stars ${size} ${grayScale ? 'gray' : ''} half`} />);
    }
    key += 1;
  }

  for (let i = 4 - stars.length; i >= 0; i -= 1, key += 1) {
    stars.push(<i key={key} className={`stars ${size} ${grayScale ? 'gray' : ''} empty`} />);
  }

  return <div className="rating-stars">{stars}</div>;
};

StarRating.propTypes = propTypes;
StarRating.defaultProps = defaultProps;

export default StarRating;
