import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SimpleModal from '../../../portals/SimpleModal';
import ModalContent from '../../../ui/molecules/ModalContent';
import { formatCurrency } from '../../../utils/Helpers';
import Button from '../../../ui/atoms/Button';
import ContentRow from '../../../ui/layouts/ContentRow';

const propTypes = {
  total: PropTypes.number.isRequired,
  totalBeforeLock: PropTypes.number.isRequired,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  originId: PropTypes.string.isRequired,
  destinationId: PropTypes.string.isRequired,
  hasDiscount: PropTypes.bool.isRequired,
  walletBalanceUsed: PropTypes.number.isRequired,
  encodedPassengersSelection: PropTypes.string.isRequired,
};

const PriceChangedModal = ({
  total,
  origin,
  destination,
  totalBeforeLock,
  departure,
  originId,
  destinationId,
  hasDiscount,
  walletBalanceUsed,
  encodedPassengersSelection = 'A1',
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const history = useHistory();
  const { t } = useTranslation('purchase');
  const [visible, setVisible] = useState(null);
  const closeModal = () => setVisible(false);

  const handleGotoSearch = () => {
    const date = moment(departure).format('DD-MMM-YY');
    history.replace(
      `/search/${originId}/${destinationId}/${date}/p/${encodedPassengersSelection}/providers`,
    );
  };
  if (
    !features.PRICE_CHANGED_MODAL_ENABLED ||
    !(visible === null && totalBeforeLock > 0 && walletBalanceUsed + total !== totalBeforeLock) ||
    hasDiscount
  )
    return null;

  return (
    <SimpleModal onClose={closeModal}>
      <ModalContent onCloseModal={closeModal} title={t('label.price_changed')}>
        <ContentRow withBorder>
          <p>
            <strong>{origin}</strong> {t('label.to')} <strong>{destination}</strong>
            <br />
            {`${t('label.new_total')}: `}
            <strong>{formatCurrency(total, 2)}</strong>
          </p>
        </ContentRow>
        <ContentRow>
          <p>{t('text.number_of_seats_not_available_or_round_trip_discount')}</p>
        </ContentRow>
        <ContentRow horizontal>
          <Button text={t('label.continue_buying')} onClick={closeModal} />
          <Button
            text={t('label.go_back_to_choose_another_schedule')}
            variant="nofill"
            onClick={() => handleGotoSearch()}
          />
        </ContentRow>
      </ModalContent>
    </SimpleModal>
  );
};

PriceChangedModal.propTypes = propTypes;

export default PriceChangedModal;
