/* eslint-disable import/prefer-default-export */
import * as types from 'constants/ActionTypes';
import { adyen as adyenApi } from 'js-api-client';
import { camelizeKeys } from 'humps';

export function setCard(card) {
  return { type: types.SET_USER_SELECTED_CARD, card };
}

export function setCards(cards) {
  return {
    type: types.SET_USER_CARDS,
    cards: camelizeKeys(cards),
  };
}

export function setUpdated(isUpdated) {
  return { type: types.SET_CARDS_UPDATED, isUpdated };
}

export function setLoading(isLoading) {
  return { type: types.SET_CARDS_LOADING, isLoading };
}

export function setAvailable(isAvailable) {
  return { type: types.SET_CARDS_AVAILABLE, isAvailable };
}

export function useAnotherCard(payWithAnotherCard) {
  return { type: types.SET_CARDS_ANOTHER_CARD, payWithAnotherCard };
}

const saveCardsToState = (dispatch, paymentCards) => {
  dispatch(setCards(paymentCards));
  dispatch(setUpdated(true));
  dispatch(setLoading(false));
  dispatch(setAvailable(true));
};

export function fetchPaymentCards() {
  return (dispatch) => {
    dispatch(setLoading(true));
    return adyenApi
      .paymentCards()
      .then((paymentCards) => {
        saveCardsToState(dispatch, paymentCards);
      })
      .catch((reason) => {

        dispatch(setLoading(false));
        dispatch(setAvailable(false));
      });
  };
}

export function deletePaymentCard(reference) {
  return (dispatch) => {
    dispatch(setUpdated(false));
    dispatch(setLoading(true));
    return adyenApi
      .deleteCard({ recurring_payment_reference: reference })
      .then((paymentCards) => {
        saveCardsToState(dispatch, paymentCards);
      })
      .catch((reason) => {

        dispatch(fetchPaymentCards());
      });
  };
}

export function setSelectedCard(card) {
  return (dispatch) => {
    dispatch(setCard(card));
    dispatch(useAnotherCard(false));
  };
}

export function setUseAnotherCard(payWithAnotherCard) {
  return (dispatch) => {
    dispatch(useAnotherCard(payWithAnotherCard));
    dispatch(setCard(''));
  };
}

export function setSaveCard(saveCard) {
  return { type: types.SET_SAVE_CARD, saveCard };
}

/** Reset the cards state */
export function resetCardsState() {
  return { type: types.RESET_CARDS_STATE };
}
