import React from 'react';
import PropTypes from 'prop-types';
import LoadingDots from '../atoms/LoadingDots';
import './InlineInput.scss';

const InlineInput = ({ name, placeholder, isLoading, onClick, buttonText, value, onChange }) => {
  return (
    <div className="form-item-wrap inline-items">
      <div className="form-item-wrap-l">
        <div className="form-item textfield">
          <input
            type="text"
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            className="form-input"
            value={value.toString()}
          />
        </div>
      </div>

      <div className="form-item-wrap-button">
        {isLoading ? (
          <button className="button-secondary" disabled>
            <LoadingDots />
          </button>
        ) : (
          <button onClick={onClick} className="button-secondary">
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

InlineInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  isLoading: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

InlineInput.defaultProps = {
  buttonText: 'Aplicar',
  isLoading: false,
};

export default InlineInput;
