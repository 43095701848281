import React, { Component } from 'react';
import PropTypes from 'prop-types';
import environment from 'config/environment';
import { Spacing, Text } from '@reservamos/elements';
import { withTranslation } from 'react-i18next';
import CheckoutWalletCheckbox from './CheckoutWalletCheckbox';
import CheckoutWalletInput from './CheckoutWalletInput';

function checkboxI18nContext(loadingWallet, usingWallet) {
  if (loadingWallet) return 'loading';
  if (usingWallet) return 'using';

  return '';
}

class CheckoutWallet extends Component {
  componentDidUpdate(prevProps) {
    const { user, updatePurchaseUser, purchaseToken, walletType } = this.props;
    const validWalletType =
      walletType !== 'doters' && walletType !== 'travelpass' && walletType !== 'costapass';
    if (validWalletType && !prevProps.user && user) {
      updatePurchaseUser(purchaseToken);
    }
  }

  render() {
    const {
      selectedPaymentOption,
      purchaseToken,
      setUsingWallet,
      updatingWallet,
      usingWallet,
      walletBalanceUsed,
      maxUsableBalance,
      chosenSpPassengerId,
      total,
      isExchange,
      user,
      t,
      walletType,
    } = this.props;

    const loadingWallet = updatingWallet;

    if (!user) return null;
    if (walletType === 'doters' || walletType === 'travelpass' || walletType === 'costapass')
      return null;
    if (selectedPaymentOption === 'oxxo') return null;

    const { availableKms: walletBalance } = user;
    const usableBalance = walletBalance >= maxUsableBalance ? maxUsableBalance : walletBalance;

    let wallet;
    let canUsePoints = total !== 0;
    if (!chosenSpPassengerId) {
      wallet = <Text>{t('user_not_a_passenger', { context: environment.brand })}</Text>;
      canUsePoints = false;
    } else if (usableBalance <= 0) {
      wallet = <Text>{t('no_wallet_funds_message')}</Text>;
      canUsePoints = false;
    } else if (usingWallet) {
      wallet = (
        <CheckoutWalletCheckbox
          checkboxContext={checkboxI18nContext}
          setUsingWallet={setUsingWallet}
          loadingWallet={loadingWallet}
          usingWallet={usingWallet}
          usableBalance={usableBalance}
          walletBalanceUsed={walletBalanceUsed}
          purchaseToken={purchaseToken}
        />
      );
    } else {
      wallet = (
        <CheckoutWalletInput
          checkboxContext={checkboxI18nContext}
          setUsingWallet={setUsingWallet}
          loadingWallet={loadingWallet}
          usableBalance={usableBalance}
          purchaseToken={purchaseToken}
        />
      );
    }

    if (isExchange && !canUsePoints) return null;

    return (
      <div className="checkout-section">
        <Spacing vertical size="S">
          <Text weight="bold">{t('wallet_name')}</Text>
          {wallet}
        </Spacing>
      </div>
    );
  }
}

CheckoutWallet.propTypes = {
  selectedPaymentOption: PropTypes.string.isRequired,
  usingWallet: PropTypes.bool.isRequired,
  updatingWallet: PropTypes.bool.isRequired,
  purchaseToken: PropTypes.string.isRequired,
  maxUsableBalance: PropTypes.number.isRequired,
  setUsingWallet: PropTypes.func.isRequired,
  updatePurchaseUser: PropTypes.func.isRequired,
  walletBalanceUsed: PropTypes.number.isRequired,
  isExchange: PropTypes.bool,
  total: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    spCard: PropTypes.string.isRequired,
    availableKms: PropTypes.number.isRequired,
  }),
  chosenSpPassengerId: PropTypes.string,
  t: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
};

CheckoutWallet.defaultProps = {};

export default withTranslation('loyalty')(CheckoutWallet);
