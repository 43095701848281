import React from 'react';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spacing } from '@reservamos/elements';
import { selectRenderer } from '../../../utils/formRenderers';
import CheckList from '../../../ui/atoms/CheckList';
import PaymentImage from '../../../ui/atoms/PaymentImage';
import { useBanksList } from '../../../../payments/react/methods/pse/hooks';

/**
 * TransferForm component renders a form for PSE bank transfers.
 * It provides options to select person type and bank from a list of available banks.
 * The form also displays a checklist with steps to complete the purchase.
 *
 * @returns {JSX.Element} The rendered component with the transfer form.
 */
const TransferForm = () => {
  const {
    env,
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { banksList, isLoading, hasError } = useBanksList();

  const labels = [
    t('text.click_done_to_complete_purchase'),
    t('text.redirect_to_pse_to_complete_payment'),
    t('text.return_to_provider_for_tickets', { provider: provider.name }),
  ];

  const options = [];

  if (isLoading) {
    options.push({ value: '', label: t('general:loading') });
  }
  if (hasError) {
    options.push({ value: '', label: 'Error' });
  }
  if (banksList.length) {
    banksList.forEach((bank) => {
      options.push({ value: bank.id, label: bank.name });
    });
  }

  const personOptions = [
    { value: '0', label: t('label.natural_person', { context: env.brand }) },
    { value: '1', label: t('label.legal_person') },
  ];

  return (
    <div className="checkout-section" id="here">
      <Spacing vertical>
        <div className="checkout-paypal-logos">
          <PaymentImage type="pse-logo" />
        </div>

        <CheckList title={t('label.pay_with_pse')} labels={labels} />

        <div className="form-item-wrap">
          <div className="form-item-wrap-l">
            <Field
              label={t('label.person_type')}
              placeholder={t('label.person_type')}
              name="personType"
              cls="form-item"
              component={selectRenderer}
              disabled={isLoading || hasError}
              options={personOptions}
            />
          </div>
          <div className="form-item-wrap-r">
            <Field
              label={t('label.select_bank')}
              placeholder={t('label.select_bank')}
              name="bank"
              cls="form-item"
              component={selectRenderer}
              disabled={isLoading || hasError}
              options={options}
            />
          </div>
        </div>
      </Spacing>
    </div>
  );
};

export default TransferForm;
