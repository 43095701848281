import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getShortAccessFilters } from '../utils/shortAccessFilters';

/**
 * Custom hook for managing short access filters.
 * @returns {Array} An array containing the short access filters and the function to set the filters.
 */
const useShortAccessFilters = () => {
  const { t } = useTranslation();
  const { features } = useSelector((state) => state.whitelabelConfig);

  const defaultCommonFilters = [
    {
      filterType: 'departureTime',
      value: 'morning',
      i18Key: 'search:label.selector_morning',
      isActive: false,
    },
    {
      filterType: 'departureTime',
      value: 'afternoon',
      i18Key: 'search:label.selector_afternoon',
      isActive: false,
    },
    {
      filterType: 'departureTime',
      value: 'night',
      i18Key: 'search:label.selector_night',
      isActive: false,
    },
    {
      filterType: 'departureTime',
      value: 'earlymorning',
      i18Key: 'search:label.selector_earlymorning',
      isActive: false,
    },
  ];
  const defaultShortAccessFilters = features.SHORT_ACCESS_FILTERS || defaultCommonFilters;

  const [shortAccessFilters, setShortAccessFilters] = useState([...defaultShortAccessFilters]);

  useEffect(() => {
    // The default short access filter are gotten and parsed
    const defaultFeaturesParsed = defaultShortAccessFilters.map((filter) => ({
      ...filter,
      label: t(`${filter.i18Key}`),
    }));

    // Gets the saved short access filters and parses them, It also mixed them with the default filters
    const savedFilters = getShortAccessFilters();
    if (savedFilters?.length) {
      const savedFiltersParsed = savedFilters
        .map((filter) => ({
          ...filter,
          label: t(`${filter.i18Key}`),
          isActive: filter.isActive,
        }))
        .filter((filter) => filter.isActive);
      savedFiltersParsed.forEach((filterSaved) => {
        const duplicatedFilterIndex = defaultFeaturesParsed.findIndex(
          (defaultFilter) => defaultFilter.value === filterSaved.value,
        );
        if (duplicatedFilterIndex > -1) {
          defaultFeaturesParsed.splice(duplicatedFilterIndex, 1);
        }
      });
      setShortAccessFilters([...defaultFeaturesParsed, ...savedFiltersParsed]);
    } else {
      setShortAccessFilters([...defaultFeaturesParsed]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to set the short access filters.
   * @param {Array} filters - The new filters to set.
   */
  const setShortAccessFiltersHandler = (filters) => {
    setShortAccessFilters([...filters]);
  };

  /**
   * Function to check if a filter option is active.
   * @param {Object} option - The filter option.
   * @param {string} option.type - The type of the filter.
   * @param {string} option.value - The value of the filter.
   * @returns {boolean} Indicates whether the filter option is active or not.
   */

  return [shortAccessFilters, setShortAccessFiltersHandler];
};

export default useShortAccessFilters;
