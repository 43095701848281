import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PassengerTypeRadio from './PassengerTypeRadio';

/**
 * Available category component
 * @param {Object} param0 - Props
 * @param {String} param0.name - Name of the field
 * @param {String} param0.type - Type of the category
 * @param {Boolean} param0.isDisabled - If the category is disabled
 * @returns {React.JSX} - Available category component
 */
const AvailableCategory = ({ name, type, isDisabled }) => {
  const { t } = useTranslation('seats');
  const { brand } = useWhitelabelEnvs();
  return (
    <Field
      name={name}
      type="radio"
      value={type}
      component={PassengerTypeRadio}
      isDisabled={isDisabled}
    >
      {t(`passengers:type.${type}`, { context: brand })}
    </Field>
  );
};

AvailableCategory.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

/**
 * List of available categories
 * @param {Object} param0 - Props
 * @param {Array} param0.categories - Categories available
 * @param {String} param0.name - Name of the field
 * @returns {Array} - List of available categories as JSX Components
 */
const AvailableCategoriesList = ({ categories, name }) => {
  return categories.map(({ type, disabled }) => (
    <AvailableCategory key={type} name={name} type={type} isDisabled={disabled} />
  ));
};

AvailableCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
};

export default AvailableCategoriesList;
