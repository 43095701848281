import * as types from 'constants/ActionTypes';
import { trip } from 'js-api-client';
import pollingPromises from 'utils/pollingPromises';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function setTripLoader(tripId = null) {
  return {
    type: types.SET_TRIP_LOADING,
    tripId,
  };
}

export function addTripDetails(tripId, details) {
  return {
    type: types.ADD_TRIP_DETAILS,
    tripId,
    details,
  };
}

export function showTripDetails(details) {
  return {
    type: types.SHOW_TRIP_DETAILS,
    details,
  };
}

export function getTripDetails(tripId) {
  return (dispatch, getStore) => {
    const { tripsDetails } = getStore();
    if (tripsDetails.hasIn(['trips', tripId])) {
      dispatch(showTripDetails(tripsDetails.getIn(['trips', tripId])));
      return;
    }

    const options = decamelizeKeys({ withPricing: false, include: ['bus'] });
    const poll = pollingPromises({
      data: tripId,
      name: 'onReceiveTrip',
      create: (polling) => trip.getWithBus(tripId, options, polling),
    });

    dispatch(setTripLoader(tripId));

    poll
      .then(({ payload }) => {
        dispatch(setTripLoader());
        const details = camelizeKeys(payload);
        // Add and show trip details
        dispatch(addTripDetails(tripId, details));
        dispatch(showTripDetails(details));
      })
      .catch(() => {
        dispatch(setTripLoader());
      });
  };
}
