/* eslint-disable no-underscore-dangle */

import { analyticsReady, getDistinctId } from 'user-analytics';
import store from '@/store';

export default () =>
  analyticsReady(() => {
    const { purchase, whitelabelConfig } = store.getState();
    const {
      sift: { enabled, apiKey },
    } = whitelabelConfig.env;
    const sessionId = getDistinctId();
    const userId = purchase.get('email') || '';

    if (enabled && sessionId) {
      const sift = window._sift || [];

      sift.push(['_setAccount', apiKey]);
      sift.push(['_setUserId', userId]);
      sift.push(['_setSessionId', sessionId]);
      sift.push(['_trackPageview']);


    }
  });
