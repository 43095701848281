import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Icon, Modal, OutlineButton, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import CSSVariablesProvider from 'components/CSSVariablesProvider/CSSVariablesProvider';
import Pet from '../../../../images/badges/pet.svg';
import Woman from '../../../../images/brands-unique/gfa/badge-woman.svg';
import SortOptionList from '../SortOptionList';
import ScheduleOptionList from '../ScheduleOptionList';
import CategoriesOptionList from '../CategoriesOptionList';
import TripOption from '../TripOption';
import filtersTypes from '../../../../constants/filtersTypes';

const { STOPS, CATEGORIES, TRIP_OPTIONS } = filtersTypes;

const PET_FRIENDLY = 'pet_friendly';
const SUPPORT_WOMAN = 'supportWoman';

/**
 * NavbarFiltersModal Component
 * This component represents a modal for filtering the trips based on the options passed.
 * @param {Object} props - The component props.
 * @param {function} props.onCloseModal - Function to close the modal.
 * @param {string|array} props.categoriesFilter - Selected categories filter.
 * @param {string|array} props.scheduleFilters - Selected schedule filters.
 * @param {string|array} props.tripOptionsFilter - Selected trip options filters.
 * @param {array} props.tripOptionsOptions - Trip options available.
 * @param {function} props.handleOnSelectOption - Function to handle option selection.
 * @param {function} props.handleOnApply - Function to handle filters applying.
 * @param {string} props.sortOptionBy - Selected sort option.
 * @param {array} props.sortOptions - Array of sort options.
 * @param {array} props.stopsAvailable - Array of stops available.
 * @param {array} props.scheduleOptions - Array of schedule options.
 * @param {array} props.categoriesOptions - Array of categories options.
 * @param {Object} props.petFriendlyCategory - Pet friendly category object.
 * @param {boolean} props.womanCategory - Flag indicating if woman category can be used.
 * @param {function} props.onResetFilters - Function to reset filters.
 * @param {boolean} props.isProviderList - Flag indicating if the list is a provider list.
 * @param {boolean} props.isOpenTicketList - Flag indicating if the list is a open ticket list.
 * @param {array} props.stopsFilter - Array of stops filters.
 * @returns {JSX.Element} The rendered NavbarFiltersModal component.
 */
const NavbarFiltersModal = ({
  scheduleFilters,
  onCloseModal,
  categoriesFilter,
  handleOnSelectOption,
  handleOnApply,
  sortOptionBy,
  stopsFilter,
  sortOptions,
  stopsAvailable,
  scheduleOptions,
  categoriesOptions,
  tripOptionsFilter,
  tripOptionsOptions,
  petFriendlyCategory,
  womanCategory,
  onResetFilters,
  isProviderList,
  isOpenTicketList,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const isFlat = features.FUNNEL_STYLE === 'FLAT';
  const canUsePet = petFriendlyCategory && petFriendlyCategory.canUse;
  const canUseWoman = womanCategory && womanCategory.canUse;

  /**
   * Function to handle the apply button click, without passing the event object.
   */
  const onApply = () => {
    handleOnApply();
  };

  /**
   * Function to handle the reset filters button click, without passing the event object.
   */
  const handleOnResetFilters = () => {
    onResetFilters();
  };

  const categoriesFiltersAvailable =
    !isProviderList && !isOpenTicketList && features.CATEGORIES_FILTER_AVAILABLE;

  /**
   * Function to check if the option is active.
   * @param {*} option - The option to check.
   * @param {*} tripOptionsFilter - The trip options filter.
   * @returns {boolean} The result of the check.
   */
  const checkIfActive = (option, tripOptionsFilter) => {
    if (typeof option.value === 'object') {
      return tripOptionsFilter?.some((item) => {
        return Object.keys(item).every((key) => item[key] === option.value[key]);
      });
    }
    return tripOptionsFilter?.includes(option.value);
  };

  const thereAreTripOptionsFilters =
    tripOptionsFilter?.length > 0 ||
    (canUsePet && !isOpenTicketList) ||
    (canUseWoman && !isOpenTicketList) ||
    features.SHOW_STOPS_FILTER;

  return (
    <Modal
      onCloseModal={onCloseModal}
      footer={
        <Spacing alignItems="center" justifyContent="space-between" flexGrow>
          <OutlineButton
            color="primary"
            text={t('general:button.clean')}
            variant="primary"
            isRounded={isFlat}
            fullWidth
            onClick={handleOnResetFilters}
          />
          <Button
            onClick={onApply}
            text={t('general:button.apply')}
            variant="primary"
            isRounded={isFlat}
            fullWidth
          />
        </Spacing>
      }
      title={t('search:label.customize_your_search')}
      titleColor={isFlat ? 'accent' : 'grayLight'}
      zIndex={10}
    >
      <CSSVariablesProvider
        theme={env.theme}
        funnelStyle={features.FUNNEL_STYLE}
        funnelStyleBrandVariation={env.brand.toUpperCase()}
      >
        <Spacing vertical size="XL">
          <SortOptionList
            options={sortOptions}
            sortBy={sortOptionBy}
            onSelectOption={handleOnSelectOption}
          />

          <Spacing vertical>
            <Spacing
              justifyContent={isFlat ? 'space-between' : 'flex-start'}
              alignItems="center"
              responsiveSize="S"
            >
              {!isFlat && (
                <Box bgColor="primary" borderRadius="L" paddingVertical="S" paddingHorizontal="XS">
                  <Spacing alignItems="center">
                    <Icon type="FilterBy" color="white" size="S" />
                  </Spacing>
                </Box>
              )}
              <Spacing vertical size="XXS">
                <Text weight="bold" size="XL">
                  {t('search:label.filter_by')}
                </Text>
                <Text size="S" color="grayMedium">
                  {t('search:label.you_can_choose_more_than_one_option')}
                </Text>
              </Spacing>
              {isFlat && (
                <Box bgColor="primary" borderRadius="L" paddingVertical="S" paddingHorizontal="S">
                  <Spacing alignItems="center">
                    <Icon type="FilterBy" color="white" size="S" />
                  </Spacing>
                </Box>
              )}
            </Spacing>

            {categoriesFiltersAvailable && (
              <CategoriesOptionList
                options={categoriesOptions}
                onSelectOption={handleOnSelectOption}
                categoriesFilterSelected={categoriesFilter}
              />
            )}

            <ScheduleOptionList
              options={scheduleOptions}
              onSelectOption={handleOnSelectOption}
              selectedScheduleOptions={scheduleFilters}
            />

            {!isProviderList && (
              <Spacing vertical size="S">
                {thereAreTripOptionsFilters && (
                  <Text size="L" weight="bold" color="grayMedium">
                    {t('search:label.travel_options')}
                  </Text>
                )}

                <Spacing vertical isResponsive justifyContent="space-between">
                  {features.SHOW_STOPS_FILTER && (
                    <TripOption
                      icon="PinDirect"
                      onChange={handleOnSelectOption}
                      isActive={stopsFilter.includes('direct')}
                      value="direct"
                      primaryText={t('search:trip_stopovers_direct')}
                      secondaryText={t('search:label.without_scales')}
                      filterType={STOPS}
                      notAvailable={!stopsAvailable.some((stop) => stop.value === 'direct')}
                      i18Key="search:trip_stopovers_direct"
                    />
                  )}
                  {canUsePet && !isOpenTicketList && (
                    <TripOption
                      icon={Pet}
                      notAvailable={!petFriendlyCategory.hasAvailability}
                      onChange={handleOnSelectOption}
                      isActive={categoriesFilter?.includes(PET_FRIENDLY)}
                      value={PET_FRIENDLY}
                      primaryText={t('search:label.pet_friendly')}
                      filterType={CATEGORIES}
                      i18Key={petFriendlyCategory.i18Key}
                    />
                  )}
                  {canUseWoman && !isOpenTicketList && (
                    <TripOption
                      icon={Woman}
                      onChange={handleOnSelectOption}
                      isActive={categoriesFilter?.includes(SUPPORT_WOMAN)}
                      notAvailable={!womanCategory.hasAvailability}
                      value={SUPPORT_WOMAN}
                      primaryText={t('seats:seat_woman')}
                      filterType={CATEGORIES}
                      i18Key={womanCategory.i18Key}
                    />
                  )}
                  {tripOptionsOptions &&
                    tripOptionsOptions.map((option) => (
                      <TripOption
                        key={option.value}
                        onChange={handleOnSelectOption}
                        isActive={checkIfActive(option, tripOptionsFilter)}
                        notAvailable={!option.hasAvailability}
                        value={option.value}
                        primaryText={t(option.primaryText)}
                        filterType={TRIP_OPTIONS}
                        i18Key={option.i18Key}
                      />
                    ))}
                </Spacing>
              </Spacing>
            )}

            <Spacing size="XS" alignItems="center">
              <Text size="S" color="grayMedium">
                *{t('search:label.filters_with_this_icon')}
              </Text>
              <Icon type="BlockNotAvailable" size="S" color="error" />
              <Text size="S" color="grayMedium">
                {t('search:label.are_not_available')}
              </Text>
            </Spacing>
          </Spacing>
        </Spacing>
      </CSSVariablesProvider>
    </Modal>
  );
};

NavbarFiltersModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  categoriesFilter: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  scheduleFilters: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  tripOptionsFilter: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  handleOnSelectOption: PropTypes.func.isRequired,
  stopsFilter: PropTypes.array.isRequired,
  handleOnApply: PropTypes.func.isRequired,
  sortOptionBy: PropTypes.string.isRequired,
  sortOptions: PropTypes.array.isRequired,
  stopsAvailable: PropTypes.array.isRequired,
  scheduleOptions: PropTypes.array.isRequired,
  categoriesOptions: PropTypes.array.isRequired,
  tripOptionsOptions: PropTypes.array.isRequired,
  petFriendlyCategory: PropTypes.object,
  womanCategory: PropTypes.bool.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  isProviderList: PropTypes.bool,
  isOpenTicketList: PropTypes.bool,
};

export default NavbarFiltersModal;
