import React, { useEffect, useState } from 'react';
import { trackEvent } from 'user-analytics';
import 'styles/components/purchase/Purchase';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@reservamos/elements';
import moment from 'moment';
import {
  confirmItineraryTracker,
  exchangeConfirmItineraryTracker,
} from 'metrics/user-analytics/purchase';
import Passengers from 'components/purchase/passengers';
import DepartureSeats from 'components/purchase/DepartureSeats';
import ReturnSeats from 'components/purchase/ReturnSeats';
import Checkout from 'components/purchase/Checkout';
import LoadingScreen from 'components/LoadingScreen';
import Header from 'components/Header';
import PurchaseSidebar from 'components/purchase/PurchaseSidebar';
import PurchaseBreadcrumbs from 'components/purchase/PurchaseBreadcrumbs';
import PurchaseReview from 'components/purchase/PurchaseReview';
import PurchaseFooter from 'components/purchase/PurchaseFooter';
import Timer from 'components/purchase/Timer';
import TimeoutModal from 'components/purchase/TimeoutModal';
import withPurchaseTimer from 'hocs/withPurchaseTimer';
import { getValidDate } from 'utils/search/searchDateParam';
import useCybersourceKeys from 'hooks/useCybersourceKeys';
import evertec from 'payments/core/engines/evertec';
import Footer from '../../../ui/atoms/Footer';
import { MercadoPagoTDCProvider } from '../../mercadoPago';
import { mercadoPagoPaymentAttempt } from '../../../actions/payment';
import TicketInfoBar from '../../../ui/atoms/TicketInfoBar';
import wayIsOpenTicket from '../../../utils/wayIsOpenTicket';
import { requestExtendPurchaseExpiration } from '../../../actions/purchase';
import PurchaseTimerModal from '../../../ui/atoms/PurchaseTimerModal';
import {
  PURCHASE_SESSION_EXTENDED,
  TICKET_INFO_BAR_DETAILS_CLICKED,
} from '../../../constants/TrackEvents';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import { setUpPurchaseLoyaltyProgram } from '../../../actions/loyalty';
import { getWalletTypeForPurchase } from '../../../utils/loyalty';
import useWhitelabelTheme from '../../../hooks/whitelabel/useWhitelabelTheme';
import { setError } from '../../../actions';

const propTypes = {
  availablePayments: PropTypes.arrayOf(PropTypes.string.isRequired),
  departureDate: PropTypes.string,
  destinationCity: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
  headerTitle: PropTypes.string.isRequired,
  loaded: PropTypes.bool,
  match: PropTypes.object.isRequired,
  originCity: PropTypes.string,
  paymentStatus: PropTypes.string,
  purchaseReviewVisible: PropTypes.bool.isRequired,
  returnDate: PropTypes.string,
  token: PropTypes.string,
  getPurchase: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  onTogglePurchaseReview: PropTypes.func.isRequired,
  // TODO: Move confirm itinerary to middleware, make purchase review stateful
  purchase: PropTypes.object.isRequired,
  transitionTo: PropTypes.func.isRequired,
  paymentId: PropTypes.number,
  paymentEngineType: PropTypes.string,
  roundTrip: PropTypes.bool.isRequired,
  fetchingPayment: PropTypes.bool.isRequired,
  departureIsOpenTicket: PropTypes.bool,
  returnIsOpenTicket: PropTypes.bool,
  searchDepartureDate: PropTypes.string,
  searchReturnsDate: PropTypes.string,
  isExchange: PropTypes.bool.isRequired,
  tripDate: PropTypes.string,
  expireMinutes: Number,
  expirationExtensionAvailable: PropTypes.string,
  minutesToExtend: PropTypes.number,
  secondsBeforeExpiration: PropTypes.number,
  timerRemainingTime: PropTypes.number,
  findableOriginId: PropTypes.string,
  findableDestinationId: PropTypes.string,
  encodedPassengers: PropTypes.string,
  paymentEngine: PropTypes.string.isRequired,
  purchaseStatus: PropTypes.string.isRequired,
};

const defaultProps = {
  loaded: false,
  paymentId: null,
  paymentEngineType: null,
};

const Purchase = ({
  availablePayments,
  departureDate,
  destinationCity,
  getPurchase,
  headerTitle,
  isUpdating,
  loaded,
  match,
  onTogglePurchaseReview,
  originCity,
  paymentStatus,
  purchase,
  purchaseReviewVisible,
  returnDate,
  showModal,
  token,
  transitionTo,
  paymentEngineType,
  paymentId,
  roundTrip,
  fetchingPayment,
  findableOriginId,
  findableDestinationId,
  departureIsOpenTicket,
  returnIsOpenTicket,
  searchDepartureDate,
  searchReturnsDate,
  isExchange,
  tripDate,
  expireMinutes,
  expirationExtensionAvailable,
  minutesToExtend,
  secondsBeforeExpiration,
  timerRemainingTime,
  encodedPassengers = 'A1',
  paymentEngine,
  purchaseStatus,
}) => {
  const [showExtendSession, setShowExtendSession] = useState(false);
  const dispatch = useDispatch();
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { colors } = useWhitelabelTheme();
  const headerTextColor = colors.headerText || 'white';
  const { t } = useTranslation();
  const dispatchMercadoPagoPayment = (formData) => dispatch(mercadoPagoPaymentAttempt(formData));
  useCybersourceKeys();

  const showPurchaseReview = () => {
    trackEvent(TICKET_INFO_BAR_DETAILS_CLICKED);
    onTogglePurchaseReview(true);
  };

  const hidePurchaseReview = () => {
    onTogglePurchaseReview(false);

    if (isExchange) {
      exchangeConfirmItineraryTracker(purchase);
    } else {
      confirmItineraryTracker(purchase);
    }
  };

  const goToSearch = () => {
    // Close the evertec modal if there is an evertec instance
    const evertecInstance = evertec.getInstance();
    evertecInstance?.closeFrame();
    if (!isExchange) {
      const departure = departureIsOpenTicket
        ? moment(searchDepartureDate, 'DD-MM-YYYY').format('DD-MMM-YY')
        : moment(departureDate).format('DD-MMM-YY');

      const validDepartureDate = getValidDate(departure);

      // Determine the base URL based on whether the purchase was redirected
      const baseUrl =
        purchase.redirect && purchase.redirectedFrom
          ? env.redirectPartners[purchase.redirectedFrom].funnelUrl
          : '/';

      // Construct the final URL using the determined base URL
      let url = `${baseUrl}search/${findableOriginId}/${findableDestinationId}/${validDepartureDate}/`;

      if (roundTrip) {
        const returns = returnIsOpenTicket
          ? moment(searchReturnsDate, 'DD-MM-YYYY').format('DD-MMM-YY')
          : moment(returnDate).format('DD-MMM-YY');

        const validReturnDate = getValidDate(returns);
        url += `${validReturnDate}/`;
      }
      if (features.PROVIDERS_SELECTION_ENABLED) {
        url += `p/${encodedPassengers}/providers`;
      } else {
        url += `p/${encodedPassengers}/departures`;
      }

      if (purchase.redirect && purchase.redirectedFrom) {
        // Redirects to the origin brand's search page if the purchase was redirected
        window.location.replace(url);
      } else {
        transitionTo(url);
      }
    } else {
      transitionTo('/exchange/schedule');
    }
  };

  /**
   * Handle the extend purchase session
   */
  const handleOnExtend = () => {
    trackEvent(PURCHASE_SESSION_EXTENDED);
    dispatch(requestExtendPurchaseExpiration(token, goToSearch));
  };

  useEffect(() => {
    if (!expirationExtensionAvailable) return;

    /**
     * The time is calculated as seconds, because the remainig time can be a little less than a second, but visually it is a second.
     * For example: 12.998 seconds should be showed as 13 seconds. This is because of the little delay of the setInterval function.
     */
    const remaining = parseInt(timerRemainingTime, 10);
    if (remaining <= 0) {
      setShowExtendSession(false);
      return;
    }
    if (remaining && remaining <= secondsBeforeExpiration && !showExtendSession) {
      setShowExtendSession(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerRemainingTime]);

  // get the purchase on component mount if not loaded
  useEffect(() => {
    if (!loaded) getPurchase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && loaded) {
      window.sessionStorage.setItem('lastPurchaseToken', token);
    }
  }, [loaded, token]);

  // redirect when the payment is processing
  useEffect(() => {
    if (loaded && paymentId && paymentStatus && paymentStatus !== 'failed') {
      if (paymentEngine === 'evertec' && purchaseStatus === 'attempt') return;
      let redirectPath = `/payment/${token}/${paymentId}`;
      if (paymentEngineType === 'transfer' || paymentEngineType === 'app_notify') {
        redirectPath = `/purchase/${token}/transfer/${paymentId}`;
      }
      transitionTo(redirectPath);
    }
  }, [
    transitionTo,
    loaded,
    paymentEngineType,
    paymentId,
    paymentStatus,
    token,
    purchaseStatus,
    paymentEngine,
  ]);

  /** ---------------------------------------
   * Loyalty Program Initialization Process
   */
  const { availableWallets } = purchase;
  const { doters, costaPass, shouldValidateOnExchange } = useLoyaltyPrograms();
  const { userIsRequested: dotersIsRequested, userIsLoggedIn: dotersIsLoggedIn } = doters;
  const { userIsRequested: costapassIsRequested, userIsLoggedIn: costapassIsLoggedIn } = costaPass;

  useEffect(() => {
    if (!token) return;
    // eslint-disable-next-line prettier/prettier
    /**
     * When the loyalty has already updated their info, the loyalty/walletType is set up in the purchase
     * this allows us to use the right walletType in the purchase and catch the logout of the user to remove the walletType
     * if the purchase has one before the logout.
     */
    if (dotersIsRequested && costapassIsRequested) {
      dispatch(setUpPurchaseLoyaltyProgram({ availableWallets }));

      // If the purchase has a walletOriginalType and the walletType base on the loyalty is not set up, redirect to exchange
      if (
        purchase.walletOriginalType &&
        shouldValidateOnExchange(purchase.walletOriginalType) &&
        !getWalletTypeForPurchase()
      ) {
        const loyalty = purchase.walletOriginalType.toLowerCase();
        const message = t(`notifications:error.should_be_logged_in`, { context: loyalty });
        dispatch(setError(400, undefined, 'error', false, message));
        transitionTo('/exchange');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dotersIsLoggedIn, costapassIsLoggedIn, dotersIsRequested, costapassIsRequested, token]);

  // ----------------------------------------

  if (!loaded || (paymentId && paymentStatus !== 'failed' && paymentEngine !== 'evertec'))
    return <LoadingScreen />;
  if (fetchingPayment && env.adyen.enabled) return <LoadingScreen />;

  const initialFormMetadata = {
    id: 'mercado_pago_form',
    fields: {
      cardNumber: {
        id: 'mercado_pago_form__cardNumber',
        placeholder: t('payment:card_number'),
        hasError: false,
        isValid: false,
      },
      expirationDate: {
        id: 'mercado_pago_form__expirationDate',
        placeholder: t('payment:card_expiration_date'),
        hasError: false,
        isValid: false,
      },
      securityCode: {
        id: 'mercado_pago_form__securityCode',
        placeholder: t('payment:card_CVV'),
        hasError: false,
        isValid: false,
      },
      cardholderName: {
        id: 'mercado_pago_form__cardholderName',
        placeholder: t('payment:card_owner_name'),
        hasError: false,
        isValid: false,
      },
      issuer: {
        id: 'mercado_pago_form__issuer',
        placeholder: 'Banco emisor',
        hasError: false,
        isValid: false,
      },
      identificationType: {
        id: 'mercado_pago_form__identificationType',
        hasError: false,
        isValid: false,
      },
      identificationNumber: {
        id: 'mercado_pago_form__identificationNumber',
        placeholder: t('payment:identification_number'),
        hasError: false,
        isValid: false,
      },
      cardholderEmail: {
        id: 'mercado_pago_form__cardholderEmail',
        placeholder: t('passengers:email'),
        hasError: false,
        isValid: false,
      },
      installments: {
        id: 'mercado_pago_form__installments',
        placeholder: t('payment:payment_installments'),
        hasError: false,
        isValid: false,
      },
    },
  };
  const shouldShowExtendPurchase = showExtendSession && expirationExtensionAvailable;
  const sidebarOnLeft = features.PURCHASE_LEFT_SIDEBAR_ENABLED;
  const purchaseLayout = sidebarOnLeft
    ? 'purchase-content purchase-sidebar-on-left'
    : 'purchase-content';

  return (
    <div className="l-purchase">
      {fetchingPayment && env.adyen.enabled && <LoadingScreen hasHeader={false} />}
      <Header backButton isFixed>
        <div className="topbar-data">
          <Text color={headerTextColor} weight="semibold" size="M">
            {t(`purchase:title.${headerTitle}`)}
          </Text>
          <Text size="S" color={headerTextColor}>
            {originCity} - {destinationCity}
          </Text>
        </div>
      </Header>

      <Timer size="fixed" remainingTime={timerRemainingTime} />

      <TicketInfoBar
        originCity={destinationCity}
        destinationCity={originCity}
        departureDate={tripDate}
        returnDate={moment(returnDate)}
        onClick={showPurchaseReview}
        passengerCount={purchase.passengers?.length}
        isRoundTrip={roundTrip}
        total={!purchase.isUpdating && purchase.total}
        isDepartureOpen={wayIsOpenTicket(purchase.departs)}
        isReturnOpen={wayIsOpenTicket(purchase.returns)}
      />

      <Timer size="medium" remainingTime={timerRemainingTime} />

      <div className={purchaseLayout}>
        {shouldShowExtendPurchase && (
          <PurchaseTimerModal
            remainingTime={timerRemainingTime}
            expireMinutes={expireMinutes}
            handleOnExtend={handleOnExtend}
            isLoading={isUpdating}
            handleGoToSearch={goToSearch}
            minutesToExtend={minutesToExtend}
          />
        )}
        <div className="purchase-step">
          <PurchaseBreadcrumbs />
          <div className="purchase-step-wrapper">
            <Route path={`${match.path}/passengers`} component={Passengers} />

            <Route path={`${match.path}/seats/departure`} component={DepartureSeats} />

            <Route path={`${match.path}/seats/return`} component={ReturnSeats} />

            <MercadoPagoTDCProvider
              onSubmit={dispatchMercadoPagoPayment}
              initialFormMetadata={initialFormMetadata}
            >
              <Route path={`${match.path}/checkout`} component={Checkout} />
            </MercadoPagoTDCProvider>
          </div>
        </div>

        <PurchaseSidebar
          isLoading={isUpdating}
          isOpenTicket={purchase.openTicket}
          showPurchaseReview={showPurchaseReview}
          roundTrip={roundTrip}
          goToSearch={goToSearch}
          isExchange={purchase.isExchange}
          timerRemainingTime={timerRemainingTime}
        />
      </div>

      {features.SHOW_PURCHASE_FOOTER && <PurchaseFooter availablePayments={availablePayments} />}
      {features.SHOW_SEARCH_FOOTER && <Footer onPurchase />}

      <PurchaseReview
        departs={purchase.departs}
        returns={purchase.returns}
        roundTrip={roundTrip}
        showModal={showModal}
        isOpenTicket={purchase.openTicket}
        onClosePurchaseReview={hidePurchaseReview}
        visible={purchaseReviewVisible}
      />
      <TimeoutModal />
    </div>
  );
};

Purchase.propTypes = propTypes;
Purchase.defaultProps = defaultProps;

export default withPurchaseTimer(Purchase);
