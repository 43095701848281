import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Gap, Icon, Spacing, Text } from '@reservamos/elements';
import returnTripsUrlFromDeparture from 'utils/purchase/returnTripsUrlFromDeparture';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { returnCTATracker } from 'metrics/user-analytics/purchase';
import ReturnCards from '../Checkout/ReturnCards';
import ReturnButton from '../../../ui/atoms/ReturnButton';
import wayIsOpenTicket from '../../../utils/wayIsOpenTicket';

/**
 * Return reminder component to select a return ticket when it was selected a simple trip.
 * @param {Object} props - Component props.
 * @param {String} props.title - Title to show.
 * @param {String} props.type - Type of reminder (button|card).
 * @returns {JSX.Element}
 */
const ReturnCTA = ({ title, type }) => {
  const history = useHistory();
  const { purchase, whitelabelConfig } = useSelector((state) => state);
  const { features } = whitelabelConfig;
  const { t } = useTranslation('passengers');

  if (!features.RETURN_TRIP_REMINDER) return null;

  const { returns, isExchange, passengerSelection } = purchase.toJS();
  /**
   * departure is not converted to JS because it is need as inmmutable object
   * by the returnTripsUrlFromDeparture function
   */
  const departureTrip = purchase.get('departs');
  if (returns.fragments || isExchange) return null;

  const destinationCity = departureTrip.getIn(['destination', 'cityName']);
  const originCity = departureTrip.getIn(['origin', 'cityName']);
  const returnAdultPrice = purchase.get('returnAdultPrice');
  const departureIsOpenTicket = wayIsOpenTicket(departureTrip.toJS());

  /**
   * Handler function to select the return simple ticket option
   */
  const onSchedulesSelected = () => {
    // Track use of scheduled ticket option
    returnCTATracker('Schedule');
    history.push(
      returnTripsUrlFromDeparture(departureTrip, departureIsOpenTicket, passengerSelection),
    );
  };

  /**
   * Handler function to select the return open ticket option
   */
  const onOpenTicketSelected = () => {
    const returnOpenURL = `${returnTripsUrlFromDeparture(
      departureTrip,
      departureIsOpenTicket,
      passengerSelection,
    )}?seenPrice=${purchase.get('seenAdultPrice')}&returnIsOpenTicket=true`;

    // Track use of open ticket option
    returnCTATracker('Open Ticket');
    history.push(returnOpenURL);
  };

  const titleToShow = title || t('label.get_return_trip');

  const hasRoute = Boolean(destinationCity && originCity);

  return (
    <Spacing size="XS" vertical>
      <Gap alignItems="center">
        <Text size="XL" color="grayStrong" weight="bold">
          {titleToShow}
        </Text>

        <Icon type="Clock" size="S" />
      </Gap>

      {hasRoute && (
        <Spacing size="XS" alignItems="center">
          <Icon type="PinOutlined" size="S" color="grayStrong" />

          <Text size="S" color="grayStrong" maxWidthEllipsis={120}>
            {destinationCity}
          </Text>

          <Icon type="ArrowForward" size="XS" color="grayMedium" />

          <Text m size="S" color="grayStrong" maxWidthEllipsis={120}>
            {originCity}
          </Text>
        </Spacing>
      )}

      {type === 'button' ? (
        <ReturnButton
          origin={destinationCity}
          destination={originCity}
          price={returnAdultPrice}
          onClick={onSchedulesSelected}
        />
      ) : (
        <ReturnCards
          onSchedulesSelected={onSchedulesSelected}
          onOpenTicketSelected={onOpenTicketSelected}
          origin={destinationCity}
          destination={originCity}
          simplePrice={returnAdultPrice}
          openPrice={returnAdultPrice}
        />
      )}
    </Spacing>
  );
};

ReturnCTA.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};

export default ReturnCTA;
