import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DialogMessage, Text } from '@reservamos/elements';

/** Dialog that indicates to the user that he/she needs to be logged */
function AuthDialog({
  title,
  bodyText,
  primaryButtonText,
  secondaryButtonText,
  showSecondaryButton = true,
  onClickPrimary,
  onClickSecondary,
}) {
  const { t } = useTranslation('passengers');

  return (
    <DialogMessage
      title={title}
      primaryButton={primaryButtonText || t('button.yes_thanks')}
      secondaryButton={secondaryButtonText || (showSecondaryButton && t('button.no_thanks'))}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      showFade
      dialogType="accent"
      iconType="freeCancellation"
    >
      <Text>{bodyText}</Text>
    </DialogMessage>
  );
}

AuthDialog.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onClickPrimary: PropTypes.func.isRequired,
  onClickSecondary: PropTypes.func,
  primaryButtonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
  showSecondaryButton: PropTypes.bool,
};

AuthDialog.defaultProps = {
  showSecondaryButton: true,
  secondaryButtonText: null,
  onClickSecondary: () => {},
};

export default AuthDialog;
