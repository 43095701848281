import * as types from 'constants/ActionTypes';
import { Map } from 'immutable';

const defaultState = Map({
  error: false,
});

export default function sso(state = defaultState, action) {
  switch (action.type) {
    case types.SSO_ERROR:
      return state.set('error', action.error);
    default:
      return state;
  }
}
