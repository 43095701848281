import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Link, Spacing, Text } from '@reservamos/elements';
import { CurrentLang } from 'utils/lang';
import etnFaacebook from '../../images/brands-unique/etn/social-facebook.svg';
import etnTwitter from '../../images/brands-unique/etn/social-twitter.svg';
import etnInstagram from '../../images/brands-unique/etn/social-instagram.svg';
import etnYoutube from '../../images/brands-unique/etn/social-youtube.svg';
import DotersInfo from './DotersInfo';
import useLoyaltyPrograms from '../../loyalty/context/useLoyaltyPrograms';
import './Footer.scss';

const Footer = ({ isFixed, onPurchase }) => {
  const {
    features,
    copies: { menu: copies, provider },
  } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const {
    doters: { userIsLoggedIn: dotersUserIsLoggedIn },
  } = useLoyaltyPrograms();
  if (!features.SHOW_SEARCH_FOOTER) return null;

  const lang = CurrentLang();

  const footerLinks = copies.footerUrls[lang].map((urls) => (
    <Link key={urls.label} text={urls.label} href={urls.link} size="S" type="primary" newTab />
  ));

  return (
    <div
      className={`rvui-footer ${isFixed ? 'rvui-footer-fixed' : ''} ${
        onPurchase ? 'rvui-footer-purchase' : ''
      }`}
    >
      <div className="rvui-footer-container">
        <Spacing isResponsive responsiveSize="S">
          <Spacing size="S">
            <a href={copies.twitter} target="_blank" rel="noreferrer" aria-label="Twitter">
              <Icon type={etnTwitter} />
            </a>
            <a href={copies.facebook} target="_blank" rel="noreferrer" aria-label="Facebook">
              <Icon type={etnFaacebook} />
            </a>
            <a href={copies.instagram} target="_blank" rel="noreferrer" aria-label="Instagram">
              <Icon type={etnInstagram} />
            </a>
            <a href={copies.youtube} target="_blank" rel="noreferrer" aria-label="YouTube">
              <Icon type={etnYoutube} />
            </a>
          </Spacing>
          {provider.phones &&
            provider.phones.map((phone, index) => {
              return (
                <Link
                  // eslint-disable-next-line react/no-array-index-key
                  key={`phones-${index}`}
                  text={phone.display}
                  href={`tel:${phone.value}`}
                  size="L"
                  weight="bold"
                  type="primary"
                />
              );
            })}
        </Spacing>
        <Spacing>
          {footerLinks}{' '}
          {features.DOTERS_ENABLED && (
            <DotersInfo
              modalTrigger={
                <Text color="primary" underline size="S" responsiveSize="XS" weight="semibold">
                  {t('loyalty:what_is_doters')}
                </Text>
              }
              isLogged={dotersUserIsLoggedIn}
            />
          )}
        </Spacing>
      </div>
    </div>
  );
};

Footer.propTypes = {
  isFixed: PropTypes.bool,
  onPurchase: PropTypes.bool,
};

Footer.defaultProps = {
  isFixed: false,
  onPurchase: false,
};

export default Footer;
