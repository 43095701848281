module.exports = {
  api: {
    version: 'v2',
  },
  lang: {
    default: 'es-MX',
  },
  currency: 'MXN',
  defaultPaymentOption: 'credit_card',
  phoneCountryCode: '+52',
  adyen: {
    enabled: false,
  },

  kushki: {
    enabled: false,
  },

  openPay: {
    enabled: false,
    showInFooter: false,
  },

  oneSignal: {
    enabled: false,
  },

  taplytics: {
    enabled: false,
  },

  tripPriceDecimals: 0,

  footer: {
    logos: [],
  },

  redirectPartners: {},

  // see utils/smartBanner for configuration
  smartBanner: { enabled: false },

  sentry: {
    dsn: 'https://5c776c2a91a640e48840d2e5109e4bb8@o25518.ingest.sentry.io/5265020',
    options: {
      release: __RELEASE__,
      debug: __DEV__,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        "Can't find variable: chrome",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'vid_mate_check',
        // Android webview erros
        'androidInterface is not defined',
        'Java exception was raised during method invocation',
        'androidOnPause is not defined',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // See: https://bugs.chromium.org/p/chromium/issues/detail?id=590375
        "undefined is not an object (evaluating '__gCrWeb.autofill.extractForms')",
        // ZTE devices bugs
        'zte',
        // Firefox erros
        '__firefox__',
        // Conekta iframe error
        'https://ssl.kaptcha.com',
        // Ad scripts
        'https://bid.g.doubleclick.net',
        // https://github.com/bugsnag/bugsnag-js/issues/186
        '__show__deepen is not defined',
      ],
      ignoreUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    },
  },
  gtmContainerId: 'GTM-MHZZBN2',
};
