import React from 'react';
import { Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PaymentImage from '../../../ui/atoms/PaymentImage';
import CheckList from '../../../ui/atoms/CheckList';

/**
 * NequiForm is a React component that renders the payment form for Nequi.
 * Including a payment image and a checklist with instructions.
 *
 * @returns {JSX.Element} A JSX element representing the Nequi payment form.
 */
const NequiForm = () => {
  const { t } = useTranslation('payment');
  const {
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);

  const labels = [
    t('nequi_instructions.second'),
    t('nequi_instructions.third'),
    t('nequi_instructions.fourth', { provider: provider.name }),
  ];

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <PaymentImage type="nequi-logo" />
        <CheckList title={t('nequi_instructions.first')} labels={labels} />
      </Spacing>
    </div>
  );
};

export default NequiForm;
