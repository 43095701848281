import React from 'react';
import PropTypes from 'prop-types';
import { Gap } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import ReturnCard from '../../../ui/atoms/ReturnCard';

/**
 * A component for displaying two ReturnCard components for selecting a schedule and an open ticket option.
 *  @component
 * @param props - Component props
 * @param {Function} props.onSchedulesSelected - A function to be called when the user selects a schedule.
 * @param {Function} props.onOpenTicketSelected - A function to be called when the user selects the open ticket option.
 * @param {string} props.origin - The origin location for the return trip.
 * @param {string} props.destination - The destination location for the return trip.
 * @param {number} props.simplePrice - The price for a simple return trip.
 * @param {number} props.openPrice - The price for an open return ticket.
 * @returns {JSX.Element} - The rendered component.
 */
const ReturnCards = ({
  onSchedulesSelected,
  onOpenTicketSelected,
  origin,
  destination,
  simplePrice,
  openPrice,
}) => {
  const { t } = useTranslation();
  const features = useWhitelabelFeatures();

  return (
    <Gap rowGap="M" columnGap="M">
      <ReturnCard
        title={t('trips:label.select_schedule')}
        price={simplePrice}
        origin={origin}
        destination={destination}
        hasBackground
        onClick={onSchedulesSelected}
      />
      {features.SHOW_OPEN_TICKET_BUTTON && (
        <ReturnCard
          title={t('general:open_ticket')}
          price={openPrice}
          origin={origin}
          destination={destination}
          onClick={onOpenTicketSelected}
        />
      )}
    </Gap>
  );
};

ReturnCards.propTypes = {
  onSchedulesSelected: PropTypes.func.isRequired,
  onOpenTicketSelected: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  simplePrice: PropTypes.number.isRequired,
  openPrice: PropTypes.number.isRequired,
};

export default ReturnCards;
