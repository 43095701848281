// @ts-check

import pse from '../../methods/pse';
import pix from '../../methods/pix';
import nequi from '../../methods/nequi';
import { NequiStrategy } from '../../methods/nequi/strategies';
import { kushkiPseStrategy, mercadoPagoPseStrategy } from '../../methods/pse/strategies';
import { mercadoPagoPixStrategy, cieloPixStrategy } from '../../methods/pix/strategies';

/**
 * @typedef {import('./Transfer.js').Transfer} Transfer
 */

const providers = {
  pse,
  pix,
  nequi,
};

const providersStrategies = {
  pse: {
    kushki: kushkiPseStrategy,
    mercadopago: mercadoPagoPseStrategy,
  },
  pix: {
    mercadopago: mercadoPagoPixStrategy,
    cielo: cieloPixStrategy,
  },
  nequi: {
    nequi: NequiStrategy,
  },
};

/**
 * Factory to create instances of Transfer with a given provider and engine.
 * @returns {object} An object with the create method to instantiate Transfer.
 */
const TransferFactory = () => {
  /**
   * Create an instance of Transfer with the given provider and engine.
   * @param {object} config - Configuration with the engine and provider.
   * @param {string} config.engine - The payment engine to use.
   * @param {string} config.provider - The payment provider to use.
   * @returns {Transfer} An instance of Transfer configured with the appropriate strategy.
   */
  const create = ({ engine, provider }) => {
    const method = providers[provider];
    const strategy = providersStrategies[provider][engine];

    if (!method) {
      throw new Error(`Provider ${provider} not found`);
    }

    if (!strategy) {
      throw new Error(`Provider strategy ${engine} not found`);
    }

    method.setStrategy(strategy);
    return method;
  };

  return { create };
};

const transferFactory = TransferFactory();

export default transferFactory;
