/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectInstallmentsPlan } from '@/actions/purchase';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useMercadoPagoTDCDynamicValues from 'hooks/useMercadoPagoTDCDynamicValues';
import { useMercadoPagoTDCContext, useMercadoPagoInstanceContext } from '../../mercadoPago';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const propTypes = {
  amount: PropTypes.string.isRequired,
};

const MercadoPagoTDCForm = ({ amount }) => {
  const dispatch = useDispatch();
  const { getInitialMercadoPagoHolderData } = useMercadoPagoTDCDynamicValues();
  const initialHolderData = getInitialMercadoPagoHolderData();
  const {
    mountMercadoPagoTDCForm,
    identificationTypes,
    formMetadata: { id: formId, fields },
    holderEmailInputRef,
    holderNameInputRef,
    identificationNumberInputRef,
    unmountMercadoPagoTDCForm,
    identificationTypeInputRef,
    validateDocumentNumber,
    cardBin,
  } = useMercadoPagoTDCContext();
  const { mercadoPagoInstanceIsCreated } = useMercadoPagoInstanceContext();
  const { t } = useTranslation();
  const { paymentPlans } = useSelector((state) => state.purchase.toJS());

  useEffect(() => {
    if (!mercadoPagoInstanceIsCreated) return;
    mountMercadoPagoTDCForm(String(amount));
    return () => {
      unmountMercadoPagoTDCForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, mercadoPagoInstanceIsCreated]);

  useEffect(() => {
    if (!cardBin) {
      dispatch(
        selectInstallmentsPlan({
          months: 1,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardBin]);

  const getValidationFeedbackClass = (field) => {
    return `form-input ${field.hasError ? 'has-error' : field.isValid && 'isValid'}`;
  };

  const onChangeInstallment = (event) => {
    const monthlySelectedPlan = Number(event.target.value);
    dispatch(
      selectInstallmentsPlan({
        ...paymentPlans.visaMc[monthlySelectedPlan],
        months: monthlySelectedPlan,
        card: 'visaMc',
      }),
    );
  };

  return (
    <>
      <form id={formId} className="checkout-section">
        <div className="form-item-wrap">
          <div className="form-item-wrap-l">
            <input
              type="text"
              id={fields.cardholderName.id}
              className={getValidationFeedbackClass(fields.cardholderName)}
              ref={holderNameInputRef}
              defaultValue={initialHolderData.name}
            />
          </div>
          <div className="form-item-wrap-r">
            <div
              id={fields.cardNumber.id}
              className={getValidationFeedbackClass(fields.cardNumber)}
            />
          </div>
        </div>
        <div className="form-item-wrap">
          <div className="form-item-wrap-l">
            <div
              id={fields.expirationDate.id}
              className={getValidationFeedbackClass(fields.expirationDate)}
            />
          </div>
          <div className="form-item-wrap-l">
            <div
              id={fields.securityCode.id}
              className={getValidationFeedbackClass(fields.securityCode)}
            />
          </div>
          <div className="form-item-wrap-r">
            <PaymentImage type="cvv" />
          </div>
        </div>
        <div className="form-item-wrap form-item-select">
          <select
            id={fields.installments.id}
            className={getValidationFeedbackClass(fields.installments)}
            onChange={onChangeInstallment}
          />
        </div>
        <div className="form-item-wrap">
          <div
            className="form-item-wrap-l form-item-select"
            style={{
              display: identificationTypes.length ? 'block' : 'none',
            }}
          >
            <select
              id={fields.identificationType.id}
              className={getValidationFeedbackClass(fields.identificationType)}
              ref={identificationTypeInputRef}
              onChange={validateDocumentNumber}
            />
          </div>
          <div
            className="form-item-wrap"
            style={{
              display: identificationTypes.length ? 'block' : 'none',
            }}
          >
            <Spacing size="S" vertical>
              <input
                type="text"
                id={fields.identificationNumber.id}
                className={getValidationFeedbackClass(fields.identificationNumber)}
                ref={identificationNumberInputRef}
                defaultValue={initialHolderData.identificationNumber}
                onChange={validateDocumentNumber}
              />
              <Text size="XS" color="accent">
                {t('passengers:instructions.cpf_or_cnpj_or_rg')}
              </Text>
            </Spacing>
          </div>
          <div className="form-item-wrap-r">
            <input
              type="email"
              id={fields.cardholderEmail.id}
              className={getValidationFeedbackClass(fields.cardholderEmail)}
              ref={holderEmailInputRef}
              defaultValue={initialHolderData.email}
            />
          </div>
        </div>
        <select id={fields.issuer.id} hidden />
      </form>
    </>
  );
};

MercadoPagoTDCForm.propTypes = propTypes;

export default MercadoPagoTDCForm;
