import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { unlockSeats } from '@/actions/purchase';
import config from 'config/environment';

const useUnlockSeats = () => {
  const location = useLocation();
  const purchase = useSelector(({ purchase }) => purchase.toJS());
  const payment = useSelector(({ payment }) => payment.toJS());

  // Event that is fired on beforeunload
  // This sends a beacon to purchase api for unlock seats on current token
  const beforeUnloadCallback = useCallback(() => {
    if (
      location.pathname.includes('purchase') ||
      location.pathname.includes('payment') ||
      location.pathname.includes('sso/login') ||
      location.pathname.includes('sso/logout')
    ) {
      return;
    }
    navigator.sendBeacon(`${config.api.purchaseUrl}/v2/purchases/${purchase.token}/unlock_seats`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase.token]);

  useEffect(() => {
    // Fire event when closing tab or browser
    if (
      window &&
      purchase.loaded &&
      purchase.token &&
      purchase.passengers?.length > 0 &&
      purchase.state !== 'completed' &&
      purchase.state !== 'pending' &&
      payment.status !== 'pending'
    ) {
      // Remove previous event before create a new one
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      // Create event with beacon call to unlock seats
      window.addEventListener('beforeunload', beforeUnloadCallback, { once: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchase.token,
    purchase.state,
    purchase.passengers?.length,
    purchase.loaded,
    payment.status,
  ]);

  // Remove event listener if purchase waiting for payment or complete
  useEffect(() => {
    if (
      window &&
      purchase.token &&
      (purchase.state === 'completed' ||
        purchase.state === 'pending' ||
        payment.status === 'pending' ||
        payment.status === 'attempt')
    ) {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase.state, payment.status]);

  useEffect(() => {
    if (
      window &&
      !location.pathname.includes('purchase') &&
      !location.pathname.includes('payment') &&
      !location.pathname.includes('sso/login') &&
      !location.pathname.includes('sso/logout')
    ) {
      // Unlock seats of the last purchase
      const lastStoredToken = window.sessionStorage?.getItem('lastPurchaseToken');
      if (lastStoredToken) {
        unlockSeats({ purchaseToken: lastStoredToken });
        window.sessionStorage?.removeItem('lastPurchaseToken');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default useUnlockSeats;
