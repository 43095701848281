import React from 'react';
import './KushkiCashLogos.scss';

const KushkiCashLogos = () => (
  <div className="kushkicash-logos-grid">
    <i className="carulla-logo" />
    <i className="consuerte-logo" />
    <i className="coopenessa-logo" />
    <i className="exito-logo" />
    <i className="jer-logo" />
    <i className="moviired-logo" />
    <i className="suchance-logo" />
    <i className="superinter-logo" />
    <i className="surtimax-logo" />
    <i className="surtimayorista-logo" />
    <i className="edeq-logo" />
    <i className="fullcarga-logo" />
  </div>
);

export default KushkiCashLogos;
