import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, OutlineButton, Spacing, Text } from '@reservamos/elements';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { createSSOLoginUrl } from 'utils/doters';
import { trackEvent } from 'user-analytics';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import doters from '../../images/doters/logo-white.svg';
import dotersGreen from '../../images/doters/greendoters.svg';
import dotersPoints from '../../images/doters/doters-points.svg';

export const COOKIE_NAME = 'doters-info-modal'; // Doters info cookie name

// TODO - Temporary modal will show again in 1 minute. Jira issue - https://reservamossaas.atlassian.net/browse/ENT-621
const EXPIRES = 1 / 24 / 60; // Cookie expire time. Expires in 1 minute

/**
 * Item to be rendered as bullet list
 * @param {string} text - Text content to render
 * @returns JSX Element
 */
const Item = ({ text }) => {
  if (!text) return <></>;
  return (
    <Spacing alignItems="center" size="S">
      <Icon type={dotersGreen} size="S" />
      <Text alignItems="center" mobileSize="S" color="grayMedium">
        {text}
      </Text>
    </Spacing>
  );
};

/**
 * Component that handles the doters info modal and the interaction of it
 * @param {*} param
 * @param {bool} param.isLogged - Is the user already logged to doters?
 * @param {Function} param.onModalClose - Function to execute when modal is closed
 * @param {bool} param.visible - Is the modal visible?
 * @param {React.Component} param.modalTrigger - Component that will trigger the modal on click
 * @param {bool} param.defaultOpened - Is the modal opened by default?
 * @returns JSX Component
 */
const DotersInfo = ({ isLogged, onModalClose, visible, modalTrigger, defaultOpened = false }) => {
  const { t } = useTranslation('loyalty');
  const features = useWhitelabelFeatures();

  const willOpenModalOnMount =
    !Cookies.get('token') && !(Cookies.get(COOKIE_NAME) === 'closed') && defaultOpened;

  /**
   * Set a cookie for EXPIRES time to avoid auto open this modal again
   */
  const handleOnModalClose = () => {
    trackEvent('Doters info modal was shown', { type: willOpenModalOnMount ? 'auto' : 'click' });
    Cookies.set(COOKIE_NAME, 'closed', { expires: EXPIRES });

    if (typeof onModalClose === 'function') {
      onModalClose();
    }
  };

  /**
   * Redirect to doters auth
   */
  const handleAuthPress = () => {
    trackEvent('Loyalty Program Sign In Click');
    const url = createSSOLoginUrl(0);
    window.open(url, '_self');
  };

  /**
   * Redirect to doters signup
   */
  const handleRegisterPress = () => {
    trackEvent('Loyalty Program Sign Up Click');
    const url = createSSOLoginUrl(1);
    window.open(url, '_self');
  };

  const labels = [
    !isLogged && t('benefits_doters.join_points', { points: 2000 }),
    t('benefits_doters.win_by_mxn'),
    t('benefits_doters.reward_ticket', { context: features.FLAT_FARE_CONTEXT }),
    t('benefits_doters.priority'),
    t('benefits_doters.packaging_discount'),
  ];

  if (!willOpenModalOnMount && !visible && !modalTrigger) {
    return null;
  }

  return (
    <Modal
      headerColor="loyalty"
      headerImg={<img src={doters} />}
      zIndex={2999999999}
      defaultOpened={willOpenModalOnMount}
      modalTrigger={modalTrigger}
      onCloseModal={handleOnModalClose}
      footer={
        !isLogged && (
          <Spacing justifyContent="space-between" size="XS" responsiveSize="S" isResponsive>
            <Button
              text={t('join_free')}
              variant="loyaltySession"
              mobileSize="S"
              isRounded
              onClick={handleRegisterPress}
            />
            <OutlineButton
              optionalColor="grayStrong"
              text={t('login')}
              variant="loyalty"
              mobileSize="S"
              isRounded
              onClick={handleAuthPress}
            />
          </Spacing>
        )
      }
      responsiveSize="S"
    >
      <Spacing textAlign="center" vertical alignItems="center" size="S">
        <Spacing size="S" mobileSize="S" vertical>
          <Text weight="semibold" color="grayMedium" textAlign="center" mobileSize="M">
            {t('about', { context: 'doters' })}
          </Text>
          <img src={dotersPoints} height="70px" />
          {labels.map((text) => (
            <Item text={text} />
          ))}
        </Spacing>
      </Spacing>
    </Modal>
  );
};

Item.propTypes = {
  text: PropTypes.string.isRequired,
};

DotersInfo.propTypes = {
  isLogged: PropTypes.bool,
  onModalClose: PropTypes.func,
  visible: PropTypes.bool,
  modalTrigger: PropTypes.node,
  defaultOpened: PropTypes.bool,
};

DotersInfo.defaultProps = {
  isLogged: false,
  visible: false,
  modalTrigger: null,
  defaultOpened: false,
};

export default DotersInfo;
