import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';

const SectionTitle = ({ title, description }) => (
  <Spacing size="XS" vertical>
    <Text size="XL" weight="bold">
      {title}
    </Text>
    {Boolean(description) && <Text>{description}</Text>}
  </Spacing>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

SectionTitle.defaultProps = {
  description: '',
};

export default SectionTitle;
