// @ts-check
import cybersource from '../../../engines/cybersource';

/**
 * @typedef {import('./CardStrategy').CardStrategy} CardStrategy
 */

/**
 * Cybersource card strategy.
 * @function CybersourceCardStrategy
 * @returns {CardStrategy}
 */
const CybersourceStrategy = () => {
  /**
   * Create payload for Cybersource.
   */
  const createPayload = (cardParams, { paymentGatewayToken }) => {
    return cybersource.createToken(cardParams, paymentGatewayToken);
  };

  return { createPayload };
};

const cybersourceStrategy = CybersourceStrategy();

export default cybersourceStrategy;
