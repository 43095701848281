import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import MessageBox from '../../ui/atoms/MessageBox';
import openTicketETN from '../../images/brands-unique/etn/openTicketETN.svg';
import openTicketGFA from '../../images/brands-unique/gfa/open-ticket.svg';

const OpenTicketMessage = ({ isLoggedIn }) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');

  const brandIcon = {
    gfa: openTicketGFA,
    default: openTicketETN,
  };

  const currentBrandIcon = brandIcon[env.brand] ?? brandIcon.default;

  const purchase = useSelector((state) => state.purchase.toJS());
  const { departs, returns, roundTrip } = purchase;
  const departureFragment = departs?.fragments?.[0] ?? null;
  const returnsFragment = returns?.fragments?.[0] ?? null;

  const isDepartureOpen = departureFragment?.openTicket ?? false;
  const isReturnOpen = returnsFragment?.openTicket ?? false;
  const hasOpenTicketTrips = isDepartureOpen || isReturnOpen;

  if (!hasOpenTicketTrips) return null;

  const departureService = isDepartureOpen ? departureFragment?.service ?? null : null;
  const returnService = isReturnOpen ? returnsFragment?.service ?? null : null;

  let title = t('label.open_ticket_message');

  if (features.MULTI_OPEN_TICKETS) {
    title += t('label.open_ticket_message_multi');

    if (roundTrip) {
      if (departureService) {
        if (returnService) {
          title += t('label.open_ticket_departure_service', {
            service: t('services', { context: departureService }),
          });
        } else {
          title += t('label.open_ticket_service', {
            service: t('services', { context: departureService }),
          });
        }
      }

      if (returnService) {
        if (departureService) {
          title += t('label.open_ticket_return_service', {
            service: t('services', { context: returnService }),
          });
        } else {
          title += t('label.open_ticket_service', {
            service: t('services', { context: returnService }),
          });
        }
      }
    } else {
      title += t('label.open_ticket_service', {
        service: t('services', { context: departureService || returnService }),
      });
    }
  }

  return (
    <MessageBox icon={currentBrandIcon} title={title}>
      <Spacing size="S" vertical>
        <Text size="S">{t('text.open_ticket_description', { context: env.brand })}</Text>
        {isLoggedIn && !features.CAN_USE_POINTS_OPEN_TICKET && (
          <Text size="S" weight="semibold">
            {t('text.open_ticket_points')}
          </Text>
        )}
      </Spacing>
    </MessageBox>
  );
};

OpenTicketMessage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default OpenTicketMessage;
