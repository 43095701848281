import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spacing, Icon, Text, ToggleSwitch } from '@reservamos/elements';

/**
 * Component for rendering a trip filter option (pet friendly, woman seats, direct).
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.notAvailable - Indicates whether the trip option is not available.
 * @param {Function} props.onChange - The function to be called when the trip option changes.
 * @param {boolean} props.isActive - Indicates whether the trip option is active or not.
 * @param {string} props.value - The value of the trip option.
 * @param {string} props.icon - The icon of the trip option.
 * @param {string} props.primaryText - The primary text of the trip option.
 * @param {string} props.secondaryText - The secondary text of the trip option.
 * @param {string} props.type - The type of the trip option.
 * @param {string} props.i18Key - The translation key for the label.
 * @returns {JSX.Element} The rendered component.
 */
const TripOption = ({
  notAvailable,
  onChange,
  isActive,
  value,
  icon,
  primaryText,
  secondaryText,
  filterType,
  i18Key,
}) => {
  const [isChecked, setIsChecked] = useState(isActive);

  /**
   * Handles the click event of the trip option.
   */
  const handleOnClick = () => {
    onChange({ filterType, label: primaryText, value, isActive: !isChecked, i18Key });
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsChecked(isActive);
  }, [isActive]);

  return (
    <Spacing size="XS" justifyContent="space-between" alignItems="center">
      <Spacing size="XS" alignItems="center">
        <Icon
          type={notAvailable ? 'BlockNotAvailable' : icon}
          size="S"
          color={notAvailable ? 'error' : 'primary'}
        />
        <Text weight="semibold" color={notAvailable ? 'grayMedium' : 'grayStrong'}>
          {primaryText}
        </Text>
        {secondaryText && (
          <Text color={notAvailable ? 'grayMedium' : 'grayStrong'}>{secondaryText}</Text>
        )}
      </Spacing>
      <ToggleSwitch
        id={`trip-option-filter-${value}`}
        fillBackground
        onChange={handleOnClick}
        checked={isChecked}
        disabled={notAvailable}
      />
    </Spacing>
  );
};

TripOption.propTypes = {
  notAvailable: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  value: PropTypes.string.isRequired,
  filterType: PropTypes.string,
  i18Key: PropTypes.string,
};

export default TripOption;
