import { useContext } from 'react';
import MercadoPagoInstanceContext from './MercadoPagoInstanceContext';

const useMercadoPagoInstanceContext = () => {
  const contextValue = useContext(MercadoPagoInstanceContext);

  if (!contextValue) {
    throw new Error(
      'MercadoPagoInstanceContext value is undefined, you likely forgot to use MercadoPagoInstanceProvider',
    );
  }

  return contextValue;
};

export default useMercadoPagoInstanceContext;
