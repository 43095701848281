import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spacing, Text, Card } from '@reservamos/elements';
import Header from 'components/Header';
import { useTranslation } from 'react-i18next';
import TicketsList from './PurchasesList';
import BrandLogo from '../BrandLogo';
import LoadingScreen from '../LoadingScreen';
import './PurchaseHistoryStyle';

/** DownloadTicketsList view that contains the ticket list */
function PurchasesHistoryView() {
  const history = useHistory();
  const ticketsState = useSelector((state) => state.tickets);
  const { t: tG } = useTranslation('general');

  const { error, isLoading, tickets, userData } = ticketsState.toJS();
  const { firstName, lastName, secondLastName, email } = userData;

  if ((error || !tickets.length) && !isLoading) history.replace('/download-tickets');

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="l-app-home">
      <Header fixed>
        <BrandLogo />
      </Header>

      <div className="home-container">
        <div className="tickets-list-container">
          <Card padding="XL">
            <Spacing vertical>
              <Spacing vertical size="XL">
                <Text size="XL" weight="bold">
                  {tG('print_tickets')}
                </Text>
                <Text size="L" weight="semibold">
                  {`${firstName} ${lastName} ${secondLastName}`}
                </Text>
              </Spacing>
              <Spacing size="S" vertical>
                <TicketsList tickets={tickets} email={email} />
              </Spacing>
            </Spacing>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PurchasesHistoryView;
