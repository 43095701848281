/**
 * Get unique adjacent seats.
 * @param {Array} seatsWithAdjacent - adjacent seats array as [[1,2],[3,4]]
 * @returns {Set} - unique adjacent seats count
 */
const getUniqueAdjacentSeats = (seatsWithAdjacent) => {
  const uniquePairs = new Set();

  seatsWithAdjacent.forEach((seat) => {
    const [seatA, seatB] = [seat.number, seat.adjacentSeats.numbers[0]];
    if (!seatA || !seatB) return uniquePairs;
    const pair = [seatA, seatB].sort((a, b) => a - b).join(',');
    uniquePairs.add(pair);
  });

  return uniquePairs;
};

/**
 * This function determines if the toggle should be turn on or off.
 * Returns true only if we have two adjacent seats picked
 * @param {Array} selectedSeatFiltered
 * @returns {boolean}
 */
const shouldTurnOnToggle = (selectedSeatFiltered) => {
  const hasOneAdjacentSeatPicked =
    selectedSeatFiltered.some((seat) => {
      return seat.isPickedAsAdjacent;
    }) &&
    selectedSeatFiltered.some((seat) => {
      return seat.isAdjacentPicked;
    });
  return hasOneAdjacentSeatPicked;
};

/**
 *This function determines the visibility of an adjacent card based on the selected seats by calculating the unique pairs of adjacent seats.
 * @param {Array} seatsSelected
 * @returns {boolean}
 */
const calculateAdjacentCardVisibility = (seatsSelected) => {
  const selectedSeatFiltered = seatsSelected.filter((selectedSeat) => selectedSeat.adjacentSeats);
  const uniques = getUniqueAdjacentSeats(selectedSeatFiltered);
  const isAdjacentCardVisible = uniques.size === 1;
  const shouldTurnOnToggleValue = shouldTurnOnToggle(selectedSeatFiltered);
  return {
    isAdjacentCardVisible,
    shouldTurnOnToggleValue,
  };
};

export default calculateAdjacentCardVisibility;
