import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spacing, Box, Text, Button } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DownloadButton from '../../ui/atoms/DownloadButton';
import { sendEmail, setEmailStatus } from '../../actions/purchase';
import { setError } from '../../actions';

/** Ticket List that show the purchase of the user */
const PurchasesList = ({ tickets, email }) => {
  const { t: tP } = useTranslation('purchase');
  const dispatch = useDispatch();
  const purchase = useSelector((state) => state.purchase);
  const { sentEmailStatus } = purchase.toJS();

  useEffect(() => {
    if (sentEmailStatus === 'sent') {
      dispatch(setError(200, 'tickets_send', 'success', false, null, null, email));
      dispatch(setEmailStatus(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentEmailStatus]);

  const handleSendEmail = (token) => () => {
    dispatch(sendEmail(token, email));
  };

  return tickets.map((ticket) => {
    const { route, departureDate, token, ticketPdfUrls } = ticket;
    return (
      <Box
        key={token}
        paddingHorizontal="S"
        paddingVertical="S"
        hasRadius
        borderRadius="S"
        hasShadow
      >
        <Spacing justifyContent="space-between" alignItems="center" isResponsive>
          <Spacing vertical size="S">
            <Text weight="semibold">{route}</Text>
            <Spacing isResponsive size="S">
              <Text size="S">
                {`${tP('label.departure')}: ${moment(departureDate).format('DD/MM/YYYY LT')}`}
              </Text>
              <Text size="S">
                {tP('label.purchase_token')}: {token}
              </Text>
            </Spacing>
          </Spacing>
          <Spacing isResponsive responsiveFullWidth>
            <DownloadButton label={tP('ticket_departure_simple')} href={ticketPdfUrls[0]} />
            {ticketPdfUrls.length > 1 && (
              <DownloadButton label={tP('ticket_return_simple')} href={ticketPdfUrls[1]} />
            )}
            <Button text={tP('resend_tickets')} variant="accent" onClick={handleSendEmail(token)} />
          </Spacing>
        </Spacing>
      </Box>
    );
  });
};

export default PurchasesList;
