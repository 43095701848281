import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StepStatus from '../../../ui/atoms/StepStatus';

const propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
  transporterType: PropTypes.string,
  isExchange: PropTypes.bool.isRequired,
};

const PaymentStepStatus = ({ status, title, transporterType, email, isExchange }) => {
  const { t } = useTranslation('purchase');

  const currentTitle = isExchange && title === 'payment' ? `${title}_exchange` : title;

  return (
    <StepStatus
      status={status}
      title={t('payment_step', {
        context: title === 'sent' ? `${title}_${transporterType}` : currentTitle,
      })}
      email={email}
      context={t('payment_step_status', { context: status })}
    />
  );
};

PaymentStepStatus.propTypes = propTypes;

export default PaymentStepStatus;
