import React from 'react';
import PropTypes from 'prop-types';
import { Spacing } from '@reservamos/elements';
import 'styles/components/ExchangeLayout';
import Header from 'components/Header';
import BrandLogo from 'components/BrandLogo';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

/**
 * ExchangeLayout Functional Component
 *
 * A layout component that renders a sidebar alongside the main content.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The main content to be displayed in the layout.
 * @param {React.ReactNode} props.sidebar - The sidebar content to be displayed alongside the main content.
 *
 * @returns {JSX.Element} The rendered layout component with the sidebar and main content.
 */
const ExchangeLayout = ({ children, sidebar }) => {
  const location = useLocation();
  return (
    <div className="l-exchange">
      <Header isFixed>
        <BrandLogo />
      </Header>
      <div className="exchange-container">
        <div className="exchange-content">
          <Spacing vertical size="XL">
            {children}
          </Spacing>
        </div>
        {!location.pathname.includes('ticket-list') && (
          <div className="exchange-sidebar">
            <div className="exchange-sidebar-wrapper">{sidebar}</div>
          </div>
        )}
      </div>
    </div>
  );
};

ExchangeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
};

export default ExchangeLayout;
