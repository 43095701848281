export default ({ type, hasTransit, name, cityName }) => {
  let transferType;

  if (hasTransit) {
    if (type === 'terminal') {
      transferType = `trasladarte a la terminal: ${name}`;
    } else {
      transferType = `trasladarte al aeropuerto: ${name}`;
    }
  } else {
    transferType = `abordar otro ${type === 'terminal' ? 'autobús' : 'avión'} en la misma terminal`;
  }

  return `Para tomar la conexión es necesario ${transferType}, en ${cityName}`;
};
