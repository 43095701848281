import React from 'react';
import './LoadingDots.scss';

const LoadingDots = () => (
  <div className="rvui-loading-dots">
    <span />
  </div>
);

export default LoadingDots;
