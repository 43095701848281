import React from 'react';
import PropTypes from 'prop-types';
import { IconText, Spacing, Text } from '@reservamos/elements';

const CheckList = ({ labels, title, disclaimer }) => {
  return (
    <Spacing vertical>
      {Boolean(title) && (
        <Text size="S" weight="semibold">
          {title}
        </Text>
      )}
      <Spacing vertical size="S">
        {labels.map((label) => (
          <IconText
            key={label}
            label={label}
            iconType="CheckmarkAccent"
            fontSize="S"
            iconSize="S"
          />
        ))}
      </Spacing>
      {Boolean(disclaimer) && <Text size="XS">{`** ${disclaimer}`}</Text>}
    </Spacing>
  );
};

CheckList.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  disclaimer: PropTypes.string,
};

CheckList.defaultProps = {
  title: '',
  disclaimer: '',
};

export default CheckList;
