/* eslint-disable array-callback-return */
// This rule is disabled since the 'every' function is from the insertion query API,
// not a JS array

/* global $ */
import insertionQ from 'insertion-query';

const smartbannerSelector = 'div.smartbanner';
const headerSelector = 'div.header-fixed';
const overlayViewSelector = 'div.overlay-view';
const timerSelector = 'div.timer-fixed';

$(document).ready(() => {
  // check if function is available before registering events
  if (!(insertionQ && insertionQ('.smartbanner') && insertionQ('.smartbanner').every)) {
    // something's wrong, remove banner, if present
    $(smartbannerSelector).remove();
    $('html').css('margin-top', 0);
    $(headerSelector).removeClass('smartbanner-hack');
    $(overlayViewSelector).removeClass('smartbanner-hack');
    $(timerSelector).removeClass('smartbanner-hack');

    return;
  }

  // add hack-class to header + overlay-view when the smartbanner is inserted
  insertionQ('.smartbanner').every((el) => {
    $(headerSelector).addClass('smartbanner-hack');
    $(overlayViewSelector).addClass('smartbanner-hack');
    $(timerSelector).addClass('smartbanner-hack');

    // add click listener for dismiss click
    $(el)
      .find('.smartbanner__exit')
      .on('click', () => {
        // remove hack-class to header + overlay-view
        $(headerSelector).removeClass('smartbanner-hack');
        $(overlayViewSelector).removeClass('smartbanner-hack');
        $(timerSelector).removeClass('smartbanner-hack');
      });

    // add click listener for dismiss click
    $(el)
      .find('.smartbanner__button')
      .on('click', () => {
        // remove hack-class to header + overlay-view
        $(headerSelector).removeClass('smartbanner-hack');
        $(overlayViewSelector).removeClass('smartbanner-hack');
        $(timerSelector).removeClass('smartbanner-hack');
      });
  });

  // add hack-class to a header when it's inserted
  insertionQ(headerSelector).every((el) => {
    if ($(smartbannerSelector).length) {
      $(el).addClass('smartbanner-hack');
    }
  });

  // add hack-class to an overlay-view when it's inserted
  insertionQ(overlayViewSelector).every((el) => {
    if ($(smartbannerSelector).length) {
      $(el).addClass('smartbanner-hack');
    }
  });

  // add hack-class to an timer when it's inserted
  insertionQ(timerSelector).every((el) => {
    if ($(smartbannerSelector).length) {
      $(el).addClass('smartbanner-hack');
    }
  });
});
