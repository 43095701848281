import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import DiscountRowItem from './DiscountRowItem';

/**
 * Sort the availability categories by priority.
 *
 * @param {object} a - The first availability category to compare.
 * @param {object} b - The second availability category to compare.
 * @returns {number} - The result of the comparison.
 */
const defaultSort = (a, b) => a.priority - b.priority;

/**
 * AvailabilityDiscounts component
 *
 * Render a list of availability discounts.
 *
 * If the title is not in translations, it will show generic version.
 * Otherwise, it will show the title depending on the direction of the trip.
 *
 * If the prop related to the title visibility is not passed,
 * it will be shown by default.
 * Otherwise, it will be shown depending on the value of the prop.
 *
 * @param {object} props - availabilityCategories, titleContext, isTitleShown
 * @param {Array<object>} props.availabilityCategories - The availability categories to display.
 * @param {string} props.titleContext - The context of the title.
 * @param {boolean} props.isTitleShown - Whether the title should be shown.
 */
const AvailabilityDiscounts = ({ availabilityCategories, titleContext, isTitleShown }) => {
  const { t } = useTranslation();
  const sortedAvailabilityCategories = availabilityCategories.sort(defaultSort);
  const discountsTitle = t('search:trip_discount_title', { context: titleContext });

  return (
    <Spacing size="S" vertical>
      {isTitleShown && <Text weight="bold">{discountsTitle}</Text>}
      <div>
        {sortedAvailabilityCategories.map((category) => {
          const categoryType = t('category', { context: category?.type });

          return (
            <DiscountRowItem
              key={categoryType}
              label={categoryType}
              availability={category?.availability}
              total={category?.total}
            />
          );
        })}
      </div>
    </Spacing>
  );
};

AvailabilityDiscounts.propTypes = {
  availabilityCategories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      availability: PropTypes.number.isRequired,
      priority: PropTypes.number.isRequired,
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ),
  titleContext: PropTypes.string,
  isTitleShown: PropTypes.bool,
};

AvailabilityDiscounts.defaultProps = {
  titleContext: '',
  isTitleShown: true,
};

export default AvailabilityDiscounts;
