import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Icon, Badge, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { getTripDiscountPercent } from 'utils/Helpers';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { discountTypes, earlyDiscountTypes } from 'models/parseTrip';
import { isIAMSA } from 'utils/Reserbus';

/**
 * Renders the discount advice component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.discountType - The type of discount.
 * @param {number} props.discountAvailability - The availability of the discount.
 * @param {number} props.discountAmount - The provider discount.
 * @param {number} [props.discountPercent] - The discount percentage from provider.
 * @param {number} props.tripTotal - The total trip amount.
 * @param {string} props.way - The trip way.
 * @returns {JSX.Element} The rendered component.
 */
const TripDiscountAdvice = ({
  discountType,
  discountAvailability,
  discountAmount = 0,
  discountPercent = 0,
  tripTotal,
  way,
}) => {
  const { brand } = useWhitelabelEnvs();
  const { t } = useTranslation('trips');

  const isIAMSABrand = isIAMSA(brand);
  const showAvailabilityAndDiscount = !isIAMSABrand;
  const availabilityContext = isIAMSABrand ? 'iamsa' : brand;

  let discountPercentToShow = '';
  // If discountPercent is provided directly, we use it.
  // This improvement is made in order to show percentage directly from dynamic pricing
  // It avoids percentage calculation mismatch between client and server on small values
  if (discountPercent) {
    discountPercentToShow = `${discountPercent}%`;
  } else if (discountAmount) {
    discountPercentToShow = getTripDiscountPercent({ tripTotal, discountAmount, way });
  }

  const discountAvailabilityText =
    discountAvailability && discountAvailability <= 5
      ? `${t('trips:label.places', { count: discountAvailability, context: availabilityContext })}`
      : null;

  const discountLabel = discountTypes[discountType]?.inLabel;
  const discountText = discountLabel
    ? `${t(discountLabel, { context: brand })} ${discountPercentToShow}`
    : discountPercentToShow;

  const discountIcon = discountTypes[discountType]?.icon?.[brand];
  if (!earlyDiscountTypes.includes(discountType)) {
    return null;
  }
  return (
    <Spacing size="XS" alignItems="center">
      {discountIcon && <Icon type={discountIcon} size="M" mobileSize="S" />}

      {(showAvailabilityAndDiscount || discountAvailabilityText) && (
        <Badge type="discount" alphaBg="S" roundCorners>
          <Text elementType="span" size="XS" mobileSize="XXS" color="discount" weight="bold" italic>
            {showAvailabilityAndDiscount && `${discountText} `}
            {discountAvailabilityText && (
              <Text
                elementType="span"
                size="XS"
                mobileSize="XXS"
                color="discount"
                weight="regular"
                italic
              >
                {discountAvailabilityText}
              </Text>
            )}
          </Text>
        </Badge>
      )}
    </Spacing>
  );
};

TripDiscountAdvice.propTypes = {
  discountType: PropTypes.string,
  discountAvailability: PropTypes.number,
  discountAmount: PropTypes.number,
  discountPercent: PropTypes.number,
  tripTotal: PropTypes.number,
  way: PropTypes.string,
};

export default TripDiscountAdvice;
