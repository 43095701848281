import store from '@/store';

/**
 * Checks if the price alerts feature is enabled.
 * @returns {boolean} - True if the feature is enabled, false otherwise.
 */
const isPriceAlertEnabled = () => {
  const { features } = store.getState().whitelabelConfig;
  return features.PRICE_ALERTS_ENABLED ?? false;
};

export default isPriceAlertEnabled;
