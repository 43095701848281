import React, { useState, useEffect } from 'react';
import { Alert, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import LoyaltyButton from 'components/LoyaltyButton';
import './DotersSessionAlert.scss';

/**
 * Component for rendering an alert to notify users that their Doters session has closed
 * and prompt them to log in again to enjoy the benefits.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const DotersSessionAlert = () => {
  const { t } = useTranslation('loyalty');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // This function checks for the presence of the 'loyalty_with_redirect' parameter in the URL query.
    // It is true when there is an attempt to automatically log in the user, which may not always succeed.
    const queryParams = new URLSearchParams(window.location.search);
    const loyaltyWithRedirect = queryParams.get('loyalty_with_redirect') === 'attempt';
    setIsVisible(loyaltyWithRedirect);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Alert alertType="info" title={t('session_alert.title')} padding="S" iconSize="S">
      <div className="session-alert-container">
        <Text mobileSize="S">{t('session_alert.body')}</Text>
        <LoyaltyButton
          id="loyalty-button-redirect"
          visible
          isLink
          buttonText={t('session_alert.cta')}
          hideIcon
          hideButtonLogo
          hideSigninText
        />
      </div>
    </Alert>
  );
};

export default DotersSessionAlert;
