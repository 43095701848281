import { connect } from 'react-redux';
import PaymentForm from './PaymentForm';

const mapStateToProps = ({ cards, siemprePlus, purchase, whitelabelConfig: { features } }) => {
  const cardsJs = cards.toJS();
  const { cards: cardList, payWithAnotherCard, isLoading, isUpdated, isAvailable } = cardsJs;

  const siemprePlusJS = siemprePlus.toJS();
  const { user } = siemprePlusJS;

  let showSavedCards = false;
  let showAdyenForm = false;
  let showCheckbox = true;

  if (features.ALLOW_TO_SAVE_CARDS && isAvailable) {
    if (user) {
      if (!isLoading && isUpdated) {
        if (cardList.length > 0) {
          showSavedCards = true;
        } else {
          showAdyenForm = true;
        }
      }

      if (payWithAnotherCard) {
        showAdyenForm = true;
      }
    } else {
      showAdyenForm = true;
    }
  } else {
    showAdyenForm = true;
    showCheckbox = false;
  }

  return {
    showSavedCards,
    payWithAnotherCard,
    showAdyenForm,
    paymentEngine: purchase.get('paymentEngine'),
    showCheckbox,
    purchaseTotal: purchase.get('total'),
    isExchange: purchase.get('isExchange'),
  };
};

export default connect(mapStateToProps)(PaymentForm);
