import { v4 as uuidv4 } from 'uuid';

let inMemoryUuid = uuidv4();

// if the browser doesn't support sessionStorage
// the id stored in memory is used
export function setInitialSessionId() {
  if (!window.sessionStorage) return;

  if (!window.sessionStorage.getItem('reservamos.sessionId')) {
    window.sessionStorage.setItem('reservamos.sessionId', uuidv4());
  }

  return window.sessionStorage.getItem('reservamos.sessionId');
}

// returns the id stored in memory if the browser
// doesn't support sessionStorage
export function getSessionId() {
  if (!window.sessionStorage) return inMemoryUuid;

  return window.sessionStorage.getItem('reservamos.sessionId');
}

// if the browser doesn't support sessionStorage
// the id stored in memory is reset
export function resetSessionId() {
  const newId = uuidv4();

  if (window.sessionStorage) {
    window.sessionStorage.setItem('reservamos.sessionId', newId);
  } else {
    inMemoryUuid = newId;
  }

  return newId;
}

/**
 * This method triggers the execution of the sign out method
 * that is provided by the siempreplus widget.
 */
export function triggerSignOutWidget(loyaltyWidgetName) {
  window.reservamosPubSub.pub(`${loyaltyWidgetName}-signOut`);
}

/**
 * This method triggers the execution of the sign in method
 * provided by the siempreplus widget.
 */
export function triggerSignInWidget(loyaltyWidgetName) {
  window.reservamosPubSub.pub(`${loyaltyWidgetName}-signIn`);
}
