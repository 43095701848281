import React from 'react';
import PropTypes from 'prop-types';
import { Gap, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import FilterChip from '../../../ui/atoms/FilterChip';
import filtersTypes from '../../../constants/filtersTypes';

/**
 * Component for rendering a list of category options.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSelectOption - The function to be called when an option is selected.
 * @param {Array} props.categoriesFilterSelected - The array of selected category filter values.
 * @param {Array} props.options - The array of category options to be rendered.
 * @returns {JSX.Element} The rendered component.
 */
const CategoriesOptionList = ({ onSelectOption, categoriesFilterSelected, options }) => {
  const { t } = useTranslation();

  if (!options || !options.length) return null;

  return (
    <Spacing size="S" vertical>
      <Text size="L" weight="bold" color="grayMedium">
        {t('search:label.filter_by_category')}
      </Text>
      <Gap columnGap="M" rowGap="S">
        {options.map((option) => (
          <FilterChip
            key={option.type}
            id={option.type}
            filterType={filtersTypes.CATEGORIES}
            i18Key={option.i18Key}
            text={t(option.i18Key)}
            icon={option.icon}
            value={option.type}
            onSelect={onSelectOption}
            active={categoriesFilterSelected.includes(option.type)}
            notAvailable={!option.hasAvailability}
          />
        ))}
      </Gap>
    </Spacing>
  );
};

CategoriesOptionList.propTypes = {
  onSelectOption: PropTypes.func,
  categoriesFilterSelected: PropTypes.array,
  options: PropTypes.array,
};

CategoriesOptionList.defaultProps = {
  options: [],
  categoriesFilterSelected: [],
};

export default CategoriesOptionList;
