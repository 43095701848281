const SHORT_ACCESS_FILTERS_LS = 'shortAccessFilters';

/**
 * Get short access filters from local storage.
 * @returns {Array} - Array of filters.
 */
export const getShortAccessFilters = () => {
  try {
    const shortAccessFilters = localStorage.getItem(SHORT_ACCESS_FILTERS_LS);
    if (!shortAccessFilters) return null;
    return JSON.parse(shortAccessFilters);
  } catch {
    return null;
  }
};

/**
 * Save short access filters in local storage.
 * @param {Array} filters - Array of filters to save.
 */
export const saveShortAccessFilters = (filters) => {
  localStorage.setItem(SHORT_ACCESS_FILTERS_LS, JSON.stringify(filters));
};
