import React from 'react';
import PropTypes from 'prop-types';
import { MessageBox, Text } from '@reservamos/elements';

/**
 * Message showed to the user when it is a mixed ticket.
 * @param {Object} props - Component props
 * @param {Object} props.t - hook to use the translations
 * @param {Boolean} props.isReturn - Flag to indicante which way is an open ticket
 * @param {String} props.messageJustAdult - Flag to show that it is just available the adult category on open ticket
 * @param {String} props.messageNotInsurance - Flag to show that it is not available the use of insurance in one the trips
 * @returns {Component}
 */
const OpenTicketNotice = ({ t, isReturn, messageJustAdult, messageNotInsurance }) => {
  return (
    <MessageBox
      borderColor="info"
      title={t(`payment:text.purchase_includes_open_ticket_${isReturn ? 'departure' : 'return'}`)}
    >
      {messageJustAdult && <Text size="S">{t('seats:open_ticket_just_adult')}</Text>}
      {messageNotInsurance && (
        <Text size="S">{t('payment:text.insurance_not_available_for_open_ticket')}</Text>
      )}
    </MessageBox>
  );
};

OpenTicketNotice.propTypes = {
  t: PropTypes.func.isRequired,
  isReturn: PropTypes.bool,
  messageJustAdult: PropTypes.bool,
  messageNotInsurance: PropTypes.bool,
};

OpenTicketNotice.defaultProps = {
  isReturn: false,
  messageJustAdult: false,
  messageNotInsurance: false,
};

export default OpenTicketNotice;
