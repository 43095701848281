import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@reservamos/elements';
import petBadge from 'images/badges/pet.svg';
import adjacentBadge from 'images/badges/adjacent-seat.svg';
import 'styles/components/SeatsLegend';

/**
 * Check if the layout has a seat type
 * @param {Array} layout
 * @param {String|Function} check
 * @returns
 */
function hasSeatType(layout, check) {
  if (!layout) return false;

  return layout.some((row) => {
    return row.some((seats) => {
      return seats.some((seat) => {
        return typeof check === 'function' ? check(seat) : seat.category === check;
      });
    });
  });
}

/**
 * SeatsLegend component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.layout - The layout of the seats.
 * @param {boolean} props.showWomanLegends - Whether to show woman legends.
 * @param {boolean} props.showPetFriendly - Whether to show pet-friendly legends.
 * @param {boolean} props.isExchange - Whether it is an exchange.
 * @param {number} props.occupied - The number of occupied seats.
 * @param {number} props.available - The number of available seats.
 * @param {number} props.selected - The number of selected seats.
 */
function SeatsLegend({
  layout,
  showWomanLegends,
  showPetFriendly,
  isExchange,
  occupied,
  available,
  selected,
}) {
  const { t } = useTranslation('seats');

  const hasHandicapSeat = hasSeatType(
    layout,
    (seat) => seat.category === 'seat_handicap' && !seat.occupied,
  );
  const hasDisabledSeats = hasSeatType(layout, 'seat_disabled');

  const hasAdjacentSeats =
    !isExchange && hasSeatType(layout, (seat) => seat.adjacentSeats && !seat.occupied);

  return (
    <div className="seats-legend-container">
      <div className="seats-legend-item">
        <i className="seat-available" />
        <p>{`${available} ${t('seat', { context: 'available', count: available })}`}</p>
      </div>

      <div className="seats-legend-item">
        <i className="seat-selected" />
        <p>{`${selected} ${t('seat', { context: 'selected', count: selected })}`}</p>
      </div>

      <div className="seats-legend-item">
        <i className="seat-occupied" />
        <p>{`${occupied} ${t('seat', { context: 'occupied', count: occupied })}`}</p>
      </div>

      {hasDisabledSeats && (
        <div className="seats-legend-item">
          <i className="seat-disabled" />
          <p>{t('seat', { context: 'seat_disabled' })}</p>
        </div>
      )}

      {showPetFriendly && (
        <div className="seats-legend-item">
          <Icon type={petBadge} />
          <p>{t('passengers:type.pet_friendly')}</p>
        </div>
      )}

      {hasAdjacentSeats && (
        <div className="seats-legend-item">
          <Icon type={adjacentBadge} />
          <p>{t('adjacent_seat.label')}</p>
        </div>
      )}

      {hasHandicapSeat && (
        <div className="seats-legend-item">
          <i className="seat-handicap-available" />
          <p>{t('seat', { context: 'handicap' })}</p>
        </div>
      )}

      {showWomanLegends && (
        <>
          <div className="seats-legend-item">
            <i className="woman-seat" />
            <p>{t('seat', { context: 'woman' })}</p>
          </div>
          <div className="seats-legend-item">
            <i className="woman-seat-selected" />
            <p>{t('seat', { context: 'selected_woman' })}</p>
          </div>
        </>
      )}
    </div>
  );
}

SeatsLegend.propTypes = {
  layout: PropTypes.array,
  showWomanLegends: PropTypes.bool,
  showPetFriendly: PropTypes.bool,
  isExchange: PropTypes.bool,
  occupied: PropTypes.number,
  available: PropTypes.number,
  selected: PropTypes.number,
};

SeatsLegend.defaultProps = {
  layout: [],
  showWomanLegends: false,
  showPetFriendly: false,
  isExchange: false,
  occupied: 0,
  available: 0,
  selected: 0,
};

export default SeatsLegend;
