import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { CurrentLang } from 'utils/lang';
import translations from '../translations';

export default function setupI18n() {
  const lng = CurrentLang();
  i18n.use(initReactI18next).init({
    debug: false,
    resources: translations,
    lng,
    returnObjects: true,
    ns: Object.keys(translations[lng]),
    defaultNS: 'general',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      defaultTransParent: 'div',
    },
  });
  return i18n;
}
