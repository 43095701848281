import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, FlatButton, Button, MessageBox } from '@reservamos/elements';
import { formatCurrency } from 'utils/Helpers';
import 'styles/components/purchase/TicketsPanel';
import 'styles/components/purchase/SendEmail';
import { useSelector } from 'react-redux';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import withPurchaseTimer from 'hocs/withPurchaseTimer';
import PaymentImage from '../../../ui/atoms/PaymentImage';
import Timer from '../Timer';

const pixInstructionsKeys = ['first', 'second', 'third', 'fourth'];

const propTypes = {
  timerRemainingTime: PropTypes.number.isRequired,
};

/**
 * Renders the PixPaymentInfo component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.timerRemainingTime - The remaining time for the timer.
 * @returns {JSX.Element} The rendered PixPaymentInfo component.
 */
const PixPaymentInfo = ({ timerRemainingTime }) => {
  const history = useHistory();
  const payment = useSelector((state) => state.payment.toJS());
  const purchase = useSelector((state) => state.purchase.toJS());
  const { reference, qrCode } = payment;
  const { total, isExpired } = purchase;
  const { t } = useTranslation('payment');
  const [hasCopiedCode, setHasCopiedCode] = useState(false);
  const amountDisplay = formatCurrency(total, 2);
  const { homeUrl } = useWhitelabelEnvs();

  /**
   * Handles the copy code action.
   */
  const onCopyCode = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(reference);
        setHasCopiedCode(true);
      } catch (error) {
        setHasCopiedCode(false);
      }
    }
  };

  /**
   * Redirects to the home page.
   */
  const redirectToHome = () => {
    if (homeUrl) {
      window.location = homeUrl;
    } else {
      history.push('/');
    }
  };

  return (
    <div className="tickets-panel">
      <div className="tickets-panel-content">
        <Spacing vertical size="S" alignItems="flex-start">
          <PaymentImage type="pix-logo" />
          <Text size="M" weight="bold">
            {t('pix_payment_strategies.strategies')}
          </Text>
          <Text size="S" weight="bold">
            {t('pix_payment_strategies.qr_code')}
          </Text>
          <ol>
            {pixInstructionsKeys.map((instructionKey, index) => (
              <li key={instructionKey}>
                <Text size="S">
                  {index + 1}. {t(`pix_qr_instructions.${instructionKey}`)}
                </Text>
              </li>
            ))}
          </ol>

          <Text size="S" weight="bold">
            {t('pix_payment_strategies.copy_code')}
          </Text>
          <ol>
            {pixInstructionsKeys.map((instructionKey, index) => (
              <li key={instructionKey}>
                <Text size="S">
                  {index + 1}. {t(`pix_code_instructions.${instructionKey}`)}
                </Text>
              </li>
            ))}
          </ol>
        </Spacing>
      </div>
      {isExpired ? (
        <div className="tickets-panel-content right-content">
          <div className="right-content-info">
            <Spacing vertical size="S">
              <MessageBox borderColor="warning" title={t('payment_expired')}>
                <Text>{t('payment_expired_message')}</Text>
              </MessageBox>
              <Button
                variant="primary"
                text={t('payment_expired_button')}
                onClick={redirectToHome}
              />
            </Spacing>
          </div>
        </div>
      ) : (
        <div className="tickets-panel-content right-content">
          <div className="right-content-info">
            <Spacing vertical size="L">
              <Spacing vertical size="S">
                <Text size="S" textAlign="center" color="grayMedium">
                  {t('purchase:label.payment_reference')}:
                </Text>
                <img
                  src={`data:image/png;base64, ${qrCode}`}
                  alt={t('qr_code')}
                  width="100%"
                  height="auto"
                />
                <FlatButton onClick={onCopyCode}>{t('common:buttons.copy_code')}</FlatButton>
                <div className="email-content">
                  <div className="feedback">
                    {hasCopiedCode && (
                      <p className="feedback-success">{t('common:buttons.code_copied')}</p>
                    )}
                  </div>
                </div>
              </Spacing>
              <Spacing vertical size="S">
                <Timer variant="payment" remainingTime={timerRemainingTime} />
              </Spacing>
            </Spacing>
          </div>
          <div className="right-content-price">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.waiting_for_your_payment')}:
              </Text>
              <div className="right-content-price-amount">
                <Text size="XL" weight="bold" textAlign="center">
                  {amountDisplay}
                </Text>
              </div>
            </Spacing>
          </div>
        </div>
      )}
    </div>
  );
};

PixPaymentInfo.propTypes = propTypes;

export default withPurchaseTimer(PixPaymentInfo);
