import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { sendEmail } from '@/actions/purchase';
import { createValidator, required, email } from 'utils/formValidations';
import SendEmail from './SendEmail';

const validate = createValidator({
  email: [required, email],
});

const withForm = reduxForm({
  form: 'sendEmail',
  validate,
})(SendEmail);

const mapStateToProps = ({ purchase }) => ({
  status: purchase.get('sentEmailStatus'),
  token: purchase.get('token'),
  transporterType: purchase.getIn(['departs', 'transportType']),
});

const mapDispatchToProps = {
  sendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(withForm);
