import * as types from 'constants/ActionTypes';

const defaultState = {
  installmentsMinAmount: 0,
  cybersourceLocalKeys: {},
};

export default function config(state = defaultState, action) {
  switch (action.type) {
    case types.SET_CONFIG:
      return { ...state, ...action.config };

    case types.SET_LOYALTY_PROGRAM:
      return { ...state, loyaltyProgramSelected: action.loyaltyProgramSelected };

    case types.SET_CYBERSOURCE_KEYS: {
      const { orgId, merchantId } = action.keys;
      return { ...state, cybersourceLocalKeys: { orgId, merchantId } };
    }

    default:
      return state;
  }
}
