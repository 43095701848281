import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/components/search/NoResults';
import { Alert, Text } from '@reservamos/elements';

const propTypes = {
  passengersNotFound: PropTypes.array,
  onClose: PropTypes.func,
};

/**
 * Message to notify that there is not trips that match with the filters selected.
 * @param {Object} props - Component props.
 * @param {Array} props.passengersNotFound - Passengers that were not found for filtering
 * @param {function} props.onClose - Function to close the alert.
 * @returns Component.
 */
const NoCategoriesFound = ({ passengersNotFound, onClose }) => {
  const { t } = useTranslation('');

  if (!passengersNotFound?.length) return null;

  return (
    <div className="no-filtered-results">
      <Alert
        title={t('search:label.categories_not_available')}
        alertType="warning"
        onCloseClick={onClose}
      >
        <Text>
          {t('search:label.not_trips_for_filters_passengers', {
            passengers: passengersNotFound.map((p) => t(`passengers:type.${p}`)).join(', '),
            count: passengersNotFound.length,
          })}
        </Text>
      </Alert>
    </div>
  );
};
NoCategoriesFound.propTypes = propTypes;

export default NoCategoriesFound;
