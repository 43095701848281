import { useSelector } from 'react-redux';
import useScheduleFilterOptions from '../utils/scheduleFilterOptions';

const includesOption = (provider, option) => provider.get('departures').includes(option);

/**
 * Hook to get the available schedule filters.
 * @param {String} way - Way of the trips to filter
 * @param {*} isNewDesign - Indicates if is needs the new design structure
 * @returns Array
 */
const useAvailableSchedulesProvidersOptions = (way, isNewDesign) => {
  const availableOptions = useSelector(({ search, providers }) => {
    const searchId = search.getIn([way, 'id']);
    const providerList = providers.getIn([searchId, 'list']);

    return {
      hasEarlymorningTrips:
        providerList &&
        providerList.find((provider) => {
          return includesOption(provider, 'earlymorning');
        }),
      hasMorningTrips:
        providerList &&
        providerList.find((provider) => {
          return includesOption(provider, 'morning');
        }),
      hasAfternoonTrips:
        providerList &&
        providerList.find((provider) => {
          return includesOption(provider, 'afternoon');
        }),
      hasNightTrips:
        providerList &&
        providerList.find((provider) => {
          return includesOption(provider, 'night');
        }),
    };
  });

  return useScheduleFilterOptions(availableOptions, isNewDesign);
};

export default useAvailableSchedulesProvidersOptions;
