import { useTranslation } from 'react-i18next';
import tripCountIcon from '../images/filters/opt-direct.svg';
import filtersTypes from '../constants/filtersTypes';

const { SORT_BY } = filtersTypes;

const useSortOptions = (isNewDesign, isProvider) => {
  const { t } = useTranslation('search');
  const iconNewDesignTypes = {
    price: 'emojiPayment',
    duration: 'emojiTimer',
    departure: 'emojiDepartureHourglass',
    arrival: 'emojiArrivalHourglass',
    near_terminals: 'emojiBus',
    tripCount: tripCountIcon,
  };

  let optionsToUse = ['price', 'duration'];

  if (!isProvider) {
    optionsToUse = [...optionsToUse, 'departure', 'arrival'];
  }

  if (!isNewDesign) {
    optionsToUse.push('stops');
  }

  if (isProvider) {
    optionsToUse.push('tripCount');
  }

  const options = optionsToUse.map((option) => ({
    title: t('label.selector', { context: option }),
    description: t('label.description.selector', { context: option }),
    icon: isNewDesign ? iconNewDesignTypes[option] : option,
    value: option,
    filterType: SORT_BY,
  }));

  return options;
};

export default useSortOptions;
