import {
  BadgeRounded,
  Button,
  Currency,
  FlatButton,
  Icon,
  IconText,
  ProviderLogo,
  Spacing,
  Text,
} from '@reservamos/elements';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'styles/components/search/ResultWithIcons';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { formatDuration, formatPercent } from 'utils/Helpers';
import '../../ui/layouts/MatrixResult';
import RouteDetailsModal from '../../ui/molecules/RouteDetailsModal';
import LowAvailability from './LowAvailability';
import PriceTooltip from './PriceTooltip';
import TripFiltersBadges from './TripFiltersBadges';
import FlexibleArrow from '../../ui/atoms/FlexibleArrow';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';

const ResultWithIcons = ({
  arrival,
  availability,
  departure,
  destination,
  duration,
  id,
  onSelectClick,
  origin,
  providerDiscount,
  providerDiscounts,
  total,
  totalBeforeDiscount,
  trip,
  filtersApplied = {},
  showNewFiltersList,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { getServiceType } = useWhitelabelTheme();
  const { t } = useTranslation();
  const { line, pricing, service, passengerTypes } = trip;
  const { discountType } = pricing;
  const hasProviderDiscount = providerDiscount && Boolean(providerDiscount.amount);
  const discountPercent = hasProviderDiscount ? formatPercent(totalBeforeDiscount, total) : null;
  const earlyDiscount = discountType === 'early_trip_discount';
  const SHOW_ON_FLAT = features.FUNNEL_STYLE === 'FLAT';
  const serviceTypeIcon = getServiceType(service).icon;
  const serviceTypeLabel = getServiceType(service).label;
  const shouldDisplayFromPriceLabel = getServiceType(service).hasMultiplePricing;
  const shouldShowDiscountsModal = features.SHOW_DISCOUNTS_MODAL_AT_RESULTS;
  const shouldShowServiceTypeBadge = features.SHOW_SERVICE_TYPE_BADGE && service;
  const shouldShowTripDetails = features.SHOW_TRIP_DETAILS_AT_RESULTS;

  return (
    <div className="result-with-icons__container">
      {hasProviderDiscount && features.DISCOUNT_BADGE_RESULT_ENABLED && (
        <div className="matrix-right-corner">
          <BadgeRounded
            text={`-${discountPercent}`}
            firstColor="discountLight"
            secondColor="discountStrong"
          />
        </div>
      )}
      <div className="result-with-icons__section">
        <ProviderLogo name={env.brand} imgSrc={line.logoUrl} />
        <div className="result-with-icons__show-on-mobile">
          {shouldShowDiscountsModal ? (
            <PriceTooltip
              amenities={line.services}
              providerDiscounts={providerDiscounts}
              availabilityCategories={passengerTypes}
              earlyDiscount={earlyDiscount}
              total={total}
              color="primary"
              tripId={id}
              providerDiscount={providerDiscount}
              shouldDisplayFromPriceLabel={shouldDisplayFromPriceLabel}
            />
          ) : (
            <Spacing size="XS" alignItems="baseline" justifyContent="flex-end">
              {Boolean(earlyDiscount) && (
                <div className="result-mobile-click">
                  <button
                    className="discount-etn"
                    type="button"
                    aria-label="mute"
                    title={t('purchase:early_discount')}
                  />
                </div>
              )}
              <Spacing vertical size="XS">
                {shouldDisplayFromPriceLabel && (
                  <Text size="XS" color="grayLight">
                    {t('trips:label.from_price')}
                  </Text>
                )}
                <Currency
                  sign={getCurrencyPrefix()}
                  price={total}
                  decimals={env.tripPriceDecimals || 0}
                  color="primary"
                  currency={getCurrencySuffix()}
                  size="XL"
                  mobileSize="L"
                  weight="bold"
                />
              </Spacing>
            </Spacing>
          )}
        </div>
        <div className="result-with-icons__show-on-desktop">
          {shouldShowServiceTypeBadge && (
            <IconText
              iconType={serviceTypeIcon}
              label={serviceTypeLabel}
              iconSize="S"
              fontSize="XS"
            />
          )}
        </div>
      </div>
      <Spacing vertical flexGrow size="M" responsiveSize="M">
        <Spacing vertical flexGrow size="XS" responsiveSize="XS">
          <div className="result-with-icons__schedule-section--time">
            <Text mobileSize="XL" size="XL" color="grayStrong" weight="bold">
              {moment(departure).format('LT')}
            </Text>
            <div className="result-with-icons__duration-container">
              <Icon type="timer" size="S" />
              <Text mobileSize="XS" size="XS" color="grayLight" textAlign="center">
                {formatDuration(duration)}
              </Text>
            </div>
            <Text mobileSize="XL" size="XL" color="grayMedium" weight="bold" textAlign="end">
              {moment(arrival).format('LT')}
            </Text>
          </div>
          <div className="result-with-icons__schedule-section--location">
            <Text mobileSize="S" color="grayLight">
              {origin.name}
            </Text>
            <FlexibleArrow />
            <Text mobileSize="S" color="grayLight" textAlign="end">
              {destination.name}
            </Text>
          </div>
        </Spacing>
        <Spacing justifyContent="space-between" alignItems="center">
          {shouldShowTripDetails ? (
            <RouteDetailsModal tripId={id} trip={trip} provider={line}>
              <div className="matrix-mobile-click">
                <FlatButton size="XS" type="info">
                  {t('general:services')}
                </FlatButton>
              </div>
            </RouteDetailsModal>
          ) : (
            <div />
          )}
          {showNewFiltersList ? (
            <TripFiltersBadges
              filtersApplied={filtersApplied}
              trip={trip}
              alternativeComponent={null}
            />
          ) : null}
          <div className="result-with-icons__show-on-mobile">
            <LowAvailability availability={availability} pricing={pricing} type="mobile" />
          </div>
          <div className="result-with-icons__show-on-desktop">
            <LowAvailability availability={availability} pricing={pricing} type="desktop" />
          </div>
        </Spacing>
      </Spacing>
      <div className="result-with-icons__section">
        <div className="result-with-icons__show-on-desktop">
          {shouldShowDiscountsModal ? (
            <PriceTooltip
              amenities={line.services}
              providerDiscounts={providerDiscounts}
              availabilityCategories={passengerTypes}
              earlyDiscount={earlyDiscount}
              total={total}
              color="primary"
              tripId={id}
              providerDiscount={providerDiscount}
              shouldDisplayFromPriceLabel={shouldDisplayFromPriceLabel}
            />
          ) : (
            <Spacing size="XS" alignItems="baseline" justifyContent="flex-end">
              {Boolean(earlyDiscount) && (
                <div className="result-mobile-click">
                  <button
                    className="discount-etn"
                    type="button"
                    aria-label="mute"
                    title={t('purchase:early_discount')}
                  />
                </div>
              )}
              <Spacing vertical size="XS">
                {shouldDisplayFromPriceLabel && (
                  <Text size="XS" color="grayLight">
                    {t('trips:label.from_price')}
                  </Text>
                )}
                <Currency
                  sign={getCurrencyPrefix()}
                  price={total}
                  decimals={env.tripPriceDecimals || 0}
                  color="primary"
                  currency={getCurrencySuffix()}
                  size="XL"
                  mobileSize="L"
                  weight="bold"
                />
              </Spacing>
            </Spacing>
          )}
        </div>
        <div className="result-with-icons__show-on-mobile">
          {shouldShowServiceTypeBadge && (
            <IconText
              iconType={serviceTypeIcon}
              label={serviceTypeLabel}
              iconSize="S"
              fontSize="XS"
            />
          )}
        </div>
        <Button
          text={t('general:select_your_seat')}
          onClick={onSelectClick}
          variant="primary"
          isRounded={SHOW_ON_FLAT}
          iconType="SeatPassenger"
          iconColor="white"
        />
      </div>
    </div>
  );
};

const placeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

ResultWithIcons.propTypes = {
  arrival: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  departure: PropTypes.string.isRequired,
  destination: placeType.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  origin: placeType.isRequired,
  providerDiscount: PropTypes.any,
  providerDiscounts: PropTypes.array,
  total: PropTypes.number.isRequired,
  totalBeforeDiscount: PropTypes.number,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
      serviceType: PropTypes.string,
    }).isRequired,
    service: PropTypes.string.isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
  }).isRequired,
  filtersApplied: PropTypes.object,
  showNewFiltersList: PropTypes.bool,
};

ResultWithIcons.defaultProps = {
  providerDiscount: null,
  providerDiscounts: [],
};

export default ResultWithIcons;
