import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getSearchDateParamFromMoment } from 'utils/search/searchDateParam';
import LoadingScreen from 'components/LoadingScreen';

/**
 * Renders the DynamicSearch component.
 *
 * @param {Object} match - The match object from react-router.
 */
const DynamicSearch = ({ match }) => {
  const history = useHistory();
  const { originSlug, destinationSlug } = match.params;
  const departureDate = getSearchDateParamFromMoment(moment().add(1, 'days'));
  const searchUrl = `/search/${originSlug}/${destinationSlug}/${departureDate}/p/A1/departures`;

  history.push(searchUrl);

  return <LoadingScreen />;
};

DynamicSearch.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      destinationSlug: PropTypes.string.isRequired,
      originSlug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DynamicSearch;
