import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Spacing,
  Box,
  Modal,
  Text,
  ProgressBar,
  FlatButton,
  LoaderDots,
  Icon,
} from '@reservamos/elements';
import './PurchaseTimerModal.scss';
import { createTimerFormat } from '../../utils/purchase/timer';

/**
 * Component used to show a modal timer to complete purchase.
 * @param {Object} props - The component props.
 * @param {number} props.remainingTime - The remaining time in minutes.
 * @param {number} props.expireMinutes - The expire time in minutes.
 * @param {function} props.handleOnExtend - Function to execute when clicking in the extend button.
 * @param {function} props.handleGoToSearch - Function to execute when clicking in the go to search button.
 * @param {boolean} props.isLoading - Flag to show the loader dots.
 * @param {number} props.minutesToExtend - The minutes to extend.
 */
const PurchaseTimerModal = ({
  remainingTime,
  expireMinutes,
  handleOnExtend,
  handleGoToSearch,
  isLoading,
  minutesToExtend,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const timeFormatted = createTimerFormat(remainingTime, 'mm:ss')?.split(':');
  const percentageRemaining = expireMinutes ? (remainingTime * 100) / expireMinutes : 0;
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  return (
    <Modal
      alphaBg="80"
      contentHeight="M"
      responsiveSize="XS"
      showCloseButton={false}
      closeIcon={false}
      headerColor
      centeredOnMobile={isFlat}
      borderRadiusBigger={isFlat}
      closeOnOverlayClick={false}
      footer={
        <Spacing alignItems="center" justifyContent="space-between" vertical>
          <Box
            bgColor="primary"
            paddingHorizontal="M"
            paddingVertical="S"
            borderRadius={isFlat ? 'L' : 'M'}
            fullWidthOnSmall
            gradientDirection="right"
            hasHoverBg
          >
            <button className="continue-purchase" onClick={handleOnExtend} disabled={isLoading}>
              <Spacing alignItems="center" justifyContent="space-between">
                <Text color="white" weight="bold">
                  {t('button.continue-purchase')}
                </Text>
                {!isLoading && (
                  <span className="add-time">
                    <Box
                      paddingHorizontal="S"
                      paddingVertical="S"
                      bgColor="white"
                      alphaBg="S"
                      borderRadius="L"
                    >
                      <Text color="white" weight="bold">
                        + {minutesToExtend} : 00
                      </Text>
                    </Box>
                  </span>
                )}
                {isLoading && <LoaderDots />}
              </Spacing>
            </button>
          </Box>
          <FlatButton
            type="primary"
            textDecorationLine="none"
            weight="bold"
            size="M"
            disabled={isLoading}
            onClick={handleGoToSearch}
          >
            {t('button.return-schedules')}
          </FlatButton>
        </Spacing>
      }
    >
      <Spacing size="L" vertical>
        <Spacing vertical justifyContent="center" alignItems="center">
          <Spacing alignItems="center" justifyContent="center" size="S" responsiveSize="S">
            <Box
              bgColor="primary"
              alphaBg="S"
              paddingHorizontal="M"
              paddingVertical="S"
              borderRadius="M"
            >
              <Text color="primary" size="XXL" weight="bold">
                {timeFormatted?.[0]}
              </Text>
            </Box>

            <Text color="primary" size="XXL" weight="bold">
              :
            </Text>

            <Box
              bgColor="primary"
              alphaBg="S"
              paddingHorizontal="M"
              paddingVertical="S"
              borderRadius="M"
            >
              <Text color="primary" size="XXL" weight="bold">
                {timeFormatted?.[1]}
              </Text>
            </Box>
          </Spacing>

          <div className="progress-bar">
            <ProgressBar
              progress={percentageRemaining}
              bgColor="grayExtraLight"
              bgColorProgress="primary"
              gradientDirection="left"
            />
          </div>
        </Spacing>

        <Spacing alignItems="center" size="S" justifyContent="center" vertical>
          <Spacing alignItems="center" justifyContent="center" size="XS">
            <Icon type="timer" />
            <Text weight="bold" size="XL">
              {t('text.do_you_want_more_time')}
            </Text>
          </Spacing>
          <Text textAlign="center" mobileSize="M" size="L">
            {t('text.time_to_finish')}
          </Text>
        </Spacing>
      </Spacing>
    </Modal>
  );
};

PurchaseTimerModal.propTypes = {
  remainingTime: PropTypes.number.isRequired,
  expireMinutes: PropTypes.number.isRequired,
  handleOnExtend: PropTypes.func.isRequired,
  handleGoToSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  minutesToExtend: PropTypes.number.isRequired,
};

export default PurchaseTimerModal;
