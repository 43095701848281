import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAd } from '../actions/ads';
import getUtmCampaign from '../utils/getUtmCampaign';

const inputShape = {
  origin: '',
  destination: '',
  line: '',
};

const useFetchAd = (adInput = inputShape, space, isDataReady = true) => {
  const { origin, destination, line } = adInput;
  const [isAdFetched, setIsAdFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAdFetched && isDataReady) {
      dispatch(
        fetchAd(
          {
            campaignSlug: getUtmCampaign(),
            origin,
            destination,
            line,
          },
          space,
        ),
      );

      setIsAdFetched(true);
    }
  }, [destination, dispatch, isAdFetched, line, origin, space, isDataReady]);
};

export default useFetchAd;
