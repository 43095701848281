import { connect } from 'react-redux';
import PaycashPaymentInfo from './PaycashPaymentInfo';

const mapStateToProps = ({ purchase, payment }) => ({
  amount: purchase.get('total'),
  paydayLimit: payment.get('expirationDate'),
  pdfUrl: payment.get('paymentFormatUrl'),
  reference: payment.get('reference'),
});

export default connect(mapStateToProps)(PaycashPaymentInfo);
