import { flattenDeep } from 'lodash';
import { gaTracker } from 'user-analytics';

function formatErrors(fields = {}) {
  const errors = Object.keys(fields).map((fieldName) => {
    const fieldError = fields[fieldName];

    if (fieldError instanceof Array) {
      return fieldError.map((errorFields) => formatErrors(errorFields));
    }

    return { fieldName, fieldError };
  });

  return flattenDeep(errors);
}

function formErrorsTracker(funnelStep, fields, paymentInfo = {}, callback) {
  formatErrors(fields).forEach((error) => {
    gaTracker.trackCheckoutErrors(funnelStep, { ...error, ...paymentInfo }, callback);
  });
}

export default formErrorsTracker;
