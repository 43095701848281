import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SlidingMenuItem.scss';

const SlidingMenuItem = ({ label, href, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const seatClass = () => {
    if (isOpen) {
      return 'rvui-sliding-menu-list-inner rvui-sliding-menu-list-inner-open';
    }

    return 'rvui-sliding-menu-list-inner';
  };

  return (
    <>
      <a
        href={href}
        className="rvui-sliding-menu-link"
        onClick={() => setIsOpen(!isOpen)}
        target={!children ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {label}
        {Boolean(children) && <i className="rvui-sliding-menu-arrow" />}
      </a>
      {Boolean(children) && <div className={seatClass()}>{children}</div>}
    </>
  );
};

SlidingMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  href: PropTypes.string,
};

SlidingMenuItem.defaultProps = {
  children: null,
  href: '#',
};

export default SlidingMenuItem;
