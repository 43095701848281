import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import InlineInput from '../../../ui/molecules/InlineInput';
import { formatCurrency } from '../../../utils/Helpers';

const CheckoutWalletInput = ({
  setUsingWallet,
  loadingWallet,
  usableBalance,
  purchaseToken,
  checkboxContext,
}) => {
  const [amountToReedem, setAmountToRedeem] = useState('');
  const { t } = useTranslation('loyalty');
  const submitBalance = () => {
    setUsingWallet(purchaseToken, true, amountToReedem);
  };

  const onInputChange = (e) => {
    const { value } = e.target;
    if (!/[0-9]+\.{0,1}?[0-9]*/.test(value)) {
      setAmountToRedeem('');
      return;
    }

    const valueAsNumber = Number.parseFloat(value);
    if (valueAsNumber < 0 || valueAsNumber > usableBalance) {
      setAmountToRedeem('');
      return;
    }

    setAmountToRedeem(value);
  };

  return (
    <Spacing vertical size="S">
      <Text>
        {t('wallet_input_label', {
          amount: formatCurrency(usableBalance, 2),
          context: checkboxContext(loadingWallet, false),
          currencyCode: 'MXN',
        })}
      </Text>
      <InlineInput
        name="redeem-wallet"
        placeholder={formatCurrency(usableBalance, 2)}
        onClick={submitBalance}
        isLoading={loadingWallet}
        setAmountToRedeem={setAmountToRedeem}
        usableBalance={usableBalance}
        value={amountToReedem}
        onChange={onInputChange}
      />
    </Spacing>
  );
};

CheckoutWalletInput.propTypes = {
  checkboxContext: PropTypes.func.isRequired,
  setUsingWallet: PropTypes.func.isRequired,
  loadingWallet: PropTypes.bool.isRequired,
  usableBalance: PropTypes.string.isRequired,
  purchaseToken: PropTypes.string.isRequired,
};

export default CheckoutWalletInput;
