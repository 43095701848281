import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconText } from '@reservamos/elements';
import i18n from 'i18next';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';
import amenities from '../../images/indexAmenities';

const Amenity = ({ isIcon, type, isVertical, iconSize }) => {
  const { amenities: customAmenities } = useWhitelabelTheme();
  const amenityType = customAmenities[type] || amenities[type] || amenities.amenity;

  if (isIcon) return <Icon type={amenityType} size={iconSize} />;

  return (
    <IconText
      isVertical={isVertical}
      mobileInline
      iconType={amenityType}
      fontSize="S"
      label={i18n.t('common:constant.amenity', { context: type })}
      iconSize={iconSize}
    />
  );
};

Amenity.propTypes = {
  /** Amenity type */
  type: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  isIcon: PropTypes.bool,
  isVertical: PropTypes.bool,
};

Amenity.defaultProps = {
  isIcon: false,
  isVertical: false,
  iconSize: 'M',
};

export default Amenity;
