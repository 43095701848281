import React from 'react';
import PropTypes from 'prop-types';
import { RadioItem, Card } from '@reservamos/elements';

const SavedCardItem = ({
  id,
  bankName,
  cardNumber,
  value,
  onChange,
  brand,
  checked,
  isEditing,
  onDeleteClick,
}) => {
  const brandsEquivalents = {
    mc: 'mastercard',
    visadankort: 'visa',
  };

  return (
    <Card key={id} isActive={checked} hasMargin={false}>
      <RadioItem
        name="selectedCard"
        textLeft={bankName}
        textRight={`**** **** **** ${cardNumber}`}
        value={value}
        onChange={onChange}
        cardBrand={brandsEquivalents[brand] ?? brand}
        isActive={checked}
        isEditing={isEditing}
        onDeleteClick={onDeleteClick}
      />
    </Card>
  );
};

SavedCardItem.propTypes = {
  bankName: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  brand: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  onDeleteClick: PropTypes.func,
};

SavedCardItem.defaultProps = {
  isEditing: false,
  onDeleteClick: () => {},
};

export default SavedCardItem;
