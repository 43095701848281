import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FlatButton, Icon, Spacing, ToggleSwitch } from '@reservamos/elements';
import adjacentIcon from 'images/badges/adjacent-seat.svg';
import { useTranslation } from 'react-i18next';
import AdjacentSeatDialog from 'ui/atoms/AdjacentSeatDialog';
import { trackCloseAdjacentDialog, trackOpenAdjacentDialog } from 'metrics/user-analytics/seats';

/**
 * AdjacentToggle component.
 * Switch that shows the adjacent seats description.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onSelect - The function to handle adjacent selection.
 * @param {boolean} props.value - The value of the adjacent switch.
 * @returns {JSX.Element} The rendered AdjacentToggle component.
 */
const AdjacentToggle = ({ onSelect, value }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('seats');

  /**
   * Toggle modal (open/close).
   */
  const toggleModal = () => {
    if (!openModal) {
      trackOpenAdjacentDialog();
    } else {
      trackCloseAdjacentDialog();
    }
    setOpenModal(!openModal);
  };

  return (
    <>
      <AdjacentSeatDialog show={openModal} onClose={toggleModal} />
      <Spacing alignItems="center" justifyContent="space-between">
        <Spacing size="XS" alignItems="center">
          <Icon type={adjacentIcon} size="S" />
          <FlatButton text={t('adjacent_seat.title_simple')} size="S" onClick={toggleModal} />
        </Spacing>
        <ToggleSwitch size="S" checked={value} fillBackground onClick={onSelect} />
      </Spacing>
    </>
  );
};

AdjacentToggle.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default AdjacentToggle;
