import { connect } from 'react-redux';
import { toggleModal } from '@/actions';
import TripViewOptions from './TripViewOptions';

const mapStateToProps = (state) => {
  const { visibility } = state.modal;

  return { showingFilters: visibility === 'visible' };
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(toggleModal('hidden')),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripViewOptions);
