import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import ProviderItem from 'components/search/ProviderItem';
import PoweredBy from 'components/PoweredBy';
import { Spacing, Text } from '@reservamos/elements';
import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';
import NoResults from '../../ui/molecules/NoResults';
import ResultLine from './ResultLine';
import NewResultLine from './NewResultLine';
import LabelLoading from '../../ui/molecules/LabelLoading';

const defaultSort = (a, b) => {
  if (a.importance !== undefined && b.importance !== undefined) {
    return a.importance - b.importance;
  }
  return a.lowestPrice - b.lowestPrice;
};

const sortProvidersBy = (a, b, property) => {
  const propertyToUse = property === 'price' ? 'lowestPrice' : property;

  if (propertyToUse === 'tripCount') {
    return b[propertyToUse] - a[propertyToUse];
  }

  return a[propertyToUse] - b[propertyToUse];
};

const filterProvidersBy = (providers, property) => {
  const propertyToUse = property && Array.isArray(property) ? property : [property];
  if (!propertyToUse || propertyToUse[0] === 'none') {
    return providers;
  }
  return providers.filter((provider) => {
    let condition = false;
    propertyToUse.forEach((prop) => {
      condition = condition || provider.departures.includes(prop);
    });
    return condition;
  });
};

function departuresDescription(departures, t) {
  if (departures.length === 4) return t('hours', { context: 'all' });
  const sorted = [];

  // crappy sorting
  if (departures.includes('earlymorning')) sorted.push('earlymorning');
  if (departures.includes('morning')) sorted.push('morning');
  if (departures.includes('afternoon')) sorted.push('afternoon');
  if (departures.includes('night')) sorted.push('night');

  return sorted
    .map((departure) => t('hours', { context: departure }))
    .join(', ')
    .replace(/,(?!.*,)/, t('hours_separator'));
}

const ProviderList = ({
  roundTrip,
  way = 'departure',
  providers,
  sortBy,
  filterBy,
  installmentsMinAmount,
  onSelectProvider,
  onDisclaimerClick,
  isLoading,
  isOpenTicket,
  isNotFiltered,
  date,
  showPoweredBy,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('search');
  const isNewResultsDesignAB = useGrowthBookFeatureValue('new_results_design');

  if (providers.length < 1 && !isLoading && !isNotFiltered) {
    return <NoResults way={way} isProviderList date={date} />;
  }

  if (providers.length < 1 && !isNotFiltered) {
    return <Loading hasHeader={false} loadingText={t('loading_results')} />;
  }

  let notFilteredProviders;
  const filteredProviders = filterProvidersBy(providers, filterBy);

  if (isNotFiltered) {
    const filteredProvidersMap = filteredProviders.map((provider) => provider.id);
    notFilteredProviders = providers.filter(
      (provider) => !filteredProvidersMap.includes(provider.id),
    );
  }

  const providersToSort = isNotFiltered ? notFilteredProviders : filteredProviders;
  const orderedProviders = providersToSort.sort((a, b) => {
    return sortBy ? sortProvidersBy(a, b, sortBy) : defaultSort(a, b);
  });

  let providersToShow = orderedProviders;
  if (way === 'return') {
    providersToShow = orderedProviders.map((provider) => {
      const { roundProviderDiscount, roundLowestPrice, roundHasDiscounts } = provider;
      return {
        ...provider,
        providerDiscount: roundProviderDiscount,
        lowestPrice: roundLowestPrice,
        hasDiscounts: roundHasDiscounts,
      };
    });
  }

  const isNotFilteredList = notFilteredProviders?.length && isNotFiltered;

  if (isNotFiltered && !notFilteredProviders?.length) return null;

  return (
    <>
      {isLoading && <LabelLoading />}
      <Spacing vertical>
        {isNotFilteredList ? (
          <Text weight="bold" size="L" mobileSize="M">
            {t('label.other_lines_you_may_be_interested_on')}
          </Text>
        ) : null}
        <div className="trip-container fade-in">
          <Spacing size="S" vertical>
            {providersToShow.map((provider, index) => {
              const { departures } = provider;
              if (isNewResultsDesignAB) {
                return (
                  <NewResultLine
                    onSelectClick={() => onSelectProvider(Object.assign(provider, { index }))}
                    provider={provider}
                    departuresDescription={departuresDescription(departures, t)}
                  />
                );
              }
              if (features.NEW_PROVIDER_RESULTS) {
                return (
                  <ResultLine
                    onSelectClick={() => onSelectProvider(Object.assign(provider, { index }))}
                    provider={provider}
                    departuresDescription={departuresDescription(departures, t)}
                    isOpenTicket={isOpenTicket}
                  />
                );
              }
              return (
                <ProviderItem
                  key={provider.id}
                  provider={provider}
                  roundTrip={roundTrip}
                  way={way}
                  position={index + 1}
                  installmentsMinAmount={installmentsMinAmount}
                  onSelectProvider={() => onSelectProvider(Object.assign(provider, { index }))}
                  onDisclaimerClick={onDisclaimerClick}
                  departuresDescription={departuresDescription(departures, t)}
                />
              );
            })}
          </Spacing>

          {features.SHOW_POWERED_BY && showPoweredBy && <PoweredBy />}
        </div>
      </Spacing>
    </>
  );
};

ProviderList.propTypes = {
  providers: PropTypes.array.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  sortBy: PropTypes.string,
  filterBy: PropTypes.string,
  way: PropTypes.string,
  installmentsMinAmount: PropTypes.number.isRequired,
  onSelectProvider: PropTypes.func.isRequired,
  onDisclaimerClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpenTicket: PropTypes.bool,
  isNotFiltered: PropTypes.bool,
  date: PropTypes.string,
  showPoweredBy: PropTypes.bool,
};

ProviderList.defaultProps = {
  isOpenTicket: false,
};

export default ProviderList;
