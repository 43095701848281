import React from 'react';
import PropTypes from 'prop-types';
import { formatDuration } from 'utils/Helpers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RouteItineraryInline from '../../RouteItineraryInline';

const TIME_FORMAT = 'LT';
const DATE_FORMAT = 'DD MMM YY';
const MIN_DURATION = 15;

const propTypes = {
  departureMoment: PropTypes.object.isRequired,
  arrivalMoment: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
};

const DateDetails = ({ departureMoment, arrivalMoment, duration }) => {
  const { t } = useTranslation('trips');
  const departureTime = departureMoment.format(TIME_FORMAT);
  const departureDate = departureMoment.format(DATE_FORMAT);
  const arrivalTime = arrivalMoment.format(TIME_FORMAT);
  const arrivalDate = arrivalMoment.format(DATE_FORMAT);
  const tripDuration = formatDuration(moment.duration(duration, 'm'));
  const showDuration = duration > MIN_DURATION;

  return (
    <RouteItineraryInline
      departure={departureTime}
      departureLabel={departureDate}
      duration={tripDuration}
      durationLabel={t('general:period')}
      arrival={arrivalTime}
      arrivalLabel={arrivalDate}
      showDuration={showDuration}
    />
  );
};

DateDetails.propTypes = propTypes;

export default DateDetails;
