/**
 * @typedef {import('./CardStrategy').CardStrategy} CardStrategy
 */

/**
 * Mercado Pago card strategy.
 */
function MercadoPagoStrategy() {
  /**
   * Create payload for Mercado Pago.
   */
  const createPayload = async (cardInfo, enginePayload = {}) => {
    const { cardBrand } = cardInfo;
    const { mercadoPagoToken, mpDeviceSessionId, issuerId } = enginePayload;

    return {
      credit_card_token: mercadoPagoToken,
      card_brand: cardBrand,
      card_bank_code: issuerId,
      credit_card_tokens: {
        mercadopago_token: mercadoPagoToken,
      },
      device_session_id: mpDeviceSessionId,
    };
  };

  return { createPayload };
}

const mercadoPagoStrategy = MercadoPagoStrategy();

export default mercadoPagoStrategy;
