import { connect } from 'react-redux';
import EvertecPaymentDetails from './EvertecPaymentDetails';

const mapStateToProps = ({ payment, purchase }) => ({
  transactionDate: payment.get('transactionDate') || new Date().toISOString(),
  status: payment.get('status'),
  reference: purchase.get('token'),
  amount: payment.get('amount'),
});

export default connect(mapStateToProps)(EvertecPaymentDetails);
