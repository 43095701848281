/** This code was copy-pasted from Enterprise code base
 * Some lines were commented due to the fact that some functionalities are not available in the current code base
 * @todos were added to uncomment the code when the functionalities are available
 *
 * The functionalities yet to be implemented are:
 * - New experimental filters design
 * - Doters
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDuration, formatPercent } from 'utils/Helpers';
import {
  Text,
  Button,
  FlatButton,
  Currency,
  BadgeRounded,
  Gap,
  ScheduleFlat,
  Icon,
  Visibility,
  Spacing,
  ProviderLogo,
} from '@reservamos/elements';
// import { useFeature } from '@growthbook/growthbook-react';
import { getCurrencySuffix } from 'utils/currency';
import dotersDot from 'images/doters/greendoters.svg';
import etnDiscount from 'images/brands-unique/etn/discountETN.png';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import SeatAvailability from './SeatAvailability';
import TripFiltersBadges from './TripFiltersBadges';
import '../../ui/layouts/MatrixResult';
import BadgePetFriendly from '../../ui/atoms/BadgePetFriendly';

// hide the duration if is minor than 15min
// because this is probably wrong estimation
const MIN_DURATION = 15;

/**
 * Render the card of a trip on the search results
 * @param {Object} props
 * @param {string} props.arrival - Arrival date
 * @param {number} props.availability - Available seats
 * @param {string} props.departure - Departure date
 * @param {number} props.duration - Duration in minutes
 * @param {string} props.id - Trip id
 * @param {Function} props.onSelectClick - Function to execute when click
 * @param {Object} props.providerDiscount - Provider discount
 * @param {Object} props.providerDiscounts - Array of available discounts
 * @param {number} props.discountBase - Discount base
 * @param {number} props.total - Total price with discount, applies flat fare
 * @param {number} props.totalBase - Total price with discounts, does not apply flat fare
 * @param {number} props.totalBeforeDiscount - Total price before discounts of any type
 * @param {number} props.walletPoints - Total price in wallet points
 * @param {Object} props.trip - Trip object
 * @param {Object} props.availableCategories - Available categories
 * @param {string} props.useFlatFare - Show use flat fare
 * @param {boolean} props.isExchange - Is exchange
 * @param {boolean} props.handleShowTripDetails - Function to handle the opening of the details modal
 * @param {Object} props.filtersApplied - Filters applied
 * @param {boolean} props.loadingPrice - Price is being loaded, maybe from a discount coupon
 * @returns {React.Component} - ResultSpecifics component
 */
const ResultSpecifics = ({
  arrival,
  availability,
  departure,
  duration,
  id,
  onSelectClick,
  providerDiscount,
  providerDiscounts,
  discountBase,
  total,
  totalBase,
  totalBeforeDiscount,
  walletPoints,
  trip,
  isExchange,
  availableCategories,
  useFlatFare,
  handleShowTripDetails,
  filtersApplied = {},
  showNewFiltersList,
  loadingPrice,
}) => {
  const { env: environment, features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const [showDetailsModal, setShowDetailsModal] = useState(true);
  const {
    line,
    passengerTypes,
    pricing,
    variableDepartureTime,
    secondFloor,
    stops,
    isPetFriendly,
  } = trip;
  const { discountType } = pricing;
  const categoriesByType = availableCategories.reduce((accum, category) => {
    accum[category.type] = category.availability;
    return accum;
  }, {});

  const teacherSeat = categoriesByType.teacher;
  const studentSeat = categoriesByType.student;
  const olderSeat = categoriesByType.older;
  const minorSeat = categoriesByType.minor;
  const hasSpecialDiscounts =
    studentSeat !== 0 || teacherSeat !== 0 || olderSeat !== 0 || minorSeat !== 0;
  const lowAvailability = availability <= features.LOW_AVAILABILITY_THRESHOLD;
  const earlyDiscount = discountType === 'early_trip_discount';
  const showDuration = duration > MIN_DURATION;
  const discountPercent = providerDiscount ? formatPercent(totalBeforeDiscount, total) : null;
  const stopsText = t('search:trip_stopovers', {
    count: stops,
    context: stops === 0 ? 'direct' : null,
  });
  const hidePriceOnExchange = features.HIDE_PRICE_ON_EXCHANGE && isExchange;

  // TODO: we need to unify the discount param for all the brands
  const providerDiscountColorFlag = discountBase !== undefined ? discountBase : providerDiscount;
  const defaultPriceColor = providerDiscountColorFlag ? 'discount' : 'primary';
  const buttonText = t('general:select');
  const departureHour = moment(departure).format('LT');
  const arrivalHour = moment(arrival).format('LT');
  const showFlatFare = useFlatFare === 'valid';

  const DOTERS_CURRENCY = t('search:doters_currency');

  /**
   * Prices
   */
  const primaryCurrency = showFlatFare ? DOTERS_CURRENCY : getCurrencySuffix();
  let primaryPrice = total;
  if (showFlatFare) primaryPrice = walletPoints;

  /**
   * If no switch then prices on the result box are mixed between MXN and Doters
   * So there is an secondary price
   */
  const secondaryPrice = showFlatFare ? totalBase : walletPoints;
  const secondaryCurrency = !showFlatFare ? DOTERS_CURRENCY : getCurrencySuffix();
  const showSecondaryPrice = Number(secondaryPrice) && walletPoints > 0;
  const currencyDoters = primaryCurrency === DOTERS_CURRENCY;

  /**
   * Get the text color for price based on flatFare, doters or MXN
   * @returns {string} - Text color
   */
  const getPriceColor = () => {
    if (loadingPrice) return 'grayLight';
    let color = defaultPriceColor;

    if (showFlatFare) {
      color = 'loyalty';
    }
    if (showFlatFare) {
      color = 'loyaltyLight';
    }
    return color;
  };
  const primaryPriceColor = getPriceColor();

  /**
   * Method to handle the selection of a trip
   * @param {String} funnelSection - section of the funnel where the trip was selected
   */
  const handleOnSelect = (funnelSection) => {
    const funnelSectionSelected = funnelSection === 'details' ? 'details' : 'results';
    onSelectClick(funnelSectionSelected);
    if (isExchange) setShowDetailsModal(false);
  };

  /**
   * Render price section on the result box
   * Evaluates if it is exchange then hide prices as ENT-308
   * @returns JSX Component
   */
  const PriceComponent = () => {
    if (hidePriceOnExchange) return null;
    const secondaryPriceColor =
      secondaryCurrency === DOTERS_CURRENCY ? 'loyaltyLight' : defaultPriceColor;
    return (
      <>
        <Gap size="XS" alignItems="center">
          {showFlatFare ? (
            <Icon type={dotersDot} size="S" />
          ) : (
            earlyDiscount && <Icon type={etnDiscount} />
          )}
          <Currency
            price={primaryPrice}
            color={primaryPriceColor}
            currency={primaryCurrency}
            decimals={currencyDoters ? 0 : environment.tripPriceDecimals || 0}
            sign={currencyDoters ? '' : '$'}
            size="XL"
            weight="semibold"
            currencyLowercase={showFlatFare}
          />
          {providerDiscount && features.DISCOUNT_BADGE_RESULT_ENABLED && (
            <BadgeRounded
              text={`-${discountPercent}`}
              firstColor="discountLight"
              secondColor="discountStrong"
              corneredRadius
            />
          )}
        </Gap>
        {showSecondaryPrice ? (
          <Currency
            price={secondaryPrice}
            color={secondaryPriceColor}
            currency={secondaryCurrency}
            sign={secondaryCurrency === DOTERS_CURRENCY ? '' : '$'}
            size="M"
            weight="semibold"
            currencyLowercase
            decimals={
              secondaryCurrency === DOTERS_CURRENCY ? 0 : environment.tripPriceDecimals || 0
            }
          />
        ) : null}
        {earlyDiscount && (
          <Text size="S" color="discount" italic weight="semibold">
            {t('search:available_discounts', { count: pricing.discountAvailability })}
          </Text>
        )}
        {!earlyDiscount && lowAvailability && (
          <Text size="S" color="discount" italic weight="semibold">
            {t('search:available_seats', { count: availability })}
          </Text>
        )}

        {providerDiscount && features.SHOW_OFFICE_PRICE && (
          <Text size="S" color="grayMedium">
            {t('search:ticket_office')}:
            <Currency
              price={totalBeforeDiscount}
              decimals={0}
              color="grayMedium"
              currency={getCurrencySuffix()}
              weight="semibold"
              size="XS"
            />
          </Text>
        )}
      </>
    );
  };

  const specialDiscountsComponent = hasSpecialDiscounts ? (
    <SeatAvailability
      t={t}
      studentSeat={studentSeat}
      teacherSeat={teacherSeat}
      minorSeat={minorSeat}
      olderSeat={olderSeat}
    />
  ) : null;

  const detailsComponent = showDetailsModal ? (
    <ProviderInfo
      amenities={line.services}
      providerDiscounts={providerDiscounts}
      initialTab={0}
      availabilityCategories={passengerTypes}
      tripId={id}
      earlyDiscount={earlyDiscount}
      buttonText={buttonText}
      onSelectClick={handleOnSelect}
      departure={departureHour}
      arrival={arrivalHour}
      price={!isExchange && primaryPrice}
      colorPrice={primaryPriceColor}
      currency={primaryCurrency}
      decimals={currencyDoters ? 0 : environment.tripPriceDecimals || 0}
      sign={currencyDoters ? '' : '$'}
      currencyLowercase={showFlatFare}
      showFlatFare={showFlatFare}
    >
      <div className="matrix-mobile-click">
        <FlatButton weight="bold" size="S" onClick={handleShowTripDetails}>
          {t('general:show_details')}
        </FlatButton>
      </div>
    </ProviderInfo>
  ) : (
    <></>
  );

  const showStopovers = filtersApplied?.stops?.includes('none');

  return (
    <ScheduleFlat
      brand={environment.brand}
      provider={
        <Spacing vertical size="XXS" alignItems="flex-start">
          <ProviderLogo name={environment.brand} imgSrc={line.logoUrl} />
          {isPetFriendly && <BadgePetFriendly />}
        </Spacing>
      }
      departure={
        <Text size="L" weight="semibold">
          {departureHour}
        </Text>
      }
      duration={
        showDuration && (
          <Text size="S" mediumSize="M" color="grayMedium" textAlign="center">
            {formatDuration(duration)}
          </Text>
        )
      }
      arrival={
        <Text size="L" color="grayMedium" textAlign="right">
          {arrivalHour}
        </Text>
      }
      seats={
        <Text size="S" weight="semibold">
          {t('search:seat', { count: availability })}
        </Text>
      }
      categories={
        showNewFiltersList ? (
          <TripFiltersBadges
            filtersApplied={filtersApplied}
            trip={trip}
            alternativeComponent={specialDiscountsComponent}
          />
        ) : (
          specialDiscountsComponent
        )
      }
      details={detailsComponent}
      removeStopovers={!showStopovers}
      stopovers={
        showStopovers &&
        !showNewFiltersList && (
          <ProviderInfo
            amenities={line.services}
            providerDiscounts={providerDiscounts}
            initialTab={0}
            availabilityCategories={passengerTypes}
            tripId={id}
            earlyDiscount={earlyDiscount}
            buttonText={buttonText}
            onSelectClick={handleOnSelect}
            departure={departureHour}
            arrival={arrivalHour}
            price={primaryPrice}
            colorPrice={primaryPriceColor}
            currency={primaryCurrency}
            decimals={currencyDoters ? 0 : environment.tripPriceDecimals || 0}
            sign={currencyDoters ? '' : '$'}
            currencyLowercase={showFlatFare}
          >
            <div className="matrix-mobile-click">
              <FlatButton size="S" weight="regular">
                {`${stopsText} - ${
                  variableDepartureTime
                    ? t('search:trip_service_on_the_way')
                    : t('search:trip_service_local')
                }`}
              </FlatButton>
            </div>
          </ProviderInfo>
        )
      }
      bus={
        <Text size="S">
          {secondFloor ? t('search:double_floor') : t('search:one_floor')}
          {features.SHOW_ETN_LUXURY && trip.line.abbr === 'etn-lujo' && (
            <Visibility display="inline" type="hideForMedium">
              {` - ${t('search:luxury')}`}
            </Visibility>
          )}
        </Text>
      }
      action={
        <Button
          text={buttonText}
          onClick={handleOnSelect}
          padding="S"
          size="S"
          weight="semibold"
          variant="accent"
          isRounded
        />
      }
      price={<PriceComponent />}
    />
  );
};

ResultSpecifics.propTypes = {
  arrival: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  departure: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  providerDiscount: PropTypes.any,
  providerDiscounts: PropTypes.array,
  availableCategories: PropTypes.array,
  total: PropTypes.number.isRequired,
  totalBeforeDiscount: PropTypes.number,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      abbr: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
    isPetFriendly: PropTypes.bool,
    recommendationType: PropTypes.string,
  }).isRequired,
  isExchange: PropTypes.bool.isRequired,
  useFlatFare: PropTypes.string,
  walletPoints: PropTypes.number,
  totalBase: PropTypes.number,
  discountBase: PropTypes.number,
  handleShowTripDetails: PropTypes.bool,
  filtersApplied: PropTypes.array,
  showNewFiltersList: PropTypes.bool,
  loadingPrice: PropTypes.bool,
};

ResultSpecifics.defaultProps = {
  providerDiscount: null,
  providerDiscounts: [],
};

export default ResultSpecifics;
