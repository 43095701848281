export function removeAllURLParams(sourceURL) {
  const rtn = sourceURL.split('?')[0];
  return rtn;
}

export function removeURLParam(key, sourceURL) {
  let param;
  let paramsArr = [];
  let rtn = sourceURL.split('?')[0];
  const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArr = queryString.split('&');
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      // eslint-disable-next-line prefer-destructuring
      param = paramsArr[i].split('=')[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    if (paramsArr.length) rtn += `?${paramsArr.join('&')}`;
  }
  return rtn;
}
