import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Currency, Gap, Icon } from '@reservamos/elements';
import { getCurrencyDecimals } from 'utils/currency';

/**
 * DiscountRowItem component
 *
 * Render a discount row item.
 *
 * Show discount as not available if:
 * - Availability is 0
 * - Total is not a number
 * Otherwise, show the discount.
 *
 * @param {object} props - label, availability, total
 * @param {string} props.label - The label of the discount.
 * @param {number} props.availability - The availability of the discount.
 * @param {number | string} props.total - The total of the discount.
 * @returns {JSX.Element} The rendered component.
 */
const DiscountRowItem = ({ label, availability, total }) => {
  const { t } = useTranslation('general');
  const noAvailable = availability === 0 || Number.isNaN(total);
  const seatContext = noAvailable ? 'zero' : '';

  return (
    <div className="provider-info-discounts-item">
      <Gap size="XS">
        <Icon type="Amenity" size="S" color={noAvailable ? 'grayLight' : 'accent'} />
        <Text size="S">{label}</Text>
      </Gap>
      <Text size="S">{t('search:seat', { count: availability, context: seatContext })}</Text>
      {noAvailable ? (
        <Text size="S" italic>
          {t('text.not_available')}
        </Text>
      ) : (
        <Currency
          color="discount"
          price={Number(total)}
          decimals={getCurrencyDecimals()}
          weight="semibold"
        />
      )}
    </div>
  );
};

DiscountRowItem.propTypes = {
  label: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DiscountRowItem;
