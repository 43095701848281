/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Text, Visibility, Icon } from '@reservamos/elements';
import 'ui/layouts/FaresModal';
import 'ui/layouts/FaresResults';
import PropTypes from 'prop-types';
import faresIcon from 'images/icons/suitcase.svg';

const FareDescription = ({ name, features, bagsCount }) => {
  return (
    <div className="fares-description-items">
      <Visibility type="hideForMobileOnly">
        <Text size="L" color="primary" weight="semibold" textAlign="center">
          {name}
        </Text>
      </Visibility>
      {Boolean(bagsCount) && (
        <div className="fares-description-icon">
          <Icon size="XL" type={faresIcon} />
          <span className="fares-icon-number">{bagsCount}</span>
        </div>
      )}

      <div className="fares-description-list">
        <Visibility type="showForMobileOnly">
          <Text color="primary" weight="semibold" elementType="span">
            {name}
          </Text>
        </Visibility>
        <ul className="list-bullets">
          {features.map((features) => (
            <li>
              <Text size="S" color="grayMedium" elementType="span">
                {features}
              </Text>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FareDescription.propTypes = {
  name: PropTypes.string.isRequired,
  bagsCount: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FareDescription;
