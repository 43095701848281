import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDuration } from 'utils/Helpers';
import InstallmentsTooltip from 'components/InstallmentsTooltip';
import { getCurrencySuffix } from 'utils/currency';

const FlightItem = ({
  origin,
  destination,
  departure,
  arrival,
  duration,
  providerDiscount,
  total,
  stops,
  installmentsMinAmount,
  onSelectClick,
}) => {
  const { t } = useTranslation('purchase');
  let stopsLabel = <p className="schedules-results-font">Directo</p>;

  if (stops === 1) {
    stopsLabel = <p className="schedules-results-font">1 escala</p>;
  } else if (stops > 1) {
    stopsLabel = <p className="schedules-results-font">{`${stops} escalas`}</p>;
  }

  return (
    <div className="result-box schedules-results">
      {providerDiscount ? <i className="result-box-sale" /> : null}
      <i className={`result-box-arrow ${providerDiscount ? 'sale' : ''}`} />

      <div className="result-box-main">
        <span className="schedules-results-font main">{moment(departure).format('LT')}</span>

        <span className="schedules-results-font">{` - ${moment(arrival).format('LT')}`}</span>
      </div>

      <div className="result-box-departures">
        <p className="schedules-results-font">{formatDuration(duration)}</p>
        {stopsLabel}
      </div>

      <div className="result-box-price">
        {providerDiscount ? (
          <label className="price">{formatCurrency(providerDiscount.amount + total)}</label>
        ) : null}

        <span className={`provider-price ${providerDiscount ? 'sale' : ''}`}>
          {`${formatCurrency(total)} `}
          <small>{getCurrencySuffix()}</small>
        </span>
        <InstallmentsTooltip installmentsMinAmount={installmentsMinAmount} />
      </div>

      <div className="result-box-summary">
        <div className="result-box-terminals">
          <p>{`${origin.cityName} - ${origin.name}`}</p>
          <p>{`${destination.cityName} - ${destination.name}`}</p>
        </div>
      </div>

      <div className="result-box-action">
        <button
          className={`button-primary ${providerDiscount ? 'sale' : ''}`}
          onClick={onSelectClick}
        >
          {t('select')}
        </button>
      </div>
    </div>
  );
};

FlightItem.propTypes = {
  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  providerDiscount: PropTypes.object,
  total: PropTypes.number.isRequired,
  stops: PropTypes.number,
  installmentsMinAmount: PropTypes.number.isRequired,
  onSelectClick: PropTypes.func.isRequired,
};

export default FlightItem;
