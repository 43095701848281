import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SmallButton, Text } from '@reservamos/elements';
import './NerbyTerminals.scss';

const NerbyTerminals = ({ onColumns, onClick, titleSize, terminals }) => {
  const { t } = useTranslation('search');

  if (!terminals) return null;
  if (terminals.length === 0) return null;

  return (
    <div className={`nerby-terminals ${onColumns ? 'nerby-terminals-columns' : ''}`}>
      <Text size={titleSize}>{t('label.near_terminals')}</Text>
      <div className="nerby-terminals-gap">
        {terminals.map(({ id, name, cityName }) => (
          <SmallButton
            key={id}
            text={`${name}, ${cityName}`}
            iconType="Pin"
            onClick={() => onClick(id)}
          />
        ))}
      </div>
    </div>
  );
};

NerbyTerminals.propTypes = {
  onClick: PropTypes.func,
  onColumns: PropTypes.bool,
  titleSize: PropTypes.oneOf(['S', 'M']),
  terminals: PropTypes.array,
};

NerbyTerminals.defaultProps = {
  onClick: () => {},
  onColumns: false,
  titleSize: 'S',
  terminals: [],
};

export default NerbyTerminals;
