import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Text, Spacing, Alert } from '@reservamos/elements';
import SectionTitle from 'ui/atoms/SectionTitle';
import Loader from 'ui/atoms/Loader';
import { useTranslation } from 'react-i18next';
import { executeRecaptcha } from 'utils/googleRecaptcha';
import { createSchedulePath } from '../../../utils/exchange';
import ExchangeTypeahead from '../ExchangeTypeahead';
import ExchangeTripList from '../ExchangeTripList';
import DatePicker from '../../DatePicker';
import ExchangeDatePicker from '../ExchangeDatePicker';

const ExchangeSecondStep = ({
  isFetching,
  token,
  originSlug,
  originDisplay,
  destinationDisplay,
  destinationSlug,
  date,
  currentTicket,
  clearTypeahead,
  errorTrip,
  errorTripExchange,
  validateExchange,
  setDate,
}) => {
  const { t } = useTranslation('exchange');
  const { features, env } = useSelector((state) => state.whitelabelConfig);

  const history = useHistory();
  const { operationNumbers, NIT, origin, destination } = useParams();

  const placesBaseUrl = `${env.api.searchUrl}/v2/places`;

  const [originInput, setOriginInput] = useState('');
  const [destinationInput, setDestinationInput] = useState('');
  const [originUrl, setOriginUrl] = useState('');
  const [destinationUrl, setDestinationUrl] = useState('');
  const [tripSelected, setTripSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const usingRecaptcha = features.EXCHANGE_REQUIRE_RECAPTCHA && env.recaptcha;

  const tripHaveSelected = useRef();

  const { maxDaysSearch } = env.searchWidget;
  // Get the current date
  const currentDate = moment();
  // Add the max days search days to the current date
  const maxDate = maxDaysSearch && currentDate.add(maxDaysSearch, 'days').toDate();

  /**
   * Function to handle the recaptcha token
   */
  const onCaptchaVerify = (onRecaptchaToken) => {
    const action = features.EXCHANGE_RECAPTCHA_ACTION;
    executeRecaptcha(action, (recaptchaToken) => {
      onRecaptchaToken(recaptchaToken);
    });
  };

  useEffect(() => {
    if (currentTicket === '') {
      const operationNumbersArray = operationNumbers.split(',');

      /**
       * Function to validate the exchange wrapping the recaptcha token if needed
       * @param {*} [recaptchaToken] - Recaptcha token
       * @returns {function} - Dispatch function
       */
      const validationFunction = (recaptchaToken) =>
        validateExchange(history, operationNumbersArray, NIT, origin, destination, recaptchaToken);

      if (usingRecaptcha) {
        onCaptchaVerify(validationFunction);
      } else {
        validationFunction();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (originInput !== '' && originDisplay === '') {
      setOriginUrl(`${placesBaseUrl}?q=${originInput}`);
    }
  }, [originInput, placesBaseUrl, originDisplay]);

  useEffect(() => {
    if (destinationInput !== '' && destinationDisplay === '') {
      setDestinationUrl(`${placesBaseUrl}?q=${destinationInput}&from=${originSlug}`);
    }
  }, [destinationDisplay, destinationInput, originSlug, placesBaseUrl]);

  const isDestinationDisabled = !originSlug;

  useEffect(() => {
    if (originDisplay !== '') {
      setOriginInput(originDisplay);
    }
  }, [originDisplay]);

  useEffect(() => {
    if (destinationDisplay !== '') {
      setDestinationInput(destinationDisplay);
    }
  }, [destinationDisplay]);

  const handlePurchaseRedirect = useCallback(() => {
    if (!isFetching && token && tripSelected) {
      history.push(`/purchase/${token}/seats/departure`);
    }
  }, [history, isFetching, token, tripSelected]);

  useEffect(() => {
    handlePurchaseRedirect();
  }, [handlePurchaseRedirect]);

  useEffect(() => {
    if (errorTrip && !tripHaveSelected.current) {
      errorTripExchange(false);
      setIsLoading(false);
      setTripSelected(false);
      tripHaveSelected.current = false;
      history.push(createSchedulePath(operationNumbers, NIT, origin, destination));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorTrip]);

  const clearTypeaheadData = (origin = false, destination = false) => {
    clearTypeahead(origin, destination);
  };

  const handleKeyUp = (control, event) => {
    event.persist();

    if (control === 'origin' && originSlug !== '') {
      clearTypeaheadData(true, true);
      setDestinationInput('');
    }

    if (control === 'destination' && destinationSlug !== '') {
      clearTypeaheadData(false, true);
    }
  };

  return (
    <>
      {(tripSelected || isLoading) && <Loader />}
      <div style={tripSelected || isLoading ? { display: 'none' } : {}}>
        <Spacing vertical>
          <SectionTitle title={t('labels.trip_schedule')} />
          {features.CAN_EXCHANGE_CHANGE_PLACES ? (
            <Spacing flexGrow>
              <ExchangeTypeahead
                label={t('labels.origin')}
                name="origin"
                onChange={setOriginInput}
                url={originUrl}
                value={originInput}
                onKeyUp={handleKeyUp}
              />
              <ExchangeTypeahead
                label={t('labels.destination')}
                name="destination"
                onChange={setDestinationInput}
                url={destinationUrl}
                value={destinationInput}
                isDisabled={isDestinationDisabled}
                onKeyUp={handleKeyUp}
              />
            </Spacing>
          ) : (
            <Spacing flexGrow>
              <Alert alertType="info" title={t('info.change_places')} />
            </Spacing>
          )}
          <div className="exchange-container-departure">
            <Text weight="semibold">{t('labels.trip_date')}</Text>
            <DatePicker
              onChange={setDate}
              value={date}
              input={<ExchangeDatePicker />}
              max={maxDate}
              modal
            />
          </div>
          <Spacing vertical>
            <div>
              <ExchangeTripList
                originSlug={originSlug}
                destinationSlug={destinationSlug}
                date={date}
                onSelectTrip={setTripSelected}
                setIsLoading={setIsLoading}
              />
            </div>
          </Spacing>
        </Spacing>
      </div>
    </>
  );
};

ExchangeSecondStep.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  originSlug: PropTypes.string.isRequired,
  originDisplay: PropTypes.string.isRequired,
  destinationDisplay: PropTypes.string.isRequired,
  destinationSlug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  currentTicket: PropTypes.string.isRequired,
  clearTypeahead: PropTypes.func.isRequired,
  errorTrip: PropTypes.bool,
  errorTripExchange: PropTypes.func,
  validateExchange: PropTypes.func,
  setDate: PropTypes.func,
};

export default ExchangeSecondStep;
