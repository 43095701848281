import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/DetailsTitle';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { Currency, Text } from '@reservamos/elements';

const propTypes = {
  total: PropTypes.number.isRequired,
  headerDescription: PropTypes.string,
  footerDescription: PropTypes.string,
  classModifier: PropTypes.string,
};

const defaultProps = {
  headerDescription: '',
  footerDescription: '', // total $4200 MXN (for installments)
  classModifier: '',
};

const DetailsTitle = ({ total, headerDescription, footerDescription, classModifier }) => {
  return (
    <div className={`detail-title${classModifier}`}>
      <Text weight="semibold" color="accent" size="S">
        {headerDescription}
      </Text>
      <div>
        <Currency
          weight="semibold"
          color="accent"
          size="S"
          price={total}
          sign={getCurrencyPrefix()}
          currency={getCurrencySuffix()}
        />
        {footerDescription && (
          <Text size="S" italic>
            {footerDescription}
          </Text>
        )}
      </div>
    </div>
  );
};

DetailsTitle.propTypes = propTypes;
DetailsTitle.defaultProps = defaultProps;

export default DetailsTitle;
