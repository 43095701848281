/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useShortAccessFilters from '../../../hooks/useShortAccessFilters';
import useAvailableCategories from '../../../hooks/useAvailableCategories';
import useAvailableSchedulesOptions from '../../../hooks/useAvailableSchedulesOptions';
import useAvailableSchedulesProvidersOptions from '../../../hooks/useAvailableSchedulesProvidersOptions';
import useAvailableStops from '../../../hooks/useAvailableStops';
import useAvailableTripOptions from '../../../hooks/useAvailableTripOptions';
import { saveShortAccessFilters } from '../../../utils/shortAccessFilters';
import ShortAccessFiltersList from './ShortAccessFiltersList';
import filtersTypes from '../../../constants/filtersTypes';
import useFiltersContext from '../../../hooks/useFilters';

const { CATEGORIES, DEPARTURE_TIME, STOPS, TRIP_OPTIONS } = filtersTypes;

export const ShortAccessFilters = ({ onSelect, way, isProviderList, isOpenTicketList }) => {
  const scheduleOptions = useAvailableSchedulesOptions(way, true);
  const scheduleProviderOptions = useAvailableSchedulesProvidersOptions(way, true);
  const availableCategories = useAvailableCategories(way, true);
  const availableStops = useAvailableStops(way, true);
  const availableTripOptions  = useAvailableTripOptions(way, true);

  const { scheduleFilters, stopsFilters, categoriesFilters, scheduleFiltersProviders, tripOptionsFilters } = useFiltersContext();

  const [shortAccessFilters, setShortAccessFilters] = useShortAccessFilters();
  
  // Validate if the filter is available
  const validateAvailability = ({ filterType, value }) => {
    if (filterType === CATEGORIES) {
      if (isProviderList || isOpenTicketList) return false;
      return availableCategories?.find((category) => category.value === value)?.hasAvailability;
    }
    if (filterType === DEPARTURE_TIME) {
      const scheduleOptionsToUse = isProviderList ? scheduleProviderOptions : scheduleOptions;
      return scheduleOptionsToUse?.find((schedule) => schedule.value === value)?.isAvailable;
    }
    if (filterType === STOPS && !isProviderList) {
      return availableStops?.some((filter) => filter.value === value);
    }
    if (filterType === TRIP_OPTIONS) {
      const isObject = typeof value === 'object';
      const isValid = availableTripOptions?.find(
        (tripOption) =>
          tripOption.value === value ||
          (isObject && JSON.stringify(tripOption.value) === JSON.stringify(value)),
      )?.hasAvailability;
      return isValid;
    }
  };

  const isActiveTripFilterOption = (value) => {
    if (typeof value === 'object') {
      return tripOptionsFilters?.find(
        (option) => JSON.stringify(option) === JSON.stringify(value),
      );
    }
    return tripOptionsFilters.includes(value);
  }

  /**
   * Function to check if a filter option is active base on the filters state.
   */
  useEffect(() => {
    // The different filters options are checked extracting the one actives
    const activeScheduleFilters = 
      !isProviderList ? scheduleOptions.filter((scheduleOption) => scheduleFilters?.includes(scheduleOption.value)) : [];

    const activeCategoriesFilters = 
      availableCategories.filter((availableCategory) => categoriesFilters?.includes(availableCategory.value));

    const activeProviderScheduleFilters = 
      isProviderList ? scheduleProviderOptions?.filter((scheduleProviderOption) => scheduleFiltersProviders?.includes(scheduleProviderOption.value)) : [];

    const activeStopsFilters = 
    availableStops?.filter((availableStop) => stopsFilters?.includes(availableStop.value)) || [];

    const activeTripOptionsFilters = availableTripOptions?.filter((availableTripOption) => isActiveTripFilterOption(availableTripOption.value)) || [];
    

    // The new short access filters array is created
    const activeFilters = [...activeScheduleFilters, ...activeProviderScheduleFilters, ...activeCategoriesFilters, ...activeStopsFilters, ...activeTripOptionsFilters];
    const missedActiveFilters = activeFilters.filter((activeFilter) => !shortAccessFilters.some((filter) => filter.value === activeFilter.value));
    const newShortAccessFilters = [...shortAccessFilters, ...missedActiveFilters];
    newShortAccessFilters.forEach((filter) => {
      if (activeFilters.some((activeFilter) => activeFilter.value === filter.value)) {
        filter.isActive = true;
      } else {
        filter.isActive = false;
      }
    });
        
    /**
     * The filters that are not already available are removed from the array of short access filters that will be shown
     */
     const availableShortAccessFilters = newShortAccessFilters.filter((filter) => validateAvailability(filter));

    /**
     * The short access filters filtered by availability are set in the state. Because these are the filters
     * that are going to be shown.
     * The short access filters that are not filtered by availability are saved in the local storage.
     */
    setShortAccessFilters(availableShortAccessFilters);
    saveShortAccessFilters(newShortAccessFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleFilters, stopsFilters, categoriesFilters, scheduleFiltersProviders]);

  return shortAccessFilters.length ? (
      <ShortAccessFiltersList options={shortAccessFilters} onSelect={onSelect} isProviderList={isProviderList} />
  ) : null;
};

ShortAccessFilters.propTypes = {
  onSelect: PropTypes.func.isRequired,
  way: PropTypes.string.isRequired,
  isProviderList: PropTypes.bool,
  isOpenTicketList: PropTypes.bool,
};

export default ShortAccessFiltersList;
