import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Spacing } from '@reservamos/elements';
import PropTypes from 'prop-types';
import DesktopHeader from 'components/DesktopHeader';
import { useTranslation } from 'react-i18next';
import MenuButton from 'ui/atoms/MenuButton';
import SlidingMenu from 'ui/molecules/SlidingMenu';
import 'styles/components/Header';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import AdaptiveLanguageSelector from 'ui/atoms/AdaptiveLanguageSelector';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';
import LoyaltyButton from '../LoyaltyButton';

const propTypes = {
  children: PropTypes.node,
  backButton: PropTypes.bool,
  homeButton: PropTypes.bool,
  showMenu: PropTypes.bool,
  switchMenu: PropTypes.func,
  hideLoyalty: PropTypes.bool,
  isFixed: PropTypes.bool,
  searchFixed: PropTypes.bool,
  aditionalFixedContent: PropTypes.node,
  showLogo: PropTypes.bool,
};

const defaultProps = {
  backButton: false,
  children: null,
  homeButton: false,
  showMenu: false,
  switchMenu: () => console.log('switch Menu'),
};

const Header = ({
  backButton,
  homeButton,
  switchMenu,
  showMenu,
  children,
  hideLoyalty,
  isFixed,
  searchFixed,
  aditionalFixedContent,
  showLogo,
}) => {
  const { SHOW_SIDEBAR_MENU } = useWhitelabelFeatures();
  const { colors, logos } = useWhitelabelTheme();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const isExchange = queryParams.get('isExchange');

  const handleGoBack = () => {
    history.go(-1);
  };

  const handleGoHome = () => {
    history.push('/');
  };

  const { t } = useTranslation('common');
  const backAction = backButton ? handleGoBack : handleGoHome;
  let leftSideButton;

  if (backButton || homeButton) {
    const hasLogo = logos.isotype && showLogo;
    leftSideButton =
      hasLogo || !backButton ? (
        <button onClick={() => backAction()} className="header-logo" aria-label="back">
          <Icon type={logos.isotype} size="M" />
        </button>
      ) : (
        <button onClick={() => backAction()} aria-label="back">
          <Icon type="BackArrow" color={colors.headerText || 'white'} />
        </button>
      );
  }

  const headerContent = (
    <header className="topbar">
      <div className="topbar-mobile">
        {leftSideButton}

        {children}

        <Spacing vertical size="S">
          {SHOW_SIDEBAR_MENU ? (
            <MenuButton onClick={switchMenu} />
          ) : (
            <div className="topbar-wrapper-icons">
              {!hideLoyalty && <LoyaltyButton id="siempre-plus-widget-mobile-header" isLink />}
              {!SHOW_SIDEBAR_MENU && <AdaptiveLanguageSelector />}
            </div>
          )}
        </Spacing>
      </div>

      <DesktopHeader hideLoyalty={hideLoyalty} />
      {SHOW_SIDEBAR_MENU && <SlidingMenu isOpen={showMenu} t={t} />}
    </header>
  );

  if (isFixed || searchFixed) {
    return (
      <>
        {searchFixed ? (
          <div className={`header-block-${isExchange ? 'exchange' : 'search'}`} />
        ) : (
          <div className="header-block" />
        )}
        <div className="header header-fixed">
          {headerContent}
          {aditionalFixedContent}
        </div>
      </>
    );
  }

  return <>{headerContent}</>;
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
