import React from 'react';
import { Text, BadgeRounded, Spacing } from '@reservamos/elements';
import PropTypes from 'prop-types';
import './DiscountBanner.scss';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import Amenity from './Amenity';

/**
 * Renders the DiscountBanner component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.discountPercent - The discount percentage.
 * @returns {JSX.Element} The rendered component.
 */
const DiscountBanner = ({ discountPercent }) => {
  const { t } = useTranslation('common');
  const { SHOW_DIGITAL_DISCOUNT_BANNER } = useWhitelabelFeatures();
  return (
    <div className="banner-discount">
      {SHOW_DIGITAL_DISCOUNT_BANNER && (
        <Spacing size="XS" alignItems="center">
          <BadgeRounded
            equalRadius
            size="M"
            text={discountPercent}
            firstColor="discountLight"
            secondColor="discountStrong"
          />
          <Text mobileSize="XXS" size="S" color="grayStrong" italic>
            {t('common:labels.digital_purchase')}
          </Text>
        </Spacing>
      )}
      <Spacing size="XS">
        {!SHOW_DIGITAL_DISCOUNT_BANNER && <Text size="XS">{t('general:amenities')}</Text>}
        <Amenity type="bathroom" isIcon iconSize="S" />
        <Amenity type="tv" isIcon iconSize="S" />
        <Amenity type="wifi" isIcon iconSize="S" />
      </Spacing>
    </div>
  );
};

DiscountBanner.propTypes = {
  discountPercent: PropTypes.string,
};

export default DiscountBanner;
