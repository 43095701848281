import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * Calculates the age of a passenger based on their date of birth.
 *
 * @param {string|Date} dateOfBirth - The passenger's date of birth. If a string, it should be in the format specified by the format parameter.
 * @param {string} [format='YYYY-MM-DD'] - The format of the dateOfBirth string. Defaults to 'YYYY-MM-DD'.
 * @returns {number|null} The calculated age in years, or null if no date of birth is provided.
 */
function getPassengerAge(dateOfBirth, format) {
  if (!dateOfBirth) return null;

  const today = moment().startOf('day');
  const birthDate = moment(dateOfBirth, format).startOf('day');

  if (today.isSame(birthDate)) return 0;

  if (birthDate.isAfter(today)) return -1;

  const age = today.diff(birthDate, 'years');
  return age;
}

getPassengerAge.propTypes = {
  dateOfBirth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  format: PropTypes.string,
};

getPassengerAge.defaultProps = {
  dateOfBirth: null,
  format: 'YYYY-MM-DD',
};

export default getPassengerAge;
