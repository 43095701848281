/* global $ */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clearError } from '@/actions';
import 'styles/components/Notification';

const intervals = [];

const Notification = ({
  code,
  redirect,
  style,
  message,
  customMsg,
  customRedirectPath,
  alertType,
}) => {
  const history = useHistory();
  const containerEl = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('notifications');
  const type = style;

  const clearErrors = useCallback(() => {
    $(containerEl).toggleClass('feedback-slide-down feedback-slide-up');

    intervals.push(
      window.setTimeout(() => {
        dispatch(clearError());

        let redirectPath = '/';
        if (customRedirectPath) redirectPath = customRedirectPath;
        if (redirect) {
          history.push(redirectPath);
        }

        intervals.forEach(clearTimeout);
      }, 500),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, redirect]);

  useEffect(() => {
    if (code !== 0 && alertType !== 'fixed') {
      intervals.push(window.setTimeout(clearErrors, 8000, redirect));
    }
  }, [clearErrors, code, redirect, alertType]);

  if (code === 0 || alertType === 'fixed') {
    return <div />;
  }

  return (
    <div className="feedback-slide-down feedback-notice" ref={containerEl}>
      <div className={`${type}-notice`}>
        <div className="notice-container">
          <i className="notification-icon" />
          <p className="message">{customMsg || t(`${type}.${message}`)}</p>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  code: PropTypes.number,
  message: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  redirect: PropTypes.bool,
  customMsg: PropTypes.string,
  customRedirectPath: PropTypes.string,
  alertType: PropTypes.string,
};

function mapStateToProps(state) {
  const { code, message, style, redirect, customMsg, customRedirectPath, alertType } =
    state.errors || {
      code: 0,
      message: '',
      style: 'error',
      redirect: true,
      customMsg: '',
      customRedirectPath: '/',
      alertType: 'global',
    };

  return { code, message, style, redirect, customMsg, customRedirectPath, alertType };
}

export default connect(mapStateToProps)(Notification);
