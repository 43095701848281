import { connect } from 'react-redux';
import PaymentStatusIcon from './PaymentStatusIcon';

function parseTitle(status, type, paymentStatus) {
  switch (status) {
    case 'attempt':
      if (type === 'paypal') return 'pending';
      if (type === 'third_party') return 'pending';
      return status;
    case 'pending':
      if (paymentStatus === 'charged') return 'pendingCompleted';
      if (type === 'credit_card' || type === 'paypal' || type === 'transfer') return 'completed';
      return status;

    default:
      return status;
  }
}

const mapStateToProps = ({ purchase, payment }) => {
  const purchaseStatus = purchase.get('state') || payment.get('purchaseState');
  const paymentStatus = payment.get('status');
  const paymentType = payment.get('paymentType') || 'credit_card';
  const isExchange = purchase.get('isExchange');

  return {
    titleStatus: parseTitle(purchaseStatus, paymentType, paymentStatus),
    isExchange,
  };
};

export default connect(mapStateToProps)(PaymentStatusIcon);
