import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFormSyncErrors, getFormMeta, getFormValues } from 'redux-form';
import { getStoredPurchaser } from 'utils/storedPurchaser';
import { useMercadoPagoTDCContext } from '../components/mercadoPago';

const useMercadoPagoTDCDynamicValues = () => {
  const state = useSelector((state) => state);
  const purchaserFieldsSyncErrors = getFormSyncErrors('purchaser')(state);
  const purchaserFieldsMeta = getFormMeta('purchaser')(state);
  const purchaserFormValues = getFormValues('purchaser')(state);
  const { updateFields: updateMercadoPagoTDCFormFields } = useMercadoPagoTDCContext();

  const updateMercadoPagoTDCFieldsWithPurchaserFormValues = () => {
    const fieldsToAnalyze = ['email', 'purchaserFirstName', 'purchaserLastName', 'documentId'];
    const fieldsValidityState = fieldsToAnalyze.reduce((fieldsValidityState, fieldName) => {
      const hasSyncError = Boolean(purchaserFieldsSyncErrors[fieldName]);
      const fieldMeta = purchaserFieldsMeta[fieldName] || {};
      const hasBeenTouched = Boolean(fieldMeta.touched);
      const hasBeenVisited = Boolean(fieldMeta.visited);
      const isActive = Boolean(fieldMeta.active);
      return {
        ...fieldsValidityState,
        [fieldName]: {
          isValid: Boolean(!hasSyncError && hasBeenTouched && hasBeenVisited && !isActive),
        },
      };
    }, {});
    let holderName = '';
    if (
      fieldsValidityState.purchaserFirstName.isValid &&
      fieldsValidityState.purchaserLastName.isValid
    ) {
      holderName = `${purchaserFormValues.purchaserFirstName} ${purchaserFormValues.purchaserLastName}`;
    }

    updateMercadoPagoTDCFormFields({
      holderName,
      holderEmail: fieldsValidityState.email.isValid ? purchaserFormValues.email : '',
      identificationNumber: fieldsValidityState.documentId.isValid
        ? purchaserFormValues.documentId
        : '',
    });
  };

  useEffect(() => {
    updateMercadoPagoTDCFieldsWithPurchaserFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchaserFieldsMeta,
    purchaserFieldsSyncErrors,
    purchaserFormValues,
    updateMercadoPagoTDCFormFields,
  ]);

  const getInitialMercadoPagoHolderData = () => {
    const storedPurchaser = getStoredPurchaser() || {};
    const { purchaserFirstName, purchaserLastName, email, documentId } = storedPurchaser;
    let name = '';
    if (purchaserFirstName && purchaserLastName) {
      name = `${purchaserFirstName} ${purchaserLastName}`;
    }
    return {
      name,
      email,
      identificationNumber: documentId,
    };
  };

  return { getInitialMercadoPagoHolderData };
};

export default useMercadoPagoTDCDynamicValues;
