import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { generateOTP, confirmOTP } from '../../../actions/payment';
import DotersModalOtp from './DotersModalOtp';

function DotersModalOtpContainer({ userEmail, visible, isLoading }) {
  // eslint-disable-next-line prettier/prettier
  const { payment, sso, purchase } = useSelector((state) => state);
  const { otpError } = payment.toJS();
  const { walletPointsUsed } = purchase.toJS();
  const { error: ssoError } = sso.toJS();

  const dispatch = useDispatch();

  if (ssoError) return null;

  const handleOnConfirmOtp = (confirmationCode) => {
    dispatch(confirmOTP(confirmationCode));
  };

  const handleOnGenerateOTP = () => {
    dispatch(generateOTP(Number(walletPointsUsed)));
  };

  return (
    <DotersModalOtp
      userEmail={userEmail}
      handleOnConfirmOtp={handleOnConfirmOtp}
      visible={visible}
      isLoading={isLoading}
      confirmationError={otpError}
      handleOnGenerateOTP={handleOnGenerateOTP}
    />
  );
}

DotersModalOtpContainer.propTypes = {
  userEmail: PropTypes.string,
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DotersModalOtpContainer;
