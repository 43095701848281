import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spacing } from '@reservamos/elements';
import CheckList from '../../ui/atoms/CheckList';
import PaymentImage from '../../ui/atoms/PaymentImage';

const PaypalForm = () => {
  const { copies } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('payment');

  const labels = [
    t('paypal_instructions.first'),
    t('paypal_instructions.second'),
    t('paypal_instructions.third', { provider: copies.provider.name }),
  ];

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <div className="checkout-paypal-logos">
          <PaymentImage type="paypal-logo" />
          <Spacing isResponsive>
            <PaymentImage type="credit-options" />
            <PaymentImage type="debit-options" />
          </Spacing>
        </div>
        <CheckList title={t('label.pay_with_payPal')} labels={labels} />
      </Spacing>
    </div>
  );
};

export default PaypalForm;
