import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, Text } from '@reservamos/elements';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '@/actions';
import { useTranslation } from 'react-i18next';

/**
 * SeatsAlert component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.padding - The padding.
 * @param {boolean} props.scrollToAlert - The scrollToAlert.
 * @param {string} props.titleSize - The titleSize.
 * @param {string} props.titleMobileSize - The titleMobileSize.
 * @param {string} props.weightTitle - The weightTitle.
 */
const SeatsAlert = ({
  padding = 'M',
  scrollToAlert,
  titleSize = 'M',
  titleMobileSize = 'M',
  weightTitle = 'semibold',
}) => {
  const { t } = useTranslation('notifications');
  const { code, message, style, customMsg, subMessage, alertType, messageData } = useSelector(
    (state) => state.errors,
  );

  const dispatch = useDispatch();
  const alertContainer = useRef();
  const shouldShowAlert = code !== 0 && alertType === 'fixed' && Boolean(message);

  useEffect(() => {
    return () => {
      if (alertType === 'fixed') dispatch(clearError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertType]);

  useEffect(() => {
    // Scrolling to the alert when an error/warning is showed
    if (scrollToAlert && shouldShowAlert) {
      alertContainer.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowAlert]);

  if (!shouldShowAlert) return null;

  return (
    <div id="alert-warning-container" ref={alertContainer}>
      <Alert
        title={customMsg || t(`${style}.${message}`, messageData)}
        alertType="error"
        hideIcon
        padding={padding}
        titleSize={titleSize}
        titleMobileSize={titleMobileSize}
        weightTitle={weightTitle}
      >
        {Boolean(subMessage) && <Text size="S">{subMessage}</Text>}
      </Alert>
    </div>
  );
};

SeatsAlert.propTypes = {
  padding: PropTypes.string,
  scrollToAlert: PropTypes.bool,
  titleSize: PropTypes.string,
  titleMobileSize: PropTypes.string,
  weightTitle: PropTypes.string,
};

export default SeatsAlert;
