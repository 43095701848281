import { Spacing, Tab, Tabs, Box, Gap } from '@reservamos/elements';
import Header from 'components/Header';
import SectionTitle from 'ui/atoms/SectionTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BrandLogo from 'components/BrandLogo';
import PqrsForm from './PqrsForm';
import PqrsTracking from './PqrsTracking';

/**
 * `Pqrs` is a React functional component that renders a page layout for handling PQRS (Petitions, Complaints, Requests, and Suggestions).
 * It includes a header with a brand logo, a section title, and tabs for switching between the PQRS registration form and the tracking interface.
 * @returns {JSX.Element} The JSX markup for the PQRS page.
 */
const Pqrs = () => {
  const { t } = useTranslation('pqrs');

  return (
    <div className="l-app-home">
      <Header isFixed>
        <BrandLogo />
      </Header>
      <Spacing vertical size="M" responsiveSize="M">
        <Box paddingHorizontal="L" paddingVertical="L" blurBg="">
          <Gap flexDirection="column" rowGap="M">
            <SectionTitle title={t('title')} />

            <Tabs borderColor="accent" activeTab={0} headerGrow headerShadow>
              <Tab label={t('register')}>
                <PqrsForm />
              </Tab>
              <Tab label={t('track')}>
                <PqrsTracking />
              </Tab>
            </Tabs>
          </Gap>
        </Box>
      </Spacing>
    </div>
  );
};

export default Pqrs;
