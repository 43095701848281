import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createValidator, required } from 'utils/formValidations';
import { setError } from '@/actions';
import { applyDiscount } from '@/actions/purchase';
import DiscountCode from './DiscountCode';

const validate = createValidator({
  discountCode: [required],
});

const mapStateToProps = (state) => {
  const { purchase } = state;

  return {
    token: purchase.get('token'),
    selectedInstallmentsPlan: purchase.get('selectedInstallmentsPlan').toObject(),
    applyingDiscount: purchase.get('applyingDiscount'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onApplyDiscount: (token, code, selectedInstallmentsPlan) =>
    dispatch(applyDiscount(token, code, selectedInstallmentsPlan)),

  onDiscountCodeFail: () => dispatch(setError(null, 'write_a_discount_code', 'warning', false)),
});

const DiscountCodeForm = reduxForm({
  form: 'discountCode',
  validate,
})(DiscountCode);

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodeForm);
