import { connect } from 'react-redux';
import { setUseAnotherCard, setSelectedCard, deletePaymentCard } from '@/actions/cards';
import SavedCards from './SavedCards';

const mapStateToProps = ({ siemprePlus, cards }) => {
  return {
    cards: cards.toJS(),
    cardList: cards.get('cards'),
    selectedCard: cards.get('selectedCard'),
    isLoading: cards.get('isLoading'),
    user: siemprePlus.get('user'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    useAnotherCard: () => dispatch(setUseAnotherCard(true)),
    selectCard: (cardId) => dispatch(setSelectedCard(cardId)),
    deleteCard: (cardId) => dispatch(deletePaymentCard(cardId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedCards);
