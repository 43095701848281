import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDuration, formatPercent } from 'utils/Helpers';
import { Text, Button, FlatButton, ScheduleClassic } from '@reservamos/elements';
import AmenitiesModal from '../../ui/molecules/AmenitiesModal';
import BadgeWoman from '../../ui/molecules/BadgeWoman';
import '../../ui/layouts/MatrixResult';
import SeatAvailability from './SeatAvailability';
import TripFiltersBadges from './TripFiltersBadges';
import PriceComponent from './PriceComponent';

// hide the duration if is minor than 16min
// because this is probably wrong estimation
const MIN_DURATION = 15;

const ResultGfa = ({
  arrival,
  departure,
  duration,
  onSelectClick,
  providerDiscount,
  total,
  serviceType,
  trip,
  availableCategories,
  availability,
  earlyDiscount,
  supportWoman,
  isExchange,
  handleShowTripDetails,
  filtersApplied,
  hasDiscountType,
  showNewFiltersList,
  walletPoints,
  position,
  way,
  windowTicketPrice,
}) => {
  const [showDetailsModal, setShowDetailsModal] = useState(true);
  const { env, features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const { line, stops, variableDepartureTime, pricing } = trip;
  const showDuration = duration > MIN_DURATION;
  const discountRoundMethod =
    way === 'return' ? features.DISCOUNT_LABEL_ROUND_METHOD_ON_RETURN : undefined;
  const discountPercent = providerDiscount
    ? formatPercent(windowTicketPrice, total, discountRoundMethod)
    : null;
  const stopsText = t('search:trip_stopovers', {
    count: stops,
    context: stops === 0 ? 'direct' : null,
  });
  const categoriesByType = availableCategories.reduce((accum, category) => {
    accum[category.type] = category.availability;
    return accum;
  }, {});

  const teacherSeat = categoriesByType.teacher;
  const studentSeat = categoriesByType.student;
  const olderSeat = categoriesByType.older;
  const minorSeat = categoriesByType.minor;
  const availableSeat = availability;
  // eslint-disable-next-line
  const hasSpecialDiscounts =
    studentSeat !== 0 || teacherSeat !== 0 || olderSeat !== 0 || minorSeat !== 0;

  const buttonText = t('general:select');
  const departureHour = moment(departure).format('LT');
  const arrivalHour = moment(arrival).format('LT');

  const discountAvailability =
    providerDiscount &&
    (hasDiscountType ? pricing.discountAvailability : providerDiscount.availability);
  const isDiscountLimited = providerDiscount && discountAvailability > 0;
  const discountSeat = providerDiscount && discountAvailability;

  const hasEarlyDiscount = earlyDiscount || isDiscountLimited;
  const discountColor = hasEarlyDiscount ? 'primary' : 'discount';
  const colorPrice = providerDiscount ? discountColor : 'grayMedium';

  /**
   * Method to handle the selection of a trip
   * @param {String} funnelSection - section of the funnel where the trip was selected
   */
  const handleOnSelect = (funnelSection) => {
    const funnelSectionSelected = funnelSection === 'details' ? 'details' : 'results';
    onSelectClick(funnelSectionSelected);
    if (isExchange) setShowDetailsModal(false);
  };

  const ctaComponent = (
    <Button
      text={buttonText}
      onClick={handleOnSelect}
      padding="S"
      size="S"
      weight="semibold"
      variant="accent"
    />
  );

  const specialDiscountsComponent = hasSpecialDiscounts ? (
    <>
      <SeatAvailability
        t={t}
        studentSeat={studentSeat}
        teacherSeat={teacherSeat}
        minorSeat={minorSeat}
        olderSeat={olderSeat}
      />
      {supportWoman && <BadgeWoman />}
    </>
  ) : null;

  const categoriesContent = showNewFiltersList ? (
    <TripFiltersBadges
      filtersApplied={filtersApplied}
      trip={trip}
      alternativeComponent={specialDiscountsComponent}
    />
  ) : (
    specialDiscountsComponent
  );

  const hidePriceOnExchange = features.HIDE_PRICE_ON_EXCHANGE && isExchange;

  const detailsCompomnent = showDetailsModal ? (
    <AmenitiesModal
      amenities={line.services}
      buttonText={buttonText}
      onSelectClick={handleOnSelect}
      departure={departureHour}
      arrival={arrivalHour}
      price={!isExchange && total}
      colorPrice={colorPrice}
    >
      <div className="matrix-mobile-click">
        <FlatButton weight="bold" size="S" onClick={handleShowTripDetails}>
          {t('general:show_amenities')}
        </FlatButton>
      </div>
    </AmenitiesModal>
  ) : (
    <></>
  );

  return (
    <ScheduleClassic
      brand={env.brand}
      logoUrl={line.logoUrl}
      departure={
        <Text size="L">
          <b>{departureHour}</b>
          {` — ${arrivalHour}`}
        </Text>
      }
      duration={
        showDuration && (
          <Text size="S" mediumSize="M" color="grayMedium">
            {formatDuration(duration)}
          </Text>
        )
      }
      seats={<Text size="S">{t('search:seat', { count: availableSeat })}</Text>}
      categories={categoriesContent}
      trip={
        <Text size="S">
          {t('search:trip_service', {
            context: variableDepartureTime ? 'on_the_way' : 'local',
          })}
        </Text>
      }
      details={detailsCompomnent}
      stopovers={<Text size="S">{stopsText}</Text>}
      bus={<Text size="S">{t('trips:type.service', { context: serviceType })}</Text>}
      action={ctaComponent}
      price={
        <PriceComponent
          hide={hidePriceOnExchange}
          walletPoints={walletPoints}
          position={position}
          total={total}
          colorPrice={colorPrice}
          providerDiscount={providerDiscount}
          discountPercent={discountPercent}
          earlyDiscount={earlyDiscount}
          showOfficePrice={features.SHOW_OFFICE_PRICE}
          windowTicketPrice={windowTicketPrice}
          isDiscountLimited={isDiscountLimited}
          discountSeat={discountSeat}
        />
      }
    />
  );
};

const placeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
});

ResultGfa.propTypes = {
  arrival: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  destination: placeType.isRequired,
  duration: PropTypes.number.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  origin: placeType.isRequired,
  providerDiscount: PropTypes.any,
  availability: PropTypes.number.isRequired,
  availableCategories: PropTypes.array,
  total: PropTypes.number.isRequired,
  serviceType: PropTypes.string.isRequired,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
  }).isRequired,
  earlyDiscount: PropTypes.bool,
  supportWoman: PropTypes.bool,
  stops: PropTypes.number,
  variableDepartureTime: PropTypes.bool,
  isExchange: PropTypes.bool,
  handleShowTripDetails: PropTypes.func,
  filtersApplied: PropTypes.object,
  hasDiscountType: PropTypes.bool,
  showNewFiltersList: PropTypes.bool,
  walletPoints: PropTypes.number,
  position: PropTypes.number,
  way: PropTypes.string,
  windowTicketPrice: PropTypes.number,
};

ResultGfa.defaultProps = {
  providerDiscount: null,
  earlyDiscount: false,
  supportWoman: true,
};

export default ResultGfa;
