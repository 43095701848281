import React from 'react';
import PropTypes from 'prop-types';
import './PurchaseLayout.scss';
import { useSelector } from 'react-redux';

const PurchaseLayout = ({ children, sidebar }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';
  return (
    <div className={`purchase-layout ${isFlat ? 'purchase-layout-flat' : ''}`}>
      <div className="purchase-layout-sidebar">{sidebar}</div>
      <div className="purchase-layout-content">{children}</div>
    </div>
  );
};

PurchaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
};

export default PurchaseLayout;
