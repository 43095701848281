import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SortSelector from './SortSelector';
import ScheduleFilterSelector from './ScheduleFilterSelector';
import StopsFilterSelector from './StopsFilterSelector';
import 'styles/components/SearchSidebar';
import { FiltersProvider } from '../context/FiltersContext';
import NavbarFilters from './search/NavbarFilters/NavbarFiltersComponent';

const SearchSidebar = ({
  onChangeScheduleFilter,
  onChangeSort,
  scheduleFilter,
  stopsFilter,
  sort,
  way,
  isStopsFilterAvailable,
  onResetFilters,
  isOpenTicketList,
}) => {
  const { t } = useTranslation('search');
  const { features } = useSelector((state) => state.whitelabelConfig);
  const isNewFilters = features.NEW_FILTERS_VERSION;

  return (
    <div className={`search-sidebar ${isNewFilters ? '' : 'search-sidebar-old'}`}>
      {isNewFilters ? (
        <FiltersProvider>
          <NavbarFilters
            onApplyFilters={onChangeScheduleFilter}
            onChangeSort={onChangeSort}
            way={way}
            onResetFilters={onResetFilters}
            isOpenTicketList={isOpenTicketList}
          />
        </FiltersProvider>
      ) : (
        <>
          <p className="sidebar-title-copy">{t('label.sort_by')}</p>
          <SortSelector onChange={onChangeSort} value={sort} way={way} />
          <p className="sidebar-title-copy">{t('label.filter_by_schedules')}</p>
          <ScheduleFilterSelector
            onChange={(newValue) => onChangeScheduleFilter('departureTime', newValue)}
            value={scheduleFilter}
            way={way}
          />
          {isStopsFilterAvailable && (
            <>
              <p className="sidebar-title-copy">{t('label.filter_by_stops')}</p>
              <StopsFilterSelector
                onChange={(newValue) => onChangeScheduleFilter('stops', newValue)}
                value={stopsFilter}
                way={way}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

SearchSidebar.propTypes = {
  onChangeScheduleFilter: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  scheduleFilter: PropTypes.string,
  sort: PropTypes.string,
  way: PropTypes.string.isRequired,
  stopsFilter: PropTypes.string.isRequired,
  isStopsFilterAvailable: PropTypes.bool.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  isOpenTicketList: PropTypes.bool.isRequired,
};

SearchSidebar.defaultProps = {
  scheduleFilter: null,
  sort: null,
};

export default SearchSidebar;
