import { trackEvent } from 'user-analytics';
import { CurrentLang } from 'utils/lang';
import { serialize as serializeSearch } from './search';

function serializeProvider(provider) {
  const {
    id,
    name,
    transporterName,
    transportType,
    ally,
    copyrightProtected,
    mixedProviders,
    position,
    tripCount,
  } = provider;
  let transporterProperties;

  if (transportType === 'mixed') {
    const lines = [];
    const transporters = [];
    const transportTypes = [];

    mixedProviders.forEach(({ transportType, name, transporterName }) => {
      lines.push(name);
      transporters.push(transporterName);
      transportTypes.push(transportType);
    });

    transporterProperties = {
      'Multicarrier Lines': lines.join(),
      'Multicarrier Transporters': transporters.join(),
      'Multicarrier Transport Types': transportTypes.join(),
    };
  } else if (id === 'multicarrier') {
    transporterProperties = {
      Line: 'Multicarrier Price Travel',
      Transporter: 'Multicarrier Price Travel',
    };
  }

  return {
    'Line': name,
    'Transporter': transporterName,
    'Transport Type': transportType,
    'Ally': ally,
    'Copyright Protected': copyrightProtected,
    'Result Position': position,
    'Trip Count': tripCount,
    ...transporterProperties,
  };
}

export default function pickedProviderTracker(search, provider) {
  const analyticsData = {
    ...serializeSearch(search),
    ...serializeProvider(provider),
    Language: CurrentLang(),
  };

  trackEvent('Picked Provider', analyticsData);
}
