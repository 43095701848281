import { compose } from 'redux';
import { connect } from 'react-redux';
import valid from 'card-validator';
import store from '@/store';
import { reduxForm } from 'redux-form';
import CardForm from './CardForm';

const validate = ({
  holderName,
  cardNumber,
  cvv2,
  expirationMonth,
  expirationYear,
  billingAddress,
}) => {
  const errors = {};
  const { features } = store.getState().whitelabelConfig;

  if (!holderName) {
    errors.holderName = 'Required';
  }

  if (features.BILLING_ADDRESS_ENABLED && !billingAddress) {
    errors.billingAddress = 'Required billing address';
  }

  const validatedCardNumber = valid.number(cardNumber);
  if (!validatedCardNumber.isValid) {
    errors.cardNumber = 'Invalid card number';
  }

  const amexCVVDigits = 4;
  const cvvIsValidForAmex = valid.cvv(cvv2, amexCVVDigits).isValid;
  const cvvIsValidForOtherBrands = valid.cvv(cvv2).isValid;
  if (!(cvvIsValidForAmex || cvvIsValidForOtherBrands)) {
    errors.cvv2 = 'Invalid CVC';
  }

  if (!expirationMonth || !valid.expirationMonth(expirationMonth).isValid) {
    errors.expirationMonth = 'Invalid expiry';
  }
  if (!expirationYear || !valid.expirationYear(expirationYear).isValid) {
    errors.expirationYear = 'Invalid expiry';
  }

  return errors;
};

const mapStateToProps = (state) => {
  const { selectedInstallmentsPlan } = state.purchase.toJS();
  const { months } = selectedInstallmentsPlan;

  return {
    initialValues: {
      paymentPlan: months > 1 ? 'installments' : 'single',
      monthlySelectedPlan: months,
      cardBrand: '',
    },
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'card',
    validate,
  }),
)(CardForm);
