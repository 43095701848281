import { connect } from 'react-redux';
import { pollTransferPayment } from '../../../actions/payment';
import PendingTransferPayment from './PendingTransferPayment';

const mapStateTpProps = ({ payment }) => ({
  paymentLoaded: payment.get('loaded'),
  loadingPayment: payment.get('isFetching'),
  processorState: payment.get('kushkiProcessorState'),
  paymentStatus: payment.get('status'),
  purchaseState: payment.get('purchaseState'),
  paymentProvider: payment.get('paymentProvider'),
});

const mapDispatchToProps = {
  pollTransferPayment,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { pollTransferPayment } = dispatchProps;
  const { params } = ownProps.match;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    fetchPayment: () =>
      pollTransferPayment({
        purchaseToken: params.token,
        paymentId: params.paymentId,
      }),
    purchaseToken: params.token,
    redirect: ownProps.history.push,
  };
};

export default connect(mapStateTpProps, mapDispatchToProps, mergeProps)(PendingTransferPayment);
