import React from 'react';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PaymentImage from '../../../ui/atoms/PaymentImage';

/**
 * EvertecForm component renders the payment instructions for Evertec.
 * It uses internationalization to display text in the user's preferred language.
 * @returns {JSX.Element} The EvertecForm component with payment instructions.
 */
const EvertecForm = () => {
  const { t } = useTranslation('payment');

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <PaymentImage type="evertec" />

        <Text size="M" weight="bold">
          {t('evertec_instructions.first')}
        </Text>

        <Text size="S">{t('evertec_instructions.second')}</Text>

        <Text size="S" italic>
          {t('evertec_instructions.third')}
        </Text>

        <Text size="S" italic>
          {t('evertec_instructions.fourth')}
        </Text>
      </Spacing>
    </div>
  );
};

export default EvertecForm;
