import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@reservamos/elements';
import './SeatsButtonWrapper.scss';

/**
 * Renders a wrapper component for seats button.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children nodes.
 * @param {string} props.ctaText - The call-to-action text.
 * @param {Function} props.onClick - The on continue function.
 * @param {boolean} props.showContent - The flag to show the content.
 * @returns {React.ReactNode} The rendered component.
 */
const SeatsButtonWrapper = ({ children, ctaText, onClick, showContent }) => {
  return (
    <div className="seats-button-wrapper">
      {showContent ? <div className="seats-button-wrapper-content">{children}</div> : null}

      <Button
        text={ctaText}
        variant="primary"
        padding="M"
        fontSize="S"
        mobilePadding="XXL"
        borderRadius="S"
        fullWidthOnSmall
        fullWidth
        onClick={onClick}
      />
    </div>
  );
};

SeatsButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showContent: PropTypes.bool,
};

export default SeatsButtonWrapper;
