import React from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

const Icon = ({ size, image }) => <span className={`rvui-icon ${size} ${image}`} />;

Icon.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  image: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  size: 'medium',
};

export default Icon;
