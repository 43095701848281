import { camelizeKeys } from 'humps';

const parsePurchaser = (user) => {
  if (!user) return null;
  const camelizedUser = camelizeKeys(user);
  const purchaserFirstName = camelizedUser.firstName || camelizedUser.name;
  return {
    purchaserFirstName,
    purchaserLastName: camelizedUser.lastName,
    purchaserSecondLastName: camelizedUser.secondLastName,
    email: camelizedUser.email,
    ...(camelizedUser.phone && { phone: camelizedUser.phone }),
  };
};

export default parsePurchaser;
