import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link, Spacing, Text } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

/**
 * Renders a link to a location terminal.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the link.
 * @param {string} props.iconType - The type of icon to display.
 * @param {string} props.terminal - The terminal name.
 * @param {string} props.city - The city name.
 * @param {string} props.url - The URL for the link.
 * @param {Function} props.onClick - The click handler.
 * @returns {JSX.Element} The rendered component.
 */
const LocationTerminalLink = ({ label, iconType, terminal, city, url, onClick }) => {
  const { TERMINAL_INCLUDES_CITY_NAME } = useWhitelabelFeatures();
  let text = ``;
  if (terminal) {
    text += terminal;
  }
  if (!TERMINAL_INCLUDES_CITY_NAME && city) {
    text += terminal ? `, ${city}` : city;
  }

  return (
    <Spacing size="XS" alignItems="center">
      <Icon type={iconType} size="S" color="accent" />

      <Text mobileSize="S" weight="bold">
        {label}
      </Text>

      <Link
        mobileSize="S"
        size="M"
        text={text}
        href={url}
        onClick={onClick}
        type="grayStrong"
        newTab
      />
    </Spacing>
  );
};

LocationTerminalLink.propTypes = {
  label: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  terminal: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LocationTerminalLink;
