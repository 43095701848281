import { connect } from 'react-redux';
import { newPurchase } from '@/actions/purchase';
import parseQueryString from 'utils/queryString/parseQueryString';
import { getSeatsPurchasePayload, isSeatsOnResultActivated } from 'utils/seats';
import NewPurchase from './NewPurchase';

const mapStateToProps = (state) => ({
  purchase: state.purchase.toJS(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createPurchase: () => {
    const { location, match } = ownProps;
    const { departureId, returnId, passengers = 'A1' } = match.params;
    const {
      seenPrice,
      adAttribution = false,
      exchangeData = '',
      tripsInfo = '',
    } = parseQueryString(location.search);
    const exchangeDataParsed = exchangeData && JSON.parse(exchangeData);
    const parsedTripInfo = tripsInfo ? JSON.parse(tripsInfo) : {};
    const selectedSeats =
      isSeatsOnResultActivated() && getSeatsPurchasePayload({ departureId, returnId });

    dispatch(
      newPurchase(
        departureId,
        returnId,
        passengers,
        seenPrice,
        adAttribution,
        Boolean(exchangeDataParsed),
        exchangeDataParsed,
        null,
        null,
        parsedTripInfo,
        selectedSeats,
      ),
    );
  },
  replaceUrl: (route) => ownProps.history.replace(route),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPurchase);
