import React from 'react';
import PropTypes from 'prop-types';
import PaymentSelector from 'ui/atoms/PaymentSelector';
import PaymentForm from '../PaymentForm';

const PaymentOptionsTypeSelector = (props) => {
  const {
    availablePayments,
    paymentMethods,
    onPaymentTypeChange,
    isOpenTicket,
    selectedOption,
    cardErrorOccured,
    hideOptions,
  } = props;
  return (
    <>
      {!hideOptions && (
        <PaymentSelector
          availablePayments={availablePayments}
          paymentMethods={paymentMethods}
          selectedOption={selectedOption}
          onPaymentTypeChange={onPaymentTypeChange}
        />
      )}
      <PaymentForm
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        key={selectedOption}
        formType={selectedOption}
        isOpenTicket={isOpenTicket}
        selectedOption={selectedOption}
        cardErrorOccured={cardErrorOccured}
      />
    </>
  );
};

PaymentOptionsTypeSelector.propTypes = {
  availablePayments: PropTypes.array.isRequired,
  onPaymentTypeChange: PropTypes.func.isRequired,
  submitPurchaser: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  selectedOption: PropTypes.string.isRequired,
  cardErrorOccured: PropTypes.bool,
  paymentMethods: PropTypes.array,
  hideOptions: PropTypes.bool,
};

export default PaymentOptionsTypeSelector;
