import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDuration } from 'utils/Helpers';
import { ProviderResume, OutlineButton } from '@reservamos/elements';
import '../../ui/layouts/MatrixResult';
import NewPriceComponent from './NewPriceComponent';

/**
 * Renders a line for a new search result.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onSelectClick - The function to handle the select click event.
 * @param {Object} props.provider - The provider object.
 * @param {string} props.departuresDescription - The description of departures.
 * @returns {JSX.Element} The rendered component.
 */
const ResultLine = ({ onSelectClick, provider, departuresDescription }) => {
  const { t } = useTranslation();
  const buttonText = t('general:select');

  const { logoUrl, duration, tripCount, lowestPrice, service, name } = provider;

  return (
    <ProviderResume
      durationTitle={t('general:period')}
      departureTitle={t('search:label.selector_departures', { count: tripCount })}
      departure={departuresDescription}
      duration={formatDuration(duration)}
      brand={name}
      serviceType={service}
      logoUrl={logoUrl}
      button={
        <OutlineButton isRounded variant="primary" text={buttonText} onClick={onSelectClick} />
      }
      price={<NewPriceComponent total={lowestPrice} isProvider />}
    />
  );
};

ResultLine.propTypes = {
  onSelectClick: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  departuresDescription: PropTypes.string.isRequired,
};

export default ResultLine;
