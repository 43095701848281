import openPay from '../../../engines/openPay';

// @ts-check

/**
 * @typedef {import('./CardStrategy').CardStrategy} CardStrategy
 */

/**
 * OpenPay card strategy.
 * @function OpenPayStrategy
 * @returns {CardStrategy}
 */
const OpenPayStrategy = () => {
  /**
   * Create a payload for OpenPay.
   * @async
   * @function createPayload
   * @param {Object} cardInfo - The card details.
   * @param {string} cardInfo.cardNumber - The card number.
   * @param {string} cardInfo.holderName - The card holder's name.
   * @param {string} cardInfo.expirationYear - The card's expiration year.
   * @param {string} cardInfo.expirationMonth - The card's expiration month.
   * @param {string} cardInfo.cvv2 - The card's CVV.
   * @param {string} cardInfo.cardBrand - The card's brand.
   * @returns {Promise<Object>} The payload for OpenPay.
   * @throws {Error} If token creation fails.
   */
  const createPayload = async (cardInfo) => {
    try {
      const openPayToken = await openPay.generateToken({
        card_number: cardInfo.cardNumber,
        holder_name: cardInfo.holderName,
        expiration_year: cardInfo.expirationYear.toString().slice(-2),
        expiration_month: cardInfo.expirationMonth,
        cvv2: cardInfo.cvv2,
      });

      let payload = {
        credit_card_tokens: {
          open_pay_token: openPayToken,
        },
        device_session_id: window.OpenPay.deviceData.setup(),
      };

      payload = {
        ...payload,
        card_holder_name: cardInfo.holderName,
        card_number: cardInfo.cardNumber,
        card_brand: cardInfo.cardBrand,
      };

      return payload;
    } catch (error) {
      throw new Error('Failed to create OpenPay token. Please try again.', error);
    }
  };

  return { createPayload };
};

const openPayStrategy = OpenPayStrategy();

export default openPayStrategy;
