import React from 'react';
import { Badge, Icon, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

const BadgeNew = () => {
  const { t } = useTranslation('common');

  return (
    <Badge hasShadow roundCorners type="success" smallPadding>
      <Text color="white" elementType="span" size="S" weight="semibold">
        {t('labels.new')}
      </Text>
      <Icon hasMargin marginSide="left" size="S" type="emojiSparkles" />
    </Badge>
  );
};

export default BadgeNew;
