import React from 'react';
import PropTypes from 'prop-types';
import { Text, Currency, Icon, Spacing, Box } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import doterSilver from 'images/doters/silverdoters.svg';
import doterGold from 'images/doters/golddoter.svg';
import DotersRewardTooltip from '../../../ui/atoms/DotersRewardTooltip';

/**
  Component for displaying Doters rewards balance
  @param {string} props.nextLevel - The name of the next level
  @param {number} props.nextLevelPoints - Points needed to reach the next level
  @param {number} props.pointsEarned - Points earned
  @returns {JSX.Element} - Doters rewards balance component
*/
const DotersNextLevel = ({ nextLevel, nextLevelPoints }) => {
  const { t } = useTranslation(['loyalty', 'seats']);
  const { DOTERS_NO_CANCELLATION_NOTICE } = useWhitelabelFeatures();

  const levelValues = {
    silver: {
      icon: doterSilver,
      level: 'points_from_the_level_silver',
    },
    gold: {
      icon: doterGold,
      level: 'points_from_the_level_gold',
    },
  };

  const level = levelValues[nextLevel]?.level;
  const levelIcon = levelValues[nextLevel]?.icon;

  return (
    <Spacing vertical size="XS" fullWidth mobileAlign="center">
      {nextLevel && (
        <DotersRewardTooltip levelIcon={levelIcon} level={nextLevel}>
          <Spacing alignItems="center" size="XS" fullWidth>
            <Text color="loyalty" mobileSize="S" whiteSpace="nowrap">
              {`${t('loyalty:you_are_to')} `}

              <Currency
                price={nextLevelPoints}
                decimals={0}
                sign=""
                weight="semibold"
                size="S"
                color="loyalty"
              />

              {t(`loyalty:${level}`)}
            </Text>

            <Icon type={levelIcon} size="S" />
          </Spacing>
        </DotersRewardTooltip>
      )}
      {DOTERS_NO_CANCELLATION_NOTICE && (
        <Box>
          <Text size="XS" color="grayMedium">
            {t('seats:passenger_policy.when_using_doters')}{' '}
            {t('seats:passenger_policy.doters_no_cancelable').toLowerCase()}
          </Text>
        </Box>
      )}
    </Spacing>
  );
};

DotersNextLevel.propTypes = {
  nextLevel: PropTypes.oneOf(['gold, silver']).isRequired,
  nextLevelPoints: PropTypes.number,
};

DotersNextLevel.defaultProps = {
  nextLevelPoints: null,
};

export default DotersNextLevel;
