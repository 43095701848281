import React from 'react';
import 'styles/components/TransferNotice';

const TransferNotice = () => (
  <div className="ts-transfer-notice">
    <i className="ic-transfer" />

    <p className="transfer-info">
      No incluye los traslados para la conexión de tu viaje multitransporte
    </p>
  </div>
);

export default TransferNotice;
