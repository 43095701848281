import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { setUsingWallet, updatePurchaseUser } from '../../../actions/purchase';
import CheckoutWallet from './CheckoutWallet';

const mapStateToProps = ({ purchase, siemprePlus }) => {
  const { user } = siemprePlus.toJS();

  return {
    purchaseToken: purchase.get('token'),
    updatingWallet: purchase.get('updatingWallet'),
    maxUsableBalance: purchase.get('maxUsableBalance'),
    usingWallet: Boolean(purchase.get('walletBalanceUsed')),
    walletBalanceUsed: purchase.get('walletBalanceUsed'),
    selectedPaymentOption: purchase.get('selectedPaymentOption'),
    chosenSpPassengerId: purchase.get('chosenSpPassengerId'),
    isExchange: purchase.get('isExchange'),
    total: purchase.get('total'),
    walletType: purchase.get('walletType'),
    user,
  };
};

const mapDispatchToProps = {
  setUsingWallet,
  updatePurchaseUser,
};

export default compose(
  withTranslation('Purchase'),

  connect(mapStateToProps, mapDispatchToProps),
)(CheckoutWallet);
