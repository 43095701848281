import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Spacing, Text } from '@reservamos/elements';
import discount from '../../images/doters/discount.svg';
import earned from '../../images/doters/gain.svg';
import './DotersPointsSummary.scss';

const DotersPointsSummary = ({ dotersUsed, dotersEarned, pendingStatus }) => {
  const { t } = useTranslation('loyalty');

  return (
    <Spacing vertical size="XS">
      <div className="doters-points-summary">
        <div className="doters-points-summary-image" />
        <div className="doters-points-summary-header">
          <Text weight="semibold" mobileSize="S" color="white">
            {t('summary_title')}
          </Text>
        </div>
        <div className="doters-points-summary-wrapper">
          <Spacing alignItems="center" justifyContent="center" flexGrow size="XS">
            {!!dotersUsed && (
              <Spacing alignItems="center" justifyContent="center" size="XS" isResponsive>
                <Icon type={discount} size="L" />
                <Text size="XL" color="grayLight" weight="semibold">
                  {dotersUsed}
                </Text>
                <Text color="grayLight" size="S">
                  {pendingStatus ? t('doters_to_be_used') : t('doters_Used')}
                </Text>
              </Spacing>
            )}
            {!!dotersEarned && (
              <Spacing alignItems="center" justifyContent="center" size="XS" isResponsive>
                <Icon type={earned} size="XL" />
                <Text size="XL" color="loyaltyLight" weight="semibold">
                  {dotersEarned}
                </Text>
                <Text color="loyaltyLight" size="S">
                  {pendingStatus ? t('doters_to_be_earn') : t('doters_Earned')}
                </Text>
              </Spacing>
            )}
          </Spacing>
        </div>
      </div>
      <Text size="S" mobileSize="XS" color="grayLight">
        {t('summary_Points')}
      </Text>
    </Spacing>
  );
};

DotersPointsSummary.propTypes = {
  dotersUsed: PropTypes.string,
  dotersEarned: PropTypes.string,
  pendingStatus: PropTypes.bool,
};

DotersPointsSummary.defaultProps = {
  dotersUsed: '',
  dotersEarned: '',
};

export default DotersPointsSummary;
