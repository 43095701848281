import React from 'react';
import { Box, Spacing, Icon, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import dotersDot from 'images/doters/greendoters.svg';

/**
 * Component used to display a badge for the Doters Flatfare reward.
 * @returns {JSX.Element} A React component that renders a badge indicating an exclusive fare reward.
 */
const DotersFlatfareBadge = () => {
  const { t } = useTranslation();
  return (
    <Box
      alphaBg="S"
      bgColor="loyaltyLight"
      borderRadius="L"
      elementType="span"
      paddingHorizontal="XS"
      paddingVertical="XXS"
    >
      <Spacing size="XS" alignItems="center">
        <Icon size="XS" type={dotersDot} />
        <Text color="loyalty" size="XS" mobileSize="XXS" weight="semibold">
          {t('loyalty:message_doters:reward_ticket_text_tarifa_exclusiva')}
        </Text>
      </Spacing>
    </Box>
  );
};

export default DotersFlatfareBadge;
