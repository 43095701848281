import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacing, Text } from '@reservamos/elements';

const InfoText = ({ children, textSize, icon, iconColor, alignment }) => (
  <Spacing size="XS" alignItems={alignment}>
    <Icon type={icon} size="S" color={iconColor} />
    <Text size={textSize}>{children}</Text>
  </Spacing>
);

InfoText.propTypes = {
  children: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
  textSize: PropTypes.string,
  icon: PropTypes.string,
  alignment: PropTypes.string,
};

InfoText.defaultProps = {
  icon: 'CircleInfo',
  iconColor: 'info',
  textSize: 'S',
  alignment: 'center',
};

export default InfoText;
