import { Map, fromJS } from 'immutable';
import * as types from 'constants/ActionTypes';
import parseTripDetails from 'utils/trips/parseTripDetails';

const defaultState = Map({
  loadingTripId: null,
  visibleTrip: null,
  trips: Map({}),
});

export default function TripsDetails(tripsDetails = defaultState, action) {
  switch (action.type) {
    case types.SHOW_TRIP_DETAILS:
      return tripsDetails.set('visibleTrip', fromJS(action.details));
    case types.ADD_TRIP_DETAILS:
      return tripsDetails.mergeIn(['trips', action.tripId], fromJS(action.details));
    case types.SET_TRIP_LOADING:
      return tripsDetails.set('loadingTripId', action.tripId);
    case types.RECEIVE_BUSES: {
      const { lines, terminals } = action.apiResponse;
      const details = {};
      action.apiResponse.trips
        .filter(({ path: hasDetails }) => Boolean(hasDetails))
        .forEach((trip) => {
          details[trip.id] = parseTripDetails({ trip, lines, terminals });
        });
      return tripsDetails.mergeIn(['trips'], fromJS(details));
    }
    default:
      return tripsDetails;
  }
}
