/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import 'styles/components/modals/LocationFilter';

function setupAccordion() {
  $('.accordion.location .submenu:first').css('display', 'block');
  $('.accordion.location .trigger').bind('click', (e) => {
    e.preventDefault();

    if ($(this).parent().hasClass('is-expanded')) {
      return;
    }

    $('.accordion.location .submenu').slideToggle('fast');
    // apply the toggle to the ul
    $('.accordion.location .content').toggleClass('is-expanded');
  });
}

function toggleAccordion() {
  $('.accordion.location .submenu').slideToggle('fast');
  // apply the toggle to the ul
  $('.accordion.location .content').toggleClass('is-expanded');
}

class LocationFilter extends Component {
  componentDidMount() {
    document.querySelector('.modal').classList.add('modal-view-options');
    setupAccordion();
  }

  componentWillUnmount() {
    document.querySelector('.modal').classList.remove('modal-view-options');
  }

  select({ type, id }) {
    const { hideModal, onFilterSelect } = this.props;

    onFilterSelect(type, id);

    if (type === 'departureLocation') {
      toggleAccordion();
    } else {
      hideModal();
      window.scrollTo(0, 0);
    }
  }

  renderLocationList(locations, selected) {
    return locations.map((location) => {
      const { id, name, type } = location;

      return (
        <div key={id} onClick={() => this.select(location)}>
          <li>
            <div className="modal-row">
              <div className="modal-select radio-button-check">
                <input type="radio" name={type} defaultChecked={id === selected} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label />
              </div>

              <div className="modal-option">
                <p>{name}</p>
              </div>
            </div>
          </li>
        </div>
      );
    });
  }

  render() {
    const {
      locations: { departure, arrival },
      departureLocation,
      arrivalLocation,
      t,
    } = this.props;

    return (
      <div className="modal-arrow-container location" onClick={(e) => e.stopPropagation()}>
        <form className="modal-content floating-sort-menu">
          <div className="subheader-accordion heading-drawer">
            <ul className="accordion location">
              <li className="content is-expanded">
                <a className="subheader-accordion-link trigger">{t('label.departing_from')}:</a>

                <ul className="submenu">{this.renderLocationList(departure, departureLocation)}</ul>
              </li>

              <li className="content">
                <a className="subheader-accordion-link trigger">{t('label.arriving_to')}:</a>

                <ul className="submenu">{this.renderLocationList(arrival, arrivalLocation)}</ul>
              </li>
            </ul>
          </div>
        </form>

        <div className="arrow-down" />
      </div>
    );
  }
}

LocationFilter.propTypes = {
  locations: PropTypes.shape({
    departure: PropTypes.array.isRequired,
    arrival: PropTypes.array.isRequired,
  }).isRequired,
  departureLocation: PropTypes.string.isRequired,
  arrivalLocation: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('trips')(LocationFilter);
