import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, Spacing, MessageBox, Link } from '@reservamos/elements';

const PromotionalCard = ({ message, iconUrl, linkUrl, linkText }) => {
  return (
    <MessageBox padding="S">
      <Spacing alignItems="center">
        {Boolean(iconUrl) && <Icon size="L" type={iconUrl} />}
        <Text size="S" weight="semibold">
          {`${message} `}
          {Boolean(linkUrl) && <Link href={linkUrl} text={linkText} type="accent" />}
        </Text>
      </Spacing>
    </MessageBox>
  );
};

PromotionalCard.propTypes = {
  message: PropTypes.string.isRequired,
  iconUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
};

PromotionalCard.defaultProps = {
  iconUrl: '',
  linkUrl: '',
  linkText: '',
};

export default PromotionalCard;
