import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  membershipDiscountPayment,
  membershipDiscountPaymentDelete,
  setUsingWallet,
} from '@/actions/purchase';
import { walletSearchDiscount } from '@/actions/search';
import { updateWalletMoney } from '@/actions/loyalty';
import usePurchase from 'hooks/store/usePurchase';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import parseQueryString from 'utils/queryString/parseQueryString';
import {
  loyaltyApplyTracker,
  loyaltyRemoveTracker,
  loyaltyModifyTracker,
} from 'metrics/user-analytics/loyalty';
import LoyaltyProgramsContext from './LoyaltyProgramsContext';
import useLoyaltyWidgetSubscriptions from '../hooks/useLoyaltyWidgetSubscriptions';
import { getWalletTypeForPurchase, isServiceAllowed } from '../../utils/loyalty';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const LoyaltyProgramsProvider = ({ children }) => {
  useLoyaltyWidgetSubscriptions();
  const { search: queryParams } = useLocation();
  const { coupon: couponCodeQueryParam } = parseQueryString(queryParams);
  const dispatch = useDispatch();
  const purchase = usePurchase();
  const costaPass = useSelector((state) => state.costapass.toJS());
  const siemprePlus = useSelector((state) => state.siemprePlus.toJS());
  const search = useSelector((state) => state.search.toJS());
  const costaPassUserIsLoggedIn = Boolean(costaPass?.profile);
  const costaPassUserIsRequested = costaPass.requested;
  const mainUserIsLoggedIn = Boolean(siemprePlus.user);
  // eslint-disable-next-line prettier/prettier
  const mainUserIsRequested = siemprePlus.requested;
  const dotersOrSiemprePlusUser = useSelector((state) => state.siemprePlus.getIn(['user']));
  const originId = useSelector((state) => state.search.get('originId'));
  const destinationId = useSelector((state) => state.search.get('destinationId'));
  const departureDate = useSelector((state) => state.search.get('departureDate'));
  const returnDate = useSelector((state) => state.search.get('returnDate'));
  const environment = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();

  const selectedLoyaltyProgram = getWalletTypeForPurchase();
  const { availableWallets = [] } = purchase;
  const isValidWalletType =
    selectedLoyaltyProgram && availableWallets.includes(selectedLoyaltyProgram);
  const commonWalletProperties = {
    minUsableBalance: purchase.walletMinimumBalanceToBeUsed || 0,
    maxUsableBalance: purchase.maxUsableBalance,
    isUpdating: purchase.updatingWallet,
    wantsFlatFare: purchase.wantsFlatFare,
    usedBalance: purchase.walletBalanceUsed,
    usedPoints: Number(purchase.walletPointsUsed),
    minPointsToUse: purchase.walletMinimumPointsToBeUsed,
  };
  const loyaltyProgramsProfiles = {
    siemprePlus: dotersOrSiemprePlusUser?.toJS() || {},
    doters: dotersOrSiemprePlusUser?.toJS() || {},
    costapass: costaPass?.profile || {},
  };
  const costaPassBalance = costaPass?.profile?.balance || 0;
  const costaPassValues = {
    userIsLoggedIn: costaPassUserIsLoggedIn,
    userIsRequested: features.COSTAPASS_ENABLED ? costaPassUserIsRequested : true,
    profile: loyaltyProgramsProfiles.costapass,
    wallet: {
      ...commonWalletProperties,
      balance: costaPassBalance,
      maxUsableBalance:
        purchase.maxUsableBalance > costaPassBalance ? costaPassBalance : purchase.maxUsableBalance,
    },
    config: {
      ...(environment.travelpassLoyaltyConfig || {}),
    },
  };
  const dotersValues = {
    userIsLoggedIn: mainUserIsLoggedIn,
    userIsRequested: mainUserIsRequested,
    profile: loyaltyProgramsProfiles.doters,
    wallet: {
      ...commonWalletProperties,
    },
  };

  const { walletTypeDiscount } = search;
  const tripsWithWalletDiscount = search.discountedTripsByCoupon
    ? search.discountedTripsByCoupon[walletTypeDiscount] || []
    : [];

  useEffect(() => {
    if (!originId || mainUserIsLoggedIn || couponCodeQueryParam) return;
    if (costaPassUserIsLoggedIn) {
      dispatch(walletSearchDiscount('ewallet'));
    }
  }, [
    originId,
    departureDate,
    returnDate,
    destinationId,
    costaPassUserIsLoggedIn,
    mainUserIsLoggedIn,
    couponCodeQueryParam,
    dispatch,
  ]);

  /**
   * Function that validates if the loyalty program should be validated on exchange based on the feature flag
   * @param {*} loyalty - loyalty program to validate
   * @returns - boolean
   */
  const shouldValidateOnExchange = (loyalty) => {
    const { VALIDATE_LOYALTY_ON_EXCHANGE } = features;
    if (!VALIDATE_LOYALTY_ON_EXCHANGE || !VALIDATE_LOYALTY_ON_EXCHANGE.length) return false;
    return VALIDATE_LOYALTY_ON_EXCHANGE.includes(loyalty);
  };

  // CostaPass Methods

  // used to differentiate between costapass & travelpass for ui display naming
  const walletPrefix = environment.travelpassLoyaltyConfig?.prefix || 'costapass';
  const onApplyCostaPassPointsDiscount = (pointsToApply) => {
    const { costapassId, token: costaPassToken } = costaPass.profile;
    const pointsToApplyWithBackendFormat = Number(pointsToApply).toFixed(2).toString();
    dispatch(
      membershipDiscountPayment(
        purchase.token,
        {
          membership_id: costapassId,
          membership_token: costaPassToken,
          amount: pointsToApplyWithBackendFormat,
          type: 'costapass',
        },
        walletPrefix,
      ),
    );
    loyaltyApplyTracker({
      wallet: 'costapass',
      purchaseToken: purchase.token,
      amount: pointsToApplyWithBackendFormat,
    });
  };

  const onRemoveCostaPassPointsDiscount = () => {
    dispatch(membershipDiscountPaymentDelete(purchase.token, walletPrefix));
    loyaltyRemoveTracker({
      wallet: 'costapass',
      purchaseToken: purchase.token,
    });
  };

  const onRefreshCostaPassWalletBalance = () => {
    const { token: costaPassToken } = costaPass.profile;
    dispatch(updateWalletMoney('ewallet', costaPassToken, purchase.token));
  };

  // End CostaPass Methods

  // Doters Methods
  const onApplyDotersPointsDiscount = ({ pointsToApply, walletPointsUsed }) => {
    const updating = walletPointsUsed > 0;
    dispatch(setUsingWallet(purchase.token, true, pointsToApply, updating));
    const trackerPayload = {
      wallet: 'doters',
      purchaseToken: purchase.token,
      amount: pointsToApply,
    };

    // If the operation is about updating or modifying the points, we need to send a different tracker
    if (updating) {
      loyaltyModifyTracker(trackerPayload);
    } else {
      loyaltyApplyTracker(trackerPayload);
    }
  };

  const onRemoveDotersPointsDiscount = () => {
    dispatch(setUsingWallet(purchase.token, false));
    loyaltyRemoveTracker({
      wallet: 'doters',
      purchaseToken: purchase.token,
    });
  };

  // End Doters Methods

  const { departs, returns } = purchase;
  const isDotersServiceAvailable = isServiceAllowed({
    departs,
    returns,
    allowedServices: features.DOTERS_ALLOWED_SERVICES,
  });

  const contextValue = {
    selectedLoyaltyProgram: isValidWalletType ? selectedLoyaltyProgram : null,
    selectedLoyaltyProgramProfile: isValidWalletType
      ? loyaltyProgramsProfiles[selectedLoyaltyProgram]
      : undefined,
    userIsLoggedInWithAnyLoyaltyProgram: costaPassUserIsLoggedIn || mainUserIsLoggedIn,
    costaPass: {
      ...costaPassValues,
      onApplyPointsDiscount: onApplyCostaPassPointsDiscount,
      onRemovePointsDiscount: onRemoveCostaPassPointsDiscount,
      onRefreshWalletBalance: onRefreshCostaPassWalletBalance,
    },
    doters: {
      ...dotersValues,
      onApplyPointsDiscount: onApplyDotersPointsDiscount,
      onRemovePointsDiscount: onRemoveDotersPointsDiscount,
      isServiceAvailable: isDotersServiceAvailable,
    },
    tripsWithWalletDiscount,
    thereAreTripsWithWalletDiscount: tripsWithWalletDiscount.length > 0,
    loggedInWithMultiplePrograms: costaPassUserIsLoggedIn && mainUserIsLoggedIn,
    shouldValidateOnExchange,
  };

  return (
    <LoyaltyProgramsContext.Provider value={contextValue}>
      {children}
    </LoyaltyProgramsContext.Provider>
  );
};

LoyaltyProgramsProvider.propTypes = propTypes;

export default LoyaltyProgramsProvider;
