import React from 'react';
import Proptypes from 'prop-types';
import { Visibility, Text, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

const PassengersList = ({ passengers }) => {
  const { t } = useTranslation('exchange');
  const { t: tSeats } = useTranslation('seats');

  return passengers.map((passenger, index) => (
    <div className="passenger-container" key={passenger.id}>
      <Spacing vertical size="XS">
        <Visibility type="hideForMedium">
          <Text weight="bold" size="S">
            {t('labels.passenger')} {index + 1}
          </Text>
        </Visibility>
        <Text key={passenger.id} weight="semibold" size="L" mobileSize="S">
          {passenger.firstName} {passenger.lastName} {passenger.secondLastName}
          {passenger.category && (
            <Text elementType="span" key={passenger.id} weight="regular" size="S">
              - {tSeats(`passengers:type.${passenger.category}`)}
            </Text>
          )}
        </Text>
      </Spacing>
    </div>
  ));
};

PassengersList.protoTypes = {
  passengers: Proptypes.array,
};

export default PassengersList;
