export default function getSearchStep(pathname) {
  if (pathname.match(/\/return\/providers/)) {
    return 'providersReturns';
  }
  if (pathname.match(/\/providers/)) {
    return 'providers';
  }
  if (pathname.match(/\/departures/)) {
    return 'departures';
  }
  if (pathname.match(/\/returns/)) {
    return 'returns';
  }

  return '';
}
