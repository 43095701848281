import React from 'react';
import PropTypes from 'prop-types';
import 'styles/lib/helpers/javascript';
import './ContentRow.scss';

const ContentRow = ({ children, withBorder, horizontal }) => (
  <div
    className={`rvui-content-row ${withBorder ? 'rvui-content-border' : ''} ${
      horizontal ? 'horizontal' : ''
    }`}
  >
    {children}
  </div>
);

ContentRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  withBorder: PropTypes.bool,
  horizontal: PropTypes.bool,
};

ContentRow.defaultProps = {
  children: null,
  withBorder: false,
  horizontal: false,
};

export default ContentRow;
