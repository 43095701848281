import { serviceName as getServiceName } from 'utils/Reserbus';

const busProviderDetails = ({ transportType, line }) => {
  const {
    name,
    ally,
    copyrightProtected,
    logoUrl,
    redirectionInfo,
    averageRating,
    serviceType,
    services,
    openTicketLogosUrls,
  } = line;

  return [
    {
      provider: {
        transportType,
        name,
        ally,
        copyrightProtected,
        logoUrl,
        averageRating,
        siteUrl: redirectionInfo && redirectionInfo.mobile.shortUrl,
        serviceName: getServiceName(serviceType),
        openTicketLogosUrls,
      },
      services,
    },
  ];
};

const flightProviderDetails = ({
  transportType = 'flight',
  providerId,
  legs,
  services = [],
  carrier,
}) => {
  const { name, ally, logoUrl } = carrier;

  if (providerId === 'multicarrier') {
    return legs.map((leg) => {
      const { services = [], carrier } = leg;
      const { name, ally, logoUrl } = carrier;

      return {
        provider: { transportType, name, ally, logoUrl },
        services,
      };
    });
  }

  return [
    {
      provider: { transportType, name, ally, logoUrl },
      services,
    },
  ];
};

const mixedProviderDetails = (trip) => {
  const providerDetails = [];

  trip.fragments.forEach((fragment) => {
    if (fragment.transportType === 'bus') {
      providerDetails.push(...busProviderDetails(fragment));
    } else {
      providerDetails.push(...flightProviderDetails(fragment));
    }
  });

  return providerDetails;
};

export default (trip) => {
  switch (trip.transportType) {
    case 'bus':
      return busProviderDetails(trip);
    case 'flight':
      return flightProviderDetails(trip);
    case 'mixed':
      return mixedProviderDetails(trip);
    default:
      return [];
  }
};
