/**
 * Load search widget style on DOM Head
 * @returns {HTMLLinkElement} link element
 */

/**
 * You can use these ulr to load the widget on your page using
 * the local dev server, just run the search-widget project and use them
 *
 * http://localhost:8080/search.css
 * http://localhost:8080/search.js
 */

/**
 *  Load search widget style on DOM Head
 * @param {String} version
 * @returns
 */
export const loadSearchWidgetStyle = (version) => {
  // Load styles
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = `https://widgets.reservamos.mx/search/${version}/search.css`;
  document.head.appendChild(link);
  return link;
};

/**
 * Load search widget script on DOM
 * @returns {HTMLScriptElement} script element
 */
export const loadSearchWidgetScript = (version, useDynamicLoad) => {
  // Load scripts
  const script = document.createElement('script');
  script.src = `https://widgets.reservamos.mx/search/${version}/search.js`;
  if (useDynamicLoad) script.dataset.dynamicWidgetCreation = `true`;
  script.async = true;
  document.body.appendChild(script);
  return script;
};
