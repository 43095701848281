import React from 'react';
import PropTypes from 'prop-types';
import { ProviderLogo } from '@reservamos/elements';
import './ProvidersGrid.scss';

const ProvidersGrid = ({ logosToShow, column }) => {
  if (!logosToShow) return null;

  return (
    <div className={`providers-grid ${column && `providers-grid-column`}`}>
      {logosToShow &&
        logosToShow.map((logo) => (
          <ProviderLogo key={logo.name} name={logo.name} imgSrc={logo.url} isSmall />
        ))}
    </div>
  );
};

ProvidersGrid.propTypes = {
  column: PropTypes.bool,
  logosToShow: PropTypes.object,
};

ProvidersGrid.defaultProps = {
  column: false,
};

export default ProvidersGrid;
