import { connect } from 'react-redux';
import LoadingRedirect from './LoadingRedirect';

const mapStateToProps = ({ search, purchase }) => {
  const redirectTo = search.get('redirectTo');
  const isUpdating = purchase.get('isUpdating');
  const redirectUrl = purchase.get('redirectUrl');
  const brand = redirectTo.get('brand');
  const redirect = redirectTo.get('redirect');
  const departure = redirectTo.get('departure');
  const returns = redirectTo.get('return');
  const roundTrip = redirectTo.get('roundTrip');
  const waitForPurchase = redirectTo.get('waitForPurchase');
  const purchaseState = purchase.get('state');

  return {
    isUpdating,
    redirectUrl,
    brand,
    redirect,
    departure,
    returns,
    roundTrip,
    waitForPurchase,
    purchaseState,
  };
};

export default connect(mapStateToProps)(LoadingRedirect);
