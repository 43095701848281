import { useEffect } from 'react';
import {
  handleCostaPassProfileUpdate,
  triggerErrorAlert,
  handleDotersOrSiemprePlusProfileUpdate,
} from '../subscriptionsHandlers';

/**
 * Loyalty widget (SiemprePlus Widget) exposes a global object called reservamosPubSub which exposes
 * the method called "sub".
 * This method allows to subscribe to events that are triggered by the widget.
 *
 * The following events are available:
 * - costapass_profile_widget: This event is triggered when the user profile is updated.
 * - siempreplusAlertError: This event is triggered when an error occurs in the widget.
 * - siempreplus: This event is triggered when the user profile is updated.
 * - notUserSession: This event is triggered when the user is not logged in.
 *
 */
const loyaltyWidgetEvents = {
  onDotersOrSiemprePlusProfileUpdate: 'siempreplus',
  onCostaPassProfileUpdate: 'costapass_profile_widget',
  onError: 'siempreplusAlertError',
};

/**
 * This hook handles the subscriptions to the events triggered by the Loyalty widget.
 */
const useLoyaltyWidgetSubscriptions = () => {
  const setLoyaltyWidgetSubscriptions = () => {
    const { sub } = window.reservamosPubSub;

    sub(
      loyaltyWidgetEvents.onDotersOrSiemprePlusProfileUpdate,
      handleDotersOrSiemprePlusProfileUpdate,
    );
    sub(loyaltyWidgetEvents.onCostaPassProfileUpdate, handleCostaPassProfileUpdate);
    sub(loyaltyWidgetEvents.onError, triggerErrorAlert);
  };

  useEffect(() => {
    setLoyaltyWidgetSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoyaltyWidgetSubscriptions;
