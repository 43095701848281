import moment from 'moment';

const format = 'DD-MM-YYYY';

function today() {
  return moment().startOf('day');
}

function dateIsPast(date) {
  const mDate = moment(date, format);
  return mDate.diff(today()) < 0;
}

export function departureDateCheck(date) {
  if (dateIsPast(date)) {
    return today().format(format);
  }
  return date;
}

export function returnDateCheck(date) {
  if (!date || date === '') {
    return null;
  }

  if (dateIsPast(date)) {
    return today().add(1, 'days').format(format);
  }
  return date;
}
