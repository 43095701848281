import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class TripSort extends Component {
  componentDidMount() {
    document.querySelector('.modal').classList.add('modal-view-options');
  }

  componentWillUnmount() {
    document.querySelector('.modal').classList.remove('modal-view-options');
  }

  select(selected) {
    const { hideModal, onSortSelect } = this.props;
    onSortSelect(selected, 'trips');
    hideModal();
    window.scrollTo(0, 0);
  }

  render() {
    const { sortProperty, floatingOptions, t } = this.props;
    const isChecked = (property) => property === sortProperty;

    return (
      <div className="modal-arrow-container sort" onClick={(e) => e.stopPropagation()}>
        <form className="modal-content floating-sort-menu">
          <div className="modal-row row-price" onClick={() => this.select('price')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('price')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_price')}</h4>
              <p>{t('label.description.selector_price')}</p>
            </div>
          </div>

          <div className="modal-row row-duration" onClick={() => this.select('duration')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('duration')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_duration')}</h4>
              <p>{t('label.description.selector_duration')}</p>
            </div>
          </div>

          <div className="modal-row row-departure" onClick={() => this.select('departure')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('departure')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_departure')}</h4>
              <p>{t('label.description.selector_departure')}</p>
            </div>
          </div>

          <div className="modal-row row-arrival" onClick={() => this.select('arrival')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('arrival')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_arrival')}</h4>
              <p>{t('label.description.selector_arrival')}</p>
            </div>
          </div>

          <div className="modal-row row-stops" onClick={() => this.select('stops')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="sort-check" defaultChecked={isChecked('stops')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_stops')}</h4>
              <p>{t('label.description.selector_stops')}</p>
            </div>
          </div>
        </form>

        <div className={`arrow-down options-${floatingOptions}`} />
      </div>
    );
  }
}

TripSort.propTypes = {
  sortProperty: PropTypes.string.isRequired,
  floatingOptions: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSortSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(TripSort);
