import React from 'react';
import PropTypes from 'prop-types';
import OptionsSelector from './OptionsSelector';
import useAvailableSchedulesProvidersOptions from '../hooks/useAvailableSchedulesProvidersOptions';

/**
 * Component for selecting a provider filter.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - The function to be called when the provider filter changes.
 * @param {string} props.value - The selected value of the provider filter.
 * @param {string} props.way - The direction of the trip.
 * @returns {JSX.Element} The rendered component.
 */
const ProviderFilterSelector = ({ onChange, value, way }) => {
  const options = useAvailableSchedulesProvidersOptions(way);

  return <OptionsSelector onChange={onChange} options={options} value={value} />;
};

ProviderFilterSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  way: PropTypes.string.isRequired,
};

ProviderFilterSelector.defaultProps = {
  value: null,
};

export default ProviderFilterSelector;
