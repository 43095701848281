import { getSessionId } from './session';

export default function loadRiskified(params) {
  const { enabled, storeDomain } = params || {};
  if (!enabled) return;

  function riskifiedBeaconLoad() {
    const sessionId = getSessionId();
    const url = `//beacon.riskified.com?shop=${storeDomain}&sid=${sessionId}`;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = false;
    s.defer = true;
    s.src = url;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  /**
   * This part of the code runs after the fetch of the tenants configs.
   * This means that most of the time, window's onload event has already happen by the time the listeners
   * in the following code are added.
   *
   * document.readyState === 'complete' means that the onload events where already triggered.
   * So we need to load the riskified script directly.
   */
  if (document.readyState === 'complete') {
    riskifiedBeaconLoad();
  } else if (window.attachEvent) {
    window.attachEvent('onload', riskifiedBeaconLoad);
  } else {
    window.addEventListener('load', riskifiedBeaconLoad, false);
  }
}
