import { session, setConfig } from 'js-api-client';
import * as types from '../constants/ActionTypes';

export function setSession({ agency, user }) {
  return {
    type: types.AGENCY_SET_SESSION,
    agency,
    user,
  };
}

export function setIsLoading(isLoading) {
  return {
    type: types.AGENCY_SET_LOADING,
    isLoading,
  };
}

export function fetchAgency(token) {
  return (dispatch) => {
    setConfig({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(setIsLoading(true));

    return session
      .agency()
      .then(({ agency, current_user: user }) => {
        dispatch(setIsLoading(false));
        dispatch(setSession({ agency, user }));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  };
}
