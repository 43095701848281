import { connect } from 'react-redux';
import Timer from './Timer';

const mapStateToProps = ({ purchase }, ownProps) => {
  const { variant: propVariant } = ownProps;
  const variant = purchase.get('isExchange') ? 'exchange' : propVariant;
  return {
    variant,
  };
};

export default connect(mapStateToProps)(Timer);
