import { connect } from 'react-redux';
import { Map } from 'immutable';
import { toggleModal } from '@/actions';
import moment from 'moment';
import { setRedirectParams } from '@/actions/search';
import { formatDuration } from 'utils/Helpers';
import { setProviderSortOrder } from '@/actions/providers';
import {
  setTripSortOrder,
  setTripFilter,
  setTripFilters,
  resetTripFilters,
} from '@/actions/tripFilters';
import { newPurchase, fetchTripsDetails } from '@/actions/purchase';
import findTrip from 'utils/trips/findTrip';
import parseQueryString from 'utils/queryString/parseQueryString';
import stringifyQueryString from 'utils/queryString/stringifyQueryString';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  tripsLocationByProvider,
  filterTrips,
  stopsFilter as stopsEvaluator,
} from 'utils/tripFilters';
import { getBaseTripDiscountPercent } from 'models/parseTrip';
import {
  getRecommendedTripsExcludeList,
  getRecommendedTripsListBySearch,
} from 'utils/userPreferences';
import { generateDepartureUrl } from 'utils/urls';
import { getTripTerminalInfo } from 'utils/trips/parseTripDetails';
import { passengersNotAvailable } from 'utils/passengers';
import { getFromSelectedSeats } from 'utils/seats';
import { isOldSearchPassengersFormat } from 'utils/Reserbus';
import ProviderReturns from './ProviderReturns';

const isSearchPolling = (trips) =>
  !trips || trips.valueSeq().some((transportType) => transportType.get('isPolling'));

/**
 * Retrieve open ticket from the trips list
 * @param {Object} trips - List of trips
 * @param {String} [providerId] - Provider id
 * @returns {Object} - Open ticket trip
 */
const retrieveOpenTrip = (trips, providerId) => {
  if (!trips || trips.size === 0) return null;
  const openTrips = trips
    .filter((t) => t.get('openTicket') && (!providerId || t.get('lineId') === providerId))
    .sort((a, b) => a.getIn(['pricing', 'total']) - b.getIn(['pricing', 'total']));

  return openTrips.size > 0 ? openTrips.first().toJS() : null;
};

const isStopsFilterAvailable = (trips) =>
  trips &&
  trips.some((t) => stopsEvaluator(t, 'direct')) &&
  trips.some((t) => stopsEvaluator(t, 'multiple'));

const mapStateToProps = (state, ownProps) => {
  const {
    config,
    providers,
    search,
    trips,
    cities,
    terminals,
    tripFilters,
    siemprePlus,
    whitelabelConfig: { features, env },
    userPreferences,
    seatsBus,
  } = state;
  const { match, location } = ownProps;
  const {
    departureDate,
    returnDate,
    departureId,
    passengers = 'A1',
    providerId: paramsProviderId,
    isOpen,
  } = match.params;
  const {
    seenPrice = 0,
    providerId: queryProviderId,
    returnIsOpenTicket = false,
  } = parseQueryString(location.search);
  const providerId = paramsProviderId || queryProviderId;
  const places = { ...cities, ...terminals };
  const searchId = search.getIn(['return', 'id']);
  const searchIsPolling = isSearchPolling(trips.get(searchId));
  const paymentPlansList = config.paymentPlans;
  const providerList = providers.getIn([searchId, 'list']);
  let provider = providerList && providerList.find((el) => el.get('id') === providerId);
  const dSearchId = search.getIn(['departure', 'id']);
  const dProviders = providers.getIn([dSearchId, 'list']);
  const departureProvider = dProviders && dProviders.find((el) => el.get('id') === providerId);
  const dTransportType = departureProvider && departureProvider.get('transportType');
  const sortBy = tripFilters.get('sortBy');
  const filters = tripFilters.get('filters');
  const departureTimeFilter = filters.get('departureTime');
  const dLocationFilter = filters.get('departureLocation');
  const aLocationFilter = filters.get('arrivalLocation');

  // Filters section
  const stopsFilter = filters.get('stops');
  const categoriesFilter = filters.get('categories');
  const tripOptionsFilter = filters.get('tripOptions');
  const activeDepartureFilter = Array.isArray(departureTimeFilter)
    ? !departureTimeFilter.includes('none')
    : departureTimeFilter !== 'none';

  const activeLocationFilter = dLocationFilter !== 'none' || aLocationFilter !== 'none';
  const activeCategoriesFilter = categoriesFilter.length;
  const activeTripOptionsFilter = tripOptionsFilter.length;

  const activeStopsFilter =
    typeof stopsFilter === 'string' ? stopsFilter !== 'none' : !stopsFilter.includes('none');
  const activeFilters =
    activeDepartureFilter ||
    activeLocationFilter ||
    activeStopsFilter ||
    activeCategoriesFilter ||
    activeTripOptionsFilter;

  const couponCode = search.get('couponCode');
  const discountedTripsByCoupon = search.getIn(['discountedTripsByCoupon', couponCode], []);

  const departureTrip = findTrip(trips.get(dSearchId), 'bus', departureId)?.toJS();

  const tripDateDeparture = departureTrip && moment(departureTrip.departure);
  const tripDepartureDuration = departureTrip && formatDuration(departureTrip.duration);
  const tripDate = tripDateDeparture && tripDateDeparture.format('ddd DD MMMM');
  const tripTime = tripDateDeparture && tripDateDeparture.format('LT');

  const originCity = departureTrip?.origin?.cityName;
  const destinationCity = departureTrip?.destination?.cityName;

  let transportType = provider && provider.get('transportType');

  let appliedFilters = filters;
  let filteredTrips;
  let tripList;
  let tripsWithoutFilters;
  let tripsWithoutFiltersAfterTime;
  let redirectToBrand;
  const needsAfterTimeFilter = departureTrip && !departureTrip.openTicket;
  const needsSameOriginFilter = departureTrip && features.REDIRECT_SAME_SITE_ONLY;
  const departureAfterTime = departureTrip && departureTrip.arrival;

  if (needsAfterTimeFilter) {
    appliedFilters = filters.merge(Map({ departureAfterTime }));
  }

  if (!provider && departureProvider) {
    transportType = dTransportType;
  }

  const searchPassengers = search.get('passengers').toJS();
  if (needsSameOriginFilter) {
    // Exclude trips from different brand if departure has redirectTo
    // Will compare departure brand from redirectTo which can be either undefined or a string
    redirectToBrand = departureTrip?.redirectTo?.brand || true;
    appliedFilters = appliedFilters.merge(Map({ sameOrigin: redirectToBrand }));
  }

  if (features.PROVIDERS_SELECTION_ENABLED) {
    tripList = trips.getIn([searchId, 'buses', 'trips']);
    filteredTrips = filterTrips(
      tripList,
      providerId,
      sortBy,
      appliedFilters,
      null,
      searchPassengers,
    );
    const filteredTripsId = filteredTrips.toJS().map((trip) => trip.id);
    tripsWithoutFilters = tripList?.filter(
      (trip) => !filteredTripsId.includes(trip.get('id')) && trip.get('lineId') === providerId,
    );
  } else {
    if (trips.getIn([searchId, 'buses', 'trips'])) {
      tripList = trips.getIn([searchId, 'buses', 'trips']).map((item) => item.set('type', 'bus'));
    }
    filteredTrips = filterTrips(
      tripList,
      providerId,
      sortBy,
      appliedFilters,
      null,
      searchPassengers,
    );
    filteredTrips = filterTrips(tripList, null, sortBy, appliedFilters, null, searchPassengers);
    provider = Map({ name: null });
  }

  const filteredTripsId = filteredTrips.toJS().map((trip) => trip.id);
  tripsWithoutFilters = tripList?.filter((trip) => !filteredTripsId.includes(trip.get('id')));

  tripsWithoutFiltersAfterTime = tripsWithoutFilters || [];
  tripsWithoutFiltersAfterTime = filterTrips(
    tripsWithoutFilters,
    providerId || null,
    sortBy,
    Map({
      ...(needsAfterTimeFilter && { departureAfterTime }),
      ...(needsSameOriginFilter && { sameOrigin: redirectToBrand }),
    }),
  );

  let filteredTripsJS = filteredTrips.toJS();
  const openTrip = retrieveOpenTrip(filteredTrips, providerId);
  const noTrips = !filteredTrips.size || (filteredTrips.size === 1 && Boolean(openTrip));

  const busTrips = trips.getIn([searchId, 'buses', 'trips']);
  const areOpenTicketTrips = busTrips?.filter((t) => t.get('openTicket')).toJS();

  const tripsForLineInfo = tripList?.size && tripList.toJS()[0];
  const discountPercent = getBaseTripDiscountPercent({
    trip: tripsForLineInfo,
    way: 'return',
    isRoundTrip: true,
  });

  // Passengers searched
  /**
   * If some passenger searched is not found in the trips, this passengers is saved to notify
   * that the passengers was not found in this route
   */
  const isOldPassengersFormat = isOldSearchPassengersFormat(passengers);
  const passengersNotFound =
    (!isOldPassengersFormat &&
      features.VALID_CATEGORIES?.length > 1 &&
      passengersNotAvailable({
        neededPassengers: searchPassengers,
        trips: filteredTripsJS,
      })) ||
    [];

  const { recommendedTrips } = userPreferences.toJS();
  const recommendedTripsForSearch = getRecommendedTripsListBySearch({
    searchId,
    recommendedTrips,
    providerId,
  });

  if (Object.keys(recommendedTripsForSearch).length) {
    filteredTripsJS = getRecommendedTripsExcludeList({
      recommendedTripsList: recommendedTripsForSearch,
      tripList: filteredTripsJS,
    });
  }

  const originId = search.get('originId');
  const destinationId = search.get('destinationId');
  const originTerminal = terminals[originId];
  const destinationTerminal = terminals[destinationId];

  // Get terminal info but swap origin and destination terminals for the return trip
  const tripTerminalInfo = getTripTerminalInfo({
    originTerminal: destinationTerminal,
    destinationTerminal: originTerminal,
  });

  // Validating if departure seats are saved, this is used for the results seats selection
  const hasValidDepartureSeats = departureTrip?.openTicket
    ? true
    : getFromSelectedSeats({
        seatsState: seatsBus.toJS(),
        fromId: departureId,
      });

  return {
    activeDepartureFilter,
    activeLocationFilter,
    activeStopsFilter,
    stopsFilter,
    categoriesFilter,
    tripOptionsFilter,
    departureDate,
    departureId,
    noTrips,
    passengers,
    returnDate,
    searchIsPolling,
    seenPrice,
    sortBy,
    transportType,
    openTrip,
    arrivalLocationFilter: aLocationFilter,
    currentPath: location.pathname,
    departureFilter: departureTimeFilter,
    departureLocationFilter: dLocationFilter,
    departureTrip: departureTrip || {},
    destination: places[search.getIn(['return', 'destinationId'])],
    hasMultipleProviders:
      features.PROVIDERS_SELECTION_ENABLED && search.get('hasMultipleProviders'),
    paymentPlans: paymentPlansList || [],
    installmentsMinAmount: config.installmentsMinAmount,
    locations: tripsLocationByProvider(tripList, providerId),
    origin: places[search.getIn(['return', 'originId'])],
    provider: provider && provider.toJS(),
    providers: providerList ? providerList.toJS() : [],
    roundTrip: !!returnDate,
    sortProvidersBy: providers.get('sortBy'),
    tooManyFilters: !searchIsPolling && noTrips && activeFilters,
    trips: filteredTripsJS,
    isStopsFilterAvailable: isStopsFilterAvailable(tripList),
    couponCode,
    discountedTripsByCoupon,
    isOpenTicketList: isOpen,
    isLogged: Boolean(siemprePlus.get('user')),
    providerId,
    areOpenTicketTrips,
    returnIsOpenTicket,
    tripDepartureDuration,
    tripDate,
    tripTime,
    originCity,
    destinationCity,
    filters: filters.toJS(),
    tripsWithoutFilters: tripsWithoutFiltersAfterTime.toJS(),
    searchOriginId: search.get('originId'),
    searchDestinationId: search.get('destinationId'),
    discountPercent,
    resultPriceToShow: search.get('resultPriceToShow'),
    firstTripLineId: tripsForLineInfo?.lineId,
    firstTripLineLogo: tripsForLineInfo?.line?.logoUrl,
    brand: env.brand,
    passengersNotFound,
    tripTerminalInfo,
    passengersQueryParam: passengers,
    hasValidDepartureSeats,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showModal: (component, extraProps) => dispatch(toggleModal('visible', component, extraProps)),
  setSortOrder: (sortBy, list) =>
    list === 'providers'
      ? dispatch(setProviderSortOrder(sortBy))
      : dispatch(setTripSortOrder(sortBy)),
  setTripFilter: (filterBy, active) => dispatch(setTripFilter(filterBy, active)),
  setTripFilters: (filter) => dispatch(setTripFilters(filter)),
  resetTripFilters: () => dispatch(resetTripFilters()),
  transitionTo: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);
    ownProps.history.push(`${route}${queryString}`);
  },
  replaceUrl: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);
    ownProps.history.replace(`${route}${queryString}`);
  },
  setRedirectParams: (way, params, brand, redirect, roundTrip, waitForPurchase) =>
    dispatch(setRedirectParams(way, params, brand, redirect, roundTrip, waitForPurchase)),

  redirectToDeparture: (hasProviderSelection) => {
    const { originSlug, destinationSlug, returnDate, departureDate, passengers } =
      ownProps.match.params;
    ownProps.history.push(
      generateDepartureUrl({
        originSlug,
        destinationSlug,
        departureDate,
        returnDate,
        passengers,
        useProviderSelection: hasProviderSelection,
      }),
    );
  },
  fetchTripsDetails: (trip) => dispatch(fetchTripsDetails(trip)),
  newPurchase: (originSlug, returnSlug, seenPrice) =>
    dispatch(newPurchase(originSlug, returnSlug, 'A1', seenPrice, null, false, {}, true, null, {})),
});

export default compose(
  withTranslation('trips'),
  connect(mapStateToProps, mapDispatchToProps),
)(ProviderReturns);
