import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import './Loader.scss';

const Loader = ({ loadingText }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();

  const DOTS_LOADING = features.LOADING_TYPE === 'DOTS';

  return (
    <div className="loader">
      <Spacing vertical textAlign="center">
        {DOTS_LOADING ? (
          <div className="spinner">
            <div className="bounce-one" />
            <div className="bounce-two" />
            <div className="" />
          </div>
        ) : (
          <div className="loader-icons">
            <div className="loader-circle" />
            <div className="loader-search">
              <i className="loader-image" />
              <i className="loader-image-second" />
            </div>
          </div>
        )}
        <Text size="S" color="grayMedium" italic>
          {t(loadingText)}
          <span className="loader-elipsis">.</span>
          <span className="loader-elipsis">.</span>
          <span className="loader-elipsis">.</span>
        </Text>
      </Spacing>
    </div>
  );
};

Loader.propTypes = {
  loadingText: PropTypes.string,
};

Loader.defaultProps = {
  loadingText: 'general:loading_messages.fourth',
};

export default Loader;
