/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import { formValueSelector, change as changeFormField } from 'redux-form';
import parsePurchaser from 'utils/purchase/parsePurchaser';
import { updatePurchaserData } from '@/actions/siemprePlus';
import {
  COSTAPASS_UPDATE_BALANCE,
  UPDATE_PURCHASE,
  SET_LOYALTY_PROGRAM,
} from '../constants/ActionTypes';
// eslint-disable-next-line import/no-cycle
import { getPurchase, updateWalletType } from './purchase';
import {
  fetchProfile,
  getUserByWalletType,
  getWalletTypeForPurchase,
  setUserTokenForAPI,
  walletTypeNeedsUserInPurchaser,
} from '../utils/loyalty';
import parsePassenger from '../utils/purchase/parsePassenger';

/**
 * Action to update the balance of the costapass wallet
 * @param {*} balance - Balance quantity to update
 * @returns Object
 */
function updateCostapassBalance(balance) {
  return {
    type: COSTAPASS_UPDATE_BALANCE,
    balance,
  };
}

export function setLoyaltyProgram(loyaltyProgramSelected) {
  return {
    type: SET_LOYALTY_PROGRAM,
    loyaltyProgramSelected,
  };
}

/**
 * Updates the wallet money of the user
 * @param {*} wallet - Wallet type to update
 * @param {*} token - Token of the user
 * @param {*} purchaseToken - Purchase token to update
 * @returns Dispatch function
 */
export function updateWalletMoney(wallet, token, purchaseToken) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PURCHASE, updating: true });
    fetchProfile(wallet, token)
      .then((profile) => {
        const { balance } = profile;
        dispatch(updateCostapassBalance(balance));
        dispatch(getPurchase(purchaseToken));
      })
      .finally(() => {
        dispatch({ type: UPDATE_PURCHASE, updating: false });
      });
  };
}

// Loyalty actions
/**
 * Replace the first passenger in the Passengers form for the one is passed as argument.
 * @param {Object} passenger - Passenger data to be set instead of the current first passenger
 */
export function changeFirstPassenger(passenger) {
  return (dispatch, getState) => {
    const state = getState();
    const passengers = formValueSelector('passengers')(state, 'passengers');
    if (passengers) {
      const firstPassenger = passengers[0];
      const parsedPassenger = parsePassenger(passenger, firstPassenger);
      dispatch(
        changeFormField('passengers', 'passengers[0]', {
          ...parsedPassenger,
          busCategory: 'general',
          category: 'adult',
        }),
      );
    }
  };
}

/**
 * Set a loyalty program in the config state of the application, with this it also updates
 * the first passenger in the passengers form with the user data passed as argument.
 * It can update the wallet_type in the purchase if it is indicated.
 * @param {String} loyaltyProgram - Loyalty program to be set
 * @param {Object} user - User data to be placed in the first passenger
 * @param {Boolean} updatePurchase - Indicates if the purchase needs to be updated in the backend too
 * @param {Boolean} needsUnlockSeats - Indicates if the seats needs to be unlocked
 */
export function handleLoyaltySelection({ loyaltyProgram, user, updatePurchase, needsUnlockSeats }) {
  return (dispatch) => {
    const { purchase: purchaseData, isExchange } = store.getState();
    const { token: purchaseToken, wantsFlatFare } = purchaseData.toJS();

    // If a loyalty program is selected and it is not an exchange, the first passenger data is updated
    const newPassenger = { ...user };
    if (loyaltyProgram && !isExchange) {
      setUserTokenForAPI();
      dispatch(changeFirstPassenger(newPassenger));
      if (walletTypeNeedsUserInPurchaser(loyaltyProgram))
        updatePurchaserData(parsePurchaser(newPassenger));
    }

    // The loyalty program is set up locally in the config state
    dispatch(setLoyaltyProgram(loyaltyProgram));

    // If the updatePurchase flag is true, the purchase wallet type is updated in the backend
    if (updatePurchase) {
      dispatch(
        updateWalletType({
          purchaseToken,
          walletType: loyaltyProgram,
          needsUnlockSeats,
          needsDisableFlatFare: wantsFlatFare && loyaltyProgram !== 'doters',
        }),
      );
    }
  };
}

/**
 * Sets a initial wallet based on the one is passed
 * @param {Array} availableWallets - available wallets to use in the purchase
 */
export function setUpPurchaseLoyaltyProgram({ availableWallets }) {
  return (dispatch) => {
    const { purchase: purchaseData } = store.getState();
    const { walletType, hasTickets } = purchaseData.toJS();
    const walletTypeToUse = getWalletTypeForPurchase();
    const userToUse = walletTypeToUse && getUserByWalletType(walletTypeToUse);

    // If the walletType is the same as the one is in the purchase, it is not necessary to update the purchase
    const walletTypeIsDifferent = walletType !== walletTypeToUse;
    setUserTokenForAPI();

    if (!walletTypeIsDifferent) return;

    const needsUnlockSeats = walletTypeIsDifferent && hasTickets;

    // It is check if the walletType is supported by the purchase
    const isValidWalletType = walletTypeToUse && availableWallets?.includes(walletTypeToUse);

    dispatch(
      handleLoyaltySelection({
        loyaltyProgram: isValidWalletType ? walletTypeToUse : null,
        user: userToUse,
        updatePurchase: walletTypeIsDifferent,
        needsUnlockSeats,
      }),
    );
  };
}
