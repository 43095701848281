export default (queryString) => {
  // Create an object with no prototype
  const emptyObj = Object.create(null);

  if (typeof queryString !== 'string') return emptyObj;

  // Removes any leading ?, #, &
  const trimmedString = queryString.trim().replace(/^[?#&]/, '');

  if (trimmedString.length < 1) return emptyObj;

  return trimmedString.split('&').reduce((paramsObj, paramString) => {
    const [key, value] = paramString.split('=');

    paramsObj[key] = value;
    return paramsObj;
  }, emptyObj);
};
