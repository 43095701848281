import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import WomanIcon from '../images/brands-unique/gfa/badge-woman.svg';
import filtersTypes from '../constants/filtersTypes';

const { CATEGORIES } = filtersTypes;

const categoriesIconTypes = {
  general: 'emojiAdult',
  older: 'emojiAdult',
  student: 'emojiStudent',
  wheelchair_handicap: 'emojiHandshake',
  special: 'emojiHandshake',
  teacher: 'emojiTeacher',
  minor: 'emojiBoy',
};

const categoriesI18Keys = {
  general: 'passengers:type.general',
  older: 'passengers:type.older',
  student: 'passengers:type.student',
  wheelchair_handicap: 'passengers:type.wheelchair_handicap',
  special: 'passengers:type.special',
  teacher: 'passengers:type.teacher',
  minor: 'passengers:type.minor',
  pet_friendly: 'passengers:type.pet_friendly',
  support_woman: 'passengers:type.support_woman',
};

/**
 * Hook to get the available categories.
 * @param {String} way - Way of the trips to filter
 * @param {*} includeWomanSupport - Indicates if should return woman support as category
 * @returns Array
 */
const useAvailableCategories = (way, includeWomanSupport) => {
  const { t } = useTranslation('');
  const state = useSelector((state) => state);
  const {
    search,
    trips,
    whitelabelConfig: { features },
  } = state;
  const searchId = search.getIn([way, 'id']);
  const tripsList = trips.getIn([searchId, 'buses', 'trips']);
  const tripListFiltered =
    trips
      ?.getIn([searchId, 'buses', 'trips'])
      ?.filter((t) => !t.get('openTicket'))
      ?.toJS() || [];

  if (!tripsList || !tripListFiltered.length) return [];

  const categories = tripListFiltered[0].passengerTypes.map((category) => {
    const canUse =
      category.type === 'pet_friendly'
        ? features.SPECIAL_CATEGORIES.includes('pet_friendly')
        : true;

    const hasAvailability = Boolean(
      tripListFiltered.find(
        (trip) => trip.passengerTypes.find((c) => c.type === category.type)?.availability > 0,
      ),
    );

    return {
      ...category,
      icon: categoriesIconTypes[category.type],
      i18Key: categoriesI18Keys[category.type],
      label: t(`passengers:type.${category.type}`),
      hasAvailability,
      canUse,
      value: category.type,
      filterType: CATEGORIES,
    };
  });

  let womanSupport = {};
  if (includeWomanSupport) {
    womanSupport = {
      type: 'supportWoman',
      i18Key: categoriesI18Keys.support_woman,
      label: t(`seats:seat_woman`),
      icon: WomanIcon,
      hasAvailability: Boolean(tripListFiltered.find((trip) => trip.supportWoman)),
      canUse: features.WOMAN_SEATS_ENABLED,
      value: 'supportWoman',
      filterType: CATEGORIES,
    };
    categories.push(womanSupport);
  }

  return categories;
};

export default useAvailableCategories;
