import * as types from 'constants/ActionTypes';
import { camelizeKeys } from 'humps';
import { Map } from 'immutable';

const defaultState = Map({
  recommendedTrips: {
    isLoading: false,
    requested: false,
  },
});

/**
 * Reducer for user preferences.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} The updated state.
 */
export default function userPreferences(state = defaultState, action) {
  switch (action.type) {
    case types.SET_RECOMMENDED_TRIPS: {
      // If there are already two recommended trips, remove the oldest one because it's a different search
      return state.set('recommendedTrips', {
        ...state.get('recommendedTrips'),
        [action.searchId]: { ...camelizeKeys(action.trips) },
        isLoading: false,
        requested: true,
      });
    }
    case types.SET_RECOMMENDED_TRIPS_LOADING:
      return state.set('recommendedTrips', {
        ...state.get('recommendedTrips'),
        isLoading: action.isLoading,
        requested: !action.isLoading,
      });
    default:
      return state;
  }
}
