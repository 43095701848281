import moment from 'moment';

/**
 * Calculates the number of days between two dates.
 * @param {string|moment.Moment} date1 - The first date.
 * @param {string|moment.Moment} date2 - The second date.
 * @returns {number} - The number of days between the two dates.
 */
export const separationDays = (date1, date2) => {
  const date1moment = moment.isMoment(date1) ? date1 : moment(date1, 'DD-MM-YYYY');
  const date2moment = moment.isMoment(date2) ? date2 : moment(date2, 'DD-MM-YYYY');

  return date1moment.startOf('day').diff(date2moment.startOf('day'), 'days');
};

/**
 * Gets the pricing property based on the way and trip type.
 * @param {string} way - The way of the trip (e.g., 'departure').
 * @param {boolean} isRoundTrip - Indicates if the trip is round trip.
 * @returns {string} - The pricing property.
 */
const getPricingProp = (way, isRoundTrip) => {
  let pricingProp = 'pricing';

  if (isRoundTrip) {
    pricingProp = way === 'departure' ? 'departureRoundTripPricing' : 'roundTripPricing';
  }

  return pricingProp;
};

/**
 * Gets the current price based on the provided prices, way, and trip type.
 * @param {Object} prices - The prices object.
 * @param {string} way - The way of the trip (e.g., 'departure').
 * @param {boolean} isRoundTrip - Indicates if the trip is round trip.
 * @returns {number} - The current price.
 */
export const getCurrentPrice = (prices, way, isRoundTrip) => {
  const minPrice = 0;
  if (!prices) return minPrice;

  const pricingProp = getPricingProp(way, isRoundTrip);
  if (!prices[pricingProp]) return minPrice;

  return prices[pricingProp];
};

/**
 * Validates departure dates and prices.
 * @param {boolean} isRoundTrip - Indicates if the trip is round trip.
 * @param {string|moment.Moment} prevDayDate - The previous day's date.
 * @param {string|moment.Moment} nextDayDate - The next day's date.
 * @param {number} prevDayPrice - The previous day's price.
 * @param {number} nextDayPrice - The next day's price.
 * @param {string|moment.Moment} returnDate - The return date.
 * @returns {Object} - An object with validation results for previous and next days.
 */
const departureValidations = (
  isRoundTrip,
  prevDayDate,
  nextDayDate,
  prevDayPrice,
  nextDayPrice,
  returnDate,
) => {
  let prevDay = false;
  let nextDay = false;

  const today = moment();

  if (isRoundTrip) {
    if (separationDays(returnDate, nextDayDate) < 0) nextDay = true;
  }

  if (separationDays(prevDayDate, today) < 0) prevDay = true;

  return { prevDay, nextDay };
};

/**
 * Validates return dates and prices.
 * @param {boolean} isRoundTrip - Indicates if the trip is round trip.
 * @param {string|moment.Moment} prevDayDate - The previous day's date.
 * @param {number} prevDayPrice - The previous day's price.
 * @param {number} nextDayPrice - The next day's price.
 * @param {string|moment.Moment} departureDate - The departure date.
 * @returns {Object} - An object with validation results for previous and next days.
 */
const returnValidations = (isRoundTrip, prevDayDate, prevDayPrice, nextDayPrice, departureDate) => {
  let prevDay = false;
  const nextDay = false;

  const today = moment();

  if (isRoundTrip) {
    if (separationDays(prevDayDate, departureDate) < 0) prevDay = true;
  }

  if (separationDays(prevDayDate, today) < 0) prevDay = true;

  return { prevDay, nextDay };
};

/**
 * Determines which buttons should be disabled based on trip details.
 * @param {string} way - The way of the trip (e.g., 'departure').
 * @param {boolean} isRoundTrip - Indicates if the trip is round trip.
 * @param {string|moment.Moment} prevDayDate - The previous day's date.
 * @param {string|moment.Moment} nextDayDate - The next day's date.
 * @param {number} prevDayPrice - The previous day's price.
 * @param {number} nextDayPrice - The next day's price.
 * @param {string|moment.Moment} departureDate - The departure date.
 * @param {string|moment.Moment} returnDate - The return date.
 * @returns {Object} - An object with validation results for previous and next days.
 */
export const getDisabledButtons = (
  way,
  isRoundTrip,
  prevDayDate,
  nextDayDate,
  prevDayPrice,
  nextDayPrice,
  departureDate,
  returnDate,
) => {
  return way === 'departure'
    ? departureValidations(
        isRoundTrip,
        prevDayDate,
        nextDayDate,
        prevDayPrice,
        nextDayPrice,
        returnDate,
      )
    : returnValidations(isRoundTrip, prevDayDate, prevDayPrice, nextDayPrice, departureDate);
};
