import { useSelector } from 'react-redux';
import { getRecommendedTripsListBySearch, isRecommendedTripsEnabled } from 'utils/userPreferences';

/**
 * Custom hook for handling recommended trips.
 *
 * @param {Object} options - The options for the hook.
 * @param {string} options.providerId - The provider ID.
 * @param {string} options.way - The way.
 * @param {string} options.departureTripArrival - The arrival date of the trip.
 * @returns {Object} - The recommended trips.
 */
const useRecommendedTrips = ({ providerId, way, departureTripArrival }) => {
  const defaultData = {
    isLoading: false,
    found: false,
    trips: [],
  };

  // state data needed
  const departureSearch = useSelector((state) => state.search?.toJS()?.departure);
  const returnSearch = useSelector((state) => state.search?.toJS()?.return);
  const recommendedTripsState = useSelector(
    (state) => state.userPreferences.toJS().recommendedTrips,
  );
  // AB test: recommended trips
  const recommendedTripsEnabled = isRecommendedTripsEnabled();

  if (!recommendedTripsEnabled) return defaultData;

  // The search id of the way required is gotten from the search state to get the recommended trips for it.
  const wayId = way === 'departure' ? departureSearch?.id : returnSearch?.id;

  // Get the recommended trips list by search id and provider id.
  const recommendedTripsList = getRecommendedTripsListBySearch({
    recommendedTrips: recommendedTripsState,
    providerId,
    searchId: wayId,
    departureTripArrival,
  });

  return {
    isLoading: recommendedTripsState.isLoading,
    requested: recommendedTripsState.requested,
    found: false,
    trips: recommendedTripsList,
  };
};

export default useRecommendedTrips;
