import store from '@/store';

export default function normalizeName(input) {
  const { features } = store.getState().whitelabelConfig;
  const normalize = !features.SUPPORT_FOR_ORTHOGRAPHIC_ACCENTS_ON_PASSENGERS;
  if (normalize) {
    return input
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z\s]+/g, '');
  }

  return input
    .replace(/[^a-zA-Z\s´ÁÉÍÓÚáéíóúñÑ˜]+/g, '')
    .replace(/(´.{1,1})+/g, '')
    .replace(/(˜.{1,1})+/g, '');
}
