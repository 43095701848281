// @ts-check

/**
 * @typedef {import('./CardStrategy').CardStrategy} CardStrategy
 */

/**
 * Adyen card strategy.
 * @function AdyenCardStrategy
 * @returns {CardStrategy}
 */
const AdyenStrategy = () => {
  /**
   * Create a payload for Adyen.
   */
  const createPayload = async (cardInfo, { adyenToken, reference, browserInfo }) => {
    let payload = adyenToken
      ? {
          credit_card_tokens: {
            adyen_token: adyenToken,
          },
        }
      : { recurring_payment_reference: reference };

    payload = {
      ...payload,
      card_holder_name: cardInfo.holderName,
      card_number: cardInfo.cardNumber,
      card_brand: cardInfo.cardBrand,
      browser_info: browserInfo,
    };

    return payload;
  };

  return { createPayload };
};

const adyenStrategy = AdyenStrategy();

export default adyenStrategy;
