import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const AnimationOverview = ({ contentTypes }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const totalIndex = currentIndex < contentTypes.length - 1;

    const intoAnimationTimeout = setTimeout(() => {
      setShowComponent(true);

      if (totalIndex) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }

      setShowComponent(false);
    }, 5800);

    const outAnimationTimeout = setTimeout(() => {
      setShowComponent(true);
    }, 5000);

    return () => {
      clearTimeout(intoAnimationTimeout);
      clearTimeout(outAnimationTimeout);
    };
  }, [currentIndex, contentTypes.length]);

  return (
    <div className={showComponent ? 'fade-up' : 'fade-down'}>{contentTypes[currentIndex]}</div>
  );
};

AnimationOverview.propTypes = {
  contentTypes: PropTypes.node.isRequired,
};

export default AnimationOverview;
