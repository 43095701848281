import store from '@/store';
import getSearchAnalyticsData from 'utils/search/getSearchAnalyticsData';
import hasRedirectTrips from 'utils/trips/hasRedirectTrips';
import { serialize, serializeTransportData, serializeSearchDetails } from './search';

export function analyticsData() {
  const { search, trips, cities, terminals } = store.getState();

  const departureId = search.getIn(['departure', 'id']);
  const departures = trips.get(departureId);
  const returnId = search.getIn(['return', 'id']);
  const returns = trips.get(returnId);
  const places = { ...cities, ...terminals };

  return getSearchAnalyticsData({
    search,
    places,
    departures,
    returns,
    lodgingProvider: 'none',
  });
}

export function viewResultsData() {
  const { search, departureData, returnData } = analyticsData();
  const {
    trips,
    whitelabelConfig: { features },
  } = store.getState();

  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location.search);

  const lastAction = queryParams.get('action');
  const nearbyTerminal = queryParams.get('nearby');

  const redirectTrips = hasRedirectTrips(trips);

  return {
    ...serialize(search),
    ...serializeTransportData(departureData, returnData),
    ...serializeSearchDetails(departureData, returnData),
    ...(features.TRIP_REDIRECTION_LEAD && { 'Has Redirect Buses': redirectTrips }),
    ...(features.SHOW_MIN_PRICES && { 'Day Navigation Action': lastAction }),
    ...(features.USE_NERBY_TERMINALS && { 'Nearby Terminal': nearbyTerminal }),
  };
}
