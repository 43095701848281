import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Visibility } from '@reservamos/elements';
import { toggleMenu } from '@/actions';
import PropTypes from 'prop-types';
import AdaptiveLanguageSelector from 'ui/atoms/AdaptiveLanguageSelector';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import AgencyHeader from './AgencyHeader';
import BrandLogo from './BrandLogo';
import MenuButton from '../ui/atoms/MenuButton';
import LoyaltyButton from './LoyaltyButton';
import useLoyaltyPrograms from '../loyalty/context/useLoyaltyPrograms';
import useWhitelabelTheme from '../hooks/whitelabel/useWhitelabelTheme';

const DesktopHeader = ({ hideLoyalty }) => {
  const features = useWhitelabelFeatures();
  const env = useWhitelabelEnvs();
  const { provider } = useWhitelabelCopies();
  const { colors } = useWhitelabelTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation('general');
  const {
    costaPass: { config: costaPassConfig },
  } = useLoyaltyPrograms();

  const onMenuButtonClick = () => dispatch(toggleMenu());

  return (
    <div className="topbar-desktop">
      <div className="topbar-desktop-l">
        <BrandLogo />
        {features.SHOW_HEADER_LABEL && (
          <Visibility type="hideForMedium">
            <Text color={colors.headerText || 'white'} size="S">
              <em>{t('header', { context: env.brand, brand: provider.name })}</em>
            </Text>
          </Visibility>
        )}
      </div>

      <div className="topbar-desktop-r">
        <AgencyHeader />
      </div>

      <Spacing alignItems="center">
        {!hideLoyalty && features.DOTERS_ENABLED && (
          <LoyaltyButton id="siempre-plus-widget-desktop-header" isLink />
        )}
        {!hideLoyalty && features.COSTAPASS_ENABLED && (
          <LoyaltyButton
            id={`siempre-plus-widget-desktop-header-${costaPassConfig.prefix}`}
            isLink
            extraConfig={costaPassConfig}
          />
        )}
        <AdaptiveLanguageSelector />
        {features.SHOW_SIDEBAR_MENU && <MenuButton onClick={onMenuButtonClick} />}
      </Spacing>
    </div>
  );
};

DesktopHeader.propTypes = {
  hideLoyalty: PropTypes.bool,
};

export default DesktopHeader;
