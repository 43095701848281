import React from 'react';
import { Button, Gap, Icon, Link, Modal, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import petBadge from 'images/badges/pet.svg';
import petBadgeDisabled from 'images/badges/pet-disabled.svg';
import PetInfoSeats from '../../images/icons/pet-info-seats.svg';
import InfoText from './InfoText';

/**
 * It shows the dialog for pet friendly on the results page.
 * This dialog has information about how to use pet friendly.
 * @param {*} props
 * @param {boolean} props.hideDialog - Should show or hide the dialog
 * @param {function} props.onPress - Callback when the dialog is closed
 * @returns - PetsDialog component.
 */
const PetsDialog = ({ hideDialog, onPress }) => {
  const { t } = useTranslation('seats');

  /**
   * Handle close dialog
   */
  const handleClose = () => {
    if (onPress && typeof onPress === 'function') {
      onPress();
    }
  };

  if (hideDialog) return null;

  return (
    <Modal
      iconType={petBadge}
      title={t('pet_dialog.title')}
      showCloseButton={false}
      defaultOpened
      closeOnOverlayClick={false}
      footer={
        <Spacing vertical textAlign="center">
          <Button text={t('pet_dialog.cta')} variant="accent" onClick={handleClose} />
          <Link
            size="M"
            text={t('pet_dialog.cta_info')}
            href="https://etn.com.mx/mascotas-abordo.html"
            type="accent"
            newTab
          />
        </Spacing>
      }
    >
      <Spacing vertical size="L" responsiveSize="M">
        <Text mobileSize="S">{t('pet_dialog.message')}</Text>
        <Gap columnGap="L">
          <Spacing size="XS" alignItems="center">
            <Icon type={petBadge} size="S" />
            <Text size="S" mobileSize="XS">
              {t('pet_dialog.available')}
            </Text>
          </Spacing>
          <Spacing size="XS" alignItems="center">
            <Icon type={petBadgeDisabled} size="S" />
            <Text size="S" mobileSize="XS">
              {t('pet_dialog.occupied')}
            </Text>
          </Spacing>
        </Gap>

        <InfoText>
          <Text mobileSize="S">
            {t('pet_dialog.disclaimer_one')}
            <Icon type="emojiPawsPet" size="S" />
            <Icon type="emojiPerson" size="S" />
          </Text>
        </InfoText>

        <Spacing flexGrow>
          <img src={PetInfoSeats} alt={t('pet_dialog.seats_for_pet_and_me')} height="130px" />
        </Spacing>

        <Spacing vertical size="XS">
          <InfoText>
            <Text elementType="span" mobileSize="S">
              {`${t('pet_dialog.disclaimer_two')} `}
            </Text>
            <Icon type={petBadge} size="S" hasMargin marginSide="right" margin="1" />
            <Text elementType="span" mobileSize="S">
              {` ${t('pet_dialog.disclaimer_continue')}`}
            </Text>
          </InfoText>
          <InfoText>
            <Text mobileSize="S">{t('pet_dialog.disclaimer_passengers')}</Text>
          </InfoText>
        </Spacing>
      </Spacing>
    </Modal>
  );
};

PetsDialog.propTypes = {
  hideDialog: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default PetsDialog;
