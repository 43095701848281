import { Spacing } from '@reservamos/elements';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckList from '../../ui/atoms/CheckList';
import PaymentImage from '../../ui/atoms/PaymentImage';

const CoppelPayForm = () => {
  const { t } = useTranslation('purchase');

  const labels = [
    t('label.pay_in_coppel_pay'),
    t('text.you_will_be_redirected_to_coppel'),
    t('text.fill_purchase_in_coppel_and_finalize'),
  ];

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <PaymentImage type="coppelpay" />
        <CheckList title={t('label.pay_with_coppelpay')} labels={labels} />
      </Spacing>
    </div>
  );
};

export default CoppelPayForm;
