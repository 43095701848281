import React, { useState } from 'react';
import { ToggleSwitch, Icon, Text, Visibility } from '@reservamos/elements';
import iconDoters from 'images/doters/logo.svg';
import './SwitchDoters.scss';
import { setResultPriceToShow } from '@/actions/search';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { trackEvent } from 'user-analytics';
import { WALLET_PRICE_SWITCHED } from 'constants/TrackEvents';

/**
 * Renders the SwitchDoters component.
 */
const SwitchDoters = () => {
  const { DOTERS_ENABLED, CAN_SWITCH_WALLET_PRICE } = useWhitelabelFeatures();
  const { resultPriceToShow } = useSelector((state) => state.search?.toJS() || {});
  const [checked, setChecked] = useState(resultPriceToShow.priceType === 'wallet');
  const { t } = useTranslation('trips');
  const dispatch = useDispatch();

  if (!DOTERS_ENABLED || !CAN_SWITCH_WALLET_PRICE) return null;

  /**
   * Handles the change event of the toggle switch.
   * @param {Object} e - The event object.
   */
  const onChange = (e) => {
    const { checked } = e.target;
    trackEvent(WALLET_PRICE_SWITCHED, { isOn: checked, walletType: 'doters' });
    setChecked(checked);
    dispatch(
      setResultPriceToShow({
        priceType: checked ? 'wallet' : 'normal',
        walletType: 'doters',
      }),
    );
  };
  return (
    <div className="switch-doters">
      <Visibility type="hideForMedium">
        <Text mobileSize="S" size="S" color="grayStrong">
          {t('label.see_price_in')}
        </Text>
      </Visibility>
      <Icon type={iconDoters} size="XL" />
      <ToggleSwitch
        checked={checked}
        id="Input-Doters"
        size="S"
        color="success"
        fillBackground
        onChange={onChange}
      />
    </div>
  );
};

export default SwitchDoters;
