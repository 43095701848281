import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import TripSchedule from './TripSchedule';

/**
 * Renders the mobile detail result component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.trip - The trip object.
 * @returns {JSX.Element} The rendered component.
 */
const MobileDetailResult = ({ trip = {} }) => {
  const { SHOW_VARIABLE_DEPARTURE_TIME } = useWhitelabelFeatures();
  const { t } = useTranslation();

  const { stops, departure, arrival, variableDepartureTime } = trip;

  const stopsText = t('search:trip_stopovers', {
    count: stops,
    context: stops === 0 ? 'direct' : null,
  });

  let stopsArray;
  if (SHOW_VARIABLE_DEPARTURE_TIME) {
    stopsArray = [
      <Text color="grayLight" size="XS" textAlign="center">
        {stopsText}
      </Text>,
      <Text color="grayLight" size="XS" textAlign="center">
        {variableDepartureTime
          ? `${t('search:trip_service_on_the_way')}`
          : `${t('search:trip_service_local')}`}
      </Text>,
    ];
  }

  const tripDateDeparture = departure && moment(departure);
  const tripDateArrival = arrival && moment(arrival);
  const tripDate = tripDateDeparture && tripDateDeparture.format('DD MMMM');
  const tripDepartureTime = tripDateDeparture && tripDateDeparture.format('LT');
  const tripArrivalTime = tripDateArrival && tripDateArrival.format('LT');

  return (
    <Spacing alignItems="center" justifyContent="space-between">
      <Spacing alignItems="center" responsiveSize="S">
        <Icon type="Afternoon" />

        <Spacing size="L">
          <TripSchedule
            departureHour={tripDepartureTime}
            arrivalHour={tripArrivalTime}
            mainContent={stopsArray || stopsText}
            mobileSize="S"
          />
        </Spacing>
      </Spacing>

      <Text mobileSize="S" size="XL" weight="semibold">
        {tripDate}
      </Text>
    </Spacing>
  );
};

MobileDetailResult.propTypes = {
  trip: PropTypes.shape({
    stops: PropTypes.number,
    departure: PropTypes.string,
    arrival: PropTypes.string,
  }).isRequired,
};

export default MobileDetailResult;
