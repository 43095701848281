import React from 'react';
import PropTypes from 'prop-types';
import BadgeWoman from 'ui/molecules/BadgeWoman';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import SeatAvailability from './SeatAvailability';

/**
 * ResultCategoriesAvailability component.
 * Shows a list of available categories and their availability in a trip.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.availableCategories - The available categories.
 * @param {boolean} props.supportWoman - Whether to support women.
 * @returns {JSX.Element|null} The ResultCategoriesAvailability component.
 */
const ResultCategoriesAvailability = ({ availableCategories, supportWoman }) => {
  const { VALID_CATEGORIES } = useWhitelabelFeatures();
  const { t } = useTranslation();
  const categoriesByType = availableCategories.reduce((accum, category) => {
    accum[category.type] = category.availability;
    return accum;
  }, {});
  const teacherSeat = categoriesByType.teacher;
  const studentSeat = categoriesByType.student;
  const olderSeat = categoriesByType.older;
  const minorSeat = categoriesByType.minor;

  const hasSpecialDiscounts =
    studentSeat !== 0 || teacherSeat !== 0 || olderSeat !== 0 || minorSeat !== 0;

  return hasSpecialDiscounts && VALID_CATEGORIES?.length > 1 ? (
    <>
      <SeatAvailability
        t={t}
        studentSeat={studentSeat}
        teacherSeat={teacherSeat}
        minorSeat={minorSeat}
        olderSeat={olderSeat}
        useSmallText
      />
      {supportWoman && <BadgeWoman />}
    </>
  ) : null;
};

ResultCategoriesAvailability.propTypes = {
  availableCategories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      availability: PropTypes.number,
    }),
  ).isRequired,
  supportWoman: PropTypes.bool,
};

export default ResultCategoriesAvailability;
