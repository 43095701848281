import { getCountryCallingCode, formatPhoneNumber } from 'react-phone-number-input';

/**
 * Normalizes a phone number string based on the provided country.
 * Used to mask the phone number before showing it to the user.
 * @param {string} rawInput - The raw phone number string.
 * @param {string} country - The two-letter country code.
 * @returns {string} The normalized phone number string.
 *
 *@example
 * ```js
 * const normalizedPhone = normalizePhone('5512345678', 'MX')
 * console.log(normalizedPhone) // 55 1234 5678
 * ```
 */
export default function normalizePhone(rawInput, country) {
  let input = rawInput ? rawInput.replace(/[^\d]/g, '') : '';

  // input.length should be greater than 1, because formatPhoneNumber
  // will return empty string when length is equal to 1
  if (input.length > 1 && country) {
    const code = getCountryCallingCode(country);
    input = formatPhoneNumber(`+${code}${input}`);
  }

  return input;
}
