import React from 'react';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FlatButton, Icon, Spacing, Text } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import './NoResults.scss';
import DayControls from 'components/search/DayControls';
import NerbyTerminals from '../atoms/NerbyTerminals';

/**
 * NoResults component.
 * Render a message indicating that no results were found and provide options for a new search.
 *
 * @param {object} props - way, providerId, isProviderList.
 * @param {string} props.way - The way of the search.
 * @param {string} props.providerId - The ID of the provider.
 * @param {boolean} props.isProviderList - Indicates if the provider list is shown.
 * @returns {JSX.Element} A React component that renders the no results message and options.
 */
const NoResults = ({ way, providerId, isProviderList }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { brand, homeUrl } = useWhitelabelEnvs();
  const { t } = useTranslation('search');

  /**
   * Redirect to the home URL to start a new search.
   */
  const handleNewSearch = () => {
    window.location.href = homeUrl;
  };

  return (
    <div className="no-results-container">
      <div className="no-results-wrapper">
        <Spacing size="XL" vertical alignItems="flex-start">
          <Spacing size="XL" vertical>
            <Spacing>
              <Icon type="Calendar" size="L" />
              <Text size="L" weight="bold">
                <Trans t={t} i18nKey="schedules_not_found" tOptions={{ context: brand }} />
              </Text>
            </Spacing>
            {features.SHOW_MIN_PRICES && (
              <Spacing vertical>
                <Text>{t('look_for_another_date')}</Text>
                <DayControls way={way} providerId={providerId} isProviderList={isProviderList} />
              </Spacing>
            )}
            {features.USE_NERBY_TERMINALS && <NerbyTerminals titleSize="M" />}
          </Spacing>
          <FlatButton onClick={handleNewSearch} size="S">
            {t('new_search')}
          </FlatButton>
        </Spacing>
      </div>
    </div>
  );
};

NoResults.propTypes = {
  way: PropTypes.string.isRequired,
  providerId: PropTypes.string,
  isProviderList: PropTypes.bool,
};

export default NoResults;
