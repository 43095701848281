import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { purchaseAttemptTracker } from 'metrics/user-analytics/purchase';
import LoadingScreen from 'components/LoadingScreen';
import { isSeatsOnResultActivated } from 'utils/seats';

const propTypes = {
  purchase: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isExchange: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    departs: PropTypes.object,
    returns: PropTypes.object,
  }).isRequired,
  createPurchase: PropTypes.func.isRequired,
  replaceUrl: PropTypes.func.isRequired,
};

class NewPurchase extends Component {
  componentDidMount() {
    const { createPurchase } = this.props;
    createPurchase();
  }

  componentWillReceiveProps(nextProps) {
    const { replaceUrl, purchase } = this.props;
    const nextPurchase = nextProps.purchase;

    const isSeatsOnResults = isSeatsOnResultActivated();

    if (purchase.isFetching && !nextPurchase.isFetching) {
      purchaseAttemptTracker(nextPurchase);
      const redirectUrl = `/purchase/${nextPurchase.token}`;

      // If seats are already selected, the user is redirected to passengers page
      const hasSelectedSeats =
        nextPurchase.departs?.selectedSeats?.length || nextPurchase.returns?.selectedSeats?.length;

      replaceUrl(
        `${redirectUrl}/${hasSelectedSeats && isSeatsOnResults ? 'passengers' : 'seats/departure'}`,
      );
    }
  }

  render() {
    return <LoadingScreen backgroundColor="white" />;
  }
}

NewPurchase.propTypes = propTypes;

export default NewPurchase;
