import React from 'react';
import { LinkButton, MessageBox, Text, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import usePurchase from 'hooks/store/usePurchase';

function MinorCompleteDisclaimer() {
  const { t } = useTranslation('purchase');
  const purchase = usePurchase();

  return (
    <MessageBox borderColor="warning" roundCorners widthFull>
      <Spacing size="S" vertical alignItems="flex-start">
        <Text>{t('text.minor_disclaimer_message_cds')}</Text>
        <LinkButton
          borderRadius
          variant="accent"
          href={purchase?.departs?.fragments?.[0]?.tickets?.[0]?.formMinorUrl ?? '#'}
          padding="S"
          text={t('text.minor_disclaimer_cta_cds')}
        />
      </Spacing>
    </MessageBox>
  );
}

export default MinorCompleteDisclaimer;
