import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { departureTimeFilter } from '../utils/tripFilters';
import useScheduleFilterOptions from '../utils/scheduleFilterOptions';

/**
 * Hook to get the available schedule filters.
 * @param {String} way - Way of the trips to filter
 * @param {*} isNewDesign - Indicates if is needs the new design structure
 * @returns Array
 */
const useAvailableSchedulesOptions = (way, isNewDesign) => {
  const { providerId } = useParams();
  const availableScheduleOptions = useSelector(({ search, trips }) => {
    const searchId = search.getIn([way, 'id']);
    const evaluator = departureTimeFilter;
    const tripsList = trips.getIn([searchId, 'buses', 'trips']);
    let tripListFiltered =
      trips?.getIn([searchId, 'buses', 'trips'])?.filter((t) => !t.get('openTicket')) || [];

    if (providerId) {
      tripListFiltered = tripListFiltered.filter((t) => providerId === t.get('providerId'));
    }

    if (!tripsList || !tripListFiltered.size) return [];

    return {
      hasEarlymorningTrips: tripListFiltered.find((trip) => evaluator(trip, 'earlymorning')),
      hasMorningTrips: tripListFiltered.find((trip) => evaluator(trip, 'morning')),
      hasAfternoonTrips: tripListFiltered.find((trip) => evaluator(trip, 'afternoon')),
      hasNightTrips: tripListFiltered.find((trip) => evaluator(trip, 'night')),
    };
  });

  return useScheduleFilterOptions(availableScheduleOptions, isNewDesign);
};

export default useAvailableSchedulesOptions;
