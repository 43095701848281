import { useState } from 'react';

function read(key) {
  if (typeof sessionStorage === 'undefined') return null;

  return sessionStorage.getItem(key);
}

function write(key, value) {
  if (typeof sessionStorage === 'undefined') return;

  sessionStorage.setItem(key, value);
}

export default function useSessionStorage(key) {
  const [value, setValue] = useState(read(key));

  const valueSetter = (newValue) => {
    write(key, newValue);
    setValue(newValue);
  };

  return [value, valueSetter];
}
