import React from 'react';
import { IconText } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import cash from '../../images/payment/kueskipay-cash.svg';
import card from '../../images/payment/kueskipay-card.svg';
import payment from '../../images/payment/kueskipay-payment.svg';
import security from '../../images/payment/kueskipay-security.svg';
import time from '../../images/payment/kueskipay-time.svg';
import './KueskipayIcons.scss';

const KueskipayIcons = () => {
  const { t } = useTranslation('payment');
  const benefits = [
    {
      img: cash,
      text: t('kueskipay_benefits.first'),
    },
    {
      img: time,
      text: t('kueskipay_benefits.second'),
    },
    {
      img: payment,
      text: t('kueskipay_benefits.third'),
    },
    {
      img: security,
      text: t('kueskipay_benefits.fourth'),
    },
    {
      img: card,
      text: t('kueskipay_benefits.fifth'),
    },
  ];

  return (
    <div className="kueski-icons-grid">
      {benefits.map((data) => (
        <IconText key={data.img} iconSize="L" label={data.text} iconType={data.img} fontSize="S" />
      ))}
    </div>
  );
};

export default KueskipayIcons;
