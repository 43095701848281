import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PurchasePricing from 'components/purchase/PurchasePricing';
import DiscountCode from 'components/purchase/DiscountCode';
import PoweredBy from 'components/PoweredBy';
import Timer from 'components/purchase/Timer';
import TripSummaryCard from 'components/purchase/TripSummaryCard';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Link } from '@reservamos/elements';
import getPurchaseStepName from '../../utils/purchase/getPurchaseStepName';
import BackButton from '../../ui/atoms/BackButton';
import LoyaltyButton from '../LoyaltyButton';
import useLoyaltyPrograms from '../../loyalty/context/useLoyaltyPrograms';
import DotersBanner from '../../ui/atoms/DotersBanner';
import DotersHeader from '../../ui/molecules/DotersHeader';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showPurchaseReview: PropTypes.func.isRequired,
  goToSearch: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  isOnDemand: PropTypes.bool.isRequired,
  isExchange: PropTypes.bool,
  timerRemainingTime: PropTypes.number,
};

const PurchaseSidebar = ({
  isLoading,
  showPurchaseReview,
  goToSearch,
  isOpenTicket,
  isOnDemand,
  isExchange,
  timerRemainingTime,
}) => {
  const location = useLocation();
  const {
    features,
    copies,
    env: { brand },
  } = useSelector((state) => state.whitelabelConfig);
  const {
    costaPass: { userIsLoggedIn: userIsLoggedInWithCostaPass, config: costaPassConfig },
    doters: { userIsLoggedIn: userIsLoggedInWithDoters },
  } = useLoyaltyPrograms();
  const pricingBeforeCheckoutIsOn = features.PRICING_BEFORE_CHECKOUT;
  const { t } = useTranslation('purchase');

  const SHOW_ON_FLAT = features.FUNNEL_STYLE === 'FLAT';
  const { SHOW_TRIP_DETAIL_CARD } = features;
  const showDetailCard = SHOW_ON_FLAT || SHOW_TRIP_DETAIL_CARD;
  const showSiemprePlus = !isOpenTicket && !isOnDemand;
  const showCostaPassBanner =
    !isOpenTicket && features.COSTAPASS_ENABLED && !userIsLoggedInWithCostaPass;

  const purchaseStep = getPurchaseStepName(location.pathname);
  const showPricing = ['checkout', 'complete'].includes(purchaseStep) || pricingBeforeCheckoutIsOn;
  const showDiscountCouponForm = purchaseStep === 'checkout';

  const isCheckout = purchaseStep === 'checkout';
  const signMessagesInvitation =
    !userIsLoggedInWithDoters && features.DOTERS_ENABLED
      ? !isCheckout && <DotersBanner show onSidebar />
      : null;

  const providerPhones = copies.provider.phones || [];
  const phoneItems = providerPhones.map((phone) => (
    <div key={phone.value} className="purchase-sidebar-contact-item">
      <Link text={phone.display} href={`tel:${phone.value}`} type="accent" />
      {phone.label && (
        <Text elementType="span" size="S" color="grayLight">{` ${phone.label}`}</Text>
      )}
    </div>
  ));

  return (
    <div className="purchase-sidebar">
      <Spacing vertical size="L">
        <BackButton onClick={goToSearch} />

        <Timer remainingTime={timerRemainingTime} />
        <DotersHeader />
        {showPricing && (
          <div>
            {SHOW_ON_FLAT && (
              <Text weight="semibold" size="S">
                {t('label.payment_details')}
              </Text>
            )}

            <div className="purchase-sidebar-pricing">
              <div className={`purchase-sidebar${isLoading ? '-loading' : ''}`}>
                <PurchasePricing />
                {!isExchange && showDiscountCouponForm && <DiscountCode />}
              </div>
            </div>
          </div>
        )}

        {showDetailCard && (
          <div>
            <Text weight="semibold" size="S">
              {t('label.trip_detail')}
            </Text>
            <TripSummaryCard showPurchaseReview={showPurchaseReview} />
          </div>
        )}

        {showSiemprePlus && signMessagesInvitation}
        {showCostaPassBanner && (
          <LoyaltyButton
            extraConfig={costaPassConfig}
            id="siempre-plus-widget-sidebar-button-costapass"
            insideIcon
          />
        )}

        <div className="purchase-sidebar-contact">
          {!SHOW_ON_FLAT && (
            <Text weight="semibold" size="S">
              {t('label.we_can_help', { context: brand })}
            </Text>
          )}

          <Spacing vertical size="S">
            <Text weight="semibold" size="S">
              {t('general:customer_service')}
            </Text>
            <Spacing vertical size="XS">
              {phoneItems}
              {copies.provider.email && (
                <Link
                  text={copies.provider.email}
                  href={`mailto:${copies.provider.email}`}
                  type="accent"
                />
              )}
              {copies.provider.nit && (
                <Text size="S" color="accent" weight="semibold">
                  NIT: {copies.provider.nit}
                </Text>
              )}
              {copies.provider.frequentQuestions && (
                <Link
                  text={copies.provider.frequentQuestions.label}
                  href={copies.provider.frequentQuestions.link}
                  type="accent"
                  newTab
                />
              )}
            </Spacing>
          </Spacing>
        </div>
        {features.SHOW_POWERED_BY && <PoweredBy />}
      </Spacing>
    </div>
  );
};

PurchaseSidebar.propTypes = propTypes;

export default PurchaseSidebar;
