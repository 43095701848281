import React from 'react';
import PropTypes from 'prop-types';
import { NewSeat as NewSeatElement } from '@reservamos/elements';
import { isPetFriendlySeat, isWomanSeat } from 'utils/purchase/seats';
import AdjacentBadge from 'images/badges/adjacent-seat.svg';
import { isSeatsOnResultActivated } from 'utils/seats';
import PetIcon from '../../images/badges/pet.svg';
import DisabledPetIcon from '../../images/badges/pet-disabled.svg';
import WomanIcon from '../../images/brands-unique/gfa/badge-woman.svg';
import DisabledWomanIcon from '../../images/badges/woman-disabled.svg';

/**
 * Represents a seat component.
 * @param {Object} props - The props object.
 * @param {boolean} props.isRecommended - Indicates if the seat is recommended.
 * @param {number} props.number - The seat number.
 * @param {string} props.type - The seat type.
 * @param {boolean} props.hasTv - Indicates if the seat has a TV.
 * @param {function} props.onClick - The click event handler.
 * @param {React.ReactNode} props.showPetFriendly - The pet friendly content to display.
 * @param {string} props.seatClass - The CSS class for the seat button.
 * @param {Category} props.category - The category to display.
 * @param {boolean} props.disabled - Indicates if the seat is disabled.
 * @param {boolean} props.isSmall - Indicates if the seat is small.
 * @param {boolean} props.showIcon - Indicates if the icon should be displayed.
 * @param {boolean} props.useNewDesign - Indicates if the new design should be used.
 * @returns {React.ReactNode} The rendered seat component.
 */
const NewSeat = ({
  isRecommended,
  number,
  type,
  hasTv,
  onClick,
  showPetFriendly,
  seatClass,
  category,
  disabled,
  isSmall,
  showIcon = true,
  useNewDesign,
  topTvOnDesktop,
}) => {
  const isSeatsOnResults = isSeatsOnResultActivated();
  const iconTypes = {
    petFriendly: { default: PetIcon, disabled: DisabledPetIcon },
    womanSeat: { default: WomanIcon, disabled: DisabledWomanIcon },
    adjacent: { default: AdjacentBadge },
  };

  /**
   * Get icon type.
   * @returns {string} The icon type.
   */
  const getIconType = () => {
    if (category === 'adjacent') return 'adjacent';
    if (showPetFriendly && isPetFriendlySeat(category)) return 'petFriendly';
    if (isWomanSeat(category)) return 'womanSeat';
  };

  const iconType = showIcon && iconTypes[getIconType()];

  return isSeatsOnResults || useNewDesign ? (
    <NewSeatElement
      isRecommended={isRecommended}
      number={number}
      type={type}
      hasTv={hasTv}
      onClick={onClick}
      iconSrc={(disabled && iconType?.disabled) || iconType?.default}
      isIconRight
      disabled={disabled}
      isSmall={isSmall}
      topTvOnDesktop={topTvOnDesktop}
    />
  ) : (
    <div className="seats-layout-bus-space">
      <button className={seatClass} onClick={onClick}>
        {number}
      </button>
      {showPetFriendly}
    </div>
  );
};

NewSeat.propTypes = {
  isRecommended: PropTypes.bool,
  number: PropTypes.number,
  type: PropTypes.string,
  hasTv: PropTypes.bool,
  onClick: PropTypes.func,
  showPetFriendly: PropTypes.node,
  seatClass: PropTypes.string,
  category: PropTypes.string,
  disabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  showIcon: PropTypes.bool,
  useNewDesign: PropTypes.bool,
  topTvOnDesktop: PropTypes.bool,
};

export default NewSeat;
