/**
 * Normalizes a given string based on the provided regular expression.
 *
 * @param {string} regex - The regular expression pattern to use for normalization.
 * @param {string} string - The input string to be normalized.
 * @returns {string} - A string containing all matches of the regular expression in the input string.
 */
const normalizeRegex = (regex, string) => {
  if (typeof string !== 'string') {

    return '';
  }

  if (!regex) {
    return string.replace(/\s/g, '');
  }

  try {
    // Validate regex
    RegExp(regex);

    const regExp = new RegExp(regex, 'g');
    return string.replace(regExp, '');
  } catch (e) {

    return string;
  }
};

export default normalizeRegex;
