import React from 'react';
import PropTypes from 'prop-types';
import { Icon, NewSeat, Spacing, Text } from '@reservamos/elements';
import { When } from 'react-if';

/**
 * Renders a NewSeatLegendItem component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display.
 * @param {string} props.type - The type of the seat.
 * @param {number} props.countSeats - The number of seats.
 * @param {boolean} props.isRecommended - Indicates if the seat is recommended.
 * @param {string} props.iconType - The type of the icon.
 * @param {boolean} props.isVertical - Indicates if the component is vertical.
 * @returns {JSX.Element} The rendered NewSeatLegendItem component.
 */
function NewSeatLegendItem({ text, type, countSeats, isRecommended, iconType, isVertical }) {
  return (
    <Spacing size="XS" alignItems="center" isResponsive vertical={isVertical}>
      {iconType ? (
        <Icon type={iconType} />
      ) : (
        <NewSeat number="" type={type} isSmall isRecommended={isRecommended} elementType="div" />
      )}

      <Text size="XS" whiteSpace="wrap" textAlign="center">
        <When condition={countSeats >= 0}>
          <Text elementType="span" size="XS" weight="semibold">
            {`${countSeats} `}
          </Text>
        </When>
        {text}
      </Text>
    </Spacing>
  );
}

NewSeatLegendItem.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  countSeats: PropTypes.number,
  isRecommended: PropTypes.bool,
  iconType: PropTypes.string,
  isVertical: PropTypes.bool,
};

NewSeatLegendItem.defaultProps = {
  text: '',
  type: '',
  isRecommended: false,
  iconType: '',
  isVertical: false,
};

export default NewSeatLegendItem;
