import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/RouteItineraryInline';

const RouteItineraryInline = ({
  departure,
  departureLabel,
  duration,
  durationLabel,
  arrival,
  arrivalLabel,
  showDuration,
}) => {
  return (
    <div className="route-inline">
      <p className="flat-text-main-bold departure">{departure}</p>
      <p className="flat-text-regular departure-label">{departureLabel}</p>
      {showDuration && (
        <>
          <p className="flat-text-regular duration">{duration}</p>
          <p className="flat-text-regular duration-label">{durationLabel}</p>
        </>
      )}
      <p className="flat-text-main arrival">{arrival}</p>
      <p className="flat-text-regular arrival-label">{arrivalLabel}</p>
    </div>
  );
};

RouteItineraryInline.propTypes = {
  departure: PropTypes.string.isRequired,
  departureLabel: PropTypes.string.isRequired,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationLabel: PropTypes.string,
  arrival: PropTypes.string.isRequired,
  arrivalLabel: PropTypes.string.isRequired,
  showDuration: PropTypes.bool,
};

RouteItineraryInline.defaultProps = {
  duration: null,
  durationLabel: null,
  showDuration: true,
};

export default RouteItineraryInline;
