import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleModal } from '@/actions';
import 'styles/components/Modal';

class Modal extends Component {
  componentWillReceiveProps(newProps) {
    const { visibility } = newProps;

    if (visibility === 'visible') {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  componentWillUnmount() {
    const { hideModal } = this.props;
    hideModal();
  }

  hideModal(event) {
    const { hideModal } = this.props;
    event.stopPropagation();
    hideModal();
  }

  render() {
    const { visibility, hideModal } = this.props;

    if (visibility === 'hidden') return null;

    return (
      <div className="modal">
        <div className="modal-fade-screen animated fade-in" onClick={hideModal}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <this.props.component {...this.props} />
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  visibility: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { ...state.modal };
}

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(toggleModal('hidden')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
