import { useContext } from 'react';
import MercadoPagoTDCContext from './MercadoPagoTDCContext';

const useMercadoPagoTDCContext = () => {
  const contextValue = useContext(MercadoPagoTDCContext);

  if (!contextValue) {
    throw new Error(
      'MercadoPagoTDCContext value is undefined, you likely forgot to use MercadoPagoTDCProvider',
    );
  }

  return contextValue;
};

export default useMercadoPagoTDCContext;
