import { connect } from 'react-redux';
import TransferPaymentTicket from './TransferPaymentTicket';

const mapStateToProps = ({ payment, whitelabelConfig: { env } }) => ({
  businessName: env.kushki.businessName,
  nit: env.kushki.NIT,
  description: payment.get('kushkiDescription'),
  createdAt: payment.get('createdAt'),
  status: payment.get('kushkiProcessorState'),
  cus: payment.get('traceabilityCode'),
  reference: payment.get('reference'),
  bankName: payment.get('bankName'),
  total: payment.get('amount'),
  pdfUrl: payment.get('receiptUrl'),
});

export default connect(mapStateToProps)(TransferPaymentTicket);
