import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cookie from 'js-cookie';
import { Text, Spacing, Currency, Link } from '@reservamos/elements';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import ModalPopUp from '../../../../ui/atoms/ModalPopUp';
import OpenTicketIcon from '../../../../images/brands-unique/gfa/open-ticket.svg';
import { trackOpenTickedModalOpened } from '../../../../metrics/user-analytics/search';

const COOKIE_NAME = 'isOpenTicketModalOpened';

const ModalContentOpenTicket = ({
  openTicketTrip,
  selectTrip,
  isRoundTrip,
  isDynamicPricing,
  analyticSearchData,
  isVisibleByTrigger,
  onModalClose,
}) => {
  const [visible, setVisible] = useState(!isDynamicPricing);

  const showModal = () => setVisible(true);

  const closeModal = () => {
    cookie.set(COOKIE_NAME, true, { expires: 7 });
    setVisible(false);
    onModalClose();
  };

  const onClickPrimary = () => {
    cookie.set(COOKIE_NAME, true, { expires: 7 });
    selectTrip(openTicketTrip, 0, true);
  };

  const handleTrackOpenModal = (from) => {
    trackOpenTickedModalOpened(analyticSearchData, from);
  };

  useEffect(() => {
    const previouslyOpenned = Boolean(cookie.get(COOKIE_NAME));
    if (visible && previouslyOpenned) {
      closeModal();
      return;
    }

    if (isVisibleByTrigger) {
      showModal();
    }

    if (!previouslyOpenned && visible) {
      showModal();
      handleTrackOpenModal('Automatic');
    }
  });

  if (!openTicketTrip || isRoundTrip) return null;

  const { origin, destination, pricing } = openTicketTrip;

  if (!visible) return null;

  return (
    <ModalPopUp
      modalTitle="Boleto Abierto a:"
      modalIconType={OpenTicketIcon}
      buttonPrimaryText="Comprar boleto abierto"
      buttonSecondaryText="Seguir buscando"
      onClickPrimary={onClickPrimary}
      onClickSecondary={() => closeModal()}
    >
      <Spacing vertical size="S">
        <div className="popup-divider" />
        <Text fontFamily="secondary" size="L" weight="semibold">
          {openTicketTrip && `${origin.cityName} a ${destination.cityName}`}
        </Text>
        <Text fontFamily="secondary" size="S">
          <span>Precio por adulto: </span>
          <Currency
            price={openTicketTrip && pricing.total}
            weight="bold"
            currency={getCurrencySuffix()}
            sign={getCurrencyPrefix()}
          />
        </Text>
        <div className="popup-divider" />
        <Text fontFamily="secondary">
          Ahora en Primera Plus, puedes comprar tu boleto abierto y viajar cuando lo decidas. Con un
          boleto abierto puedes comprar ahora y tu decides cuando viajar, sólo presenta tu boleto en
          taquilla o ingresa en nuestro sitio web y selecciona el horario de tu salida en la ruta
          seleccionada
        </Text>
        <Link
          text="Cambia tu boleto abierto aquí"
          href="https://viaje.primeraplus.com.mx/exchange"
          type="primary"
          newTab
        />
      </Spacing>
    </ModalPopUp>
  );
};

ModalContentOpenTicket.propTypes = {
  selectTrip: PropTypes.func.isRequired,
  openTicketTrip: PropTypes.shape({
    origin: PropTypes.shape({
      cityName: PropTypes.string,
    }),
    destination: PropTypes.shape({
      cityName: PropTypes.string,
    }),
    pricing: PropTypes.shape({
      total: PropTypes.number,
    }),
  }),
  isRoundTrip: PropTypes.bool.isRequired,
  isDynamicPricing: PropTypes.bool.isRequired,
  analyticSearchData: PropTypes.shape({
    destination: PropTypes.shape({
      cityName: PropTypes.string,
      cityId: PropTypes.string,
      id: PropTypes.string,
    }),
    origin: PropTypes.shape({
      cityName: PropTypes.string,
      cityId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  isVisibleByTrigger: PropTypes.bool,
  onModalClose: PropTypes.func,
};

ModalContentOpenTicket.defaultProps = {
  openTicketTrip: null,
  isVisibleByTrigger: false,
  onModalClose: () => {},
};

export default ModalContentOpenTicket;
