import React, { useState } from 'react';
import { Spacing, Box, Text, Icon } from '@reservamos/elements';
import './BubbleAlertMessage.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CirclesExpandedOnScroll from './CirclesExpandedOnScroll';

/**
 * BubbleAlertMessage component.
 * This component displays a bubble alert message that encourages users to activate alerts for better travel prices.
 * The message is wrapped in an expanding circle animation that triggers as the user scrolls down the page.
 *
 * @returns {React.Component} The BubbleAlertMessage component with an animated bubble alert.
 * @param {Object} props - Component props.
 * @param {Function} props.onClick - Function to handle click event.
 * @param {boolean} props.isActivated - Whether the alert is activated.
 */
function BubbleAlertMessage({ onClick, isActivated }) {
  const { t } = useTranslation('general');
  const [isVisible, setIsVisible] = useState(false);

  if (isActivated) return null;

  return (
    <CirclesExpandedOnScroll isVisible={isVisible} setIsVisible={setIsVisible}>
      <div className="bubble-alert-container">
        <div className="bubble-alert" onClick={onClick}>
          <Box
            borderRadius="M"
            bubbleTailPosition="bottom"
            hasShadow
            paddingHorizontal="S"
            paddingVertical="S"
            alphaBg="L"
            blurBg="L"
            border="all"
            borderColor="white"
            borderWidth={2}
          >
            <Spacing size="S" alignItems="center">
              <div className="bubble-alert-bell">
                <Icon type="BellAlert" size="S" />
              </div>

              <Text xsMobileSize="S" weight="semibold">
                {t('text.message_alert_bubble')}
              </Text>
            </Spacing>
          </Box>
        </div>
        <button className="bubble-alert-close" onClick={() => setIsVisible(false)}>
          <Icon type="Cross" mobileSize="XS" size="S" color="grayLight" />
        </button>
      </div>
    </CirclesExpandedOnScroll>
  );
}

export default BubbleAlertMessage;
BubbleAlertMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActivated: PropTypes.bool.isRequired,
};
