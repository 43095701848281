import React from 'react';
import PropTypes from 'prop-types';
import TicketPassenger from 'components/purchase/TicketPassenger';
import 'styles/components/purchase/Receipt';

const propTypes = {
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

const TicketPassengers = ({ passengers, isOpenTicket }) => {
  return (
    <div>
      {passengers.map((passengerInfo) => {
        const { name, type } = passengerInfo;
        return (
          <TicketPassenger
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...passengerInfo}
            key={`${name} - ${type}`}
            isOpenTicket={isOpenTicket}
          />
        );
      })}
    </div>
  );
};
TicketPassengers.propTypes = propTypes;

export default TicketPassengers;
