import React from 'react';
import PropTypes from 'prop-types';
import CardFormPaymentOptions from '../CardFormPaymentOptions';
import InstallmentsPlanSelectorOv from '../InstallmentsPlanSelectorOv';

function Installments({
  qualifiesForMonthlyInstallments,
  monthlySelectedPlan,
  selectedInstallmentsPlan,
  total,
  resetInstallmentsPlan,
  maxInstallmentsPlan,
  paymentPlans,
  selectedPlan,
  onSelectPlan,
}) {
  return (
    <>
      <CardFormPaymentOptions
        qualifiesForMonthlyInstallments={qualifiesForMonthlyInstallments}
        monthlySelectedPlan={monthlySelectedPlan}
        selectedInstallmentsPlan={selectedInstallmentsPlan}
        maxInstallmentsPlan={maxInstallmentsPlan}
        total={total}
        onChangeClick={resetInstallmentsPlan}
        selectedPlan={selectedPlan}
        setSelectedPlan={onSelectPlan}
      />

      <InstallmentsPlanSelectorOv
        visible={selectedPlan === 'installmentsPayment' && monthlySelectedPlan === 1}
        paymentPlans={paymentPlans}
        onCloseModal={resetInstallmentsPlan}
      />
    </>
  );
}

Installments.propTypes = {
  qualifiesForMonthlyInstallments: PropTypes.bool.isRequired,
  monthlySelectedPlan: PropTypes.number.isRequired,
  selectedInstallmentsPlan: PropTypes.shape({
    card: PropTypes.string.isRequired,
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
  total: PropTypes.number.isRequired,
  maxInstallmentsPlan: PropTypes.shape({
    months: PropTypes.number,
    monthlyPayment: PropTypes.number,
  }).isRequired,
  paymentPlans: PropTypes.object.isRequired,
  resetInstallmentsPlan: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
};

export default Installments;
