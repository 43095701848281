import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from '@reservamos/elements';
import './AlertButton.scss';

/**
 * Button component to display an alert with a counter.
 * @param {Object} props - Component props.
 * @param {Function} props.onClick - Function to handle button click.
 * @param {number} props.alertCount - Number of alerts to display.
 * @param {boolean} props.isActivated - Whether the alert is activated.
 */
const AlertButton = ({ onClick, alertCount, isActivated }) => {
  return (
    <button className="alert-button" onClick={onClick}>
      {!isActivated && (
        <span className="alert-counter">
          <Text elementType="span" size="XXS" color="white" weight="bold">
            {alertCount}
          </Text>
        </span>
      )}

      <div className="alert-bell">
        <Icon size="M" type="BellAlert" />
      </div>
    </button>
  );
};

AlertButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  alertCount: PropTypes.number.isRequired,
  isActivated: PropTypes.bool,
};

export default AlertButton;
