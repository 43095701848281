import store from '@/store';
import { receiveProfile } from '@/actions/costapass';
import { deleteWalletDiscount } from '@/actions/search';

const handleCostaPassProfileUpdate = ({ user, loyaltyName }) => {
  if (!user) {
    // remove CostaPass wallet discount
    store.dispatch(deleteWalletDiscount('costapass'));
  }
  // update CostaPass profile anyway? (even if user is logged out)
  // ( receiveProfile will check again if user is logged in or not )
  store.dispatch(receiveProfile(user, loyaltyName));
};

export default handleCostaPassProfileUpdate;
