/* eslint-disable no-case-declarations */
import { Map, fromJS } from 'immutable';
import * as types from 'constants/ActionTypes';
import getSeats from 'utils/purchase/getSeats';
import { preventSelectPetFriendly, saveSeatsOnLS, getUpdatedSeats } from 'utils/seats';

const defaultState = Map({});

/**
 * Reducer for handling seat bus state changes.
 * @param {Map} state - The current state of the seat bus.
 * @param {Object} action - The action being dispatched.
 * @returns {Map} The new state of the seat bus.
 */
export default function seatsBus(state = defaultState, action) {
  switch (action.type) {
    case types.SET_SEAT_MAP_TRIPS: {
      const { layout, diagramType, tripSlug, way, busCategories } = action;
      return state.mergeIn([tripSlug], {
        layout: fromJS(layout),
        seats: getSeats(action.layout),
        diagramType,
        tripSlug,
        way,
        busCategories,
      });
    }
    case types.RESET_SEAT_TRIPS:
      return defaultState;

    case types.SELECT_SEATS_TRIPS: {
      const { seats: actionSeats, way, tripSlug } = action;
      const seats = preventSelectPetFriendly(actionSeats);
      if (way === 'departs') saveSeatsOnLS({ id: tripSlug, seats });
      return state.set(
        tripSlug,
        Map({
          ...state.get(tripSlug).toJS(),
          selectedSeats: seats,
        }),
      );
    }

    case types.UPDATE_SEAT_TRIPS: {
      // Update the selected seats in the seats state
      const { seat, tripSlug } = action;
      const selectedSeats = state.get(tripSlug).get('selectedSeats');
      const newSelectedSeats = selectedSeats;
      const updatedSeats = getUpdatedSeats({ selectedSeats: newSelectedSeats, seat });
      return state.set(
        action.tripSlug,
        Map({
          ...state.get(action.tripSlug).toJS(),
          selectedSeats: updatedSeats,
        }),
      );
    }

    case types.SET_SEAT_MAP_ERROR: {
      const { tripId } = action;
      return state.setIn([tripId, 'error'], true);
    }
    default:
      return state;
  }
}
