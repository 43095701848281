import { useState, useEffect } from 'react';
import { getReadableQuantity } from '../../../utils/currency';

const useCostaPassSlider = ({
  wallet,
  onApplyPointsDiscount,
  onRemovePointsDiscount,
  purchaseTotal,
  purchaseTotalBeforeLock,
}) => {
  // Slider state logic -----------------
  const [disabled, setDisabled] = useState(false);
  const [sliderPoints, setSliderPoints] = useState(0);
  const [error, setError] = useState(false);
  // To enable/disable "Apply" button
  const [canApplyPoints, setCanApplyPoints] = useState(false);

  const enableOrDisableSlider = () => {
    if (wallet.usedBalance > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setSliderPoints(wallet.usedBalance);
    enableOrDisableSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.usedBalance]);

  const enableOrDisableApplyButton = () => {
    if (sliderPoints > 0) {
      setCanApplyPoints(true);
    } else {
      setCanApplyPoints(false);
    }
  };

  // To enable/disable "Apply" button
  useEffect(() => {
    enableOrDisableApplyButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderPoints]);

  // End Slider state logic -----------------

  // Slider handlers -----------------
  const handleOnChange = (e) => {
    if (disabled) return;
    const { value, name } = e.target;
    const isSlider = name === 'sliderRange';
    const valueToShow = isSlider ? Number(value).toFixed(2).toString() : value;
    if (
      (Number(value) <= wallet.maxUsableBalance && /^\d+(\.\d{0,2})?$/.test(value)) ||
      value === ''
    )
      setSliderPoints(value === '' ? '' : valueToShow);
  };

  const handleOnEdit = () => {
    setDisabled(false);
  };

  const handleOnCancel = () => {
    if (wallet.usedBalance) {
      setSliderPoints(wallet.usedBalance);
    }
    setDisabled(true);
  };

  const handleOnSubmitPoints = (sliderPoints) => {
    if (wallet.isUpdating) return;
    const sliderPointsNumber = Number(sliderPoints);
    const maxPointsBalanceNumber = Number(wallet.maxUsableBalance);
    if (
      wallet.balance > 0 &&
      sliderPointsNumber <= maxPointsBalanceNumber &&
      sliderPointsNumber >= wallet.minUsableBalance
    ) {
      if (Number(sliderPoints) === wallet.usedBalance) {
        setDisabled(true);
      } else {
        onApplyPointsDiscount(sliderPoints);
        setError(false);
      }
    }
  };

  const handleOnDelete = () => {
    if (wallet.isUpdating) return;
    if (Number(wallet.minUsableBalance) > 0) {
      setSliderPoints(wallet.minUsableBalance);
      handleOnSubmitPoints(wallet.minUsableBalance);
    } else {
      onRemovePointsDiscount();
      setError(false);
    }
  };

  // End Slider handlers -----------------

  // Slider props ------------------------
  const replacesRegexComas = /\B(?=(\d{3})+(?!\d))/g;
  const formattedMinUsableBalance = getReadableQuantity(Number(wallet.minUsableBalance));
  const formattedUsableBalance = getReadableQuantity(Number(wallet.maxUsableBalance));
  const formattedBalance = getReadableQuantity(Number(wallet.balance));

  // This variable is used to show the balance when interacting with the slider
  // totalBeforeLock = Total that came from the backed to be paid by the user
  // minUsableBalance = Total already reduced from the total to be paid by the user
  // sliderPoints = Actual value of the wallet
  const totalBeforeLockLessPoints =
    purchaseTotalBeforeLock + Number(wallet.minUsableBalance) - sliderPoints;
  const totalBeforeLessPoints = totalBeforeLockLessPoints >= 0 ? totalBeforeLockLessPoints : 0;
  const totalBalance = !disabled ? totalBeforeLessPoints : purchaseTotal;

  // End Slider props --------------------

  return {
    sliderPoints,
    canApplyPoints,
    error,
    disabled,
    handleOnChange,
    handleOnEdit,
    handleOnCancel,
    handleOnDelete,
    handleOnSubmitPoints,
    replacesRegexComas,
    formattedMinUsableBalance,
    formattedUsableBalance,
    formattedBalance,
    totalBalance,
  };
};

export default useCostaPassSlider;
