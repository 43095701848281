import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Icon } from '@reservamos/elements';
import { getDayTime } from 'utils/trips/parseTripDetails';

/**
 * Icon that represents the day time
 * @param {Object} props - Props
 * @param {Object} props.time - Time
 * @returns
 */
const TimeIcon = ({ time }) => {
  const convertedTime = time.hours ? time.hours() : moment(time).hours();
  let timeIcon = _.capitalize(getDayTime(convertedTime));
  timeIcon = timeIcon === 'Earlymorning' ? 'EarlyMorning' : timeIcon;

  return <Icon size="M" type={timeIcon} />;
};

TimeIcon.propTypes = {
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
export default TimeIcon;
