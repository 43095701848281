import * as types from 'constants/ActionTypes';
import { camelizeKeys } from 'humps';
import camelizeConverter from 'utils/camelizeConverter';

export function setCities(cities) {
  return {
    type: types.SET_CITIES,
    cities: camelizeKeys(cities, camelizeConverter),
  };
}

export function setTerminals(terminals) {
  return {
    type: types.SET_TERMINALS,
    terminals: camelizeKeys(terminals, camelizeConverter),
  };
}

export function setAirports(airports) {
  return {
    type: types.SET_AIRPORTS,
    airports: camelizeKeys(airports, camelizeConverter),
  };
}

export function setLines(lines) {
  return {
    type: types.SET_LINES,
    lines: camelizeKeys(lines, camelizeConverter),
  };
}

export function setCarriers(carriers) {
  return {
    type: types.SET_CARRIERS,
    carriers: camelizeKeys(carriers, camelizeConverter),
  };
}
