import { connect } from 'react-redux';
import moment from 'moment';
import getSearchAnalyticsData from 'utils/search/getSearchAnalyticsData';
import ModalDiscount from './ModalDiscount';

const mapStateToProps = (state) => {
  const { search, trips, cities, terminals } = state;
  const searchId = search.getIn(['departure', 'id']);
  const tripList = trips.getIn([searchId, 'buses', 'trips']).toJS();
  const minPrice = Math.min(...tripList.map((trip) => trip.pricing.total));
  const isRoundTrip = Boolean(search.get('returnDate'));
  const promotions = search.get('promotions');

  const defaultPromotion = new Map();
  defaultPromotion.set('percent', 50);
  defaultPromotion.set('price', 284);
  defaultPromotion.set('search', moment().format('DD-MMM-YY'));

  const promotionProp = promotions ? promotions.get(0) : defaultPromotion;

  const promotion = {
    percent: promotionProp.get('percent'),
    price: promotionProp.get('price'),
    search: promotionProp.get('search'),
  };

  const { percent, price, searchDate } = promotion;
  const departureDate = moment(searchDate).format('DD-MMM-YY');
  const anticipation = moment(searchDate).diff(moment().startOf('day'), 'days');

  const departureId = search.getIn(['departure', 'id']);
  const places = { ...cities, ...terminals };
  const departures = trips.get(departureId);

  const { search: analyticSearchData } = getSearchAnalyticsData({
    search,
    places,
    departures,
    returns: null,
  });

  return {
    isRoundTrip,
    anticipation,
    percent,
    minPrice,
    price,
    search: departureDate,
    analyticSearchData,
  };
};

export default connect(mapStateToProps)(ModalDiscount);
