import { connect } from 'react-redux';
import { submit as submitForm, formValueSelector } from 'redux-form';
import {
  getPurchase,
  selectPaymentOption,
  resetInstallmentsPlan,
  resetPaymentCardError,
} from '@/actions/purchase';
import { cardPaymentAttempt } from '@/actions/payment';
import { clearError } from '@/actions';
import MobilePayment from './MobilePayment';

const mapStateToProps = (state) => {
  const { purchase, payment, errors } = state;
  const purchaseJs = purchase.toJS();
  const cardSelector = formValueSelector('card');

  return {
    errors,
    cardErrorOccured: payment.get('cardErrorOccured'),
    cardValues: cardSelector(
      state,
      'holderName',
      'cardNumber',
      'cvv2',
      'expirationMonth',
      'expirationYear',
      'paymentPlan',
      'monthlySelectedPlan',
      'billingAddress',
    ),
    fetchingPayment: payment.get('isFetching') || false,
    paymentId: payment.get('id'),
    purchase: purchaseJs,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPurchase: () => dispatch(getPurchase(ownProps.match.params.token)),
  onCardPaymentAttempt: (token, cardValues, paymentInfo) =>
    dispatch(cardPaymentAttempt(token, cardValues, paymentInfo)),
  onSelectPaymentOption: (option) => dispatch(selectPaymentOption(option)),
  onSubmitForm: (formName) => dispatch(submitForm(formName)),
  resetInstallmentsPlan: () => {
    dispatch(resetInstallmentsPlan());
  },
  resetPaymentCardError: () => dispatch(resetPaymentCardError()),
  transitionTo: (route) => ownProps.history.push(route),
  clearErrors: () => dispatch(clearError()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MobilePayment);
