/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { useSelector } from 'react-redux';
import DepartureTripPicked from './DepartureTripPicked';
import DepartureTripPickedFlat from './DepartureTripPickedFlat';

// eslint-disable-next-line react/prop-types
const DepartureTripPickedFactory = (props) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  return features.FUNNEL_STYLE === 'CLASSIC' ? (
    <DepartureTripPicked {...props} />
  ) : (
    <DepartureTripPickedFlat {...props} />
  );
};
export default DepartureTripPickedFactory;
