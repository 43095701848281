import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import moment from 'moment';
import { formatCurrency } from 'utils/Helpers';
import PaycashLogos from '../../../ui/templates/PaycashLogos';
import 'styles/components/purchase/TicketsPanel';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const PaycashPaymentInfo = ({ amount, paydayLimit, reference }) => {
  const { t } = useTranslation('payment');
  const paydayLimitDisplay = moment(paydayLimit).format('LLLL');
  const amountDisplay = formatCurrency(amount, 2);

  return (
    <div>
      <div className="tickets-panel">
        <div className="tickets-panel-content">
          <Spacing vertical size="S">
            <PaymentImage type="paycash-logo" />
            <Text size="S">{t('text.steps_to_continue_with_payment')}:</Text>
            <div className="tickets-panel-text">
              <Spacing vertical size="S">
                <Text size="S">
                  <Trans i18nKey="text.paycash_payment_instructions_first" t={t}>
                    text<b>text</b>text
                  </Trans>
                </Text>
                <Text size="S">
                  <Trans i18nKey="text.paycash_payment_instructions_second" t={t}>
                    text<b>text</b>text
                  </Trans>
                </Text>
                <Text size="S">{t('text.paycash_payment_instructions_third')}</Text>
                <Text size="S">{t('text.paycash_payment_instructions_fourth')}</Text>
                <Text size="S" weight="bold">
                  {t('text.paycash_fee_note')}
                </Text>
              </Spacing>
            </div>
          </Spacing>
        </div>
        <div className="tickets-panel-content right-content">
          <div className="right-content-info">
            <Spacing vertical size="L">
              <Spacing vertical size="S">
                <Text size="S" textAlign="center" color="grayMedium">
                  {t('purchase:label.payment_reference')}:
                </Text>
                <Text size="XL" weight="bold" textAlign="center">
                  {reference}
                </Text>
              </Spacing>
              <Spacing vertical size="S">
                <Text size="S" textAlign="center" color="grayMedium">
                  {t('label.payday_limit')}:
                </Text>
                <Text size="XL" weight="bold" textAlign="center">
                  {paydayLimitDisplay}
                </Text>
              </Spacing>
            </Spacing>
          </div>
          <div className="right-content-price">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.waiting_for_your_payment')}:
              </Text>
              <div className="right-content-price-amount">
                <Text size="XL" weight="bold" textAlign="center">
                  {amountDisplay}
                </Text>
              </div>
            </Spacing>
          </div>
        </div>
      </div>
      <PaycashLogos />
    </div>
  );
};

PaycashPaymentInfo.propTypes = {
  amount: PropTypes.string.isRequired,
  paydayLimit: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};

export default PaycashPaymentInfo;
