import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@reservamos/elements';
import petBadge from 'images/badges/pet.svg';
import adjacentBadge from 'images/badges/adjacent-seat.svg';
import womanBadge from 'images/brands-unique/gfa/badge-woman.svg';
import i18n from 'i18next';
import 'styles/components/SeatsLegend/NewSeatsLegend';
import NewSeatLegendItem from 'ui/atoms/NewSeatLegendItem';

/**
 * Check if the layout has a seat type
 * @param {Array} layout
 * @param {String|Function} check
 * @returns
 */
function hasSeatType(layout, check) {
  if (!layout) return false;

  return layout.some((row) => {
    return row.some((seats) => {
      return seats.some((seat) => {
        return typeof check === 'function' ? check(seat) : seat.category === check;
      });
    });
  });
}

/**
 * Component for rendering the seats legend.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.layout - The layout of the seats.
 * @param {boolean} props.showWomanLegends - Whether to show woman legends.
 * @param {boolean} props.showPetFriendly - Whether to show pet-friendly legends.
 * @param {boolean} props.isExchange - Whether it is an exchange.
 * @param {boolean} props.isHorizontal - Whether the layout is horizontal.
 * @param {number} props.occupied - The number of occupied seats.
 * @param {number} props.available - The number of available seats.
 * @param {number} props.selected - The number of selected seats.
 * @param {boolean} props.showRecommended - Whether to show recommended seats.
 */
function NewSeatsLegend({
  layout,
  showWomanLegends,
  showPetFriendly,
  isExchange,
  isHorizontal,
  occupied,
  available,
  selected,
  showRecommended,
  isBoxMobile,
}) {
  const { t } = useTranslation('seats');

  const hasdisabledSeats = hasSeatType(layout, 'seat_disabled');

  const hasAdjacentSeats =
    !isExchange && hasSeatType(layout, (seat) => seat.adjacentSeats && !seat.occupied);

  return (
    <div
      className={`new-seats-legend-container ${
        isHorizontal ? 'new-seats-legend-horizontal' : 'new-seats-legend-box'
      } ${isBoxMobile ? 'new-seats-legend-box-mobile' : ''}`}
    >
      {!isHorizontal && (
        <Text xsMobileSize="XXS" mobileSize="XS" size="S" textAlign="center" weight="semibold">
          {i18n.t('seats:single_deck_bus')}
        </Text>
      )}

      <NewSeatLegendItem
        text={t('seat', { context: 'available', count: available })}
        type="available"
        countSeats={available}
        isVertical={isHorizontal}
      />

      <NewSeatLegendItem
        text={t('seat', { context: 'selected', count: selected })}
        type="selected"
        countSeats={selected}
        isVertical={isHorizontal}
      />

      <NewSeatLegendItem
        text={t('seat', { context: 'occupied', count: occupied })}
        type="occupied"
        countSeats={occupied}
        isVertical={isHorizontal}
      />

      {(showRecommended || showPetFriendly || showWomanLegends) && (
        <Box borderColor="grayBorder" border={isHorizontal ? 'left' : 'bottom'} />
      )}

      {showRecommended && (
        <NewSeatLegendItem
          text={t('seat', { context: 'recommended' })}
          type="available"
          isVertical={isHorizontal}
          isRecommended
        />
      )}

      {showPetFriendly && (
        <NewSeatLegendItem
          text={t('passengers:type.pet_friendly')}
          iconType={petBadge}
          isVertical={isHorizontal}
        />
      )}

      {hasAdjacentSeats && (
        <NewSeatLegendItem
          text={t('adjacent_seat.label')}
          iconType={adjacentBadge}
          isVertical={isHorizontal}
        />
      )}

      {showWomanLegends && (
        <NewSeatLegendItem
          text={t('seat', { context: 'woman' })}
          iconType={womanBadge}
          isVertical={isHorizontal}
        />
      )}

      {hasdisabledSeats && (
        <NewSeatLegendItem text="Deshabilitado" type="disabled" isVertical={isHorizontal} />
      )}
    </div>
  );
}

NewSeatsLegend.propTypes = {
  layout: PropTypes.array,
  showWomanLegends: PropTypes.bool,
  showPetFriendly: PropTypes.bool,
  isExchange: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  occupied: PropTypes.number,
  available: PropTypes.number,
  selected: PropTypes.number,
  showRecommended: PropTypes.bool,
  isBoxMobile: PropTypes.bool,
};

NewSeatsLegend.defaultProps = {
  layout: [],
  showWomanLegends: false,
  showPetFriendly: false,
  isExchange: false,
  isHorizontal: false,
  occupied: 0,
  available: 0,
  selected: 0,
  showRecommended: false,
  isBoxMobile: false,
};

export default NewSeatsLegend;
