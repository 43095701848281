import React from 'react';
import PropTypes from 'prop-types';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';
import OpenTicketWrapper from './OpenTicketWrapper';
import OpenTicketResult from './OpenTicketResult';
import OpenTicketButton from './OpenTicketButton';

/**
 * Renders the OpenTicketCTA component.
 * @param {Object} props - The component props.
 * @param {Object} props.openTrip - The open trip object.
 * @param {string} props.way - The way of the trip.
 * @param {boolean} props.isRoundTrip - Indicates if it is a round trip.
 * @param {function} props.onSelect - The function to handle trip selection.
 * @param {boolean} props.showWalletPoints - Indicates if the wallet price should be shown.
 * @param {string} props.walletType - The wallet type.
 * @returns {JSX.Element|null} The rendered OpenTicketCTA component.
 */
const OpenTicketCTA = ({ openTrip, way, isRoundTrip, onSelect, showWalletPoints, walletType }) => {
  const features = useWhitelabelFeatures();
  const isNewResultsDesign = useGrowthBookFeatureValue('new_results_design');

  if (!openTrip) return null;

  const roundPricingKey = way === 'departure' ? 'departureRoundTripPricing' : 'roundTripPricing';
  const pricingKey = isRoundTrip ? roundPricingKey : 'pricing';

  const { total, providerDiscount: tripDiscount, walletPoints } = openTrip[pricingKey];

  const useWalletPoints = features.CAN_USE_POINTS_OPEN_TICKET && showWalletPoints;

  if (!isNewResultsDesign && features.SHOW_OPEN_TICKET_ON_RESULTS) {
    return (
      <OpenTicketResult
        trip={openTrip}
        providerDiscount={tripDiscount}
        total={total}
        selectTrip={onSelect}
        provider={openTrip.line.logoUrl}
        providerName={openTrip.line.name}
        way="departure"
        windowTicket={tripDiscount ? total + tripDiscount.amount : total}
        walletPoints={useWalletPoints ? walletPoints : 0}
      />
    );
  }
  if (isNewResultsDesign || features.SHOW_OPEN_TICKET_BUTTON) {
    return (
      <OpenTicketWrapper>
        <OpenTicketButton
          trip={openTrip}
          providerDiscount={openTrip[pricingKey].providerDiscount}
          total={total}
          selectTrip={onSelect}
          walletPoints={walletPoints}
          showWalletPoints={useWalletPoints}
          walletType={walletType}
        />
      </OpenTicketWrapper>
    );
  }
  return null;
};

OpenTicketCTA.propTypes = {
  openTrip: PropTypes.object.isRequired,
  way: PropTypes.string.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  showWalletPoints: PropTypes.bool,
  walletType: PropTypes.string,
};

export default OpenTicketCTA;
