import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cookie from 'js-cookie';
import { formatCurrency } from 'utils/Helpers';
import { Text, Spacing } from '@reservamos/elements';
import ModalPopUp from 'ui/atoms/ModalPopUp';
import {
  trackDiscountModalOpened,
  trackDiscountModalClosed,
  trackDiscountModalNewSearch,
} from '../../../../metrics/user-analytics/search';
import discountETN from '../../../../images/brands-unique/etn/discountETN.png';
import discountGFA from '../../../../images/brands-unique/gfa/early-discount.png';

const COOKIE_NAME = 'isDiscountModalOpened';

const iconTypes = {
  etn: discountETN,
  gfa: discountGFA,
  default: 'emojiSparkles',
};

const ModalContentDiscount = ({
  isRoundTrip,
  anticipation,
  percent,
  minPrice,
  goToAnticipationSearch,
  price,
  search,
  analyticSearchData,
}) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const [visible, setVisible] = useState(false);

  const showModal = () => setVisible(true);

  useEffect(() => {
    const isPreviouslyOpened = Boolean(cookie.get(COOKIE_NAME));

    if (!isPreviouslyOpened) {
      showModal();
      trackDiscountModalOpened(analyticSearchData);
      cookie.set(COOKIE_NAME, true, { expires: 7 });
    }
  }, [analyticSearchData]);

  if (isRoundTrip) return null;

  const title = `Disfruta hasta ${percent}% de descuento`;

  const closeModal = () => {
    setVisible(false);
    trackDiscountModalClosed(analyticSearchData);
  };

  if (!visible) return null;

  return (
    <ModalPopUp
      modalTitle={title}
      modalIconType={iconTypes[env.brand] ?? iconTypes.default}
      buttonPrimaryText="Compra con anticipación"
      buttonSecondaryText="No estoy interesado"
      onClickPrimary={() => {
        trackDiscountModalNewSearch(analyticSearchData);
        goToAnticipationSearch(search);
      }}
      onClickSecondary={() => closeModal()}
    >
      <Spacing vertical size="S">
        <Text fontFamily="secondary" size="S">
          Compra con hasta {anticipation} días de anticipación y obtén hasta un
          <strong> {percent}%</strong> de descuento en tu viaje. Aplica restricción de horarios.
        </Text>
        <Text fontFamily="secondary" size="S">
          Precio por adulto de tu búsqueda:
          <strong>{formatCurrency(minPrice, 0)}</strong>
        </Text>
        <Text fontFamily="secondary" size="S">
          Precio por adulto al planificar tu viaje:
          <strong>{formatCurrency(price, 0)}</strong>
        </Text>
        <Text fontFamily="secondary" size="S">
          Tu ahorro:
          <strong>{formatCurrency(minPrice - price, 0)}</strong>
        </Text>
      </Spacing>
    </ModalPopUp>
  );
};

ModalContentDiscount.propTypes = {
  isRoundTrip: PropTypes.bool.isRequired,
  minPrice: PropTypes.number.isRequired,
  percent: PropTypes.number,
  price: PropTypes.number,
  search: PropTypes.string,
  anticipation: PropTypes.number.isRequired,
  goToAnticipationSearch: PropTypes.func.isRequired,
  analyticSearchData: PropTypes.shape({
    destination: PropTypes.shape({
      cityName: PropTypes.string,
      cityId: PropTypes.string,
      id: PropTypes.string,
    }),
    origin: PropTypes.shape({
      cityName: PropTypes.string,
      cityId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

export default ModalContentDiscount;
