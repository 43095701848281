/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Spacing, Modal, Text, Icon, LoaderPulse } from '@reservamos/elements';
import AlertButton from 'ui/atoms/AlertButton';
import TripPriceAlertForm from 'components/TripPriceAlertForm';
import { useSelector } from 'react-redux';
import { trackTripPriceAlerts } from 'metrics/user-analytics/search';
import { PRICE_ALERT_CREATED, PRICE_ALERT_FAILED, PRICE_ALERT_OPENED } from 'constants/TrackEvents';
import getSearchAnalyticsData from 'utils/search/getSearchAnalyticsData';
import { isCurrentDate } from 'utils/date';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import getSearchStep from 'utils/search/getSearchStep';
import LocalStorageManager from 'utils/localStorageManager';
import 'styles/components/AlertSubscriptionModal';
import RouteTripAlertPrice from 'ui/molecules/RouteTripAlertPrice';
import BubbleAlertMessage from 'ui/atoms/BubbleAlertMessage';
import isPriceAlertEnabled from 'utils/search/priceAlerts';
import userPreferences from '../services/userPreferences';

/**
 * Renders the alert subscription modal.
 */
function AlertSubscriptionModal() {
  const { t } = useTranslation();
  const savedAlertsLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActive] = useState(false);
  const [alertsSaved, setAlertsSaved] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { search, cities, terminals } = useSelector((state) => state);
  const trips = useSelector((state) => state.trips.toJS());
  const places = { ...cities, ...terminals };
  const { departure, destinationId, originId, departureDate, ...restSearch } = search.toJS();
  const { pathname } = useLocation();
  const { returnDate } = useParams();
  const departureId = departure.id;
  const returnId = restSearch.return.id;
  const searchStep = getSearchStep(pathname);
  const roundTrip = Boolean(returnDate);
  let currentTripList;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(i18n.t('errors:field_email_valid'))
      .required(i18n.t('errors:field_email')),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, i18n.t('exchange:validation.only_numbers'))
      .min(8, i18n.t('exchange:validation.min_digits', { digits: 8 }))
      .max(12, i18n.t('exchange:validation.max_digits', { digits: 12 })),
  });

  /**
   * Checks if a given date has expired.
   * @param {string} dateStr - The date string to check.
   * @returns {boolean} True if the date has expired, false otherwise.
   */
  const dateExpired = (dateStr) => {
    const dateStamp = new Date(dateStr);
    const currentDate = new Date();

    return dateStamp < currentDate;
  };

  /**
   * Checks and removes expired price alerts from the cache.
   */
  const checkAndRemoveCache = () => {
    const cache = LocalStorageManager.get('priceAlerts');
    if (cache) {
      Object.keys(cache).forEach((key) => {
        if (dateExpired(cache[key].date)) {
          delete cache[key];
        }
      });
      if (cache[`${departure.departs}_${originId}_${destinationId}`]) {
        setAlertsSaved(cache[`${departure.departs}_${originId}_${destinationId}`]);
        setIsActive(true);
      }

      LocalStorageManager.set('priceAlerts', cache);
    }
  };
  useEffect(() => {
    if (!savedAlertsLoaded.current && originId && destinationId) {
      checkAndRemoveCache();
      savedAlertsLoaded.current = true;
    }
  }, [originId, destinationId, departure.departs]);

  if (searchStep === 'departures') {
    currentTripList = trips[departureId].buses.trips;
  }

  if (searchStep === 'returns' && roundTrip) {
    currentTripList = trips[returnId].buses.trips;
  }
  /**
   * Handle the event modal.
   * @param {string} eventName - The name of the event.
   */
  const handleEventModal = (eventName, values = {}) => {
    const { search: searchData } = getSearchAnalyticsData({ search, places });
    trackTripPriceAlerts(eventName, searchData, false, {
      phoneNumber: values.phone || '',
      email: values.email || '',
      from: values.from || '',
      lowestPrice: values.lowestPrice || '',
    });
  };

  /**
   * Saves the price alert cache.
   * @param {Object} values - The values to be saved in the cache.
   */
  const savePriceAlertCache = (values) => {
    const cache = LocalStorageManager.get('priceAlerts') ?? {};
    cache[`${departure.departs}_${originId}_${destinationId}`] = {
      ...values,
      date: departure.departs,
    };
    if (cache[`${departure.departs}_${originId}_${destinationId}`]) {
      setIsActive(true);
      setAlertsSaved(cache[`${departure.departs}_${originId}_${destinationId}`]);
    }
    LocalStorageManager.set('priceAlerts', cache);
  };

  /**
   * Handles the creation of a trip with the given values.
   * @param {Object} values - The values for the trip.
   * @param {String} values.email - the email user.
   * @param {string} values.phone - the phone user
   */
  const createTripAlert = async (values) => {
    try {
      setIsLoading(true);
      const cacheData = { ...values };
      const lowestPrice = currentTripList.reduce((min, obj) => {
        return obj.pricing.total < min ? obj.pricing.total : min;
      }, currentTripList[0].pricing.total);
      const response = await userPreferences.createTripPriceAlerts({
        ...values,
        seenPrice: lowestPrice,
        destination: destinationId,
        origin: originId,
        departureDate,
      });
      if (!response.error) cacheData.id = response.id;
      handleEventModal(response.error ? PRICE_ALERT_FAILED : PRICE_ALERT_CREATED, {
        ...values,
        lowestPrice,
      });
      savePriceAlertCache(cacheData);
      setIsActive(true);
    } catch (error) {

      handleEventModal(PRICE_ALERT_FAILED);
    } finally {
      setIsLoading(false);
      userPreferences.createAnonymousProfile(values);
    }
  };

  /**
   * Shows the modal and tracks the event.
   */
  const handleShowModal = (from) => () => {
    setShowModal(true);
    handleEventModal(PRICE_ALERT_OPENED, { from });
  };

  if (isCurrentDate(departure.departs) || !isPriceAlertEnabled()) {
    return null;
  }

  return (
    <>
      <BubbleAlertMessage onClick={handleShowModal('banner')} isActivated={isActivated} />
      <AlertButton alertCount={1} onClick={handleShowModal('bell')} isActivated={isActivated} />
      {showModal && (
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: '',
            phone: '',
          }}
          onSubmit={(values) => {
            createTripAlert(values);
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, setFieldValue }) => {
            const hasError = Boolean(Object.keys(errors).length);
            return (
              <Modal
                title={t(
                  `search:title.${
                    isActivated ? 'notifications' : 'now_you_can_active_your_price_alerts'
                  }`,
                )}
                footer={
                  !isActivated && (
                    <Button
                      isDisabled={isLoading}
                      onClick={handleSubmit}
                      fullWidth
                      text={
                        hasError ? errors.email || errors.phone : t('search:button.active_alert')
                      }
                      iconColor={hasError && 'white'}
                      iconType={hasError && 'BlockNotAvailable'}
                      variant="primary"
                      padding="XL"
                      mobilePadding="XL"
                    />
                  )
                }
                hasBlushing
                removeContentPadding
                contentHeight="auto"
                responsiveSize="auto"
                onCloseModal={() => setShowModal(false)}
              >
                <div className="alert-subscription-modal">
                  {isLoading ? (
                    <LoaderPulse size="S" isAccent />
                  ) : (
                    <Spacing size="S" vertical>
                      <Spacing alignItems="flex-start">
                        <Box paddingHorizontal="S" paddingVertical="S" borderRadius="L">
                          <Spacing alignItems="center" justifyContent="center">
                            <Icon
                              size="M"
                              type={isActivated ? 'BellAlertActive' : 'BellAlert'}
                              color={isActivated ? 'success' : 'primary'}
                            />
                          </Spacing>
                        </Box>

                        <Spacing size="XS" vertical>
                          <Spacing justifyContent="space-between" alignItems="center">
                            <Text weight="semibold">
                              {t(
                                `search:label.${
                                  isActivated ? 'active_alert' : 'receive_lowest_price_alert'
                                }`,
                              )}
                            </Text>
                          </Spacing>

                          {isActivated ? (
                            <Text color="grayMedium" mobileSize="S">
                              {t('search:text.now_you_can_receive_your_price_alerts')}
                            </Text>
                          ) : (
                            <Spacing size="XS" vertical>
                              <RouteTripAlertPrice
                                iconType="Pin"
                                city={terminals[originId].cityName}
                              />
                              <RouteTripAlertPrice
                                iconType="PinOutlined"
                                city={terminals[destinationId].cityName}
                              />
                            </Spacing>
                          )}
                        </Spacing>
                      </Spacing>

                      <TripPriceAlertForm
                        values={!isActivated ? values : alertsSaved}
                        handleChange={handleChange}
                        isActivated={isActivated}
                        errorMessage={errors}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </Spacing>
                  )}
                </div>
              </Modal>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default AlertSubscriptionModal;
