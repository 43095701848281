import React from 'react';
import PropTypes from 'prop-types';
import { Gap, Icon, Spacing, Text, Box } from '@reservamos/elements';
import 'styles/components/SelectedSeats';
import { useTranslation } from 'react-i18next';
import petBadge from 'images/badges/pet.svg';

/**
 * It shows the selected seats on the bus diagram page.
 * Also displays information about pet friendly, women seats and the legend.
 * @param {*} props - Props
 * @param {boolean} props.hideLegend - Should show or hide the legend
 * @param {boolean} props.showPetFriendly - Should show or hide the pet friendly information
 * @param {function} props.handlePetDialogShowPress - Callback when the pet friendly information is pressed
 * @returns - SelectedSeats component.
 */
const HeaderDiagramBus = ({ legend, hideLegend, showPetFriendly, handlePetDialogShowPress }) => {
  const { t } = useTranslation('seats');
  return (
    <div className="selected-seats">
      <Spacing vertical size="S">
        <Text weight="bold" fontFamily="secondary">
          {legend || t('your_seats')}
        </Text>
        {hideLegend || <Text size="S">{t('chosee_to_seats')}</Text>}
        {showPetFriendly && (
          <Gap alignItems="center">
            <a onClick={handlePetDialogShowPress}>
              <Box
                paddingHorizontal="XS"
                paddingVertical="XS"
                border="all"
                borderColor="primary"
                borderRadius="L"
                bgColor=""
              >
                <Gap alignItems="center">
                  <Icon type={petBadge} size="S" />
                  <Text weight="semibold" size="S">
                    {t('pet_dialog.btn_info')}
                  </Text>
                </Gap>
              </Box>
            </a>
          </Gap>
        )}
      </Spacing>
    </div>
  );
};

HeaderDiagramBus.propTypes = {
  hideLegend: PropTypes.bool,
  showPetFriendly: PropTypes.bool,
  handlePetDialogShowPress: PropTypes.func.isRequired.isRequired,
  legend: PropTypes.string,
};

HeaderDiagramBus.defaultProps = {
  hideLegend: false,
  showPetFriendly: false,
  legend: '',
};

export default HeaderDiagramBus;
