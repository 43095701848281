// @ts-check
import AdyenCheckout from '@adyen/adyen-web';

// Type definitions for Adyen

function Adyen() {
  let adyenInstance = null;

  function getAdyen() {
    return adyenInstance;
  }

  function getAdyenInstance() {
    const instances = adyenInstance.components;
    const lastInstance = instances[instances.length - 1];
    const validInstances = instances.filter((instance) => instance.isValid);

    if (validInstances.length > 0) {
      const lastValidInstance = validInstances[validInstances.length - 1];

      lastInstance.setState(lastValidInstance.state);
      return lastInstance;
    }

    return lastInstance;
  }

  /**
   * Loads Adyen instance
   * @function initialize
   * @param {AdyenConfig}
   * @returns {void}
   *
   * @example
   * ```js
   * adyen.initialize({
   *  clientKey: '100000000000123456789',
   * });
   * ```
   */
  const initialize = (config) => {
    const { enabled, environment, publicKey: clientKey } = config.adyen || {};
    if (!enabled) return;
    if (!Adyen) return;

    adyenInstance = new AdyenCheckout({
      locale: config.lang.current || config.lang.default,
      clientKey,
      environment,
    });
  };

  return { initialize, getAdyen, getAdyenInstance };
}

const adyen = Adyen();
export default adyen;
