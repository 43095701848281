import { connect } from 'react-redux';
import { formatCurrency } from 'utils/Helpers';
import getPassengerCount from 'utils/purchase/getPassengerCount';
import { getCurrencySuffix } from 'utils/currency';
import DefaultPurchasePricing from './PurchasePricing';

const mapStateToProps = (state) => {
  const { purchase } = state;
  const {
    departs,
    returns,
    roundTrip,
    passengers,
    extras,
    insuranceAmount,
    markup,
    total,
    monthlySelectedPlan,
    selectedInstallmentsPlan,
    selectedPaymentOption,
    openTicket,
    externalCouponCode,
    carbonOffsetAmount,
    exchangedTotal,
  } = purchase.toJS();
  const passengerCount = getPassengerCount(
    purchase.get('passengers'),
    purchase.get('passengerSelection'),
  );
  const hasParent = purchase.get('parentId');

  const hasMixedTrips =
    departs.transportType === 'mixed' || (roundTrip && returns.transportType === 'mixed');

  const titleHeaderDescription = hasParent ? 'payment_difference' : 'payment_total';
  let titleFooterDescription = '';
  let titleTotal = Number(total);
  let installmentsFee = 0;

  if (selectedPaymentOption === 'credit_card' && monthlySelectedPlan > 1) {
    titleFooterDescription = `total ${formatCurrency(
      selectedInstallmentsPlan.total,
      2,
    )} ${getCurrencySuffix()}`;
    titleTotal = Number(selectedInstallmentsPlan.monthlyPayment);
    installmentsFee = Number(selectedInstallmentsPlan.fee);
  }

  return {
    selectedInstallmentsPlan,
    monthlySelectedPlan,
    selectedPaymentOption,
    departs,
    hasMixedTrips,
    passengers,
    passengerCount,
    titleFooterDescription,
    titleHeaderDescription,
    titleTotal,
    exchangedTotal,
    returns,
    roundTrip,
    externalCouponCode,
    extras: {
      carbon: Number(carbonOffsetAmount),
      installmentsFee: Number(installmentsFee),
      insurance: Number(insuranceAmount),
      markup: Number(markup),
      total: Number(extras) + Number(installmentsFee),
    },
    openTicket,
  };
};

export default connect(mapStateToProps)(DefaultPurchasePricing);
