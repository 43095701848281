/**
 * Types of cybersource error codes
 * this should be passend in the setError action to show an error message
 * */
const cybersourceErrorCodes = {
  contact_processor: 'cybersource.contact_processor',
  cv_failed: 'cybersource.cv_failed',
  cvn_not_match: 'cybersource.cvn_not_match',
  exceeds_credit_limit: 'cybersource.exceeds_credit_limit',
  expired_card: 'cybersource.expired_card',
  insufficient_fund: 'cybersource.insufficient_fund',
  invalid_account: 'cybersource.invalid_account',
  invalid_cvn: 'cybersource.invalid_cvn',
  processor_declined: 'cybersource.processor_declined',
  default: 'cybersource.default_error',
};

export default cybersourceErrorCodes;
