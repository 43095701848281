import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@reservamos/elements';

const DownloadButton = ({ label, href }) => {
  return (
    <LinkButton
      text={label}
      variant="success"
      iconType="Download"
      iconColor="white"
      href={href}
      padding="M"
      newTab
      borderRadius
      fullWidthOnSmall
    />
  );
};

DownloadButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default DownloadButton;
