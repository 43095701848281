import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spacing } from '@reservamos/elements';
import CheckList from '../../../ui/atoms/CheckList';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const ConektaStoreForm = ({ isOpenTicket }) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');

  const defaultLabels = [
    t('label.pay_in_oxxo'),
    t('text.you_will_receive_the_reference_number_oxxo'),
    t('text.we_send_an_email_when_paying'),
  ];

  // TODO: This needs to work for all tenants and it seems too specific
  const gfa = [...defaultLabels];
  if (!isOpenTicket) gfa.push(t('text.secure_seat_until_payment'));
  const rollbits = [...defaultLabels];
  if (!isOpenTicket) rollbits.push(t('text.secure_seat_until_payment'));

  const labelsType = {
    gfa,
    'roll-bits': rollbits,
    'default': defaultLabels,
  };

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <PaymentImage type="oxxo-logo" />

        <CheckList
          title={t('label.pay_with_cash_in_oxxo')}
          labels={labelsType[env.brand] ?? labelsType.default}
          disclaimer={t('text.oxxo_commission')}
        />
      </Spacing>
    </div>
  );
};

ConektaStoreForm.propTypes = {
  isOpenTicket: PropTypes.bool.isRequired,
};

export default ConektaStoreForm;
