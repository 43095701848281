import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Text } from '@reservamos/elements';
import PaymentImage from 'ui/atoms/PaymentImage';
import PricingRow from '../../../ui/atoms/PricingRow';

/**
 * Renders the payment details for an Evertec transaction.
 * This component displays a list of payment details including the transaction reference,
 * amount, date, and status. Each detail is presented in a row with its respective label
 * and value. The transaction date is formatted in DD-MM-YYYY format. The status is
 * translated based on its value using the i18n translation files.
 *
 * @param {Object} props - The component props.
 * @param {string} props.transactionDate - The date of the transaction.
 * @param {string} props.status - The payment status.
 * @param {string} props.reference - The purchase token.
 * @param {number} props.amount - The payment amount.
 * @returns {React.Component} A React component that displays the Evertec payment details.
 */
const EvertecPaymentDetails = ({ transactionDate, status, reference, amount }) => {
  const { t } = useTranslation('payment');

  return (
    <div className="purchases-section-side">
      <Text weight="bold">{t('evertec_payment_details.title')}</Text>
      <div className="purchase-sidebar-pricing">
        <div className="purchase-pricing">
          <div className="section-content list-style">
            {Boolean(reference) && (
              <PricingRow
                label={t('evertec_payment_details.reference')}
                price={reference}
                isNumber
              />
            )}
            {Boolean(amount) && (
              <PricingRow label={t('evertec_payment_details.amount')} price={amount} />
            )}
            {Boolean(true) && (
              <PricingRow
                label={t('evertec_payment_details.transaction_date')}
                price={moment(transactionDate).format('DD-MM-YYYY')}
                isNumber
              />
            )}
            {Boolean(status) && (
              <PricingRow
                label={t('evertec_payment_details.status')}
                price={t(`evertec_payment_status.${status}`)}
                isNumber
              />
            )}
          </div>
        </div>
      </div>
      <PaymentImage type="evertec" />
    </div>
  );
};

EvertecPaymentDetails.propTypes = {
  transactionDate: PropTypes.string,
  status: PropTypes.string,
  reference: PropTypes.string,
  amount: PropTypes.number,
};

EvertecPaymentDetails.defaultProps = {
  transactionDate: null,
  status: null,
  reference: null,
  amount: null,
};

export default EvertecPaymentDetails;
