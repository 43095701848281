import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@reservamos/elements';
import { useSelector } from 'react-redux';

/**
 * Component for rendering a filter result badge that indicates the filter applied in the result.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.minimalDesign - Whether to render the badge in a minimal design.
 * @param {node} props.children - The text to be displayed in the badge.
 * @returns {JSX.Element} The rendered component.
 */
const FilterResultBadge = ({ children, minimalDesign }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  return minimalDesign ? (
    <Text size="S" mobileSize="XS" weight="bold" color="success">
      {children}
    </Text>
  ) : (
    <Box
      bgColor={isFlat ? 'success' : 'white'}
      alphaBg="S"
      paddingHorizontal="XS"
      paddingVertical="XXS"
      borderRadius="S"
      borderColor={isFlat ? '' : 'success'}
      border={isFlat ? '' : 'all'}
    >
      <Text elementType="span" color="success" size="S" weight="semibold" whiteSpace="nowrap">
        {children}
      </Text>
    </Box>
  );
};

FilterResultBadge.propTypes = {
  minimalDesign: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default FilterResultBadge;
