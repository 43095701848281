import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import 'styles/components/purchase/SendEmail';
import { Button, Text, Spacing } from '@reservamos/elements';
import { fieldRenderer } from '../../../utils/formRenderers';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const SendEmail = ({ handleSubmit, sendEmail, status, token }) => {
  const { t } = useTranslation('purchase');
  return (
    <form onSubmit={handleSubmit(({ email }) => sendEmail(token, email))}>
      <div className="email-content">
        <Text size="S">{t('resend_email')}</Text>
        <Spacing alignItems="baseline" fullWidth>
          <Field
            className="form-input"
            component={fieldRenderer}
            name="email"
            placeholder="Email"
            type="text"
          />
          <Button buttonType="submit" variant="accent" text={t('general:button.send')} withHeight />
        </Spacing>
        <div className="feedback">
          <Loader isLoading={status === 'sending'} />
          {Boolean(status) && (
            <p className={`feedback-${status === 'sent' ? 'success' : 'error'}`}>
              {t('email_status', { context: status })}
            </p>
          )}
        </div>
      </div>
    </form>
  );
};

SendEmail.propTypes = propTypes;

export default SendEmail;
