import React from 'react';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';

const withWhitelabelTheme = (WrappedComponent) => {
  return (props) => {
    const whitelabelTheme = useWhitelabelTheme();
    return <WrappedComponent {...props} whitelabelTheme={whitelabelTheme} />;
  };
};

export default withWhitelabelTheme;
