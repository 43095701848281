import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { applyCoupon, resetCouponForm, deleteCoupon } from '@/actions/search';
import stringifyQueryString from 'utils/queryString/stringifyQueryString';
import useQuery from 'hooks/useQuery';
import { searchCouponTracker } from 'metrics/user-analytics/search';

function useData() {
  const data = useSelector(({ search }) => ({
    couponCode: search.get('couponCode'),
    couponLoading: search.get('couponLoading'),
    couponCodeDeleted: search.get('couponCodeDeleted'),
  }));

  const paramsCouponCode = useQuery('coupon').coupon;
  const { couponCode, couponLoading, couponCodeDeleted } = data;

  return {
    couponCode: couponCode || paramsCouponCode,
    couponLoading,
    couponCodeDeleted,
  };
}

function useCouponSelection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  function handleCouponSubmit(couponCode, showSuccess = true) {
    applyCoupon(couponCode, showSuccess, dispatch);
  }

  function handleCouponAppliedSuccesfuly(couponCode) {
    history.push({
      pathname: location.pathname,
      search: stringifyQueryString({ coupon: couponCode }, location.search),
    });
    searchCouponTracker(couponCode);
  }

  function handleDeleteCoupon() {
    dispatch(deleteCoupon());
    dispatch(resetCouponForm());
    history.push({
      pathname: location.pathname,
      search: stringifyQueryString({ coupon: '' }, location.search),
    });
  }

  function handleCouponInvalidOnLoad() {
    dispatch(resetCouponForm());
    history.push({
      pathname: location.pathname,
      search: stringifyQueryString({ coupon: '' }, location.search),
    });
  }

  return {
    handleCouponSubmit,
    handleCouponAppliedSuccesfuly,
    handleDeleteCoupon,
    handleCouponInvalidOnLoad,
  };
}

export default function useSearchCouponSelection() {
  const { couponCode, couponLoading, couponCodeDeleted } = useData();
  const {
    handleCouponSubmit,
    handleCouponAppliedSuccesfuly,
    handleDeleteCoupon,
    handleCouponInvalidOnLoad,
  } = useCouponSelection();
  return {
    couponCode,
    handleCouponSubmit,
    handleCouponAppliedSuccesfuly,
    handleDeleteCoupon,
    couponLoading,
    couponCodeDeleted,
    handleCouponInvalidOnLoad,
  };
}
