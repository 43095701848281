/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import evertec from 'payments/core/engines/evertec';
import { trackEvent } from 'user-analytics';
import { getExpirationTime } from '../../utils/purchase/timer';

const propTypes = {
  expiresAt: PropTypes.number,
  currentTime: PropTypes.number,
  purchaseToken: PropTypes.string,
  isExpired: PropTypes.bool,
  expirationExtension: PropTypes.object,
  expirePurchase: PropTypes.func.isRequired,
};

/**
 * A higher-order component that wraps another component and provides a timer feature for purchases.
 * @param {React.ComponentType} WrappedComponent - The component to be wrapped.
 * @returns {React.ComponentType} - The wrapped component with the timer feature.
 */
export default function withPurchaseTimer(WrappedComponent) {
  /**
   * PurchaseTimer component.
   * @param {*} props - The component props.
   * @returns JSX.Element - The wrapped component with the timer feature.
   */
  function PurchaseTimer(props) {
    const { features } = useSelector((state) => state.whitelabelConfig);

    const [remainingTime, setRemainingTime] = useState(0);
    const [expireMinutes, setExpireMinutes] = useState(0);
    const timerId = useRef();
    const initialExpiresAtLocal = useRef();
    const expiresExtended = useRef();
    const {
      expiresAt,
      currentTime,
      purchaseToken,
      isExpired,
      expirationExtension,
      expirePurchase,
    } = props;

    /**
     * Function to track when the purchse expires
     */
    const trackExpirePurchase = () => {
      trackEvent('Timer Purchase Expired', {
        currentTime: moment(currentTime).format('MMMM DD YYYY, h:mm:ss a'),
        remainingTime,
        pruchaseExpiresAtMoment: moment(expiresAt).format('MMMM DD YYYY, h:mm:ss a'),
        pruchaseExpiresAt: expiresAt,
        purchaseToken,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    };

    /**
     * Function to start the timer
     */
    const startTimer = () => {
      const { PURCHASE_TIMER_ENABLED } = features;
      if (!PURCHASE_TIMER_ENABLED || (!expiresAt && !initialExpiresAtLocal.current)) return;
      if (timerId.current) return;
      /*
       * A initial expires local time is set to calculate the remaining time even
       * if the javascript timer is paused becuase of the browser tab is not active
       */
      if (!initialExpiresAtLocal.current) {
        const remaining = getExpirationTime(expiresAt, currentTime, isExpired);
        initialExpiresAtLocal.current = Date.now() + remaining * 1000;
        setExpireMinutes(remaining);
      }
      timerId.current = setInterval(() => {
        const localTime = Date.now();
        const timeDiff = getExpirationTime(initialExpiresAtLocal.current, localTime, isExpired);
        if (timeDiff > 0) {
          setRemainingTime(timeDiff);
        } else {
          setRemainingTime(0);
          clearInterval(timerId.current);
          trackExpirePurchase();
          expirePurchase();
          // Close the evertec modal if there is an evertec instance
          const evertecInstance = evertec.getInstance();
          evertecInstance?.closeFrame();
        }
      }, 1000);
    };

    /** Function to clear the timer */
    const clearTimer = () => {
      initialExpiresAtLocal.current = null;
      clearInterval(timerId.current);
      timerId.current = null;
    };

    useEffect(() => {
      if (timerId) {
        clearTimer();
      }
      startTimer();
    }, [expiresAt]);

    useEffect(() => {
      if (expirationExtension?.extendedAt && !expiresExtended.current) {
        initialExpiresAtLocal.current = null;
        clearInterval(timerId.current);
        timerId.current = null;
        expiresExtended.current = true;
        startTimer();
      }
    }, [expirationExtension]);

    useEffect(() => {
      return () => {
        clearInterval(timerId.current);
      };
    }, []);

    return (
      <WrappedComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        timerRemainingTime={remainingTime}
        expireMinutes={expireMinutes}
      />
    );
  }
  PurchaseTimer.propTypes = propTypes;

  return PurchaseTimer;
}
