import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from '@reservamos/elements';
import { EXCHANGE_TYPEAHEAD_CHANGE } from 'constants/ActionTypes';
import Options from './TypeaheadOptionsContainer';
import './Typeahead';
import ExchangeContext from '../context';

const ExchangeTypeahead = ({
  isDisabled,
  label,
  name,
  onChange,
  onKeyUp,
  value,
  url,
  hasError,
  errorMessage,
  handleBlur,
}) => {
  const dispatch = useDispatch();
  const [showItems, setShowItems] = useState(false);
  const containerRef = useRef(null);

  const handleOptionSelect = (display, slug) => {
    setShowItems(false);
    dispatch({ type: EXCHANGE_TYPEAHEAD_CHANGE, name, display, slug });
  };

  const handleClickOutside = (event) => {
    if (containerRef && !containerRef.current.contains(event.target)) {
      setShowItems(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <ExchangeContext.Provider value={{ handleOptionSelect }}>
        <Input
          autocomplete="off"
          id={name}
          label={label}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          onKeyUp={(e) => onKeyUp(name, e)}
          value={value}
          readOnly={isDisabled}
          hasError={hasError}
          errorMessage={errorMessage}
          onBlur={handleBlur}
        />
        <Options url={url} showItems={showItems} setShowItems={setShowItems} />
      </ExchangeContext.Provider>
    </div>
  );
};

const propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleBlur: PropTypes.func,
};

const defaultProps = {
  isDisabled: false,
};

ExchangeTypeahead.propTypes = propTypes;
ExchangeTypeahead.defaultProps = defaultProps;

export default ExchangeTypeahead;
