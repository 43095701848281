/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Loading from 'components/Loading';
import BrandLogo from './BrandLogo';

const propTypes = {
  loadingText: PropTypes.string,
  hasHeader: PropTypes.bool,
  absolute: PropTypes.bool,
  resultMessages: PropTypes.bool,
};

const defaultProps = {
  loadingText: 'loading',
  hasHeader: true,
  absolute: false,
  resultMessages: false,
};

class LoadingScreen extends Component {
  componentDidMount() {
    $(window).scrollTop(0);
  }

  render() {
    const { hasHeader, loadingText, absolute, resultMessages } = this.props;
    let header;

    if (hasHeader) {
      header = (
        <Header isFixed showLoyaltyHeader={false} hideLoyalty>
          <BrandLogo />
        </Header>
      );
    }

    return (
      <div className={`l-app ${absolute ? 'absolute' : ''}`}>
        {header}

        <Loading loadingText={loadingText} resultMessages={resultMessages} />
      </div>
    );
  }
}

LoadingScreen.propTypes = propTypes;

LoadingScreen.defaultProps = defaultProps;

export default LoadingScreen;
