import { useSelector } from 'react-redux';

/**
 * useForm hook
 *
 * This hook provides access to the form state from the Redux store.
 *
 * @returns {object} The form state from the Redux store
 */
function useForm() {
  const form = useSelector((state) => state.form);
  return form;
}

export default useForm;
