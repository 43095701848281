// @ts-check
import moment from 'moment';
/**
 * @typedef {import("moment").Moment} Moment
 */

const searchDateParamFormat = 'YYYY-MM-DD';
const legacySearchDateParamFormat = 'DD-MMM-YY';

/**
 *
 * Get moment object from a string date with format YYYY-MM-DD, used to parse
 * search date params (departure and return dates) from url
 * @param {string} searchDate - date string with format YYYY-MM-DD
 * @returns {Moment} moment object corresponding to date input
 *
 * @example
 *
 * ```js
 * const { departureDate } = match.params // '2019-01-01'
 * const departureMoment = getMomentFromSearchDateParam(departureDate)
 * // Do something with the moment object
 * departureMoment.format('DD-MM-YYYY') // '01-01-2019'
 * ```
 */
const getMomentFromSearchDateParam = (searchDate) => {
  const parsedDateMoment = moment(searchDate, searchDateParamFormat);
  if (parsedDateMoment.isValid()) {
    return parsedDateMoment;
  }
  // If the date is not valid, try to parse it with the legacy format
  return moment(searchDate, legacySearchDateParamFormat);
};

/**
 * Get date string with format YYYY-MM-DD from a moment object, used to create
 * search date params (departure and return dates) for url
 *
 * @param {Moment} momentObj - moment object
 * @returns {string} date string with format YYYY-MM-DD
 *
 * @example
 * ```js
 * const { departureMoment } = searchFormFields
 * const departureDate = getSearchDateParamFromMoment(departureMoment)
 * // Do something with the date string
 * console.log(departureDate) // '2019-01-01'
 * ```
 */
const getSearchDateParamFromMoment = (momentObj) => momentObj.format(searchDateParamFormat);

/**
 * Determines if param date is a valid date.
 * If param date is valid it is returned.
 * If not returns current day date.
 *
 * @param {string} date - date string with format DD-MM-YYYY
 * @returns {string} date string with format DD-MM-YYYY
 *
 * @example
 * ```js
 * const invalidDate = '32-01-2024'
 * const validDate = getValidDate(invalidDate)
 * console.log(validDate) // '25-03-2024'
 * ```
 */
const getValidDate = (date) => {
  const validateLegacyFormat = moment(date, legacySearchDateParamFormat, true).isValid();
  const validateSearchFormat = moment(date, searchDateParamFormat, true).isValid();

  if (date && (validateLegacyFormat || validateSearchFormat)) {
    return date;
  }
  return moment().format(searchDateParamFormat);
};

export { getMomentFromSearchDateParam, getSearchDateParamFromMoment, getValidDate };
