import * as types from 'constants/ActionTypes';

/**
 * Resets the seat map to its initial state.
 * @returns {Object} An action object to reset the seat map.
 */
export function resetSeatBus() {
  return { type: types.RESET_SEAT_TRIPS };
}
/**
 * Sets the seat configuration for a bus trip.
 * @param {string} way - The direction of the trip (e.g., 'outbound', 'return').
 * @param {Object} trip - The trip object containing bus and diagram type.
 * @param {string} tripSlug - A unique identifier for the trip.
 * @returns {Object} An action object with type, layout, diagram type, and trip slug.
 */
export function setSeatsBus(way, trip, tripSlug) {
  const { bus, diagramType } = trip;
  return {
    way,
    layout: bus,
    diagramType,
    type: types.SET_SEAT_MAP,
    tripSlug,
  };
}

/**
 * Sets the seat configuration error for a bus trip.
 * @param {Object} params - The trip IDs.
 * @param {Object} params.tripId - The trip ID.
 * @returns {Object} An action object with type and trip IDs.
 */
export function setSeatsBusError({ tripId }) {
  return {
    type: types.SET_SEAT_MAP_ERROR,
    tripId,
  };
}
