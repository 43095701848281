// immutable
function getPassengerCount(passengers, passengerSelection) {
  if (!passengers) return 0;
  if (passengers.size > 0) return passengers.size;

  return passengerSelection
    .valueSeq()
    .reduce((totalCount, categoryCount) => (totalCount += Number(categoryCount)), 0);
}

export default getPassengerCount;
