import moment from 'moment';
import { capitalize } from 'lodash';
import { trackEvent, ecTracker } from 'user-analytics';
import dataFinder from '@/store/dataFinder';
import { hasMultipleCarriers } from 'utils/Reserbus';
import { CurrentLang } from 'utils/lang';

function getMulticarrierProperties(legs) {
  if (!hasMultipleCarriers(legs)) {
    return {};
  }

  let ally = true;
  let copyrightProtected = false;
  let multicarrierNames = [];

  legs.forEach(({ carrierId }) => {
    const carrier = dataFinder('carriers', carrierId);
    ally = ally || carrier.ally;
    copyrightProtected = copyrightProtected && carrier.copyrightProtected;
    multicarrierNames.push(carrier.name);
  });

  multicarrierNames = multicarrierNames.join();

  return {
    'Ally': !!ally,
    'Copyright Protected': !!copyrightProtected,
    'Line': 'Multicarrier Price Travel',
    'Multicarrier Lines': multicarrierNames,
    'Multicarrier Transporters': multicarrierNames,
    'Transporter': 'Multicarrier Price Travel',
  };
}

function getBusProperties(trip) {
  const {
    line: { ally, copyrightProtected, name, transporterName },
    stops,
  } = trip;

  return {
    'Ally': ally,
    'Copyright Protected': copyrightProtected,
    'Line': name,
    'Stops': parseInt(stops, 10),
    'Transporter': transporterName,
  };
}

function getFlightProperties(flight) {
  const {
    carrier: { name, ally, copyrightProtected },
    legs,
  } = flight;
  const stopovers = legs.length - 1;
  let stopCities;

  if (stopovers) {
    stopCities = legs
      .map((leg) => leg.destination.cityName)
      .slice(0, stopovers)
      .join();
  }

  return {
    'Ally': ally,
    'Copyright Protected': !!copyrightProtected,
    'Line': name,
    'Stops': stopovers,
    'Stop Cities': stopCities,
    'Transporter': name,
    ...getMulticarrierProperties(legs),
  };
}

function getMixedProperties(itinerary) {
  const { fragments } = itinerary;
  let stopovers = fragments.length - 1;
  const stopCities = [];
  const lines = [];
  const transporters = [];
  const transportTypes = [];
  let ally = true;
  let copyrightProtected = false;

  fragments.forEach((fragment) => {
    const { transportType, lineId, legs, destinationId } = fragment;
    let fragmentAlly = true;
    let fragmentProtected = false;

    if (transportType === 'bus') {
      const line = dataFinder('lines', lineId);
      const { name, transporterName, ally, copyrightProtected } = line;

      stopCities.push(dataFinder('terminals', destinationId).cityName);
      lines.push(name);
      transporters.push(transporterName);
      fragmentAlly = ally;
      fragmentProtected = copyrightProtected;
    } else {
      stopovers += legs.length - 1;
      legs.forEach(({ destinationId }) => {
        const carrier = dataFinder('carriers', legs[0].carrierId);
        const { name, ally, copyrightProtected } = carrier;

        stopCities.push(dataFinder('airports', destinationId).cityName);
        lines.push(name);
        transporters.push(name);
        fragmentAlly = fragmentAlly && ally;
        fragmentProtected = fragmentProtected || copyrightProtected;
      });
    }

    ally = ally && fragmentAlly;
    copyrightProtected = copyrightProtected || fragmentProtected;
    transportTypes.push(transportType);
  });

  return {
    'Ally': ally,
    'Copyright Protected': !!copyrightProtected,
    'Line': 'Multicarrier',
    'Multicarrier Lines': lines.join(),
    'Multicarrier Transporters': transporters.join(),
    'Multicarrier Transport Types': transportTypes.join(),
    'Stops': stopovers,
    'Stop Cities': stopCities.slice(0, stopovers).join(),
    'Transporter': 'Multicarrier',
  };
}

function getEcBusProperties(trip) {
  return { lineName: trip.line.name };
}

function getEcFlightProperties(flight) {
  return { lineName: flight.legs[0].carrier.name };
}

function getEcMixedProperties(itinerary) {
  const lineName = itinerary.fragments.map((fragment) => {
    const { transportType, lineId, legs } = fragment;

    if (transportType === 'bus') {
      return dataFinder('lines', lineId).name;
    }

    return dataFinder('carriers', legs[0].carrierId).name;
  });

  return { lineName: lineName.join(':') };
}

export function serializeTrip(trip, extra, isOpenTicket) {
  const { transportType, departure, arrival, origin, destination, pricing, recommendationType } =
    trip;
  let transportProperties;

  switch (transportType) {
    case 'bus':
      transportProperties = getBusProperties(trip);
      break;
    case 'flight':
      transportProperties = getFlightProperties(trip);
      break;
    case 'mixed':
      transportProperties = getMixedProperties(trip);
      break;
    default:
      transportProperties = {};
  }

  return {
    'Arrival': isOpenTicket ? null : moment(arrival).format(),
    'Departure': isOpenTicket ? null : moment(departure).format(),
    'Destination': destination.cityName,
    'Destination Terminal': destination.name,
    'Origin': origin.cityName,
    'Origin Terminal': origin.name,
    'Price': pricing.total,
    'Recommended Selected': Boolean(recommendationType),
    'Recommendation Type': recommendationType,
    'Route': `${origin.cityName} - ${destination.cityName}`,
    'Transport Type': transportType,
    'Open Ticket': isOpenTicket,
    ...transportProperties,
    ...extra,
    'Language': CurrentLang(),
  };
}

export function serializeEcTrip(trip, position) {
  const {
    transportType,
    id,
    departure,
    origin,
    destination,
    pricing: { total, commission },
    recommendationType,
  } = trip;
  let transportProperties;

  switch (transportType) {
    case 'bus':
      transportProperties = getEcBusProperties(trip);
      break;
    case 'flight':
      transportProperties = getEcFlightProperties(trip);
      break;
    case 'mixed':
      transportProperties = getEcMixedProperties(trip);
      break;
    default:
      transportProperties = {};
  }

  return {
    id,
    originCityName: origin.cityName,
    originCountryName: origin.countryName,
    destinationCityName: destination.cityName,
    destinationCountryName: destination.countryName,
    price: total,
    commision: commission,
    departure,
    position,
    transportType,
    recommendationType,
    ...transportProperties,
  };
}

export function selectedTripTracker(trip, position, way) {
  const { line = {}, carrier = {}, transportType } = trip;
  const eventName = `Picked ${capitalize(way)}`;
  const listName = {
    bus: `Organic - Search Results - ${line.name} - ${capitalize(way)}`,
    flight: `Organic - Search Results - ${carrier.name} - ${capitalize(way)}`,
    mixed: `Organic - Search Results - Multicarrier - ${capitalize(way)}`,
  };

  trackEvent(eventName, serializeTrip(trip, { 'Result Position': position }));
  ecTracker.trackProductClick(serializeEcTrip(trip, position), listName[transportType], way);
}

export function showTripDetailsTracker(trip, position) {
  trackEvent('Show Trip Details', serializeTrip(trip, { 'Result Position': position }));
}
