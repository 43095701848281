import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spacing } from '@reservamos/elements';
import SearchBar from 'components/search/SearchBar';
import SearchRoutes from './SearchRoutes';

/**
 * Search modal component
 * @param {Object} props - Component props
 * @param {string} props.originCity - Origin city
 * @param {string} props.destinationCity - Destination city
 * @param {Object} props.history - History object
 * @param {string} props.title - Title of the modal
 * @returns
 */
const SearchModal = ({ originCity, destinationCity, history, title }) => {
  return (
    <Modal
      modalTrigger={<SearchRoutes routeOrigin={originCity} routeDestination={destinationCity} />}
      title={title}
      showOverFlowContent
      hasBlushing
      responsiveSize="L"
    >
      <Spacing justifyContent="center" alignItems="center">
        <SearchBar isModalVersion history={history} />
      </Spacing>
    </Modal>
  );
};

SearchModal.propTypes = {
  originCity: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
};

SearchModal.defaultProps = {
  title: '',
};

export default SearchModal;
