import React from 'react';
import './PaycashLogos.scss';

const PaycashLogos = () => (
  <div className="paycash-logos-grid">
    <i className="walmart-logo" />
    <i className="superama-logo" />
    <i className="soriana-logo" />
    <i className="aurrera-logo" />
    <i className="kiosko-logo" />
    <i className="extra-logo" />
    <i className="circlek-logo" />
    <i className="seven-logo" />
  </div>
);

export default PaycashLogos;
