import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setResultPriceToShow } from '@/actions/search';
import { ssoError } from '@/actions/sso';
import { triggerSignInWidget, triggerSignOutWidget } from 'utils/session';
import usePurchase from 'hooks/store/usePurchase';
import useSSO from 'hooks/store/useSSO';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import AuthDialog from '../purchase/AuthDialog';

/** SSO auth modal to notify the user that it needs to log in */
export default function SSOAuthDialog() {
  const { token: purchaseToken } = usePurchase();
  const features = useWhitelabelFeatures();
  const sso = useSSO();
  const { t: tLoyalty } = useTranslation('loyalty');
  const dispatch = useDispatch();
  const history = useHistory();
  const loyaltyName = 'siempreplus';

  if (!features.USE_SSO || sso.error !== 401) return null;

  const onAccept = () => {
    triggerSignOutWidget(loyaltyName);
    triggerSignInWidget(loyaltyName);
  };

  const onCancel = () => {
    triggerSignOutWidget(loyaltyName);
    dispatch(setResultPriceToShow('normal'));
    dispatch(ssoError(false));
    if (purchaseToken) history.replace(`/purchase/${purchaseToken}/passengers`);
  };

  return (
    <AuthDialog
      title={tLoyalty('auth_error')}
      bodyText={tLoyalty('need_sign_in')}
      onClickPrimary={onAccept}
      onClickSecondary={onCancel}
    />
  );
}
