import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import createWidget from 'siempreplus-widget';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { REDIRECT_LOGOUT_KEY, REDIRECT_LOGIN_KEY } from 'constants/sso';

// Missing fix on widget="login" commented a fix quick

const LoyaltyWidget = ({
  buttonText,
  hidden,
  id,
  invitationText,
  modifierClass,
  variant,
  visible,
  fetchingPurchase,
  showProfileOption,
  linkText,
  hideIcon,
  ctaText,
  extraConfig,
  insideIcon,
  onSidebar,
  hideButtonLogo,
  hideSigninText,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('siempreplus');

  const HOST = env.api.searchUrl.slice(0, -4);

  const {
    colors,
    cardType,
    recoverPassword,
    registerAccount,
    thirdParty,
    iconType,
    hrefConfig,
    modalIcon,
    hasModalDescription,
    logoutApi,
    prefix,
    buttonLogo,
    showMobileIcon,
    externalRegisterURL,
    cookieExpiration = 1,
    captchaEnabled,
    endpointName,
    sandbox = false,
    links,
    showSigninText,
    light = false,
    accountURL,
  } = { ...env.siemprePlus, ...extraConfig } || {};
  const { noticeOfPrivacyUrl, termsAndConditionsUrl } = hrefConfig || {};
  const { primaryColor, strongColor, accentColor } = colors || {};

  const signInTitle = t('sign_in_title', { context: prefix });
  const modalDescription = hasModalDescription
    ? t('sign_in_description', { context: env.brand })
    : '';

  const modifierClassWithLight = light ? `${modifierClass} light` : modifierClass;

  useEffect(() => {
    try {
      const { pub } = window.reservamosPubSub;
      if (variant === 'link-home') return;
      const eventTypes = {
        costapass: 'costapass_profile_refresh',
        default: 'siempreplus_refresh',
      };

      if (pub && fetchingPurchase) {
        pub(eventTypes[cardType] ?? eventTypes.default, true);
      }
    } catch (error) {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingPurchase]);
  useEffect(() => {
    if (!visible) return;
    createWidget(`#${id}`).catch((err) => console.error(err));
  }, [visible, id]);

  if (!features.DOTERS_ENABLED && !features.COSTAPASS_ENABLED) return null;
  if (!visible) return null;

  let ssoConfig;
  if (env.sso) {
    ssoConfig = {
      'data-prop-sso-host': env.sso.host,
      'data-prop-sso-client-secret': env.sso.clientSecret,
      'data-prop-sso-logout-host': env.sso.logoutHost,
      'data-prop-sso-redirect-key-logout': REDIRECT_LOGOUT_KEY,
      'data-prop-sso-redirect-key-login': REDIRECT_LOGIN_KEY,
      'data-prop-sso-client-id': env.sso.clientId,
      'data-prop-sso-login-redirect-uri': env.sso.loginRedirectUri,
    };
  }

  const linksConfig = {};
  if (links) {
    Object.keys(links).forEach((key) => {
      const transformedKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
      linksConfig[`data-prop-link-${transformedKey}`] = links[key];
    });
  }

  return (
    <div
      id={id}
      // eslint-disable-next-line react/no-unknown-property
      widget="login"
      data-prop-sandbox={sandbox}
      data-prop-button-text={buttonText}
      data-prop-hidden={hidden}
      data-prop-invitation-text={invitationText}
      data-prop-modifier-class={modifierClassWithLight}
      data-prop-origin={HOST}
      data-prop-profile-option={showProfileOption.toString()}
      data-prop-variant={variant}
      data-prop-recover-password={recoverPassword}
      data-prop-register-account={registerAccount}
      data-prop-third-party={thirdParty}
      data-prop-facebook-login={env.siemprePlus.facebookLogin}
      data-prop-google-login={env.siemprePlus.googleLogin}
      data-prop-outlook-login={env.siemprePlus.outlookLogin}
      data-prop-primary-color={primaryColor}
      data-prop-primarystrong-color={strongColor}
      data-prop-accent-color={accentColor}
      data-prop-header-color
      data-prop-sign-in-title={signInTitle}
      data-prop-privacy-url={noticeOfPrivacyUrl}
      data-prop-terms-and-conditions-url={termsAndConditionsUrl}
      data-prop-icon-type={iconType}
      data-prop-card-type={cardType}
      data-prop-modal-icon={modalIcon}
      data-prop-modal-description={modalDescription}
      data-prop-modal-logo
      data-prop-logout-api={logoutApi}
      data-prop-mixpanel-prefix={prefix}
      data-prop-link-text={linkText}
      data-prop-hide-icon={hideIcon}
      data-prop-button-logo={hideButtonLogo ? '' : buttonLogo}
      data-prop-show-mobile-icon={showMobileIcon}
      data-prop-cta-text={ctaText}
      data-prop-inside-icon={insideIcon}
      data-prop-external-register-url={externalRegisterURL}
      data-prop-cookie-expiration={cookieExpiration}
      data-prop-on-sidebar={onSidebar}
      data-prop-captcha-enabled={captchaEnabled}
      data-prop-endpoint-name={endpointName}
      data-prop-show-signin-text={hideSigninText ? null : showSigninText}
      data-prop-loyalty={prefix}
      data-prop-account-url={accountURL}
      {...linksConfig}
      {...ssoConfig}
    />
  );
};

LoyaltyWidget.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  fetchingPurchase: PropTypes.bool,
  buttonText: PropTypes.string,
  hidden: PropTypes.string,
  invitationText: PropTypes.string,
  modifierClass: PropTypes.string,
  showProfileOption: PropTypes.bool,
  linkText: PropTypes.string,
  hideIcon: PropTypes.bool,
  ctaText: PropTypes.string,
  extraConfig: PropTypes.object,
  insideIcon: PropTypes.bool,
  onSidebar: PropTypes.bool,
  hideButtonLogo: PropTypes.bool,
  hideSigninText: PropTypes.bool,
};

LoyaltyWidget.defaultProps = {
  buttonText: '',
  hidden: '',
  invitationText: '',
  linkText: '',
  hideIcon: false,
  modifierClass: '',
  showProfileOption: false,
  onSidebar: false,
  hideSigninText: false,
};
export default LoyaltyWidget;
