import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, DialogMessage, Text } from '@reservamos/elements';
import insuranceETN from '../../images/brands-unique/etn/insuranceETN.png';
import { formatCurrency } from '../../utils/Helpers';

const propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  insuranceDuration: PropTypes.number.isRequired,
  insuranceOutgoingAmount: PropTypes.number,
  insuranceIncomingAmount: PropTypes.number,
};

const InsuranceDialog = ({
  onAccept,
  onReject,
  insuranceDuration,
  insuranceOutgoingAmount,
  insuranceIncomingAmount,
}) => {
  const { t } = useTranslation('passengers');
  let insuranceAmount = insuranceOutgoingAmount || 0;
  if (insuranceIncomingAmount) {
    insuranceAmount += insuranceIncomingAmount;
  }

  return (
    <DialogMessage
      iconType={insuranceETN}
      title={t('label.protected_traveler')}
      primaryButton={t('button.yes_thanks')}
      onClickPrimary={onAccept}
      onClickSecondary={onReject}
      secondaryButton={t('button.no_thanks')}
      showFade
      dialogType="accent"
    >
      <Spacing vertical size="S">
        <Text>
          {t('protect_luggage', { insurance_amount: formatCurrency(insuranceAmount, 2) })}
        </Text>
        <Text>{t('valid_time', { insurance_duration: insuranceDuration })}</Text>
      </Spacing>
    </DialogMessage>
  );
};

InsuranceDialog.propTypes = propTypes;
export default InsuranceDialog;
