import { useGrowthBook } from '@growthbook/growthbook-react';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { camelizeKeys } from 'humps';

/**
 * Custom Hook to get a feature value
 * @param {...string} args - The list of feature names.
 * @returns {boolean} - Whether the feature is on or not.
 */
const useGrowthBookFeaturesValue = (...args) => {
  const { brand } = useWhitelabelEnvs();
  const growthBook = useGrowthBook();

  let growthBookFeatures = {};
  if (growthBook?.ready) {
    const availableFeatures = growthBook.getFeatures();
    growthBookFeatures = args.reduce((acc, feature) => {
      let featureName = `${feature}_${brand}`;
      if (!availableFeatures[featureName]) featureName = feature;
      acc[feature] = growthBook.getFeatureValue(featureName);
      return acc;
    }, {});
  }

  return camelizeKeys(growthBookFeatures);
};

export default useGrowthBookFeaturesValue;
