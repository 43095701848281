import React from 'react';
import PropTypes from 'prop-types';
import { Gap, Spacing, Text, Visibility } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import './ResultsTitle.scss';
import { useSelector } from 'react-redux';
import { isSeatsOnResultActivated } from 'utils/seats';
import { isDesktopSize } from 'utils/Reserbus';
import AlertSubscriptionModal from 'components/AlertSubscriptionModal';

/**
 * Component for displaying the results title.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.hideTitleOnMobile - Indicates if the title should be hidden on mobile devices.
 * @param {string} props.disclaimer - The disclaimer text to display.
 * @param {React.ReactNode} props.rightContent - The content to display on the right side of the title.
 * @param {string} props.subtitle - The subtitle text.
 * @param {string} props.date - The date to display.
 * @param {string} props.sectionTitle - The section title.
 * @returns {JSX.Element} The rendered component.
 */
const ResultsTitle = ({
  hideTitleOnMobile,
  disclaimer,
  rightContent,
  subtitle,
  date,
  sectionTitle,
}) => {
  const { env, features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('trips');
  const langPt = env.lang.default.startsWith('pt');

  const contextTitle = {
    schedules: 'schedules_for',
    schedulesReturn: 'schedules_for_return',
    openDeparture: 'open_departure',
    openReturn: 'open_return',
    providersDeparture: 'providers_departure',
    providersReturn: 'providers_return',
  };

  let dateContent = '';
  if (date) {
    if (langPt) {
      dateContent = (
        <Text size="XL" mobileSize="S" weight="bold">
          <>{` ${date}`}</>
        </Text>
      );
    } else {
      dateContent = (
        <Text mobileSize="S">
          <>{` ${date}`}</>
        </Text>
      );
    }
  }

  const isMobile = !isDesktopSize();

  const leftContent = (
    <Spacing justifyContent="space-between" alignItems="center" fullWidth={isMobile}>
      <div>
        <Gap alignItems="baseline" columnGap="XS" rowGap="XXS">
          <Text size="XL" mobileSize="L" weight="bold">
            {t('trip_time', {
              context: contextTitle[sectionTitle || 'schedules'],
            })}
          </Text>
          {Boolean(subtitle || contextTitle) && dateContent}
        </Gap>
        {Boolean(disclaimer) && (
          <Text size="S" color="grayMedium">
            {disclaimer}
          </Text>
        )}
      </div>

      <Visibility type="showForMobileOnly">
        <AlertSubscriptionModal />
      </Visibility>
    </Spacing>
  );

  const hiddenTitle = <Visibility type="hideForMedium">{leftContent}</Visibility>;

  const resultsSeatsAb = isSeatsOnResultActivated();
  const isFlat = !resultsSeatsAb && features.FUNNEL_STYLE === 'FLAT';

  return (
    <>
      <div className={`results-title fade-in ${isFlat ? 'results-title-flat' : ''}`}>
        {hideTitleOnMobile ? hiddenTitle : leftContent}

        {rightContent}
      </div>
    </>
  );
};

ResultsTitle.propTypes = {
  sectionTitle: PropTypes.string,
  date: PropTypes.string,
  disclaimer: PropTypes.string,
  rightContent: PropTypes.node,
  subtitle: PropTypes.string,
  hideTitleOnMobile: PropTypes.bool,
};

ResultsTitle.defaultProps = {
  rightContent: null,
  sectionTitle: '',
  disclaimer: '',
  date: '',
  subtitle: '',
  hideTitleOnMobile: false,
};

export default ResultsTitle;
