import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, LoaderPulse } from '@reservamos/elements';
import './Checkbox.scss';

const Checkbox = ({ checked, hasError, id, isLoading, onChange, labelText }) => (
  <label className="rvui-checkbox" htmlFor={id}>
    {isLoading ? (
      <div className="rvui-checkbox-wrapper">
        <LoaderPulse size="XS" hasWrapper={false} />
      </div>
    ) : (
      <div className="rvui-checkbox-box">
        <input type="checkbox" id={id} checked={checked} onChange={onChange} />
        <label className="rvui-checkbox-box-icon" htmlFor={id}>
          <Icon type="CheckmarkAccent" size="S" />
        </label>
      </div>
    )}

    <Text elementType="span" color={hasError ? 'error' : 'grayMedium'}>
      {labelText}
    </Text>
  </label>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.string,
};

Checkbox.defaultProps = {
  hasError: false,
  isLoading: false,
  labelText: '',
};

export default Checkbox;
