import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Icon } from '@reservamos/elements';
import TicketList from './TicketsList';

/**
 * TicketListBlock Functional Component
 *
 * Renders a block containing a list of tickets for the provided trip and passengers.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.trip - The trip details, including origin, destination, and schedule.
 * @param {Array} props.passengers - The list of passengers associated with the tickets.
 * @param {Function} props.getOnClickedTicket - A function to handle ticket clicks. It returns a function
 *                                              that processes a clicked ticket based on its key.
 * @param {React.ReactNode} props.children - Additional elements or components to render inside the block.
 *
 * @returns {JSX.Element} The rendered block component containing the ticket list and additional content.
 */
const TicketListBlock = ({ trip, passengers, getOnClickedTicket, children }) => {
  const { t } = useTranslation('exchange');

  return (
    <div className="w-full">
      <p className="mb-2 text-sm">
        {children}
        <span className="ml-4">
          {trip.originDisplay} - {trip.destinationDisplay}
        </span>
      </p>
      <div className="border-b border-2 rounded-md p-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Icon type="CalendarOutlined" size="M" />
            <span className="font-medium text-sm">
              <strong>{moment(trip.time, 'HH:mm').format('hh:mm A')}</strong>
              {', '}
              {moment(trip.date, 'DD-MM-YYYY')
                .format('dddd, D MMMM')
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
              {' - '}
              {passengers.length} {t('ticket_list.tickets')}
            </span>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 mt-4">
          <TicketList passengers={passengers} onClickedTicket={getOnClickedTicket} />
        </div>
      </div>
    </div>
  );
};

TicketListBlock.propTypes = {
  trip: PropTypes.object,
  passengers: PropTypes.array,
  getOnClickedTicket: PropTypes.func,
  children: PropTypes.node,
};

export default TicketListBlock;
