import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { formatDuration } from 'utils/Helpers';
import transferTooltipCopy from 'utils/trips/transferTooltipCopy';
import IconNotice from 'components/IconNotice';
import InstallmentsTooltip from 'components/InstallmentsTooltip';
import { getCurrencySuffix } from 'utils/currency';

const propTypes = {
  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  stops: PropTypes.number,
  stopoverPlace: PropTypes.shape({
    type: PropTypes.string.isRequired,
    hasTransit: PropTypes.bool.isRequired,
    sameLine: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    cityName: PropTypes.string.isRequired,
  }).isRequired,
  installmentsMinAmount: PropTypes.number.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

class MixedItem extends Component {
  transferLabel() {
    const { stopoverPlace } = this.props;
    const { type, hasTransit, sameLine } = stopoverPlace;

    if (hasTransit) {
      if (type === 'terminal') {
        return 'Traslado a terminal';
      }

      return 'Traslado a aeropuerto';
    }

    if (sameLine) {
      return 'Cambio de línea';
    }

    return `Cambio de ${type === 'terminal' ? 'autobús' : 'avión'}`;
  }

  render() {
    const {
      origin,
      destination,
      departure,
      arrival,
      duration,
      stops,
      stopoverPlace,
      installmentsMinAmount,
      onSelectClick,
      t,
    } = this.props;
    let stopsLabel = <span className="schedules-results-stop">directo</span>;

    if (stops === 1) {
      stopsLabel = <span className="schedules-results-stop">1 escala</span>;
    } else if (stops > 1) {
      stopsLabel = <span className="schedules-results-stop">{`${stops} escalas`}</span>;
    }

    return (
      <div className="result-box schedules-results">
        <i className="result-box-arrow" />
        <div className="result-box-main">
          <span className="schedules-results-font main">{moment(departure).format('LT')}</span>

          <span className="schedules-results-font">{` - ${moment(arrival).format('LT')}`}</span>
        </div>

        <div className="result-box-departures">
          <p className="schedules-results-font">{`${formatDuration(duration)} ${stopsLabel}`}</p>
          <IconNotice iconLabel={this.transferLabel()} variant="transfer">
            <p className="tooltip-paragraph">{transferTooltipCopy(stopoverPlace)}</p>
          </IconNotice>
        </div>

        <div className="result-box-price">
          <span className="provider-price">
            {`{formatCurrency(total)} `}
            <small>{getCurrencySuffix()}</small>
          </span>
          <InstallmentsTooltip installmentsMinAmount={installmentsMinAmount} />
        </div>

        <div className="result-box-summary">
          <div className="result-box-terminals">
            <p>{`${origin.cityName} - ${origin.name}`}</p>
            <p>{`${destination.cityName} - ${destination.name}`}</p>
          </div>
        </div>

        <div className="result-box-action">
          <button className="button-primary" onClick={onSelectClick}>
            {t('select')}
          </button>
        </div>
      </div>
    );
  }
}

MixedItem.propTypes = propTypes;

export default withTranslation('purchase')(MixedItem);
