import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Spacing, DialogMessage, Text } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

const propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

const RewardTicketInvalidDialog = ({ onAccept, onReject }) => {
  const { t } = useTranslation('purchase');
  const features = useWhitelabelFeatures();
  return (
    <DialogMessage
      title={t('label.reward_ticket_invalid', { context: features.FLAT_FARE_CONTEXT })}
      primaryButton={t('button.yes_continue')}
      onClickPrimary={onAccept}
      onClickSecondary={onReject}
      secondaryButton={t('button.no_thanks')}
      showFade
      dialogType="accent"
    >
      <Spacing vertical size="S">
        <Text>
          <Trans t={t} i18nKey="text.reward_ticket_invalid" />
        </Text>
        <Text>{t('text.do_you_want_continue')}</Text>
      </Spacing>
    </DialogMessage>
  );
};

RewardTicketInvalidDialog.propTypes = propTypes;
export default RewardTicketInvalidDialog;
