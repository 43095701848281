import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from 'immutable';
import { Spacing, Text } from '@reservamos/elements';
import BankDetailsRow from './BankDetailsRow';

const BankDetails = () => {
  const { t } = useTranslation('purchase');
  const fields = useSelector(({ payment }) => {
    const bankDetails = payment.get('bankDetails');
    return (bankDetails || List()).toJS();
  });

  if (fields.length === 0) return null;
  return (
    <Spacing vertical>
      <Text size="L" weight="bold">
        {t('label.purchase_receipt')}
      </Text>
      <div className="purchase-pricing">
        <div className="section-content">
          {fields.map(({ type, value }) => (
            <BankDetailsRow key={type} type={type} value={value} />
          ))}
        </div>
      </div>
    </Spacing>
  );
};

export default BankDetails;
