/* eslint-disable camelcase */
// TODO verify camelcase
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import titleize from 'utils/titleize';
import { useSelector } from 'react-redux';
import ExchangeContext from '../context';
import { selectIcon } from './place';

const TypeaheadOption = ({ place }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const context = useContext(ExchangeContext);
  const [hover, setHover] = useState(false);

  const { slug, display, city_name, state } = place;
  const displayFormat = titleize(
    `${display}, ${features.SEARCH_CITY_REDUNDANT ? state : city_name}`,
  );

  return (
    <>
      <li
        className={`${hover ? 'typeahead__selected' : ''}`}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        onClick={() => context.handleOptionSelect(displayFormat, slug)}
      >
        <>
          <div
            className="typeahead__autocomplete-icon"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: selectIcon(place.result_type, false),
            }}
          />
          <div className="typeahead__place-info">
            <b>{`${titleize(place.display)}, `}</b>
            {titleize(`${features.SEARCH_CITY_REDUNDANT ? state : city_name}`)}
          </div>
        </>
      </li>
    </>
  );
};

const propTypes = {
  place: PropTypes.object.isRequired,
};

TypeaheadOption.propTypes = propTypes;
export default TypeaheadOption;
