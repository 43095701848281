import React from 'react';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import BadgeNew from '../../../ui/atoms/BadgeNew';
import CheckList from '../../../ui/atoms/CheckList';
import PaycashLogos from '../../../ui/templates/PaycashLogos';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const PaycashStoreForm = () => {
  const { t } = useTranslation('payment');

  const labels = [
    t('paycash_instructions.first'),
    t('paycash_instructions.second'),
    t('paycash_instructions.third'),
    t('paycash_instructions.fourth'),
    t('paycash_instructions.fifth'),
    t('paycash_instructions.sixth'),
  ];

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <Spacing alignItems="flex-end" size="S">
          <PaymentImage type="paycash-logo" />
          <BadgeNew />
        </Spacing>
        <PaycashLogos />

        <CheckList title={t('label.paycash')} labels={labels} />

        <Spacing veritcal size="S">
          <Text size="S" weight="semibold">
            * {t('label.cash_only')}.
          </Text>
          <Text size="S" weight="semibold">
            * {t('label.immediate_accreditation')}.
          </Text>
        </Spacing>
      </Spacing>
    </div>
  );
};

export default PaycashStoreForm;
