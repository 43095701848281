import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleModal } from '@/actions';
import ProviderSort from 'components/modal/ProviderSort';
import 'styles/components/search/ViewOptions';

class ProviderViewOptions extends Component {
  constructor(props) {
    super(props);

    this.select = this.select.bind(this);
  }

  select() {
    const { visibleModal, hideModal, onSortClick } = this.props;

    if (visibleModal === 'Sort') {
      hideModal();
    } else {
      onSortClick(ProviderSort, 'Sort');
    }
  }

  render() {
    const { sortProperty } = this.props;

    return (
      <div className="view-options" onClick={this.select}>
        <a className={`sort ${sortProperty ? ' filter-on' : ''}`}>ordenar</a>
      </div>
    );
  }
}

ProviderViewOptions.propTypes = {
  sortProperty: PropTypes.string,
  visibleModal: PropTypes.string,
  onSortClick: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { active } = state.modal;

  return { visibleModal: active };
}

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(toggleModal('hidden')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderViewOptions);
