import configureStore from './configureStore';

const StoreInstance = () => {
  let storeInstance;

  const getInstance = () => {
    if (!storeInstance) {
      throw new Error('La instancia de esta Store no ha sido inicializada');
    }
    return storeInstance;
  };

  const getState = () => getInstance().getState();

  const dispatch = (action) => getInstance().dispatch(action);

  const createInstance = (preloadedState = {}) => {
    if (!storeInstance) {
      storeInstance = configureStore(preloadedState);
    }
    return storeInstance;
  };

  return {
    getState,
    createInstance,
    dispatch,
  };
};

const store = StoreInstance();

export default store;
