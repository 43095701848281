import store from '@/store';

const COOKIE_BASE = 'AB-TEST';
const DEFAULT_PROBABILITY = 50;
export const DEFAULT_NO_TEST = false;

/**
 * Calculates one of the possible values using probability
 *
 * @param {Object} probability
 * @param {number} probability.probability
 * @param {string|number} probability.value
 * @returns {string|number}
 */
function calculateAB(probability) {
  const candidates = [];
  const random = Math.random();

  // Generates a new Array candidates with multiple appearance of values
  // The repetition of values is based on probability
  // Eg: ['a','a','a'...n,'b','b','b',...n] n = 50
  probability.forEach((item) => {
    const clone = Array(item.probability).fill(item.value);
    candidates.push(...clone);
  });

  // Pick a random index in candidates.length
  return candidates[Math.floor(random * candidates.length)];
}

/**
 * Returns a single AB test value from storage
 * If no test value then it returns default
 *
 * @param {string} test
 * @returns {boolean|string}
 */
export function getTest(test) {
  const { features } = store.getState().whitelabelConfig;
  const abTests = features.AB_TESTS;
  const testName = String(`${COOKIE_BASE}-${test}`).toUpperCase();

  // Avoid init if window is not ready, localStorage or tests does not exist
  if (
    typeof window === 'undefined' ||
    !window.localStorage ||
    !window.localStorage.getItem(testName) ||
    !abTests
  ) {
    return DEFAULT_NO_TEST;
  }

  // Search for test name in features.AB_TESTS
  const searchTest = abTests.find((abTest) => abTest.enabled && abTest.name === test);

  if (searchTest) {
    return window.localStorage.getItem(testName);
  }

  return DEFAULT_NO_TEST;
}

/**
 * Returns an array of AB tests
 *
 * @returns {any[] | []}
 */
export function getAllTests() {
  const { features } = store.getState().whitelabelConfig;
  const abTests = features.AB_TESTS;
  const tests = [];

  // Avoid init if window is not ready
  if (typeof window === 'undefined' || !window.localStorage) {
    return;
  }

  if (abTests) {
    abTests
      .filter((abTest) => abTest.enabled)
      .forEach((abTest) => {
        const testName = String(`${COOKIE_BASE}-${abTest.name}`).toUpperCase();
        const test = window.localStorage.getItem(testName);
        if (test) {
          tests.push(test);
        }
      });
  }

  return tests;
}

/**
 *
 * Get the probability for AB Tests depending on given values in config file
 *
 * @param {number|number[]} probability The probability. Could be of type number or array of numbers equal to value length e.g: [50,50] for values = ['a','b']
 * @param {string[]|number[]} values The values of the testing. Usually "a" and "b"
 * @returns {object} The probability object structured
 */
function getProbability(probability, values) {
  if (typeof probability !== 'number') {
    if (probability.length === values.length) {
      // Verify that the sum of probability value is iqual or close to 100%
      const accProbability = probability.reduce((acc, curr) => (acc += curr));
      const is100 = accProbability >= 98 && accProbability <= 102;
      if (!is100) {
        probability = Array(values.length).fill(Math.floor(100 / values.length));
      }

      return values.map((abValue, index) => ({ value: abValue, probability: probability[index] }));
    }

    // If array structures are not equal
    probability = DEFAULT_PROBABILITY;
  }

  // If probability is not between 1% and 100%
  if (probability < 1 && probability > 100) {
    probability = DEFAULT_PROBABILITY;
  }

  return values.map((abValue, index) => {
    const isFirst = index === 0;
    const pVal = Math.floor((100 - probability) / (values.length - 1));
    return {
      value: abValue,
      probability: isFirst ? probability : pVal,
    };
  });
}

/**
 * Initializes AB Tests in memory
 */
export function initTest() {
  // Avoid init if window is not ready
  if (typeof window === 'undefined' || !window.localStorage) {
    return;
  }

  const { features } = store.getState().whitelabelConfig;
  const abTests = features.AB_TESTS;

  if (abTests) {
    abTests
      .filter((abTest) => abTest.enabled)
      .forEach((abTest) => {
        const testName = String(`${COOKIE_BASE}-${abTest.name}`).toUpperCase();
        if (window.localStorage.getItem(testName)) {
          // abTest already initialized
          return;
        }

        // Validate probability if it is number or array
        const probability = getProbability(abTest.probability, abTest.values);

        // Save AB Test into localStorage
        window.localStorage.setItem(testName, calculateAB(probability));
      });
  }
}
