import React from 'react';
import { Button, Input, Spacing, Alert, Box, Text } from '@reservamos/elements';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import usePqrs from './usePqrs';

/**
 * `PqrsForm` is a React component that renders a form for submitting PQRS (Petitions, Complaints, Requests, and Suggestions).
 * It utilizes Formik for form state management and Yup for validation schema definition.
 * The form includes fields for name, email, subject, message, and an ID document.
 * The component also uses a custom hook `usePqrs` to interact with the tracking API
 * and manage loading states and responses.
 * It also displays a success message upon receiving a response.
 * @returns {JSX.Element} The rendered form component.
 */
const PqrsForm = () => {
  const { t } = useTranslation('pqrs');
  const { isLoading, response, submitRequest } = usePqrs();

  const initialValues = { name: '', email: '', subject: '', message: '', idDocument: '' };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('errors.name_required')),
    email: Yup.string().email(t('errors.email_invalid')).required(t('errors.email_required')),
    subject: Yup.string().required(t('errors.subject_required')),
    message: Yup.string().required(t('errors.message_required')),
    idDocument: Yup.string().required(t('errors.idDocument_required')),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        submitRequest(values).then(() => {
          resetForm();
        });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <Spacing vertical>
            <Spacing flexGrow horizontal isResponsive>
              <Input
                id="name"
                label={t('form.name')}
                value={values.name}
                hasError={errors.name !== undefined && touched.name}
                errorMessage={errors.name}
                onChange={handleChange}
                handleBlur={handleBlur}
              />
              <Input
                id="email"
                label={t('form.email')}
                value={values.email}
                hasError={errors.email !== undefined && touched.email}
                errorMessage={errors.email}
                onChange={handleChange}
              />
              <Input
                id="idDocument"
                label={t('form.idDocument')}
                value={values.idDocument}
                hasError={errors.idDocument !== undefined && touched.idDocument}
                errorMessage={errors.idDocument}
                onChange={handleChange}
              />
            </Spacing>
            <Spacing vertical>
              <Input
                id="subject"
                label={t('form.subject')}
                value={values.subject}
                hasError={errors.subject !== undefined && touched.subject}
                errorMessage={errors.subject}
                onChange={handleChange}
              />

              <Input
                id="message"
                label={t('form.message')}
                value={values.message}
                hasError={errors.message !== undefined && touched.message}
                errorMessage={errors.message}
                onChange={handleChange}
              />

              <Button
                buttonType="submit"
                text={t('button.submit')}
                variant="accent"
                isLoading={isLoading}
                isRounded
                withHeight
                fullWidthOnSmall
              />
            </Spacing>
          </Spacing>
          {response && (
            <Box paddingVertical="M" paddingVerticalMobile="M">
              <Alert alertType="success" title={`${t('alert.success')}`}>
                <Text>{response}</Text>
              </Alert>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PqrsForm;
