import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text, Icon, Currency, Spacing } from '@reservamos/elements';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import openTicketETN from '../../images/brands-unique/etn/openTicketETN.svg';
import openTicketGFA from '../../images/brands-unique/gfa/open-ticket.svg';
import 'styles/components/search/OpenTicketButton';
import WalletPrice from './WalletPrice';

const openTicketIcons = {
  etn: openTicketETN,
  gho: openTicketETN,
  costaline: openTicketETN,
  gfa: openTicketGFA,
  default: 'Ticket',
};

/**
 * Renders the OpenTicketButton component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.selectTrip - The function to select a trip.
 * @param {number} props.total - The total price.
 * @param {Object} props.trip - The trip object.
 * @param {boolean} props.isExchange - Indicates if it is an exchange.
 * @param {number} props.walletPoints - The wallet points.
 * @param {boolean} props.showWalletPoints - Indicates if the wallet price should be shown.
 * @param {string} props.walletType - The wallet type.
 * @returns {JSX.Element} The rendered OpenTicketButton component.
 */
const OpenTicketButton = ({
  selectTrip,
  total,
  trip,
  isExchange,
  walletPoints,
  showWalletPoints,
  walletType,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation();
  const context = isExchange && 'exchange';
  const message = features.MULTI_OPEN_TICKETS
    ? t('general:open_ticket_from', { context })
    : t('general:open_ticket', { context });

  const openTicketIcon = openTicketIcons[env.brand] || openTicketIcons.default;

  return (
    <div
      className="result-button"
      data-testid="open-ticket-button"
      onClick={() => selectTrip(trip)}
    >
      <div className="result-button-wrapper">
        <Spacing size="XS" alignItems="center">
          <Icon type={openTicketIcon} />
          <Text size="S" weight="semibold" mobileSize="XS">
            {message}
          </Text>

          {!isExchange && !showWalletPoints && (
            <Currency
              price={total}
              decimals={1}
              color="grayStrong"
              currency={getCurrencySuffix()}
              sign={getCurrencyPrefix()}
              size="L"
              mobileSize="L"
              weight="bold"
            />
          )}

          {showWalletPoints && <WalletPrice walletType={walletType} walletTotal={walletPoints} />}
        </Spacing>
      </div>
    </div>
  );
};

OpenTicketButton.propTypes = {
  selectTrip: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  trip: PropTypes.shape().isRequired,
  isExchange: PropTypes.bool.isRequired,
  walletPoints: PropTypes.number.isRequired,
  showWalletPoints: PropTypes.bool.isRequired,
  walletType: PropTypes.string,
};

export default OpenTicketButton;
