/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CirclesExpandedOnScroll.scss';

/**
 * CirclesExpandedOnScroll component.
 * This component triggers an animation where circles expand and transform as the user scrolls down the page.
 * It also reveals content after the animation completes.
 *
 * @param {Object} props - The props passed to CirclesExpandedOnScroll.
 * @param {React.ReactNode} props.children - The content to be displayed after the animation finishes.
 * @returns {React.Component} The CirclesExpandedOnScroll component that animates based on scroll position.
 * @param {Function} props.setIsVisible - Function to set the visibility of the component.
 * @param {boolean} props.isVisible - Whether the component is visible.
 */
const CirclesExpandedOnScroll = ({ children, setIsVisible, isVisible }) => {
  // State to control visibility and animation states
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isCircleExpanded, setIsCircleExpanded] = useState(false);
  const [previousScrollY, setPreviousScrollY] = useState(0);
  const [hasBeen10Seconds, setHasBeen10Seconds] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setIsComponentVisible(false);
      setIsContentVisible(false);
      setIsCircleExpanded(false);
      setPreviousScrollY(0);
      setHasBeen10Seconds(false);
    }
  }, [isVisible]);
  // Function to handle scroll events and trigger animations based on scroll position
  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 200 && previousScrollY <= 200 && hasBeen10Seconds) {
      setIsComponentVisible(true);
      setIsCircleExpanded(true);

      // Trigger animations with delays for a staggered effect
      setTimeout(() => {
        setIsContentVisible(true);
        setIsVisible(true);
      }, 2000);
    }

    setPreviousScrollY(currentScrollY);
  }, [previousScrollY, hasBeen10Seconds]);

  // Setup and cleanup for scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Start the 10-second timer when the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasBeen10Seconds(true);
    }, 10);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`scroll-animation-wrapper ${isComponentVisible ? 'scroll-animation-active' : ''}`}
    >
      {isContentVisible ? (
        <div className="scroll-animation-content">{children}</div>
      ) : (
        <div className="scroll-animation-container">
          <div className={`${isCircleExpanded ? 'circle-expanded' : ''}`} />
          <div className={`${isCircleExpanded ? 'circle-to-rectangle' : ''}`} />
        </div>
      )}
    </div>
  );
};

CirclesExpandedOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default CirclesExpandedOnScroll;
