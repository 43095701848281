import moment from 'moment';
import { sanitizeLodgingProvider } from 'utils/Helpers';

function searchDuration(createdAt) {
  const mCreatedAt = moment(createdAt);
  const mNow = moment();
  const duration = moment.duration(mNow.diff(mCreatedAt));
  return duration.asSeconds();
}

export default function getSearchAnalyticsData({
  search,
  places,
  departures,
  returns,
  lodgingProvider,
  openTicket = false,
}) {
  const origin = places[search.get('originId')];
  const destination = places[search.get('destinationId')];
  const returnDate = search.get('returnDate');

  const analyticsData = {
    search: {
      origin,
      destination,
      id: search.getIn(['departure', 'id']),
      roundTrip: !!returnDate,
      departureDate: openTicket ? null : moment(search.get('departureDate'), 'DD-MM-YYYY').toDate(),
      returnDate: !openTicket && returnDate && moment(returnDate, 'DD-MM-YYYY').toDate(),
      passengers: search.get('passengers').toJS(),
      openTicket,
    },
    departureData: {
      busCount: 0,
      flightCount: 0,
      multicarrierCount: 0,
    },
  };
  if (lodgingProvider) {
    analyticsData.search.lodgingProvider = sanitizeLodgingProvider(lodgingProvider);
  }
  if (departures) {
    const typeOfTransport = search.getIn(['departure', 'typeOfTransport']);
    const buses = departures.get('buses');

    if (buses) {
      analyticsData.departureData.busCount = buses.get('trips').size;
    }

    analyticsData.departureData.busActive = typeOfTransport.bus.active;

    analyticsData.departureData.parentSearchId = search.getIn(['departure', 'parentSearchId']);
    analyticsData.departureData.searchDuration = searchDuration(
      search.getIn(['departure', 'createdAt']),
    );
  }

  if (returns) {
    const typeOfTransport = search.getIn(['return', 'typeOfTransport']);
    const buses = returns.get('buses');

    analyticsData.returnData = {
      busCount: 0,
      flightCount: 0,
    };

    if (buses) {
      analyticsData.returnData.busCount = buses.get('trips').size;
    }

    analyticsData.returnData.busActive = typeOfTransport.bus.active;

    analyticsData.returnData.parentSearchId = search.getIn(['return', 'parentSearchId']);
    analyticsData.returnData.searchDuration = searchDuration(search.getIn(['return', 'createdAt']));
  }

  return analyticsData;
}
