import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { LinkButton, Spacing, Text } from '@reservamos/elements';
import moment from 'moment';
import { formatCurrency } from 'utils/Helpers';
import 'styles/components/purchase/TicketsPanel';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const OxxoPaymentInfo = ({ amount, pdfUrl, paydayLimit, reference }) => {
  const { t } = useTranslation('payment');
  const paydayLimitDisplay = moment(paydayLimit).format('LLLL');
  const amountDisplay = formatCurrency(amount, 2);

  return (
    <div className="tickets-panel">
      <div className="tickets-panel-content">
        <Spacing vertical size="S" alignItems="flex-start">
          <PaymentImage type="oxxo-logo" />
          <Text size="S">
            <Trans i18nKey="text.bring_payment_reference_to_pay" t={t}>
              text<b>text</b>text
            </Trans>
          </Text>
        </Spacing>
      </div>
      <div className="tickets-panel-content right-content">
        <div className="right-content-info">
          <Spacing vertical size="L">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('purchase:label.payment_reference')}:
              </Text>
              <Text size="XL" weight="bold" textAlign="center">
                {reference}
              </Text>
              <Text textAlign="center">
                <LinkButton
                  borderRadius
                  href={pdfUrl}
                  iconColor="white"
                  iconType="Download"
                  newTab
                  padding="M"
                  variant="accent"
                  text={t('button.print_payment_reference')}
                />
              </Text>
            </Spacing>
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.payday_limit')}:
              </Text>
              <Text size="XL" weight="bold" textAlign="center">
                {paydayLimitDisplay}
              </Text>
            </Spacing>
          </Spacing>
        </div>
        <div className="right-content-price">
          <Spacing vertical size="S">
            <Text size="S" textAlign="center" color="grayMedium">
              {t('label.waiting_for_your_payment')}:
            </Text>
            <div className="right-content-price-amount">
              <Text size="XL" weight="bold" textAlign="center">
                {amountDisplay}
              </Text>
            </div>
            <Text size="XS" italic>
              * {t('text.additional_commission_oxxo')}
            </Text>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

OxxoPaymentInfo.propTypes = {
  amount: PropTypes.string.isRequired,
  paydayLimit: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};

export default OxxoPaymentInfo;
