/* eslint-disable import/prefer-default-export */
import { exchange as exchangeApi } from 'js-api-client';
import pollingPromises from 'utils/pollingPromises';
import * as types from 'constants/ActionTypes';
import { setError } from '.';
import { createSchedulePath } from '../utils/exchange';
import customServerError from '../utils/purchase/customServerError';

/**
 * Function to validate the exchange
 * @param {*} history - History object
 * @param {*} operationNumbers - Operation numbers
 * @param {*} NIT - NIT number
 * @param {*} origin - Origin slug
 * @param {*} destination - Destination slug
 * @param {*} recaptchaToken - Recaptcha token
 * @returns {function} - Dispatch function
 */
export function validateExchange(
  history,
  operationNumbers,
  NIT,
  origin,
  destination,
  recaptchaToken,
  email,
  document,
) {
  return (dispatch) => {
    dispatch({ type: types.EXCHANGE_SET_LOADING, isLoading: true });
    return exchangeApi
      .validate(operationNumbers, NIT, origin, destination, recaptchaToken, email, document)
      .then((response) => {
        const { error } = response;
        if (error && error.message) {
          dispatch(setError(200, 'exchange_cannot_be_used', 'warning', false, error.message));
        } else if (Object.keys(response).length === 0) {
          dispatch(setError(200, 'exchange_cannot_be_used', 'warning', false));
          history.push('/exchange');
        } else {
          dispatch({ type: types.EXCHANGE_RECEIVE, data: response, nit: NIT });
          history.push(
            createSchedulePath(operationNumbers, NIT, origin, destination, email, document),
          );
        }
      })
      .catch((error) => {
        const { code, message } = error;
        dispatch(
          setError(code, 'exchange_not_found', 'warning', false, customServerError(code, message)),
        );
        history.replace('/exchange');
      })
      .finally(() => {
        dispatch({ type: types.EXCHANGE_SET_LOADING, isLoading: false });
      });
  };
}

/**
 * cancelTicket Function
 *
 * Sends a request to cancel a ticket using the provided purchase token and operation number.
 *
 * This function interacts with an API endpoint to process the cancellation of a ticket.
 *
 * @param {string} purchaseToken - The token used to authenticate the ticket purchase.
 * @param {string|number} operationNumber - The unique identifier for the operation to cancel the ticket.
 *
 * @returns {Promise<Object>} A promise that resolves to the response data from the cancellation request,
 *                            which may include the status and any additional information about the cancellation.
 *
 * @throws {Error} If the cancellation request fails, an error is thrown with the corresponding message.
 */
export function cancelTicket(purchaseToken, operationNumber) {
  return (dispatch) => {
    dispatch({ type: types.CANCEL_TICKET_LOADING, isLoading: true });
    return exchangeApi
      .cancelTicket(purchaseToken, operationNumber)
      .then((response) => {
        const { error } = response;
        if (error && error.message) {
          dispatch(setError(200, 'cancel_cannot_be_used', 'warning', false, error.message));
        } else {
          dispatch({ type: types.CANCEL_TICKET_RECEIVE, data: response });
          return response.id;
        }
      })
      .catch((error) => {
        const { code, message } = error;
        dispatch(
          setError(code, 'cancel_not_found', 'warning', false, customServerError(code, message)),
        );
      })
      .finally(() => {
        dispatch({ type: types.CANCEL_TICKET_LOADING, isLoading: false });
      });
  };
}

/**
 * getCancelledTicketStatusPoll Function
 *
 * Sends a request to poll the status of a cancelled ticket using the provided purchase token and cancellation ID.
 *
 * This function interacts with an API endpoint to retrieve the status of a previously cancelled ticket,
 * based on the purchase token and cancellation ID.
 *
 * @param {string} purchaseToken - The token used to authenticate the ticket purchase.
 * @param {string|number} idCancellation - The unique identifier for the ticket cancellation operation.
 *
 * @returns {Promise<Object>} A promise that resolves to the response data containing the current status of the cancelled ticket.
 *
 * @throws {Error} If the status polling request fails, an error is thrown with the corresponding message.
 */
export function getCancelledTicketStatusPoll(purchaseToken, idCancellation) {
  return (dispatch) => {
    dispatch({ type: types.STATUS_CANCEL_LOADING, isLoading: true });

    const polls = pollingPromises({
      data: idCancellation,
      name: 'onGetCancelStatus',
      finishedStatus: ['success'],
      failedStatus: ({ status }) =>
        status !== 'pending' && status !== 'finished' && status !== 'success',
      create: (polling, payload) =>
        exchangeApi.getCancelledTicketStatusPoll(purchaseToken, payload, polling),
    });

    return polls
      .then((pollResult) => {
        const { error, status } = pollResult;
        if (error && error.message) {
          dispatch(setError(200, 'cancel_cannot_be_used', 'warning', false, error.message));
        } else {
          dispatch(setError(null, 'cancel_success', 'success', false));
          dispatch({
            type: types.STATUS_CANCEL_RECEIVE,
            data: { status, created_at: pollResult.payload.created_at },
          });
        }
      })
      .catch((reason) => {
        const { status } = reason;
        if (reason.payload) {
          dispatch(setError(null, 'cancel_cannot_be_used', 'warning', false));
          dispatch({
            type: types.STATUS_CANCEL_RECEIVE,
            data: { status, created_at: reason.payload.created_at },
          });
        } else {
          dispatch(setError(200, 'cancel_not_found', 'warning', false));
          dispatch({
            type: types.STATUS_CANCEL_RECEIVE,
            data: { status },
          });
        }
      })
      .finally(() => {
        dispatch({ type: types.STATUS_CANCEL_LOADING, isLoading: false });
      });
  };
}

/**
 * Function to catch error
 * @param {*} errorTrip
 * @returns
 */
export function errorTripExchange(errorTrip) {
  return { type: types.ERROR_TRIP_EXCHANGE, errorTrip };
}

/**
 * Function to reset exchange
 * @returns
 */
export function resetExchange() {
  return { type: types.EXCHANGE_RESET };
}
