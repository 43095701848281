import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, FlatButton, CreditCardIcon } from '@reservamos/elements';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import SavedCardItem from '../../../ui/atoms/SavedCardItem';
import Divider from '../../../ui/atoms/Divider';

const SavedCards = ({
  cardList,
  useAnotherCard,
  selectCard,
  selectedCard,
  isLoading,
  deleteCard,
}) => {
  const { t } = useTranslation('purchase');
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Spacing vertical size="S">
            <Spacing justifyContent="space-between">
              <Text weight="bold" size="S">
                {t('label.saved_cards')}
              </Text>
              {isEditing ? (
                <FlatButton
                  size="S"
                  mobileSize="XS"
                  href="#"
                  type="error"
                  onClick={() => setIsEditing(false)}
                >
                  {t('label.cancel')}
                </FlatButton>
              ) : (
                <FlatButton
                  size="S"
                  mobileSize="XS"
                  href="#"
                  type="accent"
                  onClick={() => setIsEditing(true)}
                >
                  {t('label.update_cards')}
                </FlatButton>
              )}
            </Spacing>

            <Spacing size="S" vertical>
              {cardList.map((card) => {
                const { recurringDetail } = card;

                return (
                  <SavedCardItem
                    key={recurringDetail.recurringDetailReference}
                    id={recurringDetail.recurringDetailReference}
                    brand={recurringDetail.variant}
                    bankName={recurringDetail.card.holderName}
                    cardNumber={recurringDetail.card.number}
                    isEditing={isEditing}
                    checked={selectedCard === recurringDetail.recurringDetailReference}
                    onChange={() => selectCard(recurringDetail.recurringDetailReference)}
                    onDeleteClick={() => deleteCard(recurringDetail.recurringDetailReference)}
                  />
                );
              })}

              <Spacing alignItems="center" size="S">
                <CreditCardIcon hideBorder hasMargin={false} />
                <FlatButton size="S" href="#" type="accent" onClick={useAnotherCard}>
                  {t('label.pay_with_another_card')}
                </FlatButton>
              </Spacing>
            </Spacing>
            <Divider />
          </Spacing>
        </>
      )}
    </>
  );
};

SavedCards.propTypes = {
  cardList: PropTypes.array,
  selectedCard: PropTypes.string,
  useAnotherCard: PropTypes.func,
  deleteCard: PropTypes.func,
  selectCard: PropTypes.func,
  isLoading: PropTypes.bool,
};

SavedCards.defaultProps = {
  cardList: [],
  selectedCard: '',
  useAnotherCard: () => {},
  selectCard: () => {},
  deleteCard: () => {},
  isLoading: true,
};

export default SavedCards;
