import { ticketPriceByCategory } from 'utils/Reserbus';

function groupByPassengerType(fragments, passengers) {
  const preparedTickets = [];

  passengers.forEach(({ category, id }) => {
    fragments.forEach(({ tickets }) => {
      const passengerTicket = tickets.find(({ passengerId }) => passengerId === id);
      // infants don't generate tickets for buses, so the amount is 0
      const amount = passengerTicket ? passengerTicket.amount : 0;

      preparedTickets.push({ category, amount });
    });
  });

  return ticketPriceByCategory(preparedTickets, fragments.length);
}

function groupByBusTicketCategory(fragments, passengers) {
  const ticketsCopy = [];

  fragments.forEach((fragment) => {
    ticketsCopy.push(...fragment.tickets.map((ticket) => ({ ...ticket })));
    // infants don't generate tickets for buses, so the amount is 0
    passengers.forEach(({ category }) => {
      if (category === 'infant') {
        ticketsCopy.push({ category, amount: 0 });
      }
    });
  });

  return ticketPriceByCategory(ticketsCopy, fragments.length);
}

function prepareTicketsPricing(
  transportType,
  fragments,
  passengers,
  passengerCount,
  amount,
  displayPricingBeforeCheckout,
) {
  if (fragments.some(({ tickets }) => tickets.length < 1) || displayPricingBeforeCheckout) {
    return [
      {
        category: 'general',
        count: passengerCount,
        total: amount,
      },
    ];
  }

  if (transportType === 'bus') {
    return groupByBusTicketCategory(fragments, passengers);
  }

  return groupByPassengerType(fragments, passengers);
}

export default prepareTicketsPricing;
