import insuranceGFA from 'images/brands-unique/gfa/insurance.png';
import insuranceETN from 'images/brands-unique/etn/insuranceETN.png';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';

const localInsuranceIcons = {
  default: insuranceETN,
  gfa: insuranceGFA,
};

const useInsuranceConfig = () => {
  const { insurance, brand } = useWhitelabelEnvs();
  const localInsuranceIcon = localInsuranceIcons[brand] ?? localInsuranceIcons.default;

  return {
    icon: insurance?.icon || localInsuranceIcon,
    detailsUrl: insurance?.url || '',
  };
};

export default useInsuranceConfig;
