import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Button, Currency, Spacing, Text } from '@reservamos/elements';

/**
 * Return reminder to add the return simple ticket for the current trip.
 * @param props - Component props.
 * @param {Function} props.onClick - A function to be called when the button is clicked.
 * @param {number} props.title - Title to show in the card.
 * @param {number} props.price - The price of the return trip.hasBackgroundhasBackground
 * @param {string} props.origin - The origin location of the return trip.
 * @param {string} props.destination - The destination location of the return trip.
 * @param {string} props.hasBackground - Flag to indicate if it needs a background.
 * @returns {JSX.Element}
 */
const ReturnCard = ({ onClick, title, price, hasBackground }) => {
  const { t } = useTranslation();
  const titleToShow = title || t('trips:label.select_schedule');
  return (
    <Box
      bgColor={hasBackground && 'primary'}
      alphaBg="XS"
      borderRadius="M"
      borderColor="primary"
      border="bottom"
      paddingHorizontal="S"
      paddingVertical="S"
      hasShadow
      onClick={onClick}
    >
      <Spacing responsiveSize="S" vertical>
        <Spacing responsiveSize="M" justifyContent="space-between" alignItems="center">
          <Text size="S" weight="semibold" color="grayStrong">
            {titleToShow}
          </Text>

          <Spacing size="XXS" isResponsive alignItems="center" mobileAlign="flex-start">
            <Text color="grayStrong" size="XS">
              {t('general:from')}
            </Text>

            <Currency
              currency=""
              price={price}
              size="M"
              color="grayStrong"
              decimals="0"
              currencyLowercase
              weight="bold"
            />
          </Spacing>
        </Spacing>

        <Button
          text={t('common:buttons.add')}
          variant="noFill"
          isRounded
          fontSize="S"
          onClick={onClick}
        />
      </Spacing>
    </Box>
  );
};

ReturnCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  price: PropTypes.number,
  hasBackground: PropTypes.bool,
};

ReturnCard.defaultProps = {
  price: '',
  hasBackground: false,
};

export default ReturnCard;
