import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import TripItinerary from 'components/TripItinerary/TripItinerary';

/**
 * Component used as trip detail modal in results step for default/classic style.
 * @param {Object} props - Object props
 * @param {string} props.children - Indicates the component that will triger model opening.
 * @param {string} props.tripId - The selected trip id.
 * @param {object} props.trip - Full selected trip object.
 * @param {object} props.provider - Indicates brand's trip data with provider and services information.
 * @param {boolean} props.isPurchaseAttempt - Indicates if the user is inside purchase flow.
 * @returns {JSX.Element} A Modal component that renders a the current trip detail for result page.
 */
const RouteDetailsModal = ({ children, tripId, provider, trip, isPurchaseAttempt }) => {
  const { t } = useTranslation('search');

  // This component only uses default TripIntinerary
  return (
    <Modal
      responsiveSize="L"
      portalSelector=".portalModal"
      headerColor="primary"
      fixedHieght
      title={t('title.trip_details')}
      modalTrigger={children}
      zIndex={2999999999}
    >
      <TripItinerary
        headerDescription="total"
        providerDetails={provider}
        providerServices={provider.services}
        tripId={tripId}
        trip={trip}
        isOpenTicket={false}
        showDownloadTicketsStep={false}
        isPurchaseAttempt={isPurchaseAttempt}
      />
    </Modal>
  );
};

RouteDetailsModal.propTypes = {
  children: PropTypes.node.isRequired,
  tripId: PropTypes.string.isRequired,
  provider: PropTypes.object,
  trip: PropTypes.object.isRequired,
  isPurchaseAttempt: PropTypes.bool.isRequired,
};

RouteDetailsModal.defaultProps = {
  provider: {},
};

export default RouteDetailsModal;
