import React from 'react';
import PropTypes from 'prop-types';
import { Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import CheckList from '../../../ui/atoms/CheckList';
import BadgeNew from '../../../ui/atoms/BadgeNew';
import KushkiCashLogos from '../../../ui/templates/KushkiCashLogos';
import PaymentImage from '../../../ui/atoms/PaymentImage';

function KushkiStoreForm({ labels }) {
  const { t } = useTranslation('payment');

  return (
    <Spacing vertical>
      <Spacing alignItems="flex-end" size="S">
        <PaymentImage type="kushki-logo" />
        <BadgeNew />
      </Spacing>

      <KushkiCashLogos />

      <CheckList title={t('Store Form Title')} labels={labels} />
    </Spacing>
  );
}

KushkiStoreForm.propTypes = {
  labels: PropTypes.array.isRequired,
};

export default KushkiStoreForm;
