import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/components/search/NoResults';
import { Button } from '@reservamos/elements';

const propTypes = {
  resetTripFilters: PropTypes.func.isRequired,
};

/**
 * Message to notify that there is not trips that match with the filters selected.
 * @param {Object} props - Component props.
 * @param {function} props.resetTripFilters - Function to reset filters.
 * @returns Component.
 */
const NoFilteredResults = ({ resetTripFilters }) => {
  const { t } = useTranslation('search');

  /**
   * Function to handle the reset of the filters
   */
  const handleOnReset = () => {
    resetTripFilters(true);
  };

  return (
    <div className="no-filtered-results">
      <p className="trip-message">{t('label.not_trips_for_filters')}</p>
      <Button text={t('label.clean_filters')} variant="primary" onClick={handleOnReset} />
    </div>
  );
};
NoFilteredResults.propTypes = propTypes;

export default NoFilteredResults;
