import React from 'react';
import PropTypes from 'prop-types';
import { Text, CreditCardIcon, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/Helpers';
import './SmallNotice.scss';
import { getCurrencySuffix } from 'utils/currency';

const SmallNotice = ({ selectedInstallmentsPlan: { months, monthlyPayment } }) => {
  const { t } = useTranslation('purchase');

  return (
    <div className="small-notice animated fade-in">
      <Spacing size="XS" alignItems="center">
        <CreditCardIcon colorIcon="primary" hasMargin={false} />
        <Text color="grayStrong" size="S">
          <Text color="accent" weight="bold" size="S" elementType="span">
            {t('text.selection_of_payment_term', {
              months,
              currency_format: formatCurrency(monthlyPayment, 2),
              currency_suffix: getCurrencySuffix(),
            })}
          </Text>
        </Text>
      </Spacing>
    </div>
  );
};

SmallNotice.propTypes = {
  selectedInstallmentsPlan: PropTypes.shape({
    months: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
};

export default SmallNotice;
