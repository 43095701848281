import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '@reservamos/elements';
import 'styles/components/search/OpenTicketButton';

const OpenTicketWrapper = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="result-button-container fade-in">
      <Text size="S">
        <em>...{t('general:or_travel_with_open_ticket')}</em>
      </Text>
      {children}
    </div>
  );
};

OpenTicketWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OpenTicketWrapper;
