import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, LoaderPulse, Spacing } from '@reservamos/elements';

const LabelLoading = ({ text }) => {
  const { t } = useTranslation('search');

  const displayText = text === '' ? t('loading_results') : text;

  return (
    <Spacing size="S" alignItems="center">
      <Text size="S" color="grayLight">
        <em>{displayText}...</em>
      </Text>
      <LoaderPulse color="accent" hasWrapper={false} />
    </Spacing>
  );
};

LabelLoading.propTypes = {
  text: PropTypes.string,
};

LabelLoading.defaultProps = {
  text: '',
};

export default LabelLoading;
