import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import filtersTypes from '../constants/filtersTypes';

const { DEPARTURE_TIME } = filtersTypes;

function useScheduleFilterOptions(availableOptions, isNewDesign) {
  const { t } = useTranslation();
  const { HOUR_FORMAT: hourFormat } = useWhitelabelFeatures();
  const { hasEarlymorningTrips, hasMorningTrips, hasAfternoonTrips, hasNightTrips } =
    availableOptions;

  const iconNewDesignTypes = {
    morning: 'SortMorning',
    afternoon: 'SortAfternoon',
    night: 'SortEvening',
    earlymorning: 'SortNight',
  };

  const scheduleI18Keys = {
    morning: 'search:label.selector_morning',
    afternoon: 'search:label.selector_afternoon',
    night: 'search:label.selector_night',
    earlymorning: 'search:label.selector_earlymorning',
    none: 'search:label.selector_without_filters',
  };

  return [
    (hasMorningTrips || isNewDesign) && {
      title: t(scheduleI18Keys.morning),
      i18Key: scheduleI18Keys.morning,
      description: hourFormat === '24' ? '07:00 - 11:59' : '7:00 am - 11:59 am',
      icon: isNewDesign ? iconNewDesignTypes.morning : 'morning',
      value: 'morning',
      iconColor: 'warning',
      isAvailable: Boolean(hasMorningTrips),
      filterType: DEPARTURE_TIME,
    },
    (hasAfternoonTrips || isNewDesign) && {
      title: t(scheduleI18Keys.afternoon),
      i18Key: scheduleI18Keys.afternoon,
      description: hourFormat === '24' ? '12:00 - 18:59' : '12:00 pm - 6:59 pm',
      icon: isNewDesign ? iconNewDesignTypes.afternoon : 'afternoon',
      value: 'afternoon',
      iconColor: 'warning',
      isAvailable: Boolean(hasAfternoonTrips),
      filterType: DEPARTURE_TIME,
    },
    (hasNightTrips || isNewDesign) && {
      title: t(scheduleI18Keys.night),
      i18Key: scheduleI18Keys.night,
      description: hourFormat === '24' ? '19:00 - 23:59' : '7:00 pm - 11:59 pm',
      icon: isNewDesign ? iconNewDesignTypes.night : 'night',
      value: 'night',
      isAvailable: Boolean(hasNightTrips),
      filterType: DEPARTURE_TIME,
    },
    (hasEarlymorningTrips || isNewDesign) && {
      title: t(scheduleI18Keys.earlymorning),
      i18Key: scheduleI18Keys.earlymorning,
      description: hourFormat === '24' ? '00:00 - 06:59' : '12:00 am - 6:59 am',
      icon: isNewDesign ? iconNewDesignTypes.earlymorning : 'earlymorning',
      value: 'earlymorning',
      iconColor: 'grayStrong',
      isAvailable: Boolean(hasEarlymorningTrips),
      filterType: DEPARTURE_TIME,
    },
    !isNewDesign && {
      title: t(scheduleI18Keys.none),
      i18Key: scheduleI18Keys.none,
      description: t('search:label.description.selector_without_filters'),
      value: 'none',
      isAvailable: true,
      filterType: DEPARTURE_TIME,
    },
  ].filter(Boolean);
}

export default useScheduleFilterOptions;
