import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import CheckList from '../../ui/atoms/CheckList';
import 'styles/components/AgencyForm';

const ReservamosPayForm = () => {
  const { t } = useTranslation('loyalty');

  const labels = [t('pay_with_points'), t('purchase:text.an_email_will_be_sent')];

  return (
    <div className="agencypay">
      <Spacing vertical>
        <div className="agencypay-cont">
          <i className="agencypay-cont-icon" />
          <Text color="primaryStrong" size="L" weight="semibold">
            {t('wallet_name')}
          </Text>
        </div>

        <CheckList title={t('steps_to_pay_with_points')} labels={labels} />
      </Spacing>
    </div>
  );
};

export default ReservamosPayForm;
