import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, Spacing, Text } from '@reservamos/elements';
import openTicketETN from '../../images/brands-unique/etn/openTicketETN.svg';
import openTicketGFA from '../../images/brands-unique/gfa/open-ticket.svg';
import 'styles/components/purchase/RedeemOpenTicket';

const iconTypes = {
  default: openTicketETN,
  gfa: openTicketGFA,
};

const RedeemOpenTicket = ({ children, hasTitle, redeemDate, origin, destination, roundTrip }) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const openTicketIcon = iconTypes[env.brand] ?? iconTypes.default;
  return (
    <div className="redeem">
      {Boolean(hasTitle) && (
        <div className="tickets-title-wrapper">
          <p className="tickets-title">
            {roundTrip ? t('label.your_one_way_trip') : t('label.your_return_trip')} -
            {t('label.open_ticket')}
          </p>
        </div>
      )}
      <div className="redeem-inner">
        <Spacing vertical size="S">
          <Spacing size="XS">
            <Icon type={openTicketIcon} />
            <Text weight="semibold">
              {origin} - {destination}
            </Text>
          </Spacing>
          {children}
          {Boolean(redeemDate) && (
            <Spacing size="XS">
              <Text size="XS">Recuerda canjear tu boleto antes del día:</Text>
              <Text size="XS" weight="semibold">
                {redeemDate}
              </Text>
            </Spacing>
          )}
        </Spacing>
      </div>
    </div>
  );
};

RedeemOpenTicket.propTypes = {
  children: PropTypes.node.isRequired,
  hasTitle: PropTypes.bool,
  roundTrip: PropTypes.bool,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  redeemDate: PropTypes.string,
};

RedeemOpenTicket.defaultProps = {
  hasTitle: false,
  roundTrip: true,
  redeemDate: '',
};

export default RedeemOpenTicket;
