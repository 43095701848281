import React from 'react';
import PropTypes from 'prop-types';
import { Box, Gap, Icon, Spacing, Text } from '@reservamos/elements';
import './FilterButton.scss';
import { useTranslation } from 'react-i18next';
import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';

/**
 * Represents the Filter Button component.
 * @component
 * @param {Object} props - The props of the component.
 * @param {Function} props.onClick - Function to handle button click.
 * @param {number} props.filtersCount - The count of applied filters.
 * @param {boolean} props.disabled - Whether the button is disabled or not.
 * @param {boolean} props.isFlat - Whether the button is flat or not.
 */
const FilterButton = ({ onClick, filtersCount = 0, disabled, isFlat }) => {
  const counterFiltersApplied = true;
  const { t } = useTranslation('search');
  const isNewResultsDesign = useGrowthBookFeatureValue('new_results_design');
  const buttonText = isNewResultsDesign && !isFlat ? t('label.custom_search') : t('label.filters');
  return (
    <div className={`filter ${disabled ? 'disabled' : ''}`}>
      <button className="filter-button" disabled={disabled} onClick={!disabled && onClick}>
        {counterFiltersApplied && (
          <span className="filter-counter">
            <Text elementType="span" size="XXS" color="primary" weight="bold">
              {filtersCount}
            </Text>
          </span>
        )}
        <Box
          borderRadius="M"
          border={isFlat ? 'all' : ''}
          bgColor={isFlat ? 'white' : 'primary'}
          borderColor="accent"
        >
          <Spacing size="XS" alignItems="center" elementType="span">
            <Box
              paddingHorizontal="XS"
              paddingVertical="XS"
              bgColor="accent"
              borderRadius={isFlat ? 'S' : 'M'}
              hasShadow={isFlat}
              elementType="span"
            >
              <Spacing alignItems="center" justifyContent="center" elementType="span">
                <Icon type="FilterBy" color="white" />
              </Spacing>
            </Box>
            <Text
              mobileSize="S"
              color={isFlat ? 'primary' : 'white'}
              weight={isFlat ? 'bold' : 'regular'}
            >
              {buttonText}
            </Text>
            <Gap />
          </Spacing>
        </Box>
      </button>
    </div>
  );
};

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  isFlat: PropTypes.bool,
};

export default FilterButton;
