/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import RDatePicker from 'react-datepicker';
import { CurrentLang } from 'utils/lang';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import ptBr from 'date-fns/locale/pt-BR';
// eslint-disable-next-line import/extensions
import '../styles/vendors/ReactDatePicker.scss';

/**
 * Date picker component that implements react-datepicker
 * @param {*} param0 - Component props
 * @param {string} param0.value - Initial value
 * @param {string} param0.min - Minimum date
 * @param {string} param0.max - Maximum date
 * @param {function} param0.onChange - Callback function to be called when the date changes
 * @param {object} param0.input - Custom input component
 * @param {object} param0.modal - Show date picker in a modal/portal
 * @example
 * <DatePicker
 *  value={date}
 *  min="01-01-2020"
 *  max="01-01-2021"
 *  onChange={setDate}
 *  input={<Input label="Date" />}
 * />
 * @returns {JSX} - React component
 */
const DatePicker = ({ value, min: minProp, max: maxProp, onChange, input, modal }) => {
  const min = minProp ? new Date(minProp) : new Date();
  const max = maxProp ? new Date(maxProp) : new Date(moment().add(1, 'year'));
  const initialValue = value ? moment(value, 'DD-MM-YYYY').toDate() : min;
  const lang = CurrentLang();

  const locales = {
    'es-MX': es,
    'en-US': en,
    'es-CO': es,
    'pt-BR': ptBr,
    'default': es,
  };

  /**
   * Callback function to be called when the date changes
   */
  const onDateChange = (date) => {
    if (typeof onChange === 'function') {
      const formattedDate = moment(date).format('DD-MM-YYYY');
      onChange(formattedDate);
    }
  };

  return (
    <RDatePicker
      selected={initialValue}
      onChange={onDateChange}
      calendarClassName="funnel"
      minDate={min}
      maxDate={max}
      withPortal={modal}
      dateFormat="dd-MM-yyyy"
      value={initialValue}
      customInput={input}
      locale={locales[lang] || locales.default}
      aria
    />
  );
};

DatePicker.propTypes = {
  input: PropTypes.object,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func,
  modal: PropTypes.bool,
};

DatePicker.defaultProps = {
  modal: false,
};

export default DatePicker;
