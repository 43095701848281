import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import 'styles/components/search/DetailsButton';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  showDetails: PropTypes.func.isRequired,
  tripId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

const DetailsButton = ({ isLoading, position, showDetails, tripId, isDisabled }) => {
  const { t } = useTranslation('search');
  const loadingClass = isLoading ? 'is-loading' : '';
  const disabledClass = isDisabled ? 'is-disabled' : '';
  return (
    <button
      onClick={() => showDetails(tripId, position)}
      className={`result-box-details-button ${loadingClass} ${disabledClass}`}
      disabled={isDisabled}
    >
      {t('button.see_route')}
      <Loader isLoading={isLoading} />
    </button>
  );
};
DetailsButton.propTypes = propTypes;

export default DetailsButton;
