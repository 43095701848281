import React from 'react';
import PropTypes from 'prop-types';
import OptionsSelector from './OptionsSelector';
import useAvailableSchedulesOptions from '../hooks/useAvailableSchedulesOptions';

/**
 * Component for selecting a schedule filter.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - The function to be called when the schedule filter changes.
 * @param {string} props.value - The selected value of the schedule filter.
 * @param {string} props.way - The direction of the trip.
 * @returns {JSX.Element} The rendered component.
 */
const ScheduleFilterSelector = ({ onChange, value, way }) => {
  const options = useAvailableSchedulesOptions(way);
  return <OptionsSelector onChange={onChange} options={options} value={value} />;
};

ScheduleFilterSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  way: PropTypes.string.isRequired,
};

ScheduleFilterSelector.defaultProps = {
  value: null,
};

export default ScheduleFilterSelector;
