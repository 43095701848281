import { Map } from 'immutable';
import * as types from 'constants/ActionTypes';

const defaultOverlayView = Map({
  citySearchVisible: false,
  tripSortVisible: false,
  purchaseReviewVisible: false,
});

export default function overlayView(overlayView = defaultOverlayView, action) {
  switch (action.type) {
    case types.TOGGLE_PURCHASE_REVIEW_OVERLAY:
      return overlayView.set('purchaseReviewVisible', action.visible);

    default:
      return overlayView;
  }
}
