import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spacing, Input, Button, Card } from '@reservamos/elements';
import Header from 'components/Header';
import SectionTitle from 'ui/atoms/SectionTitle';
import { useTranslation } from 'react-i18next';
import { purchase as purchaseApi } from 'js-api-client';
import { setError as setErrorAction } from '../../actions';
import BrandLogo from '../BrandLogo';
import './DownloadTickets.scss';

function DownloadTickets() {
  const [token, setToken] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const { t } = useTranslation('purchase');
  const { t: tN } = useTranslation('notifications');

  const dispatch = useDispatch();
  const { brand } = useSelector((state) => state.whitelabelConfig.env);

  const getTicket = () => {
    setLoading(true);
    const payload = {
      transporter_key: token,
      transporter_abbr: brand,
    };
    purchaseApi
      .downloadTickets(payload)
      .then((response) => {
        const { url } = response;
        if (!url) throw new Error();

        setToken('');
        window.open(response.url, '_blank');
      })
      .catch(() => {
        dispatch(setErrorAction(404, 'tickets_not_found', 'error', false));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validToken = (value) => {
    const isValid = Boolean(value && /^[a-zA-Z0-9]*$/.test(value));
    if (!isValid) {
      setError(tN('error.invalid_reference_number'));
    } else {
      setError(false);
    }
    return isValid;
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    validToken(value);
    setToken(value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (validToken(token)) getTicket();
  };

  return (
    <div className="l-app-home">
      <Header isFixed>
        <BrandLogo />
      </Header>

      <div className="home-container">
        <Card padding="XL">
          <Spacing vertical>
            <SectionTitle
              title={t('label.download_your_tickets')}
              description={t('label.download_ticket_instructions')}
            />
            <Spacing vertical>
              <form className="download-ticket-form" onSubmit={handleOnSubmit}>
                <Spacing vertical>
                  <Input
                    type="text"
                    id="purchaseToken"
                    value={token}
                    name="token"
                    onChange={handleOnChange}
                    label={t('label.purchase_number')}
                    isDisabled={loading}
                    hasError={error}
                    errorMessage={error}
                  />
                  <Spacing justifyContent="flex-end">
                    <Button
                      padding="L"
                      buttonType="submit"
                      variant="accent"
                      text={t('button.download_tickets')}
                      isLoading={loading}
                      isDisabled={loading}
                      withHeight
                    />
                  </Spacing>
                </Spacing>
              </form>
            </Spacing>
          </Spacing>
        </Card>
      </div>
    </div>
  );
}

export default DownloadTickets;
