import React from 'react';
import PropTypes from 'prop-types';
import PaymentStepStatus from 'components/purchase/PaymentStepStatus';
import { useTranslation } from 'react-i18next';

const propTypes = {
  status: PropTypes.shape({
    payment: PropTypes.string.isRequired,
    tickets: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
  }).isRequired,
  transporterType: PropTypes.string.isRequired,
  title: PropTypes.string,
  email: PropTypes.string,
};

const PaymentStatus = ({ status, transporterType, title, email }) => {
  const { t } = useTranslation('payment');
  return (
    <div>
      {title && <h2 className="tickets-title">{title}</h2>}
      <PaymentStepStatus status={status.payment} title="payment" />
      <PaymentStepStatus status={status.tickets} title="tickets" />
      <PaymentStepStatus
        transporterType={transporterType}
        status={status.sent}
        title="sent"
        email={email}
      />
      <p className="payment-steps-disclaimer">{t('text.check_spam_email')}</p>
    </div>
  );
};

PaymentStatus.propTypes = propTypes;

export default PaymentStatus;
