import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DepartureSeats from 'components/purchase/DepartureSeats';
import ReturnSeats from 'components/purchase/ReturnSeats';

/**
 * SeatSelectorFactory component for displaying bus diagram.
 * @param {Object} props - Props.
 * @param {String} props.way - Departure or return.
 * @param {Object} props.trip - Object trip
 * @param {function} props.finishSeatsSelection - function to select trip.
 * @param {Object} props.fromTrip - object departure trip only use for return
 * @param {boolean} props.isPurchaseView - boolean to know if is purchase view.
 * @param {function} props.onCloseModal - function to close modal.
 * @param {boolean} props.resultsLayout - boolean to know if is modal mode.
 * @returns {JSX.Element} JSX.Element
 */
const SeatSelectorFactory = ({
  way = 'departure',
  trip,
  finishSeatsSelection,
  fromTrip,
  isPurchaseView,
  onCloseModal,
  resultsLayout,
}) => {
  const history = useHistory();

  return way === 'departure' ? (
    <DepartureSeats
      way="departure"
      trip={trip}
      history={history}
      isPurchaseView={isPurchaseView}
      finishSeatsSelection={finishSeatsSelection}
      onClose={onCloseModal}
      resultsLayout={resultsLayout}
    />
  ) : (
    <ReturnSeats
      way="return"
      trip={trip}
      history={history}
      isPurchaseView={isPurchaseView}
      finishSeatsSelection={finishSeatsSelection}
      fromTrip={fromTrip}
      onClose={onCloseModal}
      resultsLayout={resultsLayout}
    />
  );
};

SeatSelectorFactory.propTypes = {
  trip: PropTypes.object,
  way: PropTypes.string.isRequired,
  finishSeatsSelection: PropTypes.func,
  fromTrip: PropTypes.object,
  isPurchaseView: PropTypes.bool,
  onCloseModal: PropTypes.func,
  resultsLayout: PropTypes.bool,
};

export default SeatSelectorFactory;
