import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Spacing, Link } from '@reservamos/elements';
import { fieldRenderer } from 'utils/formRenderers';
import { CurrentLang } from 'utils/lang';
import { useSelector } from 'react-redux';
import normalizeName from '../../../utils/normalizeName';
import InfoText from '../../../ui/atoms/InfoText';

const PetsPassengersForm = ({ passenger }) => {
  const { t } = useTranslation('passengers');
  const {
    copies: { policies },
  } = useSelector((state) => state.whitelabelConfig);
  const lang = CurrentLang();

  const { termsAndConditionsPetFriendlyUrl } = policies[lang];

  return (
    <Spacing vertical size="S">
      <Spacing size="S" flexGrow>
        <Field
          name={`${passenger}.firstName`}
          type="text"
          placeholder={t('fore_name')}
          normalize={normalizeName}
          component={fieldRenderer}
        />
        <Field
          name={`${passenger}.lastName`}
          type="text"
          placeholder={t('family_name')}
          normalize={normalizeName}
          component={fieldRenderer}
        />
      </Spacing>
      <InfoText>
        <Link
          href={termsAndConditionsPetFriendlyUrl}
          text={t('pet_terms')}
          type="accent"
          size="S"
          weight="regular"
          newTab
        />
      </InfoText>
    </Spacing>
  );
};

PetsPassengersForm.propTypes = {
  passenger: PropTypes.object.isRequired,
};

PetsPassengersForm.defaultProps = {};

export default PetsPassengersForm;
