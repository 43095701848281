import React from 'react';
import './DotersHeader.scss';
import LoyaltyWidget from '../../components/LoyaltyWidget';
import useWhitelabelFeatures from '../../hooks/whitelabel/useWhitelabelFeatures';
import useLoyaltyPrograms from '../../loyalty/context/useLoyaltyPrograms';

const DotersHeader = () => {
  const features = useWhitelabelFeatures();
  const {
    doters: { profile },
  } = useLoyaltyPrograms();

  if (!profile?.email || !features.DOTERS_ENABLED) return null;

  return <LoyaltyWidget id="doters" variant="header" visible />;
};

export default DotersHeader;
