import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { Spacing } from '@reservamos/elements';
import '../styles/components/InfiniteScroll';

const defaultProps = {
  show: 10,
};

const propTypes = {
  items: PropTypes.array,
  show: PropTypes.number,
  isHorizontal: PropTypes.bool,
  usesSideBar: PropTypes.bool,
};

/**
 * InfiniteScroll component
 * @param {number} show - Number of items to show
 * @param {array} items - Array of items to show
 * @param {boolean} isHorizontal - If the items are shown horizontally
 * @param {boolean} usesSideBar - If the items are shown with a sidebar
 * @returns {JSX.Element} - InfiniteScroll component
 */
function InfiniteScroll({ show, items, isHorizontal, usesSideBar }) {
  const [currentlyShowing, setCurrentlyShowing] = useState(show);
  const showingItems = items.slice(0, currentlyShowing);

  const resetShowingHandler = useCallback(() => {
    setCurrentlyShowing(show);
  }, [show]);
  const showMoreElementsHandler = useCallback(() => {
    setCurrentlyShowing(currentlyShowing + show);
  }, [currentlyShowing, show]);

  return (
    <div>
      <Waypoint onEnter={resetShowingHandler} />

      <Spacing size="S" vertical={!isHorizontal} flexGrow>
        {showingItems?.length > 2 && usesSideBar && isHorizontal ? (
          <div className="horizontal-scroll">
            <Spacing size="S">{showingItems}</Spacing>
          </div>
        ) : (
          showingItems
        )}
      </Spacing>

      {items.length > currentlyShowing ? <Waypoint onEnter={showMoreElementsHandler} /> : null}
    </div>
  );
}

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;

export default InfiniteScroll;
