import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import AncillariesSwitch from './AncillariesSwitch';
import dotersGreen from '../../images/doters/greendoters.svg';

/**
 * Component that uses AncillariesSwitch's base.
 * @returns Doters's flatfare switch
 */
const DotersRewardsSwitch = ({ onChange, checked, isLoading, disabled }) => {
  const { t } = useTranslation('purchase');
  const features = useWhitelabelFeatures();
  return (
    <AncillariesSwitch
      headerTitle={t('title.apply_reward_ticket', { context: features.FLAT_FARE_CONTEXT })}
      isDoters
      headerIcon={dotersGreen}
      onChange={onChange}
      checked={checked}
      isLoading={isLoading}
      disabled={disabled}
    >
      <Text>
        <Trans>{t('text.use_reward_ticket')}</Trans>
      </Text>
    </AncillariesSwitch>
  );
};

DotersRewardsSwitch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

DotersRewardsSwitch.defaultProps = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DotersRewardsSwitch;
