import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Spacing, Input, Card } from '@reservamos/elements';
import MainButton from 'ui/atoms/MainButton';
import Header from 'components/Header';
import SectionTitle from 'ui/atoms/SectionTitle';
import { useTranslation } from 'react-i18next';
import BrandLogo from '../BrandLogo';
import { getAllUserTickets, setUserTickets } from '../../actions/tickets';

function PurchasesHistoryForm() {
  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    secondLastName: '',
  };
  const history = useHistory();
  const { features } = useSelector((state) => state.whitelabelConfig);

  const { t } = useTranslation('passengers');
  const { t: tG } = useTranslation('general');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserTickets([], {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    secondLastName: yup.string(),
  });

  const handleOnSubmit = (values) => {
    const { firstName, lastName, secondLastName, email } = values;
    dispatch(
      getAllUserTickets({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        secondLastName: secondLastName.trim(),
        email: email.trim(),
      }),
    );
    history.push('/download-tickets/tickets');
  };

  return (
    <div className="l-app-home">
      <div className="header-block" />
      <div className="header-fixed">
        <Header>
          <BrandLogo />
        </Header>
      </div>

      <div className="home-container">
        <Card padding="XL">
          <Spacing vertical>
            <SectionTitle
              title={tG('print_tickets')}
              description={tG('download_ticket_instructions')}
            />
            <Spacing vertical>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
              >
                {({ values, handleChange, handleBlur, errors, touched }) => {
                  const { firstName, lastName, secondLastName, email } = values;
                  return (
                    <Form className="download-ticket-form">
                      <Spacing vertical>
                        <Spacing flexGrow={1} isResponsive responsiveSize="S">
                          <Input
                            type="text"
                            id="txtName"
                            value={firstName}
                            name="firstName"
                            onChange={handleChange}
                            label={t('fore_name')}
                            hasError={errors.firstName && touched.firstName}
                            onBlur={handleBlur}
                          />
                          <Input
                            type="text"
                            id="txtLastName"
                            value={lastName}
                            name="lastName"
                            onChange={handleChange}
                            label={t('family_name_one')}
                            hasError={errors.lastName && touched.lastName}
                            onBlur={handleBlur}
                          />
                        </Spacing>
                        <Spacing flexGrow={1} isResponsive responsiveSize="S">
                          <Input
                            type="text"
                            id="txtSecondLastName"
                            value={secondLastName}
                            name="secondLastName"
                            onChange={handleChange}
                            label={`${t('family_name_two')} ${t('optional')}`}
                            hasError={errors.secondLastName && touched.secondLastName}
                            onBlur={handleBlur}
                          />
                          <Input
                            type="email"
                            id="txtEmail"
                            value={email}
                            name="email"
                            onChange={handleChange}
                            label={t('email')}
                            hasError={errors.email && touched.email}
                            onBlur={handleBlur}
                          />
                        </Spacing>
                        <Spacing justifyContent="flex-end">
                          <MainButton
                            label={tG('button.continue')}
                            isFlat={features.FUNNEL_STYLE === 'FLAT'}
                          />
                        </Spacing>
                      </Spacing>
                    </Form>
                  );
                }}
              </Formik>
            </Spacing>
          </Spacing>
        </Card>
      </div>
    </div>
  );
}

export default PurchasesHistoryForm;
