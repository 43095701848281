import React from 'react';
import PropTypes from 'prop-types';
import './PaymentImage.scss';

const PaymentImage = ({ type }) => <i className={`payment-image ${type}`} />;

PaymentImage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PaymentImage;
