import { decamelizeKeys } from 'humps';
import { purchase as purchaseApi } from 'js-api-client';
import { setError } from '.';
import { LOADING_TICKETS, SET_TICKETS } from '../constants/ActionTypes';

function loadingTickets(isLoading) {
  return {
    type: LOADING_TICKETS,
    isLoading,
  };
}

export function setUserTickets(tickets, userData) {
  return {
    type: SET_TICKETS,
    tickets,
    userData,
  };
}

/**
 * Get all the purchase with the tickets url of a user
 * @param {*} userData - User data needed to get the user tickets
 */
export function getAllUserTickets(userData) {
  return (dispatch) => {
    dispatch(loadingTickets(true));
    purchaseApi
      .downloadPurchasesHistory(decamelizeKeys(userData))
      .then((tickets) => {
        if (tickets.length) {
          dispatch(setUserTickets(tickets, userData));
        } else {
          dispatch(setError(204, 'not_purchases_found_tickets', 'warning', false));
        }
      })
      .catch(() => {
        dispatch(setError(204, 'error_getting_purchases_tickets', 'error', false));
      })
      .finally(() => {
        dispatch(loadingTickets(false));
      });
    dispatch(loadingTickets(true));
  };
}
