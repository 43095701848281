/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Text, Spacing, Input, Button, MessageBox, FlatButton } from '@reservamos/elements';
import './DotersModalOtp.scss';
import useWhitelabelFeatures from '../../../hooks/whitelabel/useWhitelabelFeatures';

const DotersModalOtp = ({
  userEmail,
  handleOnConfirmOtp,
  visible,
  isLoading,
  confirmationError,
  handleOnGenerateOTP,
}) => {
  const features = useWhitelabelFeatures();
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('payment');
  const { t: tN } = useTranslation('notifications');
  const { t: tG } = useTranslation('general');

  useEffect(() => {
    if (!visible) return;
    const firstInput = document.getElementsByName('0')[0];
    if (firstInput) firstInput.focus();
  }, [visible]);

  if (!visible || !features.DOTERS_ENABLED || !features.USE_OTP || !userEmail) return null;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (/[^0-9\b]/.test(value)) return;
    const newCode = [...code];
    newCode[name] = value;
    setCode(newCode);
    const inputToFocus = document.getElementsByName(Number(name) + 1)[0];
    if (/[0-9]/.test(value) && inputToFocus) inputToFocus.focus();
  };

  const handleOnSubmit = () => {
    setSubmitted(true);
    const codeStr = code.filter((c) => c !== '').join('');
    if (codeStr.length !== 6) return;
    handleOnConfirmOtp(codeStr);
  };

  const handleOnFocus = (e) => {
    const { value } = e.target;
    if (value) e.target.select();
  };

  return (
    <Modal headerColor="loyalty" responsiveSize="L" headerImg={<i className="doters-white" />}>
      <Spacing vertical>
        {confirmationError && (
          <MessageBox borderColor="error">
            <Text>{tN(`error.${confirmationError}`)}</Text>
          </MessageBox>
        )}
        <Text size="L" weight="bold">
          {t('label.use_your_doters_points')}
        </Text>
        <Text>
          {t('doters_instructions.we_had_sent_code')} <b>{userEmail}</b>.
          {t('doters_instructions.look_at_email')}
        </Text>
        <Text>{t('doters_instructions.once_received')}</Text>
      </Spacing>
      <Spacing vertical>
        <div className="input-layout">
          <Input
            name="0"
            maxLength="1"
            onChange={handleOnChange}
            value={code[0]}
            hasError={submitted && !code[0]}
            disabled={isLoading}
            onFocus={handleOnFocus}
            isDisabled={isLoading}
          />
          <Input
            name="1"
            maxLength="1"
            onChange={handleOnChange}
            value={code[1]}
            hasError={submitted && !code[1]}
            disabled={isLoading}
            onFocus={handleOnFocus}
            isDisabled={isLoading}
          />
          <Input
            name="2"
            maxLength="1"
            onChange={handleOnChange}
            value={code[2]}
            hasError={submitted && !code[2]}
            disabled={isLoading}
            onFocus={handleOnFocus}
            isDisabled={isLoading}
          />
          <Input
            name="3"
            maxLength="1"
            onChange={handleOnChange}
            value={code[3]}
            hasError={submitted && !code[3]}
            disabled={isLoading}
            onFocus={handleOnFocus}
            isDisabled={isLoading}
          />
          <Input
            name="4"
            maxLength="1"
            onChange={handleOnChange}
            value={code[4]}
            hasError={submitted && !code[4]}
            disabled={isLoading}
            onFocus={handleOnFocus}
            isDisabled={isLoading}
          />
          <Input
            name="5"
            maxLength="1"
            onChange={handleOnChange}
            value={code[5]}
            hasError={submitted && !code[5]}
            disabled={isLoading}
            onFocus={handleOnFocus}
            isDisabled={isLoading}
          />
        </div>
        <Text size="S" textAlign="center">
          {t('label.have_not_received_code')}{' '}
          <FlatButton onClick={handleOnGenerateOTP} type="accent">
            {t('link_text.doters_resend_code')}
          </FlatButton>
        </Text>
        <Spacing justifyContent="center">
          <Button
            onClick={handleOnSubmit}
            className="confirm"
            text={tG('button.confirm')}
            variant="primary"
            isRounded
            isLoading={isLoading}
          />
        </Spacing>
      </Spacing>
    </Modal>
  );
};

DotersModalOtp.propTypes = {
  userEmail: PropTypes.string.isRequired,
  handleOnConfirmOtp: PropTypes.func,
  handleOnGenerateOTP: PropTypes.func,
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
  confirmationError: PropTypes.string,
};

export default DotersModalOtp;
