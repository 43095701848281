import { Map } from 'immutable';
import analyticsMiddleware from 'metrics/user-analytics/middleware';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import errorMonitoring from '../errorMonitoring';
import rootReducer from '../reducers/index';
import defaultPurchase from './defaultPurchase';
import getDefaultFiltersValue from './getDefaultFiltersValue';

const configureStore = (preloadedState = {}) => {
  const defaultTripFilters = Map({
    sortBy: preloadedState.whitelabelConfig.features.SORT_TRIPS_BY_PRICE ? 'price' : 'departure',
    filters: getDefaultFiltersValue(),
  });

  const selectedPaymentOption = preloadedState.whitelabelConfig.env.defaultPaymentOption;
  const selectedPaymentMethod = preloadedState.whitelabelConfig.env.defaultPaymentMethod;
  defaultPurchase.createInstance({ selectedPaymentOption, selectedPaymentMethod });
  preloadedState.purchase = defaultPurchase.getInstance();
  preloadedState.tripFilters = defaultTripFilters;

  const baseMiddlewares = applyMiddleware(thunk, analyticsMiddleware);
  let middlewares = baseMiddlewares;

  if (
    errorMonitoring?.getReduxMiddlewares &&
    typeof errorMonitoring?.getReduxMiddlewares === 'function'
  ) {
    middlewares = compose(baseMiddlewares, errorMonitoring.getReduxMiddlewares());
  }

  const enableTrace = Boolean(process.env.RESERVAMOS_PUBLIC_ENABLE_REDUX_TRACE);
  const traceLimit = Number(process.env.RESERVAMOS_PUBLIC_REDUX_TRACE_LIMIT || 25);
  const composedEnhancers = composeWithDevTools({ trace: enableTrace, traceLimit })(middlewares);

  return createStore(rootReducer, preloadedState, composedEnhancers);
};

export default configureStore;
