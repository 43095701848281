import { Map } from 'immutable';
import * as types from 'constants/ActionTypes';

const defaultFilters = Map({
  departureTime: ['none'],
  departureLocation: 'none',
  arrivalLocation: 'none',
  stopovers: 'none',
  stops: ['none'],
  tripOptions: [],
  categories: [],
});

const defaultSortBy = '';

const defaultTripFilters = Map({
  sortBy: defaultSortBy,
  filters: defaultFilters,
});

export default function tripFilters(tripFilters = defaultTripFilters, action) {
  switch (action.type) {
    case types.SET_TRIP_SORT_ORDER:
      return tripFilters.set('sortBy', action.sortBy);

    case types.SET_TRIP_FILTER: {
      const filters = {
        ...defaultFilters.toJS(),
        ...(action.keepFiltersSelected && tripFilters.toJS().filters),
        ...(action.filterBy && { [action.filterBy]: action.active }),
        ...(action.filters && action.filters),
      };
      return tripFilters.set('filters', Map(filters));
    }

    case types.RESET_TRIP_FILTERS:
      return tripFilters
        .set(
          'filters',
          Map({
            departureTime: ['none'],
            departureLocation: 'none',
            arrivalLocation: 'none',
            stopovers: 'none',
            stops: ['none'],
            categories: [],
            tripOptions: [],
          }),
        )
        .set('sortBy', defaultSortBy);

    default:
      return tripFilters;
  }
}
