import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { toggleModal } from '@/actions';
import { setProviderSortOrder } from '@/actions/providers';
import { setRedirectParams } from '@/actions/search';
import { newPurchase, fetchTripsDetails } from '@/actions/purchase';
import {
  setTripSortOrder,
  setTripFilter,
  setTripFilters,
  resetTripFilters,
} from '@/actions/tripFilters';
import { showTripDetails } from '@/actions/trip';
import { resetSeatBus } from '@/actions/seatsBus';
import parseTripDetails, { getTripTerminalInfo } from 'utils/trips/parseTripDetails';
import parseQueryString from 'utils/queryString/parseQueryString';
import stringifyQueryString from 'utils/queryString/stringifyQueryString';
import {
  tripsLocationByProvider,
  filterTrips,
  stopsFilter as stopsEvaluator,
} from 'utils/tripFilters';
import { getBaseTripDiscountPercent } from 'models/parseTrip';
import {
  getRecommendedTripsExcludeList,
  getRecommendedTripsListBySearch,
} from 'utils/userPreferences';
import { passengersNotAvailable } from 'utils/passengers';
import { isOldSearchPassengersFormat } from 'utils/Reserbus';
import ProviderDepartures from './ProviderDepartures';

const isSearchPolling = (trips) =>
  !trips || trips.valueSeq().some((transportType) => transportType.get('isPolling'));

/**
 * Retrieve open ticket from the trips list
 * @param {Object} trips - List of trips
 * @param {String} [providerId] - Provider id
 * @returns {Object} - Open ticket trip
 */
const retrieveOpenTrip = (trips, providerId) => {
  if (!trips || trips.size === 0) return null;
  const openTrips = trips
    .filter((t) => t.get('openTicket') && (!providerId || t.get('lineId') === providerId))
    .sort((a, b) => a.getIn(['pricing', 'total']) - b.getIn(['pricing', 'total']));

  return openTrips.size > 0 ? openTrips.first().toJS() : null;
};

const isStopsFilterAvailable = (trips) => {
  return (
    trips &&
    trips.some((t) => stopsEvaluator(t, 'direct')) &&
    trips.some((t) => stopsEvaluator(t, 'multiple'))
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    config,
    providers,
    search,
    trips,
    cities,
    terminals,
    overlayView,
    tripsDetails,
    tripFilters,
    siemprePlus,
    whitelabelConfig: { features, env },
    exchange,
    userPreferences,
  } = state;
  const { match, location } = ownProps;
  const {
    departureDate,
    returnDate,
    passengers = 'A1',
    providerId: paramsProviderId,
    isOpen,
  } = match.params;
  const { providerName, providerId: queryProviderId } = parseQueryString(location.search);
  const providerId = paramsProviderId || queryProviderId;
  const places = { ...cities, ...terminals };
  const searchId = search.getIn(['departure', 'id']);
  const searchIsPolling = isSearchPolling(trips.get(searchId));
  const paymentPlansList = config.paymentPlans;
  const providerList = providers.getIn([searchId, 'list']);
  let provider = providerList && providerList.find((el) => el.get('id') === providerId);
  const transportType = provider && provider.get('transportType');

  // Filters section
  const sortBy = tripFilters.get('sortBy');
  const filters = tripFilters.get('filters');
  const departureTimeFilter = filters.get('departureTime');
  const stopsFilter = filters.get('stops');
  const categoriesFilter = filters.get('categories');
  const tripOptionsFilter = filters.get('tripOptions');
  const dLocationFilter = filters.get('departureLocation');
  const aLocationFilter = filters.get('arrivalLocation');

  const activeDepartureFilter = Array.isArray(departureTimeFilter)
    ? !departureTimeFilter.includes('none')
    : departureTimeFilter !== 'none';

  const activeLocationFilter = dLocationFilter !== 'none' || aLocationFilter !== 'none';
  const activeCategoriesFilter = categoriesFilter?.length;
  const activeTripOptionsFilter = tripOptionsFilter?.length;

  const activeStopsFilter =
    typeof stopsFilter === 'string' ? stopsFilter !== 'none' : !stopsFilter.includes('none');
  const activeFilters =
    activeDepartureFilter ||
    activeLocationFilter ||
    activeStopsFilter ||
    activeCategoriesFilter ||
    activeTripOptionsFilter;

  const hasDynamicPromotion = search.get('promotions') ? search.get('promotions').size > 0 : false;
  const visibleTrip = tripsDetails.get('visibleTrip');
  const couponCode = search.get('couponCode');
  const discountedTripsByCoupon = search.getIn(['discountedTripsByCoupon', couponCode], []);

  let filteredTrips;
  let tripList;
  let tripsWithoutFilters;

  const nearTerminals = search.getIn(['nearTerminals', 'origins']);
  const searchPassengers = search.get('passengers').toJS();

  if (features.PROVIDERS_SELECTION_ENABLED) {
    tripList = trips.getIn([searchId, 'buses', 'trips']);
    filteredTrips = filterTrips(
      tripList,
      providerId,
      sortBy,
      filters,
      nearTerminals,
      searchPassengers,
    );
    const filteredTripsId = filteredTrips.toJS().map((trip) => trip.id);
    tripsWithoutFilters = tripList?.filter(
      (trip) => !filteredTripsId.includes(trip.get('id')) && trip.get('lineId') === providerId,
    );
  } else {
    if (trips.getIn([searchId, 'buses', 'trips'])) {
      tripList = trips.getIn([searchId, 'buses', 'trips']).map((item) => item.set('type', 'bus'));
    }
    filteredTrips = filterTrips(tripList, null, sortBy, filters, nearTerminals, searchPassengers);
    const filteredTripsId = filteredTrips.toJS().map((trip) => trip.id);
    tripsWithoutFilters = tripList?.filter((trip) => !filteredTripsId.includes(trip.get('id')));
    provider = Map({ name: null, id: '' });
  }

  tripsWithoutFilters = filterTrips(tripsWithoutFilters, null, sortBy, null, nearTerminals).toJS();
  let filteredTripsJS = filteredTrips.toJS();
  const openTrip = retrieveOpenTrip(filteredTrips, providerId);
  const noTrips = !filteredTrips.size || (filteredTrips.size === 1 && Boolean(openTrip));

  const busTrips = trips.getIn([searchId, 'buses', 'trips']);
  const areOpenTicketTrips = busTrips?.filter((t) => t.get('openTicket')).toJS();

  const roundTrip = !!returnDate;

  const tripsForLineInfo = tripList?.size && tripList.toJS()[0];
  const discountPercent = getBaseTripDiscountPercent({
    trip: tripsForLineInfo,
    isRoundTrip: roundTrip,
  });

  const {
    ticket: { nit },
    operationNumbers,
  } = exchange;

  // Passengers searched
  /**
   * If some passenger searched is not found in the trips, this passengers is saved to notify
   * that the passengers was not found in this route
   */
  const isOldPassengersFormat = isOldSearchPassengersFormat(passengers);
  const passengersNotFound =
    (!isOldPassengersFormat &&
      features.VALID_CATEGORIES?.length > 1 &&
      passengersNotAvailable({
        neededPassengers: searchPassengers,
        trips: filteredTripsJS,
      })) ||
    [];

  const { recommendedTrips } = userPreferences.toJS();
  const recommendedTripsForSearch = getRecommendedTripsListBySearch({
    searchId,
    recommendedTrips,
    providerId,
  });

  if (Object.keys(recommendedTripsForSearch).length) {
    filteredTripsJS = getRecommendedTripsExcludeList({
      recommendedTripsList: recommendedTripsForSearch,
      tripList: filteredTripsJS,
    });
  }

  const originId = search.get('originId');
  const destinationId = search.get('destinationId');
  const originTerminal = terminals[originId];
  const destinationTerminal = terminals[destinationId];

  // Get terminal info from the origin and destination terminals
  const tripTerminalInfo = getTripTerminalInfo({ originTerminal, destinationTerminal });
  return {
    activeDepartureFilter,
    activeLocationFilter,
    activeStopsFilter,
    departureDate,
    noTrips,
    passengers,
    providerName,
    returnDate,
    searchIsPolling,
    sortBy,
    transportType,
    hasDynamicPromotion,
    openTrip,
    stopsFilter,
    categoriesFilter,
    tripOptionsFilter,
    arrivalLocationFilter: aLocationFilter,
    currentPath: location.pathname,
    departureFilter: departureTimeFilter,
    departureLocationFilter: dLocationFilter,
    destination: places[search.getIn(['departure', 'destinationId'])],
    paymentPlans: paymentPlansList || [],
    installmentsMinAmount: config.installmentsMinAmount,
    locations: tripsLocationByProvider(tripList, providerId),
    origin: places[search.getIn(['departure', 'originId'])],
    provider: provider && provider.toJS(),
    providers: providerList ? providerList.toJS() : [],
    roundTrip,
    sortProvidersBy: providers.get('sortBy'),
    tooManyFilters: !searchIsPolling && noTrips && activeFilters,
    trips: filteredTripsJS,
    tripSortVisible: overlayView.get('tripSortVisible'),
    tripDetails: visibleTrip ? parseTripDetails(visibleTrip.toJS()) : null,
    isStopsFilterAvailable: isStopsFilterAvailable(tripList),
    couponCode,
    discountedTripsByCoupon,
    isOpenTicketList: Boolean(isOpen),
    isLogged: Boolean(siemprePlus.get('user')),
    providerId,
    areOpenTicketTrips,
    filters: filters.toJS(),
    tripsWithoutFilters,
    nearTerminals,
    discountPercent,
    operationNumbers,
    nit,
    resultPriceToShow: search.get('resultPriceToShow'),
    firstTripLineId: tripsForLineInfo?.lineId,
    firstTripLineLogo: tripsForLineInfo?.line?.logoUrl,
    brand: env.brand,
    passengersNotFound,
    tripTerminalInfo,
    passengersQueryParam: passengers,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showModal: (component, extraProps) => dispatch(toggleModal('visible', component, extraProps)),
  setSortOrder: (sortBy, list) =>
    dispatch(list === 'providers' ? setProviderSortOrder(sortBy) : setTripSortOrder(sortBy)),
  setTripFilter: (filterBy, active) => dispatch(setTripFilter(filterBy, active)),
  setTripFilters: (filter) => dispatch(setTripFilters(filter)),
  resetTripFilters: () => dispatch(resetTripFilters()),
  transitionTo: (route, searchQuery) => {
    const queryString = stringifyQueryString(searchQuery, ownProps.location.search);
    ownProps.history.push(`${route}${queryString}`);
  },
  replaceUrl: (route) => ownProps.history.replace(route),
  closeTripDetails: () => dispatch(showTripDetails(null)),
  setRedirectParams: (way, params, brand, redirect, roundTrip, waitForPurchase) =>
    dispatch(setRedirectParams(way, params, brand, redirect, roundTrip, waitForPurchase)),
  goBack: () => {
    ownProps.history.go(-1);
  },
  fetchTripsDetails: (trips) => dispatch(fetchTripsDetails(trips)),
  resetSeatBus: () => dispatch(resetSeatBus()),
  newPurchase: (originSlug, seenPrice) =>
    dispatch(newPurchase(originSlug, undefined, 'A1', seenPrice, null, false, {}, true, null, {})),
});

export default compose(
  withTranslation('trips'),
  connect(mapStateToProps, mapDispatchToProps),
)(ProviderDepartures);
