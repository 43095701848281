import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import Amenity from '../atoms/Amenity';

const AmenitiesModal = ({ amenities, children }) => {
  const {
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('search');
  const isGfa = provider.assets === 'gfa';

  return (
    <Modal
      responsiveSize="M"
      portalSelector=".portalModal"
      title={t('label.amenities_and_services')}
      modalTrigger={children}
      zIndex={2999999999}
    >
      <Spacing isResponsive flexGrow>
        {amenities.map((amenity) => (
          <Amenity key={amenity} type={amenity} isVertical={isGfa} />
        ))}
      </Spacing>
    </Modal>
  );
};

AmenitiesModal.propTypes = {
  children: PropTypes.node.isRequired,
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AmenitiesModal;
