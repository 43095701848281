import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createValidator, required } from '../../../utils/formValidations';
import TransferForm from './TransferForm';

const validate = createValidator({
  bank: required,
  personType: required,
});

const mapStateToProps = (state) => {
  const { purchase } = state;
  const purchaseJs = purchase.toJS();
  const { documentType } = purchaseJs;
  // Set the personType field for legal entities
  // if the documentType is NIT to avoid submit error
  return {
    initialValues: {
      personType: documentType === 'NIT' ? '1' : '0',
    },
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'transfer',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate,
  }),
)(TransferForm);
