/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { withTranslation } from 'react-i18next';
import withWhitelabelTheme from '../hocs/whitelabel/withWhitelabelTheme';
import 'styles/components/OverlayView';

const propTypes = {
  visible: PropTypes.bool,
  visibleHeader: PropTypes.bool,
  headerButton: PropTypes.bool,
  closeOverlayLabel: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  hideOverlay: PropTypes.func,
  t: PropTypes.func.isRequired,
  whitelabelTheme: PropTypes.object.isRequired,
};

const defaultProps = {
  visible: false,
  visibleHeader: true,
  headerButton: true,
};

class OverlayView extends Component {
  componentWillReceiveProps(nextProps) {
    const { visible } = this.props;
    const $overlay = $(this.overlayEl);
    const $overlayContainer = $(this.overlayContainerEl);

    if (!visible && nextProps.visible) {
      $overlay.toggleClass('hide show');
      $overlayContainer.scrollTop(0);
    } else if (visible && !nextProps.visible) {
      $overlay.toggleClass('hide show');
    }
  }

  render() {
    const {
      title,
      visibleHeader,
      headerButton,
      hideOverlay,
      children,
      closeOverlayLabel,
      t,
      whitelabelTheme: { colors },
    } = this.props;
    const overlayContainerClass = `overlay-container ${visibleHeader ? '' : 'hidden-header'}`;
    const closeText = closeOverlayLabel || t('button.done');

    return (
      <div className="overlay-view hide" ref={(el) => (this.overlayEl = el)}>
        <div ref={(el) => (this.overlayContainerEl = el)} className={overlayContainerClass}>
          {visibleHeader ? (
            <div>
              <div className="header-block" />
              <div className="header header-fixed">
                <header className="topbar">
                  <div className="topbar-mobile">
                    <Text color={colors.headerText || 'white'} size="S">
                      {t(`${title}`)}
                    </Text>
                    {headerButton ? (
                      <button className="button-primary small-button" onClick={hideOverlay}>
                        {closeText}
                      </button>
                    ) : null}
                  </div>
                </header>
              </div>
            </div>
          ) : null}
          {children}
        </div>
      </div>
    );
  }
}

OverlayView.propTypes = propTypes;
OverlayView.defaultProps = defaultProps;

export default withTranslation('general')(withWhitelabelTheme(OverlayView));
