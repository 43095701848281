import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, FlatButton } from '@reservamos/elements';
import moment from 'moment';
import { formatCurrency } from 'utils/Helpers';
import 'styles/components/purchase/TicketsPanel';
import 'styles/components/purchase/SendEmail';
import { useSelector } from 'react-redux';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const pixInstructionsKeys = ['second', 'third'];

const EfectyPaymentInfo = () => {
  const payment = useSelector((state) => state.payment.toJS());
  const purchase = useSelector((state) => state.purchase.toJS());
  const { expirationDate, referenceId } = payment;
  const { total } = purchase;
  const { t } = useTranslation('payment');
  const [hasCopiedCode, setHasCopiedCode] = useState(false);
  const paydayLimitDisplay = moment(expirationDate).format('LLLL');
  const amountDisplay = formatCurrency(total, 2);

  const onCopyCode = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(referenceId);
        setHasCopiedCode(true);
      } catch (error) {
        setHasCopiedCode(false);
      }
    }
  };

  useEffect(() => {
    if (hasCopiedCode) {
      setTimeout(() => {
        setHasCopiedCode(false);
      }, 5000);
    }
  }, [hasCopiedCode]);

  return (
    <div className="tickets-panel">
      <div className="tickets-panel-content">
        <Spacing vertical size="S" alignItems="flex-start">
          <PaymentImage type="efecty" />
          <Text size="M" weight="bold">
            {t('efecty_instructions.first')}
          </Text>
          <ol>
            {pixInstructionsKeys.map((instructionKey, index) => (
              <li key={instructionKey}>
                <Text size="S">
                  {index + 1}. {t(`efecty_instructions.${instructionKey}`)}
                </Text>
              </li>
            ))}
          </ol>
        </Spacing>
      </div>
      <div className="tickets-panel-content right-content">
        <div className="right-content-info">
          <Spacing vertical size="L">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('purchase:label.payment_reference')}:
              </Text>
              <Text size="XL" weight="bold" textAlign="center">
                {referenceId}
              </Text>
              <FlatButton onClick={onCopyCode}>{t('common:buttons.copy_code')}</FlatButton>
              <div className="email-content">
                <div className="feedback">
                  {hasCopiedCode && (
                    <p className="feedback-success">{t('common:buttons.code_copied')}</p>
                  )}
                </div>
              </div>
            </Spacing>
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.payday_limit')}:
              </Text>
              <Text size="XL" weight="bold" textAlign="center">
                {paydayLimitDisplay}
              </Text>
            </Spacing>
          </Spacing>
        </div>
        <div className="right-content-price">
          <Spacing vertical size="S">
            <Text size="S" textAlign="center" color="grayMedium">
              {t('label.waiting_for_your_payment')}:
            </Text>
            <div className="right-content-price-amount">
              <Text size="XL" weight="bold" textAlign="center">
                {amountDisplay}
              </Text>
            </div>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

export default EfectyPaymentInfo;
