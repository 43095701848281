import React from 'react';
import { useTranslation } from 'react-i18next';
import './BadgeWoman.scss';
import Icon from '../atoms/Icon';
import '../atoms/LoadingDots.scss';

const BadgeWoman = () => {
  const { t } = useTranslation();

  return (
    <div className="rvui-icon-badge">
      <div className="rvui-icon-badge-wrapper">
        <Icon size="small" image="badge-woman" />
        <p className="rvui-icon-badge-text">{t('search:available_woman_seats')}</p>
      </div>
    </div>
  );
};

export default BadgeWoman;
