// @ts-check

/**
 * @typedef {import('./StoreStrategy').StoreStrategy} StoreStrategy
 */

/**
 * @function ConektaStoreStrategy
 * @returns {StoreStrategy}
 */
function ConektaStoreStrategy() {
  async function getPayload() {
    return {};
  }

  return {
    getPayload,
  };
}

const conektaStoreStrategy = ConektaStoreStrategy();

export default conektaStoreStrategy;
