import React from 'react';
import { useTranslation } from 'react-i18next';

const Recomendations = () => {
  const { t } = useTranslation('purchase');
  return (
    <>
      <h5 className="tickets-message-title">{t('trip_recomendations')}</h5>
      <div className="tickets-message-text">
        {t('recomendation_list').map((recomendation) => (
          <div>- {recomendation}</div>
        ))}
      </div>
    </>
  );
};

export default Recomendations;
