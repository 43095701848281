/**
 * Indicates if a way is open ticket (true/false), if way is not valid it will return null
 * @param {*} way
 * @returns {boolean|null}
 */
export default function wayIsOpenTicket(way) {
  if (!way || !way.fragments) return null;
  const [fragment] = way.fragments;
  return fragment.trip.openTicket;
}

/**
 * Checks if a trip is hybrid, A hybrid trip is when one of the trips is open ticket and the other is normal
 * @param {Object} departs - departure object from the purchase state
 * @param {*} returns - return object from the purchase state
 */
export function isHybridTrip(departs, returns) {
  const isDepartureOpen = wayIsOpenTicket(departs);
  const isReturnOpen = wayIsOpenTicket(returns);
  return (
    (isDepartureOpen && !isReturnOpen && returns.fragments) ||
    (!isDepartureOpen && isReturnOpen && returns.fragments)
  );
}
