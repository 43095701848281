import * as Sentry from '@sentry/react';

/**
 * Sentry Factory
 * @type {() => Readonly<import('./ErrorMonitoring').MonitorImplementation>}
 */
const SentryFactory = () => {
  const client = Sentry;

  /**
   * Sentry Initializer
   * @param {Object.<string, any>} options - Sentry options
   * @returns {void}
   */
  const init = (options) => {
    /** @type {import('@sentry/types/types').ClientOptions} */
    const clientOptions = {};

    if (!options?.dsn) {
      throw new Error('DSN is required');
    }

    clientOptions.dsn = options.dsn;

    if (options?.options && Object.keys(options.options).length > 0) {
      Object.entries(options.options).forEach(([key, value]) => {
        if (value != null) {
          clientOptions[key] = value;
        }
      });
    }
    client.init(clientOptions);
  };

  /** Send custom exception
   *
   * @param {object} params - configurable parameters
   * @param {Error} params.error - error object
   * @param {Object.<string, any>} params.info - additional information to the error
   */
  const notify = ({ error, info }) => {
    client.withScope((scope) => {
      Object.entries(info).forEach(([key, value]) => {
        // non-strict equality to allow validation for null and undefined
        if (key != null && value != null) {
          scope.setExtra(key, value);
        }
      });
      client.captureException(error);
    });
  };

  /** Get Redux Middleware
   *
   * @returns {import('redux').Middleware} - Sentry Redux Middleware
   *
   */
  const getReduxMiddleware = () => {
    return client.createReduxEnhancer();
  };

  return Object.freeze({
    init,
    notify,
    getReduxMiddleware,
  });
};

const sentry = SentryFactory();

export default sentry;
