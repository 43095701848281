import { connect } from 'react-redux';
import { setError } from '@/actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  dispatchFinishSeatSelection,
  dispatchResetPassengers,
} from '../../../features/purchase/redux/dispatchers';
import PurchaseBreadcrumbs from './PurchaseBreadcrumbs';

const mapStateToProps = (state) => {
  const { purchase } = state;
  const allowsSeatSelection = purchase.get('allowsSeatSelection');
  const roundTrip = purchase.get('roundTrip');
  const token = purchase.get('token');
  const hasSelectedSeats = purchase.get('hasSelectedSeats');
  const hasPassengers =
    Boolean(purchase.get('passengers').size > 0) && Boolean(purchase.get('hasTickets'));
  const funnelStepCount = allowsSeatSelection ? 3 : 2;

  return {
    roundTrip,
    token,
    allowsSeatSelection,
    hasSelectedSeats,
    hasPassengers,
    funnelStepCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (msg) => dispatch(setError(1, msg, 'warning', false)),
    finishSeatSelection: (history) => dispatch(dispatchFinishSeatSelection(history)),
    resetPassengers: () => dispatch(dispatchResetPassengers()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(PurchaseBreadcrumbs);
