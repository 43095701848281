import React from 'react';
import { Spacing, Currency, Icon, Text, Visibility } from '@reservamos/elements';
import dotersDot from 'images/doters/greendoters.svg';
import PropTypes from 'prop-types';
import DotersFlatfareBadge from 'ui/atoms/DotersFlatfareBadge';

const pointsIcon = {
  doters: dotersDot,
};
/**
 * Renders the wallet price component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.walletType - The type of wallet.
 * @param {number} props.walletTotal - The total amount in the wallet.
 * @param {string} props.extraWalletText - The extra text for the wallet.
 * @param {string} props.size - The size of the component.
 * @param {string} props.mobileSize - The mobile size of the component.
 * @param {string} props.iconSize - The size of the icon.
 * @returns {JSX.Element} The rendered component.
 */
const WalletPrice = ({
  walletType,
  walletTotal,
  extraWalletText,
  size,
  mobileSize,
  iconSize,
  showFlatFare,
}) => {
  return (
    <Spacing size="S" vertical>
      <div className="fade-in animated">
        <Spacing alignItems="center" size="XS">
          {showFlatFare ? (
            <DotersFlatfareBadge />
          ) : (
            <Icon type={pointsIcon[walletType]} size={iconSize || 'S'} />
          )}
          <Currency
            decimals={0}
            mobileSize={mobileSize || 'L'}
            price={walletTotal}
            sign=""
            size={size || 'XL'}
            weight="bold"
          />
        </Spacing>
      </div>
      {extraWalletText ? (
        <Visibility type="hideForMedium">
          <Spacing size="S">
            <Text size="XXS">{extraWalletText}</Text>
          </Spacing>
        </Visibility>
      ) : null}
    </Spacing>
  );
};

WalletPrice.propTypes = {
  walletType: PropTypes.string,
  walletTotal: PropTypes.number,
  extraWalletText: PropTypes.string,
  size: PropTypes.string,
  mobileSize: PropTypes.string,
  iconSize: PropTypes.string,
  showFlatFare: PropTypes.bool,
};

export default WalletPrice;
