import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const FiltersContext = createContext();

/**
 * Provider of the filters context. It provides the filters and sort option applied to the trip list.
 * @param {Object} props - Props of the component.
 * @returns {React.Component} - The component with the context.
 */
export const FiltersProvider = ({ children }) => {
  const tripFilters = useSelector((state) => state.tripFilters);
  const providers = useSelector((state) => state.providers);

  const { departureTime, stops, categories, tripOptions } = tripFilters.toJS().filters;
  const { filterBy: scheduleFiltersProviders, sortBy: sortByProviders } = providers.toJS();
  const { sortBy } = tripFilters.toJS();
  const scheduleFilters = Array.isArray(departureTime) ? departureTime : [departureTime];
  const stopsFilters = Array.isArray(stops) ? stops : [stops];

  return (
    <FiltersContext.Provider
      value={{
        scheduleFilters,
        stopsFilters,
        categoriesFilters: categories,
        sortBy,
        scheduleFiltersProviders,
        sortByProviders,
        tripOptionsFilters: tripOptions,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
