import React from 'react';
import PropTypes from 'prop-types';
import 'styles/lib/helpers/javascript';
import './ModalContent.scss';
import Icon from '../atoms/Icon';

const ModalContent = ({ children, onCloseModal, title }) => (
  <div className="rvui-modal-content">
    <div
      className="close"
      onClick={onCloseModal}
      onKeyUp={({ keyCode }) => keyCode === 13 && onCloseModal()}
      role="button"
      tabIndex={0}
    >
      <Icon size="small" image="close" />
    </div>
    <div className="title">
      {React.isValidElement(title) ? (
        title
      ) : (
        <p>
          <strong>{title}</strong>
        </p>
      )}
    </div>
    {children}
  </div>
);

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

ModalContent.defaultProps = {};

export default ModalContent;
