import React from 'react';
import PropTypes from 'prop-types';
import { SeatNumber } from '@reservamos/elements';
import adjacentBadge from 'images/badges/adjacent-seat.svg';

const Seat = ({ seat, type, icon, isSmall }) => {
  const icons = {
    adjacent: adjacentBadge,
  };

  return (
    <SeatNumber
      number={seat}
      isSmall={isSmall}
      type={type}
      iconSrc={icon && icons[icon]}
      isIconRight
    />
  );
};

Seat.propTypes = {
  seat: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['available', 'occupied', 'selected', 'disabled', 'adjacent']),
  icon: PropTypes.oneOf(['', 'adjacent']),
  isSmall: PropTypes.bool,
};

Seat.defaultProps = {
  type: 'selected',
  icon: '',
  isSmall: false,
};

export default Seat;
