import { BadgeRounded, Currency, Gap, Text, Tooltip, Icon } from '@reservamos/elements';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import discountGFA from '../../images/brands-unique/gfa/early-discount.png';

const MemoizedTooltip = memo(Tooltip);

/**
 * Render price section on the result box
 * Evaluates if it is exchange then hide prices as ENT-308
 * @returns JSX Component
 */
const PriceComponent = ({
  hide,
  walletPoints,
  position,
  total,
  colorPrice,
  providerDiscount,
  discountPercent,
  earlyDiscount,
  isDiscountLimited,
  showOfficePrice,
  windowTicketPrice,
  discountSeat,
}) => {
  const { t } = useTranslation();
  const { brand } = useWhitelabelEnvs();
  const dotersUserData = useSelector((state) => state.siemprePlus.toJS());
  const isDotersUserLoading = !dotersUserData.requested;
  const isDotersUser = !!dotersUserData.user;
  const tooltipClosed = !!localStorage.getItem('tooltipClosed');
  const DOTERS_CURRENCY = t('search:doters_currency');
  const ticketOfficeLabel = `${t('search:ticket_office_price')}:`;
  const hasEarlyDiscount = earlyDiscount || isDiscountLimited;
  const earlyDiscountIcon = {
    gfa: discountGFA,
    default: discountGFA,
  };

  const handleTooltipClose = () => {
    localStorage.setItem('tooltipClosed', 'true');
  };

  const discountOfficeComponent = showOfficePrice && (
    <Text size="XS" color="grayMedium">
      {ticketOfficeLabel} <Currency price={windowTicketPrice} size="XS" color="grayLight" />
    </Text>
  );

  // Only show tooltip if is not logged and is the second position
  // (because the first position is the open ticket trip)
  // and tooltip has not closed previously by the user
  const showTooltip = position === 2 && !tooltipClosed && !isDotersUserLoading && !isDotersUser;

  if (hide) return null;

  let dotersPrice = null;

  if (walletPoints > 0) {
    const dotersCurrency = (
      <Currency
        price={walletPoints}
        color="loyaltyLight"
        currency={DOTERS_CURRENCY}
        sign=""
        size="M"
        weight="semibold"
        currencyLowercase
        decimals={0}
      />
    );

    if (showTooltip) {
      dotersPrice = (
        <div className="matrix-mobile-click">
          <MemoizedTooltip
            text={t('loyalty:doters_tooltip')}
            defaultOpened
            closeIconColor="white"
            horizontal="right"
            type="left"
            widthBox="220"
            onClose={handleTooltipClose}
          >
            {dotersCurrency}
          </MemoizedTooltip>
        </div>
      );
    } else {
      dotersPrice = dotersCurrency;
    }
  }

  return (
    <>
      <Gap size="XS" alignItems="center">
        <Currency price={total} size="L" color={colorPrice} weight="bold" currency="MXN" />
        {hasEarlyDiscount ? (
          <Icon type={earlyDiscountIcon[brand] || earlyDiscountIcon.default} />
        ) : (
          <>
            {providerDiscount && (
              <BadgeRounded
                text={`-${discountPercent}`}
                firstColor="discountLight"
                secondColor="discountStrong"
                equalRadius
              />
            )}
          </>
        )}
      </Gap>
      {hasEarlyDiscount ? (
        <Text color="primary" size="S" italic weight="semibold" textAlign="right">
          {t('search:available_early_discount_seats', { count: discountSeat })}
        </Text>
      ) : (
        <>
          {dotersPrice}
          {discountOfficeComponent}
        </>
      )}
    </>
  );
};

PriceComponent.propTypes = {
  hide: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  colorPrice: PropTypes.string.isRequired,
  windowTicketPrice: PropTypes.number,
  walletPoints: PropTypes.number,
  discountPercent: PropTypes.number,
  providerDiscount: PropTypes.bool,
  earlyDiscount: PropTypes.bool,
  isDiscountLimited: PropTypes.bool,
  showOfficePrice: PropTypes.bool,
  discountSeat: PropTypes.number,
};

PriceComponent.defaultProps = {
  providerDiscount: null,
  earlyDiscount: false,
  showOfficePrice: false,
};

export default PriceComponent;
