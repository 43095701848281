import React from 'react';
import { Gap, Icon, Text, Box } from '@reservamos/elements';
import PropTypes from 'prop-types';

/**
 * Component that shows the details of the trip
 * @param {Object} props - Component props
 * @param {String} props.title - Title to show in the ticket info
 * @param {String} props.text - Text to show in the ticket info
 * @param {String} props.passengers - Number of passengers in the purchase
 * @param {String} props.exitAnimation - Class name to use for the animation
 * @returns
 */
const TicketInfo = ({ title, text, passengers, exitAnimation }) => {
  return (
    <div className={exitAnimation ? 'fade-up' : 'fade-down'}>
      <Gap alignItems="center" responsiveColumnGap="XS" columnGap="S">
        {passengers ? (
          <Box paddingHorizontal="XS" paddingVertical="XXS" bgColor="grayBorder" borderRadius="S">
            <Gap alignItems="center">
              <Icon size="S" type="UserOutline" color="grayStrong" />
              <Text size="S" weight="bold">
                {passengers}
              </Text>
            </Gap>
          </Box>
        ) : (
          <Icon type="Calendar" size="S" />
        )}
        {title && (
          <Text mobileSize="XXS" size="S" weight="bold">
            {title}
          </Text>
        )}
        <Text mobileSize="S" size="S" color="grayStrong">
          {text}
        </Text>
      </Gap>
    </div>
  );
};

TicketInfo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  passengers: PropTypes.number,
  exitAnimation: PropTypes.bool,
};

TicketInfo.defaultProps = {
  text: '',
  passengers: null,
  exitAnimation: false,
};

export default TicketInfo;
