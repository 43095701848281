import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDuration, formatPercent } from 'utils/Helpers';
import {
  Text,
  Badge,
  BadgeRounded,
  Currency,
  Button,
  Spacing,
  FlatButton,
} from '@reservamos/elements';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import PriceTooltip from './PriceTooltip';
import LowAvailability from './LowAvailability';
import TripFiltersBadges from './TripFiltersBadges';
import 'styles/components/search/ResultBoxHorizontal';

const ResultBoxHorizontal = ({
  arrival,
  availability,
  departure,
  duration,
  id,
  isRoundTrip,
  onSelectClick,
  providerDiscount,
  providerDiscounts,
  availableCategories,
  total,
  totalBeforeDiscount,
  trip,
  way,
  filtersApplied = {},
  showNewFiltersList,
}) => {
  const { t } = useTranslation();

  const { tripFilters } = useSelector((state) => state);

  const filteredCategories = tripFilters.getIn(['filters', 'categories']) || [];

  const { line, passengerTypes, pricing, variableDepartureTime, secondFloor, stops } = trip;
  const { discountType } = pricing;
  const earlyDiscount = discountType === 'early_trip_discount';
  const wayContext = isRoundTrip ? way : 'one way';
  const discountPercent = providerDiscount ? formatPercent(totalBeforeDiscount, total) : null;
  const stopsText = t('search:trip_stopovers', {
    count: stops,
    context: stops === 0 ? 'direct' : null,
  });

  const categoriesByType = availableCategories.reduce((accum, category) => {
    accum[category.type] = category.availability;
    return accum;
  }, {});

  const categoriesSort = (a, b) => {
    const weigth = {
      older: 5,
      teacher: 4,
      student: 3,
      minor: 2,
      wheelchair_handicap: 1,
    };
    const weightA = weigth[a];
    const weightB = weigth[b];
    return weightB - weightA;
  };

  const categoryBadges = filteredCategories.sort(categoriesSort).map((category) => (
    <Badge key={`${category}-badge`} smallPadding type="info" hasFade>
      <Text elementType="span" size="S" color="white" lineHeight="1em">
        {`${t('category', { context: category })} ${categoriesByType[category]}`}
      </Text>
    </Badge>
  ));

  const categoriesBadgesComponent = <Spacing size="XS">{categoryBadges}</Spacing>;

  return (
    <div className="result-box-horizontal">
      {providerDiscount && (
        <div className="result-box-discount-container">
          <BadgeRounded
            text={`-${discountPercent}`}
            firstColor="discountLight"
            secondColor="discountStrong"
          />
        </div>
      )}
      <div className="result-box-horizontal-data">
        <Text weight="bold">{moment(departure).format('LT')}</Text>
        <Text size="XS" color="grayMedium">
          {formatDuration(duration)}
        </Text>
        <Text size="S" weight="bold" color="grayLight">
          {moment(arrival).format('LT')}
        </Text>
        <img className="result-box-horizontal-provider" src={line.logoUrl} />
        <Text size="S" color="grayMedium">
          {variableDepartureTime
            ? t('search:trip_service_on_the_way')
            : t('search:trip_service_local')}
        </Text>
        <ProviderInfo
          amenities={line.services}
          providerDiscounts={providerDiscounts}
          initialTab={0}
          availabilityCategories={passengerTypes}
          tripId={id}
        >
          <FlatButton size="S" weight="bold">
            {stopsText}
          </FlatButton>
        </ProviderInfo>
        <Text size="S" color="grayMedium">
          {secondFloor ? 'Doble Piso' : 'Un Piso'}
        </Text>
      </div>
      <div className="result-box-horizontal-details">
        <ProviderInfo
          amenities={line.services}
          providerDiscounts={providerDiscounts}
          initialTab={0}
          availabilityCategories={passengerTypes}
          tripId={id}
        >
          <FlatButton size="S" weight="bold">
            {t('general:show_details')}
          </FlatButton>
        </ProviderInfo>
        <div className="result-box-horizontal-tags">
          {showNewFiltersList ? (
            <TripFiltersBadges
              filtersApplied={filtersApplied}
              trip={trip}
              alternativeComponent={null}
            />
          ) : null}
          {!showNewFiltersList && categoriesBadgesComponent}
        </div>
      </div>
      <div className="result-box-horizontal-price">
        <PriceTooltip
          amenities={line.services}
          providerDiscounts={providerDiscounts}
          availabilityCategories={passengerTypes}
          earlyDiscount={earlyDiscount}
          total={total}
          color={providerDiscount ? 'discount' : 'primary'}
          tripId={id}
          providerDiscount={providerDiscount}
        />
        {providerDiscount && (
          <>
            <Text color="grayLight" size="XS">
              <em>{t('search:ticket_office')}: </em>
              <Currency
                price={totalBeforeDiscount}
                decimals={0}
                color="grayLight"
                currency={getCurrencySuffix()}
                sign={getCurrencyPrefix()}
                weight="semibold"
                mobileSize="S"
              />
            </Text>
          </>
        )}
        {!providerDiscount && (
          <p className="flat-text-small-italic">
            {t('search:trip_pricing', { context: wayContext })}
          </p>
        )}
        <ProviderInfo
          amenities={line.services}
          providerDiscounts={providerDiscounts}
          initialTab={1}
          availabilityCategories={passengerTypes}
          tripId={id}
        >
          <LowAvailability availability={availability} pricing={pricing} type="desktop" />
        </ProviderInfo>
      </div>
      <div className="result-box-horizontal-action">
        <Button text={t('general:select')} onClick={onSelectClick} variant="accent" isRounded />
      </div>
    </div>
  );
};

const placeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

ResultBoxHorizontal.propTypes = {
  arrival: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  departure: PropTypes.string.isRequired,
  destination: placeType.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  origin: placeType.isRequired,
  providerDiscount: PropTypes.any,
  providerDiscounts: PropTypes.array,
  availableCategories: PropTypes.array,
  total: PropTypes.number.isRequired,
  totalBeforeDiscount: PropTypes.number,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
  }).isRequired,
  way: PropTypes.oneOf(['departure', 'return']).isRequired,
  filtersApplied: PropTypes.object,
  showNewFiltersList: PropTypes.bool,
};

ResultBoxHorizontal.defaultProps = {
  providerDiscount: null,
  providerDiscounts: [],
};

export default ResultBoxHorizontal;
