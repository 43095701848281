import store from '@/store';
import { omit } from 'lodash';

const KEY = 'form.passengers';

/**
 * Get the passengers stored in the local storage
 * @param {Object} featuresFromParams - The features object from the params
 * @returns
 */
export function getStoredPassengers(featuresFromParams) {
  const features = featuresFromParams ?? store.getState().whitelabelConfig.features;
  if (!features.STORE_PASSENGERS_FORM) return [];
  const passengers = JSON.parse(
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem(KEY) || '[]'
      : '[]',
  );
  return passengers.map((passenger) => {
    return {
      ...omit(passenger, ['id', 'wantsIncomingInsurance', 'wantsOutgoingInsurance', 'document']),
      canLoggedRemove: true,
    };
  });
}

/**
 * Set the passengers in the local storage
 * @param {Array} passengers - The passengers
 */
export function setStoredPassenger(passengers) {
  passengers = passengers.map((passenger) => {
    return omit(passenger, ['id', 'wantsIncomingInsurance', 'wantsOutgoingInsurance', 'document']);
  });

  const jsonPassengers = JSON.stringify(passengers);
  if (typeof window !== 'undefined' && window.localStorage)
    window.localStorage.setItem(KEY, jsonPassengers);
}
