import React from 'react';
import moment from 'moment';
import { Spacing, Text } from '@reservamos/elements';
import { useSelector } from 'react-redux';
import { formatCurrency } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import CheckList from '../../../ui/atoms/CheckList';
import PaymentImage from '../../../ui/atoms/PaymentImage';

const NequiPaymentInfo = () => {
  const { t } = useTranslation('payment');
  const { expirationDate } = useSelector((state) => state.payment.toJS());
  const { total } = useSelector((state) => state.purchase.toJS());
  const amountDisplay = formatCurrency(total, 2);
  const paydayLimitDisplay = moment(expirationDate).format('LLLL');
  const { provider } = useWhitelabelCopies();

  const labels = [
    t('nequi_instructions.third'),
    t('nequi_instructions.fourth', { provider: provider.name }),
  ];

  return (
    <div className="tickets-panel">
      <div className="tickets-panel-content">
        <Spacing vertical size="S" alignItems="flex-start">
          <PaymentImage type="nequi-logo" />
          <CheckList title={t('nequi_instructions.first')} labels={labels} />
        </Spacing>
      </div>
      <div className="tickets-panel-content right-content">
        <div className="right-content-price">
          <Spacing vertical size="L">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.waiting_for_your_payment')}:
              </Text>
              <div className="right-content-price-amount">
                <Text size="XL" weight="bold" textAlign="center">
                  {amountDisplay}
                </Text>
              </div>
            </Spacing>
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('label.payday_limit')}:
              </Text>
              <Text size="XL" weight="bold" textAlign="center">
                {paydayLimitDisplay}
              </Text>
            </Spacing>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

export default NequiPaymentInfo;
