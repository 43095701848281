/**
 * NequiStrategy for the payments module
 * @function NequiStrategy
 * @returns {NequiStrategy}
 */
const NequiStrategy = () => {
  const needsRedirect = false;

  /**
   * Crea un token para la estrategia de Nequi.
   * @async
   * @function createToken
   * @returns {Promise<string>} Una promesa que resuelve con un token vacío.
   */
  const createToken = async () => {
    return '';
  };

  return {
    needsRedirect,
    createToken,
  };
};

const nequiStrategy = NequiStrategy();

export default nequiStrategy;
