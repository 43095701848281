const Evertec = () => {
  let evertecInstance;

  const createSession = async ({
    purchaseUrl,
    brand,
    purchaseId,
    description,
    currency,
    amount,
    returnUrl,
    cancelUrl,
    paymentType,
    purchaser,
  }) => {
    try {
      const purchaserPhone = purchaser.phoneCode
        ? `+${purchaser.phoneCode}${purchaser.phone}`
        : purchaser.phone;
      const resp = await fetch(`${purchaseUrl}/v2/payment_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_engine: 'evertec',
          tenant: brand,
          purchase_id: purchaseId,
          description,
          currency,
          amount,
          return_url: returnUrl,
          cancel_url: cancelUrl,
          payment_type: paymentType,
          skip_result: true,
          document: purchaser.documentId,
          document_type: purchaser.documentType,
          name: purchaser.purchaserFirstName,
          surname: purchaser.purchaserLastName,
          email: purchaser.email,
          mobile: purchaserPhone,
        }),
      });
      const data = await resp.json();
      return data;
    } catch (error) {
      console.error('Error getting payment URL:', error);
      return false;
    }
  };

  const initialize = async (processUrl) => {
    try {
      if (processUrl) {
        const script = document.createElement('script');
        script.src = 'https://checkout.placetopay.com/lightbox.min.js';
        script.id = 'evertec-sdk';
        document.head.appendChild(script);

        return new Promise((resolve) => {
          script.onload = () => {
            evertecInstance = window.P;
            evertecInstance.init(processUrl);
            resolve();
          };
        });
      }
    } catch (error) {
      console.error('Error initializing Evertec:', error);
    }
  };

  const getInstance = () => {
    return evertecInstance;
  };

  return {
    initialize,
    getInstance,
    createSession,
  };
};

const evertec = Evertec();

export default evertec;
