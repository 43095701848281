import { useEffect } from 'react';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { REDIRECT_LOGOUT_KEY } from 'constants/sso';

/**
 * Redirects the user to the previous url after logout with SSO
 * */
const LogoutPage = () => {
  const environment = useWhitelabelEnvs();

  useEffect(() => {
    const redirectPath = localStorage.getItem(REDIRECT_LOGOUT_KEY);
    localStorage.removeItem(REDIRECT_LOGOUT_KEY);
    if (!redirectPath) {
      window.location.href = environment.homeUrl;
      return null;
    }

    window.location.href = redirectPath;
  });

  return null;
};

export default LogoutPage;
