import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '@reservamos/elements';

const PassengerTypeRadio = ({ children, input, isDisabled }) => {
  const handleChange = (value) => {
    input.onChange(value);
  };

  return (
    <RadioButton
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...input}
      isActive={input.checked}
      isDisabled={isDisabled}
      onChange={() => handleChange(input.value)}
    >
      {children}
    </RadioButton>
  );
};

PassengerTypeRadio.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  forceCheck: PropTypes.bool,
};

PassengerTypeRadio.defaultProps = {
  isDisabled: false,
};

export default PassengerTypeRadio;
