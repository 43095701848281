import React from 'react';
import PropTypes from 'prop-types';
import { Currency, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';

/**
 * Renders the previous price component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.price - The previous price.
 * @returns {JSX.Element} The rendered component.
 */
const PreviousPrice = ({ price }) => {
  const { t } = useTranslation('search');

  const currencySymbol = getCurrencySuffix();
  const sign = getCurrencyPrefix();
  return (
    <Text color="grayLight" size="S" italic>
      {`${t('label.previous')}: `}
      <Currency
        color="grayLight"
        sign={sign}
        decimals={0}
        currency={currencySymbol}
        price={price}
        size="S"
      />
    </Text>
  );
};

PreviousPrice.propTypes = {
  /** PreviousPrice type */
  price: PropTypes.string.isRequired,
};

export default PreviousPrice;
