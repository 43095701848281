let taplytics = null;

export function initTaplytics(taplyticsConfig) {
  if (!taplyticsConfig) return null;
  if (!taplyticsConfig.enabled) return null;
  if (!window.Taplytics) return null;

  taplytics = window.Taplytics;
  taplytics.init(taplyticsConfig.token);
}

export function getABTest(testName, defaultValue) {
  if (!taplytics) return defaultValue;

  const ABVariable = taplytics.variable(testName, defaultValue);
  const { init } = ABVariable;

  if (init) {
    return ABVariable.value;
  }

  return defaultValue;
}

export function getInstance() {
  return taplytics;
}
