import React from 'react';
import PropTypes from 'prop-types';
import { Box, Spacing } from '@reservamos/elements';
import BreakdownPricing from 'ui/atoms/BreakdownPricing';
import { useTranslation } from 'react-i18next';
import adultIcon from '../../images/brands/gfa/avatars/adult.png';
import minorIcon from '../../images/brands/gfa/avatars/minor.png';
import studentIcon from '../../images/brands/gfa/avatars/student.png';
import teacherIcon from '../../images/brands/gfa/avatars/teacher.png';
import insenIcon from '../../images/brands/gfa/avatars/old-adult.png';
import adultIconVariant from '../../images/brands/etn/avatars/adult.png';
import minorIconVariant from '../../images/brands/etn/avatars/minor.png';
import studentIconVariant from '../../images/brands/etn/avatars/student.png';
import teacherIconVariant from '../../images/brands/etn/avatars/teacher.png';
import insenIconVariant from '../../images/brands/etn/avatars/old-adult.png';

const types = {
  minor: {
    icon: minorIcon,
    iconVariant: minorIconVariant,
    label: 'available_minor_seats',
  },
  older: {
    icon: insenIcon,
    iconVariant: insenIconVariant,
    label: 'available_older_seats',
  },
  general: {
    icon: adultIcon,
    iconVariant: adultIconVariant,
    label: 'available_adult_seats',
  },
  student: {
    icon: studentIcon,
    iconVariant: studentIconVariant,
    label: 'available_student_seats',
  },
  teacher: {
    icon: teacherIcon,
    iconVariant: teacherIconVariant,
    label: 'available_teacher_seats',
  },
  wheelchair_handicap: {
    icon: null,
    iconVariant: null,
    label: 'available_wheelchair_handicap_seats',
  },
  special: {
    icon: null,
    iconVariant: null,
    label: 'available_special_seats',
  },
  pet_friendly: {
    icon: null,
    iconVariant: null,
    label: 'available_pet_friendly_seats',
  },
};

/**
 * Renders the price preview component.
 *
 * @param {string} type - The type of passenger.
 * @param {boolean} hasDiscountAnticipated - Whether there is an anticipated discount.
 * @param {Array} subDiscounts - Array of sub-discount details.
 * @param {number} discount - The discount percentage.
 * @param {boolean} isFlat - Whether to use variant icons.
 * @returns {JSX.Element} The rendered component.
 */
const CategoriesPricing = ({
  type,
  subDiscounts,
  discount,
  hasDiscountAnticipated,
  isFlat,
  total,
  countWithSpecialDiscount,
  totalSpecialDiscount,
  countWithoutSpecialDiscount,
  normalDiscountPercent,
  normalTotal,
}) => {
  const { icon, iconVariant, label } = types[type];
  const { t } = useTranslation('search');
  return (
    <Box
      paddingHorizontal="XS"
      paddingVertical="XS"
      borderRadius="M"
      alphaBg="XS"
      bgColor="primary"
    >
      <Spacing size="XXS" vertical>
        <BreakdownPricing
          label={t(label, { count: subDiscounts })}
          price={total}
          discountIcon={hasDiscountAnticipated}
          typeIcon={isFlat ? iconVariant : icon}
          discount={countWithSpecialDiscount && countWithoutSpecialDiscount ? '' : discount}
        />
        {!!normalTotal && !!countWithSpecialDiscount && (
          <Spacing size="XS" vertical>
            <BreakdownPricing
              label={t(label, { count: countWithSpecialDiscount })}
              price={totalSpecialDiscount}
              subDiscount={discount}
              discountIcon={hasDiscountAnticipated}
            />
          </Spacing>
        )}
        {!!normalTotal && !!countWithSpecialDiscount && (
          <Spacing size="XS" vertical>
            <BreakdownPricing
              label={t(label, { count: countWithoutSpecialDiscount })}
              price={normalTotal}
              subDiscount={normalDiscountPercent}
            />
          </Spacing>
        )}
      </Spacing>
    </Box>
  );
};

CategoriesPricing.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)).isRequired,
  discount: PropTypes.number,
  subDiscounts: PropTypes.string,
  hasDiscountAnticipated: PropTypes.bool,
  isFlat: PropTypes.bool,
  total: PropTypes.number,
  countWithSpecialDiscount: PropTypes.number,
  totalSpecialDiscount: PropTypes.number,
  countWithoutSpecialDiscount: PropTypes.number,
  normalDiscountPercent: PropTypes.string,
  normalTotal: PropTypes.number,
};

CategoriesPricing.defaultProps = {
  discount: 0,
  isFlat: false,
};

export default CategoriesPricing;
