// @ts-check

/**
 * @typedef {Object} MercadoPagoBankItem
 * @property {string} description - The bank name.
 * @property {string} id - The bank's id.
 */

const MercadoPago = () => {
  let mpPublicKey = '';
  let mercadoPagoInstance;

  const initialize = (publicKey = '') => {
    // @ts-ignore - MercadoPago is a global variable loaded by the script
    mercadoPagoInstance = new window.MercadoPago(publicKey);
    mpPublicKey = publicKey;
  };

  /**
   * Fetches the list of banks available for PSE payments
   * @async
   * @function fetchPseBanksList
   * @returns {Promise<MercadoPagoBankItem[]>}
   * @throws {Error} if the request fails
   * @example
   * ```js
   * const banks = await mercadoPago.fetchBankList();
   * ```
   */
  const fetchPseBanksList = async () => {
    const endpoint = `https://api.mercadopago.com/v1/payment_methods?public_key=${mpPublicKey}`;
    const response = await fetch(endpoint);
    const allPaymentMethods = await response.json();
    const psePaymentMethod = allPaymentMethods.find((paymentMethod) => paymentMethod.id === 'pse');
    if (!psePaymentMethod) throw new Error('PSE payment method not available');
    const pseBanks = psePaymentMethod.financial_institutions;
    return pseBanks;
  };

  const getCardPaymentMethod = async ({ bin }) => {
    if (!mercadoPagoInstance) {
      throw new Error('La instancia de Mercado Pago no ha sido creada');
    }
    const { results } = await mercadoPagoInstance.getPaymentMethods({
      bin,
    });
    return results[0];
  };

  const getInstance = () => {
    if (!mercadoPagoInstance) {
      throw new Error('La instancia de Mercado Pago no ha sido creada');
    }
    return mercadoPagoInstance;
  };

  return {
    initialize,
    fetchPseBanksList,
    getInstance,
    getCardPaymentMethod,
  };
};

const mercadoPago = MercadoPago();

export default mercadoPago;
