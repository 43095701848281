import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import MercadoPagoTDCContext from './MercadoPagoTDCContext';
import mercadoPago from '../../../../payments/core/engines/mercadoPago';

const propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialFormMetadata: PropTypes.object.isRequired,
};

const MercadoPagoTDCProvider = ({ children, onSubmit, initialFormMetadata }) => {
  const [tdcFormInstance, setTdcFormInstance] = useState(undefined);
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [cardBin, setCardBin] = useState('');
  const [formMetadata, setFormMetadata] = useState(initialFormMetadata);
  const formMetadataRef = useRef();
  const holderEmailInputRef = useRef();
  const holderNameInputRef = useRef();
  const identificationNumberInputRef = useRef();
  const identificationTypeInputRef = useRef();
  const tdcFormInstanceRef = useRef();
  const cardBinRef = useRef();
  formMetadataRef.current = formMetadata;
  tdcFormInstanceRef.current = tdcFormInstance;
  cardBinRef.current = cardBin;

  const updateFields = (fields) => {
    const { holderName, holderEmail, identificationNumber } = fields;
    if (holderEmailInputRef.current && holderEmail) {
      const currentHolderEmailValue = holderEmailInputRef.current.value;
      if (!currentHolderEmailValue) {
        holderEmailInputRef.current.value = holderEmail;
      }
    }
    if (holderNameInputRef.current && holderName) {
      const currentHolderNameValue = holderNameInputRef.current.value;
      if (!currentHolderNameValue) {
        holderNameInputRef.current.value = holderName;
      }
    }
    if (identificationNumberInputRef.current && identificationNumber) {
      const currentIdentificationNumberValue = identificationNumberInputRef.current.value;
      if (!currentIdentificationNumberValue) {
        identificationNumberInputRef.current.value = identificationNumber;
      }
    }
  };

  const updateFormMetadata = (error, fieldName, ignoreIdentificationNumber = true) => {
    if (fieldName === 'identificationNumber' && ignoreIdentificationNumber) return;
    const formMetadata = formMetadataRef.current;
    setFormMetadata({
      ...formMetadata,
      fields: {
        ...formMetadata.fields,
        [fieldName]: {
          ...formMetadata.fields[fieldName],
          hasError: Boolean(error),
          isValid: Boolean(!error),
        },
      },
    });
  };

  const setFieldsAsInvalid = (invalidFieldsNames) => {
    const formMetadata = formMetadataRef.current;
    const updatedFields = invalidFieldsNames.reduce((updatedFields, fieldName) => {
      const newUpatedFields = {
        ...updatedFields,
        [fieldName]: {
          ...updatedFields[fieldName],
          hasError: true,
          isValid: false,
        },
      };
      return newUpatedFields;
    }, formMetadata.fields);
    setFormMetadata({
      ...formMetadata,
      fields: {
        ...formMetadata.fields,
        ...updatedFields,
      },
    });
  };

  const unmountMercadoPagoTDCForm = () => {
    setFormMetadata(initialFormMetadata);
    tdcFormInstanceRef.current.unmount();
  };

  const validateDocumentNumber = () => {
    const { value } = identificationNumberInputRef.current;
    const { value: documentTypeValue } = identificationTypeInputRef.current;
    if (documentTypeValue === 'CPF' || documentTypeValue === 'CNPJ') {
      const valueWithOnlyDigits = value.replace(/\D/g, '');
      identificationNumberInputRef.current.value = valueWithOnlyDigits;
      if (documentTypeValue === 'CPF' && !cpf.isValid(valueWithOnlyDigits)) {
        updateFormMetadata(
          new Error('invalid identification number'),
          'identificationNumber',
          false,
        );
        return false;
      }
      if (documentTypeValue === 'CNPJ' && !cnpj.isValid(valueWithOnlyDigits)) {
        updateFormMetadata(
          new Error('invalid identification number'),
          'identificationNumber',
          false,
        );
        return false;
      }
    }
    updateFormMetadata(undefined, 'identificationNumber', false);
    return true;
  };

  const mountMercadoPagoTDCForm = async (amount) => {
    const mercadoPagoInstance = mercadoPago.getInstance();
    const cardForm = mercadoPagoInstance.cardForm({
      amount,
      iframe: true,
      form: {
        id: formMetadata.id,
        ...formMetadata.fields,
      },
      callbacks: {
        onFormMounted: (error) => {
          if (error) return console.warn('Form Mounted handling error: ', error);
        },
        onSubmit: async (event) => {
          event.preventDefault();
          const formMetadata = formMetadataRef.current;
          const hasInvalidIdentificationNumber = formMetadata.fields.identificationNumber.hasError;
          if (hasInvalidIdentificationNumber) return;
          const identificationNumberIsValid = validateDocumentNumber();
          if (!identificationNumberIsValid) return;
          const mpDeviceSessionId = window.MP_DEVICE_SESSION_ID;
          const paymentMethod = await mercadoPago.getCardPaymentMethod({ bin: cardBinRef.current });
          onSubmit({
            ...cardForm.getCardFormData(),
            mpDeviceSessionId,
            paymentMethodId: paymentMethod?.id || '',
            issuerId: paymentMethod?.issuer?.id || '',
          });
        },
        onIdentificationTypesReceived: (error, data) => {
          setIdentificationTypes(data);
        },
        onValidityChange: updateFormMetadata,
        onError: (errors) => {
          if (!errors.length) return;
          const fieldsNames = Object.keys(initialFormMetadata.fields);
          fieldsNames.push('expirationMonth', 'expirationYear');
          const fieldsWithErrors = errors.reduce((fieldsWithErrors, error) => {
            const errorMessage = error.message;
            const errorMessageArray = errorMessage.split(' ');
            let fieldName = errorMessageArray.find((messageToken) => {
              return fieldsNames.includes(messageToken);
            });
            if (fieldName === 'expirationMonth' || fieldName === 'expirationYear') {
              fieldName = 'expirationDate';
            }
            if (!fieldsWithErrors.includes(fieldName)) {
              fieldsWithErrors.push(fieldName);
            }
            return fieldsWithErrors;
          }, []);
          setFieldsAsInvalid(fieldsWithErrors);
        },
        onBinChange: (bin) => {
          setCardBin(bin);
        },
      },
    });
    setTdcFormInstance(cardForm);
  };

  const submitForm = () => tdcFormInstance.submit();

  const contextValue = {
    submitForm,
    mountMercadoPagoTDCForm,
    identificationTypes,
    formMetadata,
    holderEmailInputRef,
    holderNameInputRef,
    identificationNumberInputRef,
    updateFields,
    unmountMercadoPagoTDCForm,
    updateFormMetadata,
    identificationTypeInputRef,
    validateDocumentNumber,
    cardBin,
  };

  return (
    <MercadoPagoTDCContext.Provider value={contextValue}>{children}</MercadoPagoTDCContext.Provider>
  );
};

MercadoPagoTDCProvider.propTypes = propTypes;

export default MercadoPagoTDCProvider;
