import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import prepareItineraryDetails from 'utils/trips/prepareItineraryDetails';
import prepareProviderDetails from 'utils/trips/prepareProviderDetails';
import TripItinerary from 'components/TripItinerary';

const propTypes = {
  departureTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  showSeats: PropTypes.bool,
  showTickets: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  env: PropTypes.object.isRequired,
  showDownloadTicketsStep: PropTypes.bool,
};

const defaultProps = {
  showSeats: true,
  showTickets: false,
};

/**
 * Component used as trip detail modal body inside purchase flow.
 * @param {Object} props - Object props
 * @param {object} props.departureTrip - Indicates the departure trip data.
 * @param {object} props.returnTrip - Indicates the return trip data.
 * @param {bool} props.roundTrip - Indicates if is round trip or not.
 * @param {func} props.showModal - function to execute modal opening.
 * @param {boolean} props.showSeats - Indicates if user has selected seats to show them.
 * @param {boolean} props.showTickets - Indicates if user has selected Tickets to show them.
 * @param {boolean} props.isOpenTicket - Indicates is selected ticket is open.
 * @param {object} props.env - Indicates current environment variables.
 * @param {boolean} props.showDownloadTicketsStep - Indicates whether to see the download steps in in case the rule is fulfilled.
 * @returns {JSX.Element} A Modal body that renders a the current trip detail.
 */
class TripSummaryDetails extends Component {
  isOpenTicket(firstFragment) {
    const { isOpenTicket, env } = this.props;
    switch (env.brand) {
      case 'gfa':
        return firstFragment.openTicket;
      default:
        return isOpenTicket;
    }
  }

  renderTripDetails(trip, roundTrip = false, isReturn = false) {
    const { showModal, showSeats, showTickets, showDownloadTicketsStep } = this.props;
    const { transportType, fragments } = trip;
    const [firstFragment] = fragments;
    let title = roundTrip ? 'your_one_way_trip' : 'your_trip';
    let itinerary;
    let providerAmenities;
    let selectedSeats;

    if (isReturn) title = 'your_return_trip';

    if (transportType === 'mixed') {
      itinerary = prepareItineraryDetails(trip, showTickets);
      providerAmenities = prepareProviderDetails(trip);
    } else {
      itinerary = prepareItineraryDetails(firstFragment, showTickets);
      providerAmenities = prepareProviderDetails(firstFragment);
    }

    if (!showSeats) {
      selectedSeats = null;
    } else if (transportType === 'bus') {
      selectedSeats = firstFragment.tickets
        .map((ticket) => ({ number: ticket.seat, category: ticket.category }))
        .filter(({ number }) => !!number);
    }
    const [{ provider, services }] = providerAmenities;

    return (
      <TripItinerary
        headerDescription="total"
        itinerary={itinerary}
        providerAmenities={providerAmenities}
        selectedSeats={selectedSeats}
        showModal={showModal}
        stopoverPlace={trip.stopoverPlace}
        title={title}
        total={trip.pricing.total}
        trip={trip}
        isOpenTicket={this.isOpenTicket(firstFragment)}
        showDownloadTicketsStep={showDownloadTicketsStep}
        providerDetails={provider}
        providerServices={services}
        isPurchaseAttempt
      />
    );
  }

  render() {
    const { departureTrip, returnTrip, roundTrip } = this.props;

    return (
      <div className="trip-summary-itinerary">
        <div className="trip-summary-itinerary-left">
          {this.renderTripDetails(departureTrip, roundTrip)}
        </div>

        {roundTrip ? (
          <div className="trip-summary-itinerary-right">
            {this.renderTripDetails(returnTrip, roundTrip, true)}
          </div>
        ) : null}
      </div>
    );
  }
}

TripSummaryDetails.propTypes = propTypes;
TripSummaryDetails.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  env: state.whitelabelConfig.env,
});

export default connect(mapStateToProps)(TripSummaryDetails);
