import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import 'styles/components/purchase/PurchasesLayout';

const PurchaseLayout = ({ children }) => (
  <div className="l-purchase">
    <Header isFixed>
      <i className="topbar-logo" />
    </Header>
    {children}
  </div>
);

PurchaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PurchaseLayout;
