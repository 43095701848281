import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { range } from 'lodash';
import { Select } from '@reservamos/elements';
import { feedbackClass } from 'utils/formValidations';

/**
 * Get the days for a given year and month.
 *
 * If the month is not filled, return maximum days a month can have.
 * @param {string} year - The year.
 * @param {string} month - The month.
 * @returns {Array<{value: string, label: string}>} The days of the month.
 */
function getDays(year, month) {
  const currentDate = moment();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month() + 1; // months are 0-indexed in moment
  const currentDay = currentDate.date();
  const yearToUse = !year.length ? currentYear - 1 : year;
  const daysInMonth = !month ? 31 : moment(`${yearToUse}-${month}`, 'YYYY-MM').daysInMonth();

  return range(1, daysInMonth + 1)
    .filter((day) => {
      if (parseInt(year, 10) === currentYear && parseInt(month, 10) === currentMonth) {
        return day <= currentDay;
      }
      return true;
    })
    .map((day) => {
      const dayNumber = day.toString().padStart(2, '0');
      return {
        value: dayNumber,
        label: dayNumber,
      };
    });
}

/**
 * Get the months for a given year.
 *
 * If the year is not filled, use the year before the current year,
 * in order make sure any month is added as an option.
 * @param {string} year - The year.
 * @returns {Array<{value: string, label: string}>} The months of the year.
 */
function getMonths(year) {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1; // months are 0-indexed in moment

  const baseYear = !year ? currentYear - 1 : year;

  const months = moment.monthsShort();
  const filteredMonths = baseYear < currentYear ? months : months.slice(0, currentMonth);

  return filteredMonths.map((month, index) => {
    const monthNumber = (index + 1).toString().padStart(2, '0');

    return {
      value: monthNumber,
      label: `${monthNumber} - ${month}`,
    };
  });
}

/**
 * Get the years based on passenger type.
 * @param {string} passengerType - The type of passenger (adult, child, infant).
 * @returns {Array<{value: number, label: number}>} The years.
 */
function getYears(passengerType) {
  const passengerAgeRanges = { adult: 100, child: 19, infant: 2 };
  const currentYear = moment().year();
  const limitYear = currentYear - passengerAgeRanges[passengerType] - 1;

  const years = range(currentYear, limitYear, -1);

  return years.map((year) => ({ value: year, label: year }));
}

/**
 * BirthDateField component item.
 * @param {Object} props - The component props.
 * @param {string} props.year - The year.
 * @param {string} props.month - The month.
 * @param {string} props.day - The day.
 * @param {string} props.primaryFieldType - The primary field type.
 * @param {string} props.middleFieldType - The middle field type.
 * @param {string} props.lastFieldType - The last field type.
 * @param {string} props.passengerType - The type of passenger (adult, child, infant).
 * @param {Object} props.meta - The meta props from redux-form.
 * @param {Function} props.onDateChange - The function to handle date change.
 * @param {boolean} props.hasDateDayError - Whether the date day has an error.
 * @param {boolean} props.hasDateMonthError - Whether the date month has an error.
 * @returns {JSX.Element} The rendered component.
 */
const BirthDateFieldItem = ({
  year,
  month,
  day,
  primaryFieldType,
  middleFieldType,
  lastFieldType,
  passengerType,
  meta,
  onDateChange,
  hasDateDayError,
  hasDateMonthError,
}) => {
  const { t } = useTranslation('passengers');

  /**
   * Get the value for a given date part.
   * @param {string} type - The type of date part (day, month, year).
   * @returns {string} The value.
   */
  function getDateValue(type) {
    if (type === 'day') return day;
    if (type === 'month') return month;

    return year;
  }

  /**
   * Get the options for a given date part.
   * @param {string} type - The type of date part (day, month, year).
   * @returns {Array<{value: string, label: string}>} The options.
   */
  function getDateOptions(type) {
    if (type === 'day') return getDays(year, month);
    if (type === 'month') return getMonths(year);

    return getYears(passengerType);
  }

  /**
   * Check if the date field is disabled.
   * @param {string} type - The type of date part (day, month, year).
   * @returns {boolean} True if the date field is disabled, false otherwise.
   */
  function isDateFieldDisabled(type) {
    if (type === 'day') return false;
    if (type === 'month') return !day;

    return !month;
  }

  /**
   * Check if the field has an error.
   * @param {string} type - The type of date part (day, month, year).
   * @param {boolean} hasMetaError - Whether the meta has an error.
   * @returns {boolean} True if the field has an error, false otherwise.
   */
  function hasFieldError(type, hasMetaError) {
    if (type === 'day') {
      return hasDateDayError || hasMetaError;
    }

    if (type === 'month') {
      return hasDateMonthError || hasMetaError;
    }

    return hasMetaError;
  }

  /**
   * Render the date field.
   * @param {string} type - The type of date part (day, month, year).
   * @returns {JSX.Element} The rendered component.
   */
  function renderDateField(type) {
    const fieldId = `${type}-select`;
    const placeholder = t(`birth_date_${type}`);
    const value = getDateValue(type);
    const options = getDateOptions(type);
    const className = `form-input ${feedbackClass(meta)}`;
    const isDisabled = isDateFieldDisabled(type);

    const hasMetaError = Boolean(meta.touched && meta.error && !value);
    const hasError = hasFieldError(type, hasMetaError);

    return (
      <Select
        id={fieldId}
        placeholder={placeholder}
        value={value}
        hasError={hasError}
        options={options}
        onChange={(e) => onDateChange(type, e.target.value)}
        className={className}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <>
      {renderDateField(primaryFieldType)}
      {renderDateField(middleFieldType)}
      {renderDateField(lastFieldType)}
    </>
  );
};

BirthDateFieldItem.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  primaryFieldType: PropTypes.string.isRequired,
  middleFieldType: PropTypes.string.isRequired,
  lastFieldType: PropTypes.string.isRequired,
  passengerType: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  hasDateDayError: PropTypes.bool.isRequired,
  hasDateMonthError: PropTypes.bool.isRequired,
};

export default BirthDateFieldItem;
