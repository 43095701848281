import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class StopsFilter extends Component {
  componentDidMount() {
    document.querySelector('.modal').classList.add('modal-view-options');
  }

  componentWillUnmount() {
    document.querySelector('.modal').classList.remove('modal-view-options');
  }

  select(selected) {
    const { hideModal, onFilterSelect } = this.props;
    onFilterSelect('stops', selected);
    hideModal();
    window.scrollTo(0, 0);
  }

  render() {
    const { stopsFilter, floatingOptions, trips, t } = this.props;
    const isChecked = (active) => active === stopsFilter;

    const stops = trips.map((t) => t.stops);
    const hasMultipleStops = stops.some((s) => s >= 1);
    const hasDirect = stops.some((s) => s === 0);

    return (
      <div className="modal-arrow-container departure" onClick={(e) => e.stopPropagation()}>
        <form className="modal-content floating-sort-menu">
          {hasDirect && (
            <div className="modal-row row-direct" onClick={() => this.select('direct')}>
              <div className="modal-select radio-button-check">
                <input type="radio" name="radio" defaultChecked={isChecked('direct')} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label />
              </div>

              <div className="modal-option">
                <h4>{t('label.selector_direct_trip')}</h4>
                <p />
              </div>
            </div>
          )}

          {hasMultipleStops && (
            <div className="modal-row row-stops" onClick={() => this.select('multiple')}>
              <div className="modal-select radio-button-check">
                <input type="radio" name="radio" defaultChecked={isChecked('multiple')} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label />
              </div>
              <div className="modal-option">
                <h4>{t('label.selector_stopover_trip')}</h4>
                <p />
              </div>
            </div>
          )}
          <div className="modal-row" onClick={() => this.select('none')}>
            <div className="modal-select radio-button-check">
              <input type="radio" name="radio" defaultChecked={isChecked('none')} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label />
            </div>

            <div className="modal-option">
              <h4>{t('label.selector_all_trips')}</h4>
              <p />
            </div>
          </div>
        </form>

        <div className={`arrow-down options-${floatingOptions}`} />
      </div>
    );
  }
}

StopsFilter.propTypes = {
  stopsFilter: PropTypes.string.isRequired,
  floatingOptions: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  trips: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(StopsFilter);
