import React from 'react';
import PropTypes from 'prop-types';
import Portal from './Portal';
import './Modal.scss';

const SimpleModal = ({ children, onClose }) => (
  <Portal selector=".portalModal">
    <div className="portal-overlay" onClick={onClose} role="presentation">
      <div className="portal-modal" onClick={(e) => e.stopPropagation()} role="presentation">
        {children}
      </div>
    </div>
  </Portal>
);

SimpleModal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
};

SimpleModal.defaultProps = {
  onClose: () => {},
  children: null,
};

export default SimpleModal;
