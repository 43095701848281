import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Button, Currency, Icon, Spacing } from '@reservamos/elements';
import dotersLogo from '../../images/doters/greendoters.svg';
import costapassLogo from '../../images/costapass/costapass.svg';
import travelpassLogo from '../../images/travelpass/travelpass.png';

const LoyaltyOption = ({ balance, loyaltyProgram, onSelect }) => {
  const { t } = useTranslation('loyalty');
  const loyaltyPrograms = {
    doters: {
      logo: dotersLogo,
      color: 'loyalty',
      text: t('use_doters'),
      currencyText: t('points'),
    },

    costapass: {
      logo: costapassLogo,
      color: 'primary',
      text: t('use_costapass'),
      currencyText: 'MXN',
    },

    travelpass: {
      logo: travelpassLogo,
      color: 'primary',
      text: t('use_travelpass'),
      currencyText: 'MXN',
    },
  };

  return (
    <Box
      paddingVerticalMobile="M"
      paddingHorizontalMobile="S"
      paddingVertical="S"
      paddingHorizontal="M"
      bgColor={loyaltyPrograms[loyaltyProgram].color}
      alphaBg="XS"
      borderRadius="S"
    >
      <Spacing isResponsive responsiveSize="S" justifyContent="space-between">
        <Spacing alignItems="center" justifyContent="center" size="XS">
          <Icon type={loyaltyPrograms[loyaltyProgram].logo} />
          <Currency
            price={balance}
            decimals="0"
            sign=""
            size="L"
            mobileSize="S"
            weight="bold"
            color={loyaltyPrograms[loyaltyProgram].color}
            currency={loyaltyPrograms[loyaltyProgram].currencyText}
            currencyLowercase
          />
        </Spacing>
        <div className="select">
          <Button
            variant={loyaltyPrograms[loyaltyProgram].color}
            text={loyaltyPrograms[loyaltyProgram].text}
            fullWidth
            fontWeight="regular"
            mobileSize="S"
            padding="M"
            minHeight="35px"
            onClick={onSelect}
          />
        </div>
      </Spacing>
    </Box>
  );
};

LoyaltyOption.propTypes = {
  balance: PropTypes.number,
  loyaltyProgram: PropTypes.oneOf(['doters', 'travelpass']),
  onSelect: PropTypes.func,
};

export default LoyaltyOption;
