import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'styles/components/OpenTicketGFA';
import { Spacing, Currency, Button, Icon, ProviderLogo } from '@reservamos/elements';
import openTicket from 'images/brands-unique/gfa/open-ticket.svg';
import { useTranslation } from 'react-i18next';
import PriceComponent from './PriceComponent';

const OpenTicket = ({
  provider,
  providerName,
  selectTrip,
  providerDiscount,
  total,
  trip,
  windowTicket,
  way,
  walletPoints,
}) => {
  const { t } = useTranslation();
  const { features } = useSelector((state) => state.whitelabelConfig);
  const discountBadge = <i className="result-box-sale" />;
  const colorPrice = providerDiscount ? 'discount' : 'grayMedium';

  if (!features.SHOW_OPEN_TICKET_RESULT) return null;

  const handleOnSelect = () => {
    selectTrip(trip, null);
  };

  return (
    <div className="open-ticket-container" data-testid="open-ticket-result">
      <div className="js-mobile-click" onClick={() => selectTrip(trip, null)} />
      <div className="open-ticket-logo">
        <ProviderLogo imgSrc={provider} name={providerName} />
      </div>

      <div className="open-ticket-title-wrapper">
        <div className="open-ticket-title">
          <Icon type={openTicket} size="S" hasMargin />
          <p className="flat-text-primary">
            {t('general:open_ticket', { context: way })}
            <br className="show-for-large-only" />
            <span className="flat-text-main"> {t('general:travels')}</span>
          </p>
        </div>
      </div>

      <div className="open-ticket-text">
        <p className="flat-text-regular">{t('general:open_ticket_result_text')}</p>
      </div>

      {providerDiscount && discountBadge}
      <div className="open-ticket-price">
        <Spacing size="XS" alignItems="center" justifyContent="flex-end">
          <p className="flat-text-regular">{t('search:ticket_office_price')}:</p>
          <Currency price={windowTicket} size="XS" color="grayLight" />
        </Spacing>
        <PriceComponent walletPoints={walletPoints} total={total} colorPrice={colorPrice} />
      </div>

      <div className="open-ticket-button">
        <Button variant="primary" text={t('general:select')} onClick={handleOnSelect} />
      </div>
    </div>
  );
};

OpenTicket.propTypes = {
  provider: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  windowTicket: PropTypes.number.isRequired,
  selectTrip: PropTypes.func.isRequired,
  providerDiscount: PropTypes.number,
  total: PropTypes.number.isRequired,
  trip: PropTypes.shape().isRequired,
  way: PropTypes.string.isRequired,
  walletPoints: PropTypes.number,
};

OpenTicket.defaultProps = {
  providerDiscount: 0,
  walletPoints: 0,
};

export default OpenTicket;
