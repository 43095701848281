import { trip } from 'js-api-client';
import { camelizeKeys, decamelizeKeys } from 'humps';
import pollingPromises from '../utils/pollingPromises';

export default function fetchTripDetails(tripId, opts = {}) {
  const options = decamelizeKeys({ withPricing: false, include: ['bus'], ...opts });
  const poll = pollingPromises({
    data: tripId,
    name: 'onReceiveTrip',
    create: (polling) => trip.getWithBus(tripId, options, polling),
  });

  return poll.then(({ payload }) => {
    const details = camelizeKeys(payload);
    return details;
  });
}
