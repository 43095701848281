import { connect } from 'react-redux';
import { encodePassengers } from 'utils/Reserbus';
import PriceChangedModal from './PriceChangedModal';

const mapStateToProps = ({ purchase }) => {
  const passengersSelection = purchase.get('passengersSelection');
  const encodedPassengersSelection =
    (passengersSelection && encodePassengers(passengersSelection)) || 'A1';
  return {
    total: purchase.get('total'),
    departure: purchase.getIn(['departs', 'departure']),
    origin: purchase.getIn(['departs', 'origin', 'cityName']),
    originId: purchase.getIn(['departs', 'origin', 'id']),
    destination: purchase.getIn(['departs', 'destination', 'cityName']),
    destinationId: purchase.getIn(['departs', 'destination', 'id']),
    totalBeforeLock: purchase.get('totalBeforeLock'),
    hasDiscount: Boolean(purchase.get('discountAmount')),
    walletBalanceUsed: purchase.get('walletBalanceUsed') || 0,
    encodedPassengersSelection,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, null, mergeProps)(PriceChangedModal);
