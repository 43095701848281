import * as actionTypes from 'constants/ActionTypes';
import formErrorsTracker from 'metrics/user-analytics/formErrorsTracker';

const middleware = () => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case actionTypes.UA_FORM_ERRORS: {
      const { funnelStep, fields } = action;

      formErrorsTracker(funnelStep, fields);
      break;
    }

    default:
      break;
  }

  return result;
};

export default middleware;
