import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  paramsToObject,
  isRoundTrip,
  ObjectToSearchParams,
  fromBrand,
} from 'utils/search/tripParams';
import LoadingScreen from 'components/LoadingScreen';
import isSearchPolling from 'utils/search/isSearchPolling';
import moment from 'moment';
import { camelizeKeys } from 'humps';
import { purchaseAttemptTracker } from 'metrics/user-analytics/purchase';
import { viewResultsTracker, interestInRedirect } from 'metrics/user-analytics/search';
import { withTranslation } from 'react-i18next';

/**
 * Represents a TripSelector component.
 */
class TripSelector extends Component {
  /**
   * Constructs a new TripSelector instance.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);

    this.createSearch = this.createSearch.bind(this);
    this.findSelectedTrip = this.findSelectedTrip.bind(this);
    this.redirectToPurchase = this.redirectToPurchase.bind(this);
    this.verifyErrors = this.verifyErrors.bind(this);
    this.redirectToSearch = this.redirectToSearch.bind(this);
    this.setSearchParams = this.setSearchParams.bind(this);
    this.searchParamsSet = false;
    this.purchaseCreated = false;
    this.state = {
      roundTrip: false,
      departureParams: {},
      returnsParams: {},
      fromLead: '',
    };
  }

  componentDidMount() {
    this.createSearch();
  }

  componentDidUpdate() {
    const {
      departures,
      returns,
      newPurchase,
      token,
      setError,
      analyticsData,
      isFetching,
      location,
    } = this.props;
    const { roundTrip, fromLead } = this.state;

    if (!this.searchParamsSet) {
      this.searchParamsSet = true;
      this.setSearchParams();
    }

    const isPolling = isSearchPolling(null, departures, returns, roundTrip);
    if (isPolling) return;

    if (!token && !isFetching) {
      const departureTrip = this.findSelectedTrip(departures, 'departure');
      const returnTrip = roundTrip ? this.findSelectedTrip(returns, 'return') : null;
      const hasError = this.verifyErrors(departureTrip, returnTrip);

      interestInRedirect({
        departureTrip,
        returnTrip,
        roundTrip,
      });

      const queryParams = new URLSearchParams(location.search);

      viewResultsTracker(analyticsData, queryParams, false);

      if (hasError) {
        setError();
        this.redirectToSearch();
        return;
      }

      const departureId = departureTrip.get('id');
      const returnId = returnTrip ? returnTrip.get('id') : null;
      const { passengers, selected_seats: selectedSeats } = paramsToObject(
        queryParams,
        'departure',
      );
      const parsedSelectedSeats = selectedSeats && JSON.parse(selectedSeats);
      if (!this.purchaseCreated) {
        this.purchaseCreated = true;
        newPurchase(departureId, returnId, fromLead, passengers, parsedSelectedSeats);
      }
    }

    if (token) this.redirectToPurchase();
  }

  setSearchParams() {
    const { setParams } = this.props;
    const { departureParams, returnsParams, roundTrip } = this.state;
    const { departsOrigin, departsDestination, departsDate } = departureParams;
    const returnDate = roundTrip ? returnsParams.returnDate : undefined;

    setParams(departsOrigin, departsDestination, departsDate, returnDate);
  }

  verifyErrors(departureTrip, returnTrip) {
    const { roundTrip } = this.state;
    let hasErrors = false;

    if (roundTrip) {
      if (!departureTrip || !returnTrip) hasErrors = true;
    } else if (!departureTrip) hasErrors = true;

    return hasErrors;
  }

  findSelectedTrip(trips, way) {
    const { departureParams, returnsParams } = this.state;
    const params = way === 'departure' ? departureParams : returnsParams;

    const wayParams = {
      departure: {
        paramsHour: 'departsHour',
        paramsMinutes: 'departsMinutes',
        paramsLine: 'departsLine',
      },
      return: {
        paramsHour: 'returnHour',
        paramsMinutes: 'returnMinutes',
        paramsLine: 'returnLine',
      },
    };

    const { paramsHour, paramsMinutes, paramsLine } = wayParams[way];

    const tripList = trips.getIn(['buses', 'trips']);

    const selectedTrip = tripList.find((trip) => {
      const tripJs = trip.toJS();

      const tripHour = moment(tripJs.departure).format('HH');
      const tripMinutes = moment(tripJs.departure).format('mm');

      return (
        tripHour === params[paramsHour] &&
        tripMinutes === params[paramsMinutes] &&
        params[paramsLine] === tripJs.lineId
      );
    });

    if (!selectedTrip) return null;

    return selectedTrip;
  }

  redirectToPurchase() {
    const { token, purchase, history } = this.props;
    purchaseAttemptTracker(purchase);
    history.push(`/purchase/${token}/seats/departure`);
  }

  redirectToSearch() {
    const { history, location } = this.props;
    const { departureParams, returnsParams, roundTrip } = this.state;
    const { departsOrigin, departsDestination, departsDate } = departureParams;

    const departureDate = moment(departsDate, 'DD-MM-YYYY').format('DD-MMM-YY');
    let url = `/search/${departsOrigin}/${departsDestination}/${departureDate}/`;

    if (roundTrip) {
      const { returnsDate } = returnsParams;
      const returnDate = moment(returnsDate, 'DD-MM-YYYY').format('DD-MMM-YY');
      url += `/${returnDate}/`;
    }

    const queryParams = new URLSearchParams(location.search);
    const { passengers } = paramsToObject(queryParams, 'departure');

    url += `p/${passengers}/departures`;

    history.replace(url);
  }

  createSearch() {
    const {
      location,
      departureId,
      departureDidInvalidate,
      returnId,
      returnDidInvalidate,
      createSearch,
    } = this.props;
    const { search } = location;

    const query = new URLSearchParams(search);

    const roundTrip = isRoundTrip(query);
    const departure = paramsToObject(query, 'departure');
    const returns = roundTrip ? paramsToObject(query, 'return') : {};
    const fromLead = fromBrand(query);

    this.setState({
      fromLead,
      roundTrip,
      departureParams: camelizeKeys(departure),
      returnsParams: camelizeKeys(returns),
    });

    if (!departureId || departureDidInvalidate) {
      const { origin, destination, date, roundTrip } = ObjectToSearchParams(departure, 'departure');
      createSearch('departure', origin, destination, date, roundTrip);
    }

    if (roundTrip && (!returnId || returnDidInvalidate)) {
      const { origin, destination, date, roundTrip } = ObjectToSearchParams(returns, 'return');
      createSearch('return', origin, destination, date, roundTrip);
    }
  }

  render() {
    const { t } = this.props;

    return <LoadingScreen loadingText={t('loading')} />;
  }
}

TripSelector.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  departureId: PropTypes.number,
  departureDidInvalidate: PropTypes.bool.isRequired,
  returnId: PropTypes.number,
  returnDidInvalidate: PropTypes.bool.isRequired,
  createSearch: PropTypes.func.isRequired,
  departures: PropTypes.object,
  returns: PropTypes.object,
  newPurchase: PropTypes.func.isRequired,
  token: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  setError: PropTypes.func.isRequired,
  purchase: PropTypes.object,
  analyticsData: PropTypes.object,
  t: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
};

export default withTranslation('general')(TripSelector);
